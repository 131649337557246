import { Component, OnInit, ViewChild, Input, HostListener } from '@angular/core';
import { AppConstants } from '@app/_helpers/api-constants';
import { BreadcrumbService, EncrDecrService, MangoApiService, mangoUtils, AuthGuard } from '@app/_services';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { MenuItem, SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';
import Swal from 'sweetalert2';
import moment from 'moment';
import $ from "jquery";
import { environment } from "@environments/environment";
import { forkJoin, timer } from 'rxjs'
import { ToolbarService, LinkService, ImageService, HtmlEditorService, ResizeService, RichTextEditorComponent } from '@syncfusion/ej2-angular-richtexteditor';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { SharedComponentsService } from '@app/shared/components';
import { CurrencyPipe } from '@angular/common';
import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';
import { RRule } from 'rrule';
import { async } from 'rxjs/internal/scheduler/async';
declare let numeral: any;
declare var $: any;
declare let FattJs: any;

@Component({
  selector: 'app-recurring-invoices',
  templateUrl: './recurring-invoice-new.component.html',
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService, ResizeService]
})
export class RecurringInvoiceNewComponent implements OnInit {
  searchTextStr = this.encrDecSrvc.getObject(
    AppConstants.recurringInvoices + '_' + AppConstants.SearchString
  );
  @ViewChild('searchValue') searchValue;
  @ViewChild('searchValueTime') searchValueTime;
  searchTextTimeStr: any = '';
  filteredTimeItemsSize = -1;
  @ViewChild('dt') dt: Table;
  @ViewChild('dtExpenses') dtExpenses: Table;
  @ViewChild('dtDetails') dtDetails: Table;
  @ViewChild('timeRecordsDt') timeRecordsDt: Table;
  filteredItemsSize = -1;
  isEnrollForm: boolean = false;
  recurringPaymentRule: string = '';
  public rule: any;
  public myPaymentForm: UntypedFormGroup;
  public isBusiness: string = 'false';
  public selectedCustomer: any = null;
  public isProcessing: boolean = false;
  public isDebitCard: boolean = false;
  public isDetailAmountChanged: boolean = false;
  public isFromDelete: boolean = false;
  public sTaxProfile: any = null;
  isHeaderCheckbox = false;
  timeexp;
  public isLoadingSalesTax: boolean = false;

  public selectedReviewItems: any = [];
  public selectedTimeRecords: any = [];
  public cardOptionsList: any = [];
  public selectedDetails: any = [];
  public selectedExpenses: any = [];
  public timeRecordsFooter: any = {
    totalTime: 0,
    nonbillableamount: 0,
    laboramount: 0
  };
  public parentDetailFooter: any = {
    totalTime: 0,
    nonbillableamount: 0,
    laboramount: 0,
    wuwd: 0,
    amount: 0
  };
  public parentExpenseFooter: any = {
    amount: 0
  };
  public fattJs: any;
  public headerListItems: any = [];
  public invoiceList: any = [];
  public staffList: any = [];
  public clonedData: any = {};
  public engagementListItems: any = [];
  public activityList: any = [];
  public activityList1: any = [];
  expenseList: any = [];
  public clientsList: any = [];
  public totalAmount: any = 0;
  public recurringInvoiceHistoryTotal: any = 0;
  public invoiceDate: any = new Date();
  public lastInvoice: any;
  public replaceText: any;
  public topMemoValue: any;
  public bottomMemoValue: any;
  public clientProfile: any;
  public companyLocations: SelectItem[] = [];

  public dialogDisplay: boolean = false;
  public isDialogFormValid = false;
  public isVisaCard: string = 'true';
  public cardDialogDisplay: boolean = false;
  public IsDataReadOnly: boolean = true;
  public IsEditorChanges: boolean = false;
  public IsTopBottomModified: boolean = false;
  public isUpdateAllenabled: boolean = false;
  public isProcessDisabled: boolean = true;
  public addNewCrCardDialog: boolean = false;
  public showAssignTask: boolean = false;
  public isCrCard: boolean = false;
  public showRecurringInvoiceHistoryDialog: boolean = false;
  public btnText: any = 'Edit';
  public staffListItems: SelectItem[];
  public groupsList: SelectItem[];
  public recurringInvoiceGroupList: SelectItem[];
  public freqList: SelectItem[];
  public Staff: any = {};
  public TableCols: any = [];
  public filteredStaffSingle: any[];
  public filteredGroupsSingle: any[];
  public filteredServiceSingle: any[];
  public RecurringInvoiceHistory: any = [];
  public headerProcessedCounter = 0;
  buttonsDeleteList: MenuItem[];
  showBatchUpdate: boolean = false;
  isFormValid: boolean = false;
  isNeverEnds: boolean = false;
  public batchUpdateForm: UntypedFormGroup;
  batchUpdateMemo = null;
  successCounter = 0;
  clientNames = [];
  createTimeRecordsArr = [];
  createBillingDetailsArr = [];
  salesTaxObj: any = { serviceTax: 0 };
  updateRecurringHeaderScheduleDatesArr = [];
  updateRecurringDetailsDatesArr = [];
  updateBillingHeaderArr = [];
  processedBillingDetailCounter = [];

  public clientList: any = [];
  public filteredClients: any = [];
  public selectedType: number = 0;
  public userName;
  public recurringUrl = 'https://' + environment.BASIC_DOMAIN;
  public superAdminVisible: number[] = AppConstants.superAdminVisible;

  intervalid: any;
  cols: any[];
  _selectedColumns: any = [];

  cols1: any[];
  _selectedColumns1: any = [];

  globalFilterColumns: any = [
    'ClientName',
    'StaffName',
    'RecurringGroupDescription',
    'EngagementName',
    'InvoiceShortDescription',
    'BillAmount',
    'Frequency',
    'NextInvoiceDate',
    'PeriodTo'
  ];
  recurringHeaderFilterColumns = [
    'ClientName',
    'Description',
    'Frequency',
    'ScheduledDate',
    'InvoiceAmount'
  ];

  public salesTax: any = {
    Labor: 0,
    Expense: 0,
    taxableAmtService: 0,
    taxableAmtExpense: 0,
    serviceTax: 0,
    expenseTax: 0,
    billingHeaderId: null
  };
  public defaultStaxObj = {
    SurChargeAmount: 0,
    withSurcharge: 0,
    rule: null,
    customer_id: null,
    total: null,
    meta: { tax: 0, subtotal: null, otherField1: null, otherField2: null, reference: null },
    url: ``,
    email_notification: false,
    payment_method_id: null
  };
  public invoiceTax = 0;
  sidePanelDisplay: boolean = false;
  timeRecordsDisplay: boolean = false;
  selectedData = {};
  selectedEngagment = null;
  indexEdit = null;
  isEdit = false;
  companyId;
  recurringInvoiceDetails: any = [];
  recurringInvoiceExpenses: any = [];
  recurringInvoiceOpenTimeRecords: any = [];
  timeRecordsByHeaderIDAndDetailID = {};
  detailsByHeaderID = {};
  expensesByHeaderID = {};
  headerInvoiceAmountTotal = 0;
  headerSalesTaxTotal = 0;
  openTimeGlobalColumns: any = [];

  private eventTimeSheetSubscription;
  private eventTimeExpenseSubscription;
  public currentRuleStr: any = '';
  public ruledateSource: any = null;
  public ruleTwodateSource: any = null;
  public selectedModeOfPayment: any = null;
  public transCodeTypes: any = [];
  public cards: any = null;
  clientBalances: any = [];

  months = [
    { label: 'January', value: 0 },
    { label: 'February', value: 1 },
    { label: 'March', value: 2 },
    { label: 'April', value: 3 },
    { label: 'May', value: 4 },
    { label: 'June', value: 5 },
    { label: 'July', value: 6 },
    { label: 'August', value: 7 },
    { label: 'September', value: 8 },
    { label: 'October', value: 9 },
    { label: 'November', value: 10 },
    { label: 'December', value: 11 }
  ];

  quarters = [
    { label: '1st Quarter', value: 1 },
    { label: '2nd Quarter', value: 2 },
    { label: '3rd Quarter', value: 3 },
    { label: '4th Quarter', value: 4 }
  ];
  currentMonth = null;
  currentYear = null;
  currentQuarter = null;
  years = [];

  finalizeActions: SelectItem[];
  invoiceTemplateTypes: SelectItem[];

  showFilterSideBar: boolean = false;
  public AllStaffsTypes: any = [];
  groupList: SelectItem[];
  InvoiceStartDate = null;
  BillingPartnerID;
  SelectedGroup;
  InvoiceGroup;
  isIncludedBillThrough: boolean = true;

  isPreviewVisible: boolean = false;
  loginCompanyId: any;
  billingHeaderPreview = null;
  public dialogTab1Display: boolean = false;
  public dialogTab2Display: boolean = false;
  public dialogTab3Display: boolean = false;
  public dialogTab4Display: boolean = false;
  public dialogTab5Display: boolean = false;
  public dialogRecurringPayments: boolean = false;
  public pdfUrl: any = '';
  scratchPadEnabled: boolean = false;
  isSkipInvoiceReview = null;
  public editableData: any = null;
  public recurringRowData: any = {
    count: 99,
    Frequency: '',
    InvoiceAmount: 0,
    ScheduledDate: null
  };
  public companyDetails: any = null;
  public rteObj: RichTextEditorComponent;
  public tools: ToolbarModule = {
    items: [
      'Bold',
      'Italic',
      'Underline',
      'StrikeThrough',
      'FontName',
      'FontSize',
      'FontColor',
      'BackgroundColor',
      'LowerCase',
      'UpperCase',
      'SuperScript',
      'SubScript',
      '|',
      'Formats',
      'Alignments',
      'OrderedList',
      'UnorderedList',
      'Outdent',
      'Indent',
      '|',
      'CreateTable',
      'CreateLink',
      'Image',
      '|',
      'ClearFormat',
      'Print',
      'SourceCode',
      'FullScreen',
      '|',
      'Undo',
      'Redo'
    ]
  };
  companySetting: any;

  constructor(
    private _fb: UntypedFormBuilder,
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private breadcrumbService: BreadcrumbService,
    public mangoUtils: mangoUtils,
    private http: HttpClient,
    private auth: AuthGuard,
    private router: Router,
    private sharedSrvc: SharedComponentsService,
    private currencyPipe: CurrencyPipe,
    private translate: TranslateService
  ) {
    this.loginCompanyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.timeexp = this.encrDecSrvc.getObject(AppConstants.timeAndExpenses);
    this.userName = this.encrDecSrvc.getObject(AppConstants.userName);
    this.transCodeTypes = [
      { label: 'None', value: null },
      { label: 'Checking', value: 27 },
      { label: 'Savings', value: 37 }
    ];
    this.getMonthAndYearDropdownOptions();

    this.cardOptionsList = [
      { label: 'Credit Card', value: 'CC' },
      { label: 'ACH', value: 'ACH' }
    ];

    this.translate.reloadLang(this.translate.currentLang).subscribe(data => {
      this.invoiceTemplateTypes = [
        { value: '2', label: this.translate.instant('client.narrative') },
        {
          value: '9',
          label: this.translate.instant('client.narrative_summary')
        },
        {
          value: '10',
          label: this.translate.instant('client.narrative_detail')
        },
        {
          value: '4',
          label: this.translate.instant('client.narrative_mini_statement')
        },
        // { value: '14', label: this.translate.instant('Flexible Invoice') },
        { value: '0', label: this.translate.instant('client.detail') },
        { value: '6', label: this.translate.instant('client.detail_no_rate') },
        { value: '7', label: this.translate.instant('client.detail_no_rate_time') },
        {
          value: '11',
          label: this.translate.instant('client.detail_no_rate_time_amount')
        },
        {
          value: '12',
          label: this.translate.instant('client.narrative_simple_no_detail')
        },
        {
          value: '13',
          label: this.translate.instant('client.narrative_simple_with_detail')
        },
        {
          value: '14',
          label: this.translate.instant('client.narrative_simple_no_remit')
        },
        {
          value: '15',
          label: this.translate.instant('client.invoice_detail_summary')
        },
        {
          value: '16',
          label: this.translate.instant('client.invoice_narrative_summary_by_activity')
        }
      ];
      this.breadcrumbService.setItems([
        { label: this.translate.instant('Billing-&-Invoicing') },
        { label: this.translate.instant('recurring-invoice'), icon: 'ic-red' }
      ]);
      this.freqList = [
        { label: this.translate.instant('user.weekly'), value: 'Weekly' },
        { label: this.translate.instant('bi_weekly'), value: 'Bi-Weekly' },
        { label: this.translate.instant('user.monthly'), value: 'Monthly' },
        { label: this.translate.instant('semi_monthly'), value: 'Semi-Monthly' },
        { label: this.translate.instant('quarterly'), value: 'Quarterly' },
        { label: this.translate.instant('semi_annually'), value: 'Semi-Annually' },
        { label: this.translate.instant('user.annually'), value: 'Annually' }
      ];
      this.initializeColumns1();
      this.initializeColumns();
      this.buttonsDeleteList = [
        {
          label: 'Delete Selected Items',
          icon: 'fal fa-trash-alt',
          command: () => {
            this.batchDeleteItems();
          }
        },
        {
          label: 'Batch Update Projects',
          icon: 'fal fa-pencil',
          command: () => {
            this.onShowBatchUpdate();
          }
        }
      ];
    });
    this.getExpenseGroups();
    this.finalizeActions = [
      { label: 'Print', value: 'Print' },
      { label: 'Email', value: 'Email' },
      { label: 'Finalize Only', value: 'Finalize Only' }
    ];

    this.getAllStaffList();
    this.getGroups();
    this.initializeForm();
    this.getCompanyInfo();
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnload(e) {
    if (this.isPreviewVisible) {
      e.preventDefault();
      this.closeDialog();
    }
    if (!this.isPreviewVisible) {
      return '';
    }
  }
  getCompanyInfo() {
    const parent = this;
    parent.mangoAPISrvc.getCompanyMangoDetails(this.loginCompanyId).subscribe(response => {
      parent.companyDetails = response[0];
      parent.getwebhooksEvents();
    });
  }

  batchDeleteItems() {
    Swal.fire({
      title: this.translate.instant('confirmation'),
      text: this.translate.instant('batch_recurring_header_delete'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('yes_delete'),
      cancelButtonText: this.translate.instant('no_delete')
    }).then(result => {
      if (result.value) {
        this.batchDeleteRecurringHeader(this.selectedReviewItems);
      }
    });
  }

  batchDeleteRecurringHeader(data) {
    this.mangoAPISrvc.showLoader(true);
    const observableBatch = [];
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Authorization', this.encrDecSrvc.getObject(AppConstants.token));
    data.forEach((selectedItem, key) => {
      observableBatch.push(
        this.mangoAPISrvc.deleteRecurringInvoiceHeader(selectedItem['RecurringInvoiceHeaderID'])
      );
    });
    forkJoin(observableBatch).subscribe(
      data => {
        this.mangoAPISrvc.showLoader(false);
        const count = data.filter(
          item => item['message'].toLowerCase().indexOf('success') > -1
        ).length;
        Swal.fire({
          icon: 'success',
          title: `${this.translate.instant('Success')}!`,
          showCancelButton: false,
          allowEscapeKey: true,
          allowEnterKey: true,
          confirmButtonText: 'OK',
          text: `There were ${count} recurring header(s) deleted!`
        });
        this.headerListItems = this.headerListItems.filter(
          item =>
            !this.selectedReviewItems
              .map(deleted => deleted['RecurringInvoiceHeaderID'])
              .includes(item['RecurringInvoiceHeaderID'])
        );
        this.selectedReviewItems = [];
        this.verifyNewRecordPresents();
        this.loadFilterGrid();
      },
      err => {
        this.mangoAPISrvc.showLoader(false);
      }
    );
  }

  validateForm(isChanged?: boolean) {
    let isInValidData = false;
    let istouchedData = false;
    Object.keys(this.batchUpdateForm.controls).forEach(key => {
      if (this.batchUpdateForm.get(key).invalid) {
        isInValidData = true;
      }
      if (
        ['ScheduledDate', 'Group', 'Frequency', 'FinalizeAction', 'InvoiceTemplate'].includes(
          key
        ) &&
        this.batchUpdateForm.get(key).dirty
      ) {
        istouchedData = true;
      }
    });

    istouchedData = istouchedData || isChanged;
    this.isFormValid = !isInValidData && istouchedData;
  }

  initializeForm() {
    this.batchUpdateForm = this._fb.group({
      ScheduledDate: [null],
      isScheduledDate: [false],
      ReleaseDate: [null],
      isReleaseDate: [false],
      isMemo: [false],
      Group: [null],
      isGroup: [false],
      Frequency: [null],
      isFrequency: [false],
      InvoiceTemplate: [null],
      isInvoiceTemplate: [false],
      FinalizeAction: [null],
      isFinalizeAction: [false]
    });
    this.batchUpdateForm.valueChanges.subscribe(data => {
      this.validateForm();
    });
  }

  onShowBatchUpdate() {
    this.showBatchUpdate = true;
  }

  onCloseBatchUpdate() {
    this.showBatchUpdate = false;
    this.batchUpdateForm.reset();
    this.batchUpdateMemo = null;
  }

  saveBatchUpdate() {
    const updateObj = this.batchUpdateForm.value;

    this.selectedReviewItems = this.selectedReviewItems.map(item => {
      if (updateObj['isScheduledDate'] && updateObj['ScheduledDate']) {
        item['ScheduledDate'] = this.mangoUtils.formatDateWithoutTime(
          new Date(updateObj['ScheduledDate'])
        );

        item['isScheduledDate'] = true;
      }
      if (updateObj['isReleaseDate'] && updateObj['ReleaseDate']) {
        item['ReleaseDate'] = this.mangoUtils.formatDateWithoutTime(
          new Date(updateObj['ReleaseDate'])
        );
        item['isReleaseDate'] = true;
      }
      if (updateObj['isMemo'] && this.batchUpdateMemo) {
        item['Description'] = this.batchUpdateMemo;
      }
      if (updateObj['isGroup'] && updateObj['Group']) {
        item['RecurringInvoiceGroupID'] = updateObj['Group'];
        this.onGroupChange(item, updateObj['Group']);
      }
      if (updateObj['isFrequency'] && updateObj['Frequency']) {
        item['Frequency'] = updateObj['Frequency'];
      }
      if (updateObj['isInvoiceTemplate'] && updateObj['InvoiceTemplate']) {
        item['InvoiceTemplate'] = updateObj['InvoiceTemplate'];
      }
      if (updateObj['isFinalizeAction'] && updateObj['FinalizeAction']) {
        item['FinalizeAction'] = updateObj['FinalizeAction'];
      }
      return item;
    });
    this.batchUpdateRecurringHeader(this.selectedReviewItems);
  }

  onGroupChange(data, groupId) {
    const recurringGroupObj = this.recurringInvoiceGroupList.find((item) => item.value == groupId);
    if (!recurringGroupObj) {
      return false;
    }
    data['RecurringGroupDescription'] = recurringGroupObj.label;
  }

  batchUpdateRecurringHeader(data) {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.recurringHeaderBulkUpdate(data).subscribe(
      res => {
        this.mangoAPISrvc.showLoader(false);
        Swal.fire({
          icon: 'success',
          title: `${this.translate.instant('Success')}!`,
          showCancelButton: false,
          allowEscapeKey: true,
          allowEnterKey: true,
          confirmButtonText: 'OK',
          text: `There were ${data.length} recurring header(s) updated!`
        });
        this.selectedReviewItems = this.selectedReviewItems.map(item => {
          if (item['isScheduledDate'] && item['ScheduledDate']) {
            item['ScheduledDate'] = new Date(item['ScheduledDate']);
          }
          return item;
        });
        this.onCloseBatchUpdate();
        this.selectedReviewItems = [];
        this.verifyNewRecordPresents();
      },
      err => {
        this.mangoAPISrvc.showLoader(false);
        this.onCloseBatchUpdate();
      }
    );
  }

  onFilterFetch() {
    let query = `billThroughDate=${
      this.InvoiceStartDate ? moment(this.InvoiceStartDate).format('MM-DD-YYYY') : null
    }`;
    // query += formData['staffId'] ? (formData['staffId'] + '/') : "null/";
    // query += (this.selEngagementType.length > 0) ? (this.selEngagementType + '/') : "null/";
    query += `&billingPartnerId=${this.BillingPartnerID ? this.BillingPartnerID : null}`;
    query += `&selectedGroup=${this.SelectedGroup ? this.SelectedGroup : null}`;
    query += `&invoiceGroup=${this.InvoiceGroup ? this.InvoiceGroup : null}`;
    // query += formData['selectedBillingMethod'] ? (formData['selectedBillingMethod'] === 'Hourly' ? 'H' : 'F') : null;
    this.onCloseFilter();
    // this.headerListItems = [];
    const companyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.mangoAPISrvc.showLoader(true);
    let _this = this;
    this.mangoAPISrvc.getRecurringHeader1(companyId, query).subscribe((data: any) => {
      _this.headerListItems = data.map(function (obj) {
        obj['ScheduledDate'] = obj['ScheduledDate']
          ? new Date(_this.mangoUtils.formatDateString(obj['ScheduledDate']))
          : null;

        obj['isNew'] = false;
        obj['InvoiceAmount'] = obj['InvoiceAmount'] || 0;
        obj['rowCount'] = Number(obj['detailsCount']) + Number(obj['expensesCount']);
        const schdlDate = obj['ScheduledDate']?moment(obj['ScheduledDate']).format("MM/DD/YYYY"):moment().format("MM/DD/YYYY");
        const currentDate = moment().format("MM/DD/YYYY");
        obj['isRecurringAllowed'] = schdlDate>=currentDate;
        obj['ELProposalID'] = obj['ELProposalID'];
        return obj;
      });
      _this.totalAmount = numeral(
        data.reduce(function (a, b) {
          return a + +numeral(b['BillAmount']).value();
        }, 0)
      ).format('0,0.00');
      _this.headerListItems.sort(function (a, b) {
        if (a.ClientName.toLowerCase() < b.ClientName.toLowerCase()) {
          return -1;
        }
        if (a.ClientName.toLowerCase() > b.ClientName.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      if (_this.headerListItems.length == 0) {
        _this.showAssignTask = true;
      }
      _this.mangoAPISrvc.showLoader(false);
    });
  }

  onResetFilters() {
    this.InvoiceStartDate = null;
    this.BillingPartnerID = null;
    this.SelectedGroup = null;
    this.InvoiceGroup = null;
  }

  getAllStaffList() {
    const parent = this;
    if (parent.AllStaffsTypes.length > 0) {
      return false;
    }
    parent.AllStaffsTypes = [{ label: 'All', value: null }];
    let item = [];
    if (parent.auth.isAllowAccess(14)) {
      item = parent.encrDecSrvc.getObject(AppConstants.allStaffList);
    } else {
      item = parent.encrDecSrvc.getObject(AppConstants.staffList);
      item = item?.filter(
        staff => staff?.StaffID == parent.encrDecSrvc.getObject(AppConstants.staffID)
      );
    }
    for (let i = 0; i < item.length; ++i) {
      if (item[i].Inactive) continue;

      const obj = {};
      obj['label'] = item[i]['StaffName'];
      obj['value'] = item[i]['StaffID'];
      obj['StaffID'] = item[i]['StaffID'];
      obj['StaffName'] = item[i]['StaffName'];
      parent.AllStaffsTypes.push(obj);
    }
  }

  getGroups() {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getGroups().subscribe(
      (data: any) => {
        this.groupList = data.map(group => {
          return {
            label: group.GroupDescription,
            value: group.CustomerGroupCategoryID
          };
        });
        this.groupList.unshift({ label: 'All', value: null });
        this.mangoAPISrvc.showLoader(false);
      },
      error => {
        this.mangoAPISrvc.notify(
          'error',
          this.translate.instant('error'),
          AppConstants.fetchErrorMsg
        );
        this.mangoAPISrvc.showLoader(false);
      }
    );
  }

  onCloseFilter(): void {
    this.showFilterSideBar = false;
  }

  getMonthAndYearDropdownOptions() {
    const currentDate = new Date();
    this.currentMonth = currentDate.getMonth();
    this.currentQuarter = moment().get('quarter');
    this.currentYear = currentDate.getFullYear();

    for (let i = this.currentYear + 2; i > this.currentYear - 5; i--) {
      this.years.push({ label: i.toString(), value: i });
    }
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    //restore original order
    const arr = val.map(col => col.field)
    this._selectedColumns = this.cols.filter(col => arr.includes(col.field));
    this.openTimeGlobalColumns = [...this._selectedColumns.map(col => col.field)];
  }

  onChangeSelectedCols(event) {
    let columnsToSave = '';
    event.value.map((cols, index) => {
      if (index > 0) columnsToSave += `, ${cols.field}`;
      else columnsToSave += cols.field;
    });
    const objToSave = { OpenTimeCols: `{${columnsToSave}}` };
    this.mangoAPISrvc
      .updateUserSelectedColsByUserId(this.encrDecSrvc.getObject(AppConstants.staffID), objToSave)
      .subscribe(
        data => {},
        err => {}
      );
  }

  @Input() get selectedColumns1(): any[] {
    return this._selectedColumns1;
  }

  set selectedColumns1(val: any[]) {
    //restore original order
    const arr = val.map(col => col.field)
    this._selectedColumns1 = this.cols1.filter(col => arr.includes(col.field));
    this.recurringHeaderFilterColumns = [
      ...this._selectedColumns1.map(col => col.field),
      'ClientName',
      'Description',
      'Frequency',
      'ScheduledDate',
      'InvoiceAmount'
    ];
  }

  onChangeSelectedCols1(event) {
    let columnsToSave = '';
    event.value.map((cols, index) => {
      if (index > 0) columnsToSave += `, ${cols.field}`;
      else columnsToSave += cols.field;
    });
    const objToSave = { RecurringInvoiceCols: `{${columnsToSave}}` };
    this.mangoAPISrvc
      .updateUserSelectedColsByUserId(this.encrDecSrvc.getObject(AppConstants.staffID), objToSave)
      .subscribe(
        data => {},
        err => {}
      );
  }

  initializeColumns1() {
    const allCols = [
      {
        field: 'rowCount',
        header: this.translate.instant('Rows'),
        rowClass: 'width-7p p-text-center',
        tooltip: true,
        tooltipMessage: this.translate.instant('invoice_recurring_header_rows_tooltip')
      },
      {
        field: 'isRetainer',
        header: this.translate.instant('Retainer'),
        rowClass: 'width-10p p-text-center',
        tooltip: true,
        tooltipMessage: this.translate.instant('recurring_retainer_header_tooltip')
      },
      // { field: 'RecurringGroupDescription', header: this.translate.instant('recurring_invoice_group'), rowClass: 'width-15p p-text-left', sortable: true },
      {
        field: 'CompanyLocation',
        header: this.translate.instant('company_location'),
        rowClass: 'width-10p p-text-left',
        sortable: true
      },
      {
        field: 'BillingPartnerName',
        header: this.translate.instant('Billing_Partner'),
        rowClass: 'width-7p p-text-center',
        sortable: true
      },
      {
        field: 'BillingGroupDescription',
        header: this.translate.instant('client.billing_group_description'),
        rowClass: 'width-7p p-text-center',
        sortable: true
      }
    ];
    let selectedCols = [];
    const defaultCols = [
      {
        field: 'rowCount',
        header: this.translate.instant('Rows'),
        rowClass: 'width-7p p-text-center'
      },
      {
        field: 'isRetainer',
        header: this.translate.instant('Retainer'),
        rowClass: 'width-10p p-text-center'
      },
      // { field: 'RecurringGroupDescription', header: this.translate.instant('recurring_invoice_group'), rowClass: 'width-15p p-text-left', sortable: true },
      {
        field: 'CompanyLocation',
        header: this.translate.instant('company_location'),
        rowClass: 'width-10p p-text-left',
        sortable: true
      }
      // { field: "BillingPartnerName", header: this.translate.instant("Billing_Partner"), rowClass: 'width-7p p-text-center', sortable: true  },
      // { field: "BillingGroupDescription", header: this.translate.instant("client.billing_group_description"),  rowClass: 'width-7p p-text-center', sortable: true  },
    ];
    this.cols1 = [...allCols];

    this.mangoAPISrvc
      .getUsersSelectedColsByUserId(this.encrDecSrvc.getObject(AppConstants.staffID))
      .subscribe(
        (data: any) => {
          if (data.RecurringInvoiceCols?.length > 0) {
            selectedCols = allCols.filter(col => data.RecurringInvoiceCols.includes(col.field));
          } else {
            selectedCols = [...defaultCols];
          }
          this._selectedColumns1 = selectedCols;
          this.recurringHeaderFilterColumns = [
            ...this._selectedColumns1.map(col => col.field),
            'ClientName',
            'Description',
            'Frequency',
            'ScheduledDate',
            'InvoiceAmount'
          ];
        },
        err => {
          this.recurringHeaderFilterColumns = [
            'ClientName',
            'Description',
            'Frequency',
            'ScheduledDate',
            'InvoiceAmount'
          ];
          selectedCols = [...defaultCols];
        }
      );
  }

  initializeColumns() {
    const allCols = [
      {
        field: 'Ddate',
        header: this.translate.instant('date'),
        rowClass: 'width-7p p-text-center'
      },
      {
        field: 'StaffName',
        header: this.translate.instant('user-title'),
        rowClass: 'width-10p p-text-left'
      },
      {
        field: 'StaffNumber',
        header: this.translate.instant('User Initials'),
        rowClass: 'width-7p p-text-left'
      },
      {
        field: 'scdescr',
        header: this.translate.instant('activity'),
        rowClass: 'width-10p p-text-left'
      },
      {
        field: 'ServiceCode',
        header: this.translate.instant('Code'),
        rowClass: 'width-6p p-text-left'
      },
      {
        field: 'Memo',
        header: this.translate.instant('description'),
        rowClass: 'width-15p p-text-left'
      }
    ];
    let selectedCols = [];
    const defaultCols = [
      {
        field: 'Ddate',
        header: this.translate.instant('date'),
        rowClass: 'width-7p p-text-center'
      },
      {
        field: 'StaffName',
        header: this.translate.instant('user-title'),
        rowClass: 'width-10p p-text-left'
      },
      {
        field: 'scdescr',
        header: this.translate.instant('activity'),
        rowClass: 'width-10p p-text-left'
      },
      {
        field: 'Memo',
        header: this.translate.instant('description'),
        rowClass: 'width-15p p-text-left'
      }
    ];
    this.cols = [...allCols];
    this.mangoAPISrvc
      .getUsersSelectedColsByUserId(this.encrDecSrvc.getObject(AppConstants.staffID))
      .subscribe(
        (data: any) => {
          if (data.OpenTimeCols?.length > 0) {
            selectedCols = allCols.filter(col => data.OpenTimeCols.includes(col.field));
          } else {
            selectedCols = [...defaultCols];
          }
          this._selectedColumns = selectedCols;
          this.openTimeGlobalColumns = [...this._selectedColumns.map(col => col.field)];
        },
        err => {
          selectedCols = [...defaultCols];
          this.openTimeGlobalColumns = [
            'StaffName',
            'StaffNumber',
            'scdescr',
            'ServiceCode',
            'Memo'
          ];
        }
      );
  }

  onFinalizeActionChange(event) {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc
      .updateRecurringInvoiceHeader(this.selectedData['RecurringInvoiceHeaderID'], {
        FinalizeAction: event.value
      })
      .subscribe(
        res => {
          if (event.value == 'Email') {
            this.mangoAPISrvc.showLoader(true);
            this.mangoAPISrvc.getEmailNames(this.selectedData['ClientID']).subscribe(
              (emailsList: any) => {
                this.mangoAPISrvc.showLoader(false);
                if (emailsList.length == 0) {
                  Swal.fire({
                    icon: 'error',
                    title: this.translate.instant('Information'),
                    text: this.translate.instant('client_email_required_message'),
                    showConfirmButton: true
                  });
                  this.selectedData['FinalizeAction'] = 'Print';
                }
              },
              err => {
                this.mangoAPISrvc.showLoader(false);
                this.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
              }
            );
          } else {
            this.selectedData['FinalizeAction'] = event.value;
          }
          this.mangoAPISrvc.showLoader(false);
        },
        err => {
          this.mangoAPISrvc.showLoader(false);
          this.mangoAPISrvc.notify('error', this.translate.instant('error'), err);
        }
      );
  }

  onInvoiceTemplateChange(event) {
    this.selectedData['InvoiceTemplate'] = event.value;
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc
      .updateRecurringInvoiceHeader(this.selectedData['RecurringInvoiceHeaderID'], {
        InvoiceTemplate: event.value
      })
      .subscribe(res => {
        this.mangoAPISrvc.showLoader(false);
      });
  }

  replaceShortcuts2(value, desc, type?) {
    if (!value) {
      return;
    }
    const valueArr = value.split(" ");
    for (let i = 0; i < valueArr.length; i++) {
      let label = valueArr[i];
      for (let i = 0; i < this.mangoUtils.shortcutLabels.length; i++) {
        const shortcut = this.mangoUtils.shortcutLabels[i];
        if (shortcut["Inactive"]) {
          continue;
        }
        if (label == shortcut['ShortCutCode']) {
          label = shortcut['Phrase'];
        }
      }
      valueArr[i] = label;
    }
    desc[type] = valueArr.join(' ');
  }

  replaceShortcuts3(value) {
    if (!value) {
      return;
    }
    const valueArr = value.split(" ");
    for (let i = 0; i < valueArr.length; i++) {
      let label = valueArr[i];
      for (let i = 0; i < this.mangoUtils.shortcutLabels.length; i++) {
        const shortcut = this.mangoUtils.shortcutLabels[i];
        if (shortcut["Inactive"]) {
          continue;
        }
        if (label == shortcut['ShortCutCode']) {
          label = shortcut['Phrase'];
        }
      }
      valueArr[i] = label;
    }
    this.batchUpdateMemo = valueArr.join(' ');
  }

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (this.sidePanelDisplay && this.timeRecordsDisplay) {
      this.cancelTimeRecordsSideBar();
    } else if (this.sidePanelDisplay) {
      this.cancelRecurringInvoiceSideBar();
    }
  }

  loadFilterGrid() {
    setTimeout(() => {
      this.searchValue.nativeElement.value = this.searchTextStr || '';
      this.dt.reset();
      if (this.searchTextStr) {
        const event = new Event('input', {
          'bubbles': true,
          'cancelable': true
        });
        this.searchValue.nativeElement.dispatchEvent(event);
        this.searchValue.nativeElement.select();
      } else {
        this.searchValue.nativeElement.focus();
      }
      this.filteredItemsSize = -1;
    }, 50);
  }

  handleDropStaffSelectClick(event, data, formtype) {
    if (formtype == 'Staff') {
      data['StaffName'] = event.label;
      data['StaffNumber'] = event.initials;
      data['StaffID'] = event.value;
    } else if (formtype == 'Activity') {
      data['scdescr'] = event.originalEvent.target.textContent;
      data['ServiceCodeID'] = event.value;
    } else if (formtype == 'Expense') {
      data['ExpenseCode'] = event.originalEvent.target.textContent;
      data['ExpenseCodeID'] = event.value;
    }
  }

  getActivityGroups() {
    const parent = this;
    if (this.activityList1.length > 0) {
      return false;
    }
    //this.activityList1 = [{ label: 'Select Activity', value: null, fullobj: {} }];
    let tempList = this.encrDecSrvc.activitiesList;
    tempList = tempList.sort(parent.mangoUtils.compareValues('ServiceCode', 'asc'));
    for (let i = 0; i < tempList.length; i++) {
      parent.activityList1.push({
        label: tempList[i].ServiceCode + ' - ' + tempList[i].Description,
        value: tempList[i].ServiceCodeID,
        fullobj: tempList[i]
      });
    }
  }

  @HostListener('mouseup', ['$event'])
  @HostListener('mousemove', ['$event'])
  refreshUserState(event: MouseEvent) {
    if (!this.sharedSrvc.invoiceInactivitySub.closed)
      this.sharedSrvc.invoiceInactivitySub.next(null);
  }

  getExpenseGroups() {
    const data = this.encrDecSrvc.getObject(AppConstants.expenses);
    if (this.expenseList.length > 0 && this.expenseList.length == data.length) {
      return false;
    }
    this.expenseList = [{ label: '', value: null, dataItem: {} }];
    for (let i = 0; i < data.length; i++) {
      if (data[i].Inactive == false) {
        this.expenseList.push({
          label: data[i].Description,
          value: data[i].ExpenseCodeID,
          dataItem: data[i]
        });
      }
    }
    this.expenseList.sort(this.mangoUtils.sortList);
    this.expenseList.shift();
  }

  deleteRecurringInvoiceDetail(rowData) {
    const _this = this;
    if (rowData['RecurringInvoiceDetailID'] == null) return;
    Swal.fire({
      title: _this.translate.instant('confirmation'),
      text: _this.translate.instant('delete_alert'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: _this.translate.instant('yes_delete'),
      cancelButtonText: _this.translate.instant('no_delete')
    }).then(result => {
      if (result.value) {
        _this.mangoAPISrvc.showLoader(true);
        _this.mangoAPISrvc
          .deleteRecuringInvoiceDetail(rowData['RecurringInvoiceDetailID'])
          .subscribe(
            function (data) {
              _this.isFromDelete = true;
              _this.mangoAPISrvc.showLoader(false);
              _this.recurringInvoiceDetails = _this.recurringInvoiceDetails.filter(
                item => item['RecurringInvoiceDetailID'] != rowData['RecurringInvoiceDetailID']
              );
              _this.selectedDetails = _this.selectedDetails.filter(
                item => item['RecurringInvoiceDetailID'] != rowData['RecurringInvoiceDetailID']
              );
              _this.detailsByHeaderID[rowData['RecurringInvoiceHeaderID']] = _this.selectedDetails;

              _this.getTimeRecordsParent(rowData['RecurringInvoiceHeaderID'], true, true);

              _this.selectedData['rowCount'] -= 1;
              _this.mangoAPISrvc.notify(
                'success',
                _this.translate.instant('Success'),
                _this.translate.instant('delete-message')
              );
              _this.reOrderLineItems();
            },
            error => {
              _this.mangoAPISrvc.notify(
                'error',
                _this.translate.instant('error'),
                AppConstants.deleteErrorMsg
              );
              _this.mangoAPISrvc.showLoader(false);
            }
          );
      }
    });
  }

  deleteRecurringInvoiceExpense(rowData) {
    const _this = this;
    if (rowData['RecurringInvoiceExpenseID'] == null) return;
    Swal.fire({
      title: _this.translate.instant('confirmation'),
      text: _this.translate.instant('delete_alert'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: _this.translate.instant('yes_delete'),
      cancelButtonText: _this.translate.instant('no_delete')
    }).then(result => {
      if (result.value) {
        _this.mangoAPISrvc.showLoader(true);
        _this.mangoAPISrvc
          .deleteRecuringInvoiceExpense(rowData['RecurringInvoiceExpenseID'])
          .subscribe(
            function (data) {
              _this.isFromDelete = true;
              _this.mangoAPISrvc.showLoader(false);
              _this.recurringInvoiceExpenses = _this.recurringInvoiceExpenses.filter(
                item => item['RecurringInvoiceExpenseID'] != rowData['RecurringInvoiceExpenseID']
              );
              _this.selectedExpenses = _this.selectedExpenses.filter(
                item => item['RecurringInvoiceExpenseID'] != rowData['RecurringInvoiceExpenseID']
              );
              _this.expensesByHeaderID[rowData['RecurringInvoiceHeaderID']] =
                _this.selectedExpenses;
              _this.computeExpenseFooter(true);
              _this.selectedData['rowCount'] -= 1;
              _this.mangoAPISrvc.notify(
                'success',
                _this.translate.instant('Success'),
                _this.translate.instant('delete-message')
              );
            },
            error => {
              _this.mangoAPISrvc.notify(
                'error',
                _this.translate.instant('error'),
                AppConstants.deleteErrorMsg
              );
              _this.mangoAPISrvc.showLoader(false);
            }
          );
      }
    });
  }

  saveRecurringInvoiceExpense(rowData, rowIndex, dt) {
    if (!this.recurringInvoiceExpenseValidation(rowData)) {
      this.onRowEditInit1(rowIndex, dt, rowData);
      return;
    }
    this.selectedExpenses = this.recurringInvoiceExpenses;
    if (rowData['IsNew']) {
      this.mangoAPISrvc.showLoader(true);
      this.mangoAPISrvc.createRecuringInvoiceExpense(rowData).subscribe(
        (data: any) => {
          this.isDetailAmountChanged = true;
          rowData['RecurringInvoiceExpenseID'] = data.data['RecurringInvoiceExpenseID'];
          this.clonedData = {};
          this.computeExpenseFooter(true);
          this.mangoAPISrvc.showLoader(false);
          this.mangoAPISrvc.notify(
            'success',
            this.translate.instant('Success'),
            this.translate.instant('notification_created')
          );

          this.getTimeRecordsParent(rowData['RecurringInvoiceHeaderID'], true, true);

          this.selectedData['rowCount'] += 1;
          rowData['IsNew'] = false;
        },
        err => {
          this.mangoAPISrvc.showLoader(false);
          this.mangoAPISrvc.notify(
            'error',
            this.translate.instant('error'),
            AppConstants.createErrorMsg
          );
        }
      );
    } else {
      this.mangoAPISrvc.showLoader(true);
      this.mangoAPISrvc
        .updateRecuringInvoiceExpense(rowData['RecurringInvoiceExpenseID'], rowData)
        .subscribe(
          data => {
            if (rowData['isAmountChanged']) {
              this.isDetailAmountChanged = true;
            }
            this.clonedData = {};
            this.computeExpenseFooter(true);
            this.mangoAPISrvc.showLoader(false);
            this.mangoAPISrvc.notify(
              'success',
              this.translate.instant('Success'),
              this.translate.instant('notification_updated')
            );

            this.getTimeRecordsParent(rowData['RecurringInvoiceHeaderID'], true, true);

            rowData['IsNew'] = false;
            rowData['IsEdited'] = false;
          },
          err => {
            this.mangoAPISrvc.showLoader(false);
            this.mangoAPISrvc.notify(
              'error',
              this.translate.instant('error'),
              AppConstants.updateErrorMsg
            );
          }
        );
    }
  }

  saveRecurringInvoiceDetail(rowData, rowIndex, dt, isReleaseTime?) {
    if ((isReleaseTime && rowData['IsNew']) || !this.recurringInvoiceDetailValidation(rowData)) {
      this.onRowEditInit1(rowIndex, dt, rowData);

      return;
    }

    this.selectedDetails = this.recurringInvoiceDetails;

    this.computeWriteUpValue(rowData);

    const rd = { ...rowData };

    if (rowData['IsNew']) {
      this.mangoAPISrvc.showLoader(true);

      this.mangoAPISrvc.createRecuringInvoiceDetail(rd).subscribe(
        async (data: any) => {
          this.isDetailAmountChanged = true;

          rowData['RecurringInvoiceDetailID'] = data.data['RecurringInvoiceDetailID'];

          if (rowData['isAmountChanged']) {
            const salesTax = await this.getSalesTaxObj(
              this.selectedData['RecurringInvoiceHeaderID'],
              this.selectedData['ClientID']
            );

            this.selectedData['SalesTax'] = salesTax['serviceTax'] ? salesTax['serviceTax'] : 0;

            this.computeHeaderTotalSalesTax();
          }

          this.clonedData = {};

          this.mangoAPISrvc.showLoader(false);

          this.mangoAPISrvc.notify(
            'success',
            this.translate.instant('Success'),
            this.translate.instant('notification_created')
          );

          this.getTimeRecordsParent(rowData['RecurringInvoiceHeaderID'], true, true);

          this.selectedData['rowCount'] += 1;

          rowData['IsNew'] = false;

          rowData['IsEdited'] = false;

          this.reOrderLineItems();
        },
        error => {
          this.mangoAPISrvc.showLoader(false);

          this.mangoAPISrvc.notify(
            'error',
            this.translate.instant('error'),
            AppConstants.createErrorMsg
          );
        }
      );
    } else {
      this.mangoAPISrvc.showLoader(true);
      this.mangoAPISrvc
        .updateRecuringInvoiceDetail(rowData['RecurringInvoiceDetailID'], rd)
        .subscribe(
          async data => {
            if (rowData['isAmountChanged']) {
              this.isDetailAmountChanged = true;

              const salesTax = await this.getSalesTaxObj(
                this.selectedData['RecurringInvoiceHeaderID'],
                this.selectedData['ClientID']
              );

              this.selectedData['SalesTax'] = salesTax['serviceTax'] ? salesTax['serviceTax'] : 0;

              this.computeHeaderTotalSalesTax();
            }

            this.clonedData = {};

            const recurrInvoiceHeaderID = rowData['RecurringInvoiceHeaderID'];

            const recurrInvoiceDetailID = rowData['RecurringInvoiceDetailID'];

            if (
              rowData['releaseDateChanged'] &&
              this.timeRecordsByHeaderIDAndDetailID[recurrInvoiceHeaderID] &&
              this.timeRecordsByHeaderIDAndDetailID[recurrInvoiceHeaderID][recurrInvoiceDetailID]
            ) {
              this.timeRecordsByHeaderIDAndDetailID[recurrInvoiceHeaderID][recurrInvoiceDetailID] =
                null;
            }

            this.getTimeRecordsParent(recurrInvoiceHeaderID, true, true);

            this.mangoAPISrvc.showLoader(false);

            this.mangoAPISrvc.notify(
              'success',
              this.translate.instant('Success'),
              this.translate.instant('notification_updated')
            );

            rowData['IsNew'] = false;

            rowData['IsEdited'] = false;
          },
          error => {
            this.mangoAPISrvc.showLoader(false);

            this.mangoAPISrvc.notify(
              'error',
              this.translate.instant('error'),
              AppConstants.updateErrorMsg
            );
          }
        );
    }
  }

  processSavingRecord(
    itemData: any,
    dt?,
    rowIndex?,
    notify = true,
    isRetainer?,
    updateFromDetail?,

    updateDetailStatus?
  ) {
    if ((isRetainer && itemData['isNew']) || !this.recurringInvoiceHeaderValidation(itemData)) {
      this.onRowEditInit1(rowIndex, dt, itemData);

      return;
    }

    itemData['IsRowEditing'] = false;

    if (!updateFromDetail) {
      itemData['ScheduledDate'] = new Date(itemData['ScheduledDate']);
    }

    itemData[ 'isRecurringAllowed' ] = (
      (itemData[ 'ScheduledDate' ]?moment(itemData[ 'ScheduledDate' ]).format("MM/DD/YYYY"):moment().format("MM/DD/YYYY")) >= moment().format("MM/DD/YYYY")
    );

    if (itemData['isNew']) {
      itemData['CompanyID'] = itemData['CompanyID']
        ? itemData['CompanyID']
        : this.encrDecSrvc.getObject(AppConstants.companyID);

      this.recurringInvoiceHeaderValidation(itemData);

      if (notify) {
        this.mangoAPISrvc.showLoader(true);
      }

      itemData['ScheduledDate'] = itemData['ScheduledDate']
        ? this.mangoUtils.formatDateWithoutTime(itemData['ScheduledDate'])
        : itemData['ScheduledDate'];
      this.mangoAPISrvc.createInvoiceHeader(itemData).subscribe(
        (data: any) => {
          this.mangoAPISrvc.showLoader(false);

          if (data.message) {
            this.clonedData = {};

            itemData['RecurringInvoiceHeaderID'] = data.data['RecurringInvoiceHeaderID'];

            if (!updateFromDetail) {
              itemData['ScheduledDate'] = new Date(itemData['ScheduledDate']).toISOString();
            }

            itemData['InvoiceTemplate'] = data.data['InvoiceTemplate'];

            itemData['isNew'] = false;

            itemData['IsColumnChanges'] = false;

            this.verifyNewRecordPresents();

            if (notify) {
              this.mangoAPISrvc.notify(
                'success',
                this.translate.instant('Success'),
                this.translate.instant('notification_created')
              );
            }

            this.openViewPanel(itemData);
          } else {
            this.mangoAPISrvc.notify(
              'error',
              this.translate.instant('error'),
              AppConstants.createErrorMsg
            );
          }
        },
        error => {
          this.mangoAPISrvc.showLoader(false);

          if (notify) {
            this.mangoAPISrvc.notify(
              'error',
              this.translate.instant('error'),
              AppConstants.createErrorMsg
            );
          }
        }
      );
    } else {
      if (notify) {
        this.mangoAPISrvc.showLoader(true);
      }

      itemData['ScheduledDate'] = itemData['ScheduledDate']
        ? this.mangoUtils.formatDateWithoutTime(itemData['ScheduledDate'])
        : itemData['ScheduledDate'];
      this.mangoAPISrvc
        .updateRecurringInvoiceHeader(
          itemData['RecurringInvoiceHeaderID'],
          itemData,

          updateDetailStatus
        )
        .subscribe(
          data => {
            if (!updateFromDetail) {
              itemData['ScheduledDate'] = new Date(itemData['ScheduledDate']);
            }

            if (updateFromDetail && (this.isDetailAmountChanged || this.isFromDelete)) {
              this.updateScheduledInvoice(itemData, false);

              this.isDetailAmountChanged = false;

              this.isFromDelete = false;
            }

            this.clonedData = {};

            this.mangoAPISrvc.showLoader(false);

            itemData['isNew'] = false;
            itemData['IsEdited'] = false;

            if (notify) {
              this.mangoAPISrvc.notify(
                'success',
                this.translate.instant('Success'),
                this.translate.instant('notification_updated')
              );
            }
          },
          error => {
            this.mangoAPISrvc.showLoader(false);

            if (notify) {
              this.mangoAPISrvc.notify('error', this.translate.instant('error'), error);
            }
          }
        );
    }
  }

  saveRecurringInvoiceHeader(
    itemData: any,
    dt?,
    rowIndex?,
    notify = true,
    isRetainer?,
    updateFromDetail?
  ) {
    this.getTimeRecordsParent(itemData['RecurringInvoiceHeaderID'], false, false, () => {
      if (itemData['schedule_id'] && !updateFromDetail) {
        Swal.fire({
          title: this.translate.instant('confirmation'),
          text: this.translate.instant('recurring-payment-msg'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.translate.instant('yes_continue'),
          cancelButtonText: this.translate.instant('no_cancel')
        }).then(result => {
          if (result.value) {
            if (
              itemData.isScheduledDateChanged === true &&
              this.recurringInvoiceDetails.some(rowData => rowData['isReleaseTime']) === true
            ) {
              Swal.fire({
                title: this.translate.instant('confirmation'),
                text: this.translate.instant('recurringDate-change'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.translate.instant('yes_update'),
                cancelButtonText: this.translate.instant('no_dont_update')
              }).then(result => {
                if (result.value) {
                  this.updateScheduledInvoice(itemData, false);

                  setTimeout(() => {
                    this.processSavingRecord(
                      itemData,
                      dt,
                      rowIndex,
                      notify,
                      isRetainer,
                      updateFromDetail
                    );

                    itemData.isScheduledDateChanged = false;

                    itemData['backup-ScheduledDate'] = new Date(itemData['ScheduledDate']);
                  }, 50);
                } else {
                  this.updateScheduledInvoice(itemData, false);

                  setTimeout(() => {
                    this.processSavingRecord(
                      itemData,
                      dt,
                      rowIndex,
                      notify,
                      isRetainer,
                      updateFromDetail,

                      false
                    );

                    itemData.isScheduledDateChanged = false;
                  }, 50);
                }
              });
            } else {
              this.updateScheduledInvoice(itemData, false);

              setTimeout(() => {
                this.processSavingRecord(
                  itemData,
                  dt,
                  rowIndex,
                  (notify = true),
                  isRetainer,
                  updateFromDetail
                );

                if (itemData.isScheduledDateChanged === true) {
                  itemData.isScheduledDateChanged = false;

                  itemData['backup-ScheduledDate'] = new Date(itemData['ScheduledDate']);
                }
              }, 50);
            }
          }
        });
      } else {
        if (
          itemData.isScheduledDateChanged === true &&
          this.recurringInvoiceDetails.some(rowData => rowData['isReleaseTime']) === true
        ) {
          Swal.fire({
            title: this.translate.instant('confirmation'),
            text: this.translate.instant('recurringDate-change'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: this.translate.instant('yes_update'),
            cancelButtonText: this.translate.instant('no_dont_update')
          }).then(result => {
            if (result.value) {
              this.processSavingRecord(
                itemData,
                dt,
                rowIndex,
                notify,
                isRetainer,
                updateFromDetail
              );
            } else {
              this.processSavingRecord(
                itemData,
                dt,
                rowIndex,
                notify,
                isRetainer,
                updateFromDetail,

                false
              );
            }

            itemData.isScheduledDateChanged = false;

            itemData['backup-ScheduledDate'] = new Date(itemData['ScheduledDate']);
          });
        } else {
          this.processSavingRecord(
            itemData,
            dt,
            rowIndex,
            notify,
            isRetainer,
            updateFromDetail,

            false
          );

          if (itemData.isScheduledDateChanged === true) {
            itemData.isScheduledDateChanged = false;

            itemData['backup-ScheduledDate'] = new Date(itemData['ScheduledDate']);
          }
        }
      }
    });
  }

  recurringInvoiceChange(rowData) {
    this.selectedData['IsColumnChanges'] = true;
    if (!rowData['IsNew']) rowData['IsEdited'] = true;
  }

  releaseDateChange(rowData) {
    rowData['releaseDateChanged'] = true;
  }

  addExpenseRow(dt) {
    if (this.recurringInvoiceExpenses.filter(item => item['IsNew']).length > 0) {
      this.mangoAPISrvc.notify(
        'error',
        this.translate.instant('error'),
        this.translate.instant('cant-add-please-save')
      );
      return;
    }
    this.selectedData['AddedRow'] = true;
    const obj = {
      IsNew: true,
      ClientID: this.selectedData['ClientID'],
      RecurringInvoiceHeaderID: this.selectedData['RecurringInvoiceHeaderID'],
      ProjectMasterID: null,
      CompanyID: this.selectedData['CompanyID'],
      ExpenseCodeID: null,
      Units: null,
      Cost: null,
      Amount: null,
      RecurringInvoiceExpenseID: null
    };
    this.recurringInvoiceExpenses.push(obj);
    this.onRowEditInit1(null, dt, obj);

    setTimeout(() => {
      this.scrollDownToNewRow('new-row3');
    }, 500);
  }

  addDetailRow(dt) {
    if (this.recurringInvoiceDetails.filter(item => item['IsNew']).length > 0) {
      this.mangoAPISrvc.notify(
        'error',
        this.translate.instant('error'),
        this.translate.instant('cant-add-please-save')
      );
      return;
    }

    this.selectedData['ScheduledDate'] = new Date(this.selectedData['ScheduledDate']);

    this.selectedData['AddedRow'] = true;
    const obj = {
      IsNew: true,
      ClientID: this.selectedData['ClientID'],
      RecurringInvoiceHeaderID: this.selectedData['RecurringInvoiceHeaderID'],
      ProjectMasterID: null,
      CompanyID: this.selectedData['CompanyID'],
      ServiceCodeID: null,
      ReleaseDate: this.selectedData['ScheduledDate'],
      InvoiceDescription: null,
      Amount: null,
      RecurringInvoiceDetailID: null
    };
    this.recurringInvoiceDetails.push(obj);
    this.onRowEditInit1(null, dt, obj);

    setTimeout(() => {
      this.scrollDownToNewRow('new-row2');
    }, 500);
  }

  setWriteUpDownColor(data) {
    let colorStr = '#1f0c0c';
    if (data < 0) {
      colorStr = '#f28686';
    } else if (data > 0) {
      colorStr = '#33c126';
    }
    return colorStr;
  }

  cancelRecurringInvoiceSideBar() {
    if (
      this.recurringInvoiceDetails.filter(item => item['IsNew'] || item['IsEdited']).length > 0 ||
      this.recurringInvoiceExpenses.filter(item => item['IsNew'] || item['IsEdited']).length > 0
    ) {
      Swal.fire({
        customClass: {
          container: 'my-swal'
        },
        title: this.translate.instant('warning'),
        html: this.translate.instant('invoicing.unsaved_changes'),
        icon: 'warning',
        showCancelButton: true,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: this.translate.instant('Yes'),
        cancelButtonText: this.translate.instant('no_cancel')
      }).then(result => {
        if (result.value) {
          this.sidePanelDisplay = true;
        } else {
          this.resetRecurringInvoiceSideBarState();
        }
      });
    } else {
      this.resetRecurringInvoiceSideBarState();
    }
  }

  resetRecurringInvoiceSideBarState() {
    this.sidePanelDisplay = false;
    this.recurringInvoiceDetails = [];
    this.recurringInvoiceExpenses = [];
    this.timeRecordsFooter = {
      totalTime: 0,
      nonbillableamount: 0,
      laboramount: 0
    };
    this.parentDetailFooter = {
      totalTime: 0,
      nonbillableamount: 0,
      laboramount: 0,
      wuwd: 0,
      amount: 0
    };
    this.parentExpenseFooter = {
      amount: 0
    };
  }

  cancelTimeRecordsSideBar() {
    this.timeRecordsDisplay = false;
    this.selectedEngagment = null;
    this.recurringInvoiceOpenTimeRecords = [];
  }

  openViewPanel(rowData) {
    this.mangoAPISrvc.showLoader(true);
    this.selectedData = rowData;
    rowData['IsColumnChanges'] = false;
    if(!this.selectedData['FinalizeAction']&&this.finalizeActions) this.selectedData['FinalizeAction'] = this.finalizeActions[0]?.value;
    this.sidePanelDisplay = true;
    const headerId = this.selectedData['RecurringInvoiceHeaderID'];

    this.getTimeRecordsParent(headerId);

    if (rowData['RuleString']) {
      this.updateRecurringPaymentProperty(rowData);
    }
  }

  updateRecurringPaymentProperty(rowData) {
    this.defaultStaxObj = {
      SurChargeAmount: 0,
      withSurcharge: 0,
      rule: null,
      customer_id: null,
      total: null,
      meta: { tax: 0, subtotal: null, otherField1: null, otherField2: null, reference: null },
      url: '',
      email_notification: false,
      payment_method_id: null
    };
    this.currentRuleStr = rowData['RuleString'] ? rowData['RuleString'] : '';
    this.selectedCustomer = this.clientList.filter(
      client => client.ClientID === rowData['ClientID']
    )[0];

    this.defaultStaxObj = { "SurChargeAmount": 0, "withSurcharge": 0, "rule": null, "customer_id": null, "total": null, "meta": { "tax": 0, "subtotal": null, "otherField1": null, "otherField2": null, "reference": null }, "url": "", "email_notification": false, "payment_method_id": null };
    this.currentRuleStr = rowData["RuleString"] ? rowData["RuleString"] : '';
    this.selectedCustomer = this.clientList.filter((client) => client.ClientID === rowData["ClientID"])[0];

    this.defaultStaxObj['url'] = `${this.recurringUrl}/api/Stax/recurringPaymentCallback/${this.companyDetails.CompanyID}`;
    this.defaultStaxObj['total'] = numeral(rowData['InvoiceAmount']).value();
    this.defaultStaxObj['meta']['reference'] = this.mangoUtils.generateUUID() + '$*@^$RP';
    this.defaultStaxObj['meta']['subtotal'] = numeral(rowData['InvoiceAmount']).value();
    this.defaultStaxObj['meta']['otherField1'] =
      'from Recurring Invoice | User : ' +
      this.userName +
      ' | ClientName :' +
      this.selectedCustomer['ClientName'];
    this.defaultStaxObj['meta']['otherField2'] = rowData.RecurringInvoiceHeaderID;
    this.defaultStaxObj['customer_id'] = null;
    this.defaultStaxObj['payment_method_id'] = null;
    this.defaultStaxObj.rule = this.currentRuleStr;
    this.mangoAPISrvc.showLoader(true);
    rowData['count'] = numeral(
      rowData['RuleString'].substring(
        rowData['RuleString'].indexOf('COUNT=') + 6,
        rowData['RuleString'].indexOf(';INTERVAL=')
      )
    ).value();
    this.recurringRowData = rowData;

    this.mangoAPISrvc.getPaymentMethodInfo(rowData.ClientID).subscribe(
      (data: any) => {
        this.cards = data;
        let card = data.filter(item => item.spmid == `${rowData.SelectedRecurringPaymentOption}`);
        this.selectedModeOfPayment = card && card.length > 0 ? card[0] : null;
        if (this.selectedModeOfPayment && this.selectedModeOfPayment['spmid']) {
          this.sTaxProfile = this.selectedModeOfPayment;
          this.defaultStaxObj['customer_id'] = this.selectedModeOfPayment['scid'];
          this.defaultStaxObj['payment_method_id'] = this.selectedModeOfPayment['spmid'];
        }
        this.mangoAPISrvc.showLoader(false);
      },
      error => {
        this.mangoAPISrvc.notify(
          'error',
          this.translate.instant('error'),
          AppConstants.crcardErrorMsg
        );
        this.mangoAPISrvc.showLoader(false);
      }
    );
  }

  openTimeRecords(rowData) {
    if (rowData['laboramount'] == null && rowData['nonbillableamount'] == null) {
      this.mangoAPISrvc.notify(
        'error',
        this.translate.instant('error'),
        this.translate.instant('no_time_records')
      );
      return;
    }
    this.selectedEngagment = rowData;
    this.timeRecordsDisplay = true;
    this.mangoAPISrvc.showLoader(true);
    this.getTimeRecordsByEngagementId(
      rowData['RecurringInvoiceHeaderID'],
      rowData['CompanyID'],
      rowData['ProjectMasterID'],
      rowData['RecurringInvoiceDetailID']
    );
  }

  onShowScratchpad(data) {
    this.editableData = data;
    this.scratchPadEnabled = true;
  }

  onScratchpadChange() {
    this.timeRecordsDt.editingRowKeys = {};
    this.timeRecordsDt.editingRowKeys[this.editableData?.SlipMasterID] = true;
  }

  onScratchpadClose() {
    this.scratchPadEnabled = false;
    this.editableData = null;
  }

  getTimeRecordsByEngagementId(headerId, companyId, projectMasterId, recurringInvoiceDetailId) {
    this.mangoAPISrvc
      .getRecurringInvoiceEngagmentTimeRecords(
        headerId,
        companyId,
        projectMasterId,
        recurringInvoiceDetailId
      )
      .subscribe((data: any = []) => {
        data = data.map(function (obj) {
          obj['scdescr'] = obj?.Description || obj?.ActivityDescription;
          return obj;
        });
        this.recurringInvoiceOpenTimeRecords = data.filter(item => item['IsTimeRecord'] == 'T');
        if (
          !this.recurringInvoiceOpenTimeRecords ||
          this.recurringInvoiceOpenTimeRecords?.length == 0
        ) {
          this.mangoAPISrvc.notify(
            'error',
            this.translate.instant('error'),
            this.translate.instant('no_time_records')
          );
          this.timeRecordsDisplay = false;
          return;
        }
        this.reloadPreSelectedTimeRecords(
          headerId,
          recurringInvoiceDetailId,
          this.recurringInvoiceOpenTimeRecords
        );
        this.computeTimeRecordsFooter(headerId, recurringInvoiceDetailId);
        this.mangoAPISrvc.showLoader(false);
      });
  }

  reloadPreSelectedTimeRecords(headerId, detailId, replacement) {
    //check if there are pre-saved time records. if there are, then get the values from that.
    if (
      this.timeRecordsByHeaderIDAndDetailID[headerId] &&
      this.timeRecordsByHeaderIDAndDetailID[headerId][detailId]
    ) {
      this.selectedTimeRecords = this.timeRecordsByHeaderIDAndDetailID[headerId][detailId];
    } else {
      const obj = this.timeRecordsByHeaderIDAndDetailID[headerId] || {};
      this.selectedTimeRecords = replacement;
      obj[detailId] = this.selectedTimeRecords;
      this.timeRecordsByHeaderIDAndDetailID[headerId] = obj;
    }
  }

  reloadPreSelectedDetails(headerId, newRecord) {
    //reload pre selected recurring invoice details
    if (!this.detailsByHeaderID[headerId] || newRecord) {
      this.selectedDetails = this.recurringInvoiceDetails.filter(detail =>
        Number(detail['Amount'] != 0)
      );
      this.detailsByHeaderID[headerId] = this.selectedDetails;
    } else {
      this.selectedDetails = this.detailsByHeaderID[headerId];
      this.selectedDetails = this.detailsByHeaderID[headerId].map(item => {
        const find = this.recurringInvoiceDetails.find(item2 => item['RecurringInvoiceDetailID'] == item2['RecurringInvoiceDetailID']);
        if (find) {
          item = find;
        }
        return item;
      });
    }
  }

  reloadPreSelectedExpenses(headerId, newRecord) {
    //reload pre selected recurring invoice details
    if (!this.expensesByHeaderID[headerId] || newRecord) {
      this.selectedExpenses = this.recurringInvoiceExpenses.filter(expense =>
        Number(expense['Amount'] != 0)
      );

      this.expensesByHeaderID[headerId] = this.selectedExpenses;
    } else {
      this.selectedExpenses = this.expensesByHeaderID[headerId];
    }
  }

  async getSalesTaxObj(headerId, clientID?) {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    return new Promise(function (resolve, reject) {
      try {
        if (clientID) {
          parent.mangoAPISrvc.getSalesTaxByRecurringHeaderID(parent.companyId, clientID, headerId).subscribe((result) => { parent.mangoAPISrvc.showLoader(false); resolve(result) })
          parent.computeHeaderTotalSalesTax()
        } else {
          parent.mangoAPISrvc
            .getSalesTaxByRecurringHeaderID(
              parent.companyId,
              parent.clientProfile.ClientID,
              headerId
            )
            .subscribe(result => {
              parent.mangoAPISrvc.showLoader(false);
              resolve(result);
            });
        }
      } catch (error) {
        parent.mangoAPISrvc.showLoader(false);
        reject(error);
      }
    });
  }

  async getScheduledInvoice(scheduleId) {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    return new Promise(function (resolve, reject) {
      try {
        parent.mangoAPISrvc
          .performStaxActions({ scheduleId: scheduleId }, parent.companyDetails.CompanyID, 'gsi')
          .subscribe(result => {
            parent.mangoAPISrvc.showLoader(false);
            resolve(result);
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  async createStaxCustomer(customerObj) {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    return new Promise(function (resolve, reject) {
      parent.mangoAPISrvc
        .performStaxActions(
          {
            data: customerObj
          },
          parent.companyDetails.CompanyID,
          'cc'
        )
        .subscribe(
          result => {
            parent.mangoAPISrvc.showLoader(false);
            resolve(result);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  async updateCardDetails(customerCardID, obj) {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    return new Promise(function (resolve, reject) {
      try {
        parent.mangoAPISrvc.updateCreditCardDetails(customerCardID, obj).subscribe(result => {
          parent.mangoAPISrvc.showLoader(false);
          resolve(result);
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  async getCardDetails(clientId) {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    return new Promise(function (resolve, reject) {
      try {
        parent.mangoAPISrvc.getPaymentMethodInfo(clientId).subscribe(result => {
          parent.mangoAPISrvc.showLoader(false);
          resolve(result);
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  getTimeRecordsParent(
    headerId,
    updateHeaderInvoiceAmount?,
    newRecord?,
    callback?
  ){
    const _this = this;
    if (headerId) {
      this.mangoAPISrvc.showLoader(true);

      forkJoin([
        this.mangoAPISrvc.getRecurringInvoiceDetailsTimeRecordsParent(headerId, this.companyId),
        this.mangoAPISrvc.getRecurringInvoiceExpensesByHeaderId(headerId)
      ]).subscribe(data => {
        _this.mangoAPISrvc.showLoader(false);

        const projectMasterIDArray = [];

        //recurring invoice details
        _this.recurringInvoiceDetails = data[0];

        _this.recurringInvoiceDetails = _this.recurringInvoiceDetails.map((item) => {
          if(
            item[ "ReleaseDate" ]
          ){
            item[ "ReleaseDate" ] = (
              new Date(
                    (
                      moment( item[ "ReleaseDate" ], "YYYY-MM-DD" )
                      .format( "YYYY-MM-DDT00:00:00" )
                      .toString( )
                    )
                  )
            );
          }

          if (item["BillingMethod"] === "Fixed Fee") {
            item["Amount"] = item["Amount"] || item["FlatFeeAmount"] || "0";
          } else {
            item['Amount'] = item['Amount'] || item['laboramount'] || '0';
          }

          item["Amount"] = item["Amount"]?.replace("$", "").replace(",", "");

          if (projectMasterIDArray.includes(item["ProjectMasterID"])) {
            item["ProjectMasterDuplicate"] = true;
          } else {
            projectMasterIDArray.push(item['ProjectMasterID']);
          }

          _this.reloadPreSelectedTimeRecords(headerId, item["RecurringInvoiceDetailID"], null);

          if (this.selectedTimeRecords) {
            this.computeTimeRecordsFooter(headerId, item["RecurringInvoiceDetailID"]);

            this.setDetailRowAmounts(item);
          }

          _this.computeWriteUpValue(item);

          item["nonbillableamount"] = numeral(item["nonbillableamount"]).value();

          item["laboramount"] = numeral(item["laboramount"]).value();

          return item;
        });

        _this.reloadPreSelectedDetails(headerId, newRecord);

        _this.computeDetailFooter(updateHeaderInvoiceAmount);

        //recurring invoice expenses
        _this.recurringInvoiceExpenses = data[1];

        _this.recurringInvoiceExpenses = _this.recurringInvoiceExpenses.map((item) => {
          item["Cost"] = _this.parseStringNumber(item["Cost"]);

          item["Amount"] = _this.parseStringNumber(item["Amount"]);

          return item;
        });

        _this.reloadPreSelectedExpenses(headerId, newRecord);

        _this.computeExpenseFooter(updateHeaderInvoiceAmount);

        _this.selectedData["rowCount"] = _this.selectedDetails.length + _this.selectedExpenses.length;

        if (typeof callback == "function") {
          callback();
        }
      });
    } else callback();
  }

  computeWriteUpValue(rowData) {
    if (
      !this.selectedData['isRetainer'] &&
      rowData['isReleaseTime'] &&
      !rowData['ProjectMasterDuplicate']
    ) {
      rowData['writeupValue'] = rowData['Amount'] - rowData['laboramount'];
    } else {
      rowData['writeupValue'] = rowData['Amount'];
    }
  }

  computeExpenseAmount(rowData) {
    rowData['isAmountChanged'] = true;
    if (rowData['Units'] && rowData['Cost']) {
      rowData['Amount'] = rowData['Units'] * rowData['Cost'];
    } else {
      rowData['Amount'] = 0;
    }
  }

  toggleReleaseTime(rowData) {
    if (rowData['isReleaseTime'] && !rowData['ReleaseDate']) {
      this.mangoAPISrvc.notify(
        'error',
        this.translate.instant('error'),
        'Cannot enable release time if release thru date is null.'
      );

      rowData['isReleaseTime'] = false;

      return;
    }

    this.computeWriteUpValue(rowData);

    this.computeDetailFooter();

    this.saveRecurringInvoiceDetail(
      rowData,
      rowData['RecurringInvoiceDetailID'],
      this.dtDetails,
      true
    );
  }

  computeDetailFooter(updateHeader?) {
    let totalTime = 0;

    let nonbillableamount = 0;

    let laboramount = 0;

    let wuwd = 0;

    let amount = 0;

    this.selectedDetails.forEach(item => {
      if (
        !this.selectedData['isRetainer'] &&
        item['isReleaseTime'] &&
        !item['ProjectMasterDuplicate']
      ) {
        totalTime += Number(item['totaltime'] || 0);

        nonbillableamount += Number(item['nonbillableamount'] || 0);

        laboramount += Number(item['laboramount'] || 0);
      }

      wuwd += Number(item['writeupValue'] || 0);

      amount += Number(item['Amount'] || 0);
    });

    this.parentDetailFooter = {
      totalTime,
      nonbillableamount,
      laboramount,
      wuwd,
      amount
    };

    if (updateHeader) {
      this.updateHeaderInvoiceAmount(false);
    }
  }

  computeExpenseFooter(updateHeader?) {
    let amount = 0;

    this.selectedExpenses.forEach(item => {
      amount += Number(item['Amount']) || 0;
    });

    this.parentExpenseFooter = {
      amount
    };

    if (updateHeader) {
      this.updateHeaderInvoiceAmount(false);
    }
  }

  updateHeaderInvoiceAmount(notify, save?) {
    this.selectedData['InvoiceAmount'] =
      this.parentDetailFooter['amount'] + this.parentExpenseFooter['amount'];

    this.computeHeaderTotalInvoiceAmount();

    this.computeHeaderTotalSalesTax();

    this.saveRecurringInvoiceHeader(
      this.selectedData,
      this.dt,
      this.selectedData['RecurringInvoiceHeaderID'],
      notify,
      false,
      true
    );
  }

  computeTimeRecordsFooter(headerId, detailId) {
    let totalTime = 0;
    let nonbillableamount = 0;
    let laboramount = 0;
    if (this.recurringInvoiceOpenTimeRecords.length > 0 && this.timeRecordsByHeaderIDAndDetailID[headerId] && this.timeRecordsByHeaderIDAndDetailID[headerId][detailId]) {
      this.selectedTimeRecords = this.timeRecordsByHeaderIDAndDetailID[headerId][detailId]
        .map(selectedTimeRecord => {
          const originalRecord = this.recurringInvoiceOpenTimeRecords.filter(item => item['SlipMasterID'] == selectedTimeRecord['SlipMasterID']);
          if (originalRecord.length == 1) {
            return originalRecord[0];
          }
        }
      );
    }
    this.selectedTimeRecords.forEach(item => {
      totalTime += Number(item['TotalTime']);
      if (item['Billable']) {
        laboramount += Number(item['StandardAmount']);
      } else {
        nonbillableamount += Number(item['StandardAmount']);
      }
    });
    this.timeRecordsFooter = {
      totalTime,
      nonbillableamount,
      laboramount
    };
  }

  updateTimeRecord(rowData) {
    const _this = this;
    rowData.StandardAmount = rowData.StandardAmount
      ? numeral(rowData.StandardAmount).value()
      : 0.0;
    const obj = {
      Ddate: moment(rowData.Ddate).format("MM/DD/YYYY"),
      Approved: rowData.Approved,
      Billable: rowData.Billable,
      Billed: rowData.Billed ? rowData.Billed : false,
      BillingRate: numeral(rowData.BillingRate).value().toString(),
      ClientID: rowData.ClientID,
      ClientName: rowData.ClientName,
      ElaspedTime: rowData.TotalTime,
      Description: rowData.Description,
      Memo: rowData.Memo,
      nonbillableamount: rowData.nonbillableamount,
      ServiceCode: rowData.ServiceCode,
      ServiceCodeID: rowData.ServiceCodeID,
      StandardAmount: rowData.StandardAmount.toString(),
      TotalTime: rowData.TotalTime,
      StaffID: rowData.StaffID,
      StaffName: rowData.StaffName,
      StaffDeptID: rowData.StaffDeptID,
      StaffPayRate: rowData.StaffPayRate,
      StaffCost: Number(rowData.TotalTime) * Number(rowData.StaffPayRate),
      WorkCodeID: rowData.WorkCodeID,
      OriginatingPartnerID: rowData.OriginatingPartnerID,
      BillingPartnerID: rowData.BillingPartnerID,
      GroupDescriptionID: rowData.GroupDescriptionID,
      GroupDescriptionIDArray: rowData.GroupDescriptionIDArray,
      ClientTypeID: rowData.ClientTypeID,
      ProjectMasterID: rowData.ProjectMasterID,
      PrivateMemo: rowData.PrivateMemo,
      EngagementTypeID: rowData.EngagementTypeID,
      IsTimeRecord: 'T',
      WorkLocation: rowData.WorkLocation,
      isFlatFee: rowData.isFlatFee
    };
    _this.mangoAPISrvc.showLoader(true);
    _this.mangoAPISrvc.updateTimeSheet(rowData.SlipMasterID, obj).subscribe(
      function (res) {
        _this.mangoAPISrvc
          .sendBudgetAlert({
            ClientID: obj['ClientID'],
            ProjectMasterID: obj['ProjectMasterID'],
            CompanyID: _this.loginCompanyId,
            Ddate: moment(obj.Ddate).format('YYYY-MM-DD')
          })
          .subscribe(
            data => {},
            err => {
              console.log(err);
            }
          );
        _this.mangoAPISrvc.notify('success', 'Updated!', AppConstants.updateMsg);
        _this.mangoAPISrvc.showLoader(false);
        _this.computeTimeRecordsFooter(
          rowData['RecurringInvoiceHeaderID'],
          rowData['RecurringInvoiceDetailID']
        );
        _this.setDetailRowAmounts(_this.selectedEngagment);
        _this.computeWriteUpValue(_this.selectedEngagment);
        _this.computeDetailFooter();
      },
      err => {
        _this.mangoAPISrvc.showLoader(false);
        _this.mangoAPISrvc.notify(
          'error',
          this.translate.instant('error'),
          AppConstants.updateErrorMsg
        );
      }
    );
  }

  onBillRateChange(evt, data) {
    const newValue = evt.target.value ? evt.target.value.replace('$', '').replace(',', '') : null;

    if (newValue === null) return;

    data['BillingRate'] = newValue;
    data = this.calculateStandardAmount(data);
    // this.calculateBilledAmtWuwd(data);
  }

  onTotalTimeChange(evt, data) {
    /* const newValue = evt.target.value ? evt.target.value.replace(",", "") : null;
    if (newValue === null)
      return; */
    let newValue = evt;
    if (evt == null) {
      newValue = 0;
      return false;
    } else if (evt == '.') {
      return false;
    }

    data['TotalTime'] = newValue ? numeral(newValue).value() : 0;

    data = this.calculateStandardAmount(data);
    // this.calculateBilledAmtWuwd(data);
  }

  calculateStandardAmount(data) {
    if (data['BillingRate']) {
      if (data['Billable']) {
        data['billableamount'] = (
          parseFloat(data['TotalTime']) * parseFloat(data['BillingRate'])
        ).toString();
        data['StandardAmount'] = data['billableamount'];
      } else {
        data['nonbillableamount'] = (
          parseFloat(data['TotalTime']) * parseFloat(data['BillingRate'])
        ).toString();
        data['StandardAmount'] = data['nonbillableamount'];
      }
    }

    return data;
  }

  ngOnInit(): void {
    this.companyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.invoiceDate =
      this.encrDecSrvc.getObject(AppConstants.recurringLastInvoiceDate) != null &&
      this.encrDecSrvc.getObject(AppConstants.recurringLastInvoiceDate) != ''
        ? new Date(this.encrDecSrvc.getObject(AppConstants.recurringLastInvoiceDate))
        : new Date();
    this.InvoiceStartDate =
      this.encrDecSrvc.getObject(AppConstants.recurringLastDateThru) != null &&
      this.encrDecSrvc.getObject(AppConstants.recurringLastDateThru) != ''
        ? new Date(this.encrDecSrvc.getObject(AppConstants.recurringLastDateThru))
        : new Date();
    this.companySetting = this.encrDecSrvc.getObject(AppConstants.systemLocking);
    this.initDataSetList();
    this.initializeDialogForm();
    this.getCompanyData();
    this.getCompanyLocations();
    this.getActivityGroups();
    this.staffListItems = []; //[{label: this._translate.instant('Choose'), value: null}];
    this.groupsList = []; //[{label: this._translate.instant('Choose'), value: null}];
    this.recurringInvoiceGroupList = [];

    this.eventTimeSheetSubscription = this.sharedSrvc.timeEntryDialogData.subscribe(data => {
      this.refreshTimeRecords();
    });

    this.eventTimeExpenseSubscription = this.sharedSrvc.expenseDialogData.subscribe(data => {
      this.refreshTimeRecords();
    });

    this.intervalid = setInterval(() => {
      this.fetchClients();
    }, 50);
  }

  refreshTimeRecords(): void {
    this.getTimeRecordsParent(this.selectedEngagment['RecurringInvoiceHeaderID'], false, false);

    this.timeRecordsByHeaderIDAndDetailID = {};

    this.getTimeRecordsByEngagementId(
      this.selectedEngagment['RecurringInvoiceHeaderID'],
      this.selectedEngagment['CompanyID'],
      this.selectedEngagment['ProjectMasterID'],
      this.selectedEngagment['RecurringInvoiceDetailID']
    );
  }

  ngOnDestroy(): void {
    this.eventTimeSheetSubscription.unsubscribe();
    this.eventTimeExpenseSubscription.unsubscribe();
  }

  clearSearchFilter() {
    this.searchValue.nativeElement.value = this.searchTextStr = '';
    this.filteredItemsSize = -1;
    this.computeHeaderTotalInvoiceAmount();
    this.computeHeaderTotalSalesTax();
    this.encrDecSrvc.addObject(
      AppConstants.recurringInvoices + '_' + AppConstants.SearchString,
      ''
    );
  }

  clearSearchFilterTime() {
    this.searchValueTime.nativeElement.value = this.searchTextTimeStr = '';
    this.filteredTimeItemsSize = -1;
  }

  onFilterTime(obj) {
    this.filteredTimeItemsSize = obj.filteredValue.length;
  }

  onFilter(obj) {
    this.filteredItemsSize = obj.filteredValue.length;
    const newItems = this.headerListItems.filter(item => item['isNew']);
    if (newItems.length == 1) {
      this.dt.filteredValue.push(newItems[0]);
    }
    this.computeHeaderTotalInvoiceAmount();
    this.computeHeaderTotalSalesTax();
    this.encrDecSrvc.addObject(
      AppConstants.recurringInvoices + '_' + AppConstants.SearchString,
      obj.filters.global.value
    );
  }

  ngAfterViewInit() {
    this.searchValue.nativeElement.focus();
    const parent = this;
    $(".ui-widget-header").click(function (e) {
      parent.stopEdit(e);
    });
  }

  filterGroupsItems(event, data) {
    if (data.EngagementsList && data.EngagementsList.length > 0) {
      this.filteredGroupsSingle = this.filterData(event.query, data.EngagementsList);
    } else {
      this.getProjects(data, event.query, true);
    }
  }

  handleSelectClick(event, itemData) {
    itemData['EngagementName'] = '';
    itemData['ProjectID'] = '';
    if (event && event['ClientID']) {
      itemData['ClientID'] = event['ClientID'];
      itemData['ClientName'] = event['ClientName'];
      itemData.EngagementsList = [];
      this.getProjects(itemData, '', false);
    }
    this.verifyNewRecordPresents();
  }

  getProjects(itemData, serach?: any, isSearch?: boolean) {
    const parent = this;
    parent.groupsList = [];
    parent.engagementListItems = [];

    parent.mangoAPISrvc.showLoader(true);

    parent.mangoAPISrvc.getProjectsByClientId(itemData.ClientID).subscribe(
      function (data: any) {
        parent.mangoAPISrvc.showLoader(false);

        parent.engagementListItems = data.filter(note => note.Inactive == false);

        if (parent.engagementListItems.length === 0) {
          Swal.fire({
            title: parent.translate.instant('Client_Engagements_Required'),
            html: parent.translate.instant('billing.there_are_no_engagements_setup'),
            icon: 'warning',
            showCancelButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            confirmButtonText: parent.translate.instant('Go_to_Client'),
            cancelButtonText: parent.translate.instant('no_cancel')
          }).then(result => {
            if (result.value) {
              parent.encrDecSrvc.addObject(
                AppConstants.selectedClientRecord,
                parent.clientList.filter(client => client.ClientID === itemData['ClientID'])[0]
              );

              parent.encrDecSrvc.addObject(AppConstants.clientID, itemData['ClientID']);
              parent.encrDecSrvc.addObject(AppConstants.ClientName, itemData['ClientName']);
              parent.mangoAPISrvc.showLoader(true);
              parent.mangoAPISrvc.getAllDataCounts(itemData['ClientID']).subscribe(function (data) {
                parent.encrDecSrvc.addObject(AppConstants.allDataCountsForClient, data);
                parent.mangoAPISrvc.fireClientView(true);
                parent.router.navigate([
                  AppConstants.clientRoutePath + '/' + AppConstants.viewRoutePath
                ]);
                //parent.mangoAPISrvc.showLoader(false);
              });
            } else return;
          });
        }

        parent.applySelectedItems(
          parent.engagementListItems,
          parent.groupsList,
          'EngagementName',
          'ProjectMasterID'
        );
        let projectObj = parent.engagementListItems.filter(item => {
          return item.ProjectMasterID == itemData.ProjectID;
        })[0];
        if (projectObj) {
          itemData['EngagementName'] = projectObj['EngagementName'];
        }
        itemData['EngagementsList'] = parent.groupsList.length > 0 ? parent.groupsList : [];
        if (isSearch) {
          serach = serach ? serach : '';
          parent.filteredGroupsSingle = parent.filterData(serach, itemData['EngagementsList']);
        }
      },
      () => {
        parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  initDataSetList() {
    const companyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    const _this = this;
    forkJoin([
      this.mangoAPISrvc.getRecurringHeader1(companyId),
      this.mangoAPISrvc.getInvoiceOption(),
      this.mangoAPISrvc.getInvoiceGroups()
    ]).subscribe(
      (data: any) => {
        this.invoiceList = data[1];
        this.activityList = data[2];
        if (this.auth.isAllowAccess(14)) {
          this.staffList = this.encrDecSrvc.getObject(AppConstants.allStaffList);
        } else {
          this.staffList = this.encrDecSrvc.getObject(AppConstants.staffList);
          this.staffList = this.staffList?.filter(
            staff => staff?.StaffID == this.encrDecSrvc.getObject(AppConstants.staffID)
          );
        }
        this.staffList = this.staffList.filter(staff => !staff.Inactive);

        this.lastInvoice = this.invoiceList.NextInvoiceNumber;

        // adding new properties Label and value
        this.applySelectedItems(
          this.staffList,
          this.staffListItems,
          'StaffName',
          'StaffID',
          'StaffNumber'
        );
        this.applySelectedItems(
          this.activityList,
          this.recurringInvoiceGroupList,
          'RecurringGroupDescription',
          'RecurringInvoiceGroupID'
        );
        // adding new property based on its id .  Ex : client id to Client Name
        this.mangoUtils.setPropertyById(
          this.activityList,
          data[0],
          'RecurringInvoiceGroupID',
          'RecurringGroupDescription',
          'RecurringGroupDescription'
        );
        this.mangoUtils.setPropertyById(
          this.staffList,
          data[0],
          'StaffID',
          'StaffName',
          'StaffName'
        );
        this.headerListItems = data[0].map(function (obj) {
          obj['ScheduledDate'] = obj['ScheduledDate']
            ? new Date(
                moment(obj['ScheduledDate'], 'YYYY-MM-DD').format('YYYY-MM-DDT00:00:00').toString()
              )
            : null;

          obj['backup-ScheduledDate'] = obj['ScheduledDate']
            ? new Date(obj['ScheduledDate'])
            : null;

          obj['backup_SelectedRecurringPaymentOption'] = obj['SelectedRecurringPaymentOption'];
          obj['backup_TransType'] = obj['TransType'];
          obj['backup_StaxPaymentMethodID'] = obj['StaxPaymentMethodID'];
          obj['backup_NameOnCard'] = obj['NameOnCard'];
          obj['backup_LastFourDigits'] = obj['LastFourDigits'];
          obj['isScheduledDateChanged'] = false;
          obj['isNew'] = false;
          obj['InvoiceAmount'] = obj['InvoiceAmount'] || 0;
          obj['rowCount'] = Number(obj['detailsCount']) + Number(obj['expensesCount']);
          obj['FinalizeAction'] = obj['FinalizeAction'] || obj['ClientFinalizeAction'];
          obj['count'] = 99;
          if (obj['RuleString'] && obj['schedule_id']) {
            obj['count'] = numeral(
              obj['RuleString'].substring(
                obj['RuleString'].indexOf('COUNT=') + 6,
                obj['RuleString'].indexOf(';INTERVAL=')
              )
            ).value();
          }
          const schdlDate = obj['ScheduledDate']?moment(obj['ScheduledDate']).format("MM/DD/YYYY"):moment().format("MM/DD/YYYY");
          const currentDate = moment().format("MM/DD/YYYY");
          obj['isRecurringAllowed'] = schdlDate>=currentDate;
          obj['ELProposalID'] = obj['ELProposalID'];
          return obj;
        });

        //this.filteredItemsSize = this.headerListItems.length;
        this.totalAmount = numeral(
          data[0].reduce(function (a, b) {
            return a + +numeral(b['BillAmount']).value();
          }, 0)
        ).format('0,0.00');
        this.headerListItems.sort(function (a, b) {
          if (a.ClientName.toLowerCase() < b.ClientName.toLowerCase()) {
            return -1;
          }
          if (a.ClientName.toLowerCase() > b.ClientName.toLowerCase()) {
            return 1;
          }
          return 0;
        });

        this.mangoAPISrvc
          .getEmailNamesByCompanyID(this.loginCompanyId)
          .subscribe((custContacts: any) => {
            this.headerListItems = this.headerListItems.map(item => {
              if (item.FinalizeAction == 'Email') {
                const custContactsPerClient = custContacts?.filter(
                  contact => contact.ClientID == item.ClientID
                );
                if (custContactsPerClient?.length == 0) {
                  return {
                    ...item,
                    FinalizeAction: 'Print'
                  };
                } else return { ...item };
              } else return { ...item };
            });
          });
        if (this.headerListItems.length == 0) {
          this.showAssignTask = true;
        } else {
          if (this.invoiceList.ActivateLaborRates || this.invoiceList.ActivateExpenseRates) {
            this.getHeadersSalesTax();
          }
        }
        this.loadFilterGrid();
      },
      err => console.error(err)
    );
  }

  async getHeadersSalesTax() {
    try {
      this.isLoadingSalesTax = true;
      let numOfComplete = 1;
      this.headerListItems = await Promise.all(
        this.headerListItems.map(async headerItem => {
          const salesTax = await this.getSalesTaxObj(
            headerItem.RecurringInvoiceHeaderID,
            headerItem.ClientID
          );
          numOfComplete++;
          if (numOfComplete === this.headerListItems.length) this.isLoadingSalesTax = false;
          return {
            ...headerItem,
            SalesTax: salesTax['serviceTax'] ? salesTax['serviceTax'] : 0
          };
        })
      );
    } catch (err) {
      console.error('Error getting sales taxes...');
    }
  }

  filterStaffItems(event, data) {
    this.filteredStaffSingle = this.filterData(event.query, this.staffListItems);
  }

  filterServiceCodeItems(event, data) {
    this.filteredServiceSingle = this.filterData(event.query, this.recurringInvoiceGroupList);
  }
  filterData(query, staffListItems: any[]): any[] {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    const filtered: any[] = [];
    for (let i = 0; i < staffListItems.length; i++) {
      const staffItem = staffListItems[i];
      if (staffItem.label.toLowerCase().indexOf(query.toLowerCase()) > -1) {
        filtered.push(staffItem);
      }
    }
    return filtered;
  }

  fetchClients() {
    const list = this.encrDecSrvc.clientList;
    if (this.clientList.length == 0 || this.clientList.length !== list.length) {
      this.clientList = [];
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        this.clientList.push(item);
      }
    } else {
      clearInterval(this.intervalid);
    }
  }

  private filterTimeout: any = null;
  private filterTimer: any = timer(500);
  filterClients(event) {
    if (this.filterTimeout) {
      this.filterTimeout.unsubscribe();
    }

    this.filterTimeout = this.filterTimer.subscribe(() => {
      const filtered: any[] = [];
      this.filteredClients = [];
      const query = event.query;
      for (let i = 0; i < this.clientList.length; i++) {
        const client = this.clientList[i];
        if (client['ClientName'].toLowerCase().indexOf(query.toLowerCase()) > -1 && client["ContactRecord"] != true && client["Inactive"] != true) {
          filtered.push(client);
        } else if (
          client['ClientNumber']?.toLowerCase()?.indexOf(query.toLowerCase()) > -1 &&
          client['ContactRecord'] != true &&
          client['Inactive'] != true
        ) {
          filtered.push(client);
        }
        if (filtered.length > 20) break;
      }
      this.filteredClients = filtered;
      this.filterTimeout.unsubscribe();
    });
  }
  /*
    Process the form
  */
  attachInvoiceMemo() {
    this.bottomMemoValue = this.invoiceList.StandardFooterMessage;
    this.dialogDisplay = true;
    this.IsEditorChanges = false;
  }

  previewInvoice(rowData) {
    if (!rowData['rowCount'] || rowData['rowCount'] == 0) {
      this.mangoAPISrvc.notify(
        'error',
        this.translate.instant('error'),
        this.translate.instant('cant-preview-recurring-header-row')
      );
    } else {
      this.processInvoice(rowData);
    }
  }

  newPreviewInvoices(billingHeader) {
    let pdfUrl;
    const parent = this;
    parent.dialogTab1Display = false;
    parent.dialogTab2Display = false;
    parent.dialogTab3Display = false;
    parent.dialogTab4Display = false;
    parent.dialogTab5Display = false;
    parent.pdfUrl = "";
    billingHeader['IsPrintPreview'] = true;
    this.mangoAPISrvc.updateBillingHeader({IsPrintPreview: true}, billingHeader['BillingHeaderID'])
      .subscribe(data =>{
      switch (billingHeader['InvoiceTemplate']) {
        case '2':
        case '4':
          pdfUrl = new URL(`${encodeURIComponent('/home/tim/InvoiceNarrativeBatch.prpt')}/report`, `${environment.SERVICE_ADDRESS}/api/pentaho-api-repos/`);

          parent.isPreviewVisible = true;
          parent.pdfUrl = pdfUrl.href;

          setTimeout(() => {
            parent.mangoAPISrvc.showLoader(false);
            parent.dialogTab1Display = true;
          }, 300);          
          break;
        case '0':
        case '1':
        case '11':
        case '6':
        case '7':
          pdfUrl = new URL(`${encodeURIComponent('/home/tim/InvoiceDetailBatch.prpt')}/report`, `${environment.SERVICE_ADDRESS}/api/pentaho-api-repos/`);

          parent.isPreviewVisible = true;
          parent.pdfUrl = pdfUrl.href;

          setTimeout(() => {
            parent.mangoAPISrvc.showLoader(false);
            parent.dialogTab2Display = true;
          }, 300);
          break;
        case '12':
        case '13':
          pdfUrl = new URL(`${encodeURIComponent('/home/tim/InvoiceSimpleBatch.prpt')}/report`, `${environment.SERVICE_ADDRESS}/api/pentaho-api-repos/`);

          parent.isPreviewVisible = true;
          parent.pdfUrl = pdfUrl.href;

          setTimeout(() => {
            parent.mangoAPISrvc.showLoader(false);
            parent.dialogTab3Display = true;
          }, 300);
          break;
        case '14':
          pdfUrl = new URL(`${encodeURIComponent('/home/tim/NarrativeSimpleNoRemitBatch.prpt')}/report`, `${environment.SERVICE_ADDRESS}/api/pentaho-api-repos/`);

          parent.isPreviewVisible = true;
          parent.pdfUrl = pdfUrl.href;

          setTimeout(() => {
            parent.mangoAPISrvc.showLoader(false);
            parent.dialogTab5Display = true;
          }, 300);
          break;
        case '9':
        case '10':
          pdfUrl = new URL(`${encodeURIComponent('/home/tim/InvoiceNarrativeSummaryBatch.prpt')}/report`, `${environment.SERVICE_ADDRESS}/api/pentaho-api-repos/`);

          parent.isPreviewVisible = true;
          parent.pdfUrl = pdfUrl.href;

          setTimeout(() => {
            parent.mangoAPISrvc.showLoader(false);
            parent.dialogTab4Display = true;
          }, 300);
          break;
      }
    });
  }

  closeDialog() {
    this.isPreviewVisible = false;
    if (!this.billingHeaderPreview) return;
    this.mangoAPISrvc
      .reverseInvoice(this.billingHeaderPreview['BillingHeaderID'], true)
      .subscribe(data => {});
  }

  processInvoice(invoiceToPreview?) {

    const dayToday = new Date(new Date().setHours(0, 0, 0, 0));
    const lockDay = new Date(new Date(dayToday).setDate(this.companySetting?.effectiveLockDay));
    const monthToLock = new Date(new Date(lockDay).setMonth(lockDay.getMonth() - this.companySetting?.monthsPreviousToLock)).getMonth();
    const lastDayOfMonth = new Date(new Date().getFullYear(), monthToLock + 1, 0);

    if (
      this.companySetting?.isEnableSystemLocking &&
      dayToday >= lockDay &&
      new Date(this.invoiceDate) <= lastDayOfMonth
    ) {
      this.mangoAPISrvc.notify(
        'error',
        this.translate.instant('error'),
        `Unable to process invoice since the month is locked.`
      );
      this.mangoAPISrvc.showLoader(false);
      return;
    }

    this.headerProcessedCounter = 0;
    this.successCounter = 0;
    this.clientNames = [];
    let tempSelectedItems = this.selectedReviewItems;
    if (invoiceToPreview) {
      invoiceToPreview['isPreviewMode'] = true;
      tempSelectedItems = [invoiceToPreview];
    }
    this.createTimeRecordsArr = [];
    this.createBillingDetailsArr = [];
    this.updateRecurringHeaderScheduleDatesArr = [];
    this.updateRecurringDetailsDatesArr = [];

    this.mangoAPISrvc.showLoader(true);
    for (let i = 0; i < tempSelectedItems.length; i++) {
      const item = tempSelectedItems[i];

      this.lastInvoice += 1;
      item['temp_lastInvoice'] = this.lastInvoice;
      const FreqItem = item['Frequency'];
      const ScheduledDate = new Date(item['ScheduledDate']);
      if (!item['isPreviewMode'] && item['ScheduledDate']) {
        this.adjustDateBasedOnFrequency(item, 'ScheduledDate', ScheduledDate, FreqItem);
        const obj = { RecurringInvoiceHeaderID: item['RecurringInvoiceHeaderID'], ScheduledDate: item['ScheduledDate'] }
        this.updateRecurringHeaderScheduleDatesArr.push(obj)
      }
      this.getClientInformation(item['ClientID'], item, i);
    }


    const intervalId = setInterval(() => {
      if (this.headerProcessedCounter < tempSelectedItems.length)
        return;
      this.updateInvoice(this.lastInvoice);
      clearInterval(intervalId);
      this.mangoAPISrvc.showLoader(false);

      const intervalSlips = setInterval(
        () => this.batchCreateTimeRecords(this.createTimeRecordsArr, intervalSlips),
        900
      );
      const intervalDetails = setInterval(
        () => this.batchCreateBillingDetails(this.createBillingDetailsArr, intervalDetails),
        900
      );
      this.mangoAPISrvc
        .batchUpdateBillingHeader(this.updateBillingHeaderArr)
        .subscribe(response => {
          this.updateBillingHeaderArr = [];
        });
      this.batchUpdateRecurringInvoiceHeaderScheduleDates();
      this.batchUpdateRecurringDetailsDates();

      if (tempSelectedItems.length == 1 && tempSelectedItems[0]['isPreviewMode']) {
        if (this.billingHeaderPreview) {
          const intervalPreview = setInterval(() => {
            if (
              this.createTimeRecordsArr?.length == 0 &&
              this.createBillingDetailsArr?.length == 0
            ) {
              clearInterval(intervalPreview);

              this.newPreviewInvoices(this.billingHeaderPreview);
            }
          }, 900);
        }
        tempSelectedItems[0]['isPreviewMode'] = false;
      } else {
        let message = `<div>${this.successCounter} out of ${
          tempSelectedItems.length
        } ${this.translate.instant('Invoices_created_successfully')}</div>`;
        if (tempSelectedItems.length - this.successCounter > 0 && this.clientNames.length > 0) {
          message += `<br>`;
          message += `<div><strong>${
            tempSelectedItems.length - this.successCounter
          } Failed</strong> (Default invoices descriptions are too long.  250 character limit):</div>`;
          message += `<div>${this.clientNames.join(', ')}</div>`;
        }

        const staffID = this.encrDecSrvc.getObject(AppConstants.staffID);
        const data = {}
        data['Action'] = "Recurring Invoice";
        data['Description'] = "Process Recurring Invoice" + " - " + "StaffID: " + staffID;
        data['Table'] = "CompanyMango";
        const isManaging = this.encrDecSrvc.getObject(AppConstants.isManagingAccount);
        if (!isManaging) {
          this.mangoAPISrvc.addUserLogs(data).subscribe(
            res => {},
            err => {}
          );
        }

        Swal.fire({
          icon: 'success',
          title: this.translate.instant('Success'),
          html: message,
          showConfirmButton: true,
          showDenyButton: true,
          allowEscapeKey: false,
          allowEnterKey: false,
          backdrop: false,
          confirmButtonText: this.translate.instant('ok'),
          denyButtonText: this.translate.instant('Go_To_Invoice_Review')
        }).then(result => {
          if (result.isConfirmed) {
            this.selectedReviewItems = [];
            this.timeRecordsByHeaderIDAndDetailID = {};
            this.verifyNewRecordPresents();
          } else if (result.isDenied) {
            this.router.navigate(['/billing-invoicing/invoiceReview']);
          }
        });
      }
    }, 50);
  }

  getClientInformation(clientId, item, i) {
    const _this = this;
    const clientdata = this.clientProfile = this.clientList.filter(c => c.ClientID === clientId)[0];
    // this.mangoAPISrvc.getClientFullinformation(clientId).subscribe(clientdata => {
    if (!clientdata) {
      this.mangoAPISrvc.notify('error', this.translate.instant('error'), 'Client not found!');
      this.headerProcessedCounter++;
      return;
    }
    this.clientProfile = clientdata;
    this.setLaborRates();
    (function (index, selectedLevelOneObj, clientdata) {
      setTimeout(() => {
        _this.processHeaderAndDetails(clientdata, selectedLevelOneObj, index);
      }, index * 1000);
    })(i, item, clientdata);
  }

  handleDuplicateProjectMasterID(recurringInvoiceDetails) {
    const groupedByProjectMasterID = this.mangoUtils.groupBy(recurringInvoiceDetails, 'ProjectMasterID');
    recurringInvoiceDetails = recurringInvoiceDetails.map(detail => {
      const groupedDetails = groupedByProjectMasterID[detail['ProjectMasterID']];
      if (groupedDetails && groupedDetails.length > 1) {
        let sumAmount = 0;
        groupedDetails.map(detail => {
          sumAmount += this.parseStringNumber(detail['Amount']);
        });
        detail['DuplicateAmount'] = sumAmount;
        groupedDetails[0]['ProcessDuplicate'] = true;
      }
    });
  }

  processHeaderAndDetails(clientData, headerItem, index) {
    const _this = this;
    forkJoin([
      this.mangoAPISrvc.getRecurringInvoiceDetailsTimeRecordsParent(
        headerItem['RecurringInvoiceHeaderID'],
        this.companyId
      ),
      this.mangoAPISrvc.getRecurringInvoiceExpensesByHeaderId(
        headerItem['RecurringInvoiceHeaderID']
      )
    ]).subscribe(
      (data: any) => {
        let projectMasterIDArray = [];
        data[0] = data[0].map(item => {
          if (item['ReleaseDate']) {
            item['ReleaseDate'] = new Date(item['ReleaseDate']);
          }
          if (item['BillingMethod'] == 'Fixed Fee') {
            item['Amount'] = item['Amount'] || item['FlatFeeAmount'] || '0';
          } else {
            item['Amount'] = item['Amount'] || item['laboramount'] || '0';
          }
          item['Amount'] = item['Amount']?.replace('$', '').replace(',', '');
          if (projectMasterIDArray.includes(item['ProjectMasterID'])) {
            item['ProjectMasterDuplicate'] = true;
          } else {
            projectMasterIDArray.push(item['ProjectMasterID']);
          }
          _this.computeWriteUpValue(item);
          return item;
        });
        data[1] = data[1].map(item => {
          item['Cost'] = _this.parseStringNumber(item['Cost']);
          item['Amount'] = item['ExpenseAmount'] = _this.parseStringNumber(item['Amount']);
          return item;
        });
        //get all selected recurring invoice details and expenses
        let recurringInvoiceDetails =
          _this.detailsByHeaderID[headerItem['RecurringInvoiceHeaderID']] ||
          data[0].filter(detail => Number(detail['Amount'] != 0));
        let recurringInvoiceExpenses =
          _this.expensesByHeaderID[headerItem['RecurringInvoiceHeaderID']] || data[1];

        _this.mangoAPISrvc
          .getPreviousBalance(
            headerItem['ClientID'],
            moment(_this.invoiceDate).format('YYYY-MM-DD HH:mm:ss'),
            0
          )
          .subscribe(
            (result: any) => {
              headerItem['PreviousBalance'] = result['PreviousBalance'] || 0;
              _this.createHeaderAndDetails(
                recurringInvoiceDetails,
                recurringInvoiceExpenses,
                clientData,
                headerItem,
                index
              );
            },
            err => {
              headerItem['PreviousBalance'] = 0;
              _this.createHeaderAndDetails(
                recurringInvoiceDetails,
                recurringInvoiceExpenses,
                clientData,
                headerItem,
                index
              );
            }
          );
      },
      error => {
        _this.mangoAPISrvc.showLoader(false);
      }
    );
  }

  createHeaderAndDetails(recurringInvoiceDetails, recurringInvoiceExpenses, clientData, headerItem, index) {
    const projectMasterIDArray = [];

    if (recurringInvoiceDetails.length == 0 && recurringInvoiceExpenses.length == 0) {
      this.mangoAPISrvc.notify(
        'error',
        this.translate.instant('error'),
        this.translate.instant('invoice.no-details')
      );
      this.mangoAPISrvc.showLoader(false);
      return;
    }

    this.handleDuplicateProjectMasterID(recurringInvoiceDetails);
    recurringInvoiceDetails = recurringInvoiceDetails.map(detail => {
      this.computeWriteUpValue(detail);
      if (!projectMasterIDArray.includes(detail['ProjectMasterID']))
        projectMasterIDArray.push(detail['ProjectMasterID']);
      return detail;
    });
    recurringInvoiceExpenses = recurringInvoiceExpenses.map(expense => {
      if (!projectMasterIDArray.includes(expense['ProjectMasterID']))
        projectMasterIDArray.push(expense['ProjectMasterID']);
      return expense;
    });

    let expensesAmount = 0;
    let servicesAmount = 0;
    let salesTaxAmountExpenses = 0;
    let totalTaxExpenses = 0;

    recurringInvoiceExpenses.map(expense => {
      expensesAmount += Number(expense['Amount']);
      if (
        this.invoiceList.ActivateExpenseRates &&
        (expense['isTaxable'] || expense['isTaxable'] == null) &&
        expense.ectaxable
      ) {
        salesTaxAmountExpenses += numeral(expense['Amount']).value();
        totalTaxExpenses = salesTaxAmountExpenses * (this.salesTax.Expense / 100);
      }
    });
    recurringInvoiceDetails.map(detail => {
      servicesAmount += Number(detail['Amount']);
    });

    const billingHeaderObj = {};
    const isPreviewMode = headerItem['isPreviewMode'];
    const isRet = headerItem['isRetainer'];
    if (headerItem['isRetainer']) {
      billingHeaderObj['ClientID'] = headerItem['ClientID'];
      billingHeaderObj['ClientName'] = headerItem['ClientName'];
      billingHeaderObj['CompanyID'] = headerItem['CompanyID'];
      billingHeaderObj['InvoiceAmount'] = billingHeaderObj['InvoiceBalance'] =
        expensesAmount + servicesAmount;
      billingHeaderObj['TotalWUWD'] =
        billingHeaderObj['GraceDays'] =
        billingHeaderObj['TotalExpenses'] =
        billingHeaderObj['TotalPayments'] =
        billingHeaderObj['TotalTax'] =
        billingHeaderObj['SalesTaxAmount'] =
        billingHeaderObj['Discount'] =
          0;
      billingHeaderObj['InvoiceDate'] = this.invoiceDate;
      billingHeaderObj['TotalServices'] = servicesAmount; // total services/details
      billingHeaderObj['TotalExpenses'] = expensesAmount; //total recurring invoice expenses
      billingHeaderObj['InvoiceNumber'] = isPreviewMode ? null : headerItem['temp_lastInvoice'];
      billingHeaderObj['InvoiceType'] = 'Retainer Invoice';
      billingHeaderObj['QBReference'] = null;
      billingHeaderObj['BillNote'] = null;
      billingHeaderObj['PeriodFrom'] = null;
      billingHeaderObj['PeriodTo'] = headerItem['ScheduledDate'];
      billingHeaderObj['BillNoteTop'] = null;
      billingHeaderObj['ProjectMasterIDArray'] = projectMasterIDArray;
      billingHeaderObj['DescriptionShort'] = this.mangoUtils.replaceCaretTemplateWithMonthAndYear(
        headerItem['Description'],
        this.currentMonth,
        this.currentYear,
        this.currentQuarter
      ); //replace text
      billingHeaderObj['BillingPartnerID'] = clientData['BillingPartnerID'];
      billingHeaderObj['OriginatingPartnerID'] = clientData['OriginatingPartnerID'];
      billingHeaderObj['StaffAssignedID'] = clientData['StaffAssignedID'];
      billingHeaderObj['BillingGroupID'] = clientData['BillingGroupID'];
      billingHeaderObj['GroupDescriptionID'] = clientData['GroupDescriptionID'];
      billingHeaderObj['GroupDescriptionIDArray'] =
        clientData['GroupDescriptionIDArray'] && clientData['GroupDescriptionIDArray'].length > 0
          ? clientData['GroupDescriptionIDArray']
          : null;
      billingHeaderObj['ClientTypeID'] = clientData['ClientTypeID'];
      billingHeaderObj['RecurringInvoice'] = true;
      billingHeaderObj['PreviousBalance'] = headerItem['PreviousBalance'];
      billingHeaderObj['RecurringHeaderID'] = headerItem['RecurringInvoiceHeaderID'];
      billingHeaderObj['FinalizeAction'] = headerItem['FinalizeAction']
        ? headerItem['FinalizeAction']
        : 'Print';
      billingHeaderObj['InvoiceTemplate'] = headerItem['InvoiceTemplate']
        ? headerItem['InvoiceTemplate']
        : '2';
      billingHeaderObj['isRetainer'] = true;
      billingHeaderObj['TotalTaxExpenses'] = 0;
    } else {
      billingHeaderObj['ClientID'] = headerItem['ClientID'];
      billingHeaderObj['ClientName'] = headerItem['ClientName'];
      billingHeaderObj['CompanyID'] = headerItem['CompanyID'];
      billingHeaderObj['InvoiceAmount'] = billingHeaderObj['InvoiceBalance'] =
        expensesAmount + servicesAmount; //plus total recurring invoice expenses
      billingHeaderObj['TotalExpenses'] = expensesAmount; //total recurring invoice expenses
      billingHeaderObj['InvoiceDate'] = this.invoiceDate;
      billingHeaderObj['InvoiceNumber'] = isPreviewMode ? null : headerItem['temp_lastInvoice'];
      billingHeaderObj['PeriodFrom'] = null;
      billingHeaderObj['PeriodTo'] = headerItem['ScheduledDate'];
      billingHeaderObj['BillNoteTop'] = headerItem['TopMemo'];
      billingHeaderObj['BillNote'] = this.invoiceList.StandardFooterMessage;
      billingHeaderObj['InvoiceType'] = 'Recurring';
      billingHeaderObj['DescriptionShort'] = this.mangoUtils.replaceCaretTemplateWithMonthAndYear(
        headerItem['Description'],
        this.currentMonth,
        this.currentYear,
        this.currentQuarter
      ); //replace text
      billingHeaderObj['ProjectMasterIDArray'] = projectMasterIDArray;
      billingHeaderObj['GraceDays'] = clientData['GraceDays'];
      billingHeaderObj['BillingPartnerID'] = clientData['BillingPartnerID'];
      billingHeaderObj['OriginatingPartnerID'] = clientData['OriginatingPartnerID'];
      billingHeaderObj['StaffAssignedID'] = clientData['StaffAssignedID'];
      billingHeaderObj['BillingGroupID'] = clientData['BillingGroupID'];
      billingHeaderObj['GroupDescriptionID'] = clientData['GroupDescriptionID'];
      billingHeaderObj['GroupDescriptionIDArray'] =
        clientData['GroupDescriptionIDArray'] && clientData['GroupDescriptionIDArray'].length > 0
          ? clientData['GroupDescriptionIDArray']
          : null;
      billingHeaderObj['ClientTypeID'] = clientData['ClientTypeID'];
      billingHeaderObj['RecurringInvoice'] = true;
      billingHeaderObj['PreviousBalance'] = headerItem['PreviousBalance'];
      billingHeaderObj['RecurringHeaderID'] = headerItem['RecurringInvoiceHeaderID'];
      billingHeaderObj['TotalServices'] = servicesAmount; // total services/details
      billingHeaderObj['FinalizeAction'] = headerItem['FinalizeAction'] ? headerItem['FinalizeAction'] : 'Print';
      billingHeaderObj['InvoiceTemplate'] = headerItem['InvoiceTemplate'] ? headerItem['InvoiceTemplate'] : '2';
      billingHeaderObj["TotalTax"] = headerItem["SalesTax"];
      billingHeaderObj['TotalTaxExpenses'] = totalTaxExpenses ?? 0;
      billingHeaderObj['SalesTaxAmountExpenses'] = salesTaxAmountExpenses;
    }

    billingHeaderObj['InvoicePosted'] =
      (billingHeaderObj['FinalizeAction'] == 'Finalize Only' &&
        this.isSkipInvoiceReview &&
        !isPreviewMode) ||
      false;
    billingHeaderObj['isPreviewMode'] = isPreviewMode;

    if (this.isIncludedBillThrough && this.InvoiceStartDate) {
      billingHeaderObj['PeriodTo'] = this.InvoiceStartDate;
    }
    billingHeaderObj['BillNoteTop'] = headerItem['TopMemo']
      ? this.mangoUtils.replaceCaretTemplate(headerItem['TopMemo'], billingHeaderObj)
      : this.invoiceList.InvoiceMessage;
    billingHeaderObj['BillNote'] = headerItem['BottomMemo']
      ? this.mangoUtils.replaceCaretTemplate(headerItem['BottomMemo'], billingHeaderObj)
      : this.invoiceList.StandardFooterMessage;

    //billingHeaderObj["BillNote"] = billingHeaderObj["BillNote"] ? this.mangoUtils.replaceCaretTemplate(billingHeaderObj["BillNote"], billingHeaderObj) : "";
    billingHeaderObj['CreatedbyStaffID'] = this.encrDecSrvc.getObject(AppConstants.staffID);
    this.mangoAPISrvc.createBillingHeader(billingHeaderObj).subscribe(
      (rateData: any) => {
        this.successCounter++;
        if (!isPreviewMode) {
          // this.updateInvoice(rateData.data['InvoiceNumber']);
          billingHeaderObj['InvoiceNumber'] = rateData.data['InvoiceNumber'];
        } else {
          this.billingHeaderPreview = billingHeaderObj;
        }
        let billingDetailsToInsert = [];
        //create all expense time records
        if (recurringInvoiceExpenses && recurringInvoiceExpenses.length > 0) {
          recurringInvoiceExpenses.map(expense => {
            let billingDetailObj = {};
            billingDetailObj['ClientID'] = billingHeaderObj['ClientID'];
            billingDetailObj['CompanyID'] = billingHeaderObj['CompanyID'];
            billingDetailObj['BillingHeaderID'] = rateData.data['BillingHeaderID'];
            billingDetailObj['Description'] = this.mangoUtils.replaceCaretTemplateWithMonthAndYear(
              expense['ExpenseMemo'],
              this.currentMonth,
              this.currentYear,
              this.currentQuarter
            ); //what desc should we use
            if (this.isIncludedBillThrough && this.InvoiceStartDate) {
              billingDetailObj['Description'] =
                billingDetailObj['Description'] +
                ' (thru ' +
                moment(this.InvoiceStartDate).format('MM-DD-YYYY') +
                ')';
            }
            billingDetailObj['StaffID'] = expense['StaffID'];
            billingDetailObj['InvoiceDate'] = billingHeaderObj['InvoiceDate'];
            billingDetailObj['InvoiceNumber'] = billingHeaderObj['InvoiceNumber'];
            billingDetailObj['EngagementTypeID'] = expense['EngagementTypeID'];
            billingDetailObj['PostedQB'] = false;
            billingDetailObj['Amount'] = expense['Amount'];
            billingDetailObj['ExpenseAmount'] = expense['ExpenseAmount'];
            billingDetailObj['ProjectID'] = expense['ProjectMasterID'];
            billingDetailObj['isPreviewMode'] = headerItem['isPreviewMode'];

            billingDetailObj['OriginatingPartnerID'] = billingHeaderObj['OriginatingPartnerID'];
            billingDetailObj['BillingPartnerID'] = billingHeaderObj['BillingPartnerID'];
            billingDetailObj['StaffAssignedID'] = billingHeaderObj['StaffAssignedID'];
            billingDetailObj['GroupDescriptionIDArray'] =
              billingHeaderObj['GroupDescriptionIDArray'];
            billingDetailObj['ClientTypeID'] = billingHeaderObj['ClientTypeID'];
            // billingDetailObj['WriteUpDownTime'] = expense['writeupValue'];
            billingDetailsToInsert.push(billingDetailObj);

            let slipEntity = {};
            slipEntity['ClientID'] = billingHeaderObj['ClientID'];
            slipEntity['Tax'] = 0;
            slipEntity['GroupDescriptionIDArray'] = billingHeaderObj['GroupDescriptionIDArray'];
            slipEntity['ClientName'] = billingHeaderObj['ClientName'];
            slipEntity['CompanyID'] = billingHeaderObj['CompanyID'];
            slipEntity['BillingHeaderID'] = rateData.data['BillingHeaderID'];
            slipEntity['ExpenseCodeID'] = expense['ExpenseCodeID'];
            slipEntity['ProjectMasterID'] = parseInt(expense['ProjectMasterID']);
            slipEntity['IsTimeRecord'] = 'X';
            slipEntity['Cost'] = expense['Cost'];
            slipEntity['Units'] = expense['Units'];
            slipEntity['StaffID'] = expense['StaffID'];
            //'to hard to get the detail line item which is in RTF.  Try converting later.
            slipEntity['Description'] =
              this.mangoUtils.replaceCaretTemplateWithMonthAndYear(
                expense['Description'],
                this.currentMonth,
                this.currentYear,
                this.currentQuarter
              );
            slipEntity['Memo'] = expense['ExpenseMemo'];
            let amount = expense['Amount'];
            slipEntity['StandardAmount'] =
              slipEntity['Amount'] =
              slipEntity['BilledAmount'] =
              slipEntity['SelectedAmount'] =
                String(amount);
            slipEntity['Billable'] =
              slipEntity['Approved'] =
              slipEntity['Billed'] =
              slipEntity['ExpenseApproved'] =
                true;
            slipEntity['Reimburseable'] = false;
            // slipEntity["WriteUpDown"] = slipEntity["BilledAmount"]
            slipEntity['StaffCost'] =
              slipEntity['Finished'] =
              slipEntity['TotalTime'] =
              slipEntity['StaffCost'] =
              slipEntity['ElaspedTime'] =
                0;
            slipEntity['BillingPartnerID'] = billingHeaderObj['BillingPartnerID'];
            slipEntity['OriginatingPartnerID'] = billingHeaderObj['OriginatingPartnerID'];
            slipEntity['GroupDescriptionID'] = billingHeaderObj['BillingGroupID'];
            slipEntity['ClientTypeID'] = billingHeaderObj['ClientTypeID'];
            slipEntity['Ddate'] = slipEntity['InvoiceDate'] = billingHeaderObj['InvoiceDate'];
            slipEntity['InvoiceNumber'] = billingHeaderObj['InvoiceNumber'];
            slipEntity['PostedQB'] = slipEntity['Exclude'] = slipEntity['Vacation'] = false;
            this.createTimeSheet(slipEntity, isRet);
          });
        }

        billingHeaderObj['BillingHeaderID'] = rateData.data['BillingHeaderID'];
        billingHeaderObj['ReleaseExpense'] = headerItem['ReleaseExpense'];
        this.salesTax.billingHeaderId = rateData.data['BillingHeaderID'];

        this.processedBillingDetailCounter[billingHeaderObj['BillingHeaderID']] = 0;
        recurringInvoiceDetails.forEach(detail => {
          //build the list of billing detail to insert
          let billingDetailObj = {};
          billingDetailObj['ClientID'] = billingHeaderObj['ClientID'];
          billingDetailObj['CompanyID'] = billingHeaderObj['CompanyID'];
          billingDetailObj['BillingHeaderID'] = billingHeaderObj['BillingHeaderID'];
          billingDetailObj['Description'] = this.mangoUtils.replaceCaretTemplateWithMonthAndYear(
            detail['InvoiceDescription'],
            this.currentMonth,
            this.currentYear,
            this.currentQuarter
          ); //what desc should we use
          if (this.isIncludedBillThrough && this.InvoiceStartDate) {
            billingDetailObj['Description'] =
              billingDetailObj['Description'] +
              ' (thru ' +
              moment(this.InvoiceStartDate).format('MM-DD-YYYY') +
              ')';
          }
          billingDetailObj['StaffID'] = detail['StaffID'];
          billingDetailObj['InvoiceDate'] = billingHeaderObj['InvoiceDate'];
          billingDetailObj['InvoiceNumber'] = billingHeaderObj['InvoiceNumber'];
          billingDetailObj['EngagementTypeID'] = detail['EngagementTypeID'];
          billingDetailObj['PostedQB'] = false;
          billingDetailObj['Amount'] = detail['Amount'];
          billingDetailObj['ProjectID'] = detail['ProjectMasterID'];
          billingDetailObj['isPreviewMode'] = headerItem['isPreviewMode'];

          billingDetailObj['OriginatingPartnerID'] = billingHeaderObj['OriginatingPartnerID'];
          billingDetailObj['BillingPartnerID'] = billingHeaderObj['BillingPartnerID'];
          billingDetailObj['StaffAssignedID'] = billingHeaderObj['StaffAssignedID'];
          billingDetailObj['GroupDescriptionIDArray'] = billingHeaderObj['GroupDescriptionIDArray'];
          billingDetailObj['ClientTypeID'] = billingHeaderObj['ClientTypeID'];
          billingDetailObj['WriteUpDownTime'] = detail['writeupValue'];
          detail['PrevReleaseDate'] = detail['ReleaseDate'];

          if (!isPreviewMode) {
            this.adjustDateBasedOnFrequency(
              detail,
              'ReleaseDate',
              new Date(detail['ReleaseDate']),
              headerItem['Frequency']
            );

            let obj = {
              RecurringInvoiceDetailID: detail['RecurringInvoiceDetailID'],
              ReleaseDate: detail['ReleaseDate']
            };

            this.updateRecurringDetailsDatesArr.push(obj);
          }

          billingDetailsToInsert.push(billingDetailObj);
          this.processTimeRecords(billingHeaderObj, detail);
        });

        let processDetailInterval = setInterval(() => {
          if (
            this.processedBillingDetailCounter[billingHeaderObj['BillingHeaderID']] <
            recurringInvoiceDetails.length
          )
            return;

          clearInterval(processDetailInterval);
          this.processedBillingDetailCounter[billingHeaderObj['BillingHeaderID']] = 0;
          this.processBillingDetailRecords(billingDetailsToInsert);
          this.headerProcessedCounter += 1;
        }, 100);

        // if(index == this.selectedReviewItems.length - 1) {
        //   const intervalSlips = setInterval(() => this.batchCreateTimeRecords(this.createTimeRecordsArr, intervalSlips), 900);
        //   const intervalDetails = setInterval(() => this.batchCreateBillingDetails(this.createBillingDetailsArr, intervalDetails), 900);
        // }

        // if(!isPreviewMode){
        //   this.processRecurringInvoiceDetailRecords(recurringInvoiceDetails).subscribe(data=>{
        //   },(error) =>{
        //     this.clientNames.push(billingHeaderObj['ClientName']);
        //     this.mangoAPISrvc.notify('error', this.translate.instant('error'), AppConstants.createErrorMsg)
        //   })
        // }
      },
      error => {
        this.clientNames.push(billingHeaderObj['ClientName']);
        this.mangoAPISrvc.showLoader(false);
        this.headerProcessedCounter++;
        this.mangoAPISrvc.notify(
          'error',
          this.translate.instant('error'),
          AppConstants.createErrorMsg
        );
      }
    );
  }

  pushToUpdateArray(object, id) {
    const temp = this.updateBillingHeaderArr.find(item => item['BillingHeaderID'] == id);
    if (temp) {
      const toMerge = JSON.parse(JSON.stringify(temp['data']));
      temp['data'] = { ...toMerge, ...object }
    } else {
      this.updateBillingHeaderArr.push({
        data: object,
        BillingHeaderID: id
      });
    }
  }

  batchCreateTimeRecords(bulkData, interval) {
    const observableBatch = bulkData.splice(0, 100);

    if (observableBatch.length < 100) {
      clearInterval(interval);
    }

    this.mangoAPISrvc.batchCreateTimeRecords({ list: observableBatch }).subscribe(
      data => {
        if (bulkData.length > 0) {
          return false;
        }

        this.createTimeRecordsArr = [];
      },
      err => {
        this.createTimeRecordsArr = [];
        this.mangoAPISrvc.showLoader(false);
      }
    );
  }

  batchCreateBillingDetails(bulkData, interval) {
    const observableBatch = bulkData.splice(0, 100);

    if (observableBatch.length < 100) {
      clearInterval(interval);
    }

    this.mangoAPISrvc.batchCreateBillingDetails({ list: observableBatch }).subscribe(
      data => {
        observableBatch?.forEach(lineItem => {
          if (!lineItem['isPreviewMode']) {
            this.mangoAPISrvc
              .sendBudgetAlert({
                ClientID: lineItem.ClientID,
                ProjectMasterID: lineItem['ProjectID'],
                CompanyID: this.loginCompanyId,
                Ddate: moment(new Date(lineItem.InvoiceDate)).format('YYYY-MM-DD')
              })
              .subscribe(
                data => {},
                err => {
                  console.log(err);
                }
              );
          }
        });

        if (bulkData.length > 0) {
          return false;
        }

        this.createBillingDetailsArr = [];
      },
      err => {
        this.createBillingDetailsArr = [];
        this.mangoAPISrvc.showLoader(false);
      }
    );
  }

  batchUpdateRecurringInvoiceHeaderScheduleDates() {
    this.updateRecurringHeaderScheduleDatesArr = this.updateRecurringHeaderScheduleDatesArr.map(
      item => {
        item['ScheduledDate'] = this.mangoUtils.formatDateWithoutTime(
          new Date(item['ScheduledDate'])
        );

        return item;
      }
    );
    this.mangoAPISrvc
      .recurringHeaderBulkUpdate(this.updateRecurringHeaderScheduleDatesArr)
      .subscribe(result => {
        this.updateRecurringHeaderScheduleDatesArr = this.updateRecurringHeaderScheduleDatesArr.map(
          item => {
            item['ScheduledDate'] = this.mangoUtils.formatDateWithoutTime(
              new Date(item['ScheduledDate'])
            );
          }
        );
      });
  }

  batchUpdateRecurringDetailsDates() {
    this.updateRecurringDetailsDatesArr = this.updateRecurringDetailsDatesArr.map(item => {
      item['ReleaseDate'] = this.mangoUtils.formatDateWithoutTime(new Date(item['ReleaseDate']));

      return item;
    });
    this.mangoAPISrvc
      .bulkUpdateReleaseDates(this.updateRecurringDetailsDatesArr)
      .subscribe(result => {});
  }

  processTimeRecords(billingHeaderObj, recurringInvoiceDetail) {
    const parent = this;

    billingHeaderObj['WorkCodeID'] = recurringInvoiceDetail['WorkCodeID'];
    billingHeaderObj['ServiceCodeID'] = recurringInvoiceDetail['ServiceCodeID'];

    if (
      recurringInvoiceDetail['ProjectMasterDuplicate'] &&
      !recurringInvoiceDetail['ProcessDuplicate']
    ) {
      // the timeslips have already been processed.
      parent.processedBillingDetailCounter[billingHeaderObj['BillingHeaderID']]++;
      return;
    }

    if (recurringInvoiceDetail['isReleaseTime'] && !billingHeaderObj['isRetainer']) {
      let clientId = parseInt(recurringInvoiceDetail['ClientID']);
      let projectId = parseInt(recurringInvoiceDetail['ProjectMasterID']);
      let endDate = moment(recurringInvoiceDetail['PrevReleaseDate']).format('MM-DD-YYYY');
      parent.mangoAPISrvc.getTimeSheetsByClientId(clientId, projectId, null, endDate).subscribe(
        timeRecords => {
          //fetch selected time records
          let headerId = recurringInvoiceDetail['RecurringInvoiceHeaderID'];
          let detailId = recurringInvoiceDetail['RecurringInvoiceDetailID'];
          let selectedTimeRecords: any = [];
          if (
            this.timeRecordsByHeaderIDAndDetailID[headerId] &&
            this.timeRecordsByHeaderIDAndDetailID[headerId][detailId]
          ) {
            selectedTimeRecords = this.timeRecordsByHeaderIDAndDetailID[headerId][detailId];
          }
          selectedTimeRecords = selectedTimeRecords.length > 0 ? selectedTimeRecords : timeRecords;
          parent.createAndUpdateTimeRecord(
            billingHeaderObj,
            selectedTimeRecords,
            recurringInvoiceDetail
          );
          parent.processedBillingDetailCounter[billingHeaderObj['BillingHeaderID']]++;
        },
        () => {
          parent.processedBillingDetailCounter[billingHeaderObj['BillingHeaderID']]++;
          parent.mangoAPISrvc.showLoader(false);
        }
      );
    } else {
      parent.processedBillingDetailCounter[billingHeaderObj['BillingHeaderID']]++;
      // Need to Update all existing time records - Calculate Sales Tax and then Update BillingHeader
      if (!billingHeaderObj['isRetainer'] && (recurringInvoiceDetail?.isCalcTaxNoTime == null || recurringInvoiceDetail?.isCalcTaxNoTime) && (recurringInvoiceDetail['isTaxable'] == null || recurringInvoiceDetail['isTaxable'])) {
        const obj = {};
        const temp = parent.updateBillingHeaderArr.find(item => item['BillingHeaderID'] == billingHeaderObj['BillingHeaderID']);
        parent.salesTax.taxableAmtService = obj['taxableAmtService'] = (numeral(recurringInvoiceDetail["Amount"]).value() || 0) + (numeral(temp?.data?.SalesTaxAmount).value() || 0);
        parent.salesTax.serviceTax = obj['serviceTax'] = obj['taxableAmtService'] * (parent.salesTax.Labor / 100);
        obj["TotalTax"] = obj['serviceTax']
          ? numeral(obj['serviceTax']).value()
          : 0;
        obj["SalesTaxAmount"] = obj['taxableAmtService']
          ? numeral(obj['taxableAmtService']).value()
          : 0;
        obj['InvoiceBalance'] =
          billingHeaderObj['InvoiceAmount'] +
          billingHeaderObj['TotalTaxExpenses'] +
          obj['TotalTax'];
        parent.pushToUpdateArray(obj, billingHeaderObj['BillingHeaderID']);
      }
      const slipEntity = {};
      slipEntity["ClientID"] = billingHeaderObj.ClientID
      slipEntity["CompanyID"] = billingHeaderObj["CompanyID"]
      slipEntity["BillingHeaderID"] = billingHeaderObj["BillingHeaderID"];
      slipEntity["ServiceCodeID"] = billingHeaderObj["ServiceCodeID"];
      slipEntity["ProjectMasterID"] = parseInt(recurringInvoiceDetail['ProjectMasterID']);
      slipEntity["IsTimeRecord"] = "T";
      slipEntity["WorkCodeID"] = billingHeaderObj["WorkCodeID"];
      slipEntity["StaffID"] = recurringInvoiceDetail["StaffID"];
      //'to hard to get the detail line item which is in RTF.  Try converting later.
      slipEntity["Description"] = slipEntity["Memo"] = this.mangoUtils.replaceCaretTemplateWithMonthAndYear(recurringInvoiceDetail["InvoiceDescription"], this.currentMonth, this.currentYear, this.currentQuarter);
      slipEntity["StandardAmount"] = 0
      slipEntity["BilledAmount"] = slipEntity["WriteUpDown"] = slipEntity["SelectedAmount"] = recurringInvoiceDetail["Amount"]
      slipEntity["Billable"] = slipEntity["Approved"] = slipEntity["Billed"] = true;
      slipEntity['StaffCost'] = slipEntity["Finished"] = slipEntity["TotalTime"] = slipEntity["StaffCost"] = slipEntity["ElaspedTime"] = 0;
      slipEntity["BillingPartnerID"] = billingHeaderObj["BillingPartnerID"];
      slipEntity["OriginatingPartnerID"] = billingHeaderObj["OriginatingPartnerID"];
      slipEntity["GroupDescriptionID"] = billingHeaderObj["BillingGroupID"];
      slipEntity["ClientTypeID"] = billingHeaderObj["ClientTypeID"];

      slipEntity[ "InvoiceDate" ] = billingHeaderObj[ "InvoiceDate" ];

      slipEntity[ "Ddate" ] = (
        moment( new Date( slipEntity[ "InvoiceDate" ] ) )
        .format( "YYYY-MM-DD" )
      );

      slipEntity["InvoiceNumber"] = billingHeaderObj["InvoiceNumber"];
      slipEntity["PostedQB"] = slipEntity["Exclude"] = slipEntity["Vacation"] = false;
      this.createTimeSheet(slipEntity, billingHeaderObj['isRetainer']);
    }
  }

  roundOffDecimals(money) {
    return numeral(this.currencyPipe.transform(money, 'USD')).value();
  }

  createAndUpdateTimeRecord(billingHeaderObj, timeRecords, item) {
    const timeRecordsList: any = [];

    const isPreviewMode = billingHeaderObj['isPreviewMode'];
    const isRet = billingHeaderObj['isRetainer'];

    if (timeRecords.length == 0) {
      if ((item?.isCalcTaxNoTime == null || item?.isCalcTaxNoTime) && (item['isTaxable'] == null || item['isTaxable'])) {
        const obj = {};
        const temp = this.updateBillingHeaderArr.find(item => item['BillingHeaderID'] == billingHeaderObj['BillingHeaderID']);
        this.salesTax.taxableAmtService = obj['taxableAmtService'] = (numeral(item["Amount"]).value() || 0) + (numeral(temp?.data?.SalesTaxAmount).value() || 0);
        this.salesTax.serviceTax = obj['serviceTax'] = obj['taxableAmtService'] * (this.salesTax.Labor / 100);
        obj["TotalTax"] = obj['serviceTax']
          ? numeral(obj['serviceTax']).value()
          : 0;
        obj["SalesTaxAmount"] = obj['taxableAmtService']
          ? numeral(obj['taxableAmtService']).value()
          : 0;
        obj['InvoiceBalance'] =
          billingHeaderObj['InvoiceAmount'] +
          billingHeaderObj['TotalTaxExpenses'] +
          obj['TotalTax'];
        this.pushToUpdateArray(obj, billingHeaderObj['BillingHeaderID']);
      }

      const slipEntity = {};
      slipEntity["ClientID"] = billingHeaderObj.ClientID
      slipEntity["CompanyID"] = billingHeaderObj["CompanyID"]
      slipEntity["BillingHeaderID"] = billingHeaderObj["BillingHeaderID"];
      slipEntity["ServiceCodeID"] = billingHeaderObj["ServiceCodeID"];
      slipEntity["WorkCodeID"] = billingHeaderObj["WorkCodeID"];
      slipEntity["ProjectMasterID"] = parseInt(item['ProjectMasterID']);
      slipEntity["IsTimeRecord"] = "T";
      slipEntity["StaffID"] = item["StaffID"];
      //'to hard to get the detail line item which is in RTF.  Try converting later.
      slipEntity["Description"] = slipEntity["Memo"] = this.mangoUtils.replaceCaretTemplateWithMonthAndYear(item["InvoiceDescription"], this.currentMonth, this.currentYear, this.currentQuarter);
      slipEntity["StandardAmount"] = 0;
      const amount = item["DuplicateAmount"] || item['Amount']
      slipEntity["WriteUpDown"] = slipEntity["BilledAmount"] = slipEntity["SelectedAmount"] = amount;
      slipEntity["Billable"] = slipEntity["Approved"] = slipEntity["Billed"] = true;
      slipEntity['StaffCost'] = slipEntity["Finished"] = slipEntity["TotalTime"] = slipEntity["StaffCost"] = slipEntity["ElaspedTime"] = 0;
      slipEntity["BillingPartnerID"] = billingHeaderObj["BillingPartnerID"];
      slipEntity["OriginatingPartnerID"] = billingHeaderObj["OriginatingPartnerID"];
      slipEntity["GroupDescriptionID"] = billingHeaderObj["BillingGroupID"];
      slipEntity["ClientTypeID"] = billingHeaderObj["ClientTypeID"];

      slipEntity[ "InvoiceDate" ] = billingHeaderObj[ "InvoiceDate" ];

      slipEntity[ "Ddate" ] = (
        moment( new Date( slipEntity[ "InvoiceDate" ] ) )
        .format( "YYYY-MM-DD" )
      );

      slipEntity["InvoiceNumber"] = billingHeaderObj["InvoiceNumber"];
      slipEntity["PostedQB"] = slipEntity["Exclude"] = slipEntity["Vacation"] = false;
      // this.mangoAPISrvc.showLoader(true);
      this.createTimeSheet(slipEntity, billingHeaderObj['isRetainer']);
    } else {
      //let TotalStandardAmt = timeRecords.reduce(function (a, b) { return a + +numeral(b['StandardAmount']).value(); }, 0);
      let TotalBillableAmount = 0;
      let totalNonBillableAmount = 0;

      const amount = item["DuplicateAmount"] || item['Amount']

      timeRecords.map(function (itemData) {
        if (itemData['Billable']) {
          TotalBillableAmount += numeral(itemData['StandardAmount']).value();
        } else {
          totalNonBillableAmount += numeral(itemData['StandardAmount']).value();
        }
      });

      let totalBilledAmt = 0;
      let lastBillableSlipID = null;
      const writeupDownAmount = numeral(amount - TotalBillableAmount).value();
      const AmountToAllocate = totalNonBillableAmount + writeupDownAmount;

      this.invoiceTax = 0;
      this.salesTax.serviceTax = 0;
      this.salesTax.taxableAmtService = 0;

      for (let i = 0; i < timeRecords.length; ++i) {
        const eachTime = timeRecords[i];
        eachTime['BillingHeaderID'] = billingHeaderObj['BillingHeaderID'];
        eachTime['IsTimeRecord'] = 'T';

        const billableAmt = numeral(eachTime['StandardAmount']).value();

        if (eachTime['Billable']) {
          eachTime['BilledAmount'] = this.roundOffDecimals(
            (billableAmt / TotalBillableAmount) * amount
          );
          eachTime['WriteUpDown'] = this.roundOffDecimals(eachTime['BilledAmount'] - billableAmt);

          totalBilledAmt = this.roundOffDecimals(
            this.mangoUtils.addFloat(eachTime.BilledAmount, totalBilledAmt)
          );
          lastBillableSlipID = eachTime.SlipMasterID;
        } else {
          eachTime['WriteUpDown'] = billableAmt * -1;
          eachTime['BilledAmount'] = 0;
        }

        eachTime['Billed'] = true;
        eachTime['InvoiceDate'] = billingHeaderObj['InvoiceDate'];
        eachTime['InvoiceNumber'] = billingHeaderObj['InvoiceNumber'];
        eachTime['SelectedAmount'] = amount;

        // calculate sales tax
        if (
          this.invoiceList.ActivateLaborRates == true &&
          this.clientProfile.SalesTaxLevel != 'None' &&
          (item['isTaxable'] == null || item['isTaxable'])
        ) {
          eachTime.BilledAmount = eachTime.BilledAmount ? eachTime.BilledAmount : 0;
          if (eachTime.sctaxable == true) {
            this.salesTax.taxableAmtService += numeral(eachTime.BilledAmount).value();
            this.salesTax.serviceTax +=
              numeral(eachTime.BilledAmount).value() * (this.salesTax.Labor / 100);
          }
        }

        timeRecordsList.push(eachTime);
      }

      if (totalBilledAmt > amount) {
        const diff = this.mangoUtils.subtractFloat(totalBilledAmt, amount);
        timeRecordsList.forEach(slip => {
          if (slip.SlipMasterID === lastBillableSlipID) {
            slip.BilledAmount = this.roundOffDecimals(
              this.mangoUtils.subtractFloat(slip.BilledAmount, diff)
            );
            slip.WriteUpDown = this.roundOffDecimals(
              this.mangoUtils.subtractFloat(slip.BilledAmount, slip.StandardAmount)
            );
            return;
          }
        });
      } else if (totalBilledAmt < amount) {
        const diff = this.mangoUtils.subtractFloat(amount, totalBilledAmt);
        timeRecordsList.forEach(slip => {
          if (slip.SlipMasterID === lastBillableSlipID) {
            slip.BilledAmount = this.roundOffDecimals(
              this.mangoUtils.addFloat(slip.BilledAmount, diff)
            );
            slip.WriteUpDown = this.roundOffDecimals(
              this.mangoUtils.subtractFloat(slip.BilledAmount, slip.StandardAmount)
            );
            return;
          }
        });
      }

      if ((item['isTaxable'] == null || item['isTaxable']) && timeRecordsList?.length > 0) {
        const obj = {};
        const temp = this.updateBillingHeaderArr.find(item => item['BillingHeaderID'] == billingHeaderObj['BillingHeaderID']);
        this.salesTax.taxableAmtService = obj['taxableAmtService'] = (numeral(this.salesTax.taxableAmtService).value() || 0) + (numeral(temp?.data?.SalesTaxAmount).value() || 0);
        this.salesTax.serviceTax = obj['serviceTax'] = obj['taxableAmtService'] * (this.salesTax.Labor / 100);
        obj["TotalTax"] = obj['serviceTax']
          ? numeral(obj['serviceTax']).value()
          : 0;
        obj["SalesTaxAmount"] = obj['taxableAmtService']
          ? numeral(obj['taxableAmtService']).value()
          : 0;
        obj['InvoiceBalance'] =
          billingHeaderObj['InvoiceAmount'] +
          billingHeaderObj['TotalTaxExpenses'] +
          obj['TotalTax'];
        this.pushToUpdateArray(obj, billingHeaderObj['BillingHeaderID']);
      }

      const parent = this;
      parent.invoiceTax = (parent.salesTax.serviceTax);
      let forkJoin = null;
      if (!isRet) {
        if (isPreviewMode) {
          forkJoin = parent.createTimeSlipsRecords(timeRecordsList);
        } else {
          forkJoin = parent.updateTimeSlipsRecords(timeRecordsList);
        }
      }
      forkJoin.subscribe(data => {
        // parent.mangoAPISrvc.getBillingHeaderSingleRecordById(parent.salesTax.billingHeaderId)
        //   .subscribe((data: any) => {
        //     if (data && data.length == 1) {
        //       let obj = {};
        //       let headerTotalTax = data[0]['TotalTax'] ? this.parseStringNumber(data[0]['TotalTax']) : 0;
        //       let instanceTotalTax = this.salesTax.serviceTax ? numeral(this.salesTax.serviceTax).value() : 0;
        //       let headerSalesTaxAmount = data[0]['SalesTaxAmount'] ? this.parseStringNumber(data[0]['SalesTaxAmount']) : 0;
        //       let instanceSalesTaxAmount = this.salesTax.taxableAmtService ? numeral(this.salesTax.taxableAmtService).value() : 0;
        //       obj["TotalTax"] = headerTotalTax + instanceTotalTax;
        //       obj["SalesTaxAmount"] = headerSalesTaxAmount + instanceSalesTaxAmount;
        //       obj['InvoiceBalance'] = numeral(data[0]['InvoiceAmount']).value() + obj["TotalTax"];
        //       parent.mangoAPISrvc.updateSalesTaxInBillingHeader(obj, parent.salesTax.billingHeaderId).subscribe(data => {
        //         // parent.mangoAPISrvc.showLoader(false);
        //       }, (error) => {
        //         parent.mangoAPISrvc.showLoader(false);
        //       });
        //     }
        //   })
      });
    }
  }

  parseStringNumber(toConvert) {
    return numeral(toConvert?.replace('$', '').replace(',', '')).value();
  }

  createTimeSheet(slipEntity, isRetainer) {
    if (!isRetainer) {
      this.createTimeRecordsArr.push(slipEntity);
      // this.mangoAPISrvc.createTimeSheet(slipEntity).subscribe(data => {
      // }, (err) => {
      //   this.mangoAPISrvc.showLoader(false);
      // });
    }
    // else
    // this.mangoAPISrvc.showLoader(false);
  }

  // createRetainerBillingDetailRecord(billingheaderId, headerObj) {
  //   let lineItemSaveList: any = [];

  //   var detailObj = {};
  //   detailObj["BillingHeaderID"] = billingheaderId;
  //   detailObj["ClientID"] = headerObj.ClientID;
  //   detailObj["CompanyID"] = headerObj.CompanyID;
  //   detailObj["WorkCodeID"] = null;
  //   detailObj["ServiceCodeID"] = null;
  //   detailObj["Description"] = headerObj['DescriptionShort'];
  //   detailObj["EngagementTypeID"] = null;
  //   detailObj["ProjectID"] = null;
  //   detailObj["InvoiceDate"] = headerObj.InvoiceDate;
  //   detailObj["InvoiceNumber"] = headerObj.InvoiceNumber;
  //   detailObj["SalesTaxAmount"] = 0;
  //   detailObj["StaffID"] = null;
  //   detailObj["LineItem"] = 1;
  //   detailObj["Amount"] = headerObj.InvoiceAmount;
  //   detailObj["OriginatingPartnerID"] = this.clientProfile["OriginatingPartnerID"];
  //   detailObj["BillingPartnerID"] = this.clientProfile["BillingPartnerID"];
  //   detailObj["GroupDescriptionIDArray"] = this.clientProfile["GroupDescriptionIDArray"];
  //   detailObj["ClientTypeID"] = this.clientProfile["ClientTypeID"];

  //   lineItemSaveList.push(detailObj);

  //   this.processBillingDetailRecords(lineItemSaveList).subscribe((data) => {
  //     this.mangoAPISrvc.notify(
  //       "success",
  //       "Success!",
  //       "Retainer Invoice successfully created."
  //     );
  //   });
  // }

  processBillingDetailRecords(selectedItemList) {
    const observableBatch = [];
    const parent = this;
    // const headers = new HttpHeaders()
    //   .set("content-type", "application/json")
    //   .set("Authorization", parent.encrDecSrvc.getObject("token"));
    selectedItemList.forEach((selectedItem, key) => {
      // let urlOne = parent.http.post(
      //   `${environment.API_URL}/billingDetail/create`,
      //   selectedItem,
      //   { headers: headers }
      // );
      // observableBatch.push(urlOne);
      this.createBillingDetailsArr.push(selectedItem);
    });

    // return forkJoin(observableBatch);
  }

  processRecurringInvoiceDetailRecords(selectedItemList) {
    const observableBatch = [];
    const parent = this;
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Authorization', parent.encrDecSrvc.getObject('token'));
    selectedItemList.forEach((selectedItem, key) => {
      const urlOne = parent.http.put(
        `${environment.API_URL}/recurringInvoice/detail/updateDetail/${selectedItem['RecurringInvoiceDetailID']}`,
        selectedItem,
        { headers: headers }
      );
      observableBatch.push(urlOne);
    });
    return forkJoin(observableBatch);
  }

  adjustDateBasedOnFrequency(itemData, property, date, frequency) {
    const dateFormat = 'MM-DD-YYYY';
    switch (frequency) {
      case 'Weekly':
        itemData[property] = new Date(moment(date, dateFormat).add(7, 'days').toString());
        break;
      case 'Bi-Weekly':
        itemData[property] = new Date(moment(date, dateFormat).add(14, 'days').toString());
        break;
      case 'Monthly':
        const currDate = moment(date).format('YYYY-MM-DD');
        const endOfMonth = moment(date).endOf('month').format('YYYY-MM-DD');
        if (currDate == endOfMonth)
          itemData[property] = new Date(
            moment(date, dateFormat).add(1, 'months').endOf('month').toString()
          );
        else itemData[property] = new Date(moment(date, dateFormat).add(1, 'months').toString());
        break;
      case 'Semi-Monthly':
        itemData[property] = new Date(moment(date, dateFormat).add(15, 'days').toString());
        break;
      case 'Quarterly':
        itemData[property] = new Date(moment(date, dateFormat).add(3, 'months').toString());
        break;
      case 'Semi-Anually':
        itemData[property] = new Date(moment(date, dateFormat).add(6, 'months').toString());
        break;
      case 'Annually':
        itemData[property] = new Date(moment(date, dateFormat).add(1, 'years').toString());
        break;
    }
  }

  updateInvoice(invoiceNumber) {
    const lastInvoiceObj = {};
    lastInvoiceObj['NextInvoiceNumber'] = invoiceNumber;
    const parent = this;
    this.mangoAPISrvc.updateLastInvoiceNumber(lastInvoiceObj).subscribe(data => {
    }, (error) => {
      this.mangoAPISrvc.showLoader(false);
    });
  }

  updateTimeSlipsRecords(selectedItemList) {
    const observableBatch = [];
    selectedItemList.forEach((selectedItem) => {
      observableBatch.push(this.mangoAPISrvc.updateTimeSheet(selectedItem['SlipMasterID'], selectedItem))
    });
    return forkJoin(observableBatch);
  }

  createTimeSlipsRecords(selectedItemList) {
    const observableBatch = [];
    selectedItemList.forEach((selectedItem) => {
      observableBatch.push(this.mangoAPISrvc.createTimeSheet(selectedItem))
    });
    return forkJoin(observableBatch);
  }

  /* drop downs on row */
  handleDropSelectClick(event: any, data: any, formtype: any, isDetail?) {
    if (formtype == 'Staff') {
      data['StaffName'] = event.label;
      data['StaffID'] = event.value;
    } else if (formtype == 'EngagementName') {
      data['EngagementName'] = event.label;
      data['ProjectMasterID'] = event.value;
      data['EngagementTypeID'] = this.engagementListItems.filter(
        item => item.ProjectMasterID == event.value
      )[0]['EngagementTypeID'];
      if (isDetail) {
        const sameProjectMasterID = this.selectedDetails
          .filter(detail => !detail['IsNew'] && detail['ProjectMasterID'] == event.value);
        if (sameProjectMasterID.length > 0) {
          data['ProjectMasterDuplicate'] = true;
          data['ReleaseDate'] = sameProjectMasterID[0]['ReleaseDate'];
          data['isReleaseTime'] = sameProjectMasterID[0]['isReleaseTime'];
        } else {
          data['ProjectMasterDuplicate'] = false;
        }
      }
    } else if (formtype == 'RecurringGroupDescription') {
      const recurringGroupObj = this.recurringInvoiceGroupList.filter((item) => {
        return item.value == event.value;
      });
      if (recurringGroupObj.length == 0) {
        return false;
      }
      data['RecurringInvoiceGroupID'] = recurringGroupObj[0].value;
      if (recurringGroupObj[0].value) {
        data['RecurringGroupDescription'] = recurringGroupObj[0].label.split('-')[0];
      } else {
        data['RecurringGroupDescription'] = '';
      }
    } else if (formtype == 'ExpenseCode') {
      data['ExpenseCode'] = this.expenseList?.filter(item => item.value == event.value)[0]['label'];
      data['ExpenseCodeID'] = event.value;
    }
  }

  addRow(rowIndex, itemdata) {
    const obj = {};
    obj['BillAmount'] = itemdata['BillAmount'] ? itemdata['BillAmount'] : 0;
    obj['BottomMemo'] = itemdata['BottomMemo'] ? itemdata['BottomMemo'] : '';
    obj['ClientID'] = null;
    obj['ClientName'] = null;
    obj['EngagementName'] = '';
    obj['Frequency'] = itemdata['Frequency'] ? itemdata['Frequency'] : '';
    obj['Inactive'] = itemdata['Inactive'] ? itemdata['Inactive'] : false;
    obj['InvoiceShortDescription'] = itemdata['InvoiceShortDescription']
      ? itemdata['InvoiceShortDescription']
      : '';
    obj['MarkInvoicePosted'] = itemdata['MarkInvoicePosted']
      ? itemdata['MarkInvoicePosted']
      : false;

    //if we have only one item
    if (this.recurringInvoiceGroupList.length == 1) {
      obj['RecurringGroupDescription'] = this.recurringInvoiceGroupList[0]['label'];
      obj['RecurringInvoiceGroupID'] = this.recurringInvoiceGroupList[0]['value'];
    } else {
      obj['RecurringGroupDescription'] = itemdata['RecurringGroupDescription']
        ? itemdata['RecurringGroupDescription']
        : '';
      obj['RecurringInvoiceGroupID'] = itemdata['RecurringInvoiceGroupID']
        ? itemdata['RecurringInvoiceGroupID']
        : '';
    }

    obj['ReleaseExpense'] = itemdata['ReleaseExpense'] ? itemdata['ReleaseExpense'] : false;
    obj['ReleaseTime'] = itemdata['ReleaseTime'] ? itemdata['ReleaseTime'] : false;

    //obj['ServiceCode'] = itemdata['ServiceCode'] ? itemdata['ServiceCode'] : "";
    //obj['ServiceCodeID'] = itemdata['ServiceCodeID'] ? itemdata['ServiceCodeID'] : "";
    obj['StaffID'] = itemdata['StaffID'] ? itemdata['StaffID'] : '';
    obj['StaffName'] = itemdata['StaffName'] ? itemdata['StaffName'] : '';
    obj['TopMemo'] = itemdata['TopMemo'] ? itemdata['TopMemo'] : '';
    obj['CompanyID'] = itemdata['CompanyID'] ? itemdata['CompanyID'] : '';
    obj['isNew'] = true;
    if (rowIndex == -1) {
      obj['NextInvoiceDate'] = new Date();
      obj['PeriodFrom'] = new Date();
      obj['PeriodTo'] = new Date();
      obj['RecurringHeaderID'] = 1;
      this.headerListItems.push(obj);
    } else {
      obj['NextInvoiceDate'] = new Date(itemdata['NextInvoiceDate']);
      obj['PeriodFrom'] = new Date(itemdata['PeriodFrom']);
      obj['PeriodTo'] = new Date(itemdata['PeriodTo']);
      obj['RecurringHeaderID'] = this.maxRecurringHeaderID(this.headerListItems) + 1;
      this.headerListItems.splice(rowIndex + 1, 0, obj);
    }
    this.totalAmount = numeral(
      this.headerListItems.reduce(function (a, b) {
        return a + +numeral(b['BillAmount']).value();
      }, 0)
    ).format('0,0.00');
    this.verifyNewRecordPresents();
  }

  addRecurringInvoiceHeader(dt?: any) {
    if (this.headerListItems.filter(item => item['isNew']).length > 0) {
      this.mangoAPISrvc.notify(
        'error',
        this.translate.instant('error'),
        this.translate.instant('cant-add-please-save')
      );
      return;
    }
    const obj = { isNew: true, RecurringInvoiceHeaderID: null, Frequency: 'Weekly', rowCount: 0, InvoiceAmount: 0 };
    if (this.recurringInvoiceGroupList.length > 0) {
      obj['RecurringInvoiceGroupID'] = this.recurringInvoiceGroupList[0].value;
      obj['RecurringGroupDescription'] = this.recurringInvoiceGroupList[0].label;
    }
    if (this.headerListItems.length > 0) {
      const copy = this.headerListItems[this.headerListItems.length - 1];
      obj['RecurringInvoiceGroupID'] = copy['RecurringInvoiceGroupID'];
      obj['RecurringGroupDescription'] = copy['RecurringGroupDescription'];
      obj['Description'] = copy['Description'];
      obj['Frequency'] = copy['Frequency'];
      obj['ScheduledDate'] = copy['ScheduledDate'];
    }
    //this.headerListItems.push(obj);
    this.headerListItems.splice(this.headerListItems.length + 1, 0, obj);
    this.dt._value = this.headerListItems;
    if (this.dt.filteredValue) this.dt.filteredValue.push(obj);
    this.onRowEditInit1(null, dt, obj);

    setTimeout(() => {
      this.scrollDownToNewRow('new-row');
    }, 500);
  }

  onRowEditInit1(index, dt, data?) {
    this.removeRowEditMode();
    data['IsRowEditing'] = true;
    if (dt) dt.editingRowKeys[index] = true;
    if (
      Object.keys(this.clonedData).length === 0 ||
      (this.clonedData['RecurringInvoiceHeaderID'] &&
        this.clonedData['RecurringInvoiceHeaderID'] != data['RecurringInvoiceHeaderID']) ||
      (this.clonedData['RecurringInvoiceDetailID'] &&
        this.clonedData['RecurringInvoiceDetailID'] != data['RecurringInvoiceDetailID']) ||
      (this.clonedData['RecurringInvoiceExpenseID'] &&
        this.clonedData['RecurringInvoiceExpenseID'] != data['RecurringInvoiceExpenseID'])
    ) {
      this.clonedData = {};
      this.clonedData = { ...data };
    }
  }

  removeRowEditMode() {
    if (this.dt) this.dt.editingRowKeys = {};
    if (this.dtDetails) this.dtDetails.editingRowKeys = {};
    if (this.dtExpenses) this.dtExpenses.editingRowKeys = {};
    if (this.timeRecordsDt) this.timeRecordsDt.editingRowKeys = {};
  }

  onSelectDate(selectedDate: Date, data, type) {
    data[type] = moment(selectedDate).startOf('day').toDate();

    data['IsColumnChanges'] = true;

    if (
      !data.isNew &&
      moment(selectedDate).format('YYYY/MM/DD') !=
        moment(new Date(data['backup-ScheduledDate'])).format('YYYY/MM/DD')
    ) {
      data.isScheduledDateChanged = true;
    }

    this.verifyNewRecordPresents();
  }

  editCellComplete(event: any, data?: any) {
    if (event.type == 'focus') {
      return false;
    }
    if (event?.data) {
      event.data['IsColumnChanges'] = true;
    } else if (data) {
      data['IsColumnChanges'] = true;
    }
    this.verifyNewRecordPresents();
  }

  /*
  saving new record
*/
  recurringInvoiceHeaderValidation(itemData) {
    if (!itemData.ClientID) {
      this.mangoAPISrvc.notify('error', this.translate.instant('error'), 'Client is Required');
      return false;
    } else if (
      (!itemData.RecurringInvoiceGroupID || itemData.RecurringInvoiceGroupID == '') &&
      !itemData.ELProposalID
    ) {
      this.mangoAPISrvc.notify(
        'error',
        this.translate.instant('error'),
        'Invoice Group is Required'
      );
      return false;
    } else if (!itemData.Description || itemData.Description == '') {
      this.mangoAPISrvc.notify(
        'error',
        this.translate.instant('error'),
        'Invoice Description is Required'
      );
      return false;
    } else if (!itemData.Frequency) {
      this.mangoAPISrvc.notify(
        'error',
        this.translate.instant('error'),
        'Invoice Frequency is Required'
      );
      return false;
    } else if (!itemData.ScheduledDate) {
      this.mangoAPISrvc.notify(
        'error',
        this.translate.instant('error'),
        'Invoice Schedule Date is Required'
      );
      return false;
    }
    return true;
  }

  recurringInvoiceExpenseValidation(itemData) {
    if (!itemData.StaffID || itemData.StaffID == '') {
      this.mangoAPISrvc.notify('error', this.translate.instant('error'), 'Staff is Required');
      return false;
    } else if (!itemData.ProjectMasterID || itemData.ProjectMasterID == '') {
      this.mangoAPISrvc.notify('error', this.translate.instant('error'), 'Engagement is Required');
      return false;
    } else if (!itemData.ExpenseCodeID || itemData.ExpenseCodeID == '') {
      this.mangoAPISrvc.notify('error', this.translate.instant('error'), 'Expense is Required');
      return false;
    } else if (!itemData.Units) {
      this.mangoAPISrvc.notify('error', this.translate.instant('error'), 'Units is Required');
      return false;
    } else if (!itemData.Cost) {
      this.mangoAPISrvc.notify(
        'error',
        this.translate.instant('error'),
        'Invoice Cost is Required'
      );
      return false;
    }
    return true;
  }

  recurringInvoiceDetailValidation(itemData) {
    if (!itemData.StaffID || itemData.StaffID == '') {
      this.mangoAPISrvc.notify('error', this.translate.instant('error'), 'Staff is Required');
      return false;
    } else if (!itemData.ProjectMasterID || itemData.ProjectMasterID == '') {
      this.mangoAPISrvc.notify('error', this.translate.instant('error'), 'Engagement is Required');
      return false;
    } else if (!itemData.InvoiceDescription || itemData.InvoiceDescription == '') {
      this.mangoAPISrvc.notify(
        'error',
        this.translate.instant('error'),
        'Invoice Description is Required'
      );
      return false;
    } else if (
      !this.selectedData['isRetainer'] &&
      itemData['isReleaseTime'] &&
      !itemData.ReleaseDate
    ) {
      this.mangoAPISrvc.notify(
        'error',
        this.translate.instant('error'),
        'Release Thru Date is Required'
      );
      return false;
    }
    return true;
  }

  /*
     updating all the records at a time
  */
  updateAll() {
    this.saveAllEditedRows();
    const modifiedList = this.headerListItems.filter((item) => item['IsColumnChanges'] == true);
    const parent = this;

    this.mangoAPISrvc.showLoader(true);

    this.updateRecurringHeaders(modifiedList).subscribe(
      data => {
        this.mangoAPISrvc.showLoader(false);
        this.selectedReviewItems = [];
        this.mangoAPISrvc.notify('success', this.translate.instant('Success'), '');
        parent.verifyNewRecordPresents();
      },
      () => {
        this.mangoAPISrvc.showLoader(false);
        this.mangoAPISrvc.notify(
          'error',
          this.translate.instant('error'),
          AppConstants.updateErrorMsg
        );
      }
    );
  }

  updateRecurringHeaders(selectedItemList) {
    const observableBatch = [];
    const parent = this;
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Authorization', parent.encrDecSrvc.getObject(AppConstants.token));
    selectedItemList.forEach((selectedItem, key) => {
      selectedItem['IsColumnChanges'] = false;
      observableBatch.push(
        this.http.put(
          `${environment.API_URL}/recurringInvoice/header/updateHeader/${selectedItem['RecurringInvoiceHeaderID']}`,
          selectedItem,
          { headers: headers }
        )
      );
    });

    return forkJoin(observableBatch);
  }
  /*
     updating multiple http calls

  */
  processBatchUpdate(selectedItemList) {
    const observableBatch = [];
    const parent = this;
    selectedItemList.forEach((selectedItem, key) => {
      selectedItem['TopMemo'] = parent.topMemoValue;
      selectedItem['BottomMemo'] = parent.bottomMemoValue;
      observableBatch.push(
        this.mangoAPISrvc.updateTopBottomMemoRecurringHeader(
          selectedItem['RecurringInvoiceHeaderID'],
          selectedItem
        )
      );
    });

    return forkJoin(observableBatch);
  }
  /*

  */
  saveTopBottomMemo() {
    const parent = this;

    this.mangoAPISrvc.showLoader(true);

    this.processBatchUpdate(this.selectedReviewItems).subscribe(
      data => {
        this.mangoAPISrvc.showLoader(false);

        parent.IsTopBottomModified = false;
        parent.dialogDisplay = false;
        parent.bottomMemoValue = '';
        parent.topMemoValue = '';
        this.mangoAPISrvc.notify(
          'success',
          this.translate.instant('Success'),
          "Memo's attached Successfully"
        );
      },
      () => {
        this.mangoAPISrvc.showLoader(false);
        this.mangoAPISrvc.notify(
          'error',
          this.translate.instant('error'),
          "Memo's attached Successfully"
        );
      }
    );
  }

  /* Delete row */
  deleteRow(event: any, itemData: any) {
    event.preventDefault();
    //

    if (itemData['schedule_id']) {
      Swal.fire({
        title: this.translate.instant('warning'),
        text: this.translate.instant('recurring-payment-msg'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.translate.instant('yes_continue'),
        cancelButtonText: this.translate.instant('no_delete')
      }).then(result => {
        if (result.value) {
          if (itemData['isNew']) {
            this.headerListItems = this.headerListItems.filter(
              item => item['RecurringInvoiceHeaderID'] != itemData['RecurringInvoiceHeaderID']
            );
            this.totalAmount = numeral(
              this.headerListItems.reduce(function (a, b) {
                return a + +numeral(b['BillAmount']).value();
              }, 0)
            ).format('0,0.00');
            this.verifyNewRecordPresents();
          } else {
            const parent = this;
            Swal.fire({
              title: parent.translate.instant('confirmation'),
              text: parent.translate.instant('recurring_header_delete'),
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: parent.translate.instant('yes_delete'),
              cancelButtonText: parent.translate.instant('no_delete')
            }).then(result => {
              if (result.value) {
                parent.mangoAPISrvc.showLoader(true);
                parent.deleteScheduledInvoice(
                  itemData['RecurringInvoiceHeaderID'],
                  itemData['schedule_id'],
                  false
                );
                parent.mangoAPISrvc
                  .deleteRecurringInvoiceHeader(itemData['RecurringInvoiceHeaderID'])
                  .subscribe(
                    (data: any) => {
                      parent.mangoAPISrvc.showLoader(false);
                      if (data.message) {
                        parent.mangoAPISrvc.notify(
                          'success',
                          parent.translate.instant('Success'),
                          'Successfully deleted.'
                        );
                        this.headerListItems = this.headerListItems.filter(
                          item =>
                            item['RecurringInvoiceHeaderID'] != itemData['RecurringInvoiceHeaderID']
                        );
                        parent.totalAmount = numeral(
                          parent.headerListItems.reduce(function (a, b) {
                            return a + +numeral(b['BillAmount']).value();
                          }, 0)
                        ).format('0,0.00');
                      }
                    },
                    () => {
                      parent.mangoAPISrvc.showLoader(false);
                    }
                  );
              }
            });
          }
        }
      });
    } else {
      if (itemData['isNew']) {
        this.headerListItems = this.headerListItems.filter(
          item => item['RecurringInvoiceHeaderID'] != itemData['RecurringInvoiceHeaderID']
        );
        this.totalAmount = numeral(
          this.headerListItems.reduce(function (a, b) {
            return a + +numeral(b['BillAmount']).value();
          }, 0)
        ).format('0,0.00');
        this.verifyNewRecordPresents();
      } else {
        const parent = this;
        Swal.fire({
          title: parent.translate.instant('confirmation'),
          text: parent.translate.instant('recurring_header_delete'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: parent.translate.instant('yes_delete'),
          cancelButtonText: parent.translate.instant('no_delete')
        }).then(result => {
          if (result.value) {
            parent.mangoAPISrvc.showLoader(true);
            parent.mangoAPISrvc
              .deleteRecurringInvoiceHeader(itemData['RecurringInvoiceHeaderID'])
              .subscribe(
                (data: any) => {
                  parent.mangoAPISrvc.showLoader(false);
                  if (data.message) {
                    parent.mangoAPISrvc.notify(
                      'success',
                      parent.translate.instant('Success'),
                      'Successfully deleted.'
                    );
                    this.headerListItems = this.headerListItems.filter(
                      item =>
                        item['RecurringInvoiceHeaderID'] != itemData['RecurringInvoiceHeaderID']
                    );
                    parent.totalAmount = numeral(
                      parent.headerListItems.reduce(function (a, b) {
                        return a + +numeral(b['BillAmount']).value();
                      }, 0)
                    ).format('0,0.00');
                  }
                },
                () => {
                  parent.mangoAPISrvc.showLoader(false);
                }
              );
          }
        });
      }
    }
  }

  maxRecurringHeaderID(array) {
    return array.reduce((prev, current) =>
      prev['RecurringHeaderID'] > current['RecurringHeaderID'] ? prev : current
    )['RecurringHeaderID'];
  }

  /*
      updating US Money format
  */
  changeUSMoney(evt: any, data: any) {
    const enteredValue = evt.target.value;
    const myNumeral = numeral(enteredValue).value();
    if (myNumeral === null) {
      data.BillAmount = 0.0;
    } else {
      data.BillAmount = myNumeral;
    }
    // data['IsColumnChanges'] = true;
    // this.verifyNewRecordPresents();
    this.totalAmount = numeral(
      this.headerListItems.reduce(function (a, b) {
        return a + +numeral(b['BillAmount']).value();
      }, 0)
    ).format('0,0.00');
  }

  checkIfDetailIsSelected(rowData) {
    return (
      this.selectedDetails.filter(
        detail => detail['RecurringInvoiceDetailID'] == rowData['RecurringInvoiceDetailID']
      ).length > 0
    );
  }

  toggleCheckbox(event, rowIndex, item: any) {
    if (item.schedule_id != null) {
      Swal.fire({
        icon: 'info',
        title: `${this.translate.instant('Warning')}`,
        text: `${this.translate.instant('recurring.single_checkbox_select_stax')}`,
        confirmButtonText: 'Ok',
        showCancelButton: false,
        allowEscapeKey: true,
        allowEnterKey: true
      });
      return false;
    }
    if (this.selectedReviewItems.length > 0 && item.schedule_id != null) {
      this.selectedReviewItems.splice(rowIndex, 1);
      this.selectedReviewItems = [...this.selectedReviewItems];
      this.mangoAPISrvc.notify(
        'info',
        this.translate.instant('Warning'),
        this.translate.instant('recurring-in-process')
      );
      return false;
    }
    this.verifyNewRecordPresents();
    if (!item['rowCount'] || item['rowCount'] == 0) {
      this.selectedReviewItems = this.selectedReviewItems.filter(
        selectedItem => selectedItem['RecurringInvoiceHeaderID'] != item['RecurringInvoiceHeaderID']
      );
      this.mangoAPISrvc.notify(
        'error',
        this.translate.instant('error'),
        this.translate.instant('cant-process-recurring-header-row')
      );
    }
    this.computeHeaderTotalInvoiceAmount();
    this.computeHeaderTotalSalesTax();
    if (!item) {
      return false;
    }
  }

  removeSelectedItems() {
    for (let index = 0; index < this.headerListItems.length; index++) {
      const element = this.headerListItems[index];
      this.selectedReviewItems.splice(index, 1);
      this.dt.toggleRowWithCheckbox(event, element);
    }
  }

  toggleHeaderCheckbox(event) {
    if (this.isHeaderCheckbox) {
      this.isHeaderCheckbox = !this.isHeaderCheckbox;
      this.isProcessDisabled = true;
      this.removeSelectedItems();
      this.headerSalesTaxTotal = 0;
      this.headerInvoiceAmountTotal = 0;
      return;
    } else this.isHeaderCheckbox = !this.isHeaderCheckbox;
    this.verifyNewRecordPresents();
    let isScheduledProcessing = false;
    if (event.checked) {
      this.selectedReviewItems
        .filter(selectedItem => !selectedItem['rowCount'] || selectedItem['rowCount'] == 0)
        .forEach(element => {
          this.dt.toggleRowWithCheckbox(event, element);
        });
      setTimeout(() => {
        for (let index = 0; index < this.headerListItems.length; index++) {
          const element = this.headerListItems[index];
          const rowindex = this.selectedReviewItems.findIndex((item) => (item.RecurringInvoiceHeaderID == element.RecurringInvoiceHeaderID) && element.schedule_id != null);
          if (rowindex > -1) {
            this.selectedReviewItems.splice(rowindex, 1);
            this.selectedReviewItems = [...this.selectedReviewItems];
            isScheduledProcessing = true;
            this.dt.toggleRowWithCheckbox(event, element);
          }
        }
        this.selectedReviewItems = this.selectedReviewItems.filter(
          selectedItem => selectedItem['rowCount'] && selectedItem['rowCount'] > 0
        );
        if (isScheduledProcessing) {
          this.mangoAPISrvc.notify(
            'info',
            this.translate.instant('Warning'),
            this.translate.instant('recurring-in-process')
          );
          return false;
        }
        this.computeHeaderTotalInvoiceAmount();
        this.computeHeaderTotalSalesTax();
      }, 50);
    } else {
      this.selectedReviewItems = [];
      this.computeHeaderTotalInvoiceAmount();
      this.computeHeaderTotalSalesTax();
    }
  }

  computeHeaderTotalSalesTax() {
    let total = 0;
    let temp = [];
    if (this.dt.filteredValue && this.dt.filteredValue.length > 0) {
      temp = this.dt.filteredValue.filter(filtered =>
        this.selectedReviewItems
          .map(item => item['RecurringInvoiceHeaderID'])
          .includes(filtered['RecurringInvoiceHeaderID'])
      );
    } else {
      temp = this.selectedReviewItems;
    }
    temp.map(item => (total += Number(item['SalesTax'])));
    this.headerSalesTaxTotal = total;
  }

  computeHeaderTotalInvoiceAmount() {
    let total = 0;
    let temp = [];
    if (this.dt.filteredValue && this.dt.filteredValue.length > 0) {
      temp = this.dt.filteredValue.filter(filtered =>
        this.selectedReviewItems
          .map(item => item['RecurringInvoiceHeaderID'])
          .includes(filtered['RecurringInvoiceHeaderID'])
      );
    } else {
      temp = this.selectedReviewItems;
    }
    temp.map(item => (total += Number(item['InvoiceAmount'])));
    this.headerInvoiceAmountTotal = total;
  }

  toggleDetailCheckbox(event) {
    if (this.selectedDetails.filter(detail => Number(detail['Amount']) == 0).length > 0) {
      this.mangoAPISrvc.notify(
        'error',
        this.translate.instant('error'),
        this.translate.instant('cant-process-detail-zero-amount')
      );
      this.selectedDetails
        .filter(detail => Number(detail['Amount']) == 0)
        .forEach(detail => {
          this.dtDetails.toggleRowWithCheckbox(event, detail);
        });
      this.selectedDetails = this.selectedDetails.filter(detail => Number(detail['Amount']) != 0);
    }
    //restore original order
    const arr = this.selectedDetails.map(detail => detail['RecurringInvoiceDetailID']);
    this.selectedDetails = this.recurringInvoiceDetails.filter(detail => arr.includes(detail['RecurringInvoiceDetailID']));

    const projectMasterIDArray = [];
    this.selectedDetails.map(detail => {
      if (projectMasterIDArray.includes(detail['ProjectMasterID'])) {
        detail['ProjectMasterDuplicate'] = true;
      } else {
        detail['ProjectMasterDuplicate'] = false;
        projectMasterIDArray.push(detail['ProjectMasterID']);
      }
      this.computeWriteUpValue(detail);
    });

    this.selectedData['rowCount'] = this.selectedDetails.length + this.selectedExpenses.length;
    this.detailsByHeaderID[this.selectedData['RecurringInvoiceHeaderID']] = this.selectedDetails;
    this.computeDetailFooter();
    this.setHeaderRowAmounts();
  }

  toggleExpensesCheckBox(event) {
    if (this.selectedExpenses.filter(detail => Number(detail['Amount']) == 0).length > 0) {
      this.mangoAPISrvc.notify(
        'error',
        this.translate.instant('error'),
        this.translate.instant('cant-process-detail-zero-amount')
      );
      this.selectedExpenses
        .filter(expense => Number(expense['Amount']) == 0)
        .forEach(expense => {
          this.dtExpenses.toggleRowWithCheckbox(event, expense);
        });
      this.selectedExpenses = this.selectedExpenses.filter(detail => Number(detail['Amount']) != 0);
    }
    this.selectedData['rowCount'] = this.selectedDetails.length + this.selectedExpenses.length;
    this.expensesByHeaderID[this.selectedData['RecurringInvoiceHeaderID']] = this.selectedExpenses;
    this.computeExpenseFooter();
    this.setHeaderRowAmounts();
  }

  toggleTimeRecordCheckBox(rowData) {
    const obj = this.timeRecordsByHeaderIDAndDetailID[rowData['RecurringInvoiceHeaderID']] || {};
    obj[rowData['RecurringInvoiceDetailID']] = this.selectedTimeRecords;
    this.timeRecordsByHeaderIDAndDetailID[rowData['RecurringInvoiceHeaderID']] = obj;
    this.computeTimeRecordsFooter(
      rowData['RecurringInvoiceHeaderID'],
      rowData['RecurringInvoiceDetailID']
    );
    this.setDetailRowAmounts(this.selectedEngagment);
    this.computeWriteUpValue(this.selectedEngagment);
    this.computeDetailFooter();
  }

  setDetailRowAmounts(itemData) {
    //sets the detail row values depending on the selected time records
    itemData['laboramount'] = this.timeRecordsFooter['laboramount'] || 0;
    itemData['totaltime'] = this.timeRecordsFooter['totalTime'] || 0;
    itemData['nonbillableamount'] = this.timeRecordsFooter['nonbillableamount'] || 0;
    this.selectedDetails.map(detail => {
      if (detail['RecurringInvoiceDetailID'] == itemData['RecurringInvoiceDetailID']) {
        detail['laboramount'] = this.timeRecordsFooter['laboramount'] || 0;
        detail['totaltime'] = this.timeRecordsFooter['totalTime'] || 0;
        detail['nonbillableamount'] = this.timeRecordsFooter['nonbillableamount'] || 0;
      }
    });
  }

  setHeaderRowAmounts() {
    //sets the detail row values depending on the selected time records
    this.selectedData['InvoiceAmount'] =
      this.parentDetailFooter['amount'] + this.parentExpenseFooter['amount'];
    this.computeHeaderTotalInvoiceAmount();
    this.computeHeaderTotalSalesTax();
  }

  unselectSelectedReviewItems() {
    this.isProcessDisabled = true;
    this.selectedReviewItems = [];
    this.computeHeaderTotalInvoiceAmount();
    this.computeHeaderTotalSalesTax();
  }

  verifyNewRecordPresents() {
    setTimeout(() => {
      const newRecordsList = this.headerListItems.filter((item) => item['isNew'] == true);
      const colRecordsList = this.headerListItems.filter((item) => item['IsColumnChanges'] == true);

      if (newRecordsList.length > 0) {
        this.isUpdateAllenabled = false;
        this.isProcessDisabled = true;
      } else {
        // Memo button enbale/disable
        if (this.selectedReviewItems.length > 0) {
          this.isProcessDisabled = false;
        } else {
          this.isProcessDisabled = true;
        }
        this.isUpdateAllenabled = false;
        this.isProcessDisabled = false;
      }
      if (this.selectedReviewItems.length == 0 && !this.isProcessDisabled) {
        this.isProcessDisabled = true;
      }
    }, 500);
  }

  saveInvoice() {
    if (!this.IsEditorChanges) { return }
    const parent = this;

    parent.mangoAPISrvc.showLoader(true);

    parent.mangoAPISrvc.saveInvoice(parent.invoiceList).subscribe(
      function (data: any) {
        parent.mangoAPISrvc.showLoader(false);
        parent.mangoAPISrvc.notify('success', parent.translate.instant('Success'), data.message);
      },
      err => {
        parent.mangoAPISrvc.showLoader(false);
        parent.mangoAPISrvc.notify(
          'error',
          parent.translate.instant('error'),
          AppConstants.createErrorMsg
        );
      }
    );
  }

  onEditorChange(evt: any) {
    this.IsEditorChanges = true;
  }

  onTopBottomEditorChange(evt: any) {
    this.IsTopBottomModified = true;
  }

  enableEditor() {
    this.IsDataReadOnly = !this.IsDataReadOnly;
    if (this.IsDataReadOnly) {
      this.btnText = 'Edit';
      this.saveInvoice();
    } else {
      this.btnText = 'Save';
    }
  }

  applySelectedItems(source, destination, labelStr, valueStr, initials?) {
    if (source.length > 0) {
      for (let j = 0; j < source.length; ++j) {
        const innerItem = source[j];
        const tempObj = {};
        if (labelStr == 'ServiceCode') {
          tempObj['label'] = innerItem[labelStr] + ' - ' + innerItem['Description'];
        } else {
          tempObj['label'] = innerItem[labelStr];
        }
        if (initials) {
          tempObj['initials'] = innerItem[initials];
        }
        tempObj['value'] = innerItem[valueStr];
        destination.push(tempObj);
      }
    }
    return destination;
  }

  closeCreditCardForm() {
    this.myPaymentForm.reset();
    this.cardDialogDisplay = false;
    this.isDialogFormValid = false;
  }

  openDialog(rowIndex, rowData) {
    this.cardDialogDisplay = true;
    this.updateDialogForm(rowData);
  }

  singularProcessPayment() {}

  initializeDialogForm() {
    this.myPaymentForm = this._fb.group({
      nameOnCard: ['', [Validators.minLength(1)]],
      cardno: ['', [Validators.minLength(16)]],
      expDate: ['', [Validators.minLength(5)]],
      securityCode: ['', [Validators.minLength(3)]],
      customerDesc: [''],
      firstName: [''],
      lastName: [''],
      address: [''],
      phoneNum: [''],
      city: [''],
      state: [''],
      zip: [''],
      country: [''],
      email: [''],
      company: [''],
      amount: [''],
      fname: [''],
      lname: [''],
      TransCode: [null, [<any>Validators.required]],
      RoutingNumber: [''],
      AccountNumber: [''],
      isBusiness: [false],
      InvoiceReminderActivate: [false],
      InvoiceReminderStartDate: [new Date()]
    });
    this.myPaymentForm.valueChanges.subscribe(data => {
      this.validateDialogForm();
      this.isValidStaxForm();
    });
  }

  focusCard(index, element) {
    const iframeRef = $('iframe')[index];
    $(iframeRef).focus();
    $(iframeRef).contents().find(`#${element}`).focus();
    const formData = this.myPaymentForm.value;
    if (element == 'cvv') {
      if (!formData.fname || !formData.lname) {
        this.isProcessing = true;
      } else {
        this.isProcessing = false;
      }
    }
  }

  /*
  Verifing the form
  */
  validateDialogForm() {
    let isInValidData = false;
    let istouchedData = false;
    Object.keys(this.myPaymentForm.controls).forEach(key => {
      if (this.myPaymentForm.get(key).invalid) {
        isInValidData = true;
      }
      if (this.myPaymentForm.get(key).dirty) {
        istouchedData = true;
      }
    });
    if (!isInValidData && this.myPaymentForm.dirty) {
      this.isDialogFormValid = true;
    } else {
      this.isDialogFormValid = false;
    }
  }

  /*
  updateDialogForm form data
  */
  updateDialogForm(data) {
    this.myPaymentForm.controls['nameOnCard'].setValue('');
    this.myPaymentForm.controls['cardno'].setValue('');
    this.myPaymentForm.controls['expDate'].setValue('');
    this.myPaymentForm.controls['securityCode'].setValue('');
    this.myPaymentForm.controls['customerDesc'].setValue('');
    this.myPaymentForm.controls['firstName'].setValue('');
    this.myPaymentForm.controls['lastName'].setValue('');
    this.myPaymentForm.controls['address'].setValue('');
    this.myPaymentForm.controls['phoneNum'].setValue('');
    this.myPaymentForm.controls['city'].setValue('');
    this.myPaymentForm.controls['state'].setValue('');
    this.myPaymentForm.controls['zip'].setValue('');
    this.myPaymentForm.controls['country'].setValue('');
    this.myPaymentForm.controls['email'].setValue('');
    this.myPaymentForm.controls['company'].setValue(
      this.encrDecSrvc.getObject(AppConstants.companyName)
    );
    this.myPaymentForm.controls['amount'].setValue(
      '$' + numeral(data['BillAmount']).format('0,0.00')
    );
  }

  stopEdit(event) {
    event.stopPropagation();
    $(this.dt.editingCell).removeClass('ui-editing-cell');
    this.dt.editingCell = null;
  }

  /*
     Fetching company data from Server
     */
  getCompanyData() {
    this.mangoAPISrvc.showLoader(true);

    this.mangoAPISrvc.getCompanyViewData().subscribe(
      data => {
        this.mangoAPISrvc.showLoader(false);
        this.isSkipInvoiceReview = data['SkipInvoiceReview'];

        if (
          data['EnrollmentPending'] != null &&
          data['ClientPartnerKey'] != null &&
          data['ClientPartnerKey'] != null
        ) {
          this.isEnrollForm = true;
        } else {
          this.isEnrollForm = false;
        }
      },
      err => {
        this.mangoAPISrvc.showLoader(false);
        this.mangoAPISrvc.notify(
          'error',
          this.translate.instant('error'),
          AppConstants.fetchErrorMsg
        );
      }
    );
  }

  onRowEditInit(data: any) {
    if (Object.keys(this.clonedData).length === 0) {
      this.clonedData = {};
      this.clonedData = { ...data };
    }
    // data['IsColumnChanges'] = true
  }

  onRowEditSave(event: any, data: any, type: string) {
    data['IsColumnChanges'] = true;
    this.sidePanelDisplay = false;
    this.clonedData = {};
    this.verifyNewRecordPresents();
  }

  onRowEditCancel(data: any, index: number, type?) {
    data = Object.assign(data, this.clonedData);
    data['IsRowEditing'] = false;
    data['IsEdited'] = false;
    // data['IsColumnChanges'] = false
    this.clonedData = {};
    if (data['isNew'] || data['IsNew']) {
      switch (type) {
        case 'header':
          this.headerListItems = this.headerListItems.filter(item => !item['isNew']);
          break;
        case 'detail':
          this.recurringInvoiceDetails = this.recurringInvoiceDetails.filter(
            item => !item['IsNew']
          );
          break;
        case 'expense':
          this.recurringInvoiceExpenses = this.recurringInvoiceExpenses.filter(
            item => !item['IsNew']
          );
          break;
      }
    }
  }

  saveAllEditedRows() {
    $(`.save-btn-edit-row`).click();
  }

  setLaborRates() {
    const parent = this;
    if ((parent.invoiceList.ActivateLaborRates || parent.invoiceList.ActivateExpenseRates) && parent.clientProfile.SalesTaxLevel != 'None') {
      if (parent.clientProfile.SalesTaxLevel == 'ClientRate') {
        parent.salesTax.Labor = parent.clientProfile.Tax1ID
          ? numeral(parent.clientProfile.Tax1ID).value()
          : 0;
        parent.salesTax.Expense = parent.clientProfile.Tax2ID
          ? numeral(parent.clientProfile.Tax2ID).value()
          : 0;
      } else if (parent.clientProfile.SalesTaxLevel == 'CompanyLocationRate') {
        const selectedCompanyLocation = this.companyLocations.filter((location) => location['value'] == parent.clientProfile.CompanyMangoLocationID)[0];
        parent.salesTax.Labor = selectedCompanyLocation['laborRate'] ? numeral(selectedCompanyLocation['laborRate']).value() : 0;
        parent.salesTax.Expense = selectedCompanyLocation['expenseRate'] ? numeral(selectedCompanyLocation['expenseRate']).value() : 0;
      } else {
        parent.salesTax.Labor = parent.invoiceList.LaborRate1
          ? numeral(parent.invoiceList.LaborRate1).value()
          : 0;
        parent.salesTax.Expense = parent.invoiceList.ExpenseRate1
          ? numeral(parent.invoiceList.ExpenseRate1).value()
          : 0;
      }
    }
  }

  getCompanyLocations() {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getCompanyLocations(this.companyId).subscribe(
      (data: any) => {
        this.companyLocations = data.map(location => {
          return {
            value: location.CompanyMangoLocationID,
            label: location.CompanyLocation,
            laborRate: location.TaxRateLaborL,
            expenseRate: location.TaxRateExpenseL
          };
        });
        this.mangoAPISrvc.showLoader(false);
      },
      err => this.mangoAPISrvc.showLoader(false)
    );
  }

  scrollDownToNewRow(id) {
    const el = document.getElementById(id);
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
      setTimeout(() => {
        const el1 = document.querySelectorAll(`.${id}`);
        el1[0].querySelectorAll("textarea")[0]?.focus();
      }, 500);
    }
  }

  deleteClientAlert(itemData, index, slipMaster) {
    itemData['Billed'] = itemData['Billed'] ? itemData['Billed'] : false;
    itemData['MarkSlipsBilled'] = itemData['MarkSlipsBilled'] ? itemData['MarkSlipsBilled'] : false;
    if (itemData['Billed'] && !itemData['MarkSlipsBilled']) {
      this.mangoAPISrvc.notify(
        'error',
        'Error!',
        'This time entry has been billed. Deleting is not allowed.'
      );
      return false;
    } else {
      const parentObj = this;
      Swal.fire({
        title: parentObj.translate.instant('confirmation'),
        text: parentObj.translate.instant('delete_alert'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: parentObj.translate.instant('yes_delete'),
        cancelButtonText: parentObj.translate.instant('no_delete')
      }).then(result => {
        if (result.value) {
          parentObj.mangoAPISrvc.showLoader(true);
          parentObj.mangoAPISrvc.deleteTimeSheet(itemData.SlipMasterID).subscribe(function (data) {
            parentObj.mangoAPISrvc.showLoader(false);
            //parentObj.onDeleteSuccess(itemData.SlipMasterID);
            parentObj.mangoAPISrvc.notify(
              'success',
              parentObj.translate.instant('Success'),
              AppConstants.deleteMessage
            );
            parentObj.mangoAPISrvc.showLoader(true);

            parentObj.getTimeRecordsParent(
              parentObj.selectedEngagment['RecurringInvoiceHeaderID'],
              true,
              true
            );

            parentObj.timeRecordsByHeaderIDAndDetailID = {};
            parentObj.getTimeRecordsByEngagementId(
              parentObj.selectedEngagment['RecurringInvoiceHeaderID'],
              parentObj.selectedEngagment['CompanyID'],
              parentObj.selectedEngagment['ProjectMasterID'],
              parentObj.selectedEngagment['RecurringInvoiceDetailID']
            );
          }),
            error => {
              parentObj.mangoAPISrvc.notify(
                'error',
                parentObj.translate.instant('error'),
                AppConstants.deleteErrorMsg
              );
              parentObj.mangoAPISrvc.showLoader(false);
            };
        }
      });
    }
  }

  genarateIFrames() {
    const self = this;
    self.mangoAPISrvc.showLoader(true);
    setTimeout(() => {
      self.mangoAPISrvc.showLoader(false);
      //var form = document.querySelector('form');
      // self should get from company table
      self.fattJs = new FattJs(self.companyDetails.spkuid, {
        number: {
          id: 'fattjs-number',
          placeholder: '0000 0000 0000 0000',
          format: 'prettyFormat',
          style:
            'width: 95%; height:90%; border-radius: 3px; border: 1px solid #ccc; padding: .5em .5em; font-size: 91%;'
        },
        cvv: {
          id: 'fattjs-cvv',
          placeholder: '000',
          style:
            'width: 89%; height:90%; border-radius: 3px; border: 1px solid #ccc; padding: .5em .5em; font-size: 91%;'
        }
      });

      self.fattJs
        .showCardForm()
        .then(handler => {
          console.log(handler);
        })
        .catch(err => {});

      self.fattJs.on('card_form_complete', message => {
        console.log(message);
      });

      self.fattJs.on('card_form_incomplete', message => {
        console.log(message);
      });
    }, 500);
  }

  getDefaultCount(rowData) {
    switch (rowData.Frequency) {
      case 'Weekly':
        rowData['count'] = 1;
        break;
      case 'Bi-Weekly':
        rowData['count'] = 2;
        break;
      case 'Monthly':
        rowData['count'] = 4;
        break;
      case 'Semi-Monthly':
        rowData['count'] = 6;
        break;
      case 'Quarterly':
        rowData['count'] = 12;
        break;
      case 'Semi-Anually':
        rowData['count'] = 24;
        break;
      case 'Annually':
        rowData['count'] = 48;
        break;
    }
  }

  async getCardType(customerCardID, companyId, eventType) {
    const self = this;
    return new Promise(function (resolve, reject) {
      try {
        self.mangoAPISrvc
          .performStaxActions({ ccid: customerCardID }, companyId, eventType)
          .subscribe(function (data) {
            resolve(data);
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  async addRecurringPayments(rowData) {
    this.myPaymentForm.reset();
    if (!rowData.isRecurringAllowed) {
      Swal.fire({
        icon: 'info',
        title: this.translate.instant('Warning'),
        html: this.translate.instant('reurring-error'),
        showConfirmButton: true
      });

      return false;
    }
    this.mangoAPISrvc.showLoader(true);
    this.selectedCustomer = null;
    this.sTaxProfile = null;
    this.cards = [];
    this.selectedCustomer = this.clientProfile = this.clientList.filter(
      client => client.ClientID === rowData['ClientID']
    )[0];
    this.addNewCrCardDialog = false;
    this.isDebitCard = false;
    this.defaultStaxObj = { "SurChargeAmount": 0, "withSurcharge": 0, "rule": null, "customer_id": null, "total": null, "meta": { "tax": 0, "subtotal": null, "otherField1": null, "otherField2": null, "reference": null }, "url": "", "email_notification": false, "payment_method_id": null };
    this.currentRuleStr = rowData["RuleString"] ? rowData["RuleString"] : '';
    this.defaultStaxObj['url'] = `${this.recurringUrl}/api/Stax/recurringPaymentCallback/${this.companyDetails.CompanyID}`;
    this.salesTaxObj = await this.getSalesTaxObj(rowData.RecurringInvoiceHeaderID);
    if (this.salesTaxObj && this.salesTaxObj['serviceTax']) {
      this.salesTaxObj['serviceTax'] = this.salesTaxObj
        ? this.mangoUtils.roundOffDecimals(this.salesTaxObj['serviceTax'])
        : 0;
    }
    this.defaultStaxObj['total'] =
      numeral(rowData['InvoiceAmount']).value() + this.salesTaxObj['serviceTax'];
    this.defaultStaxObj['meta']['tax'] = 0;
    this.defaultStaxObj['meta']['reference'] = this.mangoUtils.generateUUID() + '$*@^$RP';
    this.defaultStaxObj['meta']['subtotal'] = numeral(this.defaultStaxObj['total']).value();
    this.defaultStaxObj['meta']['otherField1'] =
      'from Recurring Invoice | User : ' +
      this.userName +
      ' | ClientName :' +
      this.selectedCustomer['ClientName'];
    this.defaultStaxObj['meta']['otherField2'] = rowData.RecurringInvoiceHeaderID;

    this.defaultStaxObj['customer_id'] = null;
    this.defaultStaxObj['payment_method_id'] = null;
    this.defaultStaxObj['SurChargeAmount'] = 0;
    this.defaultStaxObj['withSurcharge'] = 0;

    this.isNeverEnds = false;
    this.sTaxProfile = null;
    if (!this.currentRuleStr || !rowData.schedule_id) {
      //this.getDefaultCount(rowData);
      rowData['count'] = 99;
      let dateStart: any = rowData['ScheduledDate']
        ? moment(rowData['ScheduledDate']).format('YYYYMMDDThhmmss')
        : null;
      dateStart = this.convertUTCDate(dateStart);
      this.currentRuleStr = `DTSTART=${dateStart};FREQ=${this.getFreqStr(
        rowData['Frequency'].toUpperCase()
      )};COUNT=${rowData['count']}`;
    } else {
      const scheduleData = await this.getScheduledInvoice(rowData.schedule_id);
      this.isProcessing = true;
      if (scheduleData['max']) {
        const value = scheduleData['max'] - scheduleData['count']
        rowData['count'] = value <= 0 ? 1 : value;
      } else {
        this.isNeverEnds = true;
        rowData['count'] = 99;
      }
      //numeral(rowData["RuleString"].substring(rowData["RuleString"].indexOf('COUNT=') + 6, rowData["RuleString"].indexOf(';INTERVAL='))).value();
    }
    this.recurringRowData = rowData;
    this.mangoAPISrvc.getPaymentMethodInfo(rowData.ClientID).subscribe(
      async (data: any) => {
        this.cards = data;
        let card = data.filter(item => item.spmid == `${rowData.StaxPaymentMethodID}`);
        this.selectedModeOfPayment = card && card.length > 0 ? card[0] : null;
        if (this.selectedModeOfPayment && this.selectedModeOfPayment['spmid']) {
          this.sTaxProfile = this.selectedModeOfPayment;
          this.addNewCrCardDialog = false;
          this.isCrCard = this.selectedModeOfPayment.TransType == 'CC' ? true : false;
          this.defaultStaxObj['customer_id'] = this.selectedModeOfPayment['scid'];
          this.defaultStaxObj['payment_method_id'] = this.selectedModeOfPayment['spmid'];
          this.selectedModeOfPayment = this.selectedModeOfPayment.TransType;

          if (this.sTaxProfile && this.sTaxProfile.CustomerCardID) {
            let cardTypeObj = await this.getCardType(
              this.sTaxProfile.CustomerCardID,
              this.companyDetails.CompanyID,
              'ct'
            );
            this.isDebitCard = cardTypeObj['bin_type'] == 'DEBIT' ? true : false;
            if (!this.sTaxProfile['CardType']) {
              this.sTaxProfile['CardType'] = cardTypeObj['bank_type']
                ? cardTypeObj['bank_type']
                : null;
              this.mangoAPISrvc
                .updateCreditCardDetails(this.sTaxProfile.CustomerCardID, this.sTaxProfile)
                .subscribe((resultRes: any) => {});
            }
          }
          if (this.sTaxProfile && this.isCrCard && this.companyDetails.isSurchargeEnabled) {
            let surchargeObj = await this.getSurchargeAmount(
              this.sTaxProfile.CustomerCardID,
              this.defaultStaxObj['total'],
              this.companyDetails.CompanyID,
              'sc'
            );
            if (surchargeObj) {
              this.defaultStaxObj['SurChargeAmount'] = surchargeObj['surcharge_amount'];
              this.defaultStaxObj['withSurcharge'] = surchargeObj['total_with_surcharge_amount'];
              this.defaultStaxObj['meta']['tax'] = surchargeObj['surcharge_amount'];
              this.isDebitCard = surchargeObj['bin_type'] == 'DEBIT' ? true : false;
            }
          }
        } else {
          if (rowData.schedule_id) {
            this.sTaxProfile = {
              scid: rowData.SelectedRecurringPaymentOption,
              StaxPaymentMethodID: rowData.StaxPaymentMethodID,
              CardNo: rowData.LastFourDigits,
              NameOnCard: rowData.NameOnCard,
              TransType: rowData.TransType
            };
            this.addNewCrCardDialog = false;
            this.selectedModeOfPayment = rowData.TransType;
          } else {
            this.addNewCrCardDialog = true;
            this.genarateIFrames();
          }
        }
        this.mangoAPISrvc.showLoader(false);
        this.dialogRecurringPayments = true;
      },
      error => {
        this.mangoAPISrvc.notify(
          'error',
          this.translate.instant('error'),
          AppConstants.crcardErrorMsg
        );
        this.mangoAPISrvc.showLoader(false);
      }
    );
  }

  async getSurchargeAmount(merchantId, Amount, companyId, eventType) {
    const self = this;
    return new Promise(function (resolve, reject) {
      try {
        self.mangoAPISrvc
          .performStaxActions({ ccid: merchantId, amount: Amount }, companyId, eventType)
          .subscribe(function (data) {
            data = data['bin_type'] ? data : null;
            resolve(data);
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  getwebhooksEvents() {
    const self = this;
    self.mangoAPISrvc.showLoader(true);
    self.mangoAPISrvc.performStaxActions({}, self.companyDetails.CompanyID, 'wh').subscribe(
      (data: any) => {
        if (data.data && data.data.length == 0) {
          let obj = {
            target_url: `${self.recurringUrl}/api/Stax/recurringPaymentCallback/${self.companyDetails.CompanyID}asd`,
            event: 'create_transaction'
          };
          self.mangoAPISrvc.performStaxActions(obj, self.companyDetails.CompanyID, 'cte').subscribe(
            (data: any) => {
              self.mangoAPISrvc.showLoader(false);
            },
            error => {
              self.mangoAPISrvc.notify(
                'error',
                self.translate.instant('error'),
                AppConstants.fetchErrorMsg
              );
              self.mangoAPISrvc.showLoader(false);
            }
          );
        } else {
          self.mangoAPISrvc.showLoader(false);
        }
      },
      error => {
        self.mangoAPISrvc.notify(
          'error',
          self.translate.instant('error'),
          AppConstants.fetchErrorMsg
        );
        self.mangoAPISrvc.showLoader(false);
      }
    );
  }

  getFreqStr(freq) {
    if (freq == 'ANNUALLY') {
      freq = 'YEARLY;INTERVAL=1';
    } else if (freq == 'QUARTERLY') {
      freq = 'MONTHLY;BYMONTH=1,4,7,10;INTERVAL=1';
    } else if (freq == 'SEMI-ANNUALLY') {
      freq = 'YEARLY;BYMONTH=1,7;INTERVAL=1';
    } else if (freq == 'BI-WEEKLY') {
      freq = 'WEEKLY;INTERVAL=2';
    } else if (freq == 'SEMI-MONTHLY') {
      freq = 'MONTHLY;BYMONTHDAY=1,15;INTERVAL=1';
    } else if (freq == 'MONTHLY') {
      freq = 'MONTHLY;INTERVAL=1';
    } else if (freq == 'WEEKLY') {
      freq = 'WEEKLY;INTERVAL=1';
    }
    return freq;
  }

  async createScheduledInvoice(rowData) {
    const parent = this;
    this.isDebitCard = false;

    let dateStart: any = rowData['ScheduledDate']
      ? moment(rowData['ScheduledDate']).format('YYYYMMDDThhmmss')
      : null;
    dateStart = this.convertUTCDate(dateStart);

    if (this.isNeverEnds == true) {
      parent.currentRuleStr = `DTSTART=${dateStart};FREQ=${this.getFreqStr(
        rowData['Frequency'].toUpperCase()
      )}`;
    } else {
      parent.currentRuleStr = `DTSTART=${dateStart};FREQ=${this.getFreqStr(
        rowData['Frequency'].toUpperCase()
      )};COUNT=${rowData['count']}`;
    }

    rowData.RuleString = parent.currentRuleStr;
    parent.defaultStaxObj.rule = parent.currentRuleStr;
    parent.defaultStaxObj[
      'url'
    ] = `${parent.recurringUrl}/api/Stax/recurringPaymentCallback/${parent.companyDetails.CompanyID}`;
    parent.defaultStaxObj['total'] =
      numeral(rowData['InvoiceAmount']).value() + parent.salesTaxObj['serviceTax'];
    parent.defaultStaxObj['meta']['tax'] = 0;
    parent.defaultStaxObj['meta']['subtotal'] = parent.defaultStaxObj['total'];
    parent.defaultStaxObj['meta']['reference'] = parent.mangoUtils.generateUUID() + '$*@^$RP';
    parent.defaultStaxObj['meta']['otherField1'] =
      'from Recurring Invoice | User : ' +
      parent.userName +
      ' | ClientName :' +
      parent.selectedCustomer['ClientName'];
    parent.defaultStaxObj['meta']['otherField2'] = rowData.RecurringInvoiceHeaderID;

    if (this.sTaxProfile && this.isCrCard && this.companyDetails.isSurchargeEnabled) {
      const surchargeObj = await this.getSurchargeAmount(this.sTaxProfile.CustomerCardID, this.defaultStaxObj['total'], this.companyDetails.CompanyID, 'sc');
      if (surchargeObj) {
        this.defaultStaxObj['SurChargeAmount'] = surchargeObj['surcharge_amount'];
        this.defaultStaxObj['withSurcharge'] = surchargeObj['total_with_surcharge_amount'];
        this.defaultStaxObj['meta']['tax'] = surchargeObj['surcharge_amount'];
        this.isDebitCard = surchargeObj['bin_type'] == 'DEBIT' ? true : false;
      }
    }

    // security issue
    delete this.defaultStaxObj['customer_id'];
    delete this.defaultStaxObj['payment_method_id'];
    this.defaultStaxObj['ccid'] = this.sTaxProfile.CustomerCardID;

    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc
      .performStaxActions(parent.defaultStaxObj, parent.companyDetails.CompanyID, 'csi')
      .subscribe(
        async (data: any) => {
          parent.mangoAPISrvc
            .staxUpdateRecurringInvoiceHeader(rowData.RecurringInvoiceHeaderID, {
              LastFourDigits: rowData.LastFourDigits,
              StaxPaymentMethodID: rowData.StaxPaymentMethodID,
              NameOnCard: rowData.NameOnCard,
              TransType: rowData.TransType,
              SelectedRecurringPaymentOption: rowData.SelectedRecurringPaymentOption,
              RuleString: data.rule,
              schedule_id: data.id
            })
            .subscribe(
              (ghdata: any) => {
                parent.initDataSetList();
                parent.mangoAPISrvc.showLoader(false);
                parent.dialogRecurringPayments = false;
                parent.mangoAPISrvc.notify('success', 'Success!', AppConstants.createMsg);
              },
              error => {
                parent.mangoAPISrvc.showLoader(false);
              }
            );
          parent.selectedModeOfPayment = null;
        },
        error => {
          parent.mangoAPISrvc.notify(
            'error',
            parent.translate.instant('error'),
            AppConstants.fetchErrorMsg
          );
          parent.mangoAPISrvc.showLoader(false);
        }
      );
  }

  async updateScheduledInvoice(rowData, isDialogFlow) {
    if (!rowData.schedule_id) return;
    const parent = this;
    this.isDebitCard = false;
    parent.selectedCustomer = parent.clientProfile = parent.clientList.filter(
      client => client.ClientID === rowData['ClientID']
    )[0];
    if (
      !this.cards ||
      this.cards?.length == 0 ||
      !this.cards.every(item => item.ClientID === parent.selectedCustomer.ClientID)
    ) {
      this.cards = await this.getCardDetails(parent.selectedCustomer.ClientID);
      this.sTaxProfile = this.cards && this.cards.length > 0 ? this.cards[0] : null;
    }

    let dateStart: any = rowData['ScheduledDate']
      ? moment(rowData['ScheduledDate']).format('YYYYMMDDThhmmss')
      : null;
    dateStart = this.convertUTCDate(dateStart);
    this.salesTaxObj = await this.getSalesTaxObj(rowData.RecurringInvoiceHeaderID);
    if (this.salesTaxObj && this.salesTaxObj['serviceTax']) {
      this.salesTaxObj['serviceTax'] = this.salesTaxObj
        ? this.mangoUtils.roundOffDecimals(this.salesTaxObj['serviceTax'])
        : 0;
    }

    if (!isDialogFlow) {
      const oldCount = numeral(rowData["RuleString"].substring(rowData["RuleString"].indexOf('COUNT=') + 6, rowData["RuleString"].indexOf(';INTERVAL='))).value();
      const scheduleData = await this.getScheduledInvoice(rowData.schedule_id);
      this.isProcessing = true;
      if (scheduleData['max']) {
        if (oldCount == rowData['count']) {
          const value = scheduleData['max'] - scheduleData['count']
          rowData['count'] = value <= 0 ? 1 : value;
        }
      } else {
        this.isNeverEnds = true;
        rowData['count'] = 99;
      }
    }

    if (this.isNeverEnds == true) {
      parent.currentRuleStr = `DTSTART=${dateStart};FREQ=${this.getFreqStr(
        rowData['Frequency'].toUpperCase()
      )}`;
    } else {
      parent.currentRuleStr = `DTSTART=${dateStart};FREQ=${parent.getFreqStr(
        rowData['Frequency'].toUpperCase()
      )};COUNT=${rowData['count']}`;
    }

    rowData.RuleString = parent.currentRuleStr;
    parent.defaultStaxObj.rule = parent.currentRuleStr;
    parent.defaultStaxObj[
      'url'
    ] = `${parent.recurringUrl}/api/Stax/recurringPaymentCallback/${parent.companyDetails.CompanyID}`;
    parent.defaultStaxObj['total'] =
      numeral(rowData['InvoiceAmount']).value() + parent.salesTaxObj['serviceTax'];
    parent.defaultStaxObj['meta']['tax'] = 0;
    parent.defaultStaxObj['meta']['subtotal'] = numeral(parent.defaultStaxObj['total']).value();
    parent.defaultStaxObj['meta']['reference'] = parent.mangoUtils.generateUUID() + '$*@^$RP';
    parent.defaultStaxObj['meta']['otherField1'] =
      'from Recurring Invoice | User : ' +
      parent.userName +
      ' | ClientName :' +
      parent.selectedCustomer['ClientName'];
    parent.defaultStaxObj['meta']['otherField2'] = rowData.RecurringInvoiceHeaderID;

    parent.defaultStaxObj['customer_id'] = parent.defaultStaxObj['customer_id']
      ? parent.defaultStaxObj['customer_id']
      : rowData['SelectedRecurringPaymentOption'];
    parent.defaultStaxObj['payment_method_id'] = parent.defaultStaxObj['payment_method_id']
      ? parent.defaultStaxObj['payment_method_id']
      : rowData['StaxPaymentMethodID'];

    parent.mangoAPISrvc.showLoader(true);

    if (this.sTaxProfile && this.sTaxProfile['spmid'] && this.isCrCard && this.companyDetails.isSurchargeEnabled) {
      const surchargeObj = await this.getSurchargeAmount(this.sTaxProfile.CustomerCardID, this.defaultStaxObj['total'], this.companyDetails.CompanyID, 'sc');
      if (surchargeObj) {
        this.defaultStaxObj['SurChargeAmount'] = surchargeObj['surcharge_amount'];
        this.defaultStaxObj['withSurcharge'] = surchargeObj['total_with_surcharge_amount'];
        this.defaultStaxObj['meta']['tax'] = surchargeObj['surcharge_amount'];
        this.isDebitCard = surchargeObj['bin_type'] == 'DEBIT' ? true : false;
      }
    }

    // security issue
    delete this.defaultStaxObj['customer_id'];
    delete this.defaultStaxObj['payment_method_id'];

    if (!this.sTaxProfile) {
      const card = this.cards.filter((item) => item.spmid == rowData.StaxPaymentMethodID);
      this.sTaxProfile = card && card.length > 0 ? card[0] : null;
    }

    this.defaultStaxObj['ccid'] = this.sTaxProfile.CustomerCardID;

    parent.mangoAPISrvc
      .performStaxActions(
        { scheduleId: rowData.schedule_id, data: parent.defaultStaxObj },
        parent.companyDetails.CompanyID,
        'usi'
      )
      .subscribe(
        (data: any) => {
          parent.mangoAPISrvc
            .staxUpdateRecurringInvoiceHeader(rowData.RecurringInvoiceHeaderID, {
              LastFourDigits: rowData.LastFourDigits,
              StaxPaymentMethodID: this.sTaxProfile.spmid,
              NameOnCard: rowData.NameOnCard,
              TransType: rowData.TransType,
              SelectedRecurringPaymentOption: rowData.SelectedRecurringPaymentOption,
              RuleString: data.rule,
              schedule_id: data.id
            })
            .subscribe(
              async (ghdata: any) => {
                parent.initDataSetList();
                parent.mangoAPISrvc.showLoader(false);
                parent.dialogRecurringPayments = false;
                parent.selectedModeOfPayment = null;
                parent.mangoAPISrvc.notify('success', 'Success!', AppConstants.updateMsg);
              },
              error => {
                parent.mangoAPISrvc.showLoader(false);
              }
            );
        },
        error => {
          parent.mangoAPISrvc.notify(
            'error',
            parent.translate.instant('error'),
            AppConstants.fetchErrorMsg
          );
          parent.mangoAPISrvc.showLoader(false);
        }
      );
  }

  deleteScheduledInvoice(id, scheduleId, isDialog) {
    const parent = this;

    if (isDialog) {
      Swal.fire({
        title: parent.translate.instant('confirmation'),
        html: `<div>${parent.translate.instant('delete-schedule-msg')}</div>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `${parent.translate.instant('yes_continue')}!`,
        cancelButtonText: parent.translate.instant('no_cancel')
      }).then(result => {
        if (result.value) {
          parent.mangoAPISrvc.showLoader(true);
          parent.mangoAPISrvc
            .performStaxActions({ scheduleId: scheduleId }, parent.companyDetails.CompanyID, 'dsi')
            .subscribe(
              (data: any) => {
                parent.mangoAPISrvc
                  .staxUpdateRecurringInvoiceHeader(id, {
                    LastFourDigits: null,
                    StaxPaymentMethodID: null,
                    NameOnCard: null,
                    TransType: null,
                    SelectedRecurringPaymentOption: null,
                    RuleString: null,
                    schedule_id: null
                  })
                  .subscribe(
                    (ghdata: any) => {
                      parent.initDataSetList();
                      parent.mangoAPISrvc.showLoader(false);
                      parent.dialogRecurringPayments = false;
                      parent.mangoAPISrvc.notify('success', 'Success!', AppConstants.stopedMessage);
                    },
                    error => {
                      parent.mangoAPISrvc.showLoader(false);
                    }
                  );
              },
              error => {
                parent.mangoAPISrvc.notify(
                  'error',
                  parent.translate.instant('error'),
                  AppConstants.fetchErrorMsg
                );
                parent.mangoAPISrvc.showLoader(false);
              }
            );
        }
      });
      return false;
    } else {
      parent.mangoAPISrvc.showLoader(true);
      parent.mangoAPISrvc
        .performStaxActions({ scheduleId: scheduleId }, parent.companyDetails.CompanyID, 'dsi')
        .subscribe(
          (data: any) => {
            parent.mangoAPISrvc
              .staxUpdateRecurringInvoiceHeader(id, {
                LastFourDigits: null,
                StaxPaymentMethodID: null,
                NameOnCard: null,
                TransType: null,
                SelectedRecurringPaymentOption: null,
                RuleString: null,
                schedule_id: null
              })
              .subscribe(
                (ghdata: any) => {
                  parent.initDataSetList();
                  parent.mangoAPISrvc.showLoader(false);
                  parent.dialogRecurringPayments = false;
                  parent.mangoAPISrvc.notify('success', 'Success!', AppConstants.stopedMessage);
                },
                error => {
                  parent.mangoAPISrvc.showLoader(false);
                }
              );
          },
          error => {
            parent.mangoAPISrvc.notify(
              'error',
              parent.translate.instant('error'),
              AppConstants.fetchErrorMsg
            );
            parent.mangoAPISrvc.showLoader(false);
          }
        );
    }
  }

  compareDates(date1, date2) {
    return moment(date1).format('YYYY/MM/DD') == moment(date2).format('YYYY/MM/DD');
  }

  async calculateSurcharge() {
    this.defaultStaxObj['SurChargeAmount'] = 0;
    this.defaultStaxObj['withSurcharge'] = 0;
    this.isDebitCard = false;
    if (this.sTaxProfile && this.companyDetails.isSurchargeEnabled) {
      this.mangoAPISrvc.showLoader(true);
      const surchargeObj = await this.getSurchargeAmount(this.sTaxProfile.CustomerCardID, this.defaultStaxObj['total'], this.companyDetails.CompanyID, 'sc');
      if (surchargeObj) {
        this.defaultStaxObj['SurChargeAmount'] = surchargeObj['surcharge_amount'];
        this.defaultStaxObj['withSurcharge'] = surchargeObj['total_with_surcharge_amount'];
        this.isDebitCard = surchargeObj['bin_type'] == 'DEBIT' ? true : false;
      }
      this.mangoAPISrvc.showLoader(false);
    }
  }

  authorizeSTaxNewCard() {
    const parent = this;
    let formData = null;
    let extraDetails = null;
    const cardObj = {};
    this.addNewCrCardDialog = false;
    let phNo = parent.selectedCustomer.Mobile
      ? parent.selectedCustomer.Mobile
      : parent.selectedCustomer.Office;
    phNo = phNo ? phNo.replace(/[{(___)-}]/g, '').trim() : '555-555-5555';
    phNo = phNo ? phNo : '555-555-5555';
    parent.mangoAPISrvc.showLoader(true);
    formData = parent.myPaymentForm.value;
    extraDetails = {
      total: 1,
      firstname: formData.fname,
      lastname: formData.lname,
      email: parent.selectedCustomer.Email,
      month: parseInt(formData.expDate.split('/')[0]),
      year: parseInt(formData.expDate.split('/')[1]),
      phone: phNo,
      address_1: parent.selectedCustomer.BusStreet1,
      address_2: parent.selectedCustomer.BusStreet2 ? parent.selectedCustomer.BusStreet2 : '',
      address_city: parent.selectedCustomer.BusCity,
      address_state: parent.selectedCustomer.BusState,
      address_zip: parent.selectedCustomer.BusZip,
      method: 'card',
      validate: false,
      send_receipt: false
    };
    // call pay api
    parent.fattJs
      .tokenize(extraDetails)
      .then(result => {
        if (result.id) {
          cardObj['CompanyID'] = parent.selectedCustomer.CompanyID;
          cardObj['ClientID'] = parent.selectedCustomer.ClientID;
          cardObj['CardNo'] = result.card_last_four;
          cardObj['ExpiryDate'] = result.card_exp;
          cardObj['NameOnCard'] = result.person_name;
          cardObj['TransType'] = 'CC';
          cardObj['FirstName'] = formData.fname;
          cardObj['LastName'] = formData.lname;
          cardObj['scid'] = result.customer_id;
          cardObj['uid'] = result.id;
          cardObj['CardType'] = result['card_type'];
          parent.encrDecSrvc.addObject(AppConstants.isFormChanged, true);
          parent.addNewCrCardDialog = false;
          if (parent.sTaxProfile == null || parent.sTaxProfile.length == 0) {
            parent.mangoAPISrvc.createCreditCardDetails(cardObj).subscribe(
              async (resultRes: any) => {
                parent.sTaxProfile = cardObj;
                parent.sTaxProfile['CustomerCardID'] = resultRes.id;
                parent.sTaxProfile = parent.sTaxProfile;
                parent.mangoAPISrvc.showLoader(false);
                await parent.calculateSurcharge();
              },
              (data: any) => {
                parent.mangoAPISrvc.showLoader(false);
              }
            );
          } else {
            parent.mangoAPISrvc
              .updateCreditCardDetails(parent.sTaxProfile.CustomerCardID, cardObj)
              .subscribe(
                async (resultRes: any) => {
                  //parent.sTaxProfile['StaxToken'] = cardObj['StaxToken'];
                  parent.sTaxProfile['NameOnCard'] = cardObj['NameOnCard'];
                  parent.sTaxProfile['CardNo'] = cardObj['CardNo'];
                  parent.sTaxProfile['CardType'] = cardObj['CardType'];
                  // parent.sTaxProfile['StaxCustomerID'] = cardObj['StaxCustomerID'];
                  // parent.sTaxProfile['StaxPaymentMethodID'] = cardObj['StaxToken'];
                  parent.sTaxProfile['isBusiness'] = cardObj['isBusiness'];
                  parent.sTaxProfile = parent.sTaxProfile;
                  parent.mangoAPISrvc.showLoader(false);
                  await parent.calculateSurcharge();
                },
                (data: any) => {
                  parent.mangoAPISrvc.showLoader(false);
                }
              );
          }
        }
      })
      .catch(err => {
        parent.mangoAPISrvc.showLoader(false);
        parent.isDialogFormValid = true;
        let msg = err['message'] ? err['message'] : null;
        if (!msg) {
          if (err.payment_attempt_message) {
            msg = err.payment_attempt_message;
          } else if (err.child_transactions && err.child_transactions.length > 0) {
            msg = err.child_transactions[0].message;
          } else if (err.error_description) {
            msg = err.error_description;
          } else {
            try {
              msg =
                typeof err === 'object'
                  ? Object.keys(err)
                      .map(k => err[k].join(' '))
                      .join(' ')
                  : JSON.stringify(err);
            } catch (error) {}
          }
        }
        Swal.fire({
          icon: 'error',
          title: parent.translate.instant('Transaction Not Approved'),
          text: msg,
          showConfirmButton: false,
          timer: 7000
        });
      });
  }

  // addNewCreditCard() {
  //   this.isProcessing = true;
  //   this.addNewCrCardDialog = true;
  //   this.isCrCard = true;
  //   this.sTaxProfile = null;
  //   this.genarateIFrames();
  //   this.defaultStaxObj["SurChargeAmount"] = 0;
  //   this.defaultStaxObj["withSurcharge"] = 0;
  // }

  convertUTCDate(date) {
    const check = moment(date, "YYYY/MM/DD");
    const month = parseInt(check.format("M"));
    const day = parseInt(check.format("D"));
    const year = parseInt(check.format("YYYY"));
    return new RRule({
      //dtstart: new Date(Date.UTC(year, month - 1, day, 23, 0, 0)),
      dtstart: moment(date, 'YYYY/MM/DD').endOf('day').toDate()
    })
      .toString()
      .split('DTSTART:')[1];
  }

  addNewCreditCard() {
    this.addNewCrCardDialog = true;
    this.isCrCard = true;
    this.sTaxProfile = null;
    this.isProcessing = true;
    this.isDebitCard = false;
    this.genarateIFrames();
    this.myPaymentForm?.controls['FirstName']?.setValue('');
    this.myPaymentForm?.controls['LastName']?.setValue('');
    this.myPaymentForm?.controls['expDate']?.setValue('');
    this.myPaymentForm?.controls['nameOnCard']?.setValue('');
    this.myPaymentForm?.controls['fname']?.setValue('');
    this.myPaymentForm?.controls['lname']?.setValue('');
    this.myPaymentForm?.controls['address']?.setValue('');
    this.myPaymentForm?.controls['city']?.setValue('');
    this.myPaymentForm?.controls['state']?.setValue('');
    this.myPaymentForm?.controls['zip']?.setValue('');
    if (this.companyDetails.isSurchargeEnabled && this.sTaxProfile) {
      this.sTaxProfile['StaxPaymentMethodID'] = null;
      this.myPaymentForm.controls['SurChargeAmount'].setValue(0);
      this.myPaymentForm.controls['withSurcharge'].setValue(0);
    }
  }

  addSTaxNewCard(recurringRowData, isUpdate) {
    const parent = this;
    if (!this.isCrCard && (!parent.selectedCustomer.BusStreet1 || !parent.selectedCustomer.BusZip || !parent.selectedCustomer.Email)) {
      Swal.fire({
        title: parent.translate.instant('error'),
        html: `<div>${parent.translate.instant('stax-card-error-msg')}</div>`,
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: `${parent.translate.instant('Go-to-Client')}!`,
        cancelButtonText: parent.translate.instant('no_cancel')
      }).then(result => {
        if (result.value) {
          parent.encrDecSrvc.addObject(AppConstants.selectedClientRecord, parent.selectedCustomer);
          parent.encrDecSrvc.addObject(AppConstants.clientID, parent.selectedCustomer['ClientID']);
          parent.encrDecSrvc.addObject(
            AppConstants.ClientName,
            parent.selectedCustomer['ClientName']
          );
          parent.mangoAPISrvc.showLoader(true);
          parent.mangoAPISrvc
            .getAllDataCounts(parent.selectedCustomer['ClientID'])
            .subscribe(function (data) {
              parent.encrDecSrvc.addObject(AppConstants.allDataCountsForClient, data);
              parent.router
                .navigate([AppConstants.clientRoutePath + '/' + AppConstants.viewRoutePath])
                .then(res => parent.mangoAPISrvc.fireClientView(true));
              parent.mangoAPISrvc.showLoader(false);
            });
        }
      });
      return false;
    } else {
      if (!this.isCrCard) {
        parent.fattJs = new FattJs(parent.companyDetails.spkuid, {});
      }

      let formData = null;
      formData = parent.myPaymentForm.value;
      if (!formData.fname || !formData.lname) {
        if (this.isCrCard && !formData.expDate) {
          parent.mangoAPISrvc.notify('error', 'Error!', 'All fields are required.');
        } else if (!this.isCrCard && (!formData.RoutingNumber || !formData.AccountNumber)) {
          parent.mangoAPISrvc.notify('error', 'Error!', 'All fields are required.');
        }
        return false;
      }
      if (parent.isProcessing) {
        return false;
      }
      parent.getStaxToken(recurringRowData, isUpdate);
    }
  }

  async getStaxToken(recurringRowData, isUpdate) {
    const cardObj = {};
    const parent = this;
    let formData = null;
    let extraDetails = null;
    let phNo = parent.selectedCustomer.Mobile
      ? parent.selectedCustomer.Mobile
      : parent.selectedCustomer.Office;
    phNo = phNo ? phNo.replace(/[{(___)-}]/g, '').trim() : '';
    phNo = phNo ? phNo : '';
    parent.isProcessing = false;
    parent.myPaymentForm.controls['isBusiness'].setValue(parent.isBusiness);
    formData = parent.myPaymentForm.value;
    parent.mangoAPISrvc.showLoader(true);

    let customerId = '';
    const existingCard = parent.cards.filter(item =>
      item.TransType == parent.isCrCard ? 'CC' : 'ACH'
    );
    if (existingCard && existingCard.length > 0) {
      customerId = existingCard && existingCard.length > 0 ? existingCard[0]?.scid : '';
    } else {
      const customerObj = {
        firstname: formData.fname,
        lastname: formData.lname,
        phone: phNo,
        email: parent.selectedCustomer.Email,
        address_1: parent.isCrCard ? formData.address : parent.selectedCustomer.BusStreet1,
        address_city: parent.isCrCard ? formData.city : parent.selectedCustomer.BusCity,
        address_state: parent.isCrCard ? formData.state : parent.selectedCustomer.BusState,
        address_zip: parent.isCrCard ? formData.zip : parent.selectedCustomer.BusZip
      };
      if (!phNo) delete customerObj.phone;
      if (!parent.selectedCustomer.Email) delete customerObj.email;
      try {
        const responseCust = await parent.createStaxCustomer(customerObj);
        customerId = responseCust['id'];
      } catch (errCust) {
        parent.mangoAPISrvc.showLoader(false);
        parent.isDialogFormValid = true;
        let msg = '';
        if (!msg) {
          let errCount = 0;
          if (errCust.address_zip && errCust.address_zip.length > 0) {
            msg += 'Phone #';
            errCount++;
          }
          if (errCust.address_1 && errCust.address_1.length > 0) {
            msg += errCount > 0 ? ', Address 1' : 'Address 1';
            errCount++;
          }
          if (errCust.address_2 && errCust.address_2.length > 0) {
            msg += errCount > 0 ? ', Address 2' : 'Address 2';
            errCount++;
          }
          if (errCust.address_city && errCust.address_city.length > 0) {
            msg += errCount > 0 ? ', city' : 'city';
            errCount++;
          }
          if (errCust.address_state && errCust.address_state.length > 0) {
            msg += errCount > 0 ? ', state' : 'state';
            errCount++;
          }
          if (errCust.address_zip && errCust.address_zip.length > 0) {
            msg += errCount > 0 ? ', and zip' : 'zip';
            errCount++;
          }
          if (errCount > 0) msg += ' are invalid!';
          else msg += ' is invalid!';
        }
        Swal.fire({
          icon: 'error',
          title: parent.translate.instant('Transaction Not Approved'),
          text: msg,
          showConfirmButton: false,
          timer: 7000
        });
        return;
      }
    }

    if (parent.isCrCard) {
      extraDetails = {
        customer_id: customerId,
        total: numeral(recurringRowData.InvoiceAmount).value(),
        firstname: formData.fname,
        lastname: formData.lname,
        phone: phNo,
        email: parent.selectedCustomer.Email,
        address_1: formData.address,
        address_city: formData.city,
        address_state: formData.state,
        address_zip: formData.zip,
        method: 'card',
        month: parseInt(formData.expDate.split('/')[0]),
        year: parseInt(formData.expDate.split('/')[1]),
        validate: false,
        send_receipt: false
      };
    } else {
      extraDetails = {
        customerId,
        total: numeral(recurringRowData.InvoiceAmount).value(),
        firstname: formData.fname,
        lastname: formData.lname,
        person_name: formData.fname + ' ' + formData.lname,
        phone: phNo,
        address_1: parent.selectedCustomer.BusStreet1,
        address_2: parent.selectedCustomer.BusStreet2 ? parent.selectedCustomer.BusStreet2 : '',
        address_city: parent.selectedCustomer.BusCity,
        address_state: parent.selectedCustomer.BusState,
        address_zip: parent.selectedCustomer.BusZip,
        bank_account: formData.AccountNumber,
        bank_routing: formData.RoutingNumber,
        bank_type: formData.TransCode == 27 ? 'checking' : 'savings',
        bank_holder_type: formData.isBusiness == 'true' ? 'business' : 'personal',
        method: 'bank',
        validate: false,
        send_receipt: false,
        url: 'https://omni.fattmerchant.com/#/bill/'
      };
    }

    parent.fattJs
      .tokenize(extraDetails)
      .then(result => {
        if (result) {
          // saving into Mango DB
          cardObj['CompanyID'] = parent.selectedCustomer.CompanyID;
          cardObj['ClientID'] = parent.selectedCustomer.ClientID;
          cardObj['CardNo'] = result.card_last_four;
          cardObj['ExpiryDate'] = result.card_exp;
          cardObj['NameOnCard'] = result.person_name;
          cardObj['TransType'] = parent.isCrCard ? 'CC' : 'ACH';
          cardObj['FirstName'] = formData.fname;
          cardObj['LastName'] = formData.lname;
          cardObj['scid'] = result.customer_id;
          cardObj['uid'] = result.id;
          cardObj['CardType'] = parent.isCrCard ? result['card_type'] : result['bank_type'];
          parent.isDebitCard = result['bin_type'] == 'DEBIT' ? true : false;
          recurringRowData['SelectedRecurringPaymentOption'] = result.customer_id;
          recurringRowData['TransType'] = cardObj['TransType'];
          recurringRowData['StaxPaymentMethodID'] = result.id;
          recurringRowData['NameOnCard'] = result.person_name;
          recurringRowData['LastFourDigits'] = result.card_last_four;
          let card = this.cards.filter(item => item.TransType == 'CC');
          if (!parent.isCrCard) {
            card = this.cards.filter(item => item.TransType == 'ACH');
            cardObj['BankAcctNo'] = formData['AccountNumber'].substr(
              formData['AccountNumber'].length - 4,
              formData['AccountNumber'].length
            );
            cardObj['isBusiness'] = formData.isBusiness == 'true' ? true : false;
          }
          //cardObj[AppConstants.StaxPaymentMethodID] = result.id;
          parent.defaultStaxObj['customer_id'] = result.customer_id;
          parent.defaultStaxObj['payment_method_id'] = result.id;

          let selectedData = card && card.length > 0 ? card[0] : null;
          if (selectedData) {
            parent.mangoAPISrvc
              .updateCreditCardDetails(selectedData.CustomerCardID, cardObj)
              .subscribe(
                async (resultRes: any) => {
                  parent.sTaxProfile = cardObj;
                  parent.sTaxProfile['CustomerCardID'] = selectedData.CustomerCardID;
                  parent.mangoAPISrvc.showLoader(false);
                  parent.addNewCrCardDialog = false;
                  parent.cards = await parent.getCardDetails(parent.selectedCustomer.ClientID);
                  await parent.calculateSurcharge();
                },
                (data: any) => {
                  parent.mangoAPISrvc.showLoader(false);
                }
              );
          } else {
            parent.mangoAPISrvc.createCreditCardDetails(cardObj).subscribe(
              async (resultRes: any) => {
                parent.sTaxProfile = cardObj;
                parent.sTaxProfile['CustomerCardID'] = resultRes.id;
                parent.mangoAPISrvc.showLoader(false);
                parent.addNewCrCardDialog = false;
                parent.cards = await parent.getCardDetails(parent.selectedCustomer.ClientID);
                await parent.calculateSurcharge();
              },
              (data: any) => {
                parent.mangoAPISrvc.showLoader(false);
              }
            );
          }
        }
      })
      .catch(err => {
        parent.mangoAPISrvc.showLoader(false);
        parent.isDialogFormValid = true;
        let msg = err['message'] ? err['message'] : null;
        if (!msg) {
          if (err.payment_attempt_message) {
            msg = err.payment_attempt_message;
          } else if (err.child_transactions && err.child_transactions.length > 0) {
            msg = err.child_transactions[0].message;
          } else if (err.error_description) {
            msg = err.error_description;
          } else {
            try {
              msg =
                typeof err === 'object'
                  ? Object.keys(err)
                      .map(k => err[k].join(' '))
                      .join(' ')
                  : JSON.stringify(err);
            } catch (error) {}
          }
        }
        Swal.fire({
          icon: 'error',
          title: parent.translate.instant('Transaction Not Approved'),
          text: msg,
          showConfirmButton: false,
          timer: 7000
        });
      });
  }

  closeStaxForm(recurringRowData) {
    recurringRowData.SelectedRecurringPaymentOption =
      recurringRowData['backup_SelectedRecurringPaymentOption'];
    recurringRowData.TransType = recurringRowData['backup_TransType'];
    recurringRowData.StaxPaymentMethodID = recurringRowData['backup_StaxPaymentMethodID'];
    recurringRowData.NameOnCard = recurringRowData['backup_NameOnCard'];
    recurringRowData.LastFourDigits = recurringRowData['backup_LastFourDigits'];
    this.myPaymentForm.reset();
    this.mangoAPISrvc.showLoader(false);
    this.isProcessing = false;
    this.selectedModeOfPayment = null;
    this.isCrCard = false;
    this.dialogRecurringPayments = false;
    this.addNewCrCardDialog = false;
    this.isNeverEnds = false;
  }

  async changePaymentType(recurringRowData) {
    const parent = this;
    if (recurringRowData.schedule_id) {
      Swal.fire({
        title: parent.translate.instant('confirmation'),
        html: `<div>${parent.translate.instant('payment-change-msg ')}</div>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `${parent.translate.instant('yes_continue')}!`,
        cancelButtonText: parent.translate.instant('no_cancel')
      }).then(async result => {
        if (result.value) {
          parent.isProcessing = false;
          recurringRowData.SelectedRecurringPaymentOption =
            recurringRowData['backup_SelectedRecurringPaymentOption'];
          recurringRowData.TransType = recurringRowData['backup_TransType'];
          recurringRowData.StaxPaymentMethodID = recurringRowData['backup_StaxPaymentMethodID'];
          recurringRowData.NameOnCard = recurringRowData['backup_NameOnCard'];
          recurringRowData.LastFourDigits = recurringRowData['backup_LastFourDigits'];
          this.isCrCard = (this.selectedModeOfPayment == "CC") ? true : false;
          const card = this.cards.filter((item) => item.TransType == this.selectedModeOfPayment);
          const selectedData = card && card.length > 0 ? card[0] : null;
          this.sTaxProfile = null;
          this.defaultStaxObj['customer_id'] = null;
          this.defaultStaxObj['payment_method_id'] = null;
          this.defaultStaxObj['SurChargeAmount'] = 0;
          this.defaultStaxObj['withSurcharge'] = 0;
          if (selectedData) {
            this.sTaxProfile = selectedData;
            this.isProcessing = false;
            this.addNewCrCardDialog = false;
            this.isDebitCard = false;
            this.defaultStaxObj['customer_id'] = selectedData['scid'];
            this.defaultStaxObj['payment_method_id'] = selectedData['spmid'];
            recurringRowData.SelectedRecurringPaymentOption = selectedData['scid'];
            recurringRowData['TransType'] = this.sTaxProfile
              ? this.sTaxProfile.TransType
              : recurringRowData['TransType'];
            recurringRowData['StaxPaymentMethodID'] = this.sTaxProfile
              ? this.sTaxProfile.spmid
              : recurringRowData['StaxPaymentMethodID'];
            recurringRowData['NameOnCard'] = this.sTaxProfile
              ? this.sTaxProfile.NameOnCard
              : recurringRowData['NameOnCard'];
            recurringRowData['LastFourDigits'] = this.sTaxProfile
              ? this.isCrCard
                ? this.sTaxProfile.CardNo
                : this.sTaxProfile.BankAcctNo
              : recurringRowData['LastFourDigits'];

            if (this.sTaxProfile && this.sTaxProfile['spmid'] && this.isCrCard) {
              const surchargeObj = await this.getSurchargeAmount(this.sTaxProfile.CustomerCardID, this.defaultStaxObj['total'], this.companyDetails.CompanyID, 'sc');
              if (surchargeObj) {
                this.defaultStaxObj['SurChargeAmount'] = surchargeObj['surcharge_amount'];
                this.defaultStaxObj['withSurcharge'] = surchargeObj['total_with_surcharge_amount'];
                this.defaultStaxObj['meta']['tax'] = surchargeObj['surcharge_amount'];
                this.isDebitCard = surchargeObj['bin_type'] == 'DEBIT' ? true : false;
              }
            }
          } else {
            this.addNewCrCardDialog = true;
            this.genarateIFrames();
          }
        } else {
          this.selectedModeOfPayment = recurringRowData.backup_TransType;
          this.isProcessing = true;
        }
      });
    } else {
      this.defaultStaxObj['SurChargeAmount'] = 0;
      this.defaultStaxObj['withSurcharge'] = 0;
      this.isDebitCard = false;
      recurringRowData.SelectedRecurringPaymentOption =
        recurringRowData['backup_SelectedRecurringPaymentOption'];
      recurringRowData.TransType = recurringRowData['backup_TransType'];
      recurringRowData.StaxPaymentMethodID = recurringRowData['backup_StaxPaymentMethodID'];
      recurringRowData.NameOnCard = recurringRowData['backup_NameOnCard'];
      recurringRowData.LastFourDigits = recurringRowData['backup_LastFourDigits'];
      this.isCrCard = (this.selectedModeOfPayment == "CC") ? true : false;
      const card = this.cards.filter((item) => item.TransType == this.selectedModeOfPayment);
      const selectedData = card && card.length > 0 ? card[0] : null;
      this.sTaxProfile = null;
      this.defaultStaxObj['customer_id'] = null;
      this.defaultStaxObj['payment_method_id'] = null;
      if (selectedData) {
        this.isProcessing = false;
        this.sTaxProfile = selectedData;
        this.addNewCrCardDialog = false;
        this.defaultStaxObj['customer_id'] = selectedData['scid'];
        this.defaultStaxObj['payment_method_id'] = selectedData['spmid'];
        recurringRowData.SelectedRecurringPaymentOption = selectedData['scid'];
        recurringRowData['TransType'] = this.sTaxProfile ? this.sTaxProfile.TransType : recurringRowData['TransType'];
        recurringRowData['StaxPaymentMethodID'] = this.sTaxProfile ? this.sTaxProfile.spmid : recurringRowData['TransType'];
        recurringRowData['NameOnCard'] = this.sTaxProfile ? this.sTaxProfile.NameOnCard : recurringRowData['NameOnCard'];
        recurringRowData['LastFourDigits'] = this.sTaxProfile ? (this.isCrCard ? this.sTaxProfile.CardNo : this.sTaxProfile.BankAcctNo) : recurringRowData['LastFourDigits'];
        if (this.sTaxProfile && this.sTaxProfile['spmid'] && this.isCrCard && this.companyDetails.isSurchargeEnabled) {
          const surchargeObj = await this.getSurchargeAmount(this.sTaxProfile.CustomerCardID, this.defaultStaxObj['total'], this.companyDetails.CompanyID, 'sc');
          if (surchargeObj) {
            this.defaultStaxObj['SurChargeAmount'] = surchargeObj['surcharge_amount'];
            this.defaultStaxObj['withSurcharge'] = surchargeObj['total_with_surcharge_amount'];
            this.defaultStaxObj['meta']['tax'] = surchargeObj['surcharge_amount'];
            this.isDebitCard = surchargeObj['bin_type'] == 'DEBIT' ? true : false;
          }
        }
      } else {
        this.addNewCrCardDialog = true;
        this.isProcessing = true;
        this.genarateIFrames();
      }
    }
  }

  isLocked(ddate) {
    if (!(this.auth.isAllowAccess(32) || this.auth.isSuperAdmin)) {
      if (this.timeexp.isLockTime && parseInt(this.timeexp.LockTimeDays) > 0) {
        const dDate = moment(ddate).format('MM/DD/YYYY');
        const dateNow = moment().format('MM/DD/YYYY');
        return moment(dateNow).diff(moment(dDate), 'days') > this.timeexp.LockTimeDays;
      }
    }
    return false;
  }

  validatePaymentForm() {
    if (this.sTaxProfile && !this.addNewCrCardDialog) {
      this.isProcessing = false;
    }
    const formData = this.myPaymentForm.value;
    if (!this.sTaxProfile && !this.isCrCard) {
      if (
        !formData.fname ||
        !formData.lname ||
        !formData.RoutingNumber ||
        !formData.AccountNumber
      ) {
        this.isProcessing = true;
      } else {
        this.isProcessing = false;
      }
    }
  }

  async showRecurringInvoiceHistory(rowData) {
    const parent = this;
    let queryStr = `?type=charge&resourceType=transaction&order=DESC&per_page=200&is_manual=0&startDate=`;
    const scheduleData = await this.getScheduledInvoice(rowData.schedule_id);
    queryStr += scheduleData['created_at'] + '&user_id=' + scheduleData['uid'];
    parent.RecurringInvoiceHistory = await parent.getTransaction(queryStr, scheduleData['id']);
    parent.recurringInvoiceHistoryTotal = parent.RecurringInvoiceHistory.reduce(function (a, b) {
      return numeral(a).value() + +numeral(b.total).value();
    }, 0);
    parent.showRecurringInvoiceHistoryDialog = true;
  }

  async getTransaction(queryStr, schedule_id) {
    const self = this;
    self.mangoAPISrvc.showLoader(true);
    return new Promise(function (resolve, reject) {
      try {
        self.mangoAPISrvc
          .performStaxActions({ queryStr: queryStr }, self.companyDetails.CompanyID, 'sth')
          .subscribe(
            function (data: any) {
              data.data
                .filter(item => item['schedule_id'] == schedule_id)
                .map(function (obj) {
                  obj['userName'] =
                    obj['customer']['firstname'] + ' ' + obj['customer']['lastname'];
                  obj['bankType'] = obj['payment_method']['bank_type'];
                  return obj;
                });
              self.mangoAPISrvc.showLoader(false);
              resolve(data.data.filter(item => item['schedule_id'] == schedule_id));
            },
            error => {
              self.mangoAPISrvc.showLoader(false);
              self.mangoAPISrvc.notify('error', 'Error', 'Error while Expanding.');
            }
          );
      } catch (error) {
        self.mangoAPISrvc.showLoader(false);
        reject(error);
      }
    });
  }

  hideRecurringHistorySideBar() {
    this.showRecurringInvoiceHistoryDialog = false;
    this.RecurringInvoiceHistory = [];
  }

  onDateSelect(event: any, formControlName) {
    if (formControlName == 'invoiceDate') {
      this.encrDecSrvc.addObject(AppConstants.recurringLastInvoiceDate, this.invoiceDate);
    } else {
      this.encrDecSrvc.addObject(AppConstants.recurringLastDateThru, this.InvoiceStartDate);
    }
  }

  reGenarateMangoData(rowData, loginCompanyId) {
    const self = this;
    Swal.fire({
      title: this.translate.instant('confirmation'),
      text: 'Do you want to Recreate Billing Header/Detail & Payment Header/Detail?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('Yes'),
      cancelButtonText: this.translate.instant('No')
    }).then(result => {
      if (result.value) {
        rowData['triggerManual'] = true;
        self.mangoAPISrvc.showLoader(true);
        self.mangoAPISrvc.recurringreGenarateMangoData(rowData, loginCompanyId).subscribe(
          function (data: any) {
            self.mangoAPISrvc.showLoader(false);
            self.mangoAPISrvc.notify(
              'success',
              self.translate.instant('Success'),
              self.translate.instant('notification_created')
            );
          },
          error => {
            self.mangoAPISrvc.showLoader(false);
            self.mangoAPISrvc.notify('error', 'Error', 'Error while Expanding.');
          }
        );
      }
    });
  }

  addNewBankCard() {
    this.addNewCrCardDialog = true;
    this.isProcessing = true;
    this.sTaxProfile = null;
    this.isCrCard = false;
    // this.myAchReceiptsForm.controls['RoutingNumber'].setValue('');
    // this.myAchReceiptsForm.controls['AccountNumber'].setValue('');
    // this.myAchReceiptsForm.controls['FirstName'].setValue('');
    // this.myAchReceiptsForm.controls['LastName'].setValue('');
    // this.myAchReceiptsForm.controls['Address1'].setValue('');
    // this.myAchReceiptsForm.controls['City'].setValue('');
    // this.myAchReceiptsForm.controls['State'].setValue('');
    // this.myAchReceiptsForm.controls['Zip'].setValue('');
  }

  isValidStaxForm() {
    const paymentFormData = this.myPaymentForm.value;
    this.isProcessing = false;
    if (
      this.isCrCard &&
      this.addNewCrCardDialog &&
      (!paymentFormData.fname ||
        !paymentFormData.lname ||
        !paymentFormData.address ||
        !paymentFormData.city ||
        !paymentFormData.state ||
        !paymentFormData.zip ||
        !paymentFormData.expDate)
    ) {
      this.isProcessing = true;
    } else if (
      !this.isCrCard &&
      this.addNewCrCardDialog &&
      (!paymentFormData.RoutingNumber ||
        !paymentFormData.AccountNumber ||
        !paymentFormData.TransCode ||
        !paymentFormData.fname ||
        !paymentFormData.lname)
    ) {
      this.isProcessing = true;
    }
  }

  verifySystemLocking(e) {
    if (e == null) return;

    const dayToday = new Date(new Date().setHours(0, 0, 0, 0));
    const lockDay = new Date(new Date(dayToday).setDate(this.companySetting?.effectiveLockDay));
    const monthToLock = new Date(new Date(lockDay).setMonth(lockDay.getMonth() - this.companySetting?.monthsPreviousToLock)).getMonth();
    const lastDayOfMonth = new Date(new Date().getFullYear(), monthToLock + 1, 0);

    if (
      this.companySetting?.isEnableSystemLocking &&
      dayToday >= lockDay &&
      new Date(e) <= lastDayOfMonth
    ) {
      this.mangoAPISrvc.notify(
        'error',
        'Error!',
        'System Locking is enabled on the selected date.'
      );
    }
  }

  validateInvoiceDateTimeout: any;
  validateInvoiceDate() {
    if (typeof this.validateInvoiceDateTimeout != 'undefined') {
      clearTimeout(this.validateInvoiceDateTimeout);
      this.validateInvoiceDateTimeout = undefined;
    }

    this.validateInvoiceDateTimeout = setTimeout(() => {
      const invoiceDate: Date = this.invoiceDate;

      const dateToday = new Date();

      if (this.mangoUtils.dateDiff(invoiceDate, dateToday, 'months') > 2) {
        Swal.fire({
          icon: 'warning',

          title: `${this.translate.instant('Warning')}?`,
          text: `${this.translate.instant('two-month-gap-date-entry-warning')}!`,

          confirmButtonText: 'OK',

          showCancelButton: false,
          allowEscapeKey: true,
          allowEnterKey: true
        });
      }

      clearTimeout(this.validateInvoiceDateTimeout);
      this.validateInvoiceDateTimeout = undefined;
    }, 500);
  }

  onRowChange(event) {
    this.reOrderLineItems();
  }

  reOrderLineItems() {
    console.log(this.recurringInvoiceDetails);
    for (let index = 0; index < this.recurringInvoiceDetails.length; index++) {
      const element = this.recurringInvoiceDetails[index];
      element['LineNumber'] = index + 1;
      element['ReleaseDate'] = element['ReleaseDate']
        ? this.mangoUtils.formatDateWithoutTime(element['ReleaseDate'])
        : element['ReleaseDate'];
      this.mangoAPISrvc
        .updateRecuringInvoiceDetail(element['RecurringInvoiceDetailID'], element)
        .subscribe(
          data => {},
          err => {
            this.mangoAPISrvc.notify(
              'error',
              this.translate.instant('error'),
              AppConstants.updateErrorMsg
            );
          }
        );
    }
  }
}
