interface DmsActionType {
  create: string;
  read: string;
  delete: string;
  download: string;
  upload: string;
}

export class AppConstants {
  public static readonly uidKey: string = 'xx3xxxxp-5xxx-zxxx-yxxx-xxxxxuxxxxxx';
  public static readonly encryptKey: string = 'ZpGe!Pk~M8Q@s%I^oKjFwV*EsRqT$bYAa';
  public static readonly dashBoardRoutePath: string = 'dashboard';
  public static readonly dashBoardInboxRoutePath: string = 'dashboard/inbox';
  public static readonly accountingRoutePath: string = 'accounting';
  public static readonly billinginvoicingRoutePath: string = 'billing-invoicing';
  public static readonly clientRoutePath: string = 'client';
  public static readonly dmsRoutePath: string = 'dms';
  public static readonly engagementsRoutePath: string = 'engagements';
  public static readonly projectManagementRoutePath: string = 'project-management';
  public static readonly reportsRoutePath: string = 'reports';
  public static readonly schedulingRoutePath: string = 'scheduling';
  public static readonly timeExpenseRoutePath: string = 'time-expense';
  public static readonly superAdmin: string = 'admin';
  public static readonly users: string = 'users';
  public static readonly companySettings: string = 'companySettings';
  public static readonly setUp: string = 'setup-wizard';
  public static readonly errorRoutePath: string = 'error';
  public static readonly accessdeniedRoutePath: string = 'accessdenied';
  public static readonly notfoundRoutePath: string = 'notfound';
  public static readonly loginRoutePath: string = 'login';
  public static readonly registerRoutePath: string = 'register';
  public static readonly passwordResetRoutePath: string = 'login/reset/:token';
  public static readonly lettersRoutePath = 'letters';

  // Routings
  public static readonly taskAssignationRoutePath: string = 'tasks-assignation';
  public static readonly viewRoutePath: string = 'view';
  public static readonly noteRoutePath: string = 'notes';
  public static readonly paymentsRoutePath: string = 'payments';
  public static readonly timeslipsRoutePath: string = 'timeslips';
  public static readonly mailRoutePath: string = 'mail';
  public static readonly ratesRoutePath: string = 'rates';
  public static readonly settingsRoutePath: string = 'settings';
  public static readonly invoicesRoutePath: string = 'invoices';
  public static readonly contactsRoutePath: string = 'contacts';
  public static readonly projectsRoutePath: string = 'projects';
  public static readonly clientScheduleRoutePath: string = 'schedule';
  public static readonly clientTodo: string = 'todo';
  public static readonly listRoutePath: string = 'list';
  public static readonly receiptsRoutePath: string = 'receipts';
  public static readonly depositsRoutePath: string = 'deposits';
  public static readonly editDepositRoutePath: string = 'editDeposit';
  public static readonly calenderRoutePath: string = 'calender';
  public static readonly permissionsRoutePath: string = 'permissions';
  public static readonly educationRoutePath: string = 'education';
  public static readonly scheduleRoutePath: string = 'Schedule';
  public static readonly merchantSetupRoutePath: string = 'merchantSetup';
  public static readonly engagementMattersRoutePath: string = 'engagementMatters';
  //public static readonly subscriptionSetupRoutePath: string = 'subscription'; //:@deprecate-stripe;
  public static readonly importsRoutePath: string = 'imports';
  public static readonly exportsRoutePath: string = 'exports';
  public static readonly importExportRoutePath: string = 'manageData';
  public static readonly taxImports: string = 'taxImports';
  public static readonly usersID: string = 'usersID';
  public static readonly staffRoleID: string = 'staffRoleID';
  public static readonly sessionTimeout: number = 3600000;
  public static readonly transactionsRoutePath: string = 'transactions';
  public static readonly creditCardsRoutePath: string = 'creditCards';
  public static readonly achTransactionRoutePath: string = 'ach';
  public static readonly returnAndVoidRoutePath: string = 'voids';
  public static readonly budgetsRoutePath: string = 'budgets';
  public static readonly capacityPlanningRoutePath: string = 'capacity-planning';
  public static readonly settingsAssignmentsRoutePath: string = 'settings/assignments';

  // Admin Routing
  public static readonly labelPrinting: string = 'labelPrinting';
  public static readonly emailAutomation: string = 'emailAutomation';
  public static readonly companyDashboard: string = 'companyDashboard';
  public static readonly adminSuperAdmin: string = 'superAdmin';
  public static readonly userLogs: string = 'userLogs';

  // Company Dashbooard Routing
  public static readonly revenue: string = 'revenue';
  public static readonly receipts: string = 'receipts';
  public static readonly staff: string = 'staff';

  // Email Automation Routing
  public static readonly setup: string = 'setup';
  public static readonly emailLists: string = 'emailLists';

  // Super Admin Dashboard Routing
  public static readonly mbRevenue: string = 'mb-revenue';
  public static readonly manageAccounts: string = 'manage-accounts';
  public static readonly customers: string = 'customers';
  public static readonly cpaEnrollments: string = 'cpaPay';
  public static readonly recurringInvoiceNew: string = 'recurring-invoice';
  public static readonly elUsageMetering: string = 'usage-metering';

  // Billing Invoicing Routing
  public static readonly manualInvoice: string = 'manualInvoice';
  public static readonly recurringInvoices: string = 'recurringInvoices';
  public static readonly batchBilling: string = 'batchBilling';
  public static readonly applyTimeToInvoice: string = 'applyTimeToInvoice';
  public static readonly clientStatements: string = 'client-statements';
  public static readonly statementPrinting: string = 'statement-printing';
  public static readonly retainerInvoice: string = 'retainerInvoice';
  public static readonly recurringInvoice: string = 'recurringInvoice';
  public static readonly queryBuilder: string = 'queryBuilder';

  // Company Routing
  public static readonly main: string = 'main';

  // Document Management Routing
  public static readonly dmsMain: string = 'dmsMain';
  public static readonly dmsUIMain: string = 'dmsUIMain';
  public static readonly filesAndfolders: string = 'filesAndfolders';

  // Engagements Routing
  public static readonly list: string = 'list';
  public static readonly view: string = 'view';
  public static readonly contacts: string = 'contacts';
  public static readonly notes: string = 'notes';

  // Import Export Routing
  public static readonly imports: string = 'imports';
  public static readonly exports: string = 'exports';

  // Scheduling Routing
  public static readonly scheduling: string = 'scheduling';

  // Project Management Routing
  public static readonly dashboard: string = 'dashboard';
  public static readonly projectList: string = 'projectList';
  public static readonly projectListWithID: string = 'projectList/:uniqueID';
  public static readonly settings: string = 'settings';
  public static readonly createTemplate: string = 'createTemplate';
  public static readonly createDuplicateWithTemplateID: string = ':templateID/createDuplicate';
  public static readonly createTemplateWithSettingsID: string = ':settingsID/createTemplate';
  public static readonly projectDetails: string = 'projectDetails';
  public static readonly projectDetailsWithProjectHeaderID: string =
    ':projectHeaderID/projectDetails';
  public static readonly clientDetailsWithProjectHeaderID: string =
    ':projectHeaderID/clientDetails';
  public static readonly masterTemplateLibrary: string = 'masterTemplateLibrary';
  public static readonly clientHistory: string = 'clientHistory';
  public static readonly ruleGenerator: string = 'ruleGenerator';

  // Project List Routing
  public static readonly projects: string = 'projects';
  public static readonly tasks: string = 'tasks';

  // Settings Routing
  public static readonly invoices: string = 'invoices';
  public static readonly activities: string = 'activities';
  public static readonly customLists: string = 'customLists';
  public static readonly banks: string = 'banks';
  public static readonly billrate: string = 'billrate';
  public static readonly rateTemplates: string = 'rateTemplates';
  public static readonly mailConfiguration: string = 'mailConfiguration';
  public static readonly quickbook: string = 'quickbook';
  public static readonly integrations: string = 'integrations';
  public static readonly mangoShareIntegration: string = 'mangoshare-integration';

  public static readonly assignments: string = 'assignments';
  public static readonly clientPortal: string = 'clientPortal';
  public static readonly manageRoles: string = 'manageRoles';
  public static readonly other: string = 'other';
  public static readonly updateHistory: string = 'updateHistory';
  public static readonly tax: string = 'tax';

  // Time Expense Routing
  public static readonly timeCard: string = 'time-card';
  public static readonly batchTimeCard: string = 'batch-time-card';
  public static readonly expenseManager: string = 'expense-manager';

  // Setup Wizard Routing
  public static readonly companyInfo: string = 'company-info';
  public static readonly companyLogo: string = 'company-logo';
  public static readonly companysettings: string = 'company-settings';
  public static readonly userSettings: string = 'user-settings';
  public static readonly finished: string = 'finished';

  // Company Setup Routing
  public static readonly setupSettings: string = 'settings';
  public static readonly setupLocations: string = 'locations';

  // Local Storage - Seession storage
  public static readonly clientRateTemplates: string = 'clientRateTemplates';
  public static readonly activityRateTemplates: string = 'activityRateTemplates';
  public static readonly rateTemplateNames: string = 'rateTemplateNames';
  public static readonly rateTemplateDetails: string = 'rateTemplateDetails';
  public static readonly rateLevelExceptions: string = 'rateLevelExceptions';
  public static readonly rateLevels: string = 'rateLevels';

  public static readonly isLoggingUser: string = 'LoggingUser';
  public static readonly isLaptop: string = 'isLaptop';
  public static readonly companySettingsData: string = 'companySettingsData';
  public static readonly isClientListFetched: string = 'isClientListFetched';
  public static readonly companyID: string = 'CompanyID';
  public static readonly clientID: string = 'ClientID';
  public static readonly allDataCountsForClient: string = 'alldataCounts';
  public static readonly allDataCountsFoEnagagement: string = 'alldataEngCounts';
  public static readonly projectID: string = 'projectID';
  public static readonly isFormChanged: string = 'isFormChanged';
  public static readonly ClientName: string = 'ClientName';
  public static readonly InvoiceFilter: string = 'InvoiceFilter';
  public static readonly ClientNameInvoice: string = 'ClientNameInvoice';
  public static readonly selectedUserName: string = 'selectedUserName';
  public static readonly SearchString: string = 'SearchString';
  public static readonly resourceID: string = 'ResourceID';
  public static readonly staffID: string = 'StaffID';
  public static readonly isNotShowElecPayments: string = 'isNotShowElecPayments';
  public static readonly token: string = 'token';
  public static readonly staffPermission: string = 'staffPermission';
  public static readonly subscriptionLevel: string = 'SubscriptionLevel';
  public static readonly setupStep: string = 'setupStep';
  public static readonly rememberMe: string = 'rememberMe';
  public static readonly userDetails: string = 'userDetails';
  public static readonly staffClientTableView: string = 'staffClientTableView';
  public static readonly staffData: string = 'staffData';
  public static readonly dateSubscribed: string = 'DateSubscribed';
  public static readonly datePlanActivated: string = 'DatePlanActivated';
  public static readonly dateCancelled: string = 'DateCancelled';
  public static readonly userName: string = 'userName';
  public static readonly userEmail: string = 'userEmail';
  public static readonly imageUrl: string = 'imageUrl';
  public static readonly language: string = 'language';
  public static readonly isManagingAccount: string = 'isManagingAccount';
  public static readonly systemLocking: string = 'systemLocking';

  public static readonly companyName: string = 'CompanyName';
  public static readonly companyData: string = 'companyData';
  public static readonly linkToPortal: string = 'LinkToPortal';
  public static readonly companyEmail: string = 'EmailCompany';
  public static readonly WebsiteCompany: string = 'WebsiteCompany';
  public static readonly profileIMGURL: string = 'imageUrl';
  public static readonly clientCount: string = 'clientCount';
  public static readonly telephone: string = 'Telephone';
  public static readonly isStaxEnrollmentStarted: string = 'isStaxEnrollmentStarted';
  public static readonly clientGroupCategory: string = 'clientGroupCategory';
  public static readonly NumberofUsers: string = 'NumberofUsers';
  public static readonly NoofActiveUsers: string = 'NoofActiveUsers';
  public static readonly NoofInactiveUsers: string = 'NoofInactiveUsers';
  public static readonly staffList: string = 'staffList';
  public static readonly allStaffList: string = 'allStaffList';
  public static readonly dmsStaffList: string = 'dmsStaffList';
  public static readonly todoStaffList: string = 'todoStaffList';
  public static readonly customerType: string = 'customerType';
  public static readonly shotHands: string = 'shotHands';
  public static readonly timeAndExpenses: string = 'timeAndExpenses';
  public static readonly activityList: string = 'activityList';
  public static readonly expenses: string = 'expenses';
  public static readonly UnbilledTime: string = 'UnbilledTime';
  public static readonly invoiceOptions: string = 'invoiceOption';
  public static readonly noteCategories: string = 'noteCategories';
  public static readonly paymentMethods: string = 'paymentMethods';
  public static readonly userPermissionObj: string = 'userPermissionObj';
  public static readonly userPermissions: string = 'userPermissions';
  public static readonly dmsEmailTemplate: string = 'dmsEmailTemplate';
  public static readonly planInfo: string = 'planInfo';
  public static readonly ddmSettings: string = 'ddmSettings';
  public static readonly fullClientList: string = 'fullClientList';
  public static readonly deleteMsg: string = 'Do you want to delete this record?';
  public static readonly deleteConfirm: string = 'Delete Confirmation';
  public static readonly selectedClientRecord: string = 'SelectedClientRecord';
  public static readonly selectedClientTabIndex: string = 'selectedClientTabIndex';
  public static readonly cashReceiptAutoApply: string = 'cashReceiptAutoApply';
  public static readonly cashReceiptPaymentType: string = 'cashReceiptPaymentType';
  public static readonly selectedEngagementName: string = 'EngagementName';
  public static readonly selectedUserRecord: string = 'selectedUserRecord';
  public static readonly updateMsg: string = 'Successfully Updated.';
  public static readonly createMsg: string = 'Successfully Created.';
  public static readonly deleteMessage: string = 'Successfully Deleted.';
  public static readonly stopedMessage: string = 'Successfully Stopped.';
  public static readonly updateErrorMsg: string = 'Error while updating record.';
  public static readonly createErrorMsg: string = 'Error while creating record.';
  public static readonly deleteErrorMsg: string = 'Error while deleting record.';
  public static readonly fetchErrorMsg: string = 'Error while fetching.';
  public static readonly emailSentMsg: string = 'Email sent successfully!';
  public static readonly emailsSentMsg: string = 'Email(s) sent successfully!';
  public static readonly crcardErrorMsg: string = 'Credit card was not registered!';
  public static readonly assignPayment: string = 'Successfully Assign Payment to Another Invoice';
  public static readonly dateError: string = 'Scheduled Date must be within two years from now';
  public static readonly errAssignPayment: string = 'Error Assigning Payment';
  public static readonly clientUpdated: string = 'clientUpdated';
  public static readonly CapacityPlanningEnabled: string = 'CapacityPlanningEnabled';

  // Client Main
  public static readonly Main: string = 'Main';
  public static readonly Settings: string = 'Settings';
  public static readonly Engagements: string = 'Engagements';
  public static readonly Invoices: string = 'Invoices';
  public static readonly InvoiceHistory: string = 'InvoiceHistory';
  public static readonly Banks: string = 'Banks';
  public static readonly Payments: string = 'Payments';
  public static readonly invoiceReview: string = 'invoiceReview';
  public static readonly TimeAndExpense: string = 'Time & Expenses';
  public static readonly Contacts: string = 'Contacts';
  public static readonly Notes: string = 'Notes';
  public static readonly EMAIlLOGS: string = 'Email Logs';
  public static readonly workLocations: string = 'workLocations';
  public static readonly savedWorkLocation: string = 'savedWorkLocation';
  public static readonly myProfile: string = 'myProfile';
  public static readonly schedulePreference: string = 'schedulePreference';
  public static readonly calendarIntegration: string = 'calendarIntegration';
  public static readonly mailIntegration: string = 'mailIntegration';
  public static readonly microsoftIntegration: string = 'microsoftIntegration';
  public static readonly emailSignature: string = 'emailSignature';
  public static readonly twoFA: string = 'twoFA';
  public static readonly CreateFromCompanyTemplate: string = 'CreateFromCompanyTemplate';

  public static readonly WorkLocation: string = 'WorkLocation';
  public static readonly timerTasks: string = 'timerTasks';
  public static readonly timeAndExpenseSettings: string = 'timeAndExpenseSettings';
  public static readonly timerTaskSelected: string = 'timerTaskSelected';

  public static readonly fromCompanyTemplate: string = 'fromCompanyTemplate';
  public static readonly companyTags: string = 'companyTags';
  public static readonly ddmProjectDetails: string = 'ddmProjectDetails';
  public static readonly ddmClientName: string = 'ddmClientName';

  public static readonly ddmMainUserType: string = 'ddmMainUserType';
  public static readonly ddmMainViewType: string = 'ddmMainViewType';
  public static readonly ddmMainDateRange: string = 'ddmMainDateRange';

  public static readonly editUserId: string = 'editUserId';
  public static readonly projectTemplateName: string = 'projectTemplateName';
  public static readonly tasksToClone: string = 'tasksToClone';
  public static superAdminVisible: number[] = [1, 971, 712, 847, 1073, 850];
  public static superAdminDeleteVisible: number[] = [6, 1157, 12632, 1167]; 
  public static updateHistoryVisible: number[] = [1, 971];

  public static readonly reportSettigs: string = 'reportSettigs';
  public static readonly InvoiceReviewUrl: string = '/billing-invoicing/invoiceReview';
  public static readonly staxRoutePath: string = 'cards-view';
  public static readonly staxTransationsRoutePath: string = 'stax-transactions';

  public static readonly timerOwner: string = 'timerOwner';

  public static readonly clientRateTemplate: number = 1;
  public static readonly activityRateTemplate: number = 2;
  public static readonly combiRateTemplate: number = 3;
  public static readonly StaxPaymentMethodID: string = 'StaxPaymentMethodID';

  public static readonly featureEmailList = [
    { name: 'Tim Sines', email: 'timsinescpa@gmail.com' },
    { name: 'Edmond Del Mundo', email: 'edmond.delmundo.ns@gmail.com' },
    { name: 'Richeve Bebedor', email: 'richeve.bebedor@narrasoft.com' },
    { name: 'Chez Andrew Intud', email: 'chez.intud.ns@gmail.com' }
  ];

  public static readonly UserSchedule: string = 'UserSchedule';
  public static readonly ReloadSchedule: string = 'ReloadSchedule';
  public static readonly redirectToDMSFromClient: string = 'redirectToDMSFromClient';
  public static readonly dmsActionType: DmsActionType = {
    read: 'read',
    create: 'create',
    delete: 'delete',
    download: 'download',
    upload: 'upload'
  };

  public static readonly retainerLastInvoiceDate = 'retainerLastInvoiceDate';
  public static readonly batchLastInvoiceDate = 'batchLastInvoiceDate';
  public static readonly batchLastDateThru = 'batchLastInvoiceDate';
  public static readonly manualLastInvoiceDate = 'manualLastInvoiceDate';
  public static readonly manualLastDateThru = 'manualLastDateThru';
  public static readonly recurringLastInvoiceDate = 'recurringLastInvoicateDate';
  public static readonly recurringLastDateThru = 'recurringLastDateThru';

  public static readonly userJustLoggedIn = 'userJustLoggedIn';
  public static readonly isDMSIshare = 'isDMSIshare';

  public static readonly urlPM = [
    `/${AppConstants.clientRoutePath}/${AppConstants.listRoutePath}`,
    `/${AppConstants.clientRoutePath}/${AppConstants.viewRoutePath}`,
    `/${AppConstants.clientRoutePath}/${AppConstants.contactsRoutePath}`,
    `/${AppConstants.clientRoutePath}/${AppConstants.projectsRoutePath}`,
    `/${AppConstants.projectManagementRoutePath}`,
    `/${AppConstants.projectManagementRoutePath}/${AppConstants.dashBoardRoutePath}`,
    `/${AppConstants.projectManagementRoutePath}/${AppConstants.clientHistory}`,
    `/${AppConstants.projectManagementRoutePath}/${AppConstants.projectList}`,
    `/${AppConstants.projectManagementRoutePath}/${AppConstants.settings}`,
    `/${AppConstants.projectManagementRoutePath}/${AppConstants.projectDetails}`,
    `/${AppConstants.setupSettings}/${AppConstants.customLists}`,
    `/${AppConstants.setupSettings}/${AppConstants.manageRoles}`,
    `/${AppConstants.companySettings}`,
    `/${AppConstants.companySettings}/${AppConstants.settingsRoutePath}`,
    `/${AppConstants.users}/${AppConstants.listRoutePath}`,
    `/${AppConstants.superAdmin}/${AppConstants.myProfile}`,
    `/${AppConstants.superAdmin}/${AppConstants.schedulePreference}`,
    `/${AppConstants.superAdmin}/${AppConstants.calendarIntegration}`,
    `/${AppConstants.superAdmin}/${AppConstants.emailSignature}`,
    `/${AppConstants.superAdmin}/${AppConstants.other}`,
    `/${AppConstants.superAdmin}/${AppConstants.labelPrinting}`,
    `/${AppConstants.superAdmin}/${AppConstants.emailAutomation}`,
    `/${AppConstants.superAdmin}/${AppConstants.companyDashboard}`,
    `/${AppConstants.superAdmin}/${AppConstants.userLogs}`,
    `/${AppConstants.users}/${AppConstants.viewRoutePath}`,
    `/${AppConstants.users}/${AppConstants.ratesRoutePath}`,
    `/${AppConstants.users}/${AppConstants.educationRoutePath}`,
    `/${AppConstants.users}/${AppConstants.clientScheduleRoutePath}`,
    `/${AppConstants.users}/${AppConstants.permissionsRoutePath}`
  ];
  //engagement letters
  public static readonly emails = 'emails';
  public static readonly services = 'services';
  public static readonly terms = 'terms';
  public static readonly proposal = 'proposal';
  public static readonly master = 'master';
  public static readonly proposalEdit = ':proposalID';
  public static readonly charts = 'charts';

  public static readonly ELServices: string = 'ELServices';
  public static readonly ELTerms: string = 'ELTerms';
  public static readonly ELEmailProposal: string = 'ELEmailProposal';
  public static readonly ELEmailProposalAccepted: string = 'ELEmailProposalAccepted';
  public static readonly ELEmailProposalMisc: string = 'ELEmailProposalMisc';
  public static readonly ELProposalObj: string = 'elSharedSrvc_proposalObj';
  public static readonly ELProjectHeaders: string = 'ELProjectHeaders';

  public static readonly subscriptionLevels = {
    FREE: 'FREE',
    ENTERPRISE: 'ENTERPRISE',
    SOLO: 'SOLO',
    PRO: 'PRO'
  };
}
