<div class="time-entry-list">
    <div class="layout-content  p-pt-0">
        <div class="p-mx-1">
            <div class="p-col-12">
                <div class="card">
                    <div class="p-formgroup-inline">
                        <div class="p-field p-mx-3 p-my-0 p-py-0">
                            <label for="For" class="p-mr-3 label-text">{{'staff-name' | translate}}</label>
                            <p-dropdown [options]="staffList" [(ngModel)]="staffID" (onChange)="selectStaff($event)"
                                [style]="{'width':'160px'}" [ngModelOptions]="{standalone: true}"></p-dropdown>
                        </div>
                        <div class="p-field p-mx-3 p-my-0 p-py-0">
                            <label for="For" class="p-mr-3 label-text">{{'Week-Including' | translate}}</label>
                            <p-calendar name="selectedWeekDate" [(ngModel)]="selectedWeekDate" [monthNavigator]="true"
                                [yearNavigator]="true" yearRange="2000:2030" showButtonBar="true"
                                (onSelect)="changeSelectedWeekDate($event)"></p-calendar>
                        </div>
                        <div class="p-field p-mx-3 p-my-0 p-py-0">
                            <span class="p-buttonset">
                                <button pButton icon="fal fa-angle-left" (click)="previous()"></button>
                                <button pButton label="{{'Today' | translate}}" (click)="today()"></button>
                                <button pButton icon="fal fa-angle-right" (click)="next()"></button>
                            </span>
                        </div>
                        <div class="p-field p-mx-3 p-my-0 p-py-0">
                            <button pButton pRipple type="button" label=" {{'refresh' | translate}}" class="p-mr-1"
                                icon="fal fa-sync" (click)="refresh()"></button>
                            <button pButton pRipple type="button" label=" {{'Add_Time' | translate}}" icon="fal fa-plus"
                                (click)="addRow(0)" *ngIf="clients.length == 0"></button>
                            <button pButton pRipple type="button" *ngIf="clients.length > 0 && isFormChanged"
                                label="{{'update-all' | translate}}" icon="fal fa-save" (click)="saveAll($event)"
                                [disabled]="(isValidForm)"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-pt-0">
                <div class="card p-pt-1">
                    <p-table #dt [value]="clients" [rowHover]="true" [responsive]="true" dataKey="SlipMasterID"
                        selectionMode="single" [filterDelay]="0" [rows]="clients.length" [scrollable]="true"
                        [columns]="selectedColumns"
                        sortField="ClientName" [scrollHeight]="'calc(100vh - 450px)'" (onFilter)="onFilter($event)"
                        styleClass="p-datatable-hoverable-rows p-datatable-responsive-demo p-datatable-sm table-medium-height"
                        [globalFilterFields]="globalFilterFields" editMode="row">
                        <ng-template pTemplate="caption">
                            <div class="table-header">
                                <div class="p-grid">
                                    <div class="p-col-6">
                                        <span class="p-input-icon-left">
                                            <i class="fal fa-search"></i>
                                            <input #searchValue pInputText type="text"
                                                (input)="dt.filterGlobal($event.target.value, 'contains')"
                                                placeholder="{{'globalsearch' | translate}}" />
                                        </span>
                                        <span>
                                            <button pButton pRipple type="button" icon="pi pi-times"
                                                class="p-button-rounded p-button-danger p-button-text"
                                                (click)="dt.reset();clearSearchFilter()" tooltipPosition="top"
                                                pTooltip="{{'clear' | translate}}"></button>
                                        </span>
                                        <p-message *ngIf="filteredItemsSize > -1" severity="success"
                                            text="{{filteredItemsSize}} {{'records_found' | translate}}."
                                            styleClass="p-ml-2">
                                        </p-message>
                                        <span *ngIf="timeMemoRequired && !isValidMemo" class="pull-right">
                                            <i class="fal fa-asterisk ic-red p-mr-2" tooltipPosition="top"></i>
                                            {{'memo-required' | translate}}
                                        </span>
                                    </div>

                                    <div class="p-col-6 p-formgroup-inline">
                                        <div class="p-col-6 p-formgroup-inline">
                                            <div class="p-field-radiobutton">
                                                <p-radioButton name="billableActual" value="billable"
                                                    label="Billable" [(ngModel)]="billableActual"
                                                    inputId="opt1" (ngModelChange)="onChangeBillableOrACtual($event)">
                                                </p-radioButton>
                                            </div>
                                            <i pTooltip="Default Time Card entry is billable time" 
                                                tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top" 
                                                class="fa fa-question-circle ic-blue p-mr-4 ic-size-16"></i>
                                            <div class="p-field-radiobutton">
                                                <p-radioButton name="billableActual" value="actual" label="Actual"
                                                    [(ngModel)]="billableActual" inputId="opt2" (ngModelChange)="onChangeBillableOrACtual($event)"></p-radioButton>
                                            </div>
                                            <p-checkbox [(ngModel)]="isRoundEntries" binary="true "
                                                [ngModelOptions]="{standalone: true} " label="Round Entries">
                                            </p-checkbox>
                                            <i pTooltip="Use your configured “Time & Expenses Time Increment” to round all entries to the increment." 
                                                tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top" 
                                                class="fa fa-question-circle ic-blue p-ml-3 ic-size-16"></i>
                                        </div>
                                        <div class="p-col-6">
                                            <span>
                                                <p-button label=" {{'Add-Row' | translate}}" class="p-mr-3" 
                                                    icon="fal fa-plus" (click)="addRow(clients.length)" pTooltip="Add new row by clicking this button or by pressing CTRL + 'i' keys" tooltipStyleClass="tooltip-width-300"  tooltipPosition="top" badge="CTRL + i" badgeClass="customized-badge" ></p-button>
                                            </span>
                                            <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns"
                                                optionLabel="header" selectedItemsLabel="{0} columns selected" (onChange)="onChangeSelectedCols($event)"
                                                [style]="{minWidth: '200px'}" placeholder="Choose Columns"></p-multiSelect>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th class="width-3p p-text-center">
                                    <!-- {{'actions' | translate}} -->
                                </th>
                                <th *ngFor="let col of columns" [pSortableColumn]="col.field" class="{{col.class}}">
                                    {{col.header}}
                                    <p-sortIcon [field]="col.field"></p-sortIcon>
                                </th>
                                <!-- <th pSortableColumn="SlipMasterID" pResizableColumn class="width-7p p-text-left">
                                    {{'sys_id' | translate}}
                                    <p-sortIcon field="SlipMasterID"></p-sortIcon>
                                </th> -->
                                <th pSortableColumn="ClientName" pResizableColumn class="width-15p p-text-left">
                                    {{'client.name' | translate}}
                                    <p-sortIcon field="ClientName"></p-sortIcon>
                                </th>
                                <th pSortableColumn="EngagementName" pResizableColumn class="width-15p p-text-left">
                                    {{'Engagements' | translate}}
                                    <p-sortIcon field="EngagementName"></p-sortIcon>
                                </th>
                                <th pSortableColumn="ServiceCode" pResizableColumn class="width-15p p-text-left">
                                    {{'activity' | translate}}
                                    <p-sortIcon field="ServiceCode"></p-sortIcon>
                                </th>
                                <th class="width-7p p-text-center">
                                    <span class="p-d-block">{{dateList[0].split(' ')[0]}}</span>
                                    <span>{{dateList[0].split(' ')[1]}}</span>
                                </th>
                                <th class="width-7p p-text-center">
                                    <span class="p-d-block">{{dateList[1].split(' ')[0]}}</span>
                                    <span>{{dateList[1].split(' ')[1]}}</span>
                                </th>
                                <th class="width-7p p-text-center">
                                    <span class="p-d-block">{{dateList[2].split(' ')[0]}}</span>
                                    <span>{{dateList[2].split(' ')[1]}}</span>
                                </th>
                                <th class="width-7p p-text-center">
                                    <span class="p-d-block">{{dateList[3].split(' ')[0]}}</span>
                                    <span>{{dateList[3].split(' ')[1]}}</span>
                                </th>
                                <th class="width-7p p-text-center">
                                    <span class="p-d-block">{{dateList[4].split(' ')[0]}}</span>
                                    <span>{{dateList[4].split(' ')[1]}}</span>
                                </th>
                                <th class="width-7p p-text-center">
                                    <span class="p-d-block">{{dateList[5].split(' ')[0]}}</span>
                                    <span>{{dateList[5].split(' ')[1]}}</span>
                                </th>
                                <th class="width-7p p-text-center">
                                    <span class="p-d-block">{{dateList[6].split(' ')[0]}}</span>
                                    <span>{{dateList[6].split(' ')[1]}}</span>
                                </th>
                                <th class="width-6p p-text-right ic-red">{{'Total-Time' | translate}}</th>
                                <th class="width-4p p-text-right ic-red"></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-expanded="expanded" let-columns="columns" let-editing="editing">
                            <tr [pEditableRow]="rowData">
                                <td class="width-3p">
                                    <div class="p-text-center">
                                        <!-- <i *ngIf="clients.length - 1 == rowIndex" class="fal fa-plus p-mr-2 cursor-hand ic-blue ic-size-15" (click)="addRow(rowIndex)"
                                            tooltipPosition="top" pTooltip="{{'add' | translate}}"></i> -->
                                        <i *ngIf="rowData['isNew']" (click)="deleteRow($event, rowIndex)"
                                            class="fal fa-trash-alt ic-red p-mr-2 cursor-hand ic-size-15"
                                            tooltipPosition="top" pTooltip="{{'mgmt-delete' | translate}}"></i>
                                    </div>
                                </td>
                                <td *ngFor="let col of columns" class="{{col.class}}" [pInitEditableRow]="col.editable"
                                    style="overflow:visible"
                                    pTooltip="{{rowData[col.field]}}" showDelay="800" tooltipStyleClass="tooltip-no-wrap"
                                    tooltipPosition="top">
                                    <span *ngIf="col.field==='SlipMasterID' && !rowData['isNew'] && rowData['SlipMasterID'] > 0">
                                        {{rowData.SlipMasterID}}
                                    </span>
                                </td>
                                <!-- <td class="width-7p p-text-left">
                                    {{rowData.SlipMasterID}}
                                </td> -->
                                <td pInitEditableRow style="overflow:visible" id="new-row"
                                    [ngClass]="{'new-row': (clients.length -1 == rowIndex && rowData['isNew'] == true)}" 
                                    (click)="onRowEditInit1(rowData['SlipMasterID'])"
                                    class="width-15p p-text-left p-text-truncate p-text-nowrap" showDelay="800"
                                    pTooltip="{{rowData.ClientName}}" tooltipPosition="top"
                                    tooltipStyleClass="tooltip-no-wrap">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <span class="p-text-left p-text-nowrap p-text-truncate"
                                                *ngIf="!rowData.isNew">{{rowData.ClientName}}</span>
                                            <p-autoComplete *ngIf="rowData.isNew" autocomplete="off"
                                                [suggestions]="filteredClients" (completeMethod)="filterClients($event)"
                                                [minLength]="1" [dropdown]="true" 
                                                placeholder="Type Client Name"
                                                (onSelect)="handleClientSelectClick($event, rowData)" appendTo="body"
                                                (onFocus)="$event.target.select()" class="p-text-truncate"
                                                [(ngModel)]="rowData['ClientID']" ngDefaultControl
                                                [ngModelOptions]="{standalone: true}"
                                                field="ClientName">
                                                <ng-template let-client pTemplate="item">
                                                    <div>{{client.ClientName}}{{ client.ClientNumber ? ' - ' + client.ClientNumber : '' }}</div>
                                                </ng-template>
                                            </p-autoComplete>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <div class="text-nowrap p-text-nowrap p-text-truncate">
                                                {{rowData['ClientName']}}</div>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pInitEditableRow class="width-15p p-text-left p-text-nowrap p-text-truncate"
                                    pTooltip="{{rowData.EngagementName}}" showDelay="800" tooltipPosition="top"
                                    (click)="onRowEditInit1(rowData['SlipMasterID'])"
                                    tooltipStyleClass="tooltip-no-wrap">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <span class="p-text-left p-text-nowrap p-text-truncate"
                                                *ngIf="!rowData.isNew">{{rowData.EngagementName}}</span>
                                            <p-dropdown *ngIf="rowData.isNew" [options]="rowData.engagementsList"
                                                [(ngModel)]="rowData.ProjectMasterID" name="projectMasterID"  
                                                (onShow)="isEngagementNameOpen=true" (onHide)="isEngagementNameOpen=false" 
                                                (onFocus)="onFocusDropdown($event, EngagementName)"
                                                [filter]="true" id="projectMasterID" #projectMasterID="ngModel"
                                                (onChange)="handleEngagementSelectClick($event, rowData,false)"
                                                appendTo="body" class="p-text-truncate"
                                                [disabled]="rowData.ClientID==null" [style]="{'width':'100%'}">
                                            </p-dropdown>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <div class="p-text-left p-text-nowrap p-text-truncate">
                                                {{rowData['EngagementName']}}</div>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pInitEditableRow style="overflow:visible;"
                                    class="width-15p p-text-left p-text-nowrap p-text-truncate"
                                    (click)="onRowEditInit1(rowData['SlipMasterID'])"
                                    pTooltip="{{rowData.ServiceCode}}" showDelay="800" tooltipPosition="top"
                                    tooltipStyleClass="tooltip-no-wrap">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <span *ngIf="!rowData.isNew"
                                                class="p-text-left p-text-nowrap p-text-truncate">{{rowData.ServiceCode}}</span>
                                            <p-dropdown *ngIf="rowData.isNew" [options]="activityList" 
                                                (onShow)="isServiceCodeOpen=true" (onHide)="isServiceCodeOpen=false" 
                                                (onFocus)="onFocusDropdown($event, ServiceCode)"
                                                [(ngModel)]="rowData.ServiceCode" name="serviceCodeID" [filter]="true"
                                                id="serviceCodeID" #serviceCodeID="ngModel"
                                                (onChange)="handleActivitySelectClick($event, rowData,false)"
                                                appendTo="body" class="p-text-truncate"
                                                [disabled]="rowData.ClientID==null" [style]="{'width':'100%'}">
                                            </p-dropdown>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <div class="p-text-left p-text-nowrap p-text-truncate">
                                                {{rowData['ServiceCode']}}</div>
                                        </ng-template>
                                    </p-cellEditor>
                                </td> 
                                <td pInitEditableRow style="overflow:visible;"
                                    (click)="onRowEditInit1(rowData['SlipMasterID'])"
                                    class="width-7p p-text-right p-text-nowrap cell-right-color"
                                    [ngClass]="{'bg-red': !rowData.weekday1.isValidMemo, '': rowData.weekday1.isValidMemo}"
                                    [ngStyle]="{'background-color':rowData['weekday1']['color']}">
                                    <i class="fa cursor-hand pull-left" [ngStyle]="{'margin-top': (editing? '10px' : '')}"
                                        [ngClass]="{'fa-file-text ic-blue': !!rowData.weekday1.Memo, 'fa-sticky-note-o': !rowData.weekday1.Memo}"
                                        (click)="addMemo('weekday1', rowData, rowIndex);$event.stopPropagation();"></i>
                                    <p-cellEditor *ngIf="billableActual === 'billable'">
                                        <ng-template pTemplate="input">
                                            <input pInputText type="text"
                                                [disabled]="(! rowData['MarkSlipsBilled'] && rowData['weekday1'] ['Billed']) || (isLocked(rowData['weekday1'] ['Ddate']))"
                                                [(ngModel)]="rowData['weekday1'] ['totalTime']"
                                                [ngModelOptions]="{ updateOn: 'blur' }"
                                                (ngModelChange)="updateTimeTotal($event,'weekday1', rowData, rowIndex)"
                                                (focus)="$event.target.select()" class="width-70p p-text-right"
                                                (mouseup)="$event.preventDefault()" />
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <span class="p-text-right"
                                                [ngClass]="{'p-text-bold': rowData['weekday1'] ['totalTime'] > 0}">{{rowData['weekday1']
                                                ['totalTime'] | number:'1.2-2'}}</span>
                                        </ng-template>
                                    </p-cellEditor>
                                    <p-cellEditor *ngIf="billableActual === 'actual'">
                                        <ng-template pTemplate="input">
                                            <input pInputText type="text"
                                                [disabled]="(! rowData['MarkSlipsBilled'] && rowData['weekday1'] ['Billed']) || (isLocked(rowData['weekday1'] ['Ddate']))"
                                                [(ngModel)]="rowData['weekday1'] ['ElaspedTime']"
                                                [ngModelOptions]="{ updateOn: 'blur' }"
                                                (ngModelChange)="updateTimeTotal($event,'weekday1', rowData, rowIndex)"
                                                (focus)="$event.target.select()" class="width-70p p-text-right"
                                                (mouseup)="$event.preventDefault()" />
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <span class="p-text-right"
                                                [ngClass]="{'p-text-bold': rowData['weekday1'] ['ElaspedTime'] > 0}">{{rowData['weekday1']
                                                ['ElaspedTime'] | number:'1.2-2'}}</span>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pInitEditableRow style="overflow:visible;"
                                    (click)="onRowEditInit1(rowData['SlipMasterID'])"
                                    class="width-7p p-text-right p-text-nowrap cell-right-color"
                                    [ngClass]="{'bg-red': !rowData.weekday2.isValidMemo, '': rowData.weekday2.isValidMemo}"
                                    [ngStyle]="{'background-color':rowData['weekday2']['color']}">
                                    <i class="fa cursor-hand pull-left" [ngStyle]="{'margin-top': (editing? '10px' : '')}"
                                        [ngClass]="{'fa-file-text ic-blue': !!rowData.weekday2.Memo, 'fa-sticky-note-o': !rowData.weekday2.Memo}"
                                        (click)="addMemo('weekday2', rowData, rowIndex);$event.stopPropagation();"></i>
                                    <p-cellEditor  *ngIf="billableActual === 'billable'">
                                        <ng-template pTemplate="input">
                                            <input pInputText type="text"
                                                [disabled]="(! rowData['MarkSlipsBilled'] && rowData['weekday2'] ['Billed']) || (isLocked(rowData['weekday2'] ['Ddate']))"
                                                [(ngModel)]="rowData['weekday2'] ['totalTime']"
                                                [ngModelOptions]="{ updateOn: 'blur' }"
                                                (ngModelChange)="updateTimeTotal($event,'weekday2', rowData, rowIndex)"
                                                (focus)="$event.target.select()" class="width-70p"
                                                (mouseup)="$event.preventDefault()" />
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <span class="p-text-right"
                                                [ngClass]="{'p-text-bold': rowData['weekday2'] ['totalTime'] > 0}">{{rowData['weekday2']
                                                ['totalTime'] | number:'1.2-2'}}</span>
                                        </ng-template>
                                    </p-cellEditor>
                                    <p-cellEditor *ngIf="billableActual === 'actual'">
                                        <ng-template pTemplate="input">
                                            <input pInputText type="text"
                                                [disabled]="(! rowData['MarkSlipsBilled'] && rowData['weekday2'] ['Billed']) || (isLocked(rowData['weekday2'] ['Ddate']))"
                                                [(ngModel)]="rowData['weekday2'] ['ElaspedTime']"
                                                [ngModelOptions]="{ updateOn: 'blur' }"
                                                (ngModelChange)="updateTimeTotal($event,'weekday2', rowData, rowIndex)"
                                                (focus)="$event.target.select()" class="width-70p"
                                                (mouseup)="$event.preventDefault()" />
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <span class="p-text-right"
                                                [ngClass]="{'p-text-bold': rowData['weekday2'] ['ElaspedTime'] > 0}">{{rowData['weekday2']
                                                ['ElaspedTime'] | number:'1.2-2'}}</span>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pInitEditableRow style="overflow:visible;"
                                    (click)="onRowEditInit1(rowData['SlipMasterID'])"
                                    class="width-7p p-text-right p-text-nowrap cell-right-color"
                                    [ngClass]="{'bg-red': !rowData.weekday3.isValidMemo, '': rowData.weekday3.isValidMemo}"
                                    [ngStyle]="{'background-color':rowData['weekday3']['color']}">
                                    <i class="fa cursor-hand pull-left" [ngStyle]="{'margin-top': (editing? '10px' : '')}"
                                        [ngClass]="{'fa-file-text ic-blue': !!rowData.weekday3.Memo, 'fa-sticky-note-o': !rowData.weekday3.Memo}"
                                        (click)="addMemo('weekday3', rowData, rowIndex);$event.stopPropagation();"></i>
                                    <p-cellEditor *ngIf="billableActual === 'billable'">
                                        <ng-template pTemplate="input">
                                            <input pInputText type="text"
                                                [disabled]="(! rowData['MarkSlipsBilled'] && rowData['weekday3'] ['Billed']) || (isLocked(rowData['weekday3'] ['Ddate']))"
                                                [(ngModel)]="rowData['weekday3'] ['totalTime']"
                                                [ngModelOptions]="{ updateOn: 'blur' }"
                                                (ngModelChange)="updateTimeTotal($event,'weekday3', rowData, rowIndex)"
                                                (focus)="$event.target.select()" class="width-70p"
                                                (mouseup)="$event.preventDefault()" />
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <span class="p-text-right"
                                                [ngClass]="{'p-text-bold': rowData['weekday3'] ['totalTime'] > 0}">{{rowData['weekday3']
                                                ['totalTime'] | number:'1.2-2'}}</span>
                                        </ng-template>
                                    </p-cellEditor>
                                    <p-cellEditor *ngIf="billableActual === 'actual'">
                                        <ng-template pTemplate="input">
                                            <input pInputText type="text"
                                                [disabled]="(! rowData['MarkSlipsBilled'] && rowData['weekday3'] ['Billed']) || (isLocked(rowData['weekday3'] ['Ddate']))"
                                                [(ngModel)]="rowData['weekday3'] ['ElaspedTime']"
                                                [ngModelOptions]="{ updateOn: 'blur' }"
                                                (ngModelChange)="updateTimeTotal($event,'weekday3', rowData, rowIndex)"
                                                (focus)="$event.target.select()" class="width-70p"
                                                (mouseup)="$event.preventDefault()" />
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <span class="p-text-right"
                                                [ngClass]="{'p-text-bold': rowData['weekday3'] ['ElaspedTime'] > 0}">{{rowData['weekday3']
                                                ['ElaspedTime'] | number:'1.2-2'}}</span>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pInitEditableRow style="overflow:visible;"
                                    (click)="onRowEditInit1(rowData['SlipMasterID'])"
                                    class="width-7p p-text-right p-text-nowrap cell-right-color"
                                    [ngClass]="{'bg-red': !rowData.weekday4.isValidMemo, '': rowData.weekday4.isValidMemo}"
                                    [ngStyle]="{'background-color':rowData['weekday4']['color']}">
                                    <i class="fa cursor-hand pull-left" [ngStyle]="{'margin-top': (editing? '10px' : '')}"
                                        [ngClass]="{'fa-file-text ic-blue': !!rowData.weekday4.Memo, 'fa-sticky-note-o': !rowData.weekday4.Memo}"
                                        (click)="addMemo('weekday4', rowData, rowIndex);$event.stopPropagation();"></i>
                                    <p-cellEditor *ngIf="billableActual === 'billable'">
                                        <ng-template pTemplate="input">
                                            <input pInputText type="text"
                                                [disabled]="(! rowData['MarkSlipsBilled'] && rowData['weekday4'] ['Billed']) || (isLocked(rowData['weekday4'] ['Ddate']))"
                                                [(ngModel)]="rowData['weekday4'] ['totalTime']"
                                                [ngModelOptions]="{ updateOn: 'blur' }"
                                                (ngModelChange)="updateTimeTotal($event,'weekday4', rowData, rowIndex)"
                                                (focus)="$event.target.select()" class="width-70p"
                                                (mouseup)="$event.preventDefault()" />
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <span
                                                [ngClass]="{'p-text-bold': rowData['weekday4'] ['totalTime'] > 0}">{{rowData['weekday4']
                                                ['totalTime'] | number:'1.2-2'}}</span>
                                        </ng-template>
                                    </p-cellEditor>
                                    <p-cellEditor *ngIf="billableActual === 'actual'">
                                        <ng-template pTemplate="input">
                                            <input pInputText type="text"
                                                [disabled]="(! rowData['MarkSlipsBilled'] && rowData['weekday4'] ['Billed']) || (isLocked(rowData['weekday4'] ['Ddate']))"
                                                [(ngModel)]="rowData['weekday4'] ['ElaspedTime']"
                                                [ngModelOptions]="{ updateOn: 'blur' }"
                                                (ngModelChange)="updateTimeTotal($event,'weekday4', rowData, rowIndex)"
                                                (focus)="$event.target.select()" class="width-70p"
                                                (mouseup)="$event.preventDefault()" />
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <span
                                                [ngClass]="{'p-text-bold': rowData['weekday4'] ['ElaspedTime'] > 0}">{{rowData['weekday4']
                                                ['ElaspedTime'] | number:'1.2-2'}}</span>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pInitEditableRow style="overflow:visible;"
                                    (click)="onRowEditInit1(rowData['SlipMasterID'])"
                                    class="width-7p p-text-right p-text-nowrap cell-right-color"
                                    [ngClass]="{'bg-red': !rowData.weekday5.isValidMemo, '': rowData.weekday5.isValidMemo}"
                                    [ngStyle]="{'background-color':rowData['weekday5']['color']}">
                                    <i class="fa cursor-hand pull-left" [ngStyle]="{'margin-top': (editing? '10px' : '')}"
                                        [ngClass]="{'fa-file-text ic-blue': !!rowData.weekday5.Memo, 'fa-sticky-note-o': !rowData.weekday5.Memo}"
                                        (click)="addMemo('weekday5', rowData, rowIndex);$event.stopPropagation();"></i>
                                    <p-cellEditor *ngIf="billableActual === 'billable'">
                                        <ng-template pTemplate="input">
                                            <input pInputText type="text"
                                                [disabled]="(! rowData['MarkSlipsBilled'] && rowData['weekday5'] ['Billed']) || (isLocked(rowData['weekday5'] ['Ddate']))"
                                                [(ngModel)]="rowData['weekday5'] ['totalTime']"
                                                [ngModelOptions]="{ updateOn: 'blur' }"
                                                (ngModelChange)="updateTimeTotal($event,'weekday5', rowData, rowIndex)"
                                                (focus)="$event.target.select()" class="width-70p"
                                                (mouseup)="$event.preventDefault()" />
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <span
                                                [ngClass]="{'p-text-bold': rowData['weekday5'] ['totalTime'] > 0}">{{rowData['weekday5']
                                                ['totalTime'] | number:'1.2-2'}}</span>
                                        </ng-template>
                                    </p-cellEditor>
                                    <p-cellEditor *ngIf="billableActual === 'actual'">
                                        <ng-template pTemplate="input">
                                            <input pInputText type="text"
                                                [disabled]="(! rowData['MarkSlipsBilled'] && rowData['weekday5'] ['Billed']) || (isLocked(rowData['weekday5'] ['Ddate']))"
                                                [(ngModel)]="rowData['weekday5'] ['ElaspedTime']"
                                                [ngModelOptions]="{ updateOn: 'blur' }"
                                                (ngModelChange)="updateTimeTotal($event,'weekday5', rowData, rowIndex)"
                                                (focus)="$event.target.select()" class="width-70p"
                                                (mouseup)="$event.preventDefault()" />
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <span
                                                [ngClass]="{'p-text-bold': rowData['weekday5'] ['ElaspedTime'] > 0}">{{rowData['weekday5']
                                                ['ElaspedTime'] | number:'1.2-2'}}</span>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pInitEditableRow style="overflow:visible;"
                                    (click)="onRowEditInit1(rowData['SlipMasterID'])"
                                    class="width-7p p-text-right p-text-nowrap cell-right-color"
                                    [ngClass]="{'bg-red': !rowData.weekday6.isValidMemo, '': rowData.weekday6.isValidMemo}"
                                    [ngStyle]="{'background-color':rowData['weekday6']['color']}">
                                    <i class="fa cursor-hand pull-left" [ngStyle]="{'margin-top': (editing? '10px' : '')}"
                                        [ngClass]="{'fa-file-text ic-blue': !!rowData.weekday6.Memo, 'fa-sticky-note-o': !rowData.weekday6.Memo}"
                                        aria-hidden="true"
                                        (click)="addMemo('weekday6', rowData, rowIndex);$event.stopPropagation();"></i>
                                    <p-cellEditor *ngIf="billableActual === 'billable'">
                                        <ng-template pTemplate="input">
                                            <input pInputText type="text"
                                                [disabled]="(! rowData['MarkSlipsBilled'] && rowData['weekday6'] ['Billed']) || (isLocked(rowData['weekday6'] ['Ddate']))"
                                                [(ngModel)]="rowData['weekday6'] ['totalTime']"
                                                [ngModelOptions]="{ updateOn: 'blur' }"
                                                (ngModelChange)="updateTimeTotal($event,'weekday6', rowData, rowIndex)"
                                                (focus)="$event.target.select()" class="width-70p"
                                                (mouseup)="$event.preventDefault()" />
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <span
                                                [ngClass]="{'p-text-bold': rowData['weekday6'] ['totalTime'] > 0}">{{rowData['weekday6']
                                                ['totalTime'] | number:'1.2-2'}}</span>
                                        </ng-template>
                                    </p-cellEditor>
                                    <p-cellEditor *ngIf="billableActual === 'actual'">
                                        <ng-template pTemplate="input">
                                            <input pInputText type="text"
                                                [disabled]="(! rowData['MarkSlipsBilled'] && rowData['weekday6'] ['Billed']) || (isLocked(rowData['weekday6'] ['Ddate']))"
                                                [(ngModel)]="rowData['weekday6'] ['ElaspedTime']"
                                                [ngModelOptions]="{ updateOn: 'blur' }"
                                                (ngModelChange)="updateTimeTotal($event,'weekday6', rowData, rowIndex)"
                                                (focus)="$event.target.select()" class="width-70p"
                                                (mouseup)="$event.preventDefault()" />
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <span
                                                [ngClass]="{'p-text-bold': rowData['weekday6'] ['ElaspedTime'] > 0}">{{rowData['weekday6']
                                                ['ElaspedTime'] | number:'1.2-2'}}</span>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                    <td pInitEditableRow style="overflow:visible;"
                                    (click)="onRowEditInit1(rowData['SlipMasterID'])"
                                    class="width-7p p-text-right p-text-nowrap cell-right-color"
                                    [ngClass]="{'bg-red': !rowData.weekday7.isValidMemo, '': rowData.weekday7.isValidMemo}"
                                    [ngStyle]="{'background-color':rowData['weekday7']['color']}">
                                    <i class="fa cursor-hand pull-left" [ngStyle]="{'margin-top': (editing? '10px' : '')}"
                                        [ngClass]="{'fa-file-text ic-blue': !!rowData.weekday7.Memo, 'fa-sticky-note-o': !rowData.weekday7.Memo}"
                                        (click)="addMemo('weekday7', rowData, rowIndex);$event.stopPropagation();"></i>
                                    <p-cellEditor *ngIf="billableActual === 'billable'">
                                        <ng-template pTemplate="input">
                                            <input pInputText type="text"
                                                [disabled]="(! rowData['MarkSlipsBilled'] && rowData['weekday7'] ['Billed']) || (isLocked(rowData['weekday7'] ['Ddate']))"
                                                [(ngModel)]="rowData['weekday7'] ['totalTime']"
                                                [ngModelOptions]="{ updateOn: 'blur' }"
                                                (ngModelChange)="updateTimeTotal($event,'weekday7', rowData, rowIndex)"
                                                (focus)="$event.target.select()" class="width-70p"
                                                (mouseup)="$event.preventDefault()" />
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <span
                                                [ngClass]="{'p-text-bold': rowData['weekday7'] ['totalTime'] > 0}">{{rowData['weekday7']
                                                ['totalTime'] | number:'1.2-2'}}</span>
                                        </ng-template>
                                    </p-cellEditor>
                                    <p-cellEditor *ngIf="billableActual === 'actual'">
                                        <ng-template pTemplate="input">
                                            <input pInputText type="text"
                                                [disabled]="(! rowData['MarkSlipsBilled'] && rowData['weekday7'] ['Billed']) || (isLocked(rowData['weekday7'] ['Ddate']))"
                                                [(ngModel)]="rowData['weekday7'] ['ElaspedTime']"
                                                [ngModelOptions]="{ updateOn: 'blur' }"
                                                (ngModelChange)="updateTimeTotal($event,'weekday7', rowData, rowIndex)"
                                                (focus)="$event.target.select()" class="width-70p"
                                                (mouseup)="$event.preventDefault()" />
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <span
                                                [ngClass]="{'p-text-bold': rowData['weekday7'] ['ElaspedTime'] > 0}">{{rowData['weekday7']
                                                ['ElaspedTime'] | number:'1.2-2'}}</span>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td class="width-6p p-text-right p-text-nowrap p-text-truncate ic-red">
                                    {{rowData.weekdayTotal | number:'1.2-2'}}
                                </td>
                                <td class="width-4p p-text-center">
                                    <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow
                                        icon="pi pi-check" class="p-button-rounded p-button-text p-button-success p-mr-2"></button>
                                    <i *ngIf="(rowData['IsColumnChanges'] || rowData['isNew']) && !editing"
                                        class="fal fa-asterisk ic-red p-mr-2 ic-size-15"
                                        tooltipPosition="top" pTooltip="{{'modified_please_save' | translate}}"></i>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="15">
                                    <p-message severity="info" text="{{'data_not_found' | translate}}."
                                        styleClass="p-px-2"></p-message>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="footer"  let-columns>
                            <tr *ngIf="clients.length > 0">
                                <td class="width-3p"></td>
                                <td *ngFor="let col of columns" class="{{col.class}}">
                                    <span *ngIf="col.field === 'ServiceCode'" class="p-text-right p-text-bold ic-red">
                                        {{'Totals' | translate}}
                                    </span>
                                </td>
                                <td class="width-15p"></td>
                                <td class="width-15p"></td>
                                <td class="width-15p"></td>
                               <!--  <td class="width-5p p-text-right ic-red">{{'Totals' | translate}}</td> -->
                                <td class="width-7p p-text-right ic-red cell-right-color">
                                    <span>{{ weekdayTotal(1) }}</span>
                                </td>
                                <td class="width-7p p-text-right ic-red cell-right-color">
                                    <span>{{ weekdayTotal(2) }}</span>
                                </td>
                                <td class="width-7p p-text-right ic-red cell-right-color">
                                    <span>{{ weekdayTotal(3) }}</span>
                                </td>
                                <td class="width-7p p-text-right ic-red cell-right-color">
                                    <span>{{ weekdayTotal(4) }}</span>
                                </td>
                                <td class="width-7p p-text-right ic-red cell-right-color">
                                    <span>{{ weekdayTotal(5) }}</span>
                                </td>
                                <td class="width-7p p-text-right ic-red cell-right-color">
                                    <span>{{ weekdayTotal(6) }}</span>
                                </td>
                                <td class="width-7p p-text-right ic-red cell-right-color">
                                    <span>{{ weekdayTotal(7) }}</span>
                                </td>
                                <td class="width-6p p-text-right ic-red">
                                    <span>{{ weekdayTotal(0) }}</span>
                                </td>
                                <td class="width-4p p-text-right ic-red">
                                    <span></span>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>
<p-dialog header="{{'work.description' | translate}}" [(visible)]="showAddMemo" [modal]="true" [style]="{width: '50vw'}"
    [contentStyle]="{overflow: 'visible'}" [maximizable]="false" showEffect="fade" [baseZIndex]="10000" [draggable]="true" [resizable]="false">
    <div class="p-m-1" *ngIf="clients[rowIndex] && clients[rowIndex] [selField]">
        <div class="p-grid p-mt-2">
            <div class="p-col-4 p-my-0 p-py-0">
                <label for="For" class="p-mr-2 label-text ic-green">{{clients[rowIndex]['ClientName']}}</label> |
                <label for="For" class="p-mx-2 label-text ic-red">{{clients[rowIndex]['ServiceCode']}}</label>
            </div>
            <div class="p-col-8 p-my-0 p-py-0 p-text-right">
                <p-checkbox [(ngModel)]=" clients[rowIndex] [selField][ 'Billable'] " binary="true "
                    [ngModelOptions]="{standalone: true} " label="{{'billable' | translate}} "
                    [disabled]="clients[rowIndex] [selField][ 'Billed'] "></p-checkbox>
            </div>
        </div>
        <div class="p-grid p-mt-2" *ngIf="companyGlobalSetting.isWorkLocationRequired">
            <div class="p-col-12">
                <label class="p-col-4 p-pl-0 p-pr-3 label-text p-my-1">{{'Work-Location' | translate}}</label>
                <p-dropdown placeholder="Search Location" #workLocation="ngModel" (focus)="$event.target.select()"
                    name="workLocation" id="workLocation" [options]="workLocationsList" [style]="{'width':'100%'}"
                    [(ngModel)]="clients[rowIndex] [selField]['WorkLocation']" filter="true" required>
                    <ng-template let-item pTemplate="selectedItem">
                        <span style="vertical-align:middle;">{{item.label}}</span>
                    </ng-template>
                    <ng-template let-itemdata pTemplate="item">
                        <div class="ui-helper-clearfix" style="position: relative;height: 25px;">
                            <div style="font-size:14px;margin-top:4px">{{itemdata.label}}</div>
                        </div>
                    </ng-template>
                </p-dropdown>
            </div>
        </div>
        <div class="p-grid p-mt-2">
            <div class="p-col-12">
                <label class="label-text p-my-1"
                    [ngClass]="{'required-field': timeMemoRequired, '': !timeMemoRequired}">{{'work.description' |
                    translate}}</label>
                    <app-mentions [(inputText)]="clients[rowIndex] [selField] ['Memo']" (inputTextChange)="replaceCaret(clients[rowIndex] [selField], 'Memo');"
                        id="memo" [rows]="5" style="width:100% !important; resize: vertical;"></app-mentions>
            </div>
        </div>
        <div class="p-grid p-mt-2">
            <div class="p-col-12">
                <label class="label-text p-my-1">{{'Private-Memo' | translate}}</label>
                <app-mentions [(inputText)]="clients[rowIndex] [selField] ['PrivateMemo']" (inputTextChange)="replaceCaret(clients[rowIndex] [selField], 'PrivateMemo')"
                    id="private-memo" [rows]="5" style="width:100% !important; resize: vertical;"></app-mentions>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button type="button" pButton pRipple icon="fal fa-times" label="{{'cancel_button' | translate}}"
             (click)="closeMemoDialog(selField, clients[rowIndex], rowIndex)"></button>
        <button type="button" pButton pRipple icon="fal fa-check" label="{{'save_button' | translate}}"
            (click)="saveMemo(selField, clients[rowIndex], rowIndex)"
            [disabled]="!clients[rowIndex] [selField]['isValidMemo']"></button>
    </ng-template>
</p-dialog>
