import { mangoUtils } from "@app/_services";
import { Component, ElementRef, EventEmitter, Input, Output, QueryList, ViewChild, ViewChildren } from "@angular/core";

@Component({
  selector: "app-mentions",
  templateUrl: "./mentions.component.html",
  styleUrls: ["./mentions.component.css"],
})
export class MentionsComponent {
  @Input() inputText!: string;
  @Input() required: boolean;
  @Input() rows: number;
  @Input() id: string;
  @Input() class: string;
  @Input() maxlength: string;
  @Input() disabled: boolean;
  @Input() style: string;
  @Output() inputTextChange = new EventEmitter<string>();
  @ViewChild("inputBox") inputBox;
  @ViewChildren("suggestionItem") suggestionItems!: QueryList<ElementRef>;
  showSuggestions = false;
  suggestions: string[] = [];
  mentionRegex = /(\/@|@|\/)([^ ]*)?/;
  selectedIndex: number = -1; 
  constructor(public mangoUtils: mangoUtils) {}

  onInput(text: string): void {
    const match = text.trim().match(this.mentionRegex);
    if (match) {
      const query = match[2] || ''; 
      this.suggestions = this.getSuggestions([query]);
      this.showSuggestions = true;
      this.selectedIndex = -1;
    } else {
      this.showSuggestions = false;
    }
  }

  onInputTextChange() {
    this.inputTextChange.emit(this.inputText);
  }

  getSuggestions(text: string[]): string[] {
    return this.mangoUtils.findChoices(text.toString());
  }

  selectSuggestion(str: string): void {
    const text = this.getText();
    let replaceWord = "";
    if (this.mangoUtils.shortcutLabels.some((shortcut) => shortcut.ShortCutCode == str)) {
      replaceWord = this.mangoUtils.shortcutLabels.find(
        (shortcut) => shortcut.ShortCutCode == str
      )?.Phrase;
    }
    let index = text.length//necessary to set the cursor position on the replaced word
    if (replaceWord) {
      const newText = text.replace(this.mentionRegex, (match, p1, p2) => {
        const suffix = text[text.indexOf(match) + match.length] !== ' ' ? ' ' : '';//set a space after the replaced word only if there is no space already
        let  replaceString = `${replaceWord}${suffix}`
        replaceString = this.mangoUtils.replaceCaretKeys(replaceString);
        index = text.indexOf(match) + replaceString.length;
        return replaceString;
      });
      this.setInputValue(newText);
      this.onInputTextChange();
    }
    this.inputBox.nativeElement.focus();

    setTimeout(() => {//set the cursor position at the end of the replaced word
      this.inputBox.nativeElement.setSelectionRange(index, index);
    }, 0);
    this.showSuggestions = false;
  }

  getText(): string {
    return this.inputText;
  }

  setInputValue(value: string): void {
    this.inputText = value;
  }

  onKeyDown(event: KeyboardEvent): void {
    if (this.showSuggestions) {
      if (event.key === "ArrowDown") {
        event.preventDefault();
        this.selectedIndex = (this.selectedIndex + 1) % this.suggestions.length;
        this.scrollToSelected();
      } else if (event.key === "ArrowUp") {
        event.preventDefault();
        this.selectedIndex = (this.selectedIndex - 1 + this.suggestions.length) % this.suggestions.length;
        this.scrollToSelected();
      } else if (event.key === "Enter" && this.selectedIndex >= 0) {
        event.preventDefault();
        this.selectSuggestion(this.suggestions[this.selectedIndex]);
      }
    }
  }

  scrollToSelected(): void {
    const selectedElement = this.suggestionItems.toArray()[this.selectedIndex];
    if (selectedElement) {
      selectedElement.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }
}
