<div class="pmsmastertemplate">
  <span class="pull-right p-mr-3 clientform-btn-top">
    <p-button
      pRipple
      label="Dashboard"
      icon="fal fa-users"
      styleClass="p-button-sm"
      class="p-button-help p-pr-2"
      routerLink="/project-management/dashboard"></p-button>
    <p-button
      pRipple
      label="{{ 'Clients' | translate }}"
      icon="fal fa-users"
      styleClass="p-button-sm"
      class="p-button-help p-pr-2"
      routerLink="/project-management/clientHistory"></p-button>
    <p-button
      pRipple
      label="{{ 'Projects' | translate }}"
      icon="fal fa-building"
      styleClass="p-button-sm"
      class="p-button-help p-pr-2"
      routerLink="/project-management/projectList"></p-button>
    <p-button
      pRipple
      label="{{ 'PM-Settings' | translate }}"
      icon="fal fa-cog"
      styleClass="p-button-sm"
      class="p-button-help p-pr-2"
      routerLink="/project-management/settings"></p-button>
  </span>
  <div class="layout-content p-p-2" style="margin-top: 2rem">
    <div class="p-grid nested-grid">
      <div class="p-col-7">
        <div class="card p-pt-0">
            <div class="p-inputgroup custom-search">
                <span class="p-inputgroup-addon">
                    <i class="pi pi-search"></i>
                </span>
                <input #searchInput pInputText type="text" placeholder="Search..."
                       (input)="applyFilter($event.target.value)" />
                <button pButton type="button" icon="pi pi-times" class="p-button-danger"
                        (click)="clearSearchFilter()"></button>
            </div>
          <table class="table table-hover table-sm">
            <thead>
              <tr>
                <th style="color: #5e81ac !important" class="width-15p text-center"></th>
                <th style="color: #5e81ac !important" class="width-55p text-left">{{ 'Master-Templates' | translate }}</th>
                <th style="color: #5e81ac !important" class="width-10p text-center"># {{ 'of-tasks' | translate }}</th>
                <th style="color: #5e81ac !important" class="width-15p text-center">{{ 'View-Tasks' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let rowData of filteredDataSource">
                <tr (click)="toggleRow(rowData)" class="cursor-hand">
                  <td colspan="4">
                    <span>
                      <i
                      style="color: black !important;"
                        *ngIf="rowData.hasChildrens"
                        [ngClass]="expandedRows[rowData.templateName] ? 'fa fa-fw fa-chevron-circle-down' : 'fa fa-fw fa-chevron-circle-right'"></i>
                      <span class="master-template-name ml-2">{{ rowData.templateName }}</span>
                    </span>
                  </td>
                </tr>
                <tr *ngIf="expandedRows[rowData.templateName] && rowData.hasChildrens">
                  <td colspan="4">
                    <table class="table table-sm">
                      <tbody>
                        <tr *ngFor="let child of rowData.childItems">
                          <td class="width-15p text-center">
                            <button
                              pTooltip="{{ 'added-to-company-library' | translate }}"
                              *ngIf="!child.isSaved"
                              pButton
                              icon="fal fa-plus"
                              label="{{ 'add' | translate }}"
                              (click)="addtoCompanyLibrary(child)"></button>
                            <button
                              class="ic-white bg-neon-green"
                              *ngIf="child.isSaved"
                              pTooltip="{{ 'added' | translate }}"
                              pButton
                              icon="fa fa-check"
                              label="Added"
                              disabled></button>
                          </td>
                          <td class="width-55p text-left">{{ child.TemplateName }}</td>
                          <td class="width-10p t-center">
                            <div class="badge-number bg-blue" *ngIf="child.detailcount > 0">
                              {{child.detailcount}}
                            </div>
                          </td>
                          <td class="width-15p t-center">
                            <i
                              class="fas fa-file-alt ic-size-18"
                              [ngClass]="child.detailcount > 0 ? 'ic-blue' : 'ic-grey'"
                              pTooltip="{{ 'view_project_tasks' | translate }}"
                              aria-hidden="true"
                              (click)="child.detailcount > 0 ? searchDetails(child) : null"></i>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<p-dialog
  [(visible)]="isDisplayDetailsTable"
  [draggable]="true"
  modal="modal"
  (onHide)="libDetailsDataSource = []"
  [style]="{width: '50vw'}"
  [responsive]="true">
  <p-header
    >{{ 'project' | translate }} - <strong>{{dialogHeaderText}}</strong> - {{ 'Tasks' | translate }}
  </p-header>
  <p-table
    styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
    [value]="libDetailsDataSource"
    [rows]="10"
    [totalRecords]="libDetailsDataSource.length"
    [pageLinks]="3"
    [rowsPerPageOptions]="[5,10,20]"
    [paginator]="libDetailsDataSource.length > 10"
    [paginatorPosition]="'top'"
    #detailtab>
    <!-- Table Header -->
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th class="width-15p p-text-center">{{ 'task' | translate }} #</th>
        <th class="width-85p p-text-center">{{ 'Task-Description' | translate }}</th>
      </tr>
    </ng-template>

    <!-- Rows -->
    <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
      <tr>
        <td class="p-text-center">{{rowData.TaskRow}}</td>
        <td class="p-text-left">{{rowData.TaskDescription}}</td>
      </tr>
    </ng-template>

    <!-- No data -->
    <ng-template pTemplate="emptymessage">
      <tr id="emptymessage">
        <td [attr.colspan]="2" class="p-text-center">
          <i class="fal fa-list-alt p-mt-5"></i>
          <p class="p-pb-5"><strong>{{ 'No-Project-Tasks' | translate }}.</strong></p>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <p-footer>
    <button pButton label="Close" (click)="isDisplayDetailsTable = false"></button>
  </p-footer>
</p-dialog>

