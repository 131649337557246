import {
  Component,
  ViewChild,
  OnInit,
  ElementRef,
  AfterViewInit,
  HostListener,
  Input
} from '@angular/core';
import { AppConstants } from '@app/_helpers/api-constants';
import {
  BreadcrumbService,
  EncrDecrService,
  MangoApiService,
  mangoUtils,
  AuthGuard
} from '@app/_services';
import { SharedComponentsService } from '@app/shared/components';
import { forkJoin, Subject, timer } from 'rxjs';
import { environment } from '@environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SelectItem } from 'primeng/api';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import $ from 'jquery';
declare let numeral: any;
declare let FattJs: any;
import moment from 'moment';
import { Table } from 'primeng/table';
import {
  ToolbarService,
  LinkService,
  ImageService,
  HtmlEditorService,
  RichTextEditorComponent,
  CountService
} from '@syncfusion/ej2-angular-richtexteditor';
import { CurrencyPipe } from '@angular/common';
import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';
@Component({
  selector: 'app-manual-invoice',
  templateUrl: './manual-invoice.component.html',
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService, CountService]
})
export class ManualInvoiceComponent implements OnInit, AfterViewInit {
  @ViewChild('clientAutocomplete') clientAutocomplete;
  public manualInvoiceForm: UntypedFormGroup;
  displayInvoiceHistoryModal: boolean = false;
  dialogDisplay: boolean = false;
  isPreviewVisible: boolean = false;
  isPreviewMode: boolean = false;
  public isDebitCard: boolean = false;
  billingHeaderId = null;
  public dialogTab1Display: boolean = false;
  public dialogTab2Display: boolean = false;
  public isBusiness: string = 'false';
  public dialogTab3Display: boolean = false;
  public dialogTab4Display: boolean = false;
  public dialogTab5Display: boolean = false;
  public isProcessing: boolean = false;
  public pdfUrl: any = '';
  paymentTypes: SelectItem[];
  cardTypes: SelectItem[];
  oldBillingHeader: any;
  oldSelectedItems: any;
  selectedAdvance: boolean = false;
  oldLineItems: any = [];
  oldInvoiceLessPayments: string;
  transCodeTypes: any = [];
  typeofDiscounts: SelectItem[];
  invoiceTemplateTypes: SelectItem[];
  finalizeActions: SelectItem[];
  public staffListItems: SelectItem[];
  companyLocations: SelectItem[] = [];
  showCardoption: boolean = false;
  public staffList: any[];
  public clonedData = {};
  engagementLevelTimeTax: 0;
  engagementLevelExpTax: 0;
  public staxHeaderTaxt: string = '';
  public allEmailContacts: any = [];
  public salesTax: any = {
    Labor: 0,
    Expense: 0,
    taxableAmtService: 0,
    taxableAmtExpense: 0,
    serviceTax: 0,
    expenseTax: 0
  };

  @ViewChild('topEditor')
  public topObj: RichTextEditorComponent;

  @ViewChild('bottomEditor')
  public bottomObj: RichTextEditorComponent;

  defaultParentRecord = {
    hasChildrens: false,
    ProjectMasterID: -1,
    EngagementName: null,
    expenseamount: '0',
    totaltime: '0',
    laboramount: '0',
    nonbillableamount: 0,
    writeupValue: 0,
    invoiceAmount: 0
  };

  public displaySideBar: boolean = false;
  public selectedParent: any = {
    ...this.defaultParentRecord,
    allTimeSlips: [],
    allExpenseSlips: [],
    allSelectedExpenseSlips: [],
    allSelectedTimeSlips: [],
    SearchClientName: '',
    EngagementName: ''
  };

  @ViewChild('descShort') input: ElementRef;
  public dom: Document;
  public myPaymentForm: UntypedFormGroup;
  public clientsList: any = [];
  public isFormValid: boolean = false;
  public noTimeRecordsWarning: boolean = false;
  public isApplyDiscount: boolean = false;
  public ServiceCodeID: any;
  public isDialogFormValid: boolean = false;
  public isLineItemsValid: boolean = false;
  public isCheckRef: boolean = false;
  public childRow: boolean = false;
  public filterActivityId: any;
  public clientRouteUrl: any = '';
  public IsAmount: boolean = true;
  public IsDateTypeReadOnly: boolean = true;
  public IsLinesItemsRequired: boolean = true;
  public IsEnableHistory: boolean = true;
  public selClient: any = '';
  public isClientSelected: boolean = false;
  public client: string;
  public descCount: number = 0;
  public grnadInvoiceAmt: any = '0';
  public selActivitiesType: any = {};
  public activitiesType: string = 'All Engagements';
  public serverMsg: string = '';
  public activitiesTypeId: any;
  public ServiceCode: any;
  public filteredClients: any = [];
  public filteredActivityList: any = [];
  public timeSlips: any = [];
  public filteredEngagementsList: any = [];
  public copyInvoicesDataSource: any = [];
  public historyDataSource: any = [];
  public invoiceOpt: any = {};
  public datePeriodTypes: SelectItem[];
  public lineItems: any = [];
  public openRetainersDataSource: any = [];
  bhPaymentDetailsArr: any = [];
  public expensesItems: any = [];
  public filteredStaffSingle: any[];
  public summaryItems: any = {
    selectedbillabletime: '$0.00',
    selectedNonbillabletime: '$0.00',
    writesupdown: '$0.00',
    Invoiceamountservices: '$0.00'
  };
  activityList: any = [];
  expList: any = [];
  public activitiesTypes: any;
  public termsList: any = [];
  public expensesList: any = [];
  public historyDisplay: boolean = false;
  public isPaymentFlowRequired: boolean = false;
  public NextInvoiceNumber: any = '';
  public selectedClientId: any;
  public AllStaffsTypes: any;
  public AllStaffsServiceTypes: any = [];
  public timeExpenseRecords: any = [];
  public selectedItems: any = [];
  public selectedExpensesItems: any = 0;
  public expensivestotal: any = '$0.00';
  public totalBillableHrs: any = 0;
  public totalStandardAmount: any = 0;
  public totalNonBillableAmt: any = 0;
  public totalNumberPaymentamt: number = 0;
  public totalPreviouslyApplied: number = 0;
  public totalAppliedAmount: number = 0;
  public processAmt: number = 0;
  public totalNumberPaymentUnapplied: any = 0;
  public totalCustomerBalance: number = 0;
  public totalLastInvoiceAmount: number = 0;
  public totalUnappliedAmount: any = 0;
  public totalBillableSummaryHrs: any = '$0.00';
  public amount_color = '#0e0e0e';
  public discount_color = '#0e0e0e';
  public lessPayments_color = '#0e0e0e';
  public lessDiscountValue = '$0.00';
  public invoiceAmount = '$0.00';
  public invoiceTax = 0;
  public invoiceLessPayments = '$0.00';
  public invoiceBalance = '$0.00';
  public showText1: any = 'Show';
  public showText2: any = 'Show';
  public isTopShow: boolean = false;
  public isBottomShow: boolean = false;
  public isShowDetails: boolean = false;
  public lessDiscount: any = '$0.00';
  public paymentProfile: any = null;
  public clientProfile: any = null;
  public showTable: boolean = true;
  public showText: any = 'Hide';
  public companyData: any = {};
  public flagAddNewCard: boolean = false;
  public mangoCompanyData: any = {};
  public isVisaCard: string = 'true';
  public serviceTableCols: any[];
  public openRetainersCols: any[];
  public timeSlipsCols: any[];
  public timeSlipsChildCols: any[];
  public expensiveCols: any[];
  public historyCols: any[];
  public selectedProjectMasterIds: any = '';
  public projectMastersArray: any = [];
  public engagementsTypes: any = [];
  private eventTimeSheetSubscription;
  private eventTimeExpenseSubscription;
  public selEngagementType: any = [];

  companyGlobalSetting: any = {};
  grandBillableExpenseAmt: any = 0.0;
  grandExpenseAmt: any = 0.0;
  grandBillableTime: any = 0.0;
  grandNonBillableAmt: any = 0.0;
  grandBillableAmt: any = 0.0;
  grandWUWDAmt: any = 0.0;
  grandInvAmt: any = 0.0;
  loginCompanyId: any;
  staffPermission: any;
  loginSerialNumber = '';
  public videoDisplay: boolean = false;
  public showACHDialog: boolean = false;
  public isOldAchCardoption: boolean = true;
  public showAchCardoption: boolean = false;
  public myAchReceiptsForm: UntypedFormGroup;
  public showAchNewCardoption: boolean = true;
  public achProfile: any = {};
  intervalid: any;
  public companyName: any;
  public emailCompany: any;
  public resourceId: any;
  isShowTimeComponent: boolean = true;

  public amountChanged: boolean = false;
  public isRedirect: boolean = false;

  @ViewChild('timeSlipsdt') dataTableComponent: Table;
  @ViewChild('servicelinetable') dt: Table;
  @ViewChild('dtchild') dtchild: Table;
  @ViewChild('dtchildex') dtchildex: Table;
  @ViewChild('searchValue') searchValue;
  @ViewChild('searchHistoryValue') searchHistoryValue;
  searchTextStr: any = '';
  searchTextTimeStr: any = '';
  filteredTimeItemsSize = -1;
  cellFocused: any = null;
  timeexp;
  _selectedColumns: any[];
  cols: any[];
  cashReceiptEmailTemplate = null;

  userTitle: string = '';

  // STax code
  public showStaxoption: boolean = false;
  public isStaxNewCard: boolean = false;
  public isCCFlow: boolean = false;
  public sTaxProfile: any = null;
  public fattJs: any;
  public isStaxVerify: boolean = false;
  public isSTaxEnabled: boolean = false;
  public transactionData: any = null;
  public userName;
  scratchPadEnabled: boolean = false;
  public editableData: any = null;
  public rteObj: RichTextEditorComponent;
  public tools: ToolbarModule = {
    items: [
      'Bold',
      'Italic',
      'Underline',
      'StrikeThrough',
      'FontName',
      'FontSize',
      'FontColor',
      'BackgroundColor',
      'LowerCase',
      'UpperCase',
      'SuperScript',
      'SubScript',
      '|',
      'Formats',
      'Alignments',
      'OrderedList',
      'UnorderedList',
      'Outdent',
      'Indent',
      '|',
      'CreateTable',
      'CreateLink',
      'Image',
      '|',
      'ClearFormat',
      'Print',
      'SourceCode',
      'FullScreen',
      '|',
      'Undo',
      'Redo'
    ]
  };
  isShowVideo = false;
  companySetting: any;

  constructor(
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private breadcrumbService: BreadcrumbService,
    private translate: TranslateService,
    public sharedSrvc: SharedComponentsService,
    private _fb: UntypedFormBuilder,
    private http: HttpClient,
    public mangoUtils: mangoUtils,
    public auth: AuthGuard,
    private router: Router,
    private currencyPipe: CurrencyPipe
  ) {
    this.loginCompanyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.userTitle = this.encrDecSrvc.getObject(AppConstants.userPermissions)?.Title;
    this.staffPermission = this.encrDecSrvc.getObject(AppConstants.staffPermission);
    this.expensesList = this.encrDecSrvc.getObject(AppConstants.expenses);
    this.emailCompany = this.encrDecSrvc.getObject(AppConstants.companyEmail);
    this.companyName = this.encrDecSrvc.getObject(AppConstants.companyName);
    this.resourceId = this.encrDecSrvc.getObject(AppConstants.resourceID);
    this.timeexp = this.encrDecSrvc.getObject(AppConstants.timeAndExpenses);
    this.userName = this.encrDecSrvc.getObject(AppConstants.userName);
    this.mangoUtils.shouldFetchCode.next(true);

    this.translate.reloadLang(this.translate.currentLang).subscribe(data => {
      this.initializeColumns();
      this.breadcrumbService.setItems([
        { label: this.translate.instant('Billing-&-Invoicing') },
        { label: this.translate.instant('billing-invoicing.manual-invoice'), icon: 'ic-red' }
      ]);

      this.typeofDiscounts = [
        { label: 'Flat Discount', value: 'Flat Amount' },
        { label: '% Discount', value: 'Percentage' }
      ];

      // these values of invoiceTemplateTypes is being used for this method -> checkNoTimeRecordsWarning
      // any changes will greatly affect the method
      this.invoiceTemplateTypes = [
        { value: '2', label: this.translate.instant('client.narrative') },
        {
          value: '9',
          label: this.translate.instant('client.narrative_summary')
        },
        {
          value: '10',
          label: this.translate.instant('client.narrative_detail')
        },
        {
          value: '4',
          label: this.translate.instant('client.narrative_mini_statement')
        },
        // { value: '14', label: this.translate.instant('Flexible Invoice') },
        { value: '0', label: this.translate.instant('client.detail') },
        { value: '6', label: this.translate.instant('client.detail_no_rate') },
        { value: '7', label: this.translate.instant('client.detail_no_rate_time') },
        {
          value: '11',
          label: this.translate.instant('client.detail_no_rate_time_amount')
        },
        {
          value: '12',
          label: this.translate.instant('client.narrative_simple_no_detail')
        },
        {
          value: '13',
          label: this.translate.instant('client.narrative_simple_with_detail')
        },
        {
          value: '14',
          label: this.translate.instant('client.narrative_simple_no_remit')
        },
        {
          value: '15',
          label: this.translate.instant('client.invoice_detail_summary')
        },
        {
          value: '16',
          label: this.translate.instant('client.invoice_narrative_summary_by_activity')
        }
      ];

      this.cardTypes = [
        { label: this.translate.instant('VISA'), value: 'VISA' },
        { label: this.translate.instant('MASTER CARD'), value: 'MSTR' },
        { label: this.translate.instant('DISCOVER'), value: 'DISC' },
        { label: this.translate.instant('AMERICAN EXPRESS'), value: 'AMEX' }
      ];

      this.transCodeTypes = [
        { label: 'None', value: null },
        { label: 'Checking', value: 27 },
        { label: 'Savings', value: 37 }
      ];

      if (this.loginCompanyId == 1) {
        this.invoiceTemplateTypes.push({ label: 'Laser Software', value: '8' });
      }

      this.finalizeActions = [
        { label: 'Print', value: 'Print' },
        { label: 'Email', value: 'Email' },
        { label: 'Finalize Only', value: 'Finalize Only' }
      ];
    });

    this.eventTimeSheetSubscription = this.sharedSrvc.timeEntryDialogData.subscribe(data => {
      this.refreshTimeandExpenseRecords();
    });

    this.eventTimeExpenseSubscription = this.sharedSrvc.expenseDialogData.subscribe(data => {
      this.refreshTimeandExpenseRecords();
    });

    this.initializeForm();
    this.initializeDialogForm();
    this.initializeACHForm();
    this.getAllStaffList();

    this.loadDefaults();
    this.getEngagements();
    this.getCompanyLocations();

    this.sharedSrvc.leaveAndSaveSub = new Subject<any>();
    this.sharedSrvc.leaveAndSaveSub.subscribe(() => {
      this.finalAndReviewAction(false);
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnload(e) {
    if (this.isPreviewVisible) {
      e.preventDefault();
      this.closeDialog();
    }
    if (!this.isPreviewVisible) {
      return '';
    }
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    //restore original order
    const arr = val.map(col => col.field);
    this._selectedColumns = this.cols.filter(col => arr.includes(col.field));
  }

  onChangeSelectedCols(event) {
    let columnsToSave = '';
    event.value.map((cols, index) => {
      if (index > 0) columnsToSave += `, ${cols.field}`;
      else columnsToSave += cols.field;
    });
    const objToSave = { OpenTimeCols: `{${columnsToSave}}` };
    this.mangoAPISrvc
      .updateUserSelectedColsByUserId(this.encrDecSrvc.getObject(AppConstants.staffID), objToSave)
      .subscribe(
        data => {},
        err => {}
      );
  }

  initializeColumns() {
    const allCols = [
      {
        field: 'Ddate',
        header: this.translate.instant('date'),
        rowClass: 'width-7p p-text-center'
      },
      {
        field: 'StaffName',
        header: this.translate.instant('user-title'),
        rowClass: 'width-10p p-text-left',
        canFilter: true
      },
      {
        field: 'StaffNumber',
        header: this.translate.instant('User Initials'),
        rowClass: 'width-7p p-text-left',
        canFilter: true
      },
      {
        field: 'scdescr',
        header: this.translate.instant('activity'),
        rowClass: 'width-10p p-text-left'
      },
      {
        field: 'ServiceCode',
        header: this.translate.instant('Code'),
        rowClass: 'width-6p p-text-left'
      },
      {
        field: 'Memo',
        header: this.translate.instant('description'),
        rowClass: 'width-15p p-text-left'
      }
    ];
    let selectedCols = [];
    const defaultCols = [
      {
        field: 'Ddate',
        header: this.translate.instant('date'),
        rowClass: 'width-7p p-text-center'
      },
      {
        field: 'StaffName',
        header: this.translate.instant('user-title'),
        rowClass: 'width-10p p-text-left'
      },
      {
        field: 'scdescr',
        header: this.translate.instant('activity'),
        rowClass: 'width-10p p-text-left'
      },
      {
        field: 'Memo',
        header: this.translate.instant('description'),
        rowClass: 'width-15p p-text-left'
      }
    ];
    this.cols = [...allCols];
    this.mangoAPISrvc
      .getUsersSelectedColsByUserId(this.encrDecSrvc.getObject(AppConstants.staffID))
      .subscribe(
        (data: any) => {
          if (data.OpenTimeCols?.length > 0) {
            selectedCols = allCols.filter(col => data.OpenTimeCols.includes(col.field));
          } else {
            selectedCols = [...defaultCols];
          }
          this._selectedColumns = selectedCols;
        },
        err => {
          selectedCols = [...defaultCols];
        }
      );
  }

  @HostListener('mouseup', ['$event'])
  @HostListener('mousemove', ['$event'])
  refreshUserState(event: MouseEvent) {
    if (!this.sharedSrvc.invoiceInactivitySub.closed)
      this.sharedSrvc.invoiceInactivitySub.next(null);
  }

  ngOnInit(): void {
    this.companySetting = this.encrDecSrvc.getObject(AppConstants.systemLocking);

    this.intervalid = setInterval(() => {
      this.getActivityGroups();
      this.getExpenseGroups();
      this.fetchClients();
    }, 50);
  }

  ngAfterViewInit() {
    // this.searchValue.nativeElement.focus();
    this.manualInvoiceForm.valueChanges.subscribe(data => {
      this.validateForm();
    });
  }

  ngOnDestroy() {
    this.sharedSrvc.leaveAndSaveSub.unsubscribe();
    this.eventTimeSheetSubscription.unsubscribe();
    this.eventTimeExpenseSubscription.unsubscribe();
  }

  previewInvoice() {
    this.processInvoice(false, true);
  }

  closeDialog() {
    this.isPreviewVisible = false;
    this.mangoAPISrvc
      .reverseInvoice(this.billingHeaderId, this.isPreviewMode)
      .subscribe(data => {});
  }

  newPreviewInvoices(billingHeader) {
    let pdfUrl;
    const parent = this;
    parent.dialogTab1Display = false;
    parent.dialogTab2Display = false;
    parent.dialogTab3Display = false;
    parent.dialogTab4Display = false;
    parent.dialogTab5Display = false;
    parent.pdfUrl = '';
    billingHeader['IsPrintPreview'] = true;
    billingHeader['LastModifiedStaffID'] = this.encrDecSrvc.getObject(AppConstants.staffID);
    this.mangoAPISrvc
      .updateBillingHeader(billingHeader, billingHeader['BillingHeaderID'])
      .subscribe(data => {
        switch (billingHeader['InvoiceTemplate']) {
          case '2':
          case '4':
            pdfUrl = new URL(`${encodeURIComponent('/home/tim/InvoiceNarrativeBatch.prpt')}/report`, `${environment.SERVICE_ADDRESS}/api/pentaho-api-repos/`);

            parent.isPreviewVisible = true;
            parent.pdfUrl = pdfUrl.href;

            setTimeout(() => {
              parent.mangoAPISrvc.showLoader(false);
              parent.dialogTab1Display = true;
            }, 300);
            break;
          case '0':
          case '1':
          case '11':
          case '6':
          case '7':
            pdfUrl = new URL(`${encodeURIComponent('/home/tim/InvoiceDetailBatch.prpt')}/report`, `${environment.SERVICE_ADDRESS}/api/pentaho-api-repos/`);

            parent.isPreviewVisible = true;
            parent.pdfUrl = pdfUrl.href;

            setTimeout(() => {
              parent.mangoAPISrvc.showLoader(false);
              parent.dialogTab2Display = true;
            }, 300);
            break;
          case '12':
          case '13':
            pdfUrl = new URL(`${encodeURIComponent('/home/tim/InvoiceSimpleBatch.prpt')}/report`, `${environment.SERVICE_ADDRESS}/api/pentaho-api-repos/`);

            parent.isPreviewVisible = true;
            parent.pdfUrl = pdfUrl.href;

            setTimeout(() => {
              parent.mangoAPISrvc.showLoader(false);
              parent.dialogTab3Display = true;
            }, 300);
            break;
          case '14':
            pdfUrl = new URL(`${encodeURIComponent('/home/tim/NarrativeSimpleNoRemitBatch.prpt')}/report`, `${environment.SERVICE_ADDRESS}/api/pentaho-api-repos/`);

            parent.isPreviewVisible = true;
            parent.pdfUrl = pdfUrl.href;

            setTimeout(() => {
              parent.mangoAPISrvc.showLoader(false);
              parent.dialogTab5Display = true;
            }, 300);
            break;
          case '15':
            pdfUrl = new URL(`${encodeURIComponent('/home/tim/InvoiceDetailSummaryBatch.prpt')}/report`, `${environment.SERVICE_ADDRESS}/api/pentaho-api-repos/`);

            parent.isPreviewVisible = true;
            parent.pdfUrl = pdfUrl.href;

            setTimeout(() => {
              parent.mangoAPISrvc.showLoader(false);
              parent.dialogTab5Display = true;
            }, 300);
            break;
          case '16':
            pdfUrl = new URL(`${encodeURIComponent('/home/tim/InvoiceNarrativeSummarybyActivityBatch.prpt')}/report`, `${environment.SERVICE_ADDRESS}/api/pentaho-api-repos/`);

            parent.isPreviewVisible = true;
            parent.pdfUrl = pdfUrl.href;

            setTimeout(() => {
              parent.mangoAPISrvc.showLoader(false);
              parent.dialogTab5Display = true;
            }, 300);
            break;
          case '9':
          case '10':
            pdfUrl = new URL(`${encodeURIComponent('/home/tim/InvoiceNarrativeSummaryBatch.prpt')}/report`, `${environment.SERVICE_ADDRESS}/api/pentaho-api-repos/`);

            parent.isPreviewVisible = true;
            parent.pdfUrl = pdfUrl.href;

            setTimeout(() => {
              parent.mangoAPISrvc.showLoader(false);
              parent.dialogTab4Display = true;
            }, 300);
            break;
        }
      });
  }

  getCompanyLocations() {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getCompanyLocations(this.loginCompanyId).subscribe(
      (data: any) => {
        this.companyLocations = data.map(location => {
          return {
            value: location.CompanyMangoLocationID,
            label: location.CompanyLocation,
            laborRate: location.TaxRateLaborL,
            expenseRate: location.TaxRateExpenseL
          };
        });
        this.mangoAPISrvc.showLoader(false);
      },
      err => this.mangoAPISrvc.showLoader(false)
    );
  }

  isLocked(ddate) {
    if (!(this.auth.isAllowAccess(32) || this.auth.isSuperAdmin)) {
      if (this.timeexp.isLockTime && parseInt(this.timeexp.LockTimeDays) > 0) {
        const dDate = moment(ddate).format('MM/DD/YYYY');
        const dateNow = moment().format('MM/DD/YYYY');
        return moment(dateNow).diff(moment(dDate), 'days') > this.timeexp.LockTimeDays;
      }
    }
    return false;
  }

  getExpenseGroups() {
    const _this = this;
    if (_this.expList.length > 0) {
      return false;
    }
    // _this.expList = [{ label: "", value: null, dataItem: {} }];
    const data = this.encrDecSrvc.getObject(AppConstants.expenses);
    for (let i = 0; i < data.length; i++) {
      if (data[i].Inactive == false) {
        _this.expList.push({
          label: data[i].Description,
          value: data[i].ExpenseCodeID,
          dataItem: data[i]
        });
      }
    }
    _this.expList.sort(_this.mangoUtils.sortList);
  }

  getActivityGroups() {
    const parent = this;
    if (this.activityList.length > 0) {
      return false;
    }
    //this.activityList = [{ label: 'Select Activity', value: null, fullobj: {} }];
    let tempList = this.encrDecSrvc.activitiesList;
    tempList = tempList.sort(parent.mangoUtils.compareValues('ServiceCode', 'asc'));
    for (let i = 0; i < tempList.length; i++) {
      parent.activityList.push({
        label: tempList[i].ServiceCode + ' - ' + tempList[i].Description,
        value: tempList[i].ServiceCodeID,
        fullobj: tempList[i]
      });
    }
  }

  getActivityName(id) {
    return this.activityList.filter(activity => activity.value === id)[0].label;
  }

  processAchCard() {
    const parent = this;
    const formData = parent.myAchReceiptsForm.value;
    formData['LastName'] = formData['FirstName'].substring(0, 19);

    const formObj = this.myPaymentForm.value;
    formObj['paymentAmount'] = numeral(formObj['paymentAmount']).value();
    formData['Amount'] = formObj['paymentAmount'];

    if (
      parent.achProfile &&
      parent.achProfile.TransType == 'ACH' &&
      parent.achProfile.TransactionReference != null
    ) {
      const achPayment = {};
      achPayment['MerchantID'] = parent.mangoCompanyData['CCmerchantID'];
      achPayment['Login'] = parent.mangoCompanyData['CClogin'];
      achPayment['Password'] = parent.mangoCompanyData['CCpassword'];
      achPayment['Amount'] = formObj['paymentAmount'];
      achPayment['Token'] = parent.achProfile.TransactionReference;
      achPayment['CheckNegativeAccounts'] = false;
      achPayment['StandardEntryCode'] = 'WEB';

      // if (formData.paymentType == "SDACH") {
      //   achPayment['SameDayACH'] = 'SD';
      // }
      parent.mangoAPISrvc.showLoader(true);
      parent.mangoAPISrvc.tokenizedPaymentsACHCard(achPayment).subscribe((data: any) => {
        if (data.Status == 'success') {
          // parent.calculateSummary();
          parent.closePaymentForm(true);
          parent.mangoAPISrvc.showLoader(false);
          parent.mangoAPISrvc.notify(
            'success',
            this.translate.instant('Success_notify'),
            this.translate.instant('Successfully_Processed')
          );
        }
      });
    } else {
      formData['TokenizeOnly'] = true;
      parent.mangoAPISrvc.showLoader(true);
      parent.mangoAPISrvc.createACHCard(formData).subscribe((data: any) => {
        if (data.Status == 'success') {
          // reference api used for creating profile for CC transactions
          // TransType = ACH or for Credit Card, TransType = CC  go back and update the Create for Cc
          // if ACH, need to use last 4 for BankAcctNo.
          const obj = {};
          obj['TransactionReference'] = data.Confirmation;
          obj['TransType'] = 'ACH';
          obj['BankAcctNo'] = formData['AccountNumber'].substr(
            formData['AccountNumber'].length - 4,
            formData['AccountNumber'].length
          );
          obj['ClientID'] = parent.selectedClientId;
          obj['CompanyID'] = parent.loginCompanyId;
          obj['Active'] = true;
          obj['nameOnCard'] = formData['FirstName'];
          parent.mangoAPISrvc.saveProfileIDinMango(obj).subscribe(data => {
            // Call tokenize API
            const achPayment = {};
            achPayment['MerchantID'] = parent.mangoCompanyData['CCmerchantID'];
            achPayment['Login'] = parent.mangoCompanyData['CClogin'];
            achPayment['Password'] = parent.mangoCompanyData['CCpassword'];
            achPayment['Amount'] = formData['Amount'];
            achPayment['Token'] = obj['TransactionReference'];
            achPayment['CheckNegativeAccounts'] = false;
            achPayment['StandardEntryCode'] = 'WEB';

            parent.mangoAPISrvc.tokenizedPaymentsACHCard(achPayment).subscribe((tokendata: any) => {
              if (tokendata.Status == 'success') {
                //parent.calculateSummary();

                parent.closePaymentForm(true);
                parent.mangoAPISrvc.showLoader(false);
                parent.mangoAPISrvc.notify(
                  'success',
                  this.translate.instant('Success_notify'),
                  this.translate.instant('Successfully_Processed')
                );
              }
            });
          }),
            error => {
              parent.mangoAPISrvc.notify(
                'error',
                this.translate.instant('error'),
                AppConstants.updateErrorMsg
              );
              parent.mangoAPISrvc.showLoader(false);
            };
        } else {
          parent.mangoAPISrvc.showLoader(false);
          parent.mangoAPISrvc.notify('error', this.translate.instant('error'), data.Message);
        }
      }),
        error => {
          parent.mangoAPISrvc.notify(
            'error',
            this.translate.instant('error'),
            AppConstants.updateErrorMsg
          );
          parent.mangoAPISrvc.showLoader(false);
        };
    }
  }

  finalAndReviewAction(isFinal) {
    const parent = this;
    if (numeral(this.invoiceBalance).value() < 0) {
      Swal.fire({
        icon: 'warning',
        title: this.translate.instant('Warning'),
        text: this.translate.instant('billing.A_negative_invoice_is_not_allowed'),
        showConfirmButton: false,
        timer: 2000
      });
      return false;
    }
    if (numeral(this.grandInvAmt).value() == 0) {
      Swal.fire({
        title: this.translate.instant('confirmation'),
        html: this.translate.instant('billing.no_invoice_amount_for_services'),
        icon: 'warning',
        showCancelButton: true,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: this.translate.instant('I_know_continue'),
        cancelButtonText: this.translate.instant('no_cancel')
      }).then(result => {
        if (result.value) {
          parent.processInvoice(isFinal);
        }
      });
    } else {
      Swal.fire({
        title: this.translate.instant('confirmation'),
        html: this.translate.instant('billing.all_selected_time_records'),
        icon: 'warning',
        showCancelButton: true,
        showDenyButton: true,
        width: '38em',
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: this.translate.instant('save_and_new'),
        denyButtonText: this.translate.instant('save_and_invoice_review'),
        cancelButtonText: this.translate.instant('no_cancel')
      }).then(result => {
        if (result.isConfirmed) {
          parent.processInvoice(isFinal);
        } else if (result.isDenied) {
          parent.isRedirect = true;
          parent.processInvoice(isFinal);
        }
      });
    }
  }
  /*
      processing the invoice below are the steps

     1. updating the comapnymango table with NextInvoiceNumber

   */
  processInvoice(isFinal: any, isPreviewMode?) {
    // let lastInvoiceObj = {};
    // lastInvoiceObj["NextInvoiceNumber"] = this.NextInvoiceNumber;
    const invoiceTemplate = this.manualInvoiceForm.value.invoiceTemplate;
    if (
      JSON.stringify(this.selectedItems) === JSON.stringify(this.oldSelectedItems) &&
      JSON.stringify(this.lineItems) === JSON.stringify(this.oldLineItems) &&
      this.selectedAdvance &&
      isPreviewMode &&
      invoiceTemplate === '0' &&
      this.invoiceLessPayments === this.oldInvoiceLessPayments
    ) {
      this.newPreviewInvoices(this.oldBillingHeader);
      setTimeout(() => {
        this.clearData();
      }, 500);
      return;
    }
    this.isPreviewMode = isPreviewMode ? isPreviewMode : false;
    this.mangoAPISrvc.showLoader(true);
    // this.mangoAPISrvc
    //   .updateLastInvoiceNumber(lastInvoiceObj)
    //   .subscribe((data) => {
    //     this.saveBillingHeaderInfo(isFinal);
    //   });
    this.mangoAPISrvc
      .getPreviousBalance(
        this.manualInvoiceForm.value.ClientID,
        moment(this.manualInvoiceForm.value.InvoiceDate).format('YYYY-MM-DD HH:mm:ss'),
        numeral(this.invoiceLessPayments || 0).value()
      )
      .subscribe(
        (result: any) => {
          this.saveBillingHeaderInfo(isFinal, result['PreviousBalance'] || 0);
        },
        err => {
          this.saveBillingHeaderInfo(isFinal, 0);
        }
      );
  }
  /*
       Saving Billing Header information for the NEW manual invoice created. Step 1
       Have to get the BillingHeaderID to be used for Detail records and to update other records.
   */
  saveBillingHeaderInfo(isFinal, previousBalance) {
    const formObj = this.manualInvoiceForm.value;
    const dialogFormObj = this.myPaymentForm.value;

    const headerObj = {};
    headerObj['ClientID'] = formObj.ClientID;
    headerObj['ClientName'] = this.selClient['ClientName'];
    headerObj['isPreviewMode'] = this.isPreviewMode;
    headerObj['CompanyID'] = this.loginCompanyId;
    headerObj['QBReference'] = null;
    headerObj['InvoiceNumber'] = this.isPreviewMode ? null : this.NextInvoiceNumber;

    headerObj['InvoiceDate'] = moment(new Date(formObj.InvoiceDate))
      .format('YYYY-MM-DD')
      .toString();

    headerObj['PeriodTo'] = formObj.InvoiceStartDate;
    headerObj['DescriptionShort'] = formObj.DescriptionShort;
    headerObj['TotalServices'] = numeral(this.grandInvAmt).value();
    headerObj['InvoiceBalance'] = numeral(this.invoiceBalance).value();
    headerObj['TotalWUWD'] = numeral(this.grandWUWDAmt).value();
    headerObj['TotalExpenses'] = numeral(this.grandExpenseAmt).value();
    //  sales tax
    headerObj['TotalTax'] = this.salesTax.serviceTax
      ? numeral(this.salesTax.serviceTax).value()
      : 0;
    headerObj['SalesTaxAmount'] = this.salesTax.taxableAmtService
      ? numeral(this.salesTax.taxableAmtService).value()
      : 0;
    headerObj['SalesTaxAmountExpenses'] = this.salesTax.taxableAmtExpense
      ? numeral(this.salesTax.taxableAmtExpense).value()
      : 0;
    headerObj['TotalTaxExpenses'] = this.salesTax.expenseTax
      ? numeral(this.salesTax.expenseTax).value()
      : 0;
    headerObj['Discount'] = numeral(this.lessDiscount).value();

    headerObj['BillNote'] = formObj.bottomInvoiceNote;
    headerObj['BillNoteTop'] = formObj.topInvoiceNote;
    headerObj['BillNote'] = headerObj['BillNote']
      ? this.mangoUtils.replaceCaretTemplate(headerObj['BillNote'], headerObj)
      : '';

    headerObj['InvoiceAmount'] = numeral(this.invoiceAmount).value();
    headerObj['TotalPayments'] = Math.abs(numeral(this.invoiceLessPayments).value());
    headerObj['InvoiceType'] = 'Manual Invoice';
    headerObj['GraceDays'] = this.clientProfile?.GraceDays;
    headerObj['ProjectMasterIDArray'] = this.projectMastersArray;

    // headerObj["PreviousBalance"] = this.mangoUtils.addFloat((this.totalCustomerBalance || 0), Math.abs(headerObj["TotalPayments"] || 0));
    headerObj['PreviousBalance'] = previousBalance;
    headerObj['PaymentsAppliedInvoicing'] = numeral(
      this.invoiceLessPayments?.replace('($', '').replace(',', '').replace(')', '')
    ).value();

    // if (isFinal) {
    //   headerObj["InvoicePosted"] = true;
    // } else {
    //   headerObj["InvoicePosted"] = false;
    // }

    headerObj['OriginatingPartnerID'] = this.clientProfile['OriginatingPartnerID'];
    headerObj['BillingPartnerID'] = this.clientProfile['BillingPartnerID'];
    headerObj['StaffAssignedID'] = this.clientProfile['StaffAssignedID'];
    headerObj['GroupDescriptionID'] = this.clientProfile['GroupDescriptionID'];
    headerObj['GroupDescriptionIDArray'] = this.clientProfile['GroupDescriptionIDArray'];
    headerObj['isShowTearOffFooter'] = this.clientProfile['isShowTearOffFooter'];

    headerObj['ClientTypeID'] = this.clientProfile['ClientTypeID'];
    headerObj['InvoiceTemplate'] = '2';

    if (!this.clientProfile['DefaultInvoiceTemplate']) {
      headerObj['InvoiceTemplate'] = '2'; // if it is Null
      this.invoiceOpt['invoiceTemplate'] = 2;
      if (formObj.invoiceTemplate == '8') {
        headerObj['InvoiceTemplate'] = '8';
      }
    } else {
      const template = this.invoiceTemplateTypes.filter(
        item => item['value'] == formObj.invoiceTemplate
      );
      if (template.length > 0 && template[0].value) {
        headerObj['InvoiceTemplate'] = template[0].value;
        this.invoiceOpt['invoiceTemplate'] = template[0].value;
      }
    }

    this.invoiceOpt['FinalizeAction'] = headerObj['FinalizeAction'] = formObj.FinalizeAction;

    headerObj['InvoicePosted'] =
      (headerObj['FinalizeAction'] == 'Finalize Only' &&
        this.mangoCompanyData['SkipInvoiceReview'] &&
        !this.isPreviewMode) ||
      false;

    // Less Payments is displayed as negative on screen
    if (numeral(this.invoiceLessPayments).value() < 0) {
      headerObj['TempDepositAmount'] = numeral(dialogFormObj['paymentAmount']).value();
      headerObj['TempDepositDate'] = dialogFormObj['paymentDate'];
      headerObj['TempDepositMemo'] = dialogFormObj['paymentMemo'];
      headerObj['TempDepositRef'] = dialogFormObj['checkReference'];
      headerObj['TempDepositType'] = dialogFormObj['paymentType'];
    }

    headerObj['CreatedbyStaffID'] = this.encrDecSrvc.getObject(AppConstants.staffID);
    headerObj['SurChargeAmount'] = dialogFormObj['SurChargeAmount']
      ? dialogFormObj['SurChargeAmount']
      : 0;
    //console.log("Billing header >>>  ", dialogFormObj)

    this.mangoAPISrvc.createBillingHeader(headerObj).subscribe(
      (rateData: any) => {
        const billingHeaderId = rateData.data['BillingHeaderID'];
        if (this.isPreviewMode) {
          this.billingHeaderId = billingHeaderId;
        }
        const lastInvoiceObj = {};
        if (!this.isPreviewMode) {
          headerObj['InvoiceNumber'] = rateData.data['InvoiceNumber'];
          lastInvoiceObj['NextInvoiceNumber'] = rateData.data['InvoiceNumber'];
          this.mangoAPISrvc.updateLastInvoiceNumber(lastInvoiceObj).subscribe(data => {
            // Call the PaymentHeader Function only if Payments were entered in Payments Dialog
            if (headerObj['TempDepositAmount'] > 0) {
              this.createPaymentHeaderRecord(billingHeaderId, headerObj['InvoiceNumber']);
            } else {
              this.saveClientSettingsData(headerObj['ClientID']);
            }
          });
        }
        /* BillingDetail must be created with a Manual Invoice
          The BillingDetail records is associated with the BillingHeader and billingHeaderID
        */
        this.createBillingDetailRecord(billingHeaderId, headerObj);
        if (!this.isPreviewMode) {
          this.mangoAPISrvc.showLoader(false);
          this.mangoAPISrvc.notify(
            'success',
            this.translate.instant('Success_notify'),
            AppConstants.createMsg
          );
        }
      },
      err => {
        this.mangoAPISrvc.notify('error', 'Error!', err);
        this.mangoAPISrvc.showLoader(false);
      }
    );
  }

  /*
    Process Invoice Service Line items in table
    Creating billing details record
  */
  createBillingDetailRecord(billingheaderId, headerObj) {
    const formObj = this.manualInvoiceForm.value;
    const lineItemSaveList: any = [];
    if (this.lineItems.length == 0) {
      this.updateExpenseDataTable(billingheaderId, headerObj);
      headerObj['BillingHeaderID'] = billingheaderId;
      this.processTimeRecords(billingheaderId, headerObj);
    } else {
      /*
        If there is Service Line Amount is > 0 then Create Billing Detail Records
        If No Service Line Amount then then No need to Create Billing Detail Record
      */
      const processedArr = [];
      for (let i = 0; i < this.lineItems.length; ++i) {
        const obj = this.lineItems[i];
        const detailObj = {};
        const indexSelItems = this.timeExpenseRecords.findIndex(
          item => item.ProjectMasterID == obj['ProjectMasterID']
        );

        const selectedExpensesID =
          this.timeExpenseRecords[indexSelItems].allSelectedExpenseSlips.map(
            item => item.SlipMasterID
          ) || [];

        const expAmount =
          this.timeExpenseRecords[indexSelItems]?.allExpenseSlips
            .filter(
              item =>
                item.ProjectMasterID == obj['ProjectMasterID'] &&
                selectedExpensesID?.includes(item['SlipMasterID'])
            )
            ?.reduce((a, b) => {
              return a + (b['Billable'] ? numeral(b['BilledAmount']).value() : 0);
            }, 0) || 0;

        if (!processedArr.includes(obj['ProjectMasterID'])) {
          processedArr.push(obj['ProjectMasterID']);
          detailObj['WriteUpDownTime'] =
            this.timeExpenseRecords[indexSelItems]?.allSelectedTimeSlips
              .filter(item => item.ProjectMasterID == obj['ProjectMasterID'])
              ?.reduce((a, b) => {
                return a + numeral(b['WriteUpDown']).value();
              }, 0) || 0;

          detailObj['WriteUpDownExpenses'] =
            this.timeExpenseRecords[indexSelItems]?.allExpenseSlips
              .filter(
                item =>
                  item.ProjectMasterID == obj['ProjectMasterID'] &&
                  selectedExpensesID?.includes(item['SlipMasterID'])
              )
              ?.reduce((a, b) => {
                return a + numeral(b['WriteUpDown']).value();
              }, 0) || 0;
        }
        detailObj['BillingHeaderID'] = billingheaderId;
        detailObj['ClientID'] = formObj.ClientID;
        detailObj['ShowDetail'] = formObj.invoiceTemplate == '14' ? obj.ShowDetail : false;
        detailObj['CompanyID'] = this.loginCompanyId;
        detailObj['WorkCodeID'] = obj.WorkCodeID;
        detailObj['ServiceCodeID'] = obj.activityId;
        detailObj['Description'] = obj['description'];
        detailObj['EngagementTypeID'] = obj['EngagementTypeID'];
        detailObj['ProjectID'] = obj['ProjectMasterID'];

        detailObj['InvoiceDate'] = moment(new Date(formObj.InvoiceDate))
          .format('YYYY-MM-DD')
          .toString();

        detailObj['InvoiceNumber'] = headerObj['InvoiceNumber'];
        detailObj['SalesTaxAmount'] = obj.invoiceTax;
        detailObj['IsExpenseRollUp'] = obj.IsExpenseRollUp || false;
        detailObj['ExpenseAmount'] = obj.ExpenseAmount ? expAmount : 0;
        if (obj.IsExpenseRollUp && obj.IsExpenseRollUp == true) {
          detailObj['IsExpenseRollUp'] = true;
        } else {
          detailObj['IsExpenseRollUp'] = false;
        }
        detailObj['StaffID'] = obj.StaffID;
        detailObj['LineItem'] = obj['LineItem'] ? obj['LineItem'] : i + 1;
        detailObj['Amount'] = numeral(obj.amount).value();
        detailObj['Discount'] = numeral(obj.discount).value();

        detailObj['OriginatingPartnerID'] = this.clientProfile['OriginatingPartnerID'];
        detailObj['BillingPartnerID'] = this.clientProfile['BillingPartnerID'];
        detailObj['GroupDescriptionIDArray'] = this.clientProfile['GroupDescriptionIDArray'];
        detailObj['ClientTypeID'] = this.clientProfile['ClientTypeID'];

        lineItemSaveList.push(detailObj);
      }

      this.processBillingDetailRecords(lineItemSaveList).subscribe(data => {
        if (!this.isPreviewMode) {
          this.lineItems?.forEach(lineItem => {
            this.mangoAPISrvc
              .sendBudgetAlert({
                ClientID: formObj.ClientID,
                ProjectMasterID: lineItem['ProjectMasterID'],
                CompanyID: this.loginCompanyId,

                Ddate: moment(new Date(formObj.InvoiceDate)).format('YYYY-MM-DD')
              })
              .subscribe(
                data => {},
                error => {
                  console.log(error);
                }
              );
          });
        }

        // this.notificationsService.notify('success', this.translate.instant("Updated Successfully"));
        /*
              This Function will be handled and checked when the Save button is selected
              Need to check to see if any Expense items have been selected for billing.
            */

        this.updateExpenseDataTable(billingheaderId, headerObj);

        headerObj['BillingHeaderID'] = billingheaderId;

        this.processTimeRecords(billingheaderId, headerObj);
      });
    }
  }

  /*
    Only Process if Payments entered in Payments Dialog.
    creating the paymentHeader Record with a NEW BillingHeader Id
    Need to See if a Payment was entered in the Payment Dialog
    If TRUE, then need to create a NEW PaymentHeader that associates with BillingHeaderID
  */
  createPaymentHeaderRecord(id, invoiceNumber) {
    const formObj = this.manualInvoiceForm.value;
    const dialogFormObj = this.myPaymentForm.value;
    const paymentHeaderObj = {};

    // Ravi : 17/12 this condition we are checking top
    //if (numeral(this.invoiceLessPayments).value() < 0) {

    paymentHeaderObj['ClientID'] = formObj.ClientID;
    paymentHeaderObj['BillingHeaderID'] = id;
    paymentHeaderObj['PaymentDate'] = dialogFormObj['paymentDate'];
    paymentHeaderObj['CheckRef'] = dialogFormObj['checkReference'];
    paymentHeaderObj['PaymentType'] = dialogFormObj['paymentType'];
    paymentHeaderObj['PaymentAmount'] = numeral(dialogFormObj['paymentAmount']).value();
    paymentHeaderObj['PaymentNote'] = dialogFormObj['paymentMemo'];
    paymentHeaderObj['TempPaymentNote'] = dialogFormObj['paymentMemo'];

    paymentHeaderObj['SelectedForDepositToBank'] = false;
    paymentHeaderObj['OriginatingPartnerID'] = this.clientProfile['OriginatingPartnerID'];
    paymentHeaderObj['BillingPartnerID'] = this.clientProfile['BillingPartnerID'];
    paymentHeaderObj['StaffAssignedID'] = this.clientProfile['StaffAssignedID'];
    paymentHeaderObj['GroupDescriptionID'] = this.clientProfile['GroupDescriptionID'];
    paymentHeaderObj['ClientTypeID'] = this.clientProfile['ClientTypeID'];
    paymentHeaderObj['CompanyID'] = this.loginCompanyId;
    paymentHeaderObj['ProjectMasterIDArray'] = this.projectMastersArray;
    paymentHeaderObj['GroupDescriptionIDArray'] = this.clientProfile['GroupDescriptionIDArray'];
    paymentHeaderObj['StaxID'] = this.transactionData ? this.transactionData.id : null;
    let note;
    if (this.transactionData) {
      if (this.transactionData.payment_method) {
        note = `Paid : ${this.transactionData.payment_method.nickname}`;
      } else if (
        this.transactionData.child_transactions &&
        this.transactionData.child_transactions.length > 0
      ) {
        note = `Paid : ${this.transactionData.child_transactions[0].payment_method.nickname}`;
      }
      paymentHeaderObj['PaymentNote'] =
        paymentHeaderObj['PaymentType'] == 'Credit Card' ? note + ' (MI)' : note + ') (MI)';
      paymentHeaderObj['TempPaymentNote'] =
        paymentHeaderObj['PaymentType'] == 'Credit Card' ? note + ' (MI)' : note + ') (MI)';
      if (paymentHeaderObj['PaymentType'] == 'Credit Card' && this.isDebitCard) {
        paymentHeaderObj['PaymentType'] = 'Debit card';
      }
    }

    paymentHeaderObj['CreatedbyStaffID'] = this.encrDecSrvc.getObject(AppConstants.staffID);
    paymentHeaderObj['SurChargeAmount'] = dialogFormObj['SurChargeAmount']
      ? dialogFormObj['SurChargeAmount']
      : 0;
    this.mangoAPISrvc.createPaymentHeader(paymentHeaderObj).subscribe((rateData: any) => {
      this.transactionData = null;
      // this.notificationsService.notify('success', this.translate.instant(rateData.message));
      /* A PaymentDetail record is only created if payment dialog was used and ProcessPayment = TRUE
       */
      this.sendMailToCustmor(this.processAmt, rateData.data['PaymentHeaderID']);
      this.sendToCompany(this.processAmt, this.clientProfile, paymentHeaderObj);
      this.createPaymentDetailRecord(
        id,
        rateData.data['PaymentHeaderID'],
        invoiceNumber,
        rateData.data,
        paymentHeaderObj
      );
      this.saveClientSettingsData(formObj.ClientID);
    });
  }

  /*
    Payment was entered in Payment Dialog = TRUE
    So a paymentDetail record must be created for the Payment
    The PaymentHeader and PaymentDetail are associated with the NEW BillingHeaderID
    Create the payment Detail Record by BillingHeader Id and PaymentHeader Id
  */
  createPaymentDetailRecord(billingheaderId, paymentheaderId, invoiceNumber, pHeader, formData) {
    const formObj = this.manualInvoiceForm.value;
    const detailObj = { ...pHeader };

    detailObj['PaymentHeaderID'] = paymentheaderId;
    detailObj['BillingHeaderID'] = billingheaderId;
    detailObj['PaymentDate'] = pHeader['PaymentDate'];
    detailObj['CheckRef'] = pHeader['CheckRef'];
    detailObj['PaymentType'] = pHeader['PaymentType'];
    detailObj['AppliedAmount'] = pHeader['PaymentAmount'];
    detailObj['InitialAmount'] = pHeader['PaymentAmount'];
    detailObj['InvoiceNumber'] = invoiceNumber;

    detailObj['InvoiceDate'] = moment(new Date(formObj.InvoiceDate))
      .format('YYYY-MM-DD')
      .toString();
    console.log({ detailObj, line: 1416 });
    detailObj['PaymentNote'] = formData['TempPaymentNote'];
    detailObj['ClientID'] = formObj.ClientID;

    this.mangoAPISrvc.createPaymentDetail(detailObj).subscribe(rateData => {
      //  this.notificationsService.notify('success', this.translate.instant(rateData.message));
    });
  }

  /*
     updaing the expense table
  */
  updateExpenseDataTable(billingheaderId, headerObj) {
    const formObj = this.manualInvoiceForm.value;
    const datasetList: any = [];
    const list = this.openRetainersDataSource.filter(item => item['selected'] == true);
    if (list.length > 0) {
      this.processSelectedRetainerPayments(billingheaderId, headerObj);
    }
  }

  updateExpenseMasterRecords(selectedItemList) {
    const observableBatch = [];
    const parent = this;
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Authorization', parent.encrDecSrvc.getObject('token'));
    selectedItemList.forEach((selectedItem, key) => {
      const urlOne = parent.http.put(
        `${environment.API_URL}/company/timeSheet/${selectedItem['SlipMasterID']}`,
        selectedItem,
        {
          headers: headers,
          withCredentials: true
        }
      );
      observableBatch.push(urlOne);
    });

    return forkJoin(observableBatch);
  }

  /*
   creating BillingDetail records

*/
  processBillingDetailRecords(selectedItemList) {
    const observableBatch = [];
    const parent = this;
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Authorization', parent.encrDecSrvc.getObject('token'));
    selectedItemList.forEach((selectedItem, key) => {
      const urlOne = parent.http.post(`${environment.API_URL}/billingDetail/create`, selectedItem, {
        headers: headers,
        withCredentials: true
      });
      observableBatch.push(urlOne);
    });

    return forkJoin(observableBatch);
  }

  /*

      Process Time Records - There are 2 conditions
      There are time records in the data table
          1. Time records are selected and need to be processed (Update Time Records)
      There are time records and NONE are selected OR There are NO time records in the Billable Time table.
          2. Time records are NOT selected (At least 1 time record needs to be created based on the Service Line Item.  Need to Insert new time record.)
    */
  processTimeRecords(billingHeaderId, billingHeader) {
    const formObj = this.manualInvoiceForm.value;
    const _TotalStandardAmount = 0;
    let _TotalStaffCost = 0;
    const timeSlipsDS = [];
    let datasetList = [];
    let createtimeSlipsDS = [];
    const parent = this;

    const isPreviewMode = billingHeader['isPreviewMode'];

    if (this.selectedItems.length == 0) {
      setTimeout(() => {
        this.clearData();
      }, 500);
    }
    if (this.selectedItems.length > 0) {
      // parent loop : level 1
      for (let i = 0; i < this.selectedItems.length; ++i) {
        const parentRow = this.selectedItems[i];
        parentRow.IsExpenseRollUp = parentRow.IsExpenseRollUp;

        // create time record if billable amount is zero
        if (
          parentRow.laboramount == 0 &&
          parentRow.expenseamount == 0 &&
          numeral(parent.grandInvAmt).value() == 0 &&
          parentRow.allSelectedTimeSlips.length == 0
        ) {
          const lineItem = this.lineItems.filter(
            element => element['ProjectMasterID'] == parentRow['ProjectMasterID']
          )[0];
          const slipEntryRecord = {};
          slipEntryRecord['BillingHeaderID'] = billingHeaderId;

          slipEntryRecord['Ddate'] = formObj.InvoiceDate;

          slipEntryRecord['InvoiceDate'] = moment(new Date(formObj.InvoiceDate))
            .format('YYYY-MM-DD')
            .toString();

          slipEntryRecord['CompanyID'] = this.loginCompanyId;
          slipEntryRecord['ClientID'] = formObj.ClientID;
          slipEntryRecord['InvoiceNumber'] = billingHeader['InvoiceNumber'];
          slipEntryRecord['ServiceCodeID'] = null;
          slipEntryRecord['Memo'] = lineItem ? lineItem['description'] : null;
          slipEntryRecord['Description'] = lineItem ? lineItem['description'] : null;
          slipEntryRecord['WorkCodeID'] = null;
          slipEntryRecord['StaffID'] = lineItem['StaffID'];
          slipEntryRecord['WriteUpDown'] = parentRow.writeupValue;
          slipEntryRecord['BillingRate'] = 0;
          slipEntryRecord['ElaspedTime'] = 0;
          slipEntryRecord['TotalTime'] = 0;
          slipEntryRecord['StaffPayRate'] = 0;
          slipEntryRecord['BilledAmount'] = parentRow.invoiceAmount;
          slipEntryRecord['SelectedAmount'] = parentRow.invoiceAmount;
          slipEntryRecord['StandardAmount'] = 0;
          slipEntryRecord['Approved'] =
            slipEntryRecord['Billable'] =
            slipEntryRecord['Billed'] =
            slipEntryRecord['Finished'] =
              true;
          slipEntryRecord['WriteOff'] = false;
          slipEntryRecord['OriginatingPartnerID'] = this.clientProfile['OriginatingPartnerID'];
          slipEntryRecord['BillingPartnerID'] = this.clientProfile['BillingPartnerID'];
          slipEntryRecord['GroupDescriptionID'] = this.clientProfile['BillingGroupID'];
          slipEntryRecord['GroupDescriptionIDArray'] =
            this.clientProfile['GroupDescriptionIDArray'];
          slipEntryRecord['ClientTypeID'] = this.clientProfile['ClientTypeID'];
          slipEntryRecord['ProjectMasterID'] = lineItem ? lineItem['ProjectMasterID'] : null;
          slipEntryRecord['EngagementTypeID'] = lineItem ? lineItem['EngagementTypeID'] : null;
          slipEntryRecord['IsTimeRecord'] = 'T';

          // Need to get the Staff information from the Service Line Items table
          if (lineItem['PercentBit'] == true) {
            slipEntryRecord['StaffCost'] =
              (slipEntryRecord['BilledAmount'] * lineItem['PercentBilling']) / 100;
            // This value is needed for the BillingHeader record
            _TotalStaffCost = slipEntryRecord['StaffCost'];
          }
          slipEntryRecord['StaffTitleID'] = lineItem['StaffTitleID'];
          slipEntryRecord['StaffDeptID'] = lineItem['StaffDeptID'];
          createtimeSlipsDS.push(slipEntryRecord);
        } else {
          const billableTimeSelected = numeral(parentRow['laboramount']).value();
          const totalNonBillableAmount = numeral(parentRow['nonbillableamount']).value();
          const eachSelectedItem = parentRow.allSelectedTimeSlips;
          // Level 2 : Time records
          if (eachSelectedItem.length > 0) {
            for (
              let timeSlipsIndex = 0;
              timeSlipsIndex < eachSelectedItem.length;
              ++timeSlipsIndex
            ) {
              const eachTimeSlip = JSON.parse(JSON.stringify(eachSelectedItem[timeSlipsIndex]));

              if (eachTimeSlip['StaffCost']) {
                _TotalStaffCost += numeral(eachTimeSlip['StaffCost']).value();
              }
              eachTimeSlip['BillingHeaderID'] = billingHeaderId;
              eachTimeSlip['Billed'] = true;

              eachTimeSlip['InvoiceDate'] = moment(new Date(formObj.InvoiceDate))
                .format('YYYY-MM-DD')
                .toString();

              eachTimeSlip['InvoiceNumber'] = billingHeader['InvoiceNumber'];
              eachTimeSlip['SelectedAmount'] = eachTimeSlip['BilledAmount'];
              eachTimeSlip['BillingRate'] = eachTimeSlip['BillingRate']
                ? eachTimeSlip['BillingRate'].toString()
                : '0';
              eachTimeSlip['StandardAmount'] = eachTimeSlip['StandardAmount']
                ? eachTimeSlip['StandardAmount'].toString()
                : '0';

              if (isPreviewMode) {
                createtimeSlipsDS.push(eachTimeSlip);
              } else {
                timeSlipsDS.push(eachTimeSlip);
              }
            }
          } else {
            // No Selected Time Records - Create 1 time record when no time records available.
            const eachTimeSlip = {};
            const lineItem = this.lineItems.filter(
              element => element['ProjectMasterID'] == parentRow['ProjectMasterID']
            )[0];
            eachTimeSlip['BillingHeaderID'] = billingHeaderId;

            eachTimeSlip['Ddate'] = formObj.InvoiceDate;
            eachTimeSlip['InvoiceDate'] = moment(new Date(formObj.InvoiceDate))
              .format('YYYY-MM-DD')
              .toString();

            eachTimeSlip['CompanyID'] = this.loginCompanyId;
            eachTimeSlip['ClientID'] = formObj.ClientID;
            eachTimeSlip['InvoiceNumber'] = billingHeader['InvoiceNumber'];
            eachTimeSlip['ServiceCodeID'] = null;
            eachTimeSlip['Memo'] = lineItem ? lineItem['description'] : null;
            eachTimeSlip['Description'] = lineItem ? lineItem['description'] : null;
            eachTimeSlip['WorkCodeID'] = null;
            eachTimeSlip['StaffID'] = lineItem['StaffID'];
            eachTimeSlip['WriteUpDown'] = parentRow.writeupValue;
            eachTimeSlip['BillingRate'] = 0;
            eachTimeSlip['ElaspedTime'] = 0;
            eachTimeSlip['TotalTime'] = 0;
            eachTimeSlip['StaffPayRate'] = 0;
            eachTimeSlip['BilledAmount'] = parentRow.invoiceAmount;
            eachTimeSlip['SelectedAmount'] = parentRow.invoiceAmount;
            eachTimeSlip['StandardAmount'] = 0;
            eachTimeSlip['Approved'] =
              eachTimeSlip['Billable'] =
              eachTimeSlip['Billed'] =
              eachTimeSlip['Finished'] =
                true;
            eachTimeSlip['WriteOff'] = false;
            eachTimeSlip['OriginatingPartnerID'] = this.clientProfile['OriginatingPartnerID'];
            eachTimeSlip['BillingPartnerID'] = this.clientProfile['BillingPartnerID'];
            eachTimeSlip['GroupDescriptionID'] = this.clientProfile['BillingGroupID'];
            eachTimeSlip['GroupDescriptionIDArray'] = this.clientProfile['GroupDescriptionIDArray'];
            eachTimeSlip['ClientTypeID'] = this.clientProfile['ClientTypeID'];
            eachTimeSlip['ProjectMasterID'] = lineItem ? lineItem['ProjectMasterID'] : null;
            eachTimeSlip['EngagementTypeID'] = lineItem ? lineItem['EngagementTypeID'] : null;
            eachTimeSlip['IsTimeRecord'] = 'T';

            // Need to get the Staff information from the Service Line Items table
            if (lineItem['PercentBit'] == true) {
              eachTimeSlip['StaffCost'] =
                (eachTimeSlip['BilledAmount'] * lineItem['PercentBilling']) / 100;
              // This value is needed for the BillingHeader record
              _TotalStaffCost = eachTimeSlip['StaffCost'];
            }
            eachTimeSlip['StaffTitleID'] = lineItem['StaffTitleID'];
            eachTimeSlip['StaffDeptID'] = lineItem['StaffDeptID'];
            createtimeSlipsDS.push(eachTimeSlip);
          }
        }

        // Level 2 : Expense records
        const allSelectedExpenseContainer = [];
        for (let i = 0; i < parentRow.allSelectedExpenseSlips.length; i++) {
          const isSelected = parentRow.allExpenseSlips.filter(
            itm => itm.SlipMasterID === parentRow.allSelectedExpenseSlips[i].SlipMasterID
          );
          if (isSelected.length > 0) allSelectedExpenseContainer.push(isSelected[0]);
        }

        const eachSelectedExpenseItem = allSelectedExpenseContainer;
        if (eachSelectedExpenseItem.length > 0) {
          for (
            let expenseIndex = 0;
            expenseIndex < eachSelectedExpenseItem.length;
            ++expenseIndex
          ) {
            const eachExpense = JSON.parse(JSON.stringify(eachSelectedExpenseItem[expenseIndex]));
            //var detailObj = {};
            eachExpense['BillingHeaderID'] = billingHeaderId;

            eachExpense['InvoiceDate'] = moment(new Date(formObj.InvoiceDate))
              .format('YYYY-MM-DD')
              .toString();

            eachExpense['InvoiceNumber'] = billingHeader['InvoiceNumber'];
            eachExpense['StandardAmount'] = eachExpense['StandardAmount']
              ? eachExpense['StandardAmount'].toString()
              : '0';
            eachExpense['BillingRate'] = eachExpense['BillingRate']
              ? eachExpense['BillingRate'].toString()
              : '0';
            //eachExpense["BilledAmount"] = eachExpense["StandardAmount"];
            eachExpense['BilledAmount'] = eachExpense['BilledAmount'] || 0;
            eachExpense['WriteUpDown'] =
              eachExpense['BilledAmount'] - eachExpense['StandardAmount'];
            eachExpense['Billed'] = true;
            eachExpense['IsPrintExpense'] = !parentRow.IsExpenseRollUp;
            //eachTimeSlip['SlipMasterID'] = eachTimeSlip['SlipMasterID'];
            datasetList.push(eachExpense);
          }
        }
      }

      if (formObj.invoiceTemplate === '0' && this.selectedAdvance) {
        this.oldBillingHeader = billingHeader;
        this.oldSelectedItems = this.selectedItems;
        this.oldLineItems = this.lineItems;
        this.oldInvoiceLessPayments = this.invoiceLessPayments;
      }
      // update Time Records from Billable Time table - one shot in DB
      this.updateTimeSlipsRecords(timeSlipsDS).subscribe(data => {
        billingHeader['TotalStaffCost'] = _TotalStaffCost;
        //billingHeader['LastModifiedStaffID'] = this.encrDecSrvc.getObject(AppConstants.staffID);
        //billingHeader['isUpdatingStaffAndDate'] = false; //set false if invoice is newly created
        parent.mangoAPISrvc
          .updateBillingHeader(billingHeader, billingHeader['BillingHeaderID'])
          .subscribe(rateData => {
            // this.notificationsService.notify('success', this.translate.instant(rateData.message));
            setTimeout(() => {
              parent.clearData();
            }, 1500);
          });
      });
      if (isPreviewMode) {
        createtimeSlipsDS = createtimeSlipsDS.concat(datasetList);
        datasetList = [];
      }
      // update Expense records
      this.updateExpenseMasterRecords(datasetList).subscribe(data => {});
      // Create TimeSlip records from the Service Line Items - one shot in DB
      this.createTimeSlipsRecords(createtimeSlipsDS).subscribe(data => {
        if (isPreviewMode) {
          this.newPreviewInvoices(billingHeader);
        }
        setTimeout(() => {
          this.clearData();
        }, 500);
      });
    }
  }

  createTimeSlipsRecords(selectedItemList) {
    const observableBatch = [];
    const parent = this;
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Authorization', parent.encrDecSrvc.getObject('token'));
    selectedItemList.forEach((selectedItem, key) => {
      const urlOne = parent.http.post(`${environment.API_URL}/company/timeSheet`, selectedItem, {
        headers: headers,
        withCredentials: true
      });
      observableBatch.push(urlOne);
    });
    return forkJoin(observableBatch);
  }

  updateTimeSlipsRecords(selectedItemList) {
    const observableBatch = [];
    const parent = this;
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Authorization', parent.encrDecSrvc.getObject('token'));

    selectedItemList.forEach((selectedItem, key) => {
      const urlOne = parent.http.put(
        `${environment.API_URL}/company/timeSheet/${selectedItem['SlipMasterID']}`,
        selectedItem,
        {
          headers: headers,
          withCredentials: true
        }
      );
      observableBatch.push(urlOne);
    });
    return forkJoin(observableBatch);
  }

  processSelectedRetainerPayments(billingheaderId, headerObj) {
    if (this.isPreviewMode) return;
    const parent = this;
    const formObj = this.manualInvoiceForm.value;
    const selectedItem = this.openRetainersDataSource.filter(item => item['selected'] == true);

    for (let i = 0; i < selectedItem.length; ++i) {
      const payment = selectedItem[i];
      var obj = {};
      obj['CompanyID'] = this.loginCompanyId;
      obj['PaymentHeaderID'] = payment['PaymentHeaderID'];
      obj['BillingHeaderID'] = billingheaderId;
      obj['PaymentDate'] = payment['PaymentDate'];
      obj['CheckRef'] = payment['CheckRef'];
      obj['AppliedAmount'] = numeral(payment['AppliedAmount']).value()
        ? numeral(payment['AppliedAmount']).value()
        : 0;
      obj['WriteOffAmount'] = 0;
      obj['PaymentType'] = payment['PaymentType'];
      obj['InvoiceNumber'] = headerObj['InvoiceNumber'];

      obj['InvoiceDate'] = moment(new Date(formObj.InvoiceDate)).format('YYYY-MM-DD').toString();

      (obj['PaymentNote'] = headerObj['DescriptionShort']), (obj['ClientID'] = formObj.ClientID);

      // creating new record in paymentDetail table
      parent.mangoAPISrvc.showLoader(true);
      parent.mangoAPISrvc.createPaymentDetail(obj).subscribe(rateData => {
        // this.notificationsService.notify('success', this.translate.instant(rateData.message));
        //this.createBillingDetailRecord(billingheaderId);
        // we are updating the payment header record
        payment['AmountPreviouslyAppliedRetainer'] = payment['AmountPreviouslyAppliedRetainer']
          ? numeral(payment['AmountPreviouslyAppliedRetainer']).value()
          : 0;
        payment['AmountPreviouslyAppliedRetainer'] += obj['AppliedAmount'];
        payment['AppliedAmount'] = numeral(payment['AppliedAmount']).value()
          ? numeral(payment['AppliedAmount']).value()
          : 0;
        payment['PaymentAmount'] = numeral(payment['PaymentAmount']).value()
          ? numeral(payment['PaymentAmount']).value()
          : 0;
        payment['PaymentUnapplied'] = numeral(payment['PaymentUnapplied']).value()
          ? numeral(payment['PaymentUnapplied']).value()
          : 0;

        //payment['LastModifiedStaffID'] = this.encrDecSrvc.getObject(AppConstants.staffID);

        parent.mangoAPISrvc
          .updatePaymentHeader(payment, payment['PaymentHeaderID'])
          .subscribe(rateData => {
            if (payment.BillingHeaderID) {
              this.mangoAPISrvc
                .getBillingHeaderById(payment.BillingHeaderID)
                .subscribe((advPaymentData: any) => {
                  if (payment.PaymentUnapplied != 0) {
                    advPaymentData.InvoiceAmount =
                      numeral(payment['PaymentUnapplied']).value() * -1;
                    advPaymentData.InvoiceBalance = numeral(advPaymentData.InvoiceAmount).value();

                    this.mangoAPISrvc
                      .updateBillingHeader(advPaymentData, advPaymentData.BillingHeaderID)
                      .subscribe(
                        data => {},
                        error => {
                          this.mangoAPISrvc.notify('error', 'Error!', AppConstants.updateErrorMsg);
                          this.mangoAPISrvc.showLoader(false);
                        }
                      );
                  } else {
                    this.mangoAPISrvc.deleteBillingHeader(payment.BillingHeaderID).subscribe(
                      data => {},
                      error => {
                        this.mangoAPISrvc.notify('error', 'Error!', AppConstants.deleteErrorMsg);
                        this.mangoAPISrvc.showLoader(false);
                      }
                    );
                  }
                });
            } else {
              const paymentHeaderDetails = this.bhPaymentDetailsArr.filter(
                item => item.PaymentHeaderID == payment.PaymentHeaderID
              );
              if (paymentHeaderDetails?.length > 0) {
                let totalUnapplied = payment['AppliedAmount'];

                paymentHeaderDetails.forEach(detail => {
                  detail['amount'] = 0;
                  totalUnapplied = this.mangoUtils.roundOffDecimals(totalUnapplied);
                  if (totalUnapplied > 0) {
                    // this.mangoAPISrvc.getBillingHeaderById(detail.BillingHeaderID).subscribe((advPaymentData: any) => {
                    const advBalance = Math.abs(numeral(detail.bhInvoiceBalance).value());
                    if (totalUnapplied > advBalance) {
                      detail['amount'] = 0;
                      totalUnapplied = this.mangoUtils.subtractFloat(totalUnapplied, advBalance);
                    } else {
                      detail['amount'] = this.mangoUtils.subtractFloat(advBalance, totalUnapplied);
                      totalUnapplied = 0;
                    }

                    this.mangoAPISrvc.getBillingHeaderById(detail.BillingHeaderID).subscribe(
                      (advPaymentData: any) => {
                        advPaymentData.InvoiceAmount = Math.abs(detail['amount']) * -1;
                        advPaymentData.InvoiceBalance = numeral(
                          advPaymentData.InvoiceAmount
                        ).value();

                        if (advPaymentData.InvoiceBalance == 0) {
                          //delete payment detail
                          this.mangoAPISrvc.deletePaymentDetails(detail.PaymentDetailID).subscribe(
                            res => {
                              this.mangoAPISrvc
                                .deleteBillingHeader(advPaymentData.BillingHeaderID)
                                .subscribe(
                                  res => {},
                                  error => {
                                    this.mangoAPISrvc.notify(
                                      'error',
                                      'Error!',
                                      AppConstants.deleteErrorMsg
                                    );
                                    this.mangoAPISrvc.showLoader(false);
                                  }
                                );
                            },
                            error => {
                              this.mangoAPISrvc.notify(
                                'error',
                                'Error!',
                                AppConstants.deleteErrorMsg
                              );
                              this.mangoAPISrvc.showLoader(false);
                            }
                          );
                        } else {
                          const paymentDetailObj = {
                            AppliedAmount: Math.abs(advPaymentData.InvoiceBalance),
                            PaymentAmount: Math.abs(advPaymentData.InvoiceBalance)
                          };

                          this.mangoAPISrvc
                            .updatePaymentDetail(paymentDetailObj, detail.PaymentDetailID)
                            .subscribe(
                              res => {
                                this.mangoAPISrvc
                                  .updateBillingHeader(
                                    advPaymentData,
                                    advPaymentData.BillingHeaderID
                                  )
                                  .subscribe(
                                    data => {},
                                    error => {
                                      this.mangoAPISrvc.notify(
                                        'error',
                                        'Error!',
                                        AppConstants.updateErrorMsg
                                      );
                                      this.mangoAPISrvc.showLoader(false);
                                    }
                                  );
                              },
                              error => {
                                this.mangoAPISrvc.notify(
                                  'error',
                                  'Error!',
                                  AppConstants.updateErrorMsg
                                );
                                this.mangoAPISrvc.showLoader(false);
                              }
                            );
                        }
                      },
                      error => {
                        this.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
                        this.mangoAPISrvc.showLoader(false);
                      }
                    );
                  }
                });
              }
            }
            parent.mangoAPISrvc.showLoader(false);
          });
      });
    }
    //this.clearData();
  }

  /*

  */
  fetchBillingRecordById(billingHeaderId, itemAppliedValue) {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc
      .getBillingHeaderRecordById(billingHeaderId)
      .subscribe((billingHeader: any) => {
        billingHeader['InvoiceBalance'] = numeral(billingHeader['InvoiceBalance']).value()
          ? numeral(billingHeader['InvoiceBalance']).value()
          : 0;
        billingHeader['InvoiceAmount'] = numeral(billingHeader['InvoiceAmount']).value()
          ? numeral(billingHeader['InvoiceAmount']).value()
          : 0;
        billingHeader['PaymentsApplied'] = numeral(billingHeader['PaymentsApplied']).value()
          ? numeral(billingHeader['PaymentsApplied']).value()
          : 0;

        billingHeader['InvoiceBalance'] += itemAppliedValue;
        billingHeader['InvoiceAmount'] += itemAppliedValue;
        billingHeader['PaymentsApplied'] += itemAppliedValue;
        billingHeader['LastModifiedStaffID'] = this.encrDecSrvc.getObject(AppConstants.staffID);
        if (billingHeader['InvoiceBalance'] == 0) {
          parent.mangoAPISrvc
            .deleteBillingHeader(billingHeader['BillingHeaderID'])
            .subscribe(rateData => {
              parent.mangoAPISrvc.showLoader(false);
            });
        } else {
          parent.mangoAPISrvc
            .updateBillingHeader(billingHeader, billingHeader['BillingHeaderID'])
            .subscribe(rateData => {
              parent.mangoAPISrvc.showLoader(false);
            });
        }
      });
  }

  /*
      fetching the client full information

  */
  fetchClientInformation(id) {
    const parent = this;
    //parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getClientFullinformation(id).subscribe((clientdata: any) => {
      parent.clientProfile = clientdata;
      parent.clientProfile.Mobile = parent.clientProfile.Mobile
        ? parent.clientProfile.Mobile.replace(/[{(___)-}]/g, '').trim()
        : '';
      parent.loadCompanyInfo(clientdata);
      parent.loadACHData(clientdata);
      parent.loginSerialNumber = '';
      // only Laser Software
      if (parent.loginCompanyId == 1) {
        let serialStr = '';
        if (clientdata.BusStreet1) {
          serialStr = clientdata.ClientName.substring(0, 4) + clientdata.BusStreet1.substring(0, 4);
        } else {
          serialStr = clientdata.ClientName.substring(0, 4);
        }
        for (let i = 0; i < serialStr.length; i++) {
          parent.loginSerialNumber += parent.getSerialNumberByChar(serialStr[i]);
        }
      }
      parent.setLaborRates();

      //parent.mangoAPISrvc.showLoader(false);
    });
  }

  setLaborRates() {
    const parent = this;
    if (
      (parent.mangoCompanyData.ActivateLaborRates == true ||
        parent.mangoCompanyData.ActivateExpenseRates == true) &&
      parent.clientProfile.SalesTaxLevel != 'None'
    ) {
      if (parent.clientProfile.SalesTaxLevel == 'ClientRate') {
        parent.salesTax.Labor = parent.clientProfile.Tax1ID
          ? numeral(parent.clientProfile.Tax1ID).value()
          : 0;
        parent.salesTax.Expense = parent.clientProfile.Tax2ID
          ? numeral(parent.clientProfile.Tax2ID).value()
          : 0;
      } else if (parent.clientProfile.SalesTaxLevel == 'CompanyLocationRate') {
        const selectedCompanyLocation = this.companyLocations.filter(
          location => location['value'] == parent.clientProfile.CompanyMangoLocationID
        )[0];
        parent.salesTax.Labor = selectedCompanyLocation['laborRate']
          ? numeral(selectedCompanyLocation['laborRate']).value()
          : 0;
        parent.salesTax.Expense = selectedCompanyLocation['expenseRate']
          ? numeral(selectedCompanyLocation['expenseRate']).value()
          : 0;
      } else {
        parent.salesTax.Labor = parent.mangoCompanyData.LaborRate1
          ? numeral(parent.mangoCompanyData.LaborRate1).value()
          : 0;
        parent.salesTax.Expense = parent.mangoCompanyData.ExpenseRate1
          ? numeral(parent.mangoCompanyData.ExpenseRate1).value()
          : 0;
      }
    }
  }

  refreshTimeSlips(obj, type = null, uuid = null) {
    const parent = this;
    parent.projectMastersArray = [];
    const formObj = parent.manualInvoiceForm.value;
    formObj['InvoiceStartDate'] = moment(formObj['InvoiceStartDate']).format('MM-DD-YYYY');
    parent.mangoAPISrvc.showLoader(true);
    parent.projectMastersArray = parent.lineItems.map(item => {
      return item['ProjectMasterID'] ? item['ProjectMasterID'] : -1;
    });
    parent.selectedProjectMasterIds = parent.projectMastersArray.toString();
    parent.timeExpenseRecords = [];
    if (parent.selectedProjectMasterIds == '') {
      return false;
    }
    formObj.staffId = formObj.staffId ? formObj.staffId : null;
    parent.mangoAPISrvc
      .getSlipTotalsByProjectMasterId(
        parent.selectedProjectMasterIds,
        formObj.staffId,
        formObj.InvoiceStartDate
      )
      .subscribe((totals: any) => {
        // check empty data
        if (totals.length != parent.projectMastersArray.length) {
          for (var i = 0; i < parent.projectMastersArray.length; ++i) {
            const temp = totals.filter(
              item => item['ProjectMasterID'] == parent.projectMastersArray[i]
            );
            if (temp && temp.length == 0) {
              parent.defaultParentRecord = {
                hasChildrens: false,
                ProjectMasterID: -1,
                EngagementName: null,
                expenseamount: '0',
                totaltime: '0',
                laboramount: '0',
                nonbillableamount: 0,
                writeupValue: 0,
                invoiceAmount: 0
              };
              const projectsDropDownList = parent.filteredEngagementsList.filter(
                item => item['value'] == parent.projectMastersArray[i]
              );
              parent.defaultParentRecord['ProjectMasterID'] = projectsDropDownList[0]['value'];
              parent.defaultParentRecord['EngagementName'] = projectsDropDownList[0]['label'];
              totals.push(parent.defaultParentRecord);
            }
          }
        }
        totals = totals.map(function (obj) {
          obj['SelectForBilling'] = true;
          obj['writeupValue'] = 0.0;
          obj['invoiceAmount'] = 0.0;
          obj['IsExpenseRollUp'] = parent.mangoCompanyData['AutoRollupExpenses'];
          obj['laboramount'] = obj['laboramount'] ? numeral(obj['laboramount']).value() : 0.0;
          obj['nonbillableamount'] = obj['nonbillableamount']
            ? numeral(obj['nonbillableamount']).value()
            : 0.0;
          obj['expenseamount'] = obj['expenseamount'] ? numeral(obj['expenseamount']).value() : 0.0;
          obj['totaltime'] = obj['totaltime'] ? numeral(obj['totaltime']).value() : 0;
          obj['hasChildrens'] = false;
          obj['isSelected'] = true;
          return obj;
        });

        totals.map(item => {
          parent.updateExpenseRollUp(
            { checked: parent.mangoCompanyData['AutoRollupExpenses'] },
            item
          );
        });

        parent.timeExpenseRecords = [...totals];
        parent.selectedItems = [...totals];
        if (obj['BillingMethod'] == 'Hourly') {
          obj['amount'] = '$' + numeral(totals[0]['laboramount']).format('0,0.00');
          obj['PreviousAmount'] = obj['amount'];
          parent.validateLineItems();
        }

        const ids = ['0', '6', '7', '11'];
        let noTimeRecords = false;

        parent.mangoAPISrvc
          .getSlipsByProjectMasterId(
            parent.selectedProjectMasterIds,
            formObj.staffId,
            formObj.InvoiceStartDate
          )
          .subscribe((slipsrecords: any) => {
            for (let i = 0; i < parent.timeExpenseRecords.length; i++) {
              let allRecords = slipsrecords[parent.timeExpenseRecords[i].ProjectMasterID]
                ? slipsrecords[parent.timeExpenseRecords[i].ProjectMasterID]
                : [];
              allRecords = allRecords.map(function (obj) {
                obj['SelectForBilling'] = true;
                obj['displayDate'] = moment(obj.Ddate.substr(0, 10)).format('MM/DD/YYYY');
                obj['scdescr'] = obj?.Description;
                return obj;
              });

              parent.timeExpenseRecords[i]['allTimeSlips'] = allRecords.filter(
                item => item['IsTimeRecord'] == 'T'
              );
              parent.timeExpenseRecords[i]['allSelectedTimeSlips'] = [
                ...allRecords.filter(item => item['IsTimeRecord'] == 'T')
              ];
              parent.timeExpenseRecords[i]['allExpenseSlips'] = allRecords.filter(
                item => item['IsTimeRecord'] == 'X'
              );

              parent.timeExpenseRecords[i]['allSelectedExpenseSlips'] = [
                ...allRecords.filter(item => item['IsTimeRecord'] == 'X')
              ];

              parent.timeExpenseRecords[i]['grandExpenseTotalAmt'] = parent.timeExpenseRecords[i][
                'allExpenseSlips'
              ].reduce(function (a, b) {
                return a + +numeral(b['StandardAmount']).value();
              }, 0);

              parent.timeExpenseRecords[i]['grandExpenseHrs'] = parent.timeExpenseRecords[i][
                'allTimeSlips'
              ].reduce(function (a, b) {
                return a + +numeral(b['TotalTime']).value();
              }, 0);
              parent.timeExpenseRecords[i]['grandBillableamount'] = parent.timeExpenseRecords[i][
                'allTimeSlips'
              ].reduce(function (a, b) {
                return a + +numeral(b['billableamount']).value();
              }, 0);
              parent.timeExpenseRecords[i]['grandNonbillableamount'] = parent.timeExpenseRecords[i][
                'allTimeSlips'
              ].reduce(function (a, b) {
                return a + +numeral(b['nonbillableamount']).value();
              }, 0);
              parent.timeExpenseRecords[i]['grandExpenseWUWDamount'] = parent.timeExpenseRecords[i][
                'allExpenseSlips'
              ].reduce(function (a, b) {
                return a + +numeral(b['WriteUpDown']).value();
              }, 0);

              parent.timeExpenseRecords[i]['grandWUWDamount'] = parent.timeExpenseRecords[i][
                'allTimeSlips'
              ].reduce(function (a, b) {
                return a + +numeral(b['WriteUpDown']).value();
              }, 0);

              parent.timeExpenseRecords[i]['grandBilledamount'] = parent.timeExpenseRecords[i][
                'allTimeSlips'
              ].reduce(function (a, b) {
                return a + +numeral(b['BilledAmount']).value();
              }, 0);

              if (
                parent.timeExpenseRecords[i]['allSelectedTimeSlips'].length == 0 &&
                parent.timeExpenseRecords[i]['allSelectedExpenseSlips'].length == 0
              ) {
                const value = this.manualInvoiceForm.get('invoiceTemplate').value;
                if (ids.includes(value)) {
                  noTimeRecords = true;
                }
              }

              if (
                parent.timeExpenseRecords[i]['allTimeSlips'].length > 0 ||
                parent.timeExpenseRecords[i]['allExpenseSlips'].length > 0
              ) {
                parent.timeExpenseRecords[i]['hasChildrens'] = true;
              }
              parent.mangoAPISrvc.showLoader(false);

              if (uuid && type === 'EngagementName') {
                document.getElementById(`invoice_desc_column_${uuid}`).click();
              }
            }
            parent.noTimeRecordsWarning = noTimeRecords;
            if (noTimeRecords) {
              Swal.fire({
                icon: 'warning',
                title: this.translate.instant('Warning'),
                text: this.translate.instant('no_time_records_warning'),
                showConfirmButton: true
              });
            }
            parent.calculateAllWUWDTotals();
          });
      });
  }

  handleDropStaffSelectClick(event, data, formtype) {
    if (formtype == 'Staff') {
      data['StaffName'] = event.label;
      data['StaffID'] = event.value;
    } else if (formtype == 'Activity') {
      data['scdescr'] = event.originalEvent.target.textContent;
      data['ServiceCodeID'] = event.value;
    } else if (formtype == 'Expense') {
      data['ExpenseCode'] = event.originalEvent.target.textContent;
      data['ExpenseCodeID'] = event.value;
    }

    // data["IsColumnChanges"] = true;

    this.childRow = true;
  }

  calculateStandardAmount(data) {
    if (data['BillingRate']) {
      if (data['Billable']) {
        data['billableamount'] = (
          parseFloat(data['TotalTime']) * parseFloat(data['BillingRate'])
        ).toString();
        data['StandardAmount'] = data['billableamount'];
      } else {
        data['nonbillableamount'] = (
          parseFloat(data['TotalTime']) * parseFloat(data['BillingRate'])
        ).toString();
        data['StandardAmount'] = data['nonbillableamount'];
      }
    }

    return data;
  }

  onSelectDate(event: any, formControlName) {
    if (formControlName == 'InvoiceDate') {
      this.encrDecSrvc.addObject(
        AppConstants.manualLastInvoiceDate,
        this.manualInvoiceForm.controls[formControlName].value
      );
    } else {
      this.encrDecSrvc.addObject(
        AppConstants.manualLastDateThru,
        this.manualInvoiceForm.controls[formControlName].value
      );
    }
  }

  handleDropSelectClick(event: any, data: any, type: any) {
    const parent = this;
    data['description'] = '';
    data['amount'] = '$0.00';
    data['discount'] = '$0.00';
    data['PreviousAmount'] = data['amount'];
    if (type == 'EngagementName') {
      if (!event.value) {
        data['EngagementName'] = '';
        data['ProjectMasterID'] = null;
        data['EngagementTypeID'] = '';
        data['description'] = '';
        return false;
      }
      const selectedProjectMasterID = this.activitiesTypes.filter(item => {
        return item.value == event.value;
      });

      if (selectedProjectMasterID.length == 0) {
        return false;
      }
      if (selectedProjectMasterID.length > 0 && selectedProjectMasterID[0].value == null) {
        return false;
      }
      data['EngagementName'] = selectedProjectMasterID[0].label;
      data['ProjectMasterID'] = selectedProjectMasterID[0].value;
      data['EngagementTypeID'] = selectedProjectMasterID[0].EngagementTypeID;
      data['description'] = selectedProjectMasterID[0].Description;
      data['description'] = data['description']
        ? this.mangoUtils.replaceCaretTemplate(data['description'])
        : '';
      let appendedDesc = this.manualInvoiceForm.controls['DescriptionShort'].value;
      appendedDesc = appendedDesc ? appendedDesc : '';

      if (data['description']) {
        if (appendedDesc) {
          appendedDesc += ' | ' + data['description'];
        } else {
          appendedDesc = data['description'];
        }
        this.manualInvoiceForm.controls['DescriptionShort'].setValue(
          this.mangoUtils.replaceCaretTemplate(appendedDesc)
        );
      }

      //data['description'] = event.Description;
      data['BillingMethod'] = selectedProjectMasterID[0]['BillingMethod'];
      if (selectedProjectMasterID[0]['BillingMethod'] == 'Fixed Fee') {
        data['amount'] =
          '$' + numeral(selectedProjectMasterID[0]['FlatFeeAmount']).format('0,0.00');
        data['PreviousAmount'] = data['amount'];
      }
      this.refreshTimeSlips(data, type, data.uuid);
    } else {
      data['StaffName'] = this.AllStaffsTypes.filter(item => item['value'] == event.value)[0][
        'label'
      ];
      data['StaffID'] = event.value;
      this.getStaffInfo(data, type, data.uuid);
    }

    this.validateLineItems();
    this.replaceShortcuts();
  }

  getStaffInfo(lineObj, type = null, uuid = null) {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getStaffInfo(lineObj['StaffID']).subscribe(data => {
      lineObj['StaffTitleID'] = data['StaffTitleID'];
      lineObj['StaffDeptID'] = data['StaffDeptID'];
      lineObj['PercentBilling'] = data['PercentBilling'];
      lineObj['PercentBit'] = data['PercentBit'];
      parent.mangoAPISrvc.showLoader(false);

      if (uuid && type) {
        switch (type) {
          case 'NewRow':
            document.getElementById(`engagement_column_${uuid}`).click();
            break;
          case 'EngagementName':
            document.getElementById(`invoice_desc_column_${uuid}`).click();
            break;
          default:
            break;
        }
      }
    }),
      error => {
        parent.mangoAPISrvc.notify(
          'error',
          this.translate.instant('error'),
          AppConstants.fetchErrorMsg
        );
        parent.mangoAPISrvc.showLoader(false);
      };
  }

  addRow() {
    if (!this.validateLineItems() && this.lineItems.length > 0) {
      return;
    }
    const lineObj = {};
    lineObj['uuid'] = this.mangoUtils.generateUUID();
    lineObj['StaffID'] = this.resourceId;
    lineObj['EngagementName'] = null;
    lineObj['ProjectMasterID'] = null;
    lineObj['EngagementTypeID'] = null;
    lineObj['description'] = '';
    lineObj['ShowDetail'] = false;

    const label = this.AllStaffsTypes.filter(item => item['value'] == lineObj['StaffID']);
    if (label.length > 0) {
      lineObj['StaffName'] = label[0]['label'];
    } else {
      lineObj['StaffName'] = '';
    }
    lineObj['amount'] = '$0.00';
    lineObj['discount'] = '$0.00';
    lineObj['PreviousAmount'] = lineObj['amount'];
    lineObj['invoiceTax'] = 0;
    lineObj['companytaxRate'] = 0;
    lineObj['StaffTitleID'] = 0;
    lineObj['StaffDeptID'] = 0;
    lineObj['PercentBilling'] = 0;
    lineObj['PercentBit'] = false;
    lineObj['isBillingHistoryPresents'] = false;
    lineObj['ActivityBillingHistoryID'] = '';
    lineObj['isNewRow'] = true;

    this.lineItems.push(lineObj);
    if (this.activitiesTypes.length == 1) {
      this.handleDropSelectClick(
        { value: this.activitiesTypes[0].value },
        lineObj,
        'EngagementName'
      );
    }

    this.getStaffInfo(lineObj, 'NewRow', lineObj['uuid']);
    this.validateLineItems();
    this.reOrderLineItems();
  }

  /*
    Checking all items filled in Line item

  */
  validateLineItems() {
    this.isLineItemsValid = true;
    let validData = true;
    if (this.IsLinesItemsRequired) {
      for (let i = 0; i < this.lineItems.length; ++i) {
        const obj = this.lineItems[i];
        if (obj['ProjectMasterID'] == null || numeral(obj['amount']).value() == null) {
          this.isLineItemsValid = false;
          validData = false;
          break;
        }
      }
    }
    this.validateForm();
    this.reOrderLineItems();
    return validData;
  }

  loadCompanyInfo(data) {
    const self = this;
    let lastName = '';
    let firstName = '';
    if (data['ContactPerson']) {
      const fullName = data['ContactPerson'].split(' ');
      firstName = fullName[0];
      if (fullName[1]) {
        lastName = fullName[1];
      }
    }
    self.companyData = {
      firstName: firstName,
      lastName: lastName,
      address: data['BusStreet1'],
      city: data['BusCity'],
      state: data['BusState'],
      zip: data['BusZip'],
      country: data['Country'],
      phoneNum: data['Office'],
      email: data['Email'],
      company: data['ClientName']
    };
  }
  /* deleting row */
  deleteRow(itemData: any, isCheckedFromTimeSlips) {
    //event.stopPropagation();
    if (isCheckedFromTimeSlips) {
      const index = this.lineItems.findIndex(item => item.uuid == itemData.uuid);
      const selectedItemIndexToRemove = this.selectedItems.findIndex(
        item => item.ProjectMasterID == this.lineItems[index].ProjectMasterID
      );
      const timeExpenseIndexToRemove = this.timeExpenseRecords.findIndex(
        item => item.ProjectMasterID == this.lineItems[index].ProjectMasterID
      );
      this.lineItems.splice(index, 1);
      if (timeExpenseIndexToRemove > -1) {
        this.timeExpenseRecords.splice(timeExpenseIndexToRemove, 1);
      }
      if (selectedItemIndexToRemove > -1) {
        this.selectedItems.splice(selectedItemIndexToRemove, 1);
      }
    } else {
      const filterObj = this.lineItems.filter(
        item => item.ProjectMasterID == itemData.ProjectMasterID
      );
      if (filterObj.length > 0) {
        for (let index = 0; index < filterObj.length; index++) {
          const element = filterObj[index];
          const itemIndex = this.lineItems.findIndex(
            item => item.ProjectMasterID == element.ProjectMasterID
          );
          this.lineItems.splice(itemIndex, 1);
        }
      }
    }

    this.totalBillableSummaryHrs =
      '$' +
      numeral(
        this.lineItems.reduce(function (a, b) {
          return a + +numeral(b['amount']).value();
        }, 0)
      ).format('0,0.00');
    const oldValue = parseFloat(numeral(this.summaryItems['selectedbillabletime']).value()).toFixed(
      2
    );
    this.summaryItems['Invoiceamountservices'] =
      '$' + numeral(numeral(this.totalBillableSummaryHrs).value()).format('0,0.00');
    this.summaryItems['writesupdown'] =
      '$' +
      numeral(
        numeral(this.totalBillableSummaryHrs).value() -
          numeral(this.summaryItems['selectedbillabletime']).value()
      ).format('0,0.00');
    const writeUpValue = numeral(this.summaryItems['writesupdown']).value();
    if (writeUpValue > 0) {
      this.amount_color = '#1a7d05';
    } else if (writeUpValue < 0) {
      this.amount_color = '#ea0b0b';
      this.summaryItems['writesupdown'] =
        '($' + numeral(Math.abs(writeUpValue)).format('0,0.00') + ')';
    } else {
      this.amount_color = '#0e0e0e';
    }
    if (this.lineItems.length == 0 && this.IsLinesItemsRequired) {
      this.addRow();
    }

    // uncheck parent selection
    const timeExpenseRecordsSize = this.lineItems.filter(
      item => item['ProjectMasterID'] == itemData.ProjectMasterID
    );
    if (timeExpenseRecordsSize.length == 0 && isCheckedFromTimeSlips) {
      const timeRecord = this.timeExpenseRecords.filter(
        item => item['ProjectMasterID'] == itemData.ProjectMasterID
      );
      if (timeRecord.length > 0) {
        timeRecord[0].isSelected = false;
        timeRecord[0].allSelectedTimeSlips = [];
        timeRecord[0].allSelectedExpenseSlips = [];
        this.checkHeaderCheckbox(timeRecord[0]);
        this.calculateSummary();
        this.dataTableComponent.selection = [...this.selectedItems];
      }
    }
    this.prepareInvoiceDesc();
    this.reOrderLineItems();
    //this.refreshTimeSlips({});
    this.calculateAllWUWDTotals();
  }

  reOrderLineItems() {
    for (let index = 0; index < this.lineItems.length; index++) {
      const element = this.lineItems[index];
      element['LineItem'] = index + 1;
    }
  }

  filterActivitiesType(event) {
    this.filteredActivityList = [];
    this.filteredEngagementsList = [];
    const tempObj = [];
    const temp = [];
    if (this.selActivitiesType.length < 10) {
      for (let i = 0; i < this.activitiesTypes.length; i++) {
        const client = this.activitiesTypes[i];
        if (
          client['label'] &&
          client['label'].toLowerCase().indexOf(event.query.toLowerCase()) >= 0
        ) {
          if (client['label'] != 'All Engagements') {
            temp.push(client);
          }
          tempObj.push(client);
        }
      }
    }
    this.filteredActivityList = tempObj.sort(this.mangoUtils.compareValues('label', 'asc'));
    this.filteredEngagementsList = temp.sort(this.mangoUtils.compareValues('label', 'asc'));
  }

  checkNoTimeRecordsWarning() {
    const ids = ['0', '6', '7', '11'];
    let noTimeRecords = false;
    this.selectedItems?.map(item => {
      if (item['allSelectedTimeSlips'].length == 0 && item['allSelectedExpenseSlips'].length == 0) {
        if (ids.includes(this.manualInvoiceForm.get('invoiceTemplate').value)) {
          noTimeRecords = true;
        }
      }
    });
    if (noTimeRecords) {
      Swal.fire({
        icon: 'warning',
        title: this.translate.instant('Warning'),
        text: this.translate.instant('no_time_records_warning'),
        showConfirmButton: true
      });
    }
    this.noTimeRecordsWarning = noTimeRecords;
    this.validateForm();
  }
  /*
    Changeing Payment Type
  */

  changefinalizeActions(control: any) {
    if (control.value === 'Email') {
      this.mangoAPISrvc.showLoader(true);
      this.mangoAPISrvc.getEmailNames(this.manualInvoiceForm.controls['ClientID'].value).subscribe({
        next: (emailsList: any) => {
          this.mangoAPISrvc.showLoader(false);
          if (emailsList.length === 0) {
            Swal.fire({
              icon: 'error',
              title: 'Information',
              text: 'Client email required message',
              showConfirmButton: true
            });
            this.manualInvoiceForm.controls['FinalizeAction'].setValue('Print');
          }
        },
        error: () => {
          this.mangoAPISrvc.showLoader(false);
          this.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
        }
      });
    }
  }
  /*
    Changeing Payment Type
  */

  changePaymentType(control: any) {
    if (control['value'] === 'Percentage') {
      this.IsAmount = false;
    } else {
      this.IsAmount = true;
    }
    this.calculateSummary();
  }
  /*
      updating US Money format
  */
  changeUSMoney(evt: any, data: any) {
    let enteredValue;
    if (evt['target']) {
      enteredValue = evt.target.value;
    } else {
      enteredValue = evt['value'] ? evt['value'] : evt['value'];
    }
    if (enteredValue == '' && enteredValue != 0) {
      enteredValue = data.amount;
    }
    const myNumeral = numeral(enteredValue);
    if (myNumeral.value() === null) {
      if (data) {
        evt['target']['value'] = data.amount = '$0.00';
      } else {
        evt.setValue('0.00');
      }
    } else {
      if (data) {
        evt['target']['value'] = data.amount = '$' + numeral(enteredValue).format('0,0.00');
        if (data.activityId == 7) {
          //  data['invoiceTax'] = (myNumeral.value() * (data['companytaxRate'] / 100));
        }
      } else {
        if (myNumeral.value() < 0) {
          enteredValue = 0;
        }
        evt.setValue(numeral(enteredValue).format('0,0.00'));
      }
      this.processAmt = numeral(this.myPaymentForm.get('paymentAmount').value).value();
      // this.invoiceTax = '$' + numeral(this.lineItems.reduce(function (a, b) { return a + +numeral(b['invoiceTax']).value(); }, 0)).format('0,0.00');
      this.validateLineItems();
      this.calculateSummary();
    }
  }

  updateForm() {
    this.manualInvoiceForm.controls['BillingHeaderID'].setValue('');
    this.manualInvoiceForm.controls['ClientID'].setValue('');
    this.manualInvoiceForm.controls['CompanyID'].setValue('');
    this.manualInvoiceForm.controls['PaymentHeaderID'].setValue('');
    this.manualInvoiceForm.controls['RetainerHeaderID'].setValue('');
    this.manualInvoiceForm.controls['CheckRef'].setValue('');
    this.manualInvoiceForm.controls['InvoiceDate'].setValue('');
    this.manualInvoiceForm.controls['PaymentType'].setValue('');
    this.manualInvoiceForm.controls['PaymentAmount'].setValue('');
    this.manualInvoiceForm.controls['DescriptionShort'].setValue('');
    this.manualInvoiceForm.controls['PaymentUnapplied'].setValue('');
  }

  fetchClients() {
    if (
      this.clientsList.length == 0 ||
      this.clientsList.length !==
        this.encrDecSrvc.clientList?.filter(
          client => client.ContactRecord != true && client.Inactive != true
        ).length
    ) {
      const list = this.encrDecSrvc.clientList;
      // let idArray = this.clientsList.map((client) => client.ClientID);
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        if (
          item['ContactRecord'] != true &&
          item['Inactive'] != true
          // &&
          // !idArray.includes(item.ClientID)
        ) {
          this.clientsList.push(item);
          // this.filteredClients.push(item);
        }
      }
    } else {
      clearInterval(this.intervalid);
    }
  }

  private filterTimeout: any = null;
  private filterTimer: any = timer(500);
  filterClients(event) {
    if (this.filterTimeout) {
      this.filterTimeout.unsubscribe();
    }

    this.filterTimeout = this.filterTimer.subscribe(() => {
      const filtered: any[] = [];
      const query = event.query;
      for (let i = 0; i < this.clientsList.length; i++) {
        const client = this.clientsList[i];
        if (client['ClientName'].toLowerCase().indexOf(query.toLowerCase()) > -1) {
          filtered.push(client);
        } else if (client['ClientNumber']?.toLowerCase()?.indexOf(query.toLowerCase()) > -1) {
          filtered.push(client);
        }

        if (filtered.length > 20) break;
      }
      this.filteredClients = filtered;
      this.filterTimeout.unsubscribe();
    });
  }

  initializeForm() {
    const cachedManualLastInvoiceDate = this.encrDecSrvc.getObject(
      AppConstants.manualLastInvoiceDate
    );

    const invoiceDate =
      cachedManualLastInvoiceDate != null && cachedManualLastInvoiceDate != ''
        ? new Date(
            moment(new Date(cachedManualLastInvoiceDate)).format('YYYY-MM-DDT00:00:00').toString()
          )
        : new Date();

    const invoiceStart =
      this.encrDecSrvc.getObject(AppConstants.manualLastDateThru) != null &&
      this.encrDecSrvc.getObject(AppConstants.manualLastDateThru) != ''
        ? new Date(this.encrDecSrvc.getObject(AppConstants.manualLastDateThru))
        : new Date();
    this.manualInvoiceForm = this._fb.group({
      BillingHeaderID: [''],
      ClientID: ['', [<any>Validators.required]],
      CompanyID: [''],
      PaymentHeaderID: [''],
      RetainerHeaderID: [''],
      DiscountType: ['Flat Amount'],
      DiscountValue: ['0.00', [<any>Validators.maxLength(20)]],

      InvoiceDate: [invoiceDate, [<any>Validators.required]],

      InvoiceStartDate: [invoiceStart],
      InvoiceEndDate: [new Date('2099-12-30')],
      PaymentType: [''],
      PaymentAmount: ['0.00', [<any>Validators.maxLength(20)]],
      DescriptionShort: ['', [<any>Validators.required, <any>Validators.maxLength(250)]],
      PaymentUnapplied: ['0.00'],
      invoiceTemplate: ['2'],
      FinalizeAction: ['Print', [<any>Validators.required]],
      bottomInvoiceNote: [''],
      topInvoiceNote: [''],
      datePeriodTypes: [''],
      staffId: [''],
      selectedTerm: [''],
      copyclipboard: [false]
    });
  }

  initializeDialogForm() {
    this.myPaymentForm = this._fb.group({
      paymentType: ['', [<any>Validators.required]],
      paymentDate: [new Date(), [<any>Validators.required]],
      paymentAmount: ['', [<any>Validators.required]],
      SurChargeAmount: [0],
      withSurcharge: [0],
      checkReference: [''],
      paymentMemo: [''],
      nameOnCard: [''],
      cardType: ['VISA'],
      cardno: [''],
      expDate: [''],
      securityCode: [''],
      customerDesc: [''],
      invoiceNumber: [''],
      firstName: [''],
      lastName: [''],
      address: [''],
      phoneNum: [''],
      city: [''],
      state: [''],
      zip: [''],
      country: [''],
      email: [''],
      company: [''],
      fname: [''],
      lname: ['']
    });
    this.myPaymentForm.valueChanges.subscribe(data => {
      this.validateDialogForm();
      this.isValidStaxForm();
    });
  }

  loadClientView() {
    const self = this;
    self.encrDecSrvc.addObject(AppConstants.selectedClientRecord, self.clientProfile);
    self.encrDecSrvc.addObject(AppConstants.clientID, self.clientProfile['ClientID']);
    self.encrDecSrvc.addObject(AppConstants.ClientName, self.clientProfile['ClientName']);

    self.mangoAPISrvc.showLoader(true);
    self.mangoAPISrvc.getAllDataCounts(self.clientProfile['ClientID']).subscribe(function (data) {
      self.encrDecSrvc.addObject(AppConstants.allDataCountsForClient, data);
      self.router.navigate([AppConstants.clientRoutePath + '/' + AppConstants.viewRoutePath]);
      self.mangoAPISrvc.fireClientView(true);
      self.mangoAPISrvc.showLoader(false);
    });
  }

  async getSurchargeAmount(merchantId, Amount, companyId, eventType) {
    const self = this;
    return new Promise(function (resolve, reject) {
      try {
        self.mangoAPISrvc
          .performStaxActions({ ccid: merchantId, amount: Amount }, companyId, eventType)
          .subscribe(function (data) {
            data = data['bin_type'] ? data : null;
            resolve(data);
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  async getCardType(customerCardID, companyId, eventType) {
    const self = this;
    return new Promise(function (resolve, reject) {
      try {
        self.mangoAPISrvc
          .performStaxActions({ ccid: customerCardID }, companyId, eventType)
          .subscribe(function (data) {
            resolve(data);
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  showCardOptions(control: any) {
    this.isCheckRef = false;
    this.showCardoption = false;
    this.showAchCardoption = false;
    this.showAchNewCardoption = false;
    this.flagAddNewCard = false;
    this.isDialogFormValid = false;
    this.isProcessing = false;
    this.isStaxNewCard = false;
    this.achProfile = null;
    this.paymentProfile = null;
    this.isSTaxEnabled = false;
    this.sTaxProfile = null;
    const self = this;
    const formData = self.myPaymentForm.value;
    //let paymentValue = numeral(formData["amount"]).value();
    self.myPaymentForm.controls['SurChargeAmount'].setValue(0);
    self.myPaymentForm.controls['withSurcharge'].setValue(0);
    self.isDebitCard = false;
    if (control['value']) {
      const enteredValue = control['value'].trim();
      self.myPaymentForm.controls['SurChargeAmount'].setValue(0);
      self.myPaymentForm.controls['withSurcharge'].setValue(0);
      if (enteredValue === 'Credit Card') {
        if (!self.selectedClientId) {
          self.mangoAPISrvc.notify(
            'error',
            this.translate.instant('error'),
            this.translate.instant('billing.Please_select_a_client_before_making_payment')
          );
          return;
        }
        self.mangoAPISrvc.showLoader(true);
        self.mangoAPISrvc
          .getPaymentProfile(self.selectedClientId, 'CC')
          .subscribe(async (result: any) => {
            self.staxHeaderTaxt = self.translate.instant(
              'billing-invoicing.credit-card-information'
            );
            self.sTaxProfile = result;
            if (result == null || result.length == 0) {
              self.addCardValidators();
              self.isDialogFormValid = false;
            } else {
              self.removeCardValidators();
              self.paymentProfile = result;
              self.myPaymentForm.controls['company'].setValue(self.client);

              if (self.sTaxProfile && self.sTaxProfile.CustomerCardID) {
                const cardTypeObj = await self.getCardType(
                  self.sTaxProfile.CustomerCardID,
                  self.mangoCompanyData.CompanyID,
                  'ct'
                );
                self.isDebitCard = cardTypeObj['bin_type'] == 'DEBIT' ? true : false;
                if (!self.sTaxProfile['CardType']) {
                  self.sTaxProfile['CardType'] = cardTypeObj['card_type']
                    ? cardTypeObj['card_type']
                    : null;
                  self.mangoAPISrvc
                    .updateCreditCardDetails(self.sTaxProfile.CustomerCardID, self.sTaxProfile)
                    .subscribe((resultRes: any) => {});
                }
              }

              // if (self.sTaxProfile && !self.sTaxProfile['StaxPaymentMethodID']) {
              //   self.sTaxProfile['StaxPaymentMethodID'] = self.sTaxProfile.StaxToken;
              //   self.mangoAPISrvc.updateCreditCardDetails(self.sTaxProfile.CustomerCardID, self.sTaxProfile).subscribe((resultRes: any) => { });
              // }
              if (self.mangoCompanyData.isSurchargeEnabled == true && self.sTaxProfile) {
                const surchargeObj = await self.getSurchargeAmount(
                  self.sTaxProfile.CustomerCardID,
                  self.myPaymentForm.controls['paymentAmount'].value,
                  self.mangoCompanyData.CompanyID,
                  'sc'
                );
                if (surchargeObj) {
                  self.myPaymentForm.controls['SurChargeAmount'].setValue(
                    surchargeObj['surcharge_amount']
                  );
                  self.myPaymentForm.controls['withSurcharge'].setValue(
                    surchargeObj['total_with_surcharge_amount']
                  );
                  self.isDebitCard = surchargeObj['bin_type'] == 'DEBIT' ? true : false;
                  if (self.isDebitCard) {
                    self.staxHeaderTaxt = self.translate.instant(
                      'billing-invoicing.debit-card-information'
                    );
                  }
                }
              }
            }

            if (self.isStaxVerify) {
              Swal.fire({
                title: self.translate.instant('confirmation'),
                html: `<div>Upon Completion of Electronic Payment, The Invoice Will Be Saved. Your Invoice Should Be Complete Before Processing Electronic Payment</div>`,
                icon: 'warning',
                showCancelButton: true,
                allowEscapeKey: false,
                allowEnterKey: false,
                confirmButtonText: 'Continue',
                cancelButtonText: 'Cancel'
              }).then(result => {
                if (result.value) {
                  self.isSTaxEnabled = true;
                  self.isCCFlow = true;
                  self.isStaxNewCard = self.sTaxProfile && self.sTaxProfile['scid'] ? false : true;
                  self.isProcessing = self.isStaxNewCard;
                  if (self.isStaxNewCard) {
                    self.myPaymentForm.controls['address'].setValue('');
                    self.myPaymentForm.controls['city'].setValue('');
                    self.myPaymentForm.controls['state'].setValue('');
                    self.myPaymentForm.controls['zip'].setValue('');
                  }
                  self.validateDialogForm();
                } else {
                  self.isDebitCard = false;
                  this.isProcessing = false;
                  self.myPaymentForm.controls['paymentType'].setValue(null);
                  self.myPaymentForm.controls['SurChargeAmount'].setValue(0);
                  self.myPaymentForm.controls['withSurcharge'].setValue(0);
                }

                self.genarateIFrames();
              });
            }
            if (self.mangoCompanyData['CCmerchantID']) {
              self.flagAddNewCard = false;
            } else {
              self.flagAddNewCard = true;
            }
            self.showCardoption = true;
            self.mangoAPISrvc.showLoader(false);
          });

        self.myPaymentForm.controls['checkReference'].setValue('');
        self.myPaymentForm.controls['checkReference'].setValidators([]);
        self.myPaymentForm.get('checkReference').updateValueAndValidity();
        //self.mangoAPISrvc.showLoader(false);
      } else if (enteredValue === 'Check') {
        self.isCheckRef = true;
        self.myPaymentForm.controls['checkReference'].setValue('');
        self.myPaymentForm.controls['checkReference'].setValidators([Validators.required]);
        self.myPaymentForm.get('checkReference').updateValueAndValidity();
        self.removeCardValidators();
      } else if (enteredValue === 'ACH') {
        self.isDialogFormValid = false;
        if (self.isStaxVerify) {
          self.isSTaxEnabled = true;
          self.isCCFlow = false;
          self.isStaxNewCard = false;
        }
        if (
          (self.mangoCompanyData['CCmerchantID'] && self.mangoCompanyData['CCpassword']) ||
          self.isSTaxEnabled
        ) {
          self.isDialogFormValid = true;
          self.myAchReceiptsForm.controls['FirstName'].setValue(
            self.selClient['ClientName'].substring(0, 19)
          );
          if (
            !self.clientProfile.BusStreet1 ||
            !self.clientProfile.BusZip ||
            !self.clientProfile.BusZip ||
            !self.clientProfile.BusState ||
            !self.clientProfile.BusCity
          ) {
            self.isProcessing = true;
            self.isDebitCard = self.showCardoption = self.showAchCardoption = false;
            Swal.fire({
              title: this.translate.instant('confirmation'),
              html: this.translate.instant('billing.client_address_and_zip_code_required'),
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: this.translate.instant('Go_to_Client'),
              cancelButtonText: this.translate.instant('no_cancel')
            }).then(result => {
              if (result.value) {
                self.loadClientView();
              } else {
                self.myPaymentForm.controls['paymentType'].setValue(null);
                self.myPaymentForm.controls['SurChargeAmount'].setValue(0);
                self.myPaymentForm.controls['withSurcharge'].setValue(0);
              }
            });
          } else {
            self.mangoAPISrvc.showLoader(true);
            self.isProcessing = false;
            self.mangoAPISrvc
              .getPaymentProfile(self.selectedClientId, 'ACH')
              .subscribe(async (result: any) => {
                self.staxHeaderTaxt = `ACH Information`;
                self.fattJs = new FattJs(self.mangoCompanyData.spkuid, {});
                self.sTaxProfile = result;
                self.isBusiness = result.isBusiness == true ? 'true' : 'false';
                self.paymentProfile = result;
                if (result == null || result.length == 0) {
                  self.showAchNewCardoption = true;
                  self.sTaxProfile = null;
                  self.isProcessing = self.isStaxNewCard = true;
                } else {
                  self.isStaxNewCard = self.sTaxProfile && self.sTaxProfile['scid'] ? false : true;
                  self.isProcessing = self.isStaxNewCard;

                  if (
                    self.sTaxProfile &&
                    !self.sTaxProfile['CardType'] &&
                    self.sTaxProfile.CustomerCardID
                  ) {
                    const cardTypeObj = await self.getCardType(
                      self.sTaxProfile.CustomerCardID,
                      self.mangoCompanyData.CompanyID,
                      'ct'
                    );
                    self.sTaxProfile['CardType'] = cardTypeObj['bank_type']
                      ? cardTypeObj['bank_type']
                      : null;
                    self.mangoAPISrvc
                      .updateCreditCardDetails(self.sTaxProfile.CustomerCardID, self.sTaxProfile)
                      .subscribe((resultRes: any) => {});
                  }

                  // if (self.sTaxProfile && !self.sTaxProfile['StaxPaymentMethodID']) {
                  //   self.sTaxProfile['StaxPaymentMethodID'] = self.sTaxProfile.StaxToken;
                  //   self.mangoAPISrvc.updateCreditCardDetails(self.sTaxProfile.CustomerCardID, self.sTaxProfile).subscribe((resultRes: any) => { });
                  // }

                  self.myPaymentForm.controls['company'].setValue(self.client);
                  if (result.TransType == 'ACH') {
                    self.showAchNewCardoption = false;
                    self.achProfile = result;
                  }
                }
                self.mangoAPISrvc.showLoader(false);
                self.showAchCardoption = true;
              });
          }
        }
      } else {
        self.myPaymentForm.controls['checkReference'].setValue('');
        self.myPaymentForm.controls['checkReference'].setValidators([]);
        self.myPaymentForm.get('checkReference').updateValueAndValidity();
        self.removeCardValidators();
      }
      self.validateDialogForm();
    }
  }

  getPaymentMethods(isStax) {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getPaymentMethods(this.loginCompanyId).subscribe((data: any) => {
      const items = [];
      for (let i = 0; i < data.length; i++) {
        if (!isStax) {
          if (data[i].PaymentDescription != 'ACH' && data[i].PaymentDescription != 'Credit Card') {
            items.push({ label: data[i].PaymentDescription, value: data[i].PaymentDescription });
          }
        } else {
          items.push({ label: data[i].PaymentDescription, value: data[i].PaymentDescription });
        }
      }
      this.paymentTypes = items.sort(function (a, b) {
        return a.CompanyPaymentMethodID - b.CompanyPaymentMethodID;
      });
      this.mangoAPISrvc.showLoader(false);
    });
  }

  updateDialogForm() {
    this.myPaymentForm.controls['paymentType'].setValue('');
    this.myPaymentForm.controls['paymentDate'].setValue(new Date());
    this.myPaymentForm.controls['paymentAmount'].setValue('');
    this.myPaymentForm.controls['checkReference'].setValue('');
    this.myPaymentForm.controls['paymentMemo'].setValue('');
    this.myPaymentForm.controls['nameOnCard'].setValue('');
    this.myPaymentForm.controls['cardno'].setValue('');
    this.myPaymentForm.controls['expDate'].setValue('');
    this.myPaymentForm.controls['securityCode'].setValue('');
    this.myPaymentForm.controls['customerDesc'].setValue('');
    this.myPaymentForm.controls['invoiceNumber'].setValue('');
    this.myPaymentForm.controls['firstName'].setValue('');
    this.myPaymentForm.controls['lastName'].setValue('');
    this.myPaymentForm.controls['address'].setValue('');
    this.myPaymentForm.controls['phoneNum'].setValue('');
    this.myPaymentForm.controls['city'].setValue('');
    this.myPaymentForm.controls['state'].setValue('');
    this.myPaymentForm.controls['zip'].setValue('');
    this.myPaymentForm.controls['country'].setValue('');
    this.myPaymentForm.controls['email'].setValue('');
    this.myPaymentForm.controls['company'].setValue('');
  }

  public cardFields = [
    'cardno',
    'expDate',
    'securityCode',
    'customerDesc',
    'invoiceNumber',
    'firstName',
    'lastName',
    'address',
    'phoneNum',
    'city',
    'state',
    'zip',
    'country',
    'email',
    'company',
    'nameOnCard'
  ];
  public requiredcardFields = ['cardno', 'expDate', 'nameOnCard', 'cardType'];

  addCardValidators() {
    const fieldNames = this.cardFields;
    for (let i = 0; i < fieldNames.length; i++) {
      const fieldName = fieldNames[i];
      if (this.requiredcardFields.indexOf(fieldName) > -1) {
        this.myPaymentForm.controls[fieldName].setValidators([Validators.required]);
      }
      if (fieldName == 'company') {
        this.myPaymentForm.controls[fieldName].setValue(this.client);
      } else {
        this.myPaymentForm.controls[fieldName].setValue(this.companyData[fieldName]);
      }
      this.myPaymentForm.get(fieldName).updateValueAndValidity();
    }
  }

  removeCardValidators() {
    const fieldNames = this.cardFields;
    for (let i = 0; i < fieldNames.length; i++) {
      const fieldName = fieldNames[i];
      this.myPaymentForm.controls[fieldName].setValue('');
      this.myPaymentForm.controls[fieldName].setValidators([]);
      this.myPaymentForm.get(fieldName).updateValueAndValidity();
    }
  }

  clearOpenRetainerTable() {
    this.openRetainersDataSource = this.mangoUtils.deepCloneObject(this.copyInvoicesDataSource);
    const totalsAppliedAmount = this.openRetainersDataSource.reduce(function (a, b) {
      return numeral(a).value() + +numeral(b.AppliedAmount).value();
    }, 0);
    this.totalAppliedAmount = numeral(totalsAppliedAmount.toFixed(2)).format('0,0.00');
    let totals = 0;
    this.openRetainersDataSource.map(function (obj) {
      if (obj.selected) {
        totals += numeral(obj.AppliedAmount).value();
      }
      return obj;
    });

    this.lineItems.map(function (obj) {
      obj['isDisabled'] = false;
      return obj;
    });
    this.totalUnappliedAmount = totals;
    this.calculateSummary();
  }
  /*
   */
  calculateLesspayments(event: any, data: any) {
    // calulated the unapplied
    const bal_dueValue = numeral(this.invoiceBalance).value();
    const lessamt = Math.abs(numeral(this.invoiceLessPayments).value());
    if (bal_dueValue <= 0 && data.selected) {
      Swal.fire({
        icon: 'error',
        title: this.translate.instant('Information'),
        html: this.translate.instant('billing.there_is_no_balance_due'),
        showConfirmButton: false,
        timer: 3000
      });
      data.selected = false;
      event.stopPropagation();
      return false;
    } else {
      const appliedAmt = numeral(data.PaymentUnapplied).value();
      // if the checkbox is selected
      if (data.selected) {
        // 80 avd 165
        if (bal_dueValue > appliedAmt) {
          data.AppliedAmount = numeral(appliedAmt).format('0,0.00');
          data.PaymentUnapplied = numeral(0).format('0,0.00');
          this.invoiceLessPayments = numeral(lessamt + appliedAmt).format('0,0.00');
        } else {
          const calculatedField = Math.abs(appliedAmt - bal_dueValue);
          data.AppliedAmount = numeral(bal_dueValue).format('0,0.00');
          data.PaymentUnapplied = numeral(calculatedField).format('0,0.00');
          this.invoiceLessPayments = numeral(lessamt - appliedAmt).format('0,0.00');
        }
      } else {
        const appiledAmtTemp = numeral(data.AppliedAmount).value();
        this.invoiceBalance = numeral(appliedAmt + appliedAmt).format('0,0.00');
        data.PaymentUnapplied = numeral(appliedAmt + appiledAmtTemp).format('0,0.00');
        data.AppliedAmount = 0;
      }
      const totalsAppliedAmount = this.openRetainersDataSource.reduce(function (a, b) {
        return numeral(a).value() + +numeral(b.AppliedAmount).value();
      }, 0);
      this.totalAppliedAmount = numeral(totalsAppliedAmount.toFixed(2)).format('0,0.00');
    }

    let totals = 0;
    this.openRetainersDataSource.map(function (obj) {
      if (obj.selected) {
        totals += numeral(obj.AppliedAmount).value();
      }
      return obj;
    });
    this.totalUnappliedAmount = totals;
    this.calculateSummary();

    const selectedItemsOpenRetainers = this.openRetainersDataSource.filter(
      item => item['selected'] == true
    );
    if (selectedItemsOpenRetainers.length > 0) {
      this.lineItems.map(obj => {
        this.selectedAdvance = true;
        obj['isDisabled'] = true;
        return obj;
      });
    } else {
      this.lineItems.map(obj => {
        this.selectedAdvance = false;
        obj['isDisabled'] = false;
        return obj;
      });
    }
  }

  onSpanRowClick(parentRecord: any) {
    this.selectedParent = {};
    this.selectedParent = parentRecord;
    this.displaySideBar = true;
    this.calcEngagementTimeSalesTax(parentRecord?.allTimeSlips);
    this.calcEngagementExpSalesTax(parentRecord?.allExpenseSlips);
  }

  /*
   Check and Uncheck row
  */
  checkUncheckRow(itemData: any, isParentSelection) {
    if (isParentSelection) {
      const isChecked = itemData.checked;
      for (let i = 0; i < this.timeExpenseRecords.length; ++i) {
        const itemData = this.timeExpenseRecords[i];
        itemData.isSelected = isChecked;
        itemData.allSelectedTimeSlips = isChecked ? [...itemData.allTimeSlips] : [];
        itemData.allSelectedExpenseSlips = isChecked ? [...itemData.allExpenseSlips] : [];
        if (isChecked) {
          this.dynamicNewLineItem(itemData);
        } else {
          this.deleteRow(itemData, false);
        }
        this.calculateSalesTaxes();
      }

      if (isChecked) {
        const index = this.lineItems.findIndex(
          item =>
            item.isNewRow == true &&
            (item['ProjectMasterID'] == -1 || item['ProjectMasterID'] == -1)
        );
        this.lineItems.splice(index, 1);
      }
    } else {
      itemData.isSelected = !itemData.isSelected;

      if (itemData.isSelected == true) {
        const index = this.lineItems.findIndex(
          item =>
            item.isNewRow == true &&
            (item['ProjectMasterID'] == -1 || item['ProjectMasterID'] == -1)
        );
        if (index > -1) {
          this.lineItems.splice(index, 1);
        }
      }

      itemData.allSelectedTimeSlips = itemData.isSelected ? [...itemData.allTimeSlips] : [];
      itemData.allSelectedExpenseSlips = itemData.isSelected ? [...itemData.allExpenseSlips] : [];
      if (itemData.isSelected) {
        itemData.isNewRow = false;
        this.dynamicNewLineItem(itemData);
      } else {
        this.deleteRow(itemData, false);
      }
    }

    this.prepareInvoiceDesc();
    this.calculteParentFooterTotals();
  }

  calculateGroupTotal(data: string) {
    const filterData = this.timeSlips.filter(item => item['ServiceCode'] == data);
    const selectedData = filterData.map(function (obj) {
      obj['TempStandardAmount'] = numeral(obj.StandardAmount.replace('$', '')).format('0.00');
      return obj;
    });
    const totals = selectedData.reduce(function (a, b) {
      return a + +b['TempStandardAmount'];
    }, 0);
    return numeral(totals.toFixed(2)).format('0,0.00');
  }
  /*
    History the form
  */
  historyData() {
    if (this.selectedClientId) {
      this.mangoAPISrvc.showLoader(true);
      this.mangoAPISrvc.getInvoiceHistory2(this.selectedClientId).subscribe((item: any) => {
        this.historyDisplay = true;
        // this.historyDataSource = item.map(function (obj) {
        //   obj['displayDate'] =  moment(obj.InvoiceDate.substr(0,10)).format("MM/DD/YYYY");
        // });
        for (let i = 0; i < item.length; ++i) {
          item[i]['displayDate'] = moment(item[i].InvoiceDate.substr(0, 10)).format('MM/DD/YYYY');
        }
        this.historyDataSource = [...item];
        this.mangoAPISrvc.showLoader(false);
      });
    }
  }

  processData() {
    this.dialogDisplay = true;
    this.isSTaxEnabled = false;
    this.sTaxProfile = null;
    this.processAmt = 0;
    if (this.myPaymentForm.value.paymentType == 'Check') {
      this.isCheckRef = true;
    } else {
      this.isCheckRef = false;
      if (
        this.myPaymentForm.value.paymentType == 'Credit Card' ||
        this.myPaymentForm.value.paymentType == 'ACH'
      ) {
        this.updateDialogForm();
      }
    }
    this.showCardoption = false;
    this.isDialogFormValid = false;
    const balValue = numeral(
      numeral(this.invoiceAmount || 0).value() + numeral(this.invoiceTax || 0).value()
    ).format('0,0.00');
    // @ravi and tim
    this.processAmt = numeral(this.invoiceBalance).value(); //numeral(balValue).value();
    this.myPaymentForm.controls['paymentAmount'].setValue(
      numeral(this.invoiceBalance).format('0,0.00')
    );
  }

  getEmailcontactsList(clientId) {
    const parent = this;
    parent.allEmailContacts = [];
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getEmailcontactsByClientID(clientId).subscribe((response: any) => {
      parent.allEmailContacts = response.filter(item => item.isClientPortalAccess == true);
      parent.mangoAPISrvc.showLoader(false);
    });
  }

  handleSelectClick(event) {
    const parent = this;
    parent.lineItems.length = 0;
    parent.grandBillableAmt = 0;
    parent.totalNumberPaymentUnapplied = 0;
    parent.clientRouteUrl = '';
    parent.encrDecSrvc.addObject(AppConstants.isFormChanged, true);
    parent.selectedClientId = null;
    parent.IsEnableHistory = true;
    parent.selClient = event;
    parent.isClientSelected = true;
    parent.client = event['ClientName'];
    parent.timeExpenseRecords = [];
    const formObj = parent.manualInvoiceForm.value;
    formObj['InvoiceStartDate'] = moment(formObj['InvoiceStartDate']).format('MM-DD-YYYY');
    formObj.staffId = formObj.staffId = (formObj.staffId && formObj.staffId !== -1) ? formObj.staffId : null;
    if (event && event['ClientID']) {
      parent.clientRouteUrl = '/clients/' + event['ClientID'] + '/edit';
      parent.getProjects(event['ClientID']);
      parent.getEmailcontactsList(event.ClientID);
      parent.client = event.ClientName;
      parent.selectedClientId = event['ClientID'];
      parent.manualInvoiceForm.controls['ClientID'].setValue(parent.selectedClientId);
      parent.IsEnableHistory = false;
      parent.validateLineItems();
      parent.mangoAPISrvc.getLastInvoiceInfo().subscribe((item: any) => {
        parent.client = event['ClientName'];
        parent.NextInvoiceNumber = numeral(item['NextInvoiceNumber']).value() + 1;
        if (item.StandardFooterMessage) {
          parent.manualInvoiceForm.controls['bottomInvoiceNote'].setValue(
            item.StandardFooterMessage
          );
        }
        parent.fetchClientInformation(event['ClientID']);
        parent.mangoAPISrvc.getSettingData(event['ClientID']).subscribe((settingData: any) => {
          parent.getOpenRetainersById(event['ClientID']);
          const template = parent.invoiceTemplateTypes.filter(
            item => item['value'] == settingData.DefaultInvoiceTemplate
          );
          if (template.length > 0 && template[0].value) {
            parent.manualInvoiceForm.controls['invoiceTemplate'].setValue(template[0].value);
            parent.isShowDetails = template[0].value == 14 ? true : false;
          }
          if (settingData.FinalizeAction) {
            parent.manualInvoiceForm.controls['FinalizeAction'].setValue(
              settingData.FinalizeAction
            );
          } else {
            parent.manualInvoiceForm.controls['FinalizeAction'].setValue('Print');
          }
          parent.changefinalizeActions({ value: settingData.FinalizeAction });
          if (settingData.TermsID) {
            parent.manualInvoiceForm.controls['selectedTerm'].setValue(settingData.TermsID);
          } else {
            parent.manualInvoiceForm.controls['selectedTerm'].setValue(null);
          }

          parent.invoiceOpt = settingData;
          parent.selEngagementType =
            parent.selEngagementType && parent.selEngagementType.length > 0
              ? parent.selEngagementType
              : null;

          //
          parent.mangoAPISrvc
            .SlipMasterbyCompanyIDLevel1New(
              event['ClientID'],
              formObj.staffId,
              parent.selEngagementType,
              formObj['InvoiceStartDate']
            )
            .subscribe(
              (leverOne: any) => {
                if (leverOne.parent.length == 0) {
                  parent.addRow();
                  return false;
                }
                //check empty data
                if (leverOne.parent.length != parent.projectMastersArray.length) {
                  for (var i = 0; i < parent.projectMastersArray.length; ++i) {
                    const temp = leverOne.parent.filter(
                      item => item['ProjectMasterID'] == parent.projectMastersArray[i]
                    );
                    if (temp && temp.length == 0) {
                      parent.defaultParentRecord = {
                        hasChildrens: false,
                        ProjectMasterID: -1,
                        EngagementName: null,
                        expenseamount: '0',
                        totaltime: '0',
                        laboramount: '0',
                        nonbillableamount: 0,
                        writeupValue: 0,
                        invoiceAmount: 0
                      };
                      const projectsDropDownList = parent.filteredEngagementsList.filter(
                        item => item['value'] == parent.projectMastersArray[i]
                      );
                      if (projectsDropDownList && projectsDropDownList.length > 0) {
                        parent.defaultParentRecord['ProjectMasterID'] =
                          projectsDropDownList[0]['value'];
                        parent.defaultParentRecord['EngagementName'] =
                          projectsDropDownList[0]['label'];
                        leverOne.parent.push(parent.defaultParentRecord);
                      } else {
                        parent.defaultParentRecord['ProjectMasterID'] = null;
                        parent.defaultParentRecord['EngagementName'] = null;
                      }
                    }
                  }
                }
                leverOne.parent = leverOne.parent.map(function (obj) {
                  obj['SelectForBilling'] = true;
                  obj['writeupValue'] = 0.0;
                  obj['invoiceAmount'] = 0.0;
                  obj['laboramount'] = obj['laboramount']
                    ? numeral(obj['laboramount']).value()
                    : 0.0;
                  obj['nonbillableamount'] = obj['nonbillableamount']
                    ? numeral(obj['nonbillableamount']).value()
                    : 0.0;
                  obj['expenseamount'] = obj['expenseamount']
                    ? numeral(obj['expenseamount']).value()
                    : 0.0;
                  obj['totaltime'] = obj['totaltime'] ? numeral(obj['totaltime']).value() : 0;
                  obj['hasChildrens'] = false;
                  obj['isSelected'] = true;
                  obj['isNewRow'] = false;
                  obj['IsExpenseRollUp'] = parent.mangoCompanyData['AutoRollupExpenses'];
                  obj['uuid'] = parent.mangoUtils.generateUUID();
                  parent.dynamicNewLineItem(obj, true);
                  return obj;
                });

                leverOne.parent.map(item => {
                  parent.updateExpenseRollUp(
                    { checked: parent.mangoCompanyData['AutoRollupExpenses'] },
                    item
                  );
                });

                parent.timeExpenseRecords = [...leverOne.parent];
                parent.selectedItems = [...leverOne.parent];

                const ids = ['0', '6', '7', '11'];
                let noTimeRecords = false;

                for (let i = 0; i < parent.timeExpenseRecords.length; i++) {
                  let allRecords = leverOne.childs.filter(
                    item => item['ProjectMasterID'] == parent.timeExpenseRecords[i].ProjectMasterID
                  );
                  allRecords = allRecords.map(function (obj) {
                    obj['SelectForBilling'] = true;
                    obj['displayDate'] = moment(obj.Ddate.substr(0, 10)).format('MM/DD/YYYY');
                    obj['Memo'] = obj['Memo']
                      ? parent.mangoUtils.replaceCaretTemplate(obj['Memo'])
                      : '';
                    return obj;
                  });

                  parent.timeExpenseRecords[i]['allTimeSlips'] = allRecords.filter(
                    item => item['IsTimeRecord'] == 'T'
                  );
                  parent.timeExpenseRecords[i]['allSelectedTimeSlips'] = [
                    ...allRecords.filter(item => item['IsTimeRecord'] == 'T')
                  ];
                  parent.timeExpenseRecords[i]['allExpenseSlips'] = allRecords.filter(
                    item => item['IsTimeRecord'] == 'X'
                  );

                  parent.timeExpenseRecords[i]['allExpenseSlips'] = allRecords.filter(
                    item => item['IsTimeRecord'] == 'X'
                  );

                  parent.timeExpenseRecords[i]['allExpenseSlips'].forEach(item => {
                    item.BilledAmount = item.Billable ? item.StandardAmount : 0;
                    item.WriteUpDown = item.Billable
                      ? 0
                      : parent.mangoUtils.subtractFloat(item.BilledAmount, item.StandardAmount);
                  });

                  parent.timeExpenseRecords[i]['allSelectedExpenseSlips'] =
                    parent.timeExpenseRecords[i]['allSelectedExpenseSlips'] = [
                      ...allRecords.filter(item => item['IsTimeRecord'] == 'X')
                    ];

                  parent.timeExpenseRecords[i]['allSelectedExpenseSlips'].forEach(item => {
                    item.BilledAmount = item.Billable ? item.StandardAmount : 0;
                    item.WriteUpDown = item.Billable
                      ? 0
                      : parent.mangoUtils.subtractFloat(item.BilledAmount, item.StandardAmount);
                  });

                  parent.timeExpenseRecords[i]['grandExpenseTotalAmt'] = parent.timeExpenseRecords[
                    i
                  ]['allExpenseSlips'].reduce(function (a, b) {
                    return a + +numeral(b['StandardAmount']).value();
                  }, 0);
                  parent.timeExpenseRecords[i]['grandExpenseBillableAmt'] =
                    parent.timeExpenseRecords[i]['allExpenseSlips'].reduce(function (a, b) {
                      return a + +(b['Billable'] ? numeral(b['billableamount']).value() : 0);
                    }, 0);

                  parent.timeExpenseRecords[i]['grandExpenseHrs'] = parent.timeExpenseRecords[i][
                    'allTimeSlips'
                  ].reduce(function (a, b) {
                    return a + +numeral(b['TotalTime']).value();
                  }, 0);
                  parent.timeExpenseRecords[i]['grandBillableamount'] = parent.timeExpenseRecords[
                    i
                  ]['allTimeSlips'].reduce(function (a, b) {
                    return a + +numeral(b['billableamount']).value();
                  }, 0);
                  parent.timeExpenseRecords[i]['grandNonbillableamount'] =
                    parent.timeExpenseRecords[i]['allTimeSlips'].reduce(function (a, b) {
                      return a + +numeral(b['nonbillableamount']).value();
                    }, 0);
                  if (
                    parent.timeExpenseRecords[i]['allSelectedTimeSlips'].length == 0 &&
                    parent.timeExpenseRecords[i]['allSelectedExpenseSlips'].length == 0
                  ) {
                    const value = this.manualInvoiceForm.get('invoiceTemplate').value;
                    if (ids.includes(value)) {
                      noTimeRecords = true;
                    }
                  }
                  if (
                    parent.timeExpenseRecords[i]['allTimeSlips'].length > 0 ||
                    parent.timeExpenseRecords[i]['allExpenseSlips'].length > 0
                  ) {
                    parent.timeExpenseRecords[i]['allExpenseSlips'] = parent.timeExpenseRecords[i][
                      'allExpenseSlips'
                    ].map(timeSlip => {
                      return {
                        ...timeSlip,
                        displayDate: moment(timeSlip.Ddate.substr(0, 10)).format('MM/DD/YYYY'),
                        Ddate: new Date(timeSlip.Ddate)
                      };
                    });

                    parent.timeExpenseRecords[i]['hasChildrens'] = true;
                  }
                }

                parent.noTimeRecordsWarning = noTimeRecords;
                if (noTimeRecords) {
                  Swal.fire({
                    icon: 'warning',
                    title: this.translate.instant('Warning'),
                    text: this.translate.instant('no_time_records_warning'),
                    showConfirmButton: true
                  });
                }

                parent.calculateAllWUWDTotals(false, false, true);
              },
              err => parent.mangoAPISrvc.showLoader(false)
            );
          setTimeout(() => {
            parent.validateForm();
          }, 500);
        });
      });

      this.getCustomerBalance(event['ClientID']);
      this.getCustomerLastInvoice(event['ClientID']);
    }
  }

  onShowScratchpad(data) {
    this.editableData = data;
    this.scratchPadEnabled = true;
  }

  onScratchpadChange() {
    this.dtchild.editingRowKeys = {};
    this.dtchild.editingRowKeys[this.editableData?.SlipMasterID] = true;
  }

  onScratchpadClose() {
    this.scratchPadEnabled = false;
    this.editableData = null;
  }

  getCustomerBalance(ClientId) {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getClientCustomerBalance(ClientId).subscribe(
      (data: any[]) => {
        this.totalCustomerBalance =
          data.length > 0
            ? data[0].CustomerBalance
              ? parseFloat(data[0].CustomerBalance)
              : 0.0
            : 0.0;
      },
      err => this.mangoAPISrvc.showLoader(false)
    );
  }

  getCustomerLastInvoice(ClientId) {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getInvoiceHistory2Limit(ClientId, 1).subscribe((data: any[]) => {
      this.totalLastInvoiceAmount =
        data.length > 0 ? (data[0].InvoiceAmount ? parseFloat(data[0].InvoiceAmount) : 0.0) : 0.0;
      this.mangoAPISrvc.showLoader(false);
    });
  }

  addNewCard() {
    const self = this;
    if (!self.showCardoption) {
      return false;
    }

    self.addCardValidators();
    self.isDialogFormValid = false;
    self.flagAddNewCard = true;
  }

  addAchNewCard() {
    const self = this;
    if (!self.showAchCardoption) {
      return false;
    }
    self.isDialogFormValid = false;
    self.showAchNewCardoption = true;
    self.flagAddNewCard = true;
  }

  closePaymentForm(isFormReset) {
    if (!isFormReset) {
      this.showCardoption = false;
      this.flagAddNewCard = false;
      this.isDialogFormValid = false;
      this.showAchCardoption = false;
      this.showAchNewCardoption = false;
      this.paymentProfile = null;
      this.achProfile = null;
      this.isStaxNewCard = false;
      this.showStaxoption = false;
      this.isCCFlow = false;
      this.dialogDisplay = false;
      this.showACHDialog = false;
      this.isProcessing = false;
      //@ravi and tim
      //this.calculateSummary();
      return false;
    } else {
      this.mangoAPISrvc.showLoader(true);
      this.dialogDisplay = false;
      setTimeout(() => {
        this.isProcessing = false;
        this.showCardoption = false;
        this.flagAddNewCard = false;
        this.showAchCardoption = false;
        this.showAchNewCardoption = false;
        this.paymentProfile = null;
        this.achProfile = null;
        this.isStaxNewCard = false;
        this.showStaxoption = false;
        this.showACHDialog = false;
        this.isCCFlow = false;
        if (
          this.dialogDisplay ||
          this.myPaymentForm.value.paymentType == 'Credit Card' ||
          this.myPaymentForm.value.paymentType == 'ACH'
        ) {
          this.dialogDisplay = false;
          this.myPaymentForm.reset();
          this.myPaymentForm.controls['paymentDate'].setValue(new Date());
          this.calculateSummary();
        }
        this.mangoAPISrvc.showLoader(false);
      }, 2000);
    }
    // else{
    //   this.myPaymentForm.reset();
    //   this.myPaymentForm.controls['paymentDate'].setValue(new Date());
    // }
  }

  /* Saving Users Education data */
  savePaymentData(arg: any) {
    const paymentAmtValue = numeral(this.myPaymentForm.get('paymentAmount').value).value();
    const paymentType = this.myPaymentForm.value.paymentType; // == 'Credit Card');
    // cr card process
    if (arg == 'card' && paymentAmtValue == 0) {
      Swal.fire({
        title: this.translate.instant('confirmation'),
        text: this.translate.instant('billing.Credit_Card_payment_amount'),
        icon: 'warning',
        showCancelButton: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: this.translate.instant('ok')
      }).then(result => {});
    } else if (
      (paymentType == 'Credit Card' || paymentType == 'ACH') &&
      (!this.companyData.address || !this.companyData.zip || !this.companyData.email)
    ) {
      Swal.fire({
        title: this.translate.instant('confirmation'),
        html: this.translate.instant('billing.Client_address_zip_code_and_email_is_required'),
        icon: 'warning',
        showCancelButton: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: this.translate.instant('ok')
      }).then(result => {});
      return false;
    } else {
      if (this.showAchCardoption) {
        this.processAchCard();
      } else {
        this.processDialogData();
      }
    }
  }

  singularCreateProfile() {
    const parent = this;
    const formData = this.myPaymentForm.value;

    const CCPayment = {};
    CCPayment['MerchantID'] = this.mangoCompanyData['CCmerchantID'];
    CCPayment['Login'] = this.mangoCompanyData['CClogin'];
    CCPayment['Password'] = this.mangoCompanyData['CCpassword'];
    CCPayment['CardType'] = formData['cardType'];
    CCPayment['CardNumber'] = formData['cardno'].replace(/\-/g, '');
    CCPayment['CVV'] = formData['securityCode'].replace(/\-/g, '');
    CCPayment['ExpDate'] = formData['expDate'].replace(/\//g, '');
    CCPayment['Amount'] = '0';
    // var displayname = formData['nameOnCard'];
    // var tmp = displayname.split(" ");
    CCPayment['FirstName'] = formData['nameOnCard'];
    CCPayment['LastName'] = formData['nameOnCard'];
    CCPayment['EmailAddress'] = parent.companyData['email'];
    CCPayment['Address1'] = parent.companyData['address'];
    CCPayment['City'] = parent.companyData['city'];
    CCPayment['State'] = parent.companyData['state'];
    CCPayment['Country'] = parent.companyData['country'];
    CCPayment['Zip'] = parent.companyData['zip'].replace(/\-/g, '');

    const sbpMangoDbProfile = {};
    sbpMangoDbProfile['ClientID'] = this.selectedClientId;
    sbpMangoDbProfile['paymentProfileID'] = '';
    sbpMangoDbProfile['expiryDate'] = formData['expDate'];
    sbpMangoDbProfile['nameOnCard'] = formData['nameOnCard'];
    sbpMangoDbProfile['active'] = true;

    // deleting old card information
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.deleteProfile(this.selectedClientId).subscribe(obj => {
      parent.mangoAPISrvc.createCustomerCC(CCPayment).subscribe((data: any) => {
        if (data.Status == 'success') {
          sbpMangoDbProfile['TransactionReference'] = data['Confirmation'];
          sbpMangoDbProfile['CardNo'] = CCPayment['CardNumber'].substr(
            CCPayment['CardNumber'].length - 4,
            CCPayment['CardNumber'].length
          );

          // create Custmor Cr Card Profile in mango DB
          parent.mangoAPISrvc.saveProfileIDinMango(sbpMangoDbProfile).subscribe(carddata => {
            parent.isDialogFormValid = false;
            sbpMangoDbProfile['CustomerCardID'] = carddata['id'];
            sbpMangoDbProfile['ExpiryDate'] = sbpMangoDbProfile['expiryDate'];
            sbpMangoDbProfile['NameOnCard'] = sbpMangoDbProfile['nameOnCard'];
            parent.singularProcessPayment(sbpMangoDbProfile, true);
            parent.mangoAPISrvc.showLoader(false);
          }),
            error => {
              parent.mangoAPISrvc.notify(
                'error',
                this.translate.instant('error'),
                AppConstants.fetchErrorMsg
              );
              parent.mangoAPISrvc.showLoader(false);
            };
        } else {
          parent.mangoAPISrvc.notify('error', this.translate.instant('error'), data.Message);
        }
      }),
        error => {
          parent.mangoAPISrvc.notify(
            'error',
            this.translate.instant('error'),
            AppConstants.fetchErrorMsg
          );
          parent.mangoAPISrvc.showLoader(false);
        };
    }),
      error => {
        parent.mangoAPISrvc.notify(
          'error',
          this.translate.instant('error'),
          AppConstants.fetchErrorMsg
        );
        parent.mangoAPISrvc.showLoader(false);
      };
  }

  chargeCustomerProfile(self?: any) {
    if (!self) {
      self = this;
    }
    const formData = self.myPaymentForm.value;
    formData.invoiceNumber = self.NextInvoiceNumber;
    const postData = {
      ClientID: self.selectedClientId,
      CustomerProfileID: self.paymentProfile.ProfileID,
      CustomerPaymentProfileID: self.paymentProfile.PaymentProfileID,
      InvoiceNumber: formData.invoiceNumber,
      InvoiceDesc: formData.paymentMemo,
      PaymentAmount: Number(formData.paymentAmount),
      Items: [
        {
          ItemID: formData.invoiceNumber,
          Name: formData.invoiceNumber,
          Description: formData.paymentMemo,
          Quantity: 1,
          UnitPrice: Number(formData.paymentAmount)
        }
      ]
    };

    self.mangoAPISrvc.chargePaymentProfile(postData).subscribe((result: any) => {
      // self.updateDialogForm();
      if (result.messages && result.messages.resultCode == 'Ok') {
        self.mangoAPISrvc.notify(
          'success',
          this.translate.instant('Success_notify'),
          this.translate.instant('billing.Credit_Card_Processed_Successfully')
        );
        self.closePaymentForm(true);
      } else {
        self.mangoAPISrvc.notify(
          'error',
          this.translate.instant('error'),
          this.translate.instant('billing.Credit_Card_processing_failed')
        );
      }
    });
  }
  /**/
  // closeAll() {
  //   debugger
  //   this.dialogDisplay = false;
  //   this.isDialogFormValid = false;
  //   this.showCardoption = false;
  // }
  /**
   * Create customer profile on authorize.net server
   */
  createCustomerProfile() {
    const self = this;
    if (!self.selectedClientId || self.paymentProfile) {
      return false;
    }

    const formData = self.myPaymentForm.value;

    const postData = {
      ClientID: self.selectedClientId,
      NameOnCard: formData.nameOnCard,
      CardNo: formData.cardno.replace(/\-/g, ''),
      ExpiryDate: formData.expDate,
      Customer: {
        Email: formData.email,
        FirstName: formData.firstName,
        LastName: formData.lastName,
        Company: formData.company,
        Address: formData.address,
        City: formData.city,
        State: formData.state,
        Zip: formData.zip,
        Country: formData.country,
        PhoneNum: formData.phoneNum,
        Description: formData.customerDesc
      },
      CustomerProfileID: '',
      CustomerPaymentProfileID: '',
      InvoiceNumber: '',
      InvoiceDesc: '',
      Items: [{ ItemID: '', Name: '', Description: '', Quantity: 1, UnitPrice: 0 }]
    };
    self.mangoAPISrvc.showLoader(true);
    self.mangoAPISrvc.saveCustomerProfile(postData).subscribe((result: any) => {
      self.mangoAPISrvc.showLoader(false);
      if (result.messages && result.messages.resultCode == 'Ok') {
        self.mangoAPISrvc.getPaymentProfile(self.selectedClientId, 'CC').subscribe(result => {
          if (result == null) {
            return;
          }
          self.paymentProfile = result;
          self.isDialogFormValid = true;
        });
      } else {
        self.mangoAPISrvc.notify(
          'error',
          this.translate.instant('Information'),
          this.translate.instant('billing.The_provided_access_token_is_invalid')
        );
      }
    });
  }

  createCustomerPaymentProfile() {
    const self = this;
    if (!self.selectedClientId || !self.paymentProfile) {
      return false;
    }

    const formData = self.myPaymentForm.value;

    const postData = {
      ClientID: self.selectedClientId,
      NameOnCard: formData.nameOnCard,
      CardNo: formData.cardno.replace(/\-/g, ''),
      ExpiryDate: formData.expDate,
      Customer: {
        Email: formData.email,
        FirstName: formData.firstName,
        LastName: formData.lastName,
        Company: formData.company,
        Address: formData.address,
        City: formData.city,
        State: formData.state,
        Zip: formData.zip,
        Country: formData.country,
        PhoneNum: formData.phoneNum,
        Description: formData.customerDesc
      },
      CustomerProfileID: self.paymentProfile.ProfileID,
      CustomerPaymentProfileID: '',
      InvoiceNumber: '',
      InvoiceDesc: '',
      Items: [{ ItemID: '', Name: '', Description: '', Quantity: 1, UnitPrice: 0 }]
    };
    self.mangoAPISrvc.showLoader(true);
    self.mangoAPISrvc.savePaymentProfile(postData).subscribe((result: any) => {
      if (result.messages && result.messages.resultCode == 'Ok') {
        self.mangoAPISrvc.getPaymentProfile(self.selectedClientId, 'CC').subscribe(result => {
          if (result == null) {
            return;
          }
          self.paymentProfile = result;
          self.flagAddNewCard = false;
          self.mangoAPISrvc.showLoader(false);
        });
      } else {
        self.mangoAPISrvc.notify(
          'error',
          this.translate.instant('Information'),
          this.translate.instant('billing.The_provided_access_token_is_invalid')
        );
      }
    });
  }

  handleActivitySelectClick() {
    this.filteredActivityList = [];
    if (this.selActivitiesType) {
      if (this.selActivitiesType.length < 3) {
      }
    }
    //mimic remote call
    setTimeout(() => {
      this.filteredActivityList = this.selActivitiesType;
    }, 100);
  }

  footGroupTotalsForRetainer() {
    const totalsPayAmt = this.openRetainersDataSource.reduce(function (a, b) {
      return numeral(a).value() + +numeral(b.PaymentAmount).value();
    }, 0);
    this.totalNumberPaymentamt = numeral(totalsPayAmt.toFixed(2)).format('0,0.00');

    const totalsPayUn = this.openRetainersDataSource.reduce(function (a, b) {
      return numeral(a).value() + +numeral(b.PaymentUnapplied).value();
    }, 0);
    this.totalNumberPaymentUnapplied = totalsPayUn;

    const totalsPreviouslyApplied = this.openRetainersDataSource.reduce(function (a, b) {
      return numeral(a).value() + +numeral(b.AmountPreviouslyAppliedRetainer).value();
    }, 0);
    this.totalPreviouslyApplied = numeral(totalsPreviouslyApplied.toFixed(2)).format('0,0.00');

    const totalsAppliedAmount = this.openRetainersDataSource.reduce(function (a, b) {
      return numeral(a).value() + +numeral(b.AppliedAmount).value();
    }, 0);
    this.totalAppliedAmount = numeral(totalsAppliedAmount.toFixed(2)).format('0,0.00');
  }
  /*

  */
  getOpenRetainersById(id) {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getOpenRetainersById(id).subscribe((data: any) => {
      parent.openRetainersDataSource = data.map(function (obj) {
        obj['selected'] = false;
        obj['AppliedAmount'] = 0.0;
        obj['AmountPreviouslyAppliedRetainer'] = numeral(
          obj['AmountPreviouslyAppliedRetainer']
        ).format('0,0.00');
        obj['PaymentAmount'] = numeral(obj['PaymentAmount']).format('0,0.00');
        obj['PaymentUnapplied'] = numeral(obj['PaymentUnapplied']).format('0,0.00');
        return obj;
      });
      this.mangoAPISrvc.getInvoices(this.selClient.ClientID).subscribe((data: any) => {
        data.map(item => {
          if (item.paymentDetails?.length > 0 && item.InvoiceBalance < 0) {
            item.paymentDetails = item.paymentDetails.map(paymentDetail => {
              return {
                ...paymentDetail,
                bhInvoiceType: item.InvoiceType,
                bhInvoiceBalance: item.InvoiceBalance
              };
            });
            this.bhPaymentDetailsArr.push(...item.paymentDetails);
          }
        });
      });

      parent.footGroupTotalsForRetainer();
      parent.copyInvoicesDataSource = parent.mangoUtils.deepCloneObject(
        parent.openRetainersDataSource
      );
      parent.mangoAPISrvc.showLoader(false);
    });
  }

  // 1st time add card.  Add to Mango DB, CreateProfile on Singular, if CreateProfile successful, then we Auth using ProfileID.
  // 2nd time (Profile has already been created.  Only Auth using ProfileID)
  singularProcessPayment(obj, isCreateTime) {
    const parent = this;
    const authProfile = {};
    const cashFormData = this.myPaymentForm.value;
    authProfile['MerchantID'] = this.mangoCompanyData['CCmerchantID'];
    authProfile['Login'] = this.mangoCompanyData['CClogin'];
    authProfile['Password'] = this.mangoCompanyData['CCpassword'];
    authProfile['Amount'] = numeral(cashFormData.paymentAmount).value();
    authProfile['StandardEntryCode'] = 'SEC';
    authProfile['AuthOnly'] = false;
    authProfile['CheckNegativeAccounts'] = false;
    authProfile['Token'] = obj['TransactionReference'];
    obj['CustomerCardID'] = obj['CustomerCardID']
      ? obj['CustomerCardID']
      : parent.paymentProfile['CustomerCardID'];
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.tokenizedPaymentsACHCard(authProfile).subscribe((result: any) => {
      if (result.Status == 'success') {
        obj['TransactionReference'] = result['Confirmation'];
        parent.mangoAPISrvc.updateProfileIDinMango(obj).subscribe(carddata => {}),
          error => {
            parent.mangoAPISrvc.notify(
              'error',
              this.translate.instant('error'),
              AppConstants.updateErrorMsg
            );
            parent.mangoAPISrvc.showLoader(false);
          };

        if (
          parent.paymentProfile &&
          parent.paymentProfile.NameOnCard &&
          parent.paymentProfile.CardNo
        ) {
          const formData = parent.myPaymentForm.value;
          const note =
            formData.paymentMemo +
            ' ' +
            'Paid By: ' +
            parent.paymentProfile.NameOnCard +
            ', ' +
            'using Credit Card:  ****' +
            parent.paymentProfile.CardNo +
            ' (MI)';
          parent.myPaymentForm.controls['paymentMemo'].setValue(note);
        }
        Swal.fire({
          icon: 'success',
          title: this.translate.instant('Success_notify'),
          showCancelButton: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          confirmButtonText: this.translate.instant('ok'),
          text: this.translate.instant('billing.Credit_Card_Processed_Successfully')
        });
      } else {
        if (isCreateTime) {
          parent.mangoAPISrvc.showLoader(true);
          parent.mangoAPISrvc.deleteProfile(obj['ClientID']).subscribe(obj => {
            Swal.fire({
              icon: 'error',
              title: this.translate.instant('error'),
              showCancelButton: false,
              allowEscapeKey: true,
              allowEnterKey: true,
              confirmButtonText: this.translate.instant('ok'),
              text: this.translate.instant('cc-processed-failed')
            });
          }),
            error => {
              parent.mangoAPISrvc.notify(
                'error',
                this.translate.instant('error'),
                AppConstants.updateErrorMsg
              );
              parent.mangoAPISrvc.showLoader(false);
            };
        } else {
          Swal.fire({
            icon: 'error',
            title: this.translate.instant('error'),
            showCancelButton: false,
            allowEscapeKey: true,
            allowEnterKey: true,
            confirmButtonText: this.translate.instant('ok'),
            text: this.translate.instant('cc-processed-failed')
          });
        }
      }
      parent.closePaymentForm(true);
    }),
      error => {
        Swal.fire({
          icon: 'error',
          title: this.translate.instant('error'),
          showCancelButton: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          confirmButtonText: this.translate.instant('ok'),
          text: this.translate.instant('cc-processed-failed')
        });
        parent.closePaymentForm(true);
        parent.mangoAPISrvc.showLoader(false);
      };
  }

  processDialogData() {
    const obj = this.myPaymentForm.value;
    if (this.myPaymentForm.get('paymentAmount').valid) {
      this.calculateSummary();
    }
    if (this.showCardoption && this.isDialogFormValid) {
      if (!this.paymentProfile) {
        //this.createCustomerProfile();
        this.singularCreateProfile();
      } else if (this.flagAddNewCard) {
        //this.createCustomerPaymentProfile();
        this.singularCreateProfile();
      } else {
        //this.chargeCustomerProfile();
        this.singularProcessPayment(this.paymentProfile, false);
      }
    } else {
      this.closePaymentForm(false);
    }
  }

  clearData() {
    if (this.isPreviewMode) return;
    this.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
    this.isApplyDiscount = false;
    this.isPaymentFlowRequired = false;
    this.selectedProjectMasterIds = '';
    this.grandBillableAmt = 0;
    this.totalNumberPaymentUnapplied = 0;
    this.clientProfile = null;
    this.updateDialogForm();
    const periodType = this.manualInvoiceForm.controls['datePeriodTypes'].value;
    const startDateValue = this.manualInvoiceForm.controls['InvoiceStartDate'].value;
    const endDateValue = this.manualInvoiceForm.controls['InvoiceEndDate'].value;
    const staffIdValue = this.manualInvoiceForm.controls['staffId'].value;

    const invoiceOldDate = this.manualInvoiceForm.controls['InvoiceDate'].value;

    this.manualInvoiceForm.reset();
    this.client = '';
    this.selClient = '';
    this.isClientSelected = false;
    this.clientRouteUrl = '';
    this.NextInvoiceNumber = '';
    this.IsEnableHistory = true;
    this.descCount = 0;
    this.manualInvoiceForm.controls['datePeriodTypes'].setValue(periodType);
    this.manualInvoiceForm.controls['InvoiceStartDate'].setValue(new Date(startDateValue));
    this.manualInvoiceForm.controls['InvoiceEndDate'].setValue(new Date(endDateValue));
    this.manualInvoiceForm.controls['staffId'].setValue(staffIdValue);

    this.manualInvoiceForm.controls['InvoiceDate'].setValue(
      new Date(moment(new Date(invoiceOldDate)).format('YYYY-MM-DDT00:00:00').toString())
    );

    this.manualInvoiceForm.controls['FinalizeAction'].setValue('Print');
    this.selectedClientId = null;
    this.IsDateTypeReadOnly = true;
    this.manualInvoiceForm.controls['DiscountValue'].setValue(0.0);
    this.activitiesType = 'All Engagements';
    this.activitiesTypeId = null;
    this.lineItems = [];
    this.timeExpenseRecords = [];
    this.expensivestotal = '$0.00';
    this.totalBillableHrs = 0;
    this.totalStandardAmount = 0;
    this.totalNonBillableAmt = 0;
    this.grandInvAmt = 0;
    this.grandExpenseAmt = 0;

    this.grandBillableTime = 0;
    this.grandNonBillableAmt = 0;
    this.grandBillableAmt = 0;
    this.grandWUWDAmt = 0;
    this.grandInvAmt = 0;
    this.grandBillableTime = 0;

    this.totalBillableSummaryHrs = '$0.00';
    this.lessDiscountValue = '$0.00';
    this.invoiceAmount = '$0.00';
    this.invoiceTax = 0;
    this.grnadInvoiceAmt = '0.00';
    this.invoiceLessPayments = '$0.00';
    this.totalUnappliedAmount = 0;
    this.invoiceBalance = '$0.00';
    this.amount_color = '#0e0e0e';
    this.discount_color = '#0e0e0e';
    this.lessPayments_color = '#0e0e0e';
    this.expensesItems = [];
    this.openRetainersDataSource = [];
    this.copyInvoicesDataSource = [];
    //this.openRetainersDataSource = this.mangoUtils.deepCloneObject(this.copyInvoicesDataSource);
    //this.addRow();
    this.footGroupTotalsForRetainer();
    this.calculateSummary();
    if (this.isRedirect) this.router.navigate(['/billing-invoicing/invoiceReview']);
    else {
      this.clientAutocomplete.inputEL.nativeElement.focus();
    }
    this.isRedirect = false;
    setTimeout(() => {
      this.transactionData = null;
    }, 1500);
  }

  saveClientSettingsData(clientId) {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.invoiceOpt['customerTermId'] = parent.manualInvoiceForm.get('selectedTerm').value;
    //parent.invoiceOpt["User7Data"] = parent.loginSerialNumber;
    parent.mangoAPISrvc.saveSettingData(clientId, parent.invoiceOpt).subscribe(settingData => {
      setTimeout(() => {
        parent.clearData();
        parent.mangoAPISrvc.showLoader(false);
      }, 1600);
    });

    if (this.loginCompanyId == 1 && parent.invoiceOpt.invoiceTemplate == 8) {
      parent.mangoAPISrvc
        .updateLaserSoftwareUpdate(clientId, { serialNo: parent.loginSerialNumber })
        .subscribe(settingData => {});
    }
  }

  getAllStaffList() {
    const parent = this;
    parent.AllStaffsTypes = [{ label: 'Select Staff', value: -1 }];
    parent.AllStaffsServiceTypes = [{ label: 'Select Staff', value: -1 }];
    let tempList = [];
    if (parent.auth.isAllowAccess(14)) {
      tempList = parent.encrDecSrvc.getObject(AppConstants.allStaffList);
    } else {
      tempList = parent.encrDecSrvc.getObject(AppConstants.staffList);
      tempList = tempList?.filter(
        staff => staff?.StaffID == parent.encrDecSrvc.getObject(AppConstants.staffID)
      );
    }
    for (let i = 0; i < tempList.length; i++) {
      if (tempList[i].Inactive) continue;

      const obj = {};
      obj['label'] = tempList[i]['StaffName'];
      obj['value'] = tempList[i]['StaffID'];
      obj['StaffID'] = tempList[i]['StaffID'];
      obj['StaffName'] = tempList[i]['StaffName'];
      parent.AllStaffsTypes.push(obj);
      parent.AllStaffsServiceTypes.push(obj);
    }
    //parent.AllStaffsTypes.sort(parent.mangoUtils.compareValues('label', 'asc'));
    //parent.AllStaffsServiceTypes.sort(parent.mangoUtils.compareValues('label', 'asc'));
  }

  loadDefaults() {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.loadManualInvoice(parent.loginCompanyId).subscribe((data: any) => {
      parent.getTerms(data[0]);
      parent.getCompanyInfo(data[1][0]);
      parent.mangoAPISrvc.showLoader(false);
    }),
      error => {
        parent.mangoAPISrvc.notify(
          'error',
          this.translate.instant('error'),
          AppConstants.fetchErrorMsg
        );
        parent.mangoAPISrvc.showLoader(false);
      };
  }

  getTerms(termsData) {
    const parent = this;
    for (let i = 0; i < termsData.length; i++) {
      if (!termsData[i].Inactive) {
        parent.termsList.push({
          label: termsData[i].TermsDescription,
          value: termsData[i].TermsID
        });
      }
    }
    parent.termsList.sort(parent.mangoUtils.compareValues('label', 'desc'));
  }

  getCompanyInfo(obj) {
    const parent = this;
    parent.mangoCompanyData = obj;
    parent.myAchReceiptsForm.controls['MerchantID'].setValue(
      parent.mangoCompanyData['CCmerchantID']
    );
    parent.myAchReceiptsForm.controls['Login'].setValue(parent.mangoCompanyData['CClogin']);
    parent.myAchReceiptsForm.controls['Password'].setValue(parent.mangoCompanyData['CCpassword']);

    parent.setStaxOption();
  }

  updateMangoBillingFlag() {
    const parent = this;
    parent.mangoCompanyData['EmailPaymentPortal'] = parent.mangoCompanyData['EmailPaymentPortal'];
    parent.mangoCompanyData['EmailPaymentMango'] = parent.mangoCompanyData['EmailPaymentMango'];
    parent.mangoCompanyData['EmailCalendarEvent'] = parent.mangoCompanyData['EmailCalendarEvent'];
    parent.mangoCompanyData['EmailFinishDDM'] = parent.mangoCompanyData['EmailFinishDDM'];
    parent.mangoCompanyData['phone'] = parent.mangoCompanyData['Telephone'];
    parent.mangoCompanyData['email'] = parent.mangoCompanyData['EmailCompany'];
    parent.mangoCompanyData['state'] = parent.mangoCompanyData['State'];
    parent.mangoCompanyData['country'] = parent.mangoCompanyData['Country'];
    parent.mangoCompanyData['companyName'] = parent.mangoCompanyData['CompanyName'];
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.updateCompanyView(parent.mangoCompanyData).subscribe(response => {
      parent.mangoAPISrvc.notify(
        'success',
        this.translate.instant('Success_notify'),
        AppConstants.updateMsg
      );
      parent.mangoAPISrvc.showLoader(false);
    });
  }

  getProjects(clientId) {
    const parent = this;
    parent.activitiesTypes = [];
    parent.filteredEngagementsList = [];
    if (!clientId) {
      return false;
    }
    //parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getProjectsByClientId(clientId).subscribe(function (data: any) {
      const filterData = data.filter(note => note.Inactive == false);
      for (let i = 0; i < filterData.length; i++) {
        parent.filteredEngagementsList.push({
          label: filterData[i].EngagementName,
          value: filterData[i].ProjectMasterID,
          ProjectMasterID: filterData[i].ProjectMasterID,
          Description: filterData[i].EngagementInvoiceDescription,
          BillingMethod: filterData[i].BillingMethod,
          FlatFeeAmount: filterData[i].FlatFeeAmount,
          EngagementTypeID: filterData[i].EngagementTypeID,
          isTaxable: filterData[i].isTaxable,
          isCalcTaxNoTime: filterData[i].isCalcTaxNoTime
        });
        parent.activitiesTypes.push({
          label: filterData[i].EngagementName,
          value: filterData[i].ProjectMasterID,
          ProjectMasterID: filterData[i].ProjectMasterID,
          Description: filterData[i].EngagementInvoiceDescription,
          BillingMethod: filterData[i].BillingMethod,
          FlatFeeAmount: filterData[i].FlatFeeAmount,
          EngagementTypeID: filterData[i].EngagementTypeID,
          isTaxable: filterData[i].isTaxable,
          isCalcTaxNoTime: filterData[i].isCalcTaxNoTime
        });
      }
      parent.activitiesTypes.sort(parent.mangoUtils.compareValues('label', 'asc'));
      parent.filteredEngagementsList.sort(parent.mangoUtils.compareValues('label', 'asc'));

      if (parent.filteredEngagementsList.length == 0) {
        //parent.mangoAPISrvc.isFormChanged = false;
        Swal.fire({
          title: parent.translate.instant('Client_Engagements_Required'),
          html: parent.translate.instant('billing.there_are_no_engagements_setup'),
          icon: 'warning',
          showCancelButton: true,
          allowEscapeKey: false,
          allowEnterKey: false,
          confirmButtonText: parent.translate.instant('Go_to_Client'),
          cancelButtonText: parent.translate.instant('no_cancel')
        }).then(result => {
          if (result.value) {
            parent.encrDecSrvc.addObject(AppConstants.selectedClientRecord, parent.selClient);
            parent.encrDecSrvc.addObject(AppConstants.clientID, parent.selClient['ClientID']);
            parent.encrDecSrvc.addObject(AppConstants.ClientName, parent.selClient['ClientName']);
            parent.mangoAPISrvc.showLoader(true);
            parent.mangoAPISrvc
              .getAllDataCounts(parent.selClient['ClientID'])
              .subscribe(function (data) {
                parent.encrDecSrvc.addObject(AppConstants.allDataCountsForClient, data);
                parent.mangoAPISrvc.fireClientView(true);
                parent.router.navigate([
                  AppConstants.clientRoutePath + '/' + AppConstants.viewRoutePath
                ]);
                //parent.mangoAPISrvc.showLoader(false);
              });
          } else {
            parent.encrDecSrvc.addObject(AppConstants.clientID, -1);
            parent.router.navigate([
              AppConstants.clientRoutePath + '/' + AppConstants.viewRoutePath
            ]);
            parent.mangoAPISrvc.fireClientView(true);
          }
        });
      }
    }),
      error => {
        parent.mangoAPISrvc.notify(
          'error',
          parent.translate.instant('error'),
          AppConstants.updateErrorMsg
        );
        //parent.mangoAPISrvc.showLoader(false);
      };
  }
  doMoneyFormate(data: any, property) {
    const myNumeral = numeral(data[property]);
    if (myNumeral.value() === null) {
      data[property] = 0.0;
    }
    data[property] = '$' + numeral(myNumeral.value()).format('0,0.00');
  }
  calculateWUWD(value: any, data: any, doNotDistributeInvoice?: boolean, isFixed?: boolean) {
    const myNumeral = numeral(value);
    if (myNumeral.value() === null) {
      data['amount'] = 0.0;
    }
    data['amount'] = myNumeral.value();
    this.calculateAllWUWDTotals(doNotDistributeInvoice, isFixed);
  }
  calculateAllWUWDTotals(doNotDistributeInvoice?: boolean, isFixed?: boolean, isInitial?: boolean) {
    this.invoiceTax = 0;
    this.calculateSalesTaxes();
    for (let i = 0; i < this.selectedItems.length; i++) {
      const projectId = this.selectedItems[i]['ProjectMasterID'];
      // get line items List with same projectId
      const lineItemsWithMatchedProjectIds = this.lineItems.filter(
        item => item['ProjectMasterID'] == projectId
      );
      const sumupAllAmount = lineItemsWithMatchedProjectIds.reduce(function (a, b) {
        return a + +numeral(b['amount']).value();
      }, 0);
      const sumupAllDiscount = lineItemsWithMatchedProjectIds.reduce(function (a, b) {
        return a + +numeral(b['discount']).value();
      }, 0);
      const seletedParentItem = this.selectedItems.filter(
        item => item['ProjectMasterID'] == projectId
      );
      seletedParentItem[0]['invoiceAmount'] = sumupAllAmount - sumupAllDiscount;

      seletedParentItem[0]['totalService'] = sumupAllAmount;

      seletedParentItem[0]['writeupValue'] =
        sumupAllAmount -
        sumupAllDiscount -
        seletedParentItem[0]?.allSelectedTimeSlips?.reduce((a, b) => {
          return a + +numeral(b?.BilledAmount || 0).value();
        }, 0);
      !doNotDistributeInvoice &&
        this.distributeInvoiceAmount(
          { ...seletedParentItem[0], amount: sumupAllAmount },
          seletedParentItem[0]['BillingMethod'] === 'Hourly' ? isFixed : true,
          isInitial
        );
    }
    this.computeFooter();
    this.computeExpenseFooter();
    this.validateLineItems();
    this.calculateFooters();
    this.calculateSummary();
  }

  calculateFooters() {
    this.grandBillableExpenseAmt = this.selectedItems.reduce(function (a, b) {
      return a + +numeral(b['grandExpenseTotalAmt']).value();
    }, 0);
    this.grandExpenseAmt = this.selectedItems.reduce(function (a, b) {
      return a + +numeral(b['expenseamount']).value();
    }, 0);
    this.grandBillableTime = this.selectedItems.reduce(function (a, b) {
      return a + +numeral(b['totaltime']).value();
    }, 0);
    this.grandNonBillableAmt = this.selectedItems.reduce(function (a, b) {
      return a + +numeral(b['nonbillableamount']).value();
    }, 0);
    this.grandBillableAmt = this.selectedItems.reduce(function (a, b) {
      return a + +numeral(b['laboramount']).value();
    }, 0);
    this.grandWUWDAmt = this.selectedItems.reduce(function (a, b) {
      return a + +numeral(b['writeupValue']).value();
    }, 0);

    this.grandInvAmt = this.selectedItems.reduce(function (a, b) {
      return a + +numeral(b['totalService']).value();
    }, 0);
  }

  singleUncheck(event: any, data: any, parentRow: any) {
    event.preventDefault();
    event.stopPropagation();
    event.cancelBubble = true;
    const totals = 0;
    const nonBillabletotals = 0;
    this.checkHeaderCheckbox(parentRow);
    this.calculateSummary();
    this.dataTableComponent.selection = [...this.selectedItems];
    this.calculateSalesTaxes();
  }

  setWriteUpDownColor(data) {
    let colorStr = '#1f0c0c';
    if (data < 0) {
      colorStr = '#f28686';
    } else if (data > 0) {
      colorStr = '#33c126';
    }
    return colorStr;
  }

  sendMailtoCompany(amt, toArr, Client, paymentHeaderObj) {
    const parent = this;
    const curUser = this.encrDecSrvc.getObject(AppConstants.userName);
    const companysendInBlueObj = {
      sender: { name: curUser, email: environment.EMAIL_RETURN_SENDER },
      to: [{ email: '', name: '' }],
      //replyTo: { email: environment.EMAIL_RETURN_SENDER },
      replyTo: { email: environment.EMAIL_RETURN_SENDER },
      templateId: 17,
      params: {}
    };
    const toObj = { email: '', name: '' };
    if (toArr.length == 0) {
      toObj['email'] = toObj['name'] = parent.mangoCompanyData['EmailCompany'];
      toArr.push(toObj);
    }

    amt = '$' + numeral(amt).format('0,0.00');

    companysendInBlueObj.to = toArr;
    //companysendInBlueObj.bcc = toArr;
    companysendInBlueObj.params['COMPANYNAME'] = Client['ClientName'];
    companysendInBlueObj.params['PAYMENTAMOUNT'] = '$' + numeral(amt).format('0,0.00');
    companysendInBlueObj.params['PAYMENT_TYPE'] = paymentHeaderObj['PaymentType'];
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.sendSMTPEmail(companysendInBlueObj).subscribe(
      data => {
        parent.mangoAPISrvc.showLoader(false);
        parent.mangoAPISrvc.notify('success', 'Notification', 'Email sent successfully');
      },
      err => {
        // parent.mangoAPISrvc.showLoader(false);
      }
    );
  }

  sendToCompany(amt, Client, paymentHeaderObj) {
    if (this.mangoCompanyData['MangoPaymentNotification']?.length > 0) {
      this.mangoAPISrvc.getListOfUsers().subscribe((response: any) => {
        if (response?.length > 0) {
          const toArr = [];
          response.forEach(staff => {
            if (
              this.mangoCompanyData['MangoPaymentNotification'].includes(staff.StaffID.toString())
            ) {
              if (staff.Email) {
                toArr.push({ email: staff.Email, name: staff.StaffName });
              }
            }
          });
          this.sendMailtoCompany(amt, toArr, Client, paymentHeaderObj);
        }
      });
    }
  }

  sendMailToCustmor(amt, paymentHeaderID) {
    const parent = this;
    if (!parent.clientProfile['Email'] || parent.mangoCompanyData['EmailPaymentMango'] == false) {
      return false;
    }
    const sendInBlueObj = {};
    let toObj = { email: '', name: '' };
    const toArr = [];
    const tags = [];

    tags.push(parent.clientProfile['ClientID'].toString());
    tags.push('none');
    tags.push(paymentHeaderID.toString());
    tags.push(parent.loginCompanyId.toString());
    tags.push(parent.clientProfile['ClientName']);

    parent.clientProfile['ContactPerson'] = parent.clientProfile['ContactPerson']
      ? parent.clientProfile['ContactPerson']
      : '';

    for (let index = 0; index < this.allEmailContacts.length; index++) {
      const element = this.allEmailContacts[index];
      toObj = { email: '', name: '' };
      toObj['name'] = element['ContactName'] ? element['ContactName'] : element['Email'];
      toObj['email'] = element['Email'];
      toArr.push(toObj);
    }

    const senderObj = { name: '', email: '' };
    senderObj['name'] = parent.companyName;
    senderObj['email'] = environment.EMAIL_RETURN_SENDER;
    // senderObj["email"] = parent.emailCompany;
    sendInBlueObj['replyTo'] = { email: environment.EMAIL_RETURN_SENDER };
    sendInBlueObj['subject'] = 'Payment Received';
    sendInBlueObj['tags'] = tags;
    sendInBlueObj['sender'] = senderObj;
    sendInBlueObj['sender.name'] = parent.companyName;
    sendInBlueObj['sender.email'] = environment.EMAIL_RETURN_SENDER;
    //sendInBlueObj["to"] = [{ email: parent.emailCompany, name: parent.companyName }];
    sendInBlueObj['to'] = parent.mangoUtils.deduplicateEmail(toArr);

    amt = '$' + numeral(amt).format('0,0.00');
    parent.mangoAPISrvc.showLoader(true);
    const customer = this.clientProfile;
    this.mangoAPISrvc
      .getEmailTemplateByTypeAndCompany('cashReceipt', this.loginCompanyId)
      .subscribe(result => {
        if (!result['EmailTemplateID']) {
          this.cashReceiptEmailTemplate = this.mangoUtils.getDefaultTemplate(
            'cashReceipt',
            this.loginCompanyId
          );
        } else {
          this.cashReceiptEmailTemplate = result;
        }
        let htmlContent = this.mangoUtils.getCashReceiptEmailTemplate();
        sendInBlueObj['subject'] = this.cashReceiptEmailTemplate['EmailSubject'];
        const formData = this.myPaymentForm.value;
        if (this.mangoCompanyData.isSurchargeEnabled && formData.SurChargeAmount > 0) {
          this.cashReceiptEmailTemplate['htmlBody'] +=
            `</br></br><div style="text-align: center;font-size: 17px;color: #cb0000;">Transaction subject to a surcharge in the amount of <strong>$${formData.SurChargeAmount}</strong>.</div>`;
        }
        htmlContent = this.mangoUtils.replaceAll(
          htmlContent,
          '%InjectTemplate%',
          this.cashReceiptEmailTemplate['htmlBody']
        );
        htmlContent = this.mangoUtils.replaceAll(
          htmlContent,
          '%ContactName%',
          customer['ContactPerson']
        );
        htmlContent = this.mangoUtils.replaceAll(htmlContent, '%YourFirmName%', this.companyName);
        htmlContent = this.mangoUtils.replaceAll(
          htmlContent,
          '%ClientName%',
          customer['ClientName']
        );
        htmlContent = this.mangoUtils.replaceAll(htmlContent, '%Amount%', amt);
        htmlContent = this.mangoUtils.replaceAll(htmlContent, '%Email%', customer['Email']);
        sendInBlueObj['htmlContent'] = htmlContent;

        sendInBlueObj['subject'] = this.mangoUtils.replaceAll(
          sendInBlueObj['subject'],
          '%ClientName%',
          customer['ClientName']
        );

        this.mangoAPISrvc.sendSMTPEmail(sendInBlueObj).subscribe(data => {
          this.mangoAPISrvc.notify(
            'success',
            this.translate.instant('notification'),
            this.translate.instant('email-sent-successfully')
          );
          parent.mangoAPISrvc.showLoader(false);
        });
      });
  }

  prepareInvoiceDesc() {
    this.manualInvoiceForm.controls['DescriptionShort'].setValue('');
    let appendedDesc = '';
    for (let index = 0; index < this.lineItems.length; index++) {
      const element = this.lineItems[index];
      if (element['description']) {
        if (appendedDesc) {
          appendedDesc += ' | ' + element['description'];
        } else {
          appendedDesc = element['description'] ? element['description'] : '';
        }
        this.manualInvoiceForm.controls['DescriptionShort'].setValue(appendedDesc);
      }
    }

    this.manualInvoiceForm.controls['DescriptionShort'].setValue(
      this.mangoUtils.replaceCaretTemplate(
        this.manualInvoiceForm.controls['DescriptionShort'].value
      )
    );
    this.projectMastersArray = [];
    this.projectMastersArray = this.lineItems.map(item => {
      item['ProjectMasterID'] = item['ProjectMasterID'] ? item['ProjectMasterID'] : -1;
      return item['ProjectMasterID'];
    });
    this.selectedProjectMasterIds = this.projectMastersArray.toString();
    this.charCount();
  }

  dynamicNewLineItem(obj, isInitial?) {
    const lineObj = {};
    lineObj['uuid'] = this.mangoUtils.generateUUID();
    lineObj['StaffID'] = this.resourceId;
    lineObj['EngagementName'] = obj['EngagementName'] ? obj['EngagementName'] : null;
    lineObj['ProjectMasterID'] = obj['ProjectMasterID'] ? obj['ProjectMasterID'] : null;
    lineObj['EngagementTypeID'] = obj['EngagementTypeID'] ? obj['EngagementTypeID'] : null;
    lineObj['description'] = obj['EngagementInvoiceDescription']
      ? obj['EngagementInvoiceDescription']
      : null;

    const label = this.AllStaffsTypes.filter(item => item['value'] == lineObj['StaffID']);
    if (label.length > 0) {
      lineObj['StaffName'] = label[0]['label'];
    } else {
      lineObj['StaffName'] = '';
    }
    if (this.activitiesTypes.length == 1) {
      lineObj['ProjectMasterID'] = this.activitiesTypes[0].value;
      lineObj['EngagementName'] = this.activitiesTypes[0].label;
      lineObj['EngagementTypeID'] = this.activitiesTypes[0].EngagementTypeID;
      lineObj['description'] = this.activitiesTypes[0].Description;
    }

    const activity = this.activitiesTypes.filter(item => {
      return item.ProjectMasterID == lineObj['ProjectMasterID'];
    })[0];

    lineObj['amount'] =
      obj['BillingMethod'] == 'Hourly'
        ? obj['laboramount']
        : obj['FlatFeeAmount']
          ? obj['FlatFeeAmount']
          : activity?.FlatFeeAmount;
    lineObj['amount'] =
      '$' + (lineObj['amount'] ? numeral(lineObj['amount']).format('0,0.00') : '0.00');
    lineObj['discount'] = '$0.00';
    lineObj['PreviousAmount'] = lineObj['amount'];
    lineObj['invoiceTax'] = 0;
    lineObj['companytaxRate'] = 0;
    lineObj['StaffTitleID'] = 0;
    lineObj['StaffDeptID'] = 0;
    lineObj['PercentBilling'] = 0;
    lineObj['PercentBit'] = false;
    lineObj['isBillingHistoryPresents'] = false;
    lineObj['ActivityBillingHistoryID'] = '';
    lineObj['isNewRow'] = obj.isNewRow;
    lineObj['ExpenseAmount'] = obj.expenseamount;
    lineObj['description'] = lineObj['description']
      ? this.mangoUtils.replaceCaretTemplate(lineObj['description'])
      : activity?.Description
        ? this.mangoUtils.replaceCaretTemplate(activity.Description)
        : '';
    this.getStaffInfo(lineObj);
    this.lineItems.push(lineObj);
    this.dt.editingRowKeys[lineObj['uuid']] = false;
    if (!isInitial) {
      setTimeout(() => {
        this.onRowEditSave(null, lineObj, 'InvoiceItems');
      }, 1000);
    }
    this.prepareInvoiceDesc();
    this.validateLineItems();
  }

  updateExpenseRollUp(event, parentRecord) {
    const selectedLineItem = this.lineItems.filter(
      item => item['ProjectMasterID'] == parentRecord.ProjectMasterID
    );
    parentRecord['IsExpenseRollUp'] = event.checked;
    selectedLineItem.map(function (obj) {
      obj['IsExpenseRollUp'] = event.checked;
    });
  }

  refreshTimeandExpenseRecords() {
    const parent = this;
    parent.lineItems = [];
    parent.lineItems.length = 0;
    parent.grandBillableAmt = 0;
    parent.totalNumberPaymentUnapplied = 0;
    const formObj = parent.manualInvoiceForm.value;
    parent.encrDecSrvc.addObject(AppConstants.manualLastDateThru, formObj['InvoiceStartDate']);
    formObj['InvoiceStartDate'] = moment(formObj['InvoiceStartDate']).format('MM-DD-YYYY');
    formObj.staffId = formObj.staffId ? formObj.staffId : null;
    parent.selEngagementType =
      parent.selEngagementType && parent.selEngagementType.length > 0
        ? parent.selEngagementType
        : null;
    parent.timeExpenseRecords = [];
    parent.selectedItems = [];
    if (!this.selClient['ClientID']) {
      return false;
    }
    parent.mangoAPISrvc.showLoader(true);

    parent.mangoAPISrvc
      .SlipMasterbyCompanyIDLevel1New(
        this.selClient['ClientID'],
        // formObj.staffId,
        formObj.staffId == -1 ? null : formObj.staffId,
        parent.selEngagementType,
        formObj['InvoiceStartDate']
      )
      .subscribe(
        (leverOne: any) => {
          if (leverOne.parent.length == 0) {
            parent.selectedItems = [];
            parent.calculateFooters();
            parent.addRow();
            parent.displaySideBar = false;
            return false;
          }

          leverOne.parent = leverOne.parent.map(function (obj) {
            obj['SelectForBilling'] = true;
            obj['writeupValue'] = 0.0;
            obj['invoiceAmount'] = 0.0;
            obj['laboramount'] = obj['laboramount'] ? numeral(obj['laboramount']).value() : 0.0;
            obj['nonbillableamount'] = obj['nonbillableamount']
              ? numeral(obj['nonbillableamount']).value()
              : 0.0;
            obj['expenseamount'] = obj['expenseamount']
              ? numeral(obj['expenseamount']).value()
              : 0.0;
            obj['totaltime'] = obj['totaltime'] ? numeral(obj['totaltime']).value() : 0;
            obj['IsExpenseRollUp'] = parent.mangoCompanyData['AutoRollupExpenses'];
            obj['hasChildrens'] = false;
            obj['isSelected'] = true;
            obj['isNewRow'] = false;
            obj['uuid'] = parent.mangoUtils.generateUUID();
            parent.dynamicNewLineItem(obj, true);
            return obj;
          });

          leverOne.parent.map(item => {
            parent.updateExpenseRollUp(
              { checked: parent.mangoCompanyData['AutoRollupExpenses'] },
              item
            );
          });

          parent.timeExpenseRecords = [...leverOne.parent];
          parent.selectedItems = [...leverOne.parent];

          for (let i = 0; i < parent.timeExpenseRecords.length; i++) {
            let allRecords = leverOne.childs.filter(
              item => item['ProjectMasterID'] == parent.timeExpenseRecords[i].ProjectMasterID
            );
            allRecords = allRecords.map(function (obj) {
              obj['SelectForBilling'] = true;
              obj['displayDate'] = moment(obj.Ddate.substr(0, 10)).format('MM/DD/YYYY');
              return obj;
            });

            parent.timeExpenseRecords[i]['allTimeSlips'] = allRecords.filter(
              item => item['IsTimeRecord'] == 'T'
            );
            parent.timeExpenseRecords[i]['allSelectedTimeSlips'] = [
              ...allRecords.filter(item => item['IsTimeRecord'] == 'T')
            ];
            parent.timeExpenseRecords[i]['allExpenseSlips'] = allRecords.filter(
              item => item['IsTimeRecord'] == 'X'
            );

            parent.timeExpenseRecords[i]['allExpenseSlips'].forEach(item => {
              item.BilledAmount = item.Billable ? item.StandardAmount : 0;
              item.WriteUpDown = item.Billable
                ? 0
                : parent.mangoUtils.subtractFloat(item.BilledAmount, item.StandardAmount);
            });

            parent.timeExpenseRecords[i]['allSelectedExpenseSlips'] = [
              ...allRecords.filter(item => item['IsTimeRecord'] == 'X')
            ];

            parent.timeExpenseRecords[i]['allSelectedExpenseSlips'].forEach(item => {
              item.BilledAmount = item.Billable ? item.StandardAmount : 0;
              item.WriteUpDown = item.Billable
                ? 0
                : parent.mangoUtils.subtractFloat(item.BilledAmount, item.StandardAmount);
            });

            parent.timeExpenseRecords[i]['grandExpenseTotalAmt'] = parent.timeExpenseRecords[i][
              'allExpenseSlips'
            ].reduce(function (a, b) {
              return a + +numeral(b['StandardAmount']).value();
            }, 0);

            parent.timeExpenseRecords[i]['grandExpenseHrs'] = parent.timeExpenseRecords[i][
              'allTimeSlips'
            ].reduce(function (a, b) {
              return a + +numeral(b['TotalTime']).value();
            }, 0);
            parent.timeExpenseRecords[i]['grandBillableamount'] = parent.timeExpenseRecords[i][
              'allTimeSlips'
            ].reduce(function (a, b) {
              return a + +numeral(b['billableamount']).value();
            }, 0);
            parent.timeExpenseRecords[i]['grandNonbillableamount'] = parent.timeExpenseRecords[i][
              'allTimeSlips'
            ].reduce(function (a, b) {
              return a + +numeral(b['nonbillableamount']).value();
            }, 0);

            parent.timeExpenseRecords[i]['grandWUWDamount'] = parent.timeExpenseRecords[i][
              'allTimeSlips'
            ].reduce(function (a, b) {
              return a + +numeral(b['WriteUpDown']).value();
            }, 0);
            parent.timeExpenseRecords[i]['grandBilledamount'] = parent.timeExpenseRecords[i][
              'allTimeSlips'
            ].reduce(function (a, b) {
              return a + +numeral(b['BilledAmount']).value();
            }, 0);

            if (
              parent.timeExpenseRecords[i]['allTimeSlips'].length > 0 ||
              parent.timeExpenseRecords[i]['allExpenseSlips'].length > 0
            ) {
              parent.timeExpenseRecords[i]['hasChildrens'] = true;
            }
          }

          if (parent.selectedParent) {
            const indexSelItems = parent.timeExpenseRecords.findIndex(
              item => item.ProjectMasterID == parent.selectedParent.ProjectMasterID
            );

            parent.selectedParent = parent.timeExpenseRecords[indexSelItems];

            if (!parent.selectedParent) parent.displaySideBar = false;
          }

          parent.calculateAllWUWDTotals(false, false, true);
        },
        err => parent.mangoAPISrvc.showLoader(false)
      );
  }

  syncSelectedItems(parentRow) {
    const indexSelItems = this.selectedItems.findIndex(
      item => item.ProjectMasterID == parentRow.ProjectMasterID
    );

    this.selectedItems[indexSelItems] = parentRow;

    const indexSelTimeExpense = this.timeExpenseRecords.findIndex(
      item => item.ProjectMasterID == parentRow.ProjectMasterID
    );

    this.timeExpenseRecords[indexSelTimeExpense]['allSelectedTimeSlips'] =
      parentRow.allSelectedTimeSlips;
    this.timeExpenseRecords[indexSelTimeExpense]['allSelectedExpenseSlips'] =
      parentRow.allSelectedExpenseSlips;
  }

  checkHeaderCheckbox(parentRow: any) {
    const index = this.selectedItems.findIndex(
      item => item.ProjectMasterID == parentRow.ProjectMasterID
    );
    this.syncSelectedItems(parentRow);

    if (!parentRow.allSelectedExpenseSlips && !parentRow.allSelectedTimeSlips) {
      this.selectedItems.splice(index, 1).slice(0); // remove parent row from parent selection
    } else if (
      (parentRow.allSelectedExpenseSlips && parentRow.allSelectedExpenseSlips.length > 0) ||
      (parentRow.allSelectedTimeSlips && parentRow.allSelectedTimeSlips.length > 0)
    ) {
      if (index === -1) {
        parentRow.isSelected = true;
        this.selectedItems = this.selectedItems.concat(parentRow);
      }
    }
    // adding line items
    for (let selectedIndex = 0; selectedIndex < this.selectedItems.length; selectedIndex++) {
      const selecedElement = this.selectedItems[selectedIndex];
      const lineFindIndex = this.lineItems.findIndex(
        item => item.ProjectMasterID == selecedElement.ProjectMasterID
      );
      if (lineFindIndex == -1) {
        this.dynamicNewLineItem(selecedElement);
      }
    }

    const selExpenseRecord = [];
    for (let i = 0; i < parentRow.allSelectedExpenseSlips.length; i++) {
      const isIncluded = parentRow.allExpenseSlips.filter(
        itm => itm.SlipMasterID === parentRow.allSelectedExpenseSlips[i].SlipMasterID
      );
      if (isIncluded.length > 0) selExpenseRecord.push(isIncluded[0]);
    }
    parentRow['expenseamount'] = selExpenseRecord.reduce(function (a, b) {
      return a + +numeral(b['BilledAmount']).value();
    }, 0);
    this.calculateSalesTaxes();
    this.prepareInvoiceDesc();
    // this.calculteParentFooterTotals();
    this.calculateInvoiceLineAmt(
      parentRow.ProjectMasterID,
      this.getProjectInvoiceAmount(parentRow.ProjectMasterID)
    );
    this.calculateAllWUWDTotals(true);
    this.calculteParentFooterTotals();
    // this.calculateSummary();
  }

  calcEngagementTimeSalesTax(values): void {
    this.engagementLevelTimeTax = 0;
    if (
      !this.mangoCompanyData.ActivateLaborRates ||
      !this.salesTax.Labor ||
      numeral(this.salesTax.Labor).value() == 0
    )
      return;

    if (values?.length > 0) {
      let taxableAmtService = 0;
      for (let n = 0; n < values.length; n++) {
        const record = values[n];
        const selectedProject = this.activitiesTypes?.find(
          item => item.ProjectMasterID == record.ProjectMasterID
        );

        if (selectedProject?.isTaxable == null || selectedProject?.isTaxable) {
          record.BilledAmount = record.BilledAmount ? record.BilledAmount : 0;
          if (record.sctaxable == true) {
            taxableAmtService += numeral(record.BilledAmount).value();
          }
        }
      }

      this.engagementLevelTimeTax = this.roundOffDecimals(
        taxableAmtService * (this.salesTax.Labor / 100)
      );
      return;
    } else return;
  }

  calcEngagementExpSalesTax(values): void {
    this.engagementLevelExpTax = 0;
    if (
      !this.mangoCompanyData.ActivateExpenseRates ||
      !this.salesTax.Expense ||
      numeral(this.salesTax.Expense).value() == 0
    )
      return;

    if (values?.length > 0) {
      let taxableAmtExpense = 0;
      for (let n = 0; n < values.length; n++) {
        const record = values[n];

        record.BilledAmount = record.BilledAmount ? record.BilledAmount : 0;
        if (record.ectaxable == true && record.IsTaxable == true) {
          taxableAmtExpense += numeral(record.BilledAmount).value();
        }
        if (record.ectaxable == true && record.IsTaxable == undefined) {
          taxableAmtExpense += numeral(record.BilledAmount).value();
        }
      }

      this.engagementLevelExpTax = this.roundOffDecimals(
        taxableAmtExpense * (this.salesTax.Expense / 100)
      );
      return;
    } else return;
  }

  calculateSalesTaxes() {
    // only timeslips
    this.invoiceTax = 0;
    this.salesTax.serviceTax = 0;
    this.salesTax.expenseTax = 0;
    this.salesTax.taxableAmtExpense = 0;
    this.salesTax.taxableAmtService = 0;

    for (let i = 0; i < this.selectedItems.length; i++) {
      const parentRow = this.selectedItems[i];
      const selectedProject = this.activitiesTypes?.find(
        item => item.ProjectMasterID == parentRow.ProjectMasterID
      );

      if (selectedProject?.isTaxable == null || selectedProject?.isTaxable) {
        if (this.mangoCompanyData.ActivateLaborRates) {
          if (parentRow.allSelectedTimeSlips && parentRow.allSelectedTimeSlips.length > 0) {
            for (let index = 0; index < parentRow.allSelectedTimeSlips.length; index++) {
              const element = parentRow.allSelectedTimeSlips[index];
              element.BilledAmount = element.BilledAmount ? element.BilledAmount : 0;
              if (element.sctaxable == true) {
                this.salesTax.taxableAmtService += numeral(element.BilledAmount).value();
              }
            }
          } else if (selectedProject?.isCalcTaxNoTime == null || selectedProject?.isCalcTaxNoTime) {
            this.salesTax.taxableAmtService += numeral(parentRow.invoiceAmount).value() || 0;
          }
        }

        if (this.mangoCompanyData.ActivateExpenseRates) {
          if (parentRow.allSelectedExpenseSlips) {
            for (let index = 0; index < parentRow.allSelectedExpenseSlips.length; index++) {
              const element = parentRow.allExpenseSlips?.find(
                item => item.SlipMasterID == parentRow.allSelectedExpenseSlips[index]?.SlipMasterID
              );
              if (element) {
                if (element.isExpenseChanged) {
                  element.BilledAmount = element.BilledAmount ? element.BilledAmount : 0;
                } else {
                  element.BilledAmount = element.Billable ? element.StandardAmount : 0;
                }
                // need to check if user changed taxable at the expense entry level
                if (element.ectaxable == true && element.IsTaxable == true) {
                  this.salesTax.taxableAmtExpense += numeral(element.BilledAmount).value();
                }
              }
            }
          }
        }
      }
    }
    // invoiceTax is for the Summary
    // calculate the serviceTax + expenseTax put to invoiceTax for summary
    this.salesTax.serviceTax = this.roundOffDecimals(
      this.salesTax.taxableAmtService * (this.salesTax.Labor / 100)
    );
    this.salesTax.expenseTax = this.roundOffDecimals(
      this.salesTax.taxableAmtExpense * (this.salesTax.Expense / 100)
    );
    this.invoiceTax = this.roundOffDecimals(
      this.mangoUtils.addFloat(this.salesTax.serviceTax, this.salesTax.expenseTax)
    );
  }

  calculteParentFooterTotals() {
    // loop parent selection
    for (let parentIndex = 0; parentIndex < this.timeExpenseRecords.length; ++parentIndex) {
      const parentRow = this.timeExpenseRecords[parentIndex];
      const parentSelectedItem = this.selectedItems.indexOf(parentRow);
      if (parentSelectedItem !== -1) {
        // we need to get selected Child items
        const childSelectedTimeItems = parentRow.allSelectedTimeSlips;
        const childSelectedExpensiveItems = parentRow.allSelectedExpenseSlips;
        const selExpensive = [];
        for (let i = 0; i < parentRow.allSelectedExpenseSlips.length; i++) {
          const isIncluded = parentRow.allExpenseSlips.filter(
            itm => itm.SlipMasterID === parentRow.allSelectedExpenseSlips[i].SlipMasterID
          );
          if (isIncluded.length > 0) selExpensive.push(isIncluded[0]);
        }

        parentRow['expenseamount'] = selExpensive.reduce(function (a, b) {
          return a + +numeral(b['BilledAmount']).value();
        }, 0);
        parentRow['totaltime'] = childSelectedTimeItems.reduce(function (a, b) {
          return a + +numeral(b['TotalTime']).value();
        }, 0);
        parentRow['laboramount'] = childSelectedTimeItems.reduce(function (a, b) {
          return a + +numeral(b['billableamount']).value();
        }, 0);
        parentRow['nonbillableamount'] = childSelectedTimeItems.reduce(function (a, b) {
          return a + +numeral(b['nonbillableamount']).value();
        }, 0);
      } else {
        parentRow['invoiceAmount'] = 0.0;
        parentRow['writeupValue'] = 0.0;
        // parentRow['expenseamount'] = 0.00;
        // parentRow['totaltime'] = 0.00;
        // parentRow['laboramount'] = 0.00;
        // parentRow['nonbillableamount'] = 0.00;
      }
    }
  }

  /*
    Show/Hide Editor
  */
  showHideTable(flag) {
    if (flag) {
      this.isTopShow = !this.isTopShow;
    } else {
      this.isBottomShow = !this.isBottomShow;
      if (this.isBottomShow) {
        this.replaceInvoiceCarets();
      }
    }
  }

  replaceInvoiceCarets() {
    const formObj = this.manualInvoiceForm.value;
    const data = {
      ClientName: this.selClient['ClientName'],
      InvoiceNumber: this.NextInvoiceNumber,
      InvoiceDate: formObj['InvoiceDate'],
      InvoiceAmount: this.invoiceAmount
    };
    formObj.bottomInvoiceNote = formObj.bottomInvoiceNote
      ? this.mangoUtils.replaceCaretTemplate(formObj.bottomInvoiceNote, data)
      : '';
    this.manualInvoiceForm.controls['bottomInvoiceNote'].setValue(formObj.bottomInvoiceNote);
  }
  /*
    Date Change
  */
  changeDateTypes() {
    this.IsDateTypeReadOnly = true;
    const obj = this.manualInvoiceForm.controls['datePeriodTypes'].value;
    if (obj == 'Today') {
      this.manualInvoiceForm.controls['InvoiceStartDate'].setValue(new Date());
      this.manualInvoiceForm.controls['InvoiceEndDate'].setValue(new Date());
    } else if (obj == 'Week') {
      this.manualInvoiceForm.controls['InvoiceStartDate'].setValue(
        new Date(moment().startOf('isoWeek').format())
      );
      this.manualInvoiceForm.controls['InvoiceEndDate'].setValue(
        new Date(moment().endOf('isoWeek').format())
      );
    } else if (obj == 'lastweek') {
      this.manualInvoiceForm.controls['InvoiceStartDate'].setValue(
        new Date(moment().startOf('isoWeek').subtract(1, 'week').format())
      );
      this.manualInvoiceForm.controls['InvoiceEndDate'].setValue(
        new Date(moment().endOf('isoWeek').subtract(1, 'week').format())
      );
    } else if (obj == 'Month') {
      this.manualInvoiceForm.controls['InvoiceStartDate'].setValue(
        new Date(moment().startOf('month').format())
      );
      this.manualInvoiceForm.controls['InvoiceEndDate'].setValue(
        new Date(moment().endOf('month').format())
      );
    } else if (obj == 'lastmonth') {
      this.manualInvoiceForm.controls['InvoiceStartDate'].setValue(
        new Date(moment().subtract(1, 'months').startOf('month').format())
      );
      this.manualInvoiceForm.controls['InvoiceEndDate'].setValue(
        new Date(moment().subtract(1, 'months').endOf('month').format())
      );
    } else if (obj == 'Quarter') {
      this.manualInvoiceForm.controls['InvoiceStartDate'].setValue(
        new Date(moment().startOf('quarter').format())
      );
      this.manualInvoiceForm.controls['InvoiceEndDate'].setValue(
        new Date(moment().endOf('quarter').format())
      );
    } else if (obj == 'lastquarter') {
      this.manualInvoiceForm.controls['InvoiceStartDate'].setValue(
        new Date(moment().subtract(1, 'quarters').startOf('quarter').format())
      );
      this.manualInvoiceForm.controls['InvoiceEndDate'].setValue(
        new Date(moment().subtract(1, 'quarters').endOf('quarter').format())
      );
    } else if (obj == 'Year') {
      this.manualInvoiceForm.controls['InvoiceStartDate'].setValue(
        new Date(moment().startOf('year').format())
      );
      this.manualInvoiceForm.controls['InvoiceEndDate'].setValue(
        new Date(moment().endOf('year').format())
      );
    } else if (obj == 'lastyear') {
      this.manualInvoiceForm.controls['InvoiceStartDate'].setValue(
        new Date(moment().subtract(1, 'years').startOf('year').format())
      );
      this.manualInvoiceForm.controls['InvoiceEndDate'].setValue(
        new Date(moment().subtract(1, 'years').endOf('year').format())
      );
    } else if (obj == 'custom') {
      this.IsDateTypeReadOnly = false;
      this.manualInvoiceForm.controls['InvoiceStartDate'].setValue(new Date());
      this.manualInvoiceForm.controls['InvoiceEndDate'].setValue(new Date());
    } else {
      this.manualInvoiceForm.controls['InvoiceStartDate'].setValue(new Date('1901-01-01'));
      this.manualInvoiceForm.controls['InvoiceEndDate'].setValue(new Date('2099-12-30'));
    }
  }

  initializeACHForm() {
    const parent = this;
    parent.myAchReceiptsForm = parent._fb.group({
      MerchantID: [''],
      Login: [''],
      Password: [''],
      RoutingNumber: ['', [<any>Validators.required]],
      AccountNumber: ['', [<any>Validators.required]],
      TransCode: [null, [<any>Validators.required]],
      Amount: ['', [<any>Validators.required]],
      FirstName: ['', [<any>Validators.required]],
      LastName: ['', [<any>Validators.required]],
      EmailAddress: ['', [<any>Validators.required]],
      Address1: ['', [<any>Validators.required]],
      City: ['', [<any>Validators.required]],
      State: ['', [<any>Validators.required]],
      Zip: ['', [<any>Validators.required]],
      Country: ['US'],
      StandardEntryCode: ['WEB'],
      Description: ['ACH'],
      CheckNegativeAccounts: [false],
      TokenizeOnly: [false],
      fname: [''],
      lname: [''],
      isBusiness: [false]
    });

    this.myAchReceiptsForm.valueChanges.subscribe(data => {
      this.isValidStaxForm();
    });
  }

  loadACHData(data) {
    this.isBusiness = 'false';
    this.myAchReceiptsForm.controls['RoutingNumber'].setValue('');
    this.myAchReceiptsForm.controls['AccountNumber'].setValue('');
    this.myAchReceiptsForm.controls['isBusiness'].setValue(false);
    this.myAchReceiptsForm.controls['Amount'].setValue('');
    this.myAchReceiptsForm.controls['FirstName'].setValue('');
    this.myAchReceiptsForm.controls['LastName'].setValue('');
    this.myAchReceiptsForm.controls['EmailAddress'].setValue(data.Email);
    this.myAchReceiptsForm.controls['Address1'].setValue(data.BusStreet1);
    this.myAchReceiptsForm.controls['City'].setValue(data.BusCity);
    this.myAchReceiptsForm.controls['State'].setValue(data.BusState);
    this.myAchReceiptsForm.controls['Zip'].setValue(data.BusZip);
  }

  validateForm() {
    let isInValidData = false;
    let istouchedData = false;
    Object.keys(this.manualInvoiceForm.controls).forEach(key => {
      if (this.manualInvoiceForm.get(key).invalid) {
        isInValidData = true;
      }
      if (this.manualInvoiceForm.get(key).dirty) {
        istouchedData = true;
      }
    });
    if (!isInValidData && this.isLineItemsValid && !this.noTimeRecordsWarning) {
      this.isFormValid = true;
    } else {
      this.isFormValid = false;
    }
    // if (!this.mangoAPISrvc.isFormChanged) {
    //   this.mangoAPISrvc.isFormChanged = istouchedData;
    // }
  }
  /*
    calculateSummary
  */
  calculateSummary() {
    let totals: any = 0;
    const totalServices = numeral(this.grandInvAmt).value();
    const totalExp = numeral(this.grandExpenseAmt).value();
    const discountLet = this.lineItems.reduce(function (a, b) {
      return a + +numeral(b['discount']).value();
    }, 0);
    this.grandInvAmt = this.selectedItems.reduce(function (a, b) {
      return a + +numeral(b['totalService']).value();
    }, 0);
    let laborRate = numeral(this.mangoCompanyData['LaborRate1']).value();
    let expenseRate = numeral(this.mangoCompanyData['ExpenseRate1']).value();
    this.calculateSalesTaxes();
    // let invoiceTax: any = 0;

    // if (this.mangoCompanyData["ActivateLaborRates"]) {
    //   invoiceTax = totalServices * (laborRate / 100);
    // }
    // if (this.mangoCompanyData["ActivateExpenseRates"]) {
    //   invoiceTax = invoiceTax + totalExp * (expenseRate / 100);
    // }
    // //this.invoiceTax = "$" + numeral(invoiceTax).format("0,0.00");
    // this.invoiceTax = invoiceTax;

    const invoicepayments = numeral(
      this.totalUnappliedAmount + numeral(this.myPaymentForm.get('paymentAmount').value).value()
    ).value();
    totals = totalServices + totalExp - discountLet;
    const totalsBalance = totals + this.invoiceTax - Math.abs(invoicepayments);
    this.invoiceAmount = '$' + numeral(totals).format('0,0.00');
    this.invoiceBalance = '$' + numeral(totalsBalance).format('0,0.00');

    this.lessDiscount = '$' + numeral(discountLet).format('0,0.00');
    if (discountLet > 0) {
      this.discount_color = '#ea0b0b';
      this.lessDiscount = '($' + numeral(Math.abs(discountLet)).format('0,0.00') + ')';
    } else {
      this.discount_color = '#0e0e0e';
    }
    if (invoicepayments > 0) {
      this.lessPayments_color = '#ea0b0b';
      this.invoiceLessPayments = '($' + numeral(invoicepayments).format('0,0.00') + ')';
    } else {
      this.invoiceLessPayments = '$' + numeral(invoicepayments).format('0,0.00');
      this.lessPayments_color = '#0e0e0e';
    }

    //required columns in line items
    this.IsLinesItemsRequired = true;
    if (this.selectedExpensesItems.length > 0 && this.selectedItems.length == 0) {
      this.IsLinesItemsRequired = false;
    }

    this.grnadInvoiceAmt = numeral(this.invoiceAmount).value() + numeral(this.invoiceTax).value();
  }
  /*
    Verifing the form
  */
  validateDialogForm() {
    let isInValidData = false;
    let istouchedData = false;
    Object.keys(this.myPaymentForm.controls).forEach(key => {
      if (this.myPaymentForm.get(key).invalid) {
        isInValidData = true;
      }
      if (this.myPaymentForm.get(key).dirty) {
        istouchedData = true;
      }
    });
    let formAmt = numeral(this.myPaymentForm.value['paymentAmount']).value();
    formAmt = formAmt ? formAmt : 0;
    if (!isInValidData && this.myPaymentForm.dirty && formAmt > 0) {
      this.isDialogFormValid = true;
    } else {
      this.isDialogFormValid = false;
    }
  }

  getSerialNumberByChar(str) {
    let text;
    switch (str.toUpperCase()) {
      // 2022
      case 'A':
        text = '8';
        break;
      case 'B':
        text = '2';
        break;
      case 'C':
        text = '4';
        break;

      case 'D':
        text = '3';
        break;
      case 'E':
        text = '6';
        break;
      case 'F':
        text = '4';
        break;
      case 'G':
        text = '8';
        break;
      case 'H':
        text = '1';
        break;
      case 'I':
        text = '4';
        break;
      case 'J':
        text = '2';
        break;
      case 'K':
        text = '6';
        break;
      case 'L':
        text = '8';
        break;
      case 'M':
        text = '9';
        break;
      case 'N':
        text = '2';
        break;
      case 'O':
        text = '9';
        break;
      case 'P':
        text = '3';
        break;
      case 'Q':
        text = '1';
        break;
      case 'R':
        text = '4';
        break;
      case 'S':
        text = '8';
        break;
      case 'T':
        text = '1';
        break;
      case 'U':
        text = '4';
        break;
      case 'V':
        text = '3';
        break;
      case 'W':
        text = '3';
        break;
      case 'X':
        text = '6';
        break;
      case 'Y':
        text = '5';
        break;
      case 'Z':
        text = '8';
        break;
      case '1':
        text = '4';
        break;
      case '2':
        text = '1';
        break;
      case '3':
        text = '5';
        break;
      case '4':
        text = '7';
        break;
      case '5':
        text = '4';
        break;
      case '6':
        text = '7';
        break;
      case '7':
        text = '8';
        break;
      case '8':
        text = '1';
        break;
      case '9':
        text = '7';
        break;
      case '0':
        text = '4';
        break;
      default:
        text = '6';
    }
    // 2021
    //   case "A":
    //     text = "6";
    //     break;
    //   case "B":
    //     text = "1";
    //     break;
    //   case "C":
    //     text = "7";
    //     break;

    //   case "D":
    //     text = "4";
    //     break;
    //   case "E":
    //     text = "2";
    //     break;
    //   case "F":
    //     text = "1";
    //     break;
    //   case "G":
    //     text = "4";
    //     break;
    //   case "H":
    //     text = "8";
    //     break;
    //   case "I":
    //     text = "5";
    //     break;
    //   case "J":
    //     text = "4";
    //     break;
    //   case "K":
    //     text = "2";
    //     break;
    //   case "L":
    //     text = "5";
    //     break;
    //   case "M":
    //     text = "6";
    //     break;
    //   case "N":
    //     text = "3";
    //     break;
    //   case "O":
    //     text = "5";
    //     break;
    //   case "P":
    //     text = "6";
    //     break;
    //   case "Q":
    //     text = "2";
    //     break;
    //   case "R":
    //     text = "5";
    //     break;
    //   case "S":
    //     text = "4";
    //     break;
    //   case "T":
    //     text = "9";
    //     break;
    //   case "U":
    //     text = "9";
    //     break;
    //   case "V":
    //     text = "9";
    //     break;
    //   case "W":
    //     text = "4";
    //     break;
    //   case "X":
    //     text = "4";
    //     break;
    //   case "Y":
    //     text = "2";
    //     break;
    //   case "Z":
    //     text = "6";
    //     break;
    //   case "1":
    //     text = "6";
    //     break;
    //   case "2":
    //     text = "2";
    //     break;
    //   case "3":
    //     text = "2";
    //     break;
    //   case "4":
    //     text = "4";
    //     break;
    //   case "5":
    //     text = "8";
    //     break;
    //   case "6":
    //     text = "2";
    //     break;
    //   case "7":
    //     text = "9";
    //     break;
    //   case "8":
    //     text = "2";
    //     break;
    //   case "9":
    //     text = "2";
    //     break;
    //   case "0":
    //     text = "5";
    //     break;
    //   default:
    //     text = "9";
    // }
    return text;
  }

  removeOffset(date: Date) {
    const d = new Date();
    const offset = d.getTimezoneOffset();
    const time = date.getTime() - offset * 60 * 1000;
    return new Date(time);
  }

  saveTimeEntry(data) {
    const parent = this;
    data.StandardAmount = data.StandardAmount ? numeral(data.StandardAmount).value() : 0.0;
    const obj = {
      BilledAmount: data.BilledAmount,
      Ddate: moment(data.Ddate).format('MM/DD/YYYY'),
      Approved: data.Approved,
      Billable: data.Billable,
      Billed: data.Billed ? data.Billed : false,
      BillingRate: numeral(data.BillingRate).value()?.toString(),
      ClientID: data.ClientID,
      ClientName: data.ClientName,
      ElaspedTime: data.TotalTime,
      Description: data.Description,
      Memo: data.Memo,
      nonbillableamount: data.nonbillableamount,
      ServiceCode: data.ServiceCode,
      ServiceCodeID: data.ServiceCodeID,
      StandardAmount: data.StandardAmount.toString(),
      TotalTime: data.TotalTime,
      StaffID: data.StaffID,
      StaffName: data.StaffName,
      StaffDeptID: data.StaffDeptID,
      StaffPayRate: data.StaffPayRate,
      StaffCost: Number(data.TotalTime) * Number(data.StaffPayRate),
      WorkCodeID: data.WorkCodeID,
      OriginatingPartnerID: data.OriginatingPartnerID,
      BillingPartnerID: data.BillingPartnerID,
      GroupDescriptionID: data.GroupDescriptionID,
      GroupDescriptionIDArray: data.GroupDescriptionIDArray,
      ClientTypeID: data.ClientTypeID,
      ProjectMasterID: data.ProjectMasterID,
      PrivateMemo: data.PrivateMemo,
      EngagementTypeID: data.EngagementTypeID,
      IsTimeRecord: 'T',
      WorkLocation: data.WorkLocation,
      isFlatFee: data.isFlatFee
    };
    this.encrDecSrvc.addObject(AppConstants.savedWorkLocation, data.WorkLocation);
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.updateTimeSheet(data.SlipMasterID, obj).subscribe(
      function (resp) {
        parent.mangoAPISrvc
          .sendBudgetAlert({
            ClientID: obj['ClientID'],
            ProjectMasterID: obj['ProjectMasterID'],
            CompanyID: parent.loginCompanyId,
            Ddate: moment(obj.Ddate).format('YYYY-MM-DD')
          })
          .subscribe(
            data => {},
            err => {
              console.log(err);
            }
          );
        // parent.selectedItems = parent.timeExpenseRecords;
        // parent.selectedItems = parent.timeExpenseRecords;
        // parent.calculteParentFooterTotals();
        // parent.calculateFooters()
        // parent.calculateSummary()
        // parent.refreshTimeandExpenseRecords()
        parent.mangoAPISrvc.notify('success', 'Updated!', AppConstants.updateMsg);
        data['IsColumnChanges'] = false;
        parent.mangoAPISrvc.showLoader(false);
      },
      err => {
        data['IsColumnChanges'] = false;
        parent.mangoAPISrvc.showLoader(false);
        parent.mangoAPISrvc.notify(
          'error',
          this.translate.instant('error'),
          AppConstants.updateErrorMsg
        );
      }
    );
    // data['IsColumnChanges'] = false
  }

  saveTimeExpense(evt, data) {
    const parent = this;
    data.Ddate = moment(data.Ddate).format('MM/DD/YYYY');
    data['ExpenseCodeID'] = data.ExpenseCodeID ? data.ExpenseCodeID : null;
    data['ExpenseCode'] = data.ExpenseCode ? data.ExpenseCode : null;
    data['Cost'] = data['Cost'] ? data['Cost'] : 0;
    data['Units'] = data['Units'] ? data['Units'] : 0;
    data['Markup'] = data['Markup'] ? data['Markup'] : 0;
    data['Tax'] = data['Tax'] ? data['Tax'] : 0;
    data['Reimbursed'] = data['Reimbursed'] ? data['Reimbursed'] : false;
    data['Reimburseable'] = data['Reimburseable'] ? data['Reimburseable'] : false;
    data['IsTimeRecord'] = 'X';
    data['Description'] = data['Description'] ? data['Description'] : null;
    data['BillingRate'] = data['BillingRate'] ? data['BillingRate'] : null;
    data['TotalTime'] = data['TotalTime'] ? data['TotalTime'] : null;
    data['StaffPayRate'] = data['StaffPayRate'] ? data['StaffPayRate'] : 0;
    data['ElaspedTime'] = data['ElaspedTime'] ? data['ElaspedTime'] : null;
    /*  data["WriteupDown"] = data["WriteupDown"]
       ? data["WriteupDown"]
       : 0; */

    data['SelectedAmount'] = data['SelectedAmount'] ? data['SelectedAmount'] : 0;
    data['Billed'] = data['Billed'] ? data['Billed'] : false;

    data.StaffID = data.StaffID;
    const newData = { ...data };
    delete newData['WriteUpDown'];
    delete newData['BilledAmount'];
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.updateTimeSheet(data.SlipMasterID, newData).subscribe(function (resp) {
      parent.mangoAPISrvc
        .sendBudgetAlert({
          ClientID: newData['ClientID'],
          ProjectMasterID: newData['ProjectMasterID'],
          CompanyID: parent.loginCompanyId,
          Ddate: moment(newData.Ddate).format('YYYY-MM-DD')
        })
        .subscribe(
          data => {},
          err => {
            console.log(err);
          }
        );
      // parent.selectedItems = parent.timeExpenseRecords;
      // parent.calculteParentFooterTotals(true);
      // parent.calculateFooters()
      // parent.calculateSummary()
      // parent.refreshTimeandExpenseRecords()
      data['IsColumnChanges'] = false;
      parent.mangoAPISrvc.showLoader(false);
      parent.mangoAPISrvc.notify('success', 'Updated!', AppConstants.updateMsg);
    }),
      error => {
        data['IsColumnChanges'] = false;
        parent.mangoAPISrvc.showLoader(false);
        parent.mangoAPISrvc.notify(
          'error',
          this.translate.instant('error'),
          AppConstants.updateErrorMsg
        );
      };
    // data['IsColumnChanges'] = false
  }

  cardTypeChange() {
    const formData = this.myPaymentForm.value;
    this.isVisaCard = formData.cardType != 'AMEX' ? 'true' : 'false';
  }

  filterData(query, staffListItems: any[]): any[] {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    const filtered: any[] = [];
    for (let i = 0; i < staffListItems.length; i++) {
      const staffItem = staffListItems[i];
      if (staffItem.label.toLowerCase().indexOf(query.toLowerCase()) > -1) {
        filtered.push(staffItem);
      }
    }
    return filtered;
  }

  filterStaffItems(event, data) {
    this.filteredStaffSingle = this.filterData(event.query, this.AllStaffsTypes);
  }

  SelectEngagements(opt) {
    const activity = this.engagementsTypes.filter(item => {
      return item.value == opt.value;
    })[0];
  }

  getEngagements() {
    const parent = this;
    parent.mangoAPISrvc.getEngagementTypes(this.loginCompanyId).subscribe(function (data: any) {
      const list = data.filter(invoiceGroup => invoiceGroup.Inactive == false);
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        parent.engagementsTypes.push({
          label: item.Description,
          value: item.EngagementTypeID,
          EngagementTypeID: item.EngagementTypeID
        });
      }
      parent.engagementsTypes.sort(parent.mangoUtils.compareValues('label', 'asc'));
    }),
      error => {
        parent.mangoAPISrvc.notify(
          'error',
          this.translate.instant('error'),
          AppConstants.updateErrorMsg
        );
        parent.mangoAPISrvc.showLoader(false);
      };
  }

  charCount() {
    const desValue = this.manualInvoiceForm.controls['DescriptionShort'].value;
    if (desValue && desValue.length != 0) {
      this.descCount = desValue.length;
    } else {
      this.descCount = 0;
    }
  }

  replaceShortcuts() {
    const value = this.manualInvoiceForm.value['DescriptionShort'];
    const value2 = this.convertShortcuts(value);
    this.manualInvoiceForm.controls['DescriptionShort'].setValue(value2);
    const desValue = this.manualInvoiceForm.controls['DescriptionShort'].value;
    if (desValue == null || desValue.trim() == '') {
      this.manualInvoiceForm.controls['copyclipboard'].setValue(false);
    }
    this.charCount();
    this.validateLineItems();
  }

  replacePaymentShortCuts() {
    const value = this.myPaymentForm.value['paymentMemo'];
    const value2 = this.convertShortcuts(value);
    this.myPaymentForm.controls['paymentMemo'].setValue(value2);
  }

  replaceShortcutsNew(form, filed) {
    const value = form.value;
    const value2 = this.convertShortcuts(value);
    this.manualInvoiceForm.controls[filed].setValue(value2);
    //form.controls[filed].setValue(value2);
  }

  replaceShortcuts2(value, desc, type?) {
    if (!value) {
      return;
    }
    const valueArr = value.split(' ');
    for (let i = 0; i < valueArr.length; i++) {
      let label = valueArr[i];
      for (let i = 0; i < this.mangoUtils.shortcutLabels.length; i++) {
        const shortcut = this.mangoUtils.shortcutLabels[i];
        if (shortcut['Inactive']) {
          continue;
        }
        if (label == shortcut['ShortCutCode']) {
          label = shortcut['Phrase'];
        }
      }
      valueArr[i] = label;
    }
    if (type === 'Memo') desc['Memo'] = valueArr.join(' ');
    else {
      desc['description'] = valueArr.join(' ');
      this.validateLineItems();
    }
  }

  replaceCaret(value, rowData, type?) {
    if (type === 'Memo') {
      rowData['Memo'] = this.mangoUtils.replaceCaretTemplate(rowData['Memo']);
      return;
    } else if (type === 'InvoiceItems') {
      const value = this.mangoUtils.replaceCaretTemplate(
        this.manualInvoiceForm.value['DescriptionShort']
      );
      this.manualInvoiceForm.controls['DescriptionShort'].setValue(value);
      return;
    }
    rowData['description'] = this.mangoUtils.replaceCaretTemplate(rowData['description']);
  }

  replaceLineItemDesc(event) {
    const value = event.data.description;
    const value2 = this.convertShortcuts(value);
    event.data.description = value2;
  }

  private convertShortcuts(value) {
    if (!value && value != '') {
      return '';
    }
    for (let i = 0; i < this.mangoUtils.shortcutLabels.length; i++) {
      const shortcut = this.mangoUtils.shortcutLabels[i];
      if (shortcut['Inactive']) {
        continue;
      }
      if (value.includes(shortcut['ShortCutCode'])) {
        value = this.mangoUtils.replaceAll(value, shortcut['ShortCutCode'], shortcut['Phrase']);
      }
    }
    return value;
  }

  openTimeEntryDailog(data?: any, isTimeRecord?: any, parentItems?: any) {
    const parent = this;
    const counter = -1;
    data['isEditFlow'] = true;
    data['isDisable'] = true;
    data['ClientName'] = parent.selClient.ClientName;
    if (isTimeRecord) {
      data['ServiceCode'] = data['Description'];
      parent.sharedSrvc.openTimeEntryDialog(data);
    } else {
      parent.sharedSrvc.openExpenseDialog(data);
    }
  }

  updateDialogData(responseData, isTimeRecord, parentItems, data) {
    const parent = this;
    let recordTobeUpdated = {};
    let selectedRecordTobeUpdated = {};
    let rowItem = -1;
    let parentSelectedItem = -1;

    if (isTimeRecord) {
      rowItem = parentItems.allTimeSlips.indexOf(data);
      parentSelectedItem = parentItems.allSelectedTimeSlips.indexOf(data);
    } else {
      rowItem = parentItems.allExpenseSlips.indexOf(data);
      parentSelectedItem = parentItems.allSelectedExpenseSlips.indexOf(data);
    }
    if (rowItem !== -1) {
      recordTobeUpdated = isTimeRecord
        ? parentItems.allTimeSlips[rowItem]
        : parentItems.allExpenseSlips[rowItem];
      recordTobeUpdated['Ddate'] = moment(responseData['Ddate']).format('MM-DD-YYYY');
      recordTobeUpdated['StaffName'] = responseData['StaffName'];
      recordTobeUpdated['Description'] = responseData['Description'];
      recordTobeUpdated['Memo'] = responseData['Memo'];
      recordTobeUpdated['isFlatFee'] = responseData['isFlatFee'];
      recordTobeUpdated['TotalTime'] = responseData['TotalTime'];
      recordTobeUpdated['ElaspedTime'] = responseData['ElaspedTime'];
      recordTobeUpdated['BillingRate'] = responseData['BillingRate'];
      recordTobeUpdated['nonbillableamount'] = responseData['nonbillableamount'];
      recordTobeUpdated['billableamount'] = responseData['StandardAmount'];
      recordTobeUpdated['StandardAmount'] = responseData['StandardAmount'];
      recordTobeUpdated['StaffCost'] = responseData['StaffCost'];
      recordTobeUpdated['StaffPayRate'] = responseData['StaffPayRate'];
      recordTobeUpdated['isFlatFee'] = responseData['isFlatFee'];
      recordTobeUpdated['Units'] = responseData['Units'];
      if (responseData.IsTimeRecord == 'X') {
        recordTobeUpdated['Cost'] = numeral(responseData['Cost']).format('0,0.00');
        recordTobeUpdated['Markup'] = responseData['Markup'];
        recordTobeUpdated['Tax'] = responseData['Tax'];
        recordTobeUpdated['Reimburseable'] = responseData['Reimburseable'];
        recordTobeUpdated['Reimbursed'] = responseData['Reimbursed'];
        recordTobeUpdated['Memo'] = responseData['Memo'];
        recordTobeUpdated['Description'] = responseData['Description'];
        recordTobeUpdated['ExpenseCode'] = responseData['ExpenseCode'];
        recordTobeUpdated['ExpenseCodeID'] = responseData['ExpenseCodeID'];
      }
    }
    if (parentSelectedItem !== -1) {
      selectedRecordTobeUpdated = isTimeRecord
        ? parentItems.allSelectedTimeSlips[parentSelectedItem]
        : parentItems.allSelectedExpenseSlips[parentSelectedItem];
      selectedRecordTobeUpdated['Ddate'] = moment(responseData['Ddate']).format('MM-DD-YYYY');
      selectedRecordTobeUpdated['StaffName'] = responseData['StaffName'];
      selectedRecordTobeUpdated['StaffID'] = responseData['StaffID'];
      selectedRecordTobeUpdated['Description'] = responseData['Description'];
      selectedRecordTobeUpdated['Memo'] = responseData['Memo'];
      selectedRecordTobeUpdated['isFlatFee'] = responseData['isFlatFee'];
      selectedRecordTobeUpdated['TotalTime'] = responseData['TotalTime'];
      selectedRecordTobeUpdated['ElaspedTime'] = responseData['ElaspedTime'];
      selectedRecordTobeUpdated['BillingRate'] = responseData['BillingRate'];
      selectedRecordTobeUpdated['nonbillableamount'] = responseData['nonbillableamount'];
      selectedRecordTobeUpdated['billableamount'] = responseData['StandardAmount'];
      selectedRecordTobeUpdated['StandardAmount'] = responseData['StandardAmount'];
      selectedRecordTobeUpdated['StaffCost'] = responseData['StaffCost'];
      selectedRecordTobeUpdated['StaffPayRate'] = responseData['StaffPayRate'];
      selectedRecordTobeUpdated['isFlatFee'] = responseData['isFlatFee'];
      selectedRecordTobeUpdated['Units'] = responseData['Units'];
      if (responseData.IsTimeRecord == 'X') {
        selectedRecordTobeUpdated['Cost'] = numeral(responseData['Cost']).format('0,0.00');
        selectedRecordTobeUpdated['Markup'] = responseData['Markup'];
        selectedRecordTobeUpdated['Tax'] = responseData['Tax'];
        selectedRecordTobeUpdated['Reimburseable'] = responseData['Reimburseable'];
        selectedRecordTobeUpdated['Reimbursed'] = responseData['Reimbursed'];
        selectedRecordTobeUpdated['Memo'] = responseData['Memo'];
        selectedRecordTobeUpdated['Description'] = responseData['Description'];
        selectedRecordTobeUpdated['ExpenseCode'] = responseData['ExpenseCode'];
        selectedRecordTobeUpdated['ExpenseCodeID'] = responseData['ExpenseCodeID'];
      }
    }
    parent.calculteParentFooterTotals();
  }

  onRowChange(event) {
    this.validateLineItems();
    this.reOrderLineItems();
  }

  clearSearchFilter() {
    this.searchHistoryValue.nativeElement.value = this.searchTextStr = '';
  }

  clearSearchFilterTime() {
    this.searchValue.nativeElement.value = this.searchTextTimeStr = '';
    this.filteredTimeItemsSize = -1;
    this.calcEngagementTimeSalesTax(this.dtchild?._value);
  }

  onFilterTime(obj) {
    this.filteredTimeItemsSize = obj.filteredValue.length;
    if (obj.filteredValue.length === 0 && this.searchValue?.nativeElement?.value == '')
      this.calcEngagementTimeSalesTax(this.dtchild?._value);
    else this.calcEngagementTimeSalesTax(obj?.filteredValue);
  }

  onEditInit(event, table) {
    this.cellFocused = { ...event, table };
  }

  onEditComplete() {
    this.cellFocused = null;
  }

  onEditCancel() {
    this.cellFocused = null;
  }

  // calls computerFooter when value changes
  onBillRateChange(evt, data) {
    const newValue = evt.target.value ? evt.target.value.replace('$', '').replace(',', '') : null;

    if (newValue === null) return;

    data['BillingRate'] = newValue;
    data = this.calculateStandardAmount(data);
    this.calculateBilledAmtWuwd(data);
  }

  // calls computerFooter when value changes
  onTotalTimeChange(evt, data) {
    //const newValue = evt.target.value ? evt.target.value.replace(",", "") : null;
    let newValue = evt;
    if (evt == null) {
      newValue = 0;
      return false;
    } else if (evt == '.') {
      return false;
    }

    data['TotalTime'] = newValue ? numeral(newValue).value() : 0;
    data = this.calculateStandardAmount(data);
    this.calculateBilledAmtWuwd(data);
  }

  computeFooter() {
    this.selectedItems.forEach(timeSlip => {
      let totalTime = 0;
      let totalBillableTime = 0;
      let totalNonBillableAmount = 0;
      // let invoiceAmt = 0;
      let totalWUWD = 0;
      let totalBilledAmount = 0;
      timeSlip.allSelectedTimeSlips.forEach(slip => {
        totalTime += numeral(slip.TotalTime).value();
        if (slip.Billable) {
          // invoiceAmt += numeral(slip.BilledAmount).value();
          totalBillableTime += numeral(slip.StandardAmount).value();
        } else totalNonBillableAmount += numeral(slip.StandardAmount).value();

        totalWUWD += numeral(slip.WriteUpDown).value();
        totalBilledAmount += numeral(slip.BilledAmount).value();
      });
      timeSlip.laboramount = totalBillableTime;
      timeSlip.nonbillableamount = totalNonBillableAmount;
      timeSlip.totaltime = totalTime;
      timeSlip.grandExpenseHrs = totalTime;
      timeSlip.grandBillableamount = totalBillableTime;
      timeSlip.grandNonbillableamount = totalNonBillableAmount;
      timeSlip.grandWUWDamount = totalWUWD;
      timeSlip.grandBilledamount = totalBilledAmount;
      //timeSlip.grandExpenseTotalAmt = timeSlip.allSelectedExpenseSlips.reduce(function (a, b) { return a + +numeral(b['BilledAmount']).value(); }, 0);
      if (timeSlip.allSelectedTimeSlips && timeSlip.allSelectedTimeSlips.length > 0) {
        // timeSlip.invoiceAmount = invoiceAmt
        timeSlip.writeupValue = totalWUWD;
      }
    });

    this.selectedItems['invoiceAmount'] = this.selectedItems.reduce((a, b) => {
      return a + +numeral(b['invoiceAmount']).value();
    }, 0);
    this.selectedItems['writeupValue'] = this.selectedItems.reduce((a, b) => {
      return a + +numeral(b['writeupValue']).value();
    }, 0);
  }

  computeExpenseFooter() {
    this.selectedItems.forEach(timeSlip => {
      const totalBillableTime = 0;
      const totalNonBillableAmount = 0;
      // let invoiceAmt = 0;
      let totalWUWD = 0;
      let totalBilledAmount = 0;
      const allSelectedContainer = [];
      for (let i = 0; i < timeSlip.allSelectedExpenseSlips.length; i++) {
        const isSelected = timeSlip.allExpenseSlips.filter(
          itm => itm.SlipMasterID === timeSlip.allSelectedExpenseSlips[i].SlipMasterID
        );
        if (isSelected.length > 0) allSelectedContainer.push(isSelected[0]);
      }
      allSelectedContainer.forEach(slip => {
        totalWUWD += numeral(slip.WriteUpDown).value();
        totalBilledAmount += slip.Billable ? numeral(slip.BilledAmount).value() : 0;
      });

      timeSlip.grandExpenseWUWDamount = totalWUWD;
      timeSlip.grandExpenseBillableAmt = totalBilledAmount;
      timeSlip.grandExpenseTotalAmt = allSelectedContainer.reduce(function (a, b) {
        return a + +(b['Billable'] ? numeral(b['BilledAmount']).value() : 0);
      }, 0);
      timeSlip.expenseamount = allSelectedContainer.reduce(function (a, b) {
        return a + +numeral(b['BilledAmount']).value();
      }, 0);

      /*  if (timeSlip.allSelectedTimeSlips && timeSlip.allSelectedTimeSlips.length > 0) {
         // timeSlip.invoiceAmount = invoiceAmt
         timeSlip.writeupValue = totalBilledAmount - totalBillableTime;
       } */
    });

    this.selectedItems['invoiceAmount'] = this.selectedItems.reduce((a, b) => {
      return a + +numeral(b['invoiceAmount']).value();
    }, 0);
    this.selectedItems['writeupValue'] = this.selectedItems.reduce((a, b) => {
      return a + +numeral(b['writeupValue']).value();
    }, 0);
  }

  onBilledAmountChange(evt, data, isExpense?) {
    const newValue = evt.target.value ? evt.target.value.replace('$', '').replace(',', '') : null;
    if (newValue === null) return;

    data['BilledAmount'] = newValue;
    if (isExpense) {
      data['Billable'] = true;
      this.calculateExpenseBilledAmtWuwd(data, true);
    } else this.calculateBilledAmtWuwd(data, true);
    if (!isExpense) {
      this.calcEngagementTimeSalesTax(
        this.searchValue?.nativeElement?.value == ''
          ? this.dtchild?._value
          : this.dtchild?.filteredValue
      );
    } else {
      this.calcEngagementExpSalesTax(this.dtchildex?._value);
    }
  }

  calculateExpenseBilledAmtWuwd(data, isBilledAmtChanged?) {
    data['WriteUpDown'] =
      numeral(data['BilledAmount']).value() - numeral(data['StandardAmount']).value();
    const invoiceAmt = this.getProjectInvoiceAmount(data.ProjectMasterID);
    this.timeExpenseRecords.forEach(record => {
      if (data.ProjectMasterID === record.ProjectMasterID) {
        // record.allSelectedTimeSlips.forEach((slip) => {
        //   invoiceAmt += numeral(slip.BilledAmount).value()
        // })
        if (isBilledAmtChanged) {
          const initBilledAmt = numeral(data['InitialBilledAmt']).value();
          const currBilledAmt = numeral(data['BilledAmount']).value();
          if (initBilledAmt > currBilledAmt) {
            record.invoiceAmount = invoiceAmt - (initBilledAmt - currBilledAmt);
          } else if (initBilledAmt <= currBilledAmt) {
            record.invoiceAmount = invoiceAmt + (currBilledAmt - initBilledAmt);
          }
        }
      }
    });
  }

  calculateBilledAmtWuwd(data, isBilledAmtChanged?) {
    if (!data['Billable']) {
      data['BilledAmount'] = 0.0;
    } else {
      data['WriteUpDown'] =
        numeral(data['BilledAmount']).value() - numeral(data['StandardAmount']).value();
      const invoiceAmt = this.getProjectInvoiceAmount(data.ProjectMasterID);
      this.timeExpenseRecords.forEach(record => {
        if (data.ProjectMasterID === record.ProjectMasterID) {
          // record.allSelectedTimeSlips.forEach((slip) => {
          //   invoiceAmt += numeral(slip.BilledAmount).value()
          // })
          if (isBilledAmtChanged) {
            const initBilledAmt = numeral(data['InitialBilledAmt']).value();
            const currBilledAmt = numeral(data['BilledAmount']).value();
            if (initBilledAmt > currBilledAmt) {
              record.invoiceAmount = invoiceAmt - (initBilledAmt - currBilledAmt);
            } else if (initBilledAmt <= currBilledAmt) {
              record.invoiceAmount = invoiceAmt + (currBilledAmt - initBilledAmt);
            }
          }
        }
      });

      const sameProject = this.lineItems?.filter(
        line => data.ProjectMasterID === line.ProjectMasterID
      );
      const totalInvoice = sameProject.reduce((a, b) => {
        return a + +numeral(b['amount']).value();
      }, 0);

      let deduction = totalInvoice - invoiceAmt;
      this.lineItems?.map(line => {
        if (data.ProjectMasterID === line.ProjectMasterID) {
          let lineAmount = numeral(line.amount).value();
          if (deduction === 0) return;

          if (deduction >= lineAmount) {
            deduction -= lineAmount;
            lineAmount = 0;
          } else {
            lineAmount -= deduction;
            deduction = 0;
          }

          line.amount = lineAmount;
          this.doMoneyFormate(line, 'amount');
          line.PreviousAmount = line.amount;
        }
      });
    }
  }

  recomputeLineItems(projectMasterID) {
    const invoiceAmt = this.getProjectInvoiceAmount(projectMasterID);
    const sameProject = this.lineItems?.filter(line => projectMasterID === line.ProjectMasterID);
    const totalInvoice = sameProject.reduce((a, b) => {
      return a + +numeral(b['amount']).value();
    }, 0);

    let deduction = totalInvoice - invoiceAmt;
    this.lineItems?.map(line => {
      if (projectMasterID === line.ProjectMasterID) {
        let lineAmount = numeral(line.amount).value();
        if (deduction === 0) return;

        if (deduction >= lineAmount) {
          deduction -= lineAmount;
          lineAmount = 0;
        } else {
          lineAmount -= deduction;
          deduction = 0;
        }

        line.amount = lineAmount;
        this.doMoneyFormate(line, 'amount');
        line.PreviousAmount = line.amount;
      }
    });
  }

  calculateInvoiceLineAmt(id: number, invoiceAmt: number): void {
    const sameProject = this.lineItems?.filter(line => id === line.ProjectMasterID);
    const totalInvoice = sameProject.reduce((a, b) => {
      return a + +numeral(b['amount']).value();
    }, 0);

    let deduction = totalInvoice - invoiceAmt;
    this.lineItems?.map(line => {
      if (id === line.ProjectMasterID) {
        let lineAmount = numeral(line.amount).value();
        if (deduction === 0) return;

        if (deduction >= lineAmount) {
          deduction -= lineAmount;
          lineAmount = 0;
        } else {
          lineAmount -= deduction;
          deduction = 0;
        }

        line.amount = lineAmount;
        this.doMoneyFormate(line, 'amount');
        line.PreviousAmount = line.amount;
      }
    });
  }

  roundOffDecimals(money) {
    return numeral(this.currencyPipe.transform(money, 'USD')).value();
  }

  distributeInvoiceAmount(data, isFixed?: boolean, isInitial?: boolean) {
    let unpaidAmount =
      typeof data.amount === 'number'
        ? data.amount
        : numeral(data.amount.replace('$', '').replace(',', '')).value();
    const parent = this;
    parent.timeExpenseRecords.forEach(record => {
      if (data.ProjectMasterID === record.ProjectMasterID) {
        let totalBillable = 0;
        let timeSlipsArray = [];
        let expenseSlipsArray = [];

        if (isInitial) {
          timeSlipsArray = data.allTimeSlips;
          expenseSlipsArray = data.allExpenseSlips;
        } else {
          timeSlipsArray = data.allSelectedTimeSlips;
          expenseSlipsArray = data.allSelectedExpenseSlips;
        }

        totalBillable = timeSlipsArray.reduce((a, b) => {
          return b.Billable &&
            ((numeral(b.BilledAmount || 0).value() == 0 && isInitial) || !isInitial)
            ? a + +numeral(b['StandardAmount']).value()
            : a + 0;
        }, 0);

        if (isInitial) {
          unpaidAmount = this.mangoUtils.subtractFloat(
            unpaidAmount,
            timeSlipsArray.reduce((a, b) => {
              return b.Billable && numeral(b.BilledAmount).value() > 0
                ? a + +numeral(b['BilledAmount']).value()
                : a + 0;
            }, 0)
          );
        }

        const isDistribute: boolean = totalBillable < unpaidAmount;
        let totalBilledAmt = 0;
        let lastBillableSlipID = null;
        timeSlipsArray.forEach(slip => {
          const slipStandardAmt = numeral(slip.StandardAmount).value();
          if (numeral(slip.BilledAmount).value() > 0 && isInitial) {
            slip.WriteUpDown = parent.mangoUtils.subtractFloat(slip.BilledAmount, slipStandardAmt);
            slip.IsBilled = true;
            return;
          }
          if (slip.Billable) {
            if (record.BillingMethod === 'Fixed Fee' || isFixed || isDistribute) {
              const invoiceAMount =
                isFixed || (record.BillingMethod === 'Hourly' && isDistribute)
                  ? unpaidAmount
                  : numeral(record.FlatFeeAmount).value();
              slip.BilledAmount = parent.roundOffDecimals(
                (slipStandardAmt / totalBillable) * invoiceAMount
              );
              slip.WriteUpDown = parent.roundOffDecimals(
                parent.mangoUtils.subtractFloat(slip.BilledAmount, slipStandardAmt)
              );

              totalBilledAmt = parent.roundOffDecimals(
                parent.mangoUtils.addFloat(slip.BilledAmount, totalBilledAmt)
              );
              lastBillableSlipID = slip.SlipMasterID;
            } else {
              if (unpaidAmount >= slipStandardAmt) {
                slip.BilledAmount = slipStandardAmt;
                slip.WriteUpDown = 0.0;
              } else {
                if (unpaidAmount <= 0) {
                  slip.BilledAmount = 0;
                } else slip.BilledAmount = unpaidAmount;

                slip.WriteUpDown = parent.mangoUtils.subtractFloat(
                  slip.BilledAmount,
                  slipStandardAmt
                );
              }
              unpaidAmount = parent.mangoUtils.subtractFloat(unpaidAmount, slip.BilledAmount);
            }
          } else {
            slip.WriteUpDown = slip.BilledAmount - slipStandardAmt;
          }
          slip['InitialBilledAmt'] = slip.BilledAmount;
        });
        if (record.BillingMethod === 'Fixed Fee' || isFixed || isDistribute) {
          if (totalBilledAmt > unpaidAmount) {
            const diff = parent.mangoUtils.subtractFloat(totalBilledAmt, unpaidAmount);
            timeSlipsArray.forEach(slip => {
              if (slip.SlipMasterID === lastBillableSlipID) {
                slip.BilledAmount = parent.roundOffDecimals(
                  parent.mangoUtils.subtractFloat(slip.BilledAmount, diff)
                );
                slip.WriteUpDown = parent.roundOffDecimals(
                  parent.mangoUtils.subtractFloat(slip.BilledAmount, slip.StandardAmount)
                );
                return;
              }
            });
          } else if (totalBilledAmt < unpaidAmount) {
            const diff = parent.mangoUtils.subtractFloat(unpaidAmount, totalBilledAmt);
            timeSlipsArray.forEach(slip => {
              if (slip.SlipMasterID === lastBillableSlipID) {
                slip.BilledAmount = parent.roundOffDecimals(
                  parent.mangoUtils.addFloat(slip.BilledAmount, diff)
                );
                slip.WriteUpDown = parent.roundOffDecimals(
                  parent.mangoUtils.subtractFloat(slip.BilledAmount, slip.StandardAmount)
                );
                return;
              }
            });
          }
        }
        expenseSlipsArray.forEach(slip => {
          slip.BilledAmount = slip.BilledAmount || slip.StandardAmount;
        });
      }
    });
    return;
  }

  getProjectInvoiceAmount(id: number): number {
    let invoiceAmt = 0.0;
    this.timeExpenseRecords?.forEach(record => {
      if (id === record.ProjectMasterID) {
        record.allSelectedTimeSlips.forEach(slip => {
          invoiceAmt += numeral(slip.BilledAmount).value();
        });
      }
    });
    return invoiceAmt;
  }

  selectCell(clss, index, xtraEl?) {
    setTimeout(() => {
      let colClass = `.${clss}-${index}`;
      colClass += xtraEl ? ` > ${xtraEl}` : '';
      $(colClass).select();
    }, 100);
  }

  onRowEditInit(data: any, column, index?) {
    if (Object.keys(this.clonedData).length === 0) {
      this.clonedData = {};
      this.clonedData = { ...data };
    }
    if (!data['IsRowEditing']) {
      if (column === 'BilledAmount') {
        this.selectCell('billed-amount', index, 'input');
      } else if (column === 'BillRate') {
        this.selectCell('bill-rate', index, 'input');
      } else if (column === 'TotalTime') {
        this.selectCell('total-time', index, 'input');
      } else if (column === 'Memo') {
        this.selectCell('memo-data', index);
      } else if (column === 'InvoiceDesc') {
        this.selectCell('invoice-desc', index);
      } else if (column === 'InvoiceAmount') {
        this.selectCell('invoice-amount', index);
      } else if (column === 'Discount') {
        this.selectCell('discount', index);
      }
    }

    data['IsRowEditing'] = true;
  }

  resetIsBilledFlag(id) {
    this.selectedItems
      .filter(item => item.ProjectMasterID === id)[0]
      ?.allTimeSlips.map(item => (item.IsBilled = false));
  }

  onRowEditSave(event: any, data: any, type: string, isExpense?: any) {
    if (type == 'InvoiceItems') {
      const recordChanges: number = this.selectedItems
        .filter(item => item.ProjectMasterID === data.ProjectMasterID)[0]
        ?.allTimeSlips?.filter(item => item.IsColumnChanges || item.IsBilled).length;

      if (this.amountChanged && recordChanges > 0) {
        Swal.fire({
          title: this.translate.instant('confirmation'),
          html: this.translate.instant('billing.invoice_amount_changed'),
          icon: 'warning',
          showCancelButton: true,
          allowEscapeKey: false,
          allowEnterKey: false,
          confirmButtonText: this.translate.instant('I_know_continue'),
          cancelButtonText: this.translate.instant('no_cancel')
        }).then(result => {
          if (result.value) {
            this.calculateWUWD(data.amount, data, false, true);
            this.doMoneyFormate(data, 'amount');
            this.resetIsBilledFlag(data.ProjectMasterID);
            data['PreviousAmount'] = data.amount;
          } else {
            this.doMoneyFormate(data, 'amount');
            data.amount = data['PreviousAmount'];
          }
        });
      } else {
        this.calculateWUWD(data.amount, data, false, true);
        this.doMoneyFormate(data, 'amount');
      }
      this.amountChanged = false;
    } else if (type == 'TimeRecords') {
      if (data['TotalTimeChanged'] === true) {
        this.calculateWUWD(data.amount, data, true);
        data['TotalTimeChanged'] = false;
        data['IsColumnChanges'] = true;
      }

      if (data['BillRateChanged'] === true) {
        this.calculateWUWD(data.amount, data, true);
        data['BillRateChanged'] = false;
        data['IsColumnChanges'] = true;
      }

      if (data['BilledAmountChanged']) {
        if (isExpense) {
          this.selectedItems.map(item => {
            item.allSelectedExpenseSlips.forEach(expense => {
              expense['isExpenseChanged'] = expense.SlipMasterID == data.SlipMasterID;
            });

            item.allExpenseSlips.forEach(expense => {
              expense['isExpenseChanged'] = expense.SlipMasterID == data.SlipMasterID;
            });
          });
        }

        this.calculateWUWD(data.amount, data, true);
        data['BilledAmountChanged'] = false;
        data['IsBilled'] = true;
      }

      if (!data['IsBilled']) data['IsColumnChanges'] = true;
    }
    data['IsRowEditing'] = false;
    this.clonedData = {};
  }

  onRowEditCancel(data: any, index: number) {
    data = Object.assign(data, this.clonedData);

    this.amountChanged = false;
    data['IsRowEditing'] = false;
    data['IsColumnChanges'] = false;
    this.clonedData = {};
  }

  onHideSideBar() {
    let dirtyData = 0;
    dirtyData += this.selectedParent?.allTimeSlips?.filter(
      col => col?.IsColumnChanges === true
    )?.length;
    dirtyData += this.selectedParent?.allExpenseSlips?.filter(
      col => col?.IsColumnChanges === true
    )?.length;

    if (dirtyData > 0) {
      Swal.fire({
        title: this.translate.instant('warning'),
        html: this.translate.instant('manual.unsaved_changes'),
        icon: 'warning',
        showCancelButton: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonText: this.translate.instant('OK')
      }).then(result => {
        if (result.value) this.displaySideBar = true;
        this.remProjAndLineItemIfNoSelected();
      });
    } else this.remProjAndLineItemIfNoSelected();
  }

  remProjAndLineItemIfNoSelected() {
    const index = this.selectedItems.findIndex(
      item => item.ProjectMasterID == this.selectedParent.ProjectMasterID
    );

    if (
      this.selectedParent.allSelectedExpenseSlips &&
      this.selectedParent.allSelectedExpenseSlips.length == 0 &&
      this.selectedParent.allSelectedTimeSlips &&
      this.selectedParent.allSelectedTimeSlips.length == 0
    ) {
      this.selectedParent.isSelected = false;
      const temp = this.selectedItems;
      temp.splice(index, 1).slice(0); // remove parent row from parent selection
      this.selectedItems = [].concat(temp); // trick to update the view
    }

    // removing line item
    for (let lineIndex = 0; lineIndex < this.lineItems.length; lineIndex++) {
      const lineElement = this.lineItems[lineIndex];
      const lineFindIndex = this.selectedItems.findIndex(
        item => item.ProjectMasterID == lineElement.ProjectMasterID
      );
      if (lineFindIndex == -1) {
        this.lineItems.splice(lineIndex, 1);
      }
    }

    if (this.lineItems.length == 0 && this.IsLinesItemsRequired) {
      this.addRow();
    }
  }

  setStaxOption() {
    const parent = this;
    parent.isStaxVerify = false;
    parent.mangoAPISrvc
      .performStaxActions({ isFullData: false }, parent.mangoCompanyData.CompanyID, 'lrv')
      .subscribe(
        (response: any) => {
          if (
            response &&
            response.underwriting_status != 'PENDED' &&
            parent.mangoCompanyData.StaxStatus == 'ACTIVE'
          ) {
            parent.isStaxVerify = true;
            this.getPaymentMethods(true);
          } else {
            this.getPaymentMethods(false);
            if (
              response &&
              response.underwriting_status == 'PENDED' &&
              parent.staffPermission == 1
            ) {
              parent.isStaxVerify = false;
              Swal.fire({
                title: parent.translate.instant('confirmation'),
                html: `<div>Your MangoPayments enrollment has some underwriting issues that require immediate attention!</div>`,
                icon: 'warning',
                showCancelButton: true,
                allowEscapeKey: false,
                allowEnterKey: false,
                confirmButtonText: 'Enroll Now',
                cancelButtonText: 'Cancel'
              }).then(result => {
                if (result.value) {
                  parent.mangoAPISrvc.isFormChanged = false;
                  parent.encrDecSrvc.addObject(AppConstants.isFormChanged, false);
                  parent.router.navigate(['accounting/cards-view']);
                }
              });
            } else {
              parent.isStaxVerify = false;
            }
          }
        },
        error => {
          this.getPaymentMethods(false);
        }
      );
  }

  processSTaxUsingToken() {
    const parent = this;
    if (!this.sTaxProfile) {
      return false;
    }

    const formData = parent.myPaymentForm.value;
    const cashformData = parent.myPaymentForm.value;
    const amount = numeral(formData.paymentAmount).value();
    const cardObj = {
      method: parent.isCCFlow ? 'card' : 'bank',
      CustomerCardID: this.sTaxProfile.CustomerCardID,
      send_receipt: false,
      meta: {
        tax: 0,
        shippingAmount: 0,
        customerCode: this.selClient['ClientID'],
        otherField1:
          'from Manual Invoice | User : ' +
          this.userName +
          ' | ClientName :' +
          this.selClient['ClientName'] +
          ' | Memo : ' +
          cashformData.PaymentNote,
        subtotal: amount
      },
      total: amount
    };

    if (amount == 0) {
      Swal.fire({
        icon: 'error',
        title: 'The amount must be at least .01',
        showConfirmButton: false,
        timer: 3000
      });
      return false;
    } else if (parent.isProcessing) {
      return false;
    }
    parent.isProcessing = true;
    parent.mangoAPISrvc.showLoader(true);

    parent.mangoAPISrvc
      .performStaxActions(cardObj, parent.mangoCompanyData.CompanyID, 'c')
      .subscribe(
        (result: any) => {
          parent.calculateSummary();
          parent.processInvoice(false);
          parent.showStaxoption = false;
          parent.transactionData = result;
          parent.isPaymentFlowRequired = true;

          parent.encrDecSrvc.addObject(AppConstants.isFormChanged, true);
          const obj = {};
          obj['uid'] = parent.transactionData ? parent.transactionData.receiptID : null;
          obj['isBusiness'] = !parent.isCCFlow && parent.isBusiness == 'true' ? true : false;
          parent.mangoAPISrvc
            .updateCreditCardDetails(parent.sTaxProfile.CustomerCardID, obj)
            .subscribe((resultRes: any) => {
              setTimeout(() => {
                parent.mangoAPISrvc.showLoader(false);
                parent.closePaymentForm(true);
              }, 5000);
            });
          Swal.fire({
            icon: 'success',
            title: parent.translate.instant('Success'),
            html: parent.translate.instant('Transaction Approved.'),
            showConfirmButton: false,
            timer: 5000
          });
        },
        (err: any) => {
          parent.isProcessing = false;
          parent.mangoAPISrvc.showLoader(false);
          let msg = err['message'] ? err['message'] : err;
          if (!msg) {
            if (err.payment_attempt_message) {
              msg = err.payment_attempt_message;
            } else if (err.child_transactions && err.child_transactions.length > 0) {
              msg = err.child_transactions[0].message;
            } else if (err.error_description) {
              msg = err.error_description;
            } else {
              try {
                msg =
                  typeof err === 'object'
                    ? Object.keys(err)
                        .map(k => err[k].join(' '))
                        .join(' ')
                    : JSON.stringify(err);
              } catch (error) {}
            }
          }
          Swal.fire({
            icon: 'error',
            title: parent.translate.instant('Transaction Not Approved'),
            text: msg,
            showConfirmButton: false,
            timer: 7000
          });
          //parent.mangoAPISrvc.notify('error', 'Error!', data);
        }
      );
  }

  authorizeSTaxNewCard() {
    const parent = this;
    let formData = null;
    let extraDetails = null;
    const cardObj = {};
    parent.isProcessing = true;
    let phNo = parent.selClient.Mobile ? parent.selClient.Mobile : parent.selClient.Office;
    phNo = phNo ? phNo.replace(/[{(___)-}]/g, '').trim() : '555-555-5555';
    phNo = phNo ? phNo : '555-555-5555';
    parent.mangoAPISrvc.showLoader(true);
    if (this.isCCFlow) {
      formData = parent.myPaymentForm.value;
      extraDetails = {
        total: numeral(formData.paymentAmount).value(),
        firstname: formData.fname,
        lastname: formData.lname,
        email: parent.selClient.Email,
        month: formData.expDate ? parseInt(formData.expDate.split('/')[0]) : null,
        year: formData.expDate ? parseInt(formData.expDate.split('/')[1]) : null,
        phone: phNo,
        address_1: formData.address,
        address_city: formData.city,
        address_state: formData.state,
        address_zip: formData.zip,
        method: 'card',
        validate: false,
        send_receipt: false,
        meta: {
          otherField1:
            'from Manual Invoice | User : ' +
            this.userName +
            ' | ClientName :' +
            this.selClient['ClientName']
        }
      };
    } else {
      parent.myAchReceiptsForm.controls['Amount'].setValue(
        parent.myPaymentForm.value.paymentAmount
      );
      parent.myAchReceiptsForm.controls['isBusiness'].setValue(parent.isBusiness);
      formData = parent.myAchReceiptsForm.value;
      extraDetails = {
        total: numeral(formData.Amount).value(),
        firstname: formData.fname,
        lastname: formData.lname,
        person_name: formData.fname + ' ' + formData.lname,
        phone: phNo,
        address_1: parent.selClient.BusStreet1,
        address_2: parent.selClient.BusStreet2 ? parent.selClient.BusStreet2 : '',
        address_city: parent.selClient.BusCity,
        address_state: parent.selClient.BusState,
        address_zip: parent.selClient.BusZip,
        bank_account: formData.AccountNumber,
        bank_routing: formData.RoutingNumber,
        bank_type: formData.TransCode == 27 ? 'checking' : 'savings',
        bank_holder_type: formData.isBusiness == 'true' ? 'business' : 'personal',
        method: 'bank',
        validate: false,
        send_receipt: false,
        url: 'https://omni.fattmerchant.com/#/bill/'
      };
    }
    // call pay api
    this.fattJs
      .tokenize(extraDetails)
      .then(result => {
        if (result.id) {
          cardObj['CompanyID'] = parent.selClient.CompanyID;
          cardObj['ClientID'] = parent.selClient.ClientID;
          cardObj['CardNo'] = result.card_last_four;
          cardObj['ExpiryDate'] = result.card_exp;
          cardObj['NameOnCard'] = result.person_name;
          cardObj['TransType'] = parent.isCCFlow ? 'CC' : 'ACH';
          cardObj['FirstName'] = extraDetails.firstname;
          cardObj['LastName'] = extraDetails.lastname;
          cardObj['scid'] = result.customer_id;
          cardObj['uid'] = result.id;
          cardObj['CardType'] = parent.isCCFlow ? result['card_type'] : result['bank_type'];
          parent.isDebitCard = result['bin_type'] == 'DEBIT' ? true : false;
          if (!parent.isCCFlow) {
            cardObj['BankAcctNo'] = formData['AccountNumber'].substr(
              formData['AccountNumber'].length - 4,
              formData['AccountNumber'].length
            );
            cardObj['isBusiness'] = formData.isBusiness == 'true' ? true : false;
          }
          parent.encrDecSrvc.addObject(AppConstants.isFormChanged, true);
          parent.isProcessing = false;
          if (parent.sTaxProfile == null || parent.sTaxProfile.length == 0) {
            parent.mangoAPISrvc.createCreditCardDetails(cardObj).subscribe(
              async (resultRes: any) => {
                parent.sTaxProfile = cardObj;
                parent.sTaxProfile['CustomerCardID'] = resultRes.id;
                parent.paymentProfile = parent.sTaxProfile;
                parent.paymentProfile['CustomerCardID'] = resultRes.id;
                parent.mangoAPISrvc.showLoader(false);
                parent.showStaxoption = false;
                parent.isStaxNewCard = false;
                await parent.calculateSurcharge();
              },
              (data: any) => {
                parent.mangoAPISrvc.showLoader(false);
              }
            );
          } else {
            parent.mangoAPISrvc
              .updateCreditCardDetails(parent.sTaxProfile.CustomerCardID, cardObj)
              .subscribe(
                async (resultRes: any) => {
                  //parent.sTaxProfile['StaxToken'] = cardObj['StaxToken'];
                  parent.sTaxProfile['NameOnCard'] = cardObj['NameOnCard'];
                  parent.sTaxProfile['CardNo'] = cardObj['CardNo'];
                  parent.sTaxProfile['CardType'] = cardObj['CardType'];
                  // parent.sTaxProfile['scid'] = cardObj['scid'];
                  // parent.sTaxProfile['StaxPaymentMethodID'] = cardObj['StaxToken'];
                  parent.sTaxProfile['isBusiness'] = cardObj['isBusiness'];
                  parent.paymentProfile = parent.sTaxProfile;
                  parent.mangoAPISrvc.showLoader(false);
                  parent.showStaxoption = false;
                  parent.isStaxNewCard = false;
                  await parent.calculateSurcharge();
                },
                (data: any) => {
                  parent.mangoAPISrvc.showLoader(false);
                }
              );
          }
        }
      })
      .catch(err => {
        parent.isProcessing = false;
        parent.mangoAPISrvc.showLoader(false);
        parent.isDialogFormValid = true;
        let msg = err['message'] ? err['message'] : null;
        if (!msg) {
          if (err.payment_attempt_message) {
            msg = err.payment_attempt_message;
          } else if (err.child_transactions && err.child_transactions.length > 0) {
            msg = err.child_transactions[0].message;
          } else if (err.error_description) {
            msg = err.error_description;
          } else {
            try {
              msg =
                typeof err === 'object'
                  ? Object.keys(err)
                      .map(k => err[k].join(' '))
                      .join(' ')
                  : JSON.stringify(err);
            } catch (error) {}
          }
        }
        Swal.fire({
          icon: 'error',
          title: parent.translate.instant('Transaction Not Approved'),
          text: msg,
          showConfirmButton: false,
          timer: 7000
        });
      });
  }

  addSTaxNewCard() {
    const parent = this;
    let formData = null;
    let extraDetails = null;
    let phNo = parent.selClient.Mobile ? parent.selClient.Mobile : parent.selClient.Office;
    phNo = phNo ? phNo.replace(/[{(___)-}]/g, '').trim() : '555-555-5555';
    phNo = phNo ? phNo : '555-555-5555';
    parent.mangoAPISrvc.showLoader(true);
    if (this.isCCFlow) {
      formData = parent.myPaymentForm.value;
      extraDetails = {
        total: numeral(formData.paymentAmount).value(),
        firstname: formData.fname,
        lastname: formData.lname,
        email: parent.selClient.Email,
        month: formData.expDate ? parseInt(formData.expDate.split('/')[0]) : null,
        year: formData.expDate ? parseInt(formData.expDate.split('/')[1]) : null,
        phone: phNo,
        address_1: parent.selClient.BusStreet1,
        address_2: parent.selClient.BusStreet2 ? parent.selClient.BusStreet2 : '',
        address_city: parent.selClient.BusCity,
        address_state: parent.selClient.BusState,
        address_zip: parent.selClient.BusZip,
        method: 'card',
        validate: false,
        send_receipt: false,
        meta: {
          otherField1:
            'from Manual Invoice | User : ' +
            this.userName +
            ' | ClientName :' +
            this.selClient['ClientName']
        }
      };
      // call pay api
      parent.fattJs
        .pay(extraDetails)
        .then(result => {
          if (result.id) {
            parent.getStaxToken(result);
          }
        })
        .catch(err => {
          parent.mangoAPISrvc.showLoader(false);
          parent.isDialogFormValid = true;
          let msg = err['message'] ? err['message'] : null;
          if (!msg) {
            if (err.payment_attempt_message) {
              msg = err.payment_attempt_message;
            } else if (err.child_transactions && err.child_transactions.length > 0) {
              msg = err.child_transactions[0].message;
            } else if (err.error_description) {
              msg = err.error_description;
            } else {
              try {
                msg =
                  typeof err === 'object'
                    ? Object.keys(err)
                        .map(k => err[k].join(' '))
                        .join(' ')
                    : JSON.stringify(err);
              } catch (error) {}
            }
          }
          Swal.fire({
            icon: 'error',
            title: parent.translate.instant('Transaction Not Approved'),
            text: msg,
            showConfirmButton: false,
            timer: 7000
          });
        });
    } else {
      parent.myAchReceiptsForm.controls['Amount'].setValue(
        parent.myPaymentForm.value.paymentAmount
      );
      parent.myAchReceiptsForm.controls['isBusiness'].setValue(parent.isBusiness);
      formData = parent.myAchReceiptsForm.value;

      extraDetails = {
        total: numeral(formData.Amount).value(),
        firstname: formData.fname,
        lastname: formData.lname,
        person_name: formData.fname + ' ' + formData.lname,
        email: parent.selClient.Email,
        phone: phNo,
        address_1: parent.selClient.BusStreet1,
        address_2: parent.selClient.BusStreet2 ? parent.selClient.BusStreet2 : '',
        address_city: parent.selClient.BusCity,
        address_state: parent.selClient.BusState,
        address_zip: parent.selClient.BusZip,
        method: 'bank',
        validate: false,
        url: 'https://omni.fattmerchant.com/#/bill/',
        bank_type: formData.TransCode == 27 ? 'checking' : 'savings',
        bank_account: formData.AccountNumber,
        bank_routing: formData.RoutingNumber,
        bank_holder_type: formData.isBusiness == 'true' ? 'business' : 'personal',
        send_receipt: false,
        meta: {
          otherField1:
            'from Manual Invoice | User : ' +
            this.userName +
            ' | ClientName :' +
            this.selClient['ClientName']
        }
      };

      parent.fattJs
        .pay(extraDetails)
        .then(result => {
          if (result.id) {
            parent.getStaxToken(result);
          }
        })
        .catch(err => {
          parent.isDialogFormValid = true;
          parent.mangoAPISrvc.showLoader(false);
          let msg = err['message'] ? err['message'] : null;
          if (!msg) {
            if (err.payment_attempt_message) {
              msg = err.payment_attempt_message;
            } else if (err.child_transactions && err.child_transactions.length > 0) {
              msg = err.child_transactions[0].message;
            } else if (err.error_description) {
              msg = err.error_description;
            } else {
              msg =
                typeof err === 'object'
                  ? Object.keys(err)
                      .map(k => err[k].join(' '))
                      .join(' ')
                  : JSON.stringify(err);
            }
          }
          Swal.fire({
            icon: 'error',
            title: parent.translate.instant('Transaction Not Approved'),
            text: msg,
            showConfirmButton: false,
            timer: 7000
          });
        });
    }
  }

  getStaxToken(result) {
    const cardObj = {};
    const parent = this;
    let formData = null;
    let extraDetails = null;
    let phNo = parent.selClient.Mobile ? parent.selClient.Mobile : parent.selClient.Office;
    phNo = phNo ? phNo.replace(/[{(___)-}]/g, '').trim() : '555-555-5555';
    phNo = phNo ? phNo : '555-555-5555';
    parent.transactionData = result;
    parent.isPaymentFlowRequired = true;
    if (this.isCCFlow) {
      formData = parent.myPaymentForm.value;
      extraDetails = {
        total: numeral(formData.paymentAmount).value(),
        firstname: formData.fname,
        lastname: formData.lname,
        email: parent.selClient.Email,
        month: parseInt(formData.expDate.split('/')[0]),
        year: parseInt(formData.expDate.split('/')[1]),
        phone: phNo,
        address_1: parent.selClient.BusStreet1,
        address_2: parent.selClient.BusStreet2 ? parent.selClient.BusStreet2 : '',
        address_city: parent.selClient.BusCity,
        address_state: parent.selClient.BusState,
        address_zip: parent.selClient.BusZip,
        send_receipt: false,
        method: 'card',
        validate: false
      };
    } else {
      parent.myAchReceiptsForm.controls['Amount'].setValue(
        parent.myPaymentForm.value.paymentAmount
      );
      parent.myAchReceiptsForm.controls['isBusiness'].setValue(parent.isBusiness);
      formData = parent.myAchReceiptsForm.value;
      extraDetails = {
        total: numeral(formData.Amount).value(),
        firstname: formData.fname,
        lastname: formData.lname,
        person_name: formData.fname + ' ' + formData.lname,
        phone: phNo,
        address_1: parent.selClient.BusStreet1,
        address_2: parent.selClient.BusStreet2 ? parent.selClient.BusStreet2 : '',
        address_city: parent.selClient.BusCity,
        address_state: parent.selClient.BusState,
        address_zip: parent.selClient.BusZip,
        bank_account: formData.AccountNumber,
        bank_routing: formData.RoutingNumber,
        bank_type: formData.TransCode == 27 ? 'checking' : 'savings',
        bank_holder_type: formData.isBusiness == 'true' ? 'business' : 'personal',
        method: 'bank',
        validate: false,
        send_receipt: false,
        url: 'https://omni.fattmerchant.com/#/bill/'
      };
    }

    this.fattJs
      .tokenize(extraDetails)
      .then(result => {
        if (result) {
          // saving into Mango DB
          cardObj['CompanyID'] = parent.selClient.CompanyID;
          cardObj['ClientID'] = parent.selClient.ClientID;
          cardObj['CardNo'] = result.card_last_four;
          cardObj['ExpiryDate'] = result.card_exp;
          cardObj['NameOnCard'] = result.person_name;
          cardObj['TransType'] = parent.isCCFlow ? 'CC' : 'ACH';
          cardObj['FirstName'] = formData.fname;
          cardObj['LastName'] = formData.lname;
          cardObj['scid'] = result.customer_id;
          cardObj['uid'] = result.id;
          cardObj['CardType'] = parent.isCCFlow ? result['card_type'] : result['bank_type'];
          if (!parent.isCCFlow) {
            cardObj['BankAcctNo'] = formData['AccountNumber'].substr(
              formData['AccountNumber'].length - 4,
              formData['AccountNumber'].length
            );
            cardObj['isBusiness'] = formData.isBusiness == 'true' ? true : false;
          }
          parent.encrDecSrvc.addObject(AppConstants.isFormChanged, true);
          if (parent.sTaxProfile == null || parent.sTaxProfile.length == 0) {
            parent.mangoAPISrvc.createCreditCardDetails(cardObj).subscribe(
              (resultRes: any) => {
                parent.mangoAPISrvc.showLoader(false);
                parent.calculateSummary();
                parent.processInvoice(false);
                //parent.mangoAPISrvc.notify('success', 'Success!', parent.translate.instant("Proccesed  successfully."));
                parent.closePaymentForm(true);
                Swal.fire({
                  icon: 'success',
                  title: parent.translate.instant('Success'),
                  html: parent.translate.instant('Transaction Approved.'),
                  showConfirmButton: false,
                  timer: 5000
                });
              },
              (data: any) => {
                parent.mangoAPISrvc.showLoader(false);
              }
            );
          } else {
            parent.mangoAPISrvc
              .updateCreditCardDetails(parent.sTaxProfile.CustomerCardID, cardObj)
              .subscribe(
                (resultRes: any) => {
                  parent.mangoAPISrvc.showLoader(false);
                  parent.calculateSummary();
                  parent.processInvoice(false);
                  //parent.mangoAPISrvc.notify('success', 'Success!', parent.translate.instant("Proccesed  successfully."));
                  parent.closePaymentForm(true);
                  Swal.fire({
                    icon: 'success',
                    title: parent.translate.instant('Success'),
                    html: parent.translate.instant('Transaction Approved.'),
                    showConfirmButton: false,
                    timer: 5000
                  });
                },
                (data: any) => {
                  parent.mangoAPISrvc.showLoader(false);
                }
              );
          }
        }
      })
      .catch(err => {
        parent.mangoAPISrvc.showLoader(false);
        let msg = err['message'] ? err['message'] : null;
        if (!msg) {
          if (err.payment_attempt_message) {
            msg = err.payment_attempt_message;
          } else if (err.child_transactions && err.child_transactions.length > 0) {
            msg = err.child_transactions[0].message;
          } else if (err.error_description) {
            msg = err.error_description;
          } else {
            try {
              msg =
                typeof err === 'object'
                  ? Object.keys(err)
                      .map(k => err[k].join(' '))
                      .join(' ')
                  : JSON.stringify(err);
            } catch (error) {}
          }
        }
        Swal.fire({
          icon: 'error',
          title: parent.translate.instant('Transaction Not Approved'),
          text: msg,
          showConfirmButton: false,
          timer: 7000
        });
      });
  }

  genarateIFrames() {
    const self = this;
    self.mangoAPISrvc.showLoader(true);
    setTimeout(() => {
      self.mangoAPISrvc.showLoader(false);
      const form = document.querySelector('form');
      // self should get from company table
      self.fattJs = new FattJs(self.mangoCompanyData.spkuid, {
        number: {
          id: 'fattjs-number',
          placeholder: '0000 0000 0000 0000',
          format: 'prettyFormat',
          style:
            'width: 96%; height:90%; border-radius: 3px; border: 1px solid #ccc; padding: .5em .5em; font-size: 91%;'
        },
        cvv: {
          id: 'fattjs-cvv',
          placeholder: '000',
          style:
            'width: 30%; height:90%; border-radius: 3px; border: 1px solid #ccc; padding: .5em .5em; font-size: 91%;'
        }
      });

      self.fattJs
        .showCardForm()
        .then(handler => {})
        .catch(err => {});

      self.fattJs.on('card_form_complete', message => {
        self.isDialogFormValid = message.validCvv && message.validNumber;
      });

      self.fattJs.on('card_form_uncomplete', message => {
        self.isDialogFormValid = message.validCvv && message.validNumber;
      });
    }, 500);
  }

  deleteClientAlert(itemData, index) {
    itemData['Billed'] = itemData['Billed'] ? itemData['Billed'] : false;
    itemData['MarkSlipsBilled'] = itemData['MarkSlipsBilled'] ? itemData['MarkSlipsBilled'] : false;
    if (itemData['Billed'] && !itemData['MarkSlipsBilled']) {
      this.mangoAPISrvc.notify(
        'error',
        'Error!',
        'This time entry has been billed. Deleting is not allowed.'
      );
      return false;
    } else {
      const parentObj = this;
      Swal.fire({
        title: parentObj.translate.instant('confirmation'),
        text: parentObj.translate.instant('delete_alert'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: parentObj.translate.instant('yes_delete'),
        cancelButtonText: parentObj.translate.instant('no_delete')
      }).then(result => {
        if (result.value) {
          parentObj.mangoAPISrvc.showLoader(true);
          parentObj.mangoAPISrvc.deleteTimeSheet(itemData.SlipMasterID).subscribe(function (data) {
            parentObj.mangoAPISrvc.showLoader(false);
            //parentObj.onDeleteSuccess(itemData.SlipMasterID);
            parentObj.mangoAPISrvc.notify(
              'success',
              parentObj.translate.instant('Success'),
              AppConstants.deleteMessage
            );
            parentObj.refreshTimeandExpenseRecords();
            //parentObj.refresh()
          }),
            error => {
              parentObj.mangoAPISrvc.notify(
                'error',
                parentObj.translate.instant('error'),
                AppConstants.deleteErrorMsg
              );
              parentObj.mangoAPISrvc.showLoader(false);
            };
        }
      });
    }
  }

  focusCard(index, element) {
    const iframeRef = $('iframe')[index];
    $(iframeRef).focus();
    $(iframeRef).contents().find(`#${element}`).focus();
  }

  addNewCreditCard() {
    this.isStaxNewCard = true;
    this.isProcessing = true;
    this.staxHeaderTaxt = this.translate.instant('billing-invoicing.credit-card-information');
    this.genarateIFrames();
    this.isDebitCard = false;
    if (this.mangoCompanyData.isSurchargeEnabled && this.paymentProfile) {
      this.paymentProfile['uid'] = null;
      this.myPaymentForm.controls['SurChargeAmount'].setValue(0);
      this.myPaymentForm.controls['withSurcharge'].setValue(0);
    }
  }

  async calculateSurcharge() {
    if (this.paymentProfile && this.mangoCompanyData.isSurchargeEnabled) {
      this.isDebitCard = false;
      this.mangoAPISrvc.showLoader(true);
      const processAmt = numeral(this.myPaymentForm.get('paymentAmount').value).value();
      const surchargeObj = await this.getSurchargeAmount(
        this.paymentProfile.CustomerCardID,
        processAmt,
        this.mangoCompanyData.CompanyID,
        'sc'
      );
      if (surchargeObj) {
        this.myPaymentForm.controls['SurChargeAmount'].setValue(surchargeObj['surcharge_amount']);
        this.myPaymentForm.controls['withSurcharge'].setValue(
          surchargeObj['total_with_surcharge_amount']
        );
        this.isDebitCard = surchargeObj['bin_type'] == 'DEBIT' ? true : false;
        if (this.isDebitCard) {
          this.staxHeaderTaxt = this.translate.instant('billing-invoicing.debit-card-information');
        }
      }
      this.mangoAPISrvc.showLoader(false);
    }
  }

  addNewBankCard() {
    const parent = this;
    this.isProcessing = true;
    this.isStaxNewCard = true;
    this.myAchReceiptsForm.controls['RoutingNumber'].setValue('');
    this.myAchReceiptsForm.controls['AccountNumber'].setValue('');
    this.myAchReceiptsForm.controls['FirstName'].setValue('');
    this.myAchReceiptsForm.controls['LastName'].setValue('');
    // this.myAchReceiptsForm.controls['Address1'].setValue('');
    // this.myAchReceiptsForm.controls['City'].setValue('');
    // this.myAchReceiptsForm.controls['State'].setValue('');
    // this.myAchReceiptsForm.controls['Zip'].setValue('');
  }

  isValidStaxForm() {
    const paymentFormData = this.myPaymentForm.value;
    const achFormData = this.myAchReceiptsForm.value;
    this.isProcessing = false;
    if (
      this.isCCFlow &&
      this.isStaxNewCard &&
      (!paymentFormData.fname ||
        !paymentFormData.lname ||
        !paymentFormData.address ||
        !paymentFormData.city ||
        !paymentFormData.state ||
        !paymentFormData.zip ||
        !paymentFormData.expDate)
    ) {
      this.isProcessing = true;
    } else if (
      !this.isCCFlow &&
      this.isStaxNewCard &&
      (!achFormData.RoutingNumber ||
        !achFormData.AccountNumber ||
        !achFormData.TransCode ||
        !achFormData.fname ||
        !achFormData.lname)
    ) {
      this.isProcessing = true;
    }
  }

  verifySystemLocking(e) {
    if (e == null) return;

    const dayToday = new Date(new Date().setHours(0, 0, 0, 0));
    const lockDay = new Date(new Date(dayToday).setDate(this.companySetting.effectiveLockDay));
    const monthToLock = new Date(
      new Date(lockDay).setMonth(lockDay.getMonth() - this.companySetting.monthsPreviousToLock)
    ).getMonth();
    const lastDayOfMonth = new Date(new Date().getFullYear(), monthToLock + 1, 0);

    if (
      this.companySetting.isEnableSystemLocking &&
      dayToday >= lockDay &&
      new Date(e) <= lastDayOfMonth
    ) {
      this.mangoAPISrvc.notify(
        'error',
        'Error!',
        'System Locking is enabled on the selected date.'
      );
    }
  }

  validateInvoiceDateTimeout: any;
  validateInvoiceDate() {
    if (typeof this.validateInvoiceDateTimeout != 'undefined') {
      clearTimeout(this.validateInvoiceDateTimeout);
      this.validateInvoiceDateTimeout = undefined;
    }

    this.validateInvoiceDateTimeout = setTimeout(() => {
      const invoiceDate: Date = this.manualInvoiceForm.controls['InvoiceDate']
        .value as unknown as Date;

      const dateToday = new Date();

      if (this.mangoUtils.dateDiff(invoiceDate, dateToday, 'months') > 2) {
        Swal.fire({
          icon: 'warning',

          title: `${this.translate.instant('Warning')}?`,
          text: `${this.translate.instant('two-month-gap-date-entry-warning')}!`,

          confirmButtonText: 'OK',

          showCancelButton: false,
          allowEscapeKey: true,
          allowEnterKey: true
        });
      }

      clearTimeout(this.validateInvoiceDateTimeout);
      this.validateInvoiceDateTimeout = undefined;
    }, 500);
  }

  onUpdateBilledAmt(data) {
    this.distributeInvoiceAmount(
      { ...this.selectedParent, amount: data.newBilledAmt },
      true,
      false
    );
    this.recomputeLineItems(this.selectedParent?.ProjectMasterID);
    this.calculateAllWUWDTotals(true);
    this.calcEngagementTimeSalesTax(
      this.searchValue?.nativeElement?.value == ''
        ? this.dtchild?._value
        : this.dtchild?.filteredValue
    );
  }

  preventTimeRecordUpdate(projectMasterId, childRowData, ri, event) {
    const lineItemsWithMatchedProjectIds = this.lineItems.filter(
      item => item['ProjectMasterID'] == projectMasterId
    );
    const sumupAllDiscount = lineItemsWithMatchedProjectIds.reduce(function (a, b) {
      return a + +numeral(b['discount']).value();
    }, 0);
    if (sumupAllDiscount > 0) {
      Swal.fire({
        icon: 'warning',
        title: this.translate.instant('Warning'),
        text: this.translate.instant('invoice.discount_in_placed'),
        showConfirmButton: true
      });
      this.dtchild.cancelRowEdit(childRowData);
      childRowData['IsColumnChanges'] = false;
      childRowData['IsRowEditing'] = false;
    } else {
      event.target.select();
    }
  }

  distributeDiscount() {
    const totalServices = numeral(this.grandInvAmt).value();
    const amountToDistribute =
      this.manualInvoiceForm.value.DiscountType === 'Flat Amount'
        ? numeral(this.manualInvoiceForm.value.DiscountValue).value()
        : (numeral(this.manualInvoiceForm.value.DiscountValue).value() / 100) *
          numeral(totalServices).value();
    if (amountToDistribute > totalServices) {
      Swal.fire({
        icon: 'warning',
        title: this.translate.instant('Warning'),
        text: this.translate.instant('billing.discount_greater_than_services')
      });
      return false;
    }

    let totalDiscountsApplied = 0;
    let lastLineItem = 0;
    this.lineItems.forEach(lineItem => {
      const lineItemAmount = numeral(lineItem.amount).value();
      lineItem.discount =
        '$' +
        numeral(
          this.roundOffDecimals((lineItemAmount / totalServices) * amountToDistribute)
        ).format('0,0.00');
      totalDiscountsApplied += numeral(lineItem.discount).value();
      lastLineItem = lineItem.LineItem;
    });
    if (amountToDistribute < totalDiscountsApplied) {
      const diff = this.mangoUtils.subtractFloat(totalDiscountsApplied, amountToDistribute);
      this.lineItems.forEach(lineItem => {
        if (lineItem.LineItem === lastLineItem) {
          lineItem.discount = this.roundOffDecimals(
            this.mangoUtils.subtractFloat(lineItem.discount, diff)
          );
          this.doMoneyFormate(lineItem, 'discount');
          return;
        }
      });
    } else if (amountToDistribute > totalDiscountsApplied) {
      const diff = this.mangoUtils.subtractFloat(amountToDistribute, totalDiscountsApplied);
      this.lineItems.forEach(lineItem => {
        if (lineItem.LineItem === lastLineItem) {
          lineItem.discount = this.roundOffDecimals(
            this.mangoUtils.addFloat(lineItem.discount, diff)
          );
          this.doMoneyFormate(lineItem, 'discount');
          return;
        }
      });
    }
    this.lineItems.forEach(lineItem => {
      this.calculateWUWD(lineItem.amount, lineItem, false, true);
      this.doMoneyFormate(lineItem, 'amount');
    });
  }

  clearDiscount() {
    this.manualInvoiceForm.controls['DiscountValue'].setValue(0);
    this.lineItems.forEach(lineItem => {
      lineItem.discount = '$' + numeral(0).format('0,0.00');
      this.calculateWUWD(lineItem.amount, lineItem, false, true);
      this.doMoneyFormate(lineItem, 'amount');
    });
  }
}
