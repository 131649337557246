<div class="time-to-invoice">
    <div class="layout-content p-pt-0">
        <span class="pull-right p-mr-3 clientform-btn-top">
            <button pButton pRipple label="{{'save_button' | translate}}" icon="fas fa-check"
                class="p-button-sm p-pr-2 p-mr-2" [disabled]="(selectedTimeRecords.length == 0)" (click)="processTimeRecords()"></button>
            <button pButton pRipple label="{{'clear' | translate}}" icon="fad fa-eraser"  class="p-button-sm p-pr-2 p-mr-2"
                [disabled]="(IsEnableHistory)" (click)="clearData()" [disabled]="(!isHistoryValid)" tooltipPosition="top" pTooltip="{{ 'clear' | translate }}"></button>
        </span>
        <form role="form" [formGroup]="timeSlipsInvoiceForm" novalidate>
            <div class="p-mx-2">
                <div class="p-col-12 p-pb-2 p-pt-0">
                    <div class="card p-py-2">
                        <div class="p-formgroup-inline">
                            <div class="p-field p-pb-0 p-mb-0">
                                <label for="View" class="p-mr-3 label-text  required-field">{{'client.name' |
                                    translate}}</label>
                                <p-autoComplete [(ngModel)]="selClient" [ngModelOptions]="{ standalone: true }"
                                    [suggestions]="filteredClients" autocomplete="off" (completeMethod)="filterClients($event)"
                                    field="ClientName" (onSelect)="handleSelectClick($event)" ngDefaultControl
                                    placeholder="Search Clients" (onFocus)="$event.target.select()" [dropdown]="true"
                                    required>
                                    <ng-template let-client pTemplate="item">
                                        <div>{{client.ClientName}}{{ client.ClientNumber ? ' - ' + client.ClientNumber : '' }}</div>
                                    </ng-template>
                                </p-autoComplete>
                            </div>
                            <div class="p-field p-pb-0 p-mb-0 p-ml-2">
                                <p-checkbox
                                    [binary]="true"
                                    label="Include All"
                                    [(ngModel)]="isIncludeAll"
                                    [ngModelOptions]="{ standalone: true }"
                                >
                                </p-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-pb-2 p-pt-0" *ngIf="isShowTable">
                    <div class="card p-py-2">
                        <div class="p-formgroup-inline">
                            <div class="p-field p-col-2 p-py-0 p-my-0">
                                <label class="label-text">{{'Accounts.invoice-date' | translate}}</label>
                                <div class="p-text-left p-d-block">{{timeSlipsInvoiceForm.get('InvoiceDate').value}}
                                </div>
                            </div>
                            <div class="p-field p-col-2 p-py-0 p-my-0">
                                <label class="label-text">{{'Accounts.invoice-number' | translate}}</label>
                                <div class="p-text-left p-d-block">{{timeSlipsInvoiceForm.get('InvoiceNumber').value}}
                                </div>
                            </div>
                            <div class="p-field p-col-2 p-py-0 p-my-0">
                                <label class="label-text">{{'Accounts.invoice-amount' | translate}}</label>
                                <div class="p-text-left p-d-block">{{timeSlipsInvoiceForm.get('InvoiceAmount').value}}
                                </div>
                            </div>
                            <div class="p-field p-col-6 p-py-0 p-my-0">
                                <label class="label-text">{{'billing-invoicing.invoice-description' |
                                    translate}}</label>
                                <div class="p-text-left p-d-block">
                                    {{timeSlipsInvoiceForm.get('DescriptionShort').value}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-pt-0" *ngIf="isShowTable">
                    <div class="card p-pt-0">
                        <p-table #dt [value]="timeSlipsDataSource" [rowHover]="true" [responsive]="true" [rows]="10"
                            dataKey="SlipMasterID" selectionMode="single" [showCurrentPageReport]="true" [pageLinks]="3"
                            [rowsPerPageOptions]="[10,25,50]" [filterDelay]="0"
                            styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo" [scrollHeight]="'calc(90vh - 400px)'" [scrollable]="true"
                            [globalFilterFields]="globalFilterColumns"
                            [resizableColumns]="true" [(selection)]="selectedTimeRecords" (onFilter)="onFilterTimeRecords($event, dt)" (onHeaderCheckboxToggle)="parentCheckboxSelection($event)"
                            (onRowSelect)="parentCheckboxSelection($event)"
                            (onRowUnselect)="parentCheckboxSelection($event)" [columns]="selectedColumns">
                            <ng-template pTemplate="caption">
                                <div class="table-header">
                                    <span class="p-input-icon-left">
                                        <i class="fal fa-search"></i>
                                        <input #searchValue pInputText type="text" (input)="globalSearch($event)" placeholder="{{'globalsearch' | translate}}" />
                                    </span>
                                    <span>
                                        <button pButton pRipple type="button" icon="pi pi-times"
                                            class="p-button-rounded p-button-danger p-button-text"
                                            (click)="dt.reset();clearSearchFilter()" tooltipPosition="top"
                                            pTooltip="{{'clear' | translate}}"></button>
                                    </span>
                                    <span>
                                        <p-message *ngIf="filteredItemsSize > -1" severity="success"
                                            text="{{filteredItemsSize}} {{'records_found' | translate}}."
                                            styleClass="p-ml-2"></p-message>
                                    </span>
                                    <span>
                                        <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header" class="p-mr-2 p-d-flex p-jc-end"
                                            [ngModelOptions]="{standalone: true}" selectedItemsLabel="{0} columns selected" [style]="{minWidth: '200px', marginTop: '-35px'}" placeholder="Choose Columns">
                                        </p-multiSelect>
                                    </span>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header" let-columns>
                                <tr>
                                    <th class="width-3p p-text-center">
                                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                    </th>
                                    <th class="width-6p p-text-center" pSortableColumn="Ddate">
                                        {{'date' | translate}}
                                        <p-sortIcon field="Ddate"></p-sortIcon>
                                    </th>
                                    <!-- <th class="width-8p p-text-center" pSortableColumn="StaffName">
                                        {{'user-title' | translate}}
                                        <p-sortIcon field="StaffName"></p-sortIcon>
                                    </th>
                                    <th class="width-10p p-text-left" pSortableColumn="EngagementName">
                                        {{'Engagements' | translate}}
                                        <p-sortIcon field="EngagementName"></p-sortIcon>
                                    </th>
                                    <th class="width-10p p-text-left" pSortableColumn="Description">
                                        {{'work.description' | translate}}
                                        <p-sortIcon field="Description"></p-sortIcon>
                                    </th> -->
                                    <th *ngFor="let col of columns" class="{{col.rowClass}}" [pSortableColumn]="col.field" class="p-text-left">
                                        {{col.header}}
                                        <p-sortIcon [field]="col.field"></p-sortIcon>
                                    </th>
                                    <th class="width-5p p-text-right">{{'Time' | translate}}</th>
                                    <th class="width-7p p-text-right">{{'billable-amount' | translate}}</th>
                                    <th class="width-7p p-text-right">{{'writes-up-down' | translate}}</th>
                                    <th class="width-7p p-text-right">
                                        {{'billed-amount' | translate}}
                                    </th>
                                    <th class="width-13p p-text-left">
                                        {{'Memo' | translate}}
                                    </th>
                                    <th class="width-5p p-text-center" pSortableColumn="Billed">
                                        {{'billed' | translate}}
                                        <p-sortIcon field="Billed"></p-sortIcon>
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-ri="rowIndex" let-rowData let-columns="columns">
                                <tr class="cursor-hand">
                                    <td class="width-3p p-text-center">
                                        <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                                    </td>
                                    <td class="width-6p p-text-center">
                                        {{ mangoUtils.formatDateString(rowData.Ddate) | date:'MM/dd/yyyy'}}
                                    </td>
                                    <!-- <td class="width-8p p-text-center">
                                        {{rowData.StaffName}}
                                    </td>
                                    <td class="width-10p p-text-left">
                                        {{rowData.EngagementName}}
                                    </td>
                                    <td class="width-10p p-text-left">
                                        {{rowData.Description}}
                                    </td> -->
                                    <td *ngFor="let col of columns" class="{{col.rowClass}} p-text-truncate"
                                        pTooltip="{{rowData[col.field]}}" showDelay="800"
                                        tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top">
                                        {{ rowData[col.field] }}
                                    </td>
                                    <td class="width-5p p-text-right">
                                        {{rowData.TotalTime}}
                                    </td>
                                    <td class="width-7p p-text-right">
                                        {{rowData.StandardAmount | currency:'USD':'symbol':'1.2-2'}}
                                    </td>
                                    <td class="width-7p p-text-right">
                                        {{rowData.WriteUpDown | currency:'USD':'symbol':'1.2-2'}}
                                    </td>
                                    <td class="width-7p p-text-right">
                                        {{rowData.BilledAmount | currency:'USD':'symbol':'1.2-2'}}
                                    </td>
                                    <td class="width-13p p-text-left" pTooltip="{{rowData.Memo}}" showDelay="800"
                                        tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top">
                                        {{rowData.Memo}}
                                    </td>
                                    <td class="width-5p p-text-center">
                                        <i *ngIf="rowData['Billed']" class="fa fa-lock" aria-hidden="true"></i>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="10">
                                        <p-message severity="info" text="{{'data_not_found' | translate}}."
                                            styleClass="p-px-2"></p-message>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="footer" let-columns>
                                <tr *ngIf="(filteredItemsSize > 0) || (filteredItemsSize == -1 && timeSlipsDataSource.length > 0)">
                                    <td class="width-3p p-text-center"></td>
                                    <td *ngFor="let col of columns" class="{{col.rowClass}}"></td>
                                    <td class="width-6p p-text-right ic-red">{{'Totals' | translate}}</td>
                                    <td class="width-5p p-text-right ic-red">
                                        <span>{{ TotalTime }}</span>
                                    </td>
                                    <td class="width-7p p-text-right ic-red">
                                        <span>{{ totalStandarAmount | currency:'USD':true:'1.2-2' }}</span>
                                    </td>
                                    <td class="width-7p p-text-right ic-red">
                                        <span>{{ totalWriteUpDown | currency:'USD':true:'1.2-2' }}</span>
                                    </td>
                                    <td class="width-7p p-text-right ic-red">
                                        <span>{{ totalBilledAmount | currency:'USD':true:'1.2-2' }}</span>
                                    </td>
                                    <td class="width-13p"></td>
                                    <td class="width-5p"></td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<p-dialog [(visible)]="historyDisplay" [style]="{width: '63vw'}" [modal]="true" [maximizable]="false" showEffect="fade"
    [baseZIndex]="1" [draggable]="true" [resizable]="false" header="{{ 'select_client_invoice' | translate }}">
    <p-table [rowHover]="true" [responsive]="true" [rows]="7" [value]="historyDataSource" [showCurrentPageReport]="true"
        [pageLinks]="3" [filterDelay]="0" [scrollHeight]="'calc(90vh - 360px)'" [scrollable]="true"
        styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo" dataKey="InvoiceNumber"
        [globalFilterFields]="['InvoiceDate','InvoiceNumber','InvoiceAmount','DescriptionShort','EngagementName']"
        (onFilter)="onFilter($event)" sortField="InvoiceDate"
        [sortOrder]="-1" #historydt>
        <ng-template pTemplate="caption">
            <div class="table-header">
                <span class="p-input-icon-left">
                    <i class="fal fa-search"></i>
                    <input #searchValue pInputText type="text"
                        (input)="historydt.filterGlobal($event.target.value, 'contains')"
                        placeholder="{{'globalsearch' | translate}}" />
                </span>
                <span>
                    <button pButton pRipple type="button" icon="pi pi-times"
                        class="p-button-rounded p-button-danger p-button-text"
                        (click)="historydt.reset();clearSearchFilter()" tooltipPosition="top"
                        pTooltip="{{'clear' | translate}}"></button>
                </span>
                <p-message *ngIf="filteredItemsSize > -1" severity="success"
                    text="{{filteredItemsSize}} {{'records_found'}}." styleClass="p-ml-2"></p-message>
            </div>
        </ng-template>
        <!-- Table Header -->
        <ng-template pTemplate="header">
            <tr>
                <th class="p-text-left width-9p" pSortableColumn="InvoiceDate">
                    {{'Accounts.invoice-date' | translate}}
                    <p-sortIcon field="InvoiceDate"></p-sortIcon>
                </th>
                <th class="p-text-left width-9p">
                    {{'engagement.name' | translate}}
                </th>
                <th class="p-text-left width-7p" pSortableColumn="InvoiceNumber">
                    {{'Invoice' | translate}} #
                    <p-sortIcon field="InvoiceNumber"></p-sortIcon>
                </th>
                <th class="p-text-left width-20p">
                    {{'invoice-memo' | translate}}
                </th>
                <th class="p-text-right width-10p" pSortableColumn="InvoiceAmount">
                    {{'Accounts.invoice-amount' | translate}}
                    <p-sortIcon field="InvoiceAmount"></p-sortIcon>
                </th>
            </tr>
        </ng-template>
        <!-- Rows -->
        <ng-template pTemplate="body" let-rowData>
            <tr class="cursor-hand" (click)="handleHistoryClick(rowData);">
                <td class="p-text-left width-9p">
                    {{ mangoUtils.formatDateString(rowData.InvoiceDate) | date: "MM/dd/yyyy" }}
                </td>
                <td class="p-text-left p-text-nowrap p-text-truncate width-9p" tooltipPosition="top"
                    pTooltip="{{ rowData.EngagementName }}" showDelay="800" tooltipStyleClass="tooltip-no-wrap">
                    {{rowData.EngagementName}}
                </td>
                <td class="p-text-left width-7p">
                    {{ rowData.InvoiceNumber }}
                </td>
                <td class="p-text-left p-text-nowrap p-text-truncate width-20p" tooltipPosition="top"
                    pTooltip="{{ rowData.DescriptionShort }}" showDelay="800" tooltipStyleClass="tooltip-no-wrap">
                    {{ rowData.DescriptionShort }}
                </td>
                <td class="p-text-right width-10p">${{ rowData.InvoiceAmount }}</td>
            </tr>
        </ng-template>
        <!-- No data -->
        <ng-template pTemplate="emptymessage">
            <tr>
                <td [attr.colspan]="5">
                    <p-message severity="info" text="{{'data_not_found' | translate}}." styleClass="p-px-2"></p-message>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <p-footer>
        <button type="button" pButton pRipple icon="fal fa-times" label=" {{'cancel_button' | translate}}" (click)="historyDisplay = false"></button>
    </p-footer>
</p-dialog>
