import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { forkJoin } from 'rxjs';
import { BreadcrumbService, EncrDecrService, MangoApiService } from '@app/_services';
import { AppConstants } from '@app/_helpers/api-constants';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-master-template-library',
  styleUrl: './master-template-library.component.scss',
  templateUrl: './master-template-library.component.html'
})
export class MasterTemplateLibraryComponent implements OnInit {
  filteredItemsSize = -1;
  public libDetailsDataSource: any = [];
  public expandedRows: { [key: string]: boolean } = {};
  public libDataSource: any = [];
  @ViewChild('searchValue') searchValue;
  @ViewChild('searchInput', { static: false }) searchInput: ElementRef;
  public selectedLibItems: any = [];
  public isDisplayDetailsTable: boolean = false;
  public dialogHeaderText: any = "";
  public ddmsettingsObj: any = {};
  public filteredDataSource: any[] = [];
  companyId;

  constructor(
    private mangoAPISrvc: MangoApiService, 
    private encrDecSrvc: EncrDecrService, 
    private breadcrumbService: BreadcrumbService,
    private translate: TranslateService
  ) {
    this.companyId = this.encrDecSrvc.getObject(AppConstants.companyID);

    this.translate.reloadLang(this.translate.currentLang).subscribe(data=>{
      this.breadcrumbService.setItems([
        { label: this.translate.instant('Project-Management') },
        { label: this.translate.instant('pm.master-template-library'), icon: 'ic-red' },
      ]);
    })
  }

  ngOnInit(): void {
    this.fetchDataSource();
    this.fetchDdmSettings();
  }

  fetchDdmSettings() {
    this.ddmsettingsObj = this.encrDecSrvc.getObject(AppConstants.companyTags);
  }

  fetchDataSource() {
    const parent = this;

    parent.mangoAPISrvc.showLoader(true);

    parent.mangoAPISrvc.getMasterTemplateHeader().subscribe(function (data: any) {
      data.map(function (obj) {
        obj['isSaved'] = false;
        return obj;
      });
      const mySet = new Set(data.map(item => item.Category));
      const finalObj = [];
      mySet.forEach(function (value, index) {
        const obj = { 'templateName': null, 'index': null, 'childItems': [] };
        obj['templateName'] = value;
        const childItems = data.filter((item) => item['Category'] == value);
        obj['SearchField'] = `${obj['SearchField']} ${childItems?.map((item) => item.TemplateName)}`
        childItems.sort((a, b) => a.TemplateName.localeCompare(b.TemplateName));
        obj['childItems'] = childItems;
        obj['hasChildrens'] = (childItems.length > 0) ? true : false;
        obj['allSelectedItems'] = [];
        finalObj.push(obj);
        parent.expandedRows[obj['templateName']] = false;
      })
      parent.libDataSource = finalObj;
      parent.filteredDataSource = [...finalObj]; 
      parent.mangoAPISrvc.showLoader(false);
    }, (error) => {
      parent.mangoAPISrvc.showLoader(false);
    })
  }

  applyFilter(query: string) {
    console.log('query')
    if (!query) {
      this.filteredDataSource = [...this.libDataSource];
    } else {
      this.filteredDataSource = this.libDataSource.filter(item => {
        const searchField = item.SearchField || '';
        return searchField.toLowerCase().includes(query.toLowerCase());
      });
    }
    this.filteredItemsSize = this.filteredDataSource.length;
  }

  clearSearchFilter() {
    this.filteredDataSource = [...this.libDataSource];
    this.filteredItemsSize = -1;
    if (this.searchInput) {
      this.searchInput.nativeElement.value = '';
    }
  }
  toggleRow(rowData) {
    this.expandedRows[rowData.templateName] = !this.expandedRows[rowData.templateName];
  }

  createCompanyTemplateDetails(selectedLibItems) {
    const observableBatch = [];
    const parent = this;
    selectedLibItems.forEach((selectedItem, key) => {
      observableBatch.push(parent.mangoAPISrvc.addCompanyTemplateDetails(selectedItem));
    });
    return forkJoin(observableBatch);
  }

  addtoCompanyLibrary(rowObj) {
    const parent = this;
    rowObj['libDetailsDataSource'] = [];

    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getMasterTemplateDetails(rowObj.MasterTemplateHeaderID).subscribe(function (data) {
      rowObj['libDetailsDataSource'] = data;

      const obj = {};
      obj['CompanyID'] = parent.companyId;
      obj['Repeat'] = rowObj.Repeat;
      obj['TemplateName'] = rowObj.TemplateName;
      obj['ManagerID'] = parent.ddmsettingsObj.DefaultManagerID;
      obj['UserAssignedID'] = parent.ddmsettingsObj.DefaultUserID;

      parent.mangoAPISrvc.createCompanyTemplateHeader(obj).subscribe(function (data) {
        rowObj['CompanyTemplateHeaderID'] = data['id'];
        const detailsSize = rowObj['libDetailsDataSource'].length > 0 ? rowObj['libDetailsDataSource'].length : 0;
        if (detailsSize == 0) {
          const detailsObj = {};
          detailsObj['CompanyID'] = parent.companyId;
          detailsObj['CompanyTemplateHeaderID'] = rowObj.CompanyTemplateHeaderID;
          parent.mangoAPISrvc.addCompanyTemplateDetails(detailsObj).subscribe(function (data: any) {
            rowObj.isSaved = true;
            parent.mangoAPISrvc.notify('success', 'Success!', data.message);
          }, (error) => {
            parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.createErrorMsg);
          });
        } else {
          const dataSource = [];
          for (let index = 0; index < rowObj['libDetailsDataSource'].length; index++) {
            const element = rowObj['libDetailsDataSource'][index];
            const detailsObj = {};
            detailsObj['CompanyID'] = parent.companyId;
            detailsObj['CompanyTemplateHeaderID'] = rowObj.CompanyTemplateHeaderID;
            detailsObj['TaskDescription'] = element.TaskDescription;
            detailsObj['TaskRow'] = element.TaskRow;
            dataSource.push(detailsObj);
          }

          parent.createCompanyTemplateDetails(dataSource)
            .subscribe((data: any) => {
              rowObj.isSaved = true;
              parent.mangoAPISrvc.notify('success', 'Success!', data.message);
              parent.mangoAPISrvc.showLoader(false);
            }, (error) => {
              parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.createErrorMsg);
              parent.mangoAPISrvc.showLoader(false);
            });
        }
      }, (error) => {
        parent.mangoAPISrvc.showLoader(false);
      })
    }, (error) => {
        parent.mangoAPISrvc.showLoader(false);
    })
  }

  onFilter(obj) {
    this.filteredItemsSize = obj.filteredValue.length;
  }

  searchDetails(data) {
    const parent = this;
    parent.isDisplayDetailsTable = true;
    parent.dialogHeaderText = data.TemplateName;

    parent.mangoAPISrvc.showLoader(true);

    parent.mangoAPISrvc.getMasterTemplateDetails(data.MasterTemplateHeaderID).subscribe(function (data) {
      parent.libDetailsDataSource = data;
      parent.mangoAPISrvc.showLoader(false);
    }, (error) => {
      parent.mangoAPISrvc.notify('error', 'Error!', AppConstants.fetchErrorMsg);
      parent.mangoAPISrvc.showLoader(false);
    });

  }
}
