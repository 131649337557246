<div class="merchant-setup">
    <div class="layout-content">
        <div class="p-col-12 p-px-2" *ngIf="isapiLoaded">
            <div class="card p-py-3" *ngIf="mangoCompanyData &&  !mangoCompanyData.isStaxEnrollmentStarted">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-col-4">
                        <img alt="logo" src="assets/img/Mango-payments.png" height="40px" width="270px"
                            class="pull-left" />
                    </div>
                    <div class="p-col-4 p-text-center ic-red enroll-header-text">
                        Enrollment Application
                    </div>
                    <div class="p-col-4">
                        <img alt="logo" src="assets/img/cards.png" height="40px" width="240px" class="pull-right" />
                    </div>
                </div>
                <!-- form -->
                <form #userForm="ngForm" autocomplete="off">
                    <div class="p-fluid p-formgrid p-grid  p-mt-5">
                        <div class="p-col-5">
                            <div class="p-field p-col-12">
                                <label class="label-text required-field">Company Name</label>
                                <input [(ngModel)]="sTaxObj.merchant.company_name" type="text" autocomplete="off"
                                    name="companyName" #companyName="ngModel" required (blur)="isFormChanged()"
                                    pInputText>
                            </div>
                            <div class="p-field p-col-12">
                                <label class="label-text required-field">Name</label>
                                <input [(ngModel)]="sTaxObj.merchant.contact_name" type="text" (blur)="isFormChanged()"
                                    required autocomplete="off" pInputText>
                            </div>
                            <div class="p-field p-col-12">
                                <label class="label-text required-field">Email</label>
                                <input [(ngModel)]="sTaxObj.merchant.contact_email" autocomplete="off"
                                    [pattern]="emailPattern" (blur)="isFormChanged()" type="email" name="emailId"
                                    #userEmail="ngModel" required pInputText [email]="true">
                                <div *ngIf="userEmail.errors?.pattern" class="ic-red">
                                    Email not valid.
                                </div>
                            </div>
                            <!-- <div class="p-field p-col-12">
                            <label class="label-text required-field">Contact Email</label>
                            <input [(ngModel)]="sTaxObj.contact_email" type="email" autocomplete="nope" required
                                pInputText>
                        </div> -->

                        </div>
                        <div class="p-col-5">
                            <div class="p-field p-col-12">
                                <label class="label-text required-field">Password</label>
                                <input [(ngModel)]="sTaxObj.user.password" type="password" autocomplete="off" minlength="8"
                                    required name="password" #password="ngModel" (blur)="isFormChanged()" pInputText>
                                <div *ngIf="password.errors?.minlength" class="ic-red">
                                    Password must be at least 8 characters long.
                                </div>
                            </div>
                            <div class="p-field p-col-12">
                                <label class="label-text required-field">Password Confirmation</label>
                                <input [(ngModel)]="sTaxObj.user.password_confirmation" type="password" autocomplete="off"
                                    (blur)="isFormChanged()" minlength="8" required name="confirmation"
                                    #confirmation="ngModel" required pInputText>
                                <div *ngIf="confirmation.errors?.minlength" class="ic-red">
                                    Password Confirmation must be at least 8 characters long and do match.
                                </div>
                            </div>
                            <div class="p-field p-col-12">
                                <label class="label-text required-field">Why are you applying for merchant
                                    processing?</label>
                                <p-dropdown [options]="processingDataSet" scrollHeight="400px"
                                    [(ngModel)]="sTaxObj.registration.reason_for_applying" name="reasonforchange"
                                    #reasonforchange="ngModel">
                                </p-dropdown>
                            </div>
                        </div>

                        <div class="p-grid row-credit-card">
                          <div class="p-field p-col-4">
                            <label class="label-text required-field">Annual Volume</label>
                            <input [(ngModel)]="sTaxObj.registration.annual_volume" type="text" name="annualVolume" required pInputText pTooltip="Enter the annual volume in numeric format">
                            <div *ngIf="sTaxObj.registration.annual_volume === '0'" class="ic-red">
                              Annual Volume cannot be zero.
                            </div>
                          </div>
                          <div class="p-field p-col-4">
                            <label class="label-text required-field">Highest Transaction Amount</label>
                            <input [(ngModel)]="sTaxObj.registration.highest_trans_amount" type="text" name="highestTransAmount" required pInputText pTooltip="Enter the highest transaction amount in numeric format">
                            <div *ngIf="sTaxObj.registration.highest_trans_amount === '0'" class="ic-red">
                              Highest Transaction Amount cannot be zero.
                            </div>
                          </div>
                          <div class="p-field p-col-4">
                            <label class="label-text required-field">Average Transaction Size</label>
                            <input [(ngModel)]="sTaxObj.registration.avg_trans_size" type="text" name="avgTransSize" required pInputText pTooltip="Enter the average transaction size in numeric format">
                            <div *ngIf="sTaxObj.registration.avg_trans_size === '0'" class="ic-red">
                              Average Transaction Size cannot be zero.
                            </div>
                          </div>
                        </div>



                        <div class="p-col-12">
                            <button type="button" pButton pRipple label="{{ 'save.enrollment' | translate }}"
                                [disabled]="userForm.invalid || sTaxObj.user.password < 8 || sTaxObj.user.password != sTaxObj.user.password_confirmation"
                                class="p-mr-1 p-button-sm pull-right" style="width: 10%;"
                                (click)="openStaxSurchargeConditions()"
                                *ngIf="mangoCompanyData &&  !mangoCompanyData.isStaxEnrollmentStarted"></button>
                        </div>
                    </div>
                </form>
            </div>
            <div *ngIf="mangoCompanyData && mangoCompanyData.isStaxEnrollmentStarted">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-col-5">
                        <h1>Business Info</h1>
                    </div>
                    <div class="p-col-7">
                        <img alt="logo" src="assets/img/Mango-payments.png" height="40px" width="270px"
                            class="pull-right" />
                    </div>
                </div>
                <div class="p-grid nested-grid">
                    <div class="p-col-3">
                        <div class="p-grid">
                            <div class="p-col-12" *ngIf="StaxUnderwriting.underwriting_status !== 'REJECTED'">
                                <div class="box p-px-0 p-pt-0">
                                    <div class="orange-box p-p-0">
                                        <div class="p-px-3 p-py-4">
                                            <h4 class="p-text-bold p-mb-2 ic-white">
                                                {{Staxassumedata.company_name}}
                                            </h4>
                                        </div>
                                    </div>
                                    <div class="white-box label-color p-my-3">

                                        <div class="p-pl-3" *ngIf="Staxassumedata.contact_phone||StaxUnderwriting.business_location_phone_number"><i
                                            class="fas fa-phone p-pr-2 ic-size-15" aria-hidden="true"></i>
                                        <span class="ic-size-15">{{Staxassumedata.contact_phone||StaxUnderwriting.business_location_phone_number |
                                            slice:0:3}}-{{Staxassumedata.contact_phone||StaxUnderwriting.business_location_phone_number |
                                            slice:3:6}}-{{Staxassumedata.contact_phone||StaxUnderwriting.business_location_phone_number |
                                            slice:6:10}}</span>
                                    </div>
                                    <div class="p-pl-3 p-pt-2 ic-size-15" *ngIf="Staxassumedata.contact_email||StaxUnderwriting.business_email"><i
                                            class="fas fa-envelope p-pr-2 ic-size-15"
                                            aria-hidden="true"></i>{{Staxassumedata.contact_email||StaxUnderwriting.business_email}}</div>
                                    <div class="p-pl-3 p-pt-2 p-pb-1"><i
                                            class="fas fa-map-marker-alt p-pr-2 ic-size-15" aria-hidden="true"></i>
                                        <span>{{Staxassumedata.address_1||StaxUnderwriting.business_address_1}}&nbsp;</span>
                                        <span>{{Staxassumedata.address_2||StaxUnderwriting.business_address_2}}&nbsp;</span>
                                        <span>{{Staxassumedata.address_city||StaxUnderwriting.business_address_city}},&nbsp;</span>
                                        <span>{{Staxassumedata.address_state||StaxUnderwriting.business_location_address_state}}&nbsp;</span>
                                        <span>{{Staxassumedata.address_zip||StaxUnderwriting.business_location_address_zip}}</span>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-12"
                                *ngIf="Staxassumedata.status === 'ACTIVE' && StaxUnderwriting.underwriting_status !== 'PENDED' && StaxUnderwriting.underwriting_status !== 'REJECTED'">
                                <div class="box p-px-0 p-pt-0">
                                    <div class="orange-box p-p-0">
                                        <div class="p-p-3">
                                            <h4 class="p-text-bold p-mb-2 ic-white">
                                                Transactions
                                            </h4>
                                        </div>
                                    </div>
                                    <div class="p-py-4">
                                        <div class="p-grid">
                                            <div class="p-col-12 p-py-0">
                                                <div class="p-grid">
                                                    <div class="p-col-12 ">
                                                        <p-button label="View Transaction History"
                                                            styleClass="p-button-link ic-size-15 ic-red text-decoration"
                                                            class="ic-red text-decoration"
                                                            (click)="redirectTransation()">
                                                        </p-button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="p-col-12 p-py-0">
                                                <div class="p-grid">
                                                    <div class="p-col-12 ">
                                                        <p-button label="Mango Payments Portal"
                                                            (click)="logintoStax(false)"
                                                            styleClass="p-button-link ic-size-15 ic-red text-decoration"
                                                            class="ic-red text-decoration">
                                                        </p-button>
                                                    </div>
                                                    <!-- <div class="p-col-12 ">
                                                        <p-button label="Deposit Report" (click)="logintoStaxReports('report%2Fdeposit-report%3F')"
                                                            styleClass="p-button-link ic-size-15 ic-red text-decoration"
                                                            class="ic-red text-decoration">
                                                        </p-button>
                                                    </div> -->
                                                </div>
                                            </div>
                                            <!-- <div class="p-col-12 p-py-0" *ngIf="mangoCompanyData.StaxToken_Old != null">
                                                <div class="p-grid">
                                                    <div class="p-col-12 ">
                                                        <p-button label="Mango Payments Portal (OLD)"
                                                            styleClass="p-button-link ic-size-15 ic-red text-decoration"
                                                            class="ic-red text-decoration"
                                                            (click)="logintoStax(true)">
                                                        </p-button>
                                                    </div>
                                                </div>
                                            </div> -->
                                            <div class="p-col-12 p-py-0">
                                                <div class="p-grid">
                                                    <div class="p-col-12 ">
                                                        <p-button label="Reports"
                                                            (click)="logintoStaxReports('reports')"
                                                            styleClass="p-button-link ic-size-15 ic-red text-decoration"
                                                            class="ic-red text-decoration">
                                                        </p-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-4">
                        <div class="p-grid">
                            <div class="p-col-12">
                                <div>
                                    <!--  redirect button code -->
                                    <div
                                        *ngIf="!mangoCompanyData.isStMIdPresents && !StaxUnderwriting.electronic_signature">
                                        <button style="width:100%" type="button" pButton pRipple
                                            label="Attention Required >> Click Here to Update Enrollment Application"
                                            icon="far fa-exclamation-circle" class="p-button-sm"
                                            (click)="finishEnrollment()"></button>
                                    </div>
                                    <!-- after filling the form   -->
                                    <div class="p-p-0" *ngIf="mangoCompanyData.isStMIdPresents">
                                        <div class="white-box">
                                            <div class="p-px-3 p-pt-3 p-py-2">
                                                <h3 class="p-text-bold p-mb-2 label-color">Application
                                                    Status
                                                </h3>
                                                <div>
                                                    <span
                                                        class="ic-red  p-text-bold font-15size">{{StaxUnderwriting.underwriting_status
                                                        || mangoCompanyData.StaxStatus || 'AWAITING
                                                        REVIEW'}}
                                                    </span>
                                                    <span class="ic-red  p-text-bold font-15size conditional-msg" (click)="showUnderWritingConditions()"
                                                        *ngIf="StaxUnderwriting.underwriting_status && StaxUnderwriting.underwriting_status === 'APPROVED' && StaxUnderwriting.underwriting_substatuses && StaxUnderwriting.underwriting_substatuses.length > 0">
                                                        <i class="fas fa-hand-holding-usd"> <span
                                                                class="p-px-3">Conditional
                                                                Approval</span> </i>
                                                    </span>
                                                </div>
                                                <div class="p-mt-3 stax"
                                                    *ngIf="StaxUnderwriting.underwriting_status && StaxUnderwriting.underwriting_status === 'APPROVED' && StaxUnderwriting.underwriting_substatuses && StaxUnderwriting.underwriting_substatuses.length > 0">
                                                    <p-message severity="warn"
                                                        text="Payouts will be held until this account receives full approval.">
                                                    </p-message>
                                                </div>
                                            </div>
                                            <div class="p-p-3">
                                                <h3 class="p-text-bold p-mb-2 label-color">Account Status
                                                </h3>
                                                <!-- <h5 class="ic-red">{{Staxassumedata.merchant.status}}</h5> -->
                                                <h4 class="ic-red p-text-bold"
                                                    *ngIf="StaxUnderwriting.underwriting_status !== 'REJECTED'">
                                                    {{Staxassumedata.status}}
                                                </h4>
                                                <h4 class="ic-red p-text-bold"
                                                    *ngIf="StaxUnderwriting.underwriting_status === 'REJECTED'">REJECTED
                                                </h4>
                                            </div>
                                        </div>
                                        <!-- <div class="orange-box"
                                            *ngIf="((StaxUnderwriting.underwriting_substatuses && StaxUnderwriting.underwriting_substatuses.length > 0) || StaxUnderwriting.underwriting_note) && StaxUnderwriting.underwriting_status != 'REJECTED'">
                                            <div class="p-p-3" *ngIf="StaxUnderwriting.underwriting_substatuses">
                                                <h4 class="p-text-bold p-mb-2 ic-white">Why ?
                                                </h4>
                                                <h6 class="ic-red"
                                                    *ngFor='let obj of StaxUnderwriting.underwriting_substatuses'>
                                                    <i class="fa fa-question-circle ic-white question-tooltip-icon p-mr-1"
                                                        tooltipPosition="right"></i>
                                                    <span class="ic-white">{{obj.message}}</span>
                                                </h6>
                                            </div>
                                            <div class="p-p-3" style="border-top: 1px solid #f9f2f2;"
                                                *ngIf="StaxUnderwriting.underwriting_note">
                                                <h4 class="p-text-bold p-mb-2 ic-white ">Underwriting Notes
                                                </h4>
                                                <h5 class="ic-white">
                                                    {{StaxUnderwriting.underwriting_note}}
                                                </h5>
                                            </div>
                                        </div> -->
                                        <div class="s-tax-icon"
                                            *ngIf="(StaxUnderwriting.underwriting_substatuses && StaxUnderwriting.underwriting_substatuses.length > 0 && StaxUnderwriting.underwriting_status !== 'REJECTED') || Staxassumedata.status === 'PENDING'">
                                            <button type="button" style="font-size:14px;width:100%;border-radius: 0;"
                                                pButton pRipple label="Click Here to Access Enrollment Application"
                                                icon="far fa-exclamation-circle" class="p-button-sm"
                                                (click)="finishEnrollment()"></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="p-col-12"
                                *ngIf="Staxassumedata.status == 'ACTIVE' && StaxUnderwriting.underwriting_status != 'PENDED' && StaxUnderwriting.underwriting_status != 'REJECTED'">
                                <div class="box">
                                    <div class="p-px-3 p-pt-3 p-py-2">
                                        <h4 class="p-text-bold p-mb-2 label-color">Maximum Allowed Per Transaction
                                        </h4>
                                        <h5 class="ic-red">
                                        </h5>
                                        <h5 class="ic-red ic-size-30 p-text-bold">
                                            {{Staxassumedata.options.transaction_limit | currency : 'USD' : true :
                                            '1.2-2'}}</h5>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div class="p-col-5" *ngIf="StaxUnderwriting.underwriting_status !== 'REJECTED'">
                        <div class="box p-p-0"
                        *ngIf="StaxUnderwriting.pricing_plan != getStaxEnumValue(5) && StaxUnderwriting.pricing_plan != getStaxEnumValue(5) && StaxUnderwriting.pricing_plan != getStaxEnumValue(6) && StaxUnderwriting.pricing_plan != getStaxEnumValue(2) && StaxUnderwriting.pricing_plan != getStaxEnumValue(3) ">
                        <div class="p-fluid p-formgrid p-grid">
                                <div class="p-col-5">
                                    <h3 class="p-text-bold label-color p-mt-2"><i class="fa fa-tags p-pt-4 p-pl-4"
                                            aria-hidden="true"></i> Pricing</h3>
                                </div>
                                <div class="p-col-7">
                                    <img alt="logo" src="assets/img/cards.png" height="55px" width="240px"
                                        class="pull-right p-pt-3" />
                                </div>
                            </div>

                            <div class="white-box price-bg">
                                <div class="p-p-2">
                                    <h4 class="p-text-bold p-mb-2 label-color">Credit Card
                                    </h4>
                                    <h5 class="ic-red ic-size-30 p-text-bold">{{StaxUnderwriting.plan_dcamnt}}% +
                                        {{StaxUnderwriting.plan_txamnt | currency : 'USD' : true :
                                        '1.2-2'}}</h5>
                                </div>
                                <div class="p-p-2">
                                    <h4 class="p-text-bold p-mb-2 label-color">ACH
                                    </h4>
                                    <h5 class="ic-red ic-size-30 p-text-bold p-mb-0">
                                        <!-- ${{StaxUnderwriting.plan_ach_txamnt}} -->

                                        {{StaxUnderwriting.plan_ach_dcamnt}}% up to
                                        {{StaxUnderwriting.plan_ach_cap | currency : 'USD' : true :
                                        '1.2-2'}} max
                                    </h5>
                                </div>
                                <div class="p-p-2 p-mt-6" *ngIf="!mangoCompanyData.AcknowledgedVisaMC">
                                    <button type="button" pButton pRipple label="Enable Surcharging"
                                        class="p-mr-1 p-button-sm" (click)="openStaxSurchargeConditions()"></button>
                                </div>
                                <div class="p-p-2 p-mt-6"
                                    *ngIf="mangoCompanyData.AcknowledgedVisaMC && !mangoCompanyData.isSurchargeEnabled">
                                    <div class="ic-blue">Links to notify MC & Visa of surcharging intent</div>
                                    <ul class="p-pl-0">
                                        <li class="p-mb-2">
                                            <a class="ic-red text-decoration p-pr-3" style="font-size: 18px;"
                                                href="https://usa.visa.com/Forms/merchant-surcharge-notification-form.html"
                                                target="_blank">Visa</a>
                                            <a class="ic-red text-decoration" style="font-size: 18px;"
                                                href="https://www.mastercard.us/en-us/surcharge-disclosure-webform.html"
                                                target="_blank">MasterCard</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <!-- Surcharge -->
                        <div class="box p-p-0" *ngIf="StaxUnderwriting.pricing_plan == getStaxEnumValue(5) || StaxUnderwriting.pricing_plan == getStaxEnumValue(7) || StaxUnderwriting.pricing_plan == getStaxEnumValue(6) || StaxUnderwriting.pricing_plan == getStaxEnumValue(11)">
                            <div class="p-fluid p-formgrid p-grid">
                                <div class="p-col-5">
                                    <h3 class="p-text-bold label-color p-mt-2"><i class="fa fa-tags p-pt-4 p-pl-4"
                                            aria-hidden="true"></i> Pricing</h3>
                                </div>
                                <div class="p-col-7">
                                    <img alt="logo" src="assets/img/cards.png" height="55px" width="240px"
                                        class="pull-right p-pt-3" />
                                </div>
                            </div>
                            <div class="white-box price-bg p-pt-4">
                                <div class="p-grid">
                                    <div class="p-col-6 label-color">&nbsp;</div>
                                    <div class="p-col-3 label-color">
                                        <div class="sur-label-box">
                                            Your Firm's Fees
                                        </div>
                                    </div>
                                    <div class="p-col-3 label-color">
                                        <div class="sur-label-box">
                                            Your Client's Fees
                                        </div>
                                    </div>
                                    <!-- row   1 -->
                                    <div class="p-col-6 label-color p-text-bold ic-size-20">Credit Card Rate(Surcharge)
                                    </div>
                                    <div class="p-col-3 p-text-center ic-red p-text-bold ic-size-20">
                                        N/A
                                    </div>
                                    <div class="p-col-3 p-text-center ic-red p-text-bold ic-size-20">
                                        {{StaxUnderwriting.cnp_credit_surcharge_rate}}%
                                    </div>
                                    <!-- Row 2 -->
                                    <div class="p-col-6 label-color p-text-bold ic-size-20">Debit Card Rate
                                    </div>
                                    <div class="p-col-3 p-text-center ic-red p-text-bold ic-size-20">
                                        {{StaxUnderwriting.cnp_debit_discount_rate}}% +
                                        {{StaxUnderwriting.cnp_debit_trans_fee | currency : 'USD' : true :
                                        '1.2-2'}}
                                    </div>
                                    <div class="p-col-3 p-text-center ic-red p-text-bold ic-size-20">
                                        N/A
                                    </div>
                                    <!-- Row 3 -->
                                    <div class="p-col-6 label-color p-text-bold ic-size-20">ACH
                                    </div>
                                    <div class="p-col-3 p-text-center ic-red p-text-bold ic-size-20">
                                        {{StaxUnderwriting.plan_ach_dcamnt}}% up to
                                        {{StaxUnderwriting.plan_ach_cap | currency : 'USD' : true :
                                        '1.2-2'}} max
                                    </div>
                                    <div class="p-col-3 p-text-center ic-red p-text-bold ic-size-20">
                                        N/A
                                    </div>

                                    <div class="label-color p-pt-5 font-italic">
                                        Debit cards and ACH transactions are not subject to Surcharging.
                                    </div>
                                    <div class="label-color font-italic">
                                        Surcharging applies to all credit cards and can't be disabled at the
                                        Client Level.
                                    </div>
                                </div>
                                <div class="p-p-2 ic-red ic-size-12 terms"
                                    *ngIf="mangoCompanyData.AcknowledgedVisaMC == true">
                                    <p-checkbox [(ngModel)]="mangoCompanyData.AcknowledgedVisaMC" binary="true"
                                        disabled="true"
                                        label="I acknowledge that I have informed Visa and Mastercard of my intent to surcharge Credit Card Payments.">
                                    </p-checkbox>
                                </div>
                                <div class="p-p-2 p-mt-6"
                                    *ngIf="mangoCompanyData.AcknowledgedVisaMC && !mangoCompanyData.isSurchargeEnabled">
                                    <div class="ic-blue">Links to notify MC & Visa of surcharging intent</div>
                                    <ul class="p-pl-0">
                                        <li class="p-mb-2">
                                            <a class="ic-red text-decoration p-pr-3" style="font-size: 18px;"
                                                href="https://usa.visa.com/Forms/merchant-surcharge-notification-form.html"
                                                target="_blank">Visa</a>
                                            <a class="ic-red text-decoration" style="font-size: 18px;"
                                                href="https://www.mastercard.us/en-us/surcharge-disclosure-webform.html"
                                                target="_blank">MasterCard</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <!-- NON Surcharge -->
                        <div class="box p-p-0" *ngIf="StaxUnderwriting.pricing_plan == getStaxEnumValue(1) || StaxUnderwriting.pricing_plan == getStaxEnumValue(2) || StaxUnderwriting.pricing_plan == getStaxEnumValue(3) || StaxUnderwriting.pricing_plan == getStaxEnumValue(4) || StaxUnderwriting.pricing_plan == getStaxEnumValue(10)">
                            <div class="p-fluid p-formgrid p-grid">
                                <div class="p-col-5">
                                    <h3 class="p-text-bold label-color p-mt-2"><i class="fa fa-tags p-pt-4 p-pl-4"
                                            aria-hidden="true"></i> Pricing</h3>
                                </div>
                                <div class="p-col-7">
                                    <img alt="logo" src="assets/img/cards.png" height="55px" width="240px"
                                        class="pull-right p-pt-3" />
                                </div>
                            </div>
                            <div class="white-box price-bg p-pt-6">
                                <div class="p-grid">
                                    <div class="p-col-7 label-color">&nbsp;</div>
                                    <div class="p-col-5 label-color">
                                        <div class="sur-label-box">
                                            Your Firm's Fees
                                        </div>
                                    </div>
                                    <!-- row   1 -->
                                    <div class="p-col-7 label-color p-text-bold ic-size-20">Credit Card Rate(Non-AMEX)
                                    </div>
                                    <div class="p-col-5 p-text-center ic-red p-text-bold ic-size-20">
                                        {{StaxUnderwriting.credit_qual_disc_rate}}% +
                                        {{StaxUnderwriting.credit_qual_trans_fee | currency : 'USD' : true :
                                        '1.2-2'}}
                                    </div>
                                    <!-- Row 2 -->
                                    <div class="p-col-7 label-color p-text-bold ic-size-20">Credit Card Rate(AMEX)
                                    </div>
                                    <div class="p-col-5 p-text-center ic-red p-text-bold ic-size-20">
                                        {{StaxUnderwriting.amex_non_disc_rate}}% +
                                        {{StaxUnderwriting.amex_non_trans_fee | currency : 'USD' : true :
                                        '1.2-2'}}
                                    </div>
                                    <!-- Row 3 -->
                                    <div class="p-col-7 label-color p-text-bold ic-size-20">ACH
                                    </div>
                                    <div class="p-col-5 p-text-center ic-red p-text-bold ic-size-20">
                                        {{StaxUnderwriting.plan_ach_dcamnt}}% up to
                                        {{StaxUnderwriting.plan_ach_cap | currency : 'USD' : true :
                                        '1.2-2'}} max
                                    </div>

                                    <div class="p-p-2 p-mt-6" *ngIf="!mangoCompanyData.AcknowledgedVisaMC">
                                        <button type="button" pButton pRipple label="Enable Surcharging"
                                            class="p-mr-1 p-button-sm" (click)="openStaxSurchargeConditions()"></button>
                                    </div>
                                    <div class="p-p-2 p-mt-6"
                                        *ngIf="mangoCompanyData.AcknowledgedVisaMC && !mangoCompanyData.isSurchargeEnabled">
                                        <div class="ic-blue">Links to notify MC & Visa of surcharging intent</div>
                                        <ul class="p-pl-0">
                                            <li class="p-mb-2">
                                                <a class="ic-red text-decoration p-pr-3" style="font-size: 18px;"
                                                    href="https://usa.visa.com/Forms/merchant-surcharge-notification-form.html"
                                                    target="_blank">Visa</a>
                                                <a class="ic-red text-decoration" style="font-size: 18px;"
                                                    href="https://www.mastercard.us/en-us/surcharge-disclosure-webform.html"
                                                    target="_blank">MasterCard</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<p-dialog header="Mango Payments - Surcharge Information" [(visible)]="isSurchargeConfirmationDialog" [modal]="true"
    [style]="{width: '55vw'}" [maximizable]="false" showEffect="fade" [baseZIndex]="10000" [draggable]="true"
    [resizable]="false" [closable]="false" [closeOnEscape]="false">
    <div class="p-fluid p-formgrid p-grid p-m-1">
        <div class="p-col-12">
            <div class="card">
                <span><i class="fal fa-exclamation-circle ic-red p-mr-2" style="font-size: 25px;"></i></span>
                <span style="font-weight: 700;position: relative;top: -4px;">Before proceeding with enabling
                    Surcharging, here are a few important
                    pointers for using Credit Card Surcharing:</span>
                <ul>
                    <li class="p-mb-2">In the event you decide to stop Surcharging, you will need to contact support to
                        cancel the
                        surcharging merchant account and create a new account without surcharging.</li>
                    <li class="p-mb-2">You should provide 30 days notice to <a class="ic-red text-decoration"
                            href="https://usa.visa.com/Forms/merchant-surcharge-notification-form.html"
                            target="_blank">Visa</a>, and <a class="ic-red text-decoration"
                            href="https://www.mastercard.us/en-us/surcharge-disclosure-webform.html"
                            target="_blank">MasterCard</a> of your intent to surcharge Credit Card transactions. Other
                        brands DO NOT require notice.</li>
                    <li class="p-mb-2">ALL payments accepted using Credit Cards (not Prepaid or Debit Cards) will incur
                        the surcharge.
                        It is NOT optional. When using integrated payments, the type of card is automatically
                        identified.</li>
                    <li class="p-mb-2">Prepaid and Debit Cards will not incur a surcharge.</li>
                    <li class="p-mb-2">Because of surcharge notice rules, all users will see the term "Surcharge"
                        whether they are
                        using a Credit Card or not.
                        For more information, please refer to this <a class="ic-red text-decoration"
                            href="https://usa.visa.com/dam/VCOM/download/merchants/surcharging-faq-by-merchants.pdf"
                            target="_blank">FAQ</a> .
                    </li>
                    <li class="p-mb-2">Check for any restrictions on surcharging in your business location. At present,
                        surcharging is
                        not allowed in Massachusetts or Connecticut.</li>
                    <li class="p-mb-2">It is recommended that a notification email or letter be sent to all existing
                        clients informing that surcharging will apply to qualified credit cards.</li>
                </ul>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button type="button" pButton pRipple icon="fas fa-check" label="{{'save.enrollment' | translate }}"
            (click)="processStaxCard()"></button>
        <button type="button" pButton pRipple icon="fa fa-close" label="{{'cancel' | translate }}"
            (click)="isSurchargeConfirmationDialog=false;selectedSurchargeOption=null;isSurchargeTerms=false;"></button>
    </ng-template>
</p-dialog>

<p-dialog header="Mango Payments - Surcharging" [(visible)]="isSurchargeDialog" [modal]="true" [style]="{width: '55vw'}"
    [maximizable]="false" showEffect="fade" [baseZIndex]="10000" [draggable]="true" [resizable]="false"
    [closable]="false" [closeOnEscape]="false">
    <div class="p-fluid p-formgrid p-grid p-m-1">
        <div class="p-col-12">
            <div class="card">
                <div class="p-col-12">
                    <!-- New Enroll -->
                    <div *ngIf="isapiLoaded && !mangoCompanyData['isStMIdPresents']">
                        Enabling Surcharging is done at your firm level and will apply to all clients for qualified
                        credit
                        cards. If you do not want enable surcharging now, you can continue with the enrollment process
                        and
                        enable surcharging at later date. Note that while you have 30 days to enter the information
                        before
                        the
                        application expires, you must choose to enable Credit Card Surcharging before beginning the
                        application
                        process with Visa/Mastercard.
                    </div>
                    <!-- Enrolled Flow -->
                    <div *ngIf="isapiLoaded && mangoCompanyData['isStMIdPresents']">
                        Enabling Surcharging is done at your firm level and will apply to all clients for qualified
                        credit
                        cards. You must choose to enable Credit Card Surcharging before beginning the
                        application process with Visa/Mastercard. There are links to begin the application process with
                        Visa/Mastercard when
                        you Continue, as well as additional information regarding Surcharging.
                    </div>

                    <div class="p-mt-2">
                        To learn more about Surcharging and make an informed decision, we recommend downloading and
                        review
                        this
                        <a class="ic-red text-decoration"
                            href="https://usa.visa.com/dam/VCOM/download/merchants/surcharging-faq-by-merchants.pdf"
                            target="_blank">FAQ</a>.
                    </div>
                </div>
                <div class="p-col-12 p-mt-5">
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-col-3 p-mt-2">
                            <label class="label-text required-field">Proceed with surcharging</label>
                        </div>
                        <div class="p-col-4">
                            <p-dropdown [options]="surchargeOptions" [(ngModel)]="selectedSurchargeOption"
                                placeholder="Select Option" optionLabel="name" (onChange)="onChangeAcceptance()">
                            </p-dropdown>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-my-3" *ngIf="selectedSurchargeOption && selectedSurchargeOption.code == 1">
                    <p-checkbox [(ngModel)]="isSurchargeTerms" binary="true" inputId="binary"
                        (onChange)="onChangeAcceptance()"
                        label="I acknowledge that I will inform Visa and Mastercard of my intent to surcharge Credit Card Payments.">
                    </p-checkbox>
                </div>
                <div class="p-col-12 p-my-3">
                    &nbsp;
                </div>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button type="button" pButton pRipple icon="fas fa-check" label="{{'save.enrollment' | translate }}"
            (click)="processSurchargeTerms()" [disabled]="!enableContinueBtn"></button>
        <button type="button" pButton pRipple icon="fa fa-close" label="{{'cancel' | translate }}"
            (click)="isSurchargeDialog=false;selectedSurchargeOption=null;isSurchargeTerms=false;"></button>
    </ng-template>
</p-dialog>

<p-dialog header="Underwriting Conditions" [(visible)]="isunderwritingDialog" [modal]="true"
    [style]="{width: '55vw'}" [maximizable]="false" showEffect="fade" [baseZIndex]="10000" [draggable]="true"
    [resizable]="false" [closable]="false" [closeOnEscape]="false">
    <div class="p-fluid p-formgrid p-grid p-m-1">
        <div class="p-col-12">
            <div class="card">
                <div class="p-col-12">
                    <div class="orange-box"
                    *ngIf="((StaxUnderwriting.underwriting_substatuses && StaxUnderwriting.underwriting_substatuses.length > 0) || StaxUnderwriting.underwriting_note) && StaxUnderwriting.underwriting_status !== 'REJECTED'">
                    <div class="p-p-3" *ngIf="StaxUnderwriting.underwriting_substatuses">
                        <h4 class="p-text-bold p-mb-2 ic-white">Why ?
                        </h4>
                        <h6 class="ic-red"
                            *ngFor='let obj of StaxUnderwriting.underwriting_substatuses'>
                            <i class="fa fa-question-circle ic-white question-tooltip-icon p-mr-1"
                                tooltipPosition="right"></i>
                            <span class="ic-white">{{obj.message}}</span>
                        </h6>
                    </div>
                    <div class="p-p-3" style="border-top: 1px solid #f9f2f2;"
                        *ngIf="StaxUnderwriting.underwriting_note">
                        <h4 class="p-text-bold p-mb-2 ic-white ">Underwriting Notes
                        </h4>
                        <h5 class="ic-white">
                            {{StaxUnderwriting.underwriting_note}}
                        </h5>
                    </div>
                </div> 
                </div>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button type="button" pButton pRipple icon="fa fa-close" label="{{'close' | translate }}"
            (click)="isunderwritingDialog=false;"></button>
    </ng-template>
</p-dialog>
