<div class="client-projects">
    <div class="layout-content">
        <!-- table -->
        <div class="p-grid">
            <div class="p-col-12">
                <div class="card p-ml-3 p-pt-0">
                    <p-table #dt id="project-table" [value]="todoList" [responsive]="true" sortField="ClientName"
                        [filterDelay]="0"
                        styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo todo-table"
                        sortField="ClientName" [scrollable]="true" scrollHeight="calc(82vh - 300px)"
                        [globalFilterFields]="['Status','Priority','ClientName','Description','DueDate', 'StaffName']"
                        [sortOrder]="0">
                        <ng-template pTemplate="caption">
                            <div class="table-header">
                                <span class="p-input-icon-left">
                                    <i class="fal fa-search"></i>
                                    <input #searchValue pInputText type="text"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="{{ 'Search-by' | translate }}" />
                                </span>
                                <span>
                                    <button pButton pRipple type="button" icon="pi pi-times"
                                        class="p-button-rounded p-button-danger p-button-text"
                                        (click)="dt.reset();clearSearchFilter()" tooltipPosition="top"
                                        pTooltip="{{'clear' | translate}}"></button>
                                </span>
                                <p-message *ngIf="filteredItemsSize > -1" severity="success"
                                    text="{{filteredItemsSize}} {{ 'records_found' | translate }}." styleClass="p-ml-2">
                                </p-message>
                                <span class="pull-right">
                                    <label class="label-text p-mr-2">{{'due-date' | translate}}</label>
                                    <p-dropdown [options]="dateRangeOptions" [(ngModel)]="selectedDateRange" class="width-60p" (onChange)="getTodoSchedule()">
                                    </p-dropdown>
                                    <p-dropdown [options]="statusOptions" [(ngModel)]="defaultFilter" [ngModelOptions]="{standalone: true}"   class="width-60p p-ml-2" 
                                    (onChange)="changeStatus($event)"></p-dropdown>
                                    <p-button pRipple label="{{'Add To-Do' | translate}}" icon="fal fa-plus"
                                        styleClass="p-button-sm" class="p-button-help p-pl-3" (click)="addTodo()">
                                    </p-button>
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="width-4p p-text-center">
                                    {{'actions' | translate}}
                                </th>
                                <th class="width-6p p-text-center" pSortableColumn="Priority">
                                    {{'Priority' | translate}}
                                    <p-sortIcon field="Priority"></p-sortIcon>
                                </th>
                                <!-- <th class="width-15p p-text-left" pSortableColumn="ClientName">
                                    {{'timer.client' | translate}}
                                    <p-sortIcon field="ClientName"></p-sortIcon>
                                </th> -->
                                <th class="width-30p p-text-left" pSortableColumn="Description">
                                    {{'memo' | translate}}
                                    <p-sortIcon field="Description"></p-sortIcon>
                                </th>
                                <th class="width-10p p-text-left" pSortableColumn="DateCreated">
                                    {{'Assign_Staff' | translate}}
                                    <p-sortIcon field="DateCreated"></p-sortIcon>
                                </th>
                                <th class="width-10p p-text-left" pSortableColumn="DateCreated">
                                    {{'Created' | translate}}
                                    <p-sortIcon field="DateCreated"></p-sortIcon>
                                </th>
                                <th class="width-10p p-text-left" pSortableColumn="DueDate">
                                    {{'due-date' | translate}}
                                    <p-sortIcon field="DueDate"></p-sortIcon>
                                </th>
                                <th class="width-10p p-text-left" pSortableColumn="DateFinished">
                                    {{'date_completed' | translate}}
                                    <p-sortIcon field="DateFinished"></p-sortIcon>
                                </th>
                                <th class="width-5p p-text-center">
                                    {{'completed' | translate}}
                                </th>
                                <th class="width-5p p-text-center">
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData>
                            <tr class="cursor-hand">
                                <td class="width-4p p-text-center">
                                    <i pRipple class="fal fa-pencil p-mr-1 cursor-hand  ic-green" tooltipPosition="top" (click)="editTodo(rowData);$event.stopPropagation();"
                                        pTooltip="{{'Edit' | translate}}"></i>
                                    <i pRipple class="fal fa-trash-alt ic-red cursor-hand p-pl-2" tooltipPosition="top" (click)="deleteTodoAlert(rowData);$event.stopPropagation();"
                                        pTooltip="{{'mgmt-delete' | translate}}"></i>
                                </td>
                                <td class="width-6p p-text-center">
                                    <i class="fa fa-flag ic-red ic-size-16" aria-hidden="true"
                                        *ngIf="rowData.Priority === 'High'"></i>
                                    <i class="fa fa-flag ic-yellow ic-size-16" aria-hidden="true"
                                        *ngIf="rowData.Priority === 'Medium'"></i>
                                    <i class="fa fa-flag ic-green ic-size-16" aria-hidden="true"
                                        *ngIf="rowData.Priority === 'Low'"></i>
                                </td>
                               <!--  <td class="width-15p p-text-left p-text-nowrap p-text-truncate"
                                    pTooltip="{{rowData.ClientName}}" tooltipStyleClass="tooltip-no-wrap"
                                    showDelay="800" tooltipPosition="top">
                                    {{rowData.ClientName}}
                                </td> -->
                                <td class="width-30p p-text-left p-text-nowrap p-text-truncate"
                                    pTooltip="{{rowData.Description}}" tooltipStyleClass="tooltip-no-wrap"
                                    showDelay="800" tooltipPosition="top">
                                    {{rowData.Description}}
                                </td>
                                <td class="width-10p p-text-left p-text-nowrap p-text-truncate">
                                    {{rowData.StaffName}}
                                </td>
                                <td class="width-10p p-text-left p-text-nowrap p-text-truncate">
                                    {{rowData.DateCreated | date:'MM/dd/yyyy' : 'UTC'}}
                                </td>
                                <td class="width-10p p-text-left p-text-nowrap p-text-truncate">
                                    {{ rowData.DueDate | date:'MM/dd/yyyy'}}
                                </td>
                                <td class="width-10p p-text-left p-text-nowrap p-text-truncate">
                                    {{rowData.DateFinished | date:'MM/dd/yyyy' : 'UTC'}}
                                </td>
                                <td class="width-5p p-text-center">
                                    <i (click)="$event.stopPropagation();"
                                        *ngIf="rowData.Status === 'Complete'" class="fal fa-check ic-green ic-size-16"
                                        aria-hidden="true"></i>
                                </td>
                                <td class="width-5p p-text-center">
                                    <i class="pi pi-lock ic-red" *ngIf="rowData.isPrivate" tooltipPosition="top"
                                        pTooltip="{{'private' | translate}}" showDelay="800"></i>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="6">
                                    <p-message severity="info" text="{{'data_not_found' | translate}}."
                                        styleClass="p-px-2">
                                    </p-message>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>