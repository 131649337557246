import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { EncrDecrService, MangoApiService, BreadcrumbService, mangoUtils} from '@app/_services';
import { MenuItem, SelectItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from '@app/_helpers/api-constants';
import Swal from 'sweetalert2';
import { Table } from 'primeng/table';
import $ from "jquery";

@Component({
  selector: 'app-billrate',
  templateUrl: './rate-templates.component.html'
})
export class RateTemplates implements OnInit {
  @ViewChild('dtRateTemplateNames') dtRateTemplateNames: Table;
  @ViewChild('dtRateLevels') dtRateLevels: Table;
  @ViewChild('dtRateTemplateDetail') dtRateTemplateDetail: Table;
  @ViewChild('dtRateLevelExceptions') dtRateLevelExceptions: Table;
  @ViewChild('dtClients') dtClients: Table;
  @ViewChild('dtActivity') dtActivity: Table;
  items: MenuItem[];
  activeItem: MenuItem;
  viewType = 0;
  displayRateTemplateNamesPanel = false;
  companyId: number = null;
  rateTemplatesNames = [];
  rateTemplatesNamesOptions = [];
  clientRateTemplatesNamesOptions = [];
  activityRateTemplatesNamesOptions = [];
  rateTemplateDetails = [];
  selectedRateTemplateDetails = [];
  rateLevelExceptions = [];
  rateLevels = [];
  rateLevelsOptions = [];
  rateTemplateDetailsrateLevelsOptions = [];
  exceptionsRateLevelsOptions = [];
  staffRateLevelsOptions = [];
  staffsRateLevels = [];
  clientRateTemplates = [];
  selectedClientRateTemplates = [];
  selectedStaffRateLevels = [];
  selectedRateLevel = null;
  showInactive = false;
  selectedRateTemplate = null
  clientSelectedRateTemplate = null
  activitySelectedRateTemplate = null
  selectedRateTemplateObj = {}
  showSelectedTemplate = false;
  activityList: any = [];
  staffList: any = [];
  staffListOptions: any = [];
  batchOptionsButtons1: any = [];
  batchOptionsButtons2: any = [];
  batchOptionsButtons3: any = [];
  clientGroupCategory: any = [];
  activityRateTemplates = [];
  selectedActivityRateTemplates = [];

  @ViewChild('searchValue') searchValue;
  searchTextStr: any = "";
  filteredItemsSize = -1;  
  
  @ViewChild('searchValue') activitySearchValue;
  activitySearchTextStr: any = "";
  activityFilteredItemsSize = -1;

  @ViewChild('rateTemplateNamesSearchValue') rateTemplateNamesSearchValue;
  rateTemplateNamesSearchTextStr: any = "";
  rateTemplateNamesFilteredItemsSize = -1;

  @ViewChild('rateTemplateDetailSearchValue') rateTemplateDetailSearchValue;
  rateTemplateDetailSearchTextStr: any = "";
  rateTemplateDetailFilteredItemsSize = -1;

  @ViewChild('rateLevelExceptionsSearchValue') rateLevelExceptionsSearchValue;
  rateLevelExceptionsSearchTextStr: any = "";
  rateLevelExceptionsFilteredItemsSize = -1;

  @ViewChild('rateLevelsSearchValue') rateLevelsSearchValue;
  rateLevelsSearchTextStr: any = "";
  rateLevelsFilteredItemsSize = -1;

  @ViewChild('staffsRateLevelsSearchValue') staffsRateLevelsSearchValue;
  staffsRateLevelsSearchTextStr: any = "";
  staffsRateLevelsFilteredItemsSize = -1;

  templateTypeOptions = [
    {
      label: "Client Rate Template - All Activites",
      value: 1
    },
    {
      label: "Activity Rates Template - All Clients",
      value: 2
    },
    {
      label: "Client & Activity Template - Combinations",
      value: 3
    }
  ]

  cols1: any[];
  _selectedColumns1: any = [];
  clientRateTemplatesGlobalFilter = [];

  constructor(
    protected mangoUtils: mangoUtils,
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private breadcrumbService: BreadcrumbService,
    private translate: TranslateService) {
    this.companyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    const interval = setInterval(() => {
      if(!this.translate.translations[this.translate.currentLang])
        return;
      clearInterval(interval);
      this.initTranslations();
      this.initBatchOptions1();
      this.initializeColumns1();
      // this.initBatchOptions2();
      // this.initBatchOptions3();
    }, 300);
  }

  @Input() get selectedColumns1(): any[] {
    return this._selectedColumns1;
  }

  set selectedColumns1(val: any[]) {
      //restore original order
      const arr = val.map(col => col.field)
      this._selectedColumns1 = this.cols1.filter(col => arr.includes(col.field));
      this.clientRateTemplatesGlobalFilter = [...this._selectedColumns1.map(col => col.field), 'ClientName', 'TemplateName'];
  }

  initializeColumns1(){
    this.clientRateTemplatesGlobalFilter = ['ClientName', 'TemplateName','CustomerTypeDescription','GroupDescriptionIDArrayDesc', 'BillingPartner', 'BusZip']
    this.cols1 = [
      {
        field: "CustomerTypeDescription",
        header: this.translate.instant("client.type"),
        class: "width-10p p-text-left",
        sortable: true,
      },
      {
        field: "GroupDescriptionIDArrayDesc",
        header: this.translate.instant("Client_Group"),
        class: "width-10p p-text-left",
        sortable: true,
      },
      {
        field: "BillingPartner",
        header: this.translate.instant("client.billing_partner"),
        class: "width-10p p-text-left",
        sortable: true,
      },
      {
        field: "BusZip",
        header: this.translate.instant("client.business_zip"),
        class: "width-7p p-text-left",
        sortable: true,
      },
      /*  { field: 'Email', header: this.translate.instant('email'), class: 'width-10p p-text-left'}, */
    ];
    this.selectedColumns1 = [
      /* { field: 'ContactName', header: this.translate.instant('client.contact_name'), class: 'width-15p p-text-left'}, */
      /*  { field: 'Company', header: this.translate.instant('company'), class: 'width-15p p-text-left'}, */
      
      {
        field: "CustomerTypeDescription",
        header: this.translate.instant("client.type"),
        class: "width-10p p-text-left",
        sortable: true,
      },
      {
        field: "GroupDescriptionIDArrayDesc",
        header: this.translate.instant("Client_Group"),
        class: "width-10p p-text-left",
        sortable: true,
      },
      {
        field: "BillingPartner",
        header: this.translate.instant("client.billing_partner"),
        class: "width-10p p-text-left",
        sortable: true,
      },
      {
        field: "BusZip",
        header: this.translate.instant("client.business_zip"),
        class: "width-7p p-text-left",
        sortable: true,
      },
      /*  { field: 'Email', header: this.translate.instant('email'), class: 'width-10p p-text-left'}, */
    ];
  }


  ngOnInit(): void {
    this.items = [
      { label: 'Rate Templates', 
        icon: 'pi pi-percentage ic-size-10', 
        command: () => {
          this.changeViewType(0);
        }
      },
      { label: 'Assign Staff Levels', 
        icon: 'pi pi-user-edit',
         command: () =>{
          this.changeViewType(1);
         }
      },
      { label: 'Assign Templates to Clients', 
        icon: 'pi pi-users', 
        command: () => {
          this.changeViewType(2);
        }
      },
      { label: 'Assign Templates to Activities', 
        icon: 'pi pi-users', 
        command: () => {
          this.changeViewType(3);
        }
      }
    ];
    this.activeItem = this.items[0];
    this.fetchRateTemplateNames();
    this.getActivityGroups();
    this.getAllStaffList();
    this.clientGroupCategory = this.encrDecSrvc.getObject(AppConstants.clientGroupCategory);
    this.searchTextStr = this.encrDecSrvc.getObject(
      AppConstants.clientRateTemplates + "clients_rate_templates" + AppConstants.SearchString
    );
  }

  checkRateTemplateOptions(){
    const rateTemplates = this.rateTemplatesNamesOptions.filter(template=>!template['Inactive']);
    if(rateTemplates.length == 0){
      this.noRateTemplatesWarning();
    }
  }

  noRateTemplatesWarning(){
    Swal.fire({
      title: this.translate.instant('confirmation'),
      text: this.translate.instant('You have no rate templates. Do you want to create now?'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('Yes_Proceed'),
      cancelButtonText: this.translate.instant('no_leave')
    }).then((result) => {
      if(result.value){
        this.openRateTemplateNamesPanel(0, true);
      }
    })
  }

  noRateLevelsWarning(){
    Swal.fire({
      title: this.translate.instant('confirmation'),
      text: this.translate.instant('You have no rate levels. Do you want to create now?'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('Yes_Proceed'),
      cancelButtonText: this.translate.instant('no_leave')
    }).then((result) => {
      if(result.value){
        this.openRateTemplateNamesPanel(1, true);
      }
    })
  }

  noRateTemplateDetailsWarning(){
    Swal.fire({
      title: this.translate.instant('confirmation'),
      text: this.translate.instant('You have no rate template details. Do you want to create now?'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('Yes_Proceed'),
      cancelButtonText: this.translate.instant('no_leave')
    }).then((result) => {
      if(result.value){
        // this.openRateTemplateNamesPanel(1, true);
        this.addRateTemplateDetail(this.dtRateTemplateDetail)
      }
    })
  }

  initTranslations() {
    this.breadcrumbService.setItems([
      { label: this.translate.instant('Settings') },
      { label: this.translate.instant('rate_profiles'), icon: 'ic-red' }
    ]);
  }

  initBatchOptions1(){
    const parent = this;
    this.batchOptionsButtons1 = [
      {
        label: parent.translate.instant('rate.apply_selected_rate_level'),
        icon: "fal fa-pencil",
        command: () => {
          parent.applySelectedRateLevel();
        },
      },
      {
        label: parent.translate.instant('rate.clear_rate_levels'),
        icon: "pi pi-times",
        command: () => {
          parent.applySelectedRateLevel(true);
        },
      },
    ];
  }

  // initBatchOptions2(){
  //   let parent = this;
  //   this.batchOptionsButtons2 = [
  //     {
  //       label: parent.translate.instant('rate.apply_selected_rate_template_name'),
  //       icon: "fal fa-pencil",
  //       command: () => {
  //         parent.bulkUpdateClientRateTemplates();
  //       },
  //     },
  //     {
  //       label: parent.translate.instant('rate.clear_rate_template_names'),
  //       icon: "pi pi-times",
  //       command: () => {
  //         parent.bulkUpdateClientRateTemplates(true);
  //       },
  //     },
  //   ];
  // }

  // initBatchOptions3(){
  //   let parent = this;
  //   this.batchOptionsButtons3 = [
  //     {
  //       label: parent.translate.instant('rate.apply_selected_rate_template_name'),
  //       icon: "fal fa-pencil",
  //       command: () => {
  //         parent.bulkUpdateActivityRateTemplates();
  //       },
  //     },
  //     {
  //       label: parent.translate.instant('rate.clear_rate_template_names'),
  //       icon: "pi pi-times",
  //       command: () => {
  //         parent.bulkUpdateActivityRateTemplates(true);
  //       },
  //     },
  //   ];
  // }

  fetchRateTemplateNames(){
    this.mangoAPISrvc.showLoader(true);
    this.rateTemplatesNamesOptions = [];
    this.mangoAPISrvc.getRateTemplateNames(this.companyId)
      .subscribe((result: any)=>{
        this.rateTemplatesNames = result;
        this.rateTemplatesNames = this.rateTemplatesNames.map(template=>{
          if(!template['Inactive']){
            this.rateTemplatesNamesOptions.push({
              label: template['TemplateName'],
              value: template['RateTemplateNamesID'],
              data: template
            })
          }
          template['TemplateTypeValue'] = this.getRateTemplateType(template['TemplateType']);
          return template;
        })
        this.mangoAPISrvc.showLoader(false);
      })
  }

  getActivityGroups() {
    const parent = this;
    let tempList = this.encrDecSrvc.getObject(AppConstants.activityList);
    if ((tempList.length == 0) || (this.activityList.length > 0 && this.activityList.length == tempList.length)) {
      return false;
    }
    parent.activityList = [];
    tempList = tempList.sort(parent.mangoUtils.compareValues('ServiceCode', 'asc'));
    for (let i = 0; i < tempList.length; i++) {
      if (tempList[i].Inactive == false) {
        parent.activityList.push({ label: tempList[i].ServiceCode + ' - ' + tempList[i].Description, value: tempList[i].ServiceCodeID, fullobj: tempList[i] })
      }
    }
  }

  getAllStaffList() {
    const parent = this;
    const item = parent.encrDecSrvc.getObject(AppConstants.staffList);
    item.filter(staff=>!staff['Inactive'])
      .map(staff=>{
        const obj = {};
        obj["label"] = staff["StaffName"];
        obj["value"] = staff["StaffID"];
        obj["StaffID"] = staff["StaffID"];
        obj["StaffName"] = staff["StaffName"];
        obj["StaffNumber"] = staff["StaffNumber"];
        obj["Email"] = staff["Email"];
        this.staffList.push(obj);
        this.staffListOptions.push(obj);
      })
  }

  getRateTemplateType(id){
    switch(id){
      case 1: return "Client Rate Template - All Activites"
      case 2: return "Activity Rate Template - All Clients"
      case 3: return "Client & Activity Template - Combinations"
    }
  }

  cancelRateTemplateNamesPanel(){
    this.displayRateTemplateNamesPanel = false;
  }

  openRateTemplateNamesPanel(viewType?, addRow?){
    this.displayRateTemplateNamesPanel = true;
    this.changeViewType(viewType || 0, addRow);
  }

  changeViewType(viewType, addRow?){
    this.viewType = viewType;
    this.activeItem = this.items[viewType];
    if(this.viewType == 0){
      if(addRow){
        setTimeout(()=>{
          this.addRateTemplateName(this.dtRateTemplateNames);
        }, 500)
      }
      this.onShowInactiveChange(true);
    }else if(this.viewType == 1){
      this.mangoAPISrvc.showLoader(true);
      this.selectedStaffRateLevels = [];
      this.mangoAPISrvc.getRateLevels(this.companyId).subscribe((result: any)=>{
        this.rateLevels = result;
        if(addRow){
          this.addRateLevel(this.dtRateLevels);
        }
        if(this.rateLevels.filter(rateLevel=>rateLevel['RateLevelID']).length > 0){
          this.rateLevelsOptions = this.rateLevels.map(rateLevel=>{
            return {
              label: rateLevel['RateLevelDescription'],
              value: rateLevel['RateLevelID']
            }
          });
        }
        this.staffRateLevelsOptions = [{label: "None Assigned", value: null}, ...this.rateLevelsOptions];
        this.filterDetailRateLevelsOptions();
        this.filterExceptionsRateLevelsOptions();
        this.mangoAPISrvc.getStaffRateLevels(this.companyId).subscribe((result: any)=>{
        this.mangoAPISrvc.showLoader(false);
        this.staffsRateLevels = result.map(staff=>{
          staff['RateLevelDescription'] = staff['RateLevelID']? staff['RateLevelDescription'] : "None Assigned";
          return staff;
        })
        })
      })
    }else if(this.viewType == 2){
      this.selectedClientRateTemplates = [];
      this.showSelectedTemplate = false;
      this.clientRateTemplatesNamesOptions = [
        { label: "None Assigned", value: null }, 
          ...this.rateTemplatesNamesOptions.filter(template=>template && template.data && template.data['TemplateType'] != 2)
        ];
      this.mangoAPISrvc.showLoader(true);
      this.mangoAPISrvc.getClientRateTemplates(this.companyId).subscribe((result: any)=>{
        this.mangoAPISrvc.showLoader(false);
        this.clientRateTemplates = result;
        this.clientRateTemplates = this.clientRateTemplates.map(client=>{
          client["GroupDescriptionIDArrayDesc"] = "";
          client["TemplateName"] = client["RateTemplateNamesID"]? client["TemplateName"] : "None Assigned";
          client["GroupDescriptionIDArray"] = client["GroupDescriptionIDArray"] || [];
          if(client['GroupDescriptionIDArray'] && client['GroupDescriptionIDArray'].length > 0){
            client['GroupDescriptionIDArray'].filter(item => item != -1);
            client['GroupDescriptionIDArrayDesc'] = 
              this.clientGroupCategory?.filter(group => client['GroupDescriptionIDArray'].includes(group['CustomerGroupCategoryID']))
                .map(group => group['GroupDescription']).join(", ");
          }
          return client;
        })
      })
    }else if(this.viewType == 3){ 
      this.showSelectedTemplate = false;
      this.selectedActivityRateTemplates = [];
      this.activityRateTemplatesNamesOptions = [
        { label: "None Assigned", value: null }, 
          ...this.rateTemplatesNamesOptions.filter(template=>template && template.data && template.data['TemplateType'] == 2)
        ];
      this.mangoAPISrvc.showLoader(true);
      this.mangoAPISrvc.getActivityRateTemplates(this.companyId).subscribe((result: any)=>{
        this.mangoAPISrvc.showLoader(false);
        this.activityRateTemplates = result.map(item=>{
          item["TemplateName"] = item["RateTemplateNamesID"]? item["TemplateName"] : "None Assigned";
          return item;
        });
      })
    }
  }

  clearSearchFilter(type) {
    switch(type){
      case 'clients':
        this.searchValue.nativeElement.value = this.searchTextStr = "";
        this.filteredItemsSize = -1;
        this.encrDecSrvc.addObject(
          AppConstants.clientRateTemplates + "clients" + AppConstants.SearchString, "");
        break;
      case 'activities':
        this.activitySearchValue.nativeElement.value = this.activitySearchTextStr = "";
        this.activityFilteredItemsSize = -1;
        this.encrDecSrvc.addObject(
          AppConstants.activityRateTemplates + "activities" + AppConstants.SearchString, "");
        break;
      case 'rate_template_names':
        this.rateTemplateNamesSearchValue.nativeElement.value = this.rateTemplateNamesSearchTextStr = "";
        this.rateTemplateNamesFilteredItemsSize = -1;
        this.encrDecSrvc.addObject(
          AppConstants.rateTemplateNames + "rate_template_names" + AppConstants.SearchString, "");
        break;
      case 'rate_template_details':
        this.rateTemplateDetailSearchValue.nativeElement.value = this.rateTemplateDetailSearchTextStr = "";
        this.rateTemplateDetailFilteredItemsSize = -1;
        this.encrDecSrvc.addObject(
          AppConstants.rateTemplateDetails + "rate_template_details" + AppConstants.SearchString, "");
        break;
      case 'rate_level_exceptions':
        this.rateLevelExceptionsSearchValue.nativeElement.value = this.rateLevelExceptionsSearchTextStr = "";
        this.rateLevelExceptionsFilteredItemsSize = -1;
        this.encrDecSrvc.addObject(
          AppConstants.rateLevelExceptions + "rate_level_exceptions" + AppConstants.SearchString, "");
        break;
      case 'rate_levels':
        this.rateLevelsSearchValue.nativeElement.value = this.rateLevelsSearchTextStr = "";
        this.rateLevelsFilteredItemsSize = -1;
        this.encrDecSrvc.addObject(
          AppConstants.rateLevels + "rate_levels" + AppConstants.SearchString, "");
        break;
      case 'staff_rate_levels':
        this.staffsRateLevelsSearchValue.nativeElement.value = this.staffsRateLevelsSearchTextStr = "";
        this.staffsRateLevelsFilteredItemsSize = -1;
        this.encrDecSrvc.addObject(
          AppConstants.rateLevels + "staff_rate_levels" + AppConstants.SearchString, "");
        break;
    }
  }

  //validate

  validateRateLevelDetail(rowData){
    if(!rowData['RateLevelID']){
      this.mangoAPISrvc.notify('error', 'Error', this.translate.instant("Rate Level is required"));
      return false;
    } else if(!rowData['HourlyRate']){
      this.mangoAPISrvc.notify('error', 'Error', this.translate.instant("Hourly Rate is required"));
      return false;
    } else if (this.selectedRateTemplateObj && this.selectedRateTemplateObj['TemplateType'] == 3 && !rowData['ServiceCodeID']){
      this.mangoAPISrvc.notify('error', 'Error', this.translate.instant("Activity is required"));
      return false;
    }
    return true;
  }

  validateRateLevelExceptions(rowData){
    if(!rowData['StaffID']){
      this.mangoAPISrvc.notify('error', 'Error', this.translate.instant("Staff is required"));
      return false;
    } else if(!rowData['RateLevelID']){
      this.mangoAPISrvc.notify('error', 'Error', this.translate.instant("Rate Level is required"));
      return false;
    }
    return true;
  }

  validateRateTempalateNames(rowData){
    if(!rowData['TemplateName']){
      this.mangoAPISrvc.notify('error', 'Error', this.translate.instant("Template Name is required"));
      return false;
    } else if(!rowData['TemplateType']){
      this.mangoAPISrvc.notify('error', 'Error', this.translate.instant("Template Type is required"));
      return false;
    }
    return true;
  }

  validateRateLevels(rowData){
    if(!rowData['RateLevelDescription']){
      this.mangoAPISrvc.notify('error', 'Error', this.translate.instant("Description is required"));
      return false;
    }
    return true;
  }

  //save functions 

  saveRateLevelDetail(rowData){
    if(!this.validateRateLevelDetail(rowData)){
      this.onRowEditInit(rowData['RateTemplateDetailsID'], this.dtRateTemplateDetail)
      return;
    }
    if(this.selectedRateTemplateObj && this.selectedRateTemplateObj['TemplateType'] == 3 && !this.validateActivityCodeAndRateLevel(rowData)){
      this.mangoAPISrvc.notify('error', this.translate.instant('error'), "A record with the same activity and rate level already exists.");
      this.onRowEditInit(rowData['RateTemplateDetailsID'], this.dtRateTemplateDetail)
      return;
    }
    if(rowData['IsNew']){
      this.mangoAPISrvc.showLoader(true);
      this.mangoAPISrvc.addRateTemplateDetail(rowData).subscribe((result:any)=>{
        rowData['IsNew'] = false;
        rowData['IsChanged'] = false;
        rowData['IsServiceCodeChanged'] = false;
        rowData['IsHourlyRateChanged'] = false;
        rowData['RateTemplateDetailsID'] = result.data['RateTemplateDetailsID'];
        this.filterExceptionsRateLevelsOptions();
        this.mangoAPISrvc.showLoader(false);
        this.mangoAPISrvc.notify('success', this.translate.instant('Success'), this.translate.instant('notification_created'));
      })
    } else if((rowData['IsServiceCodeChanged'] || rowData['IsHourlyRateChanged']) && rowData['RateTemplateDetailsID']){
      this.mangoAPISrvc.showLoader(true);
      this.mangoAPISrvc.putRateTemplateDetail(rowData['RateTemplateDetailsID'], rowData).subscribe(result=>{
        this.mangoAPISrvc.showLoader(false);
        rowData['IsNew'] = false;
        rowData['IsServiceCodeChanged'] = false;
        rowData['IsChanged'] = false;
        rowData['IsHourlyRateChanged'] = false;
        this.mangoAPISrvc.notify('success', this.translate.instant('Success'), this.translate.instant('notification_updated'));
      })
    }
  }

  saveRateTemplate(rowData){
    if(!this.validateRateTempalateNames(rowData)){
      this.onRowEditInit(rowData['RateTemplateNamesID'], this.dtRateTemplateNames)
      return;
    }
    if(rowData['IsNew']){
      this.mangoAPISrvc.showLoader(true);
      this.mangoAPISrvc.addRateTemplateName(rowData).subscribe((result:any)=>{
        rowData['IsNew'] = false;
        rowData['IsChanged'] = false;
        rowData['RateTemplateNamesID'] = result.data['RateTemplateNamesID'];
        if(!rowData['Inactive']){
          this.rateTemplatesNamesOptions.push({label: rowData['TemplateName'], value: rowData['RateTemplateNamesID'], data: rowData })
        }
        this.onShowInactiveChange();
        this.mangoAPISrvc.showLoader(false);
        this.mangoAPISrvc.notify('success', this.translate.instant('Success'), this.translate.instant('notification_created'));
      })
    } else if(rowData['IsChanged'] && rowData['RateTemplateNamesID']){
      this.updateRateTemplateName(rowData)
      
    }
  }

  updateRateTemplateName(rowData, dt?){
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.putRateTemplateName(rowData['RateTemplateNamesID'], rowData).subscribe(result=>{
      this.mangoAPISrvc.showLoader(false);
      rowData['IsNew'] = false;
      rowData['IsChanged'] = false;
      if(!rowData['Inactive']){
        const tempOption = this.rateTemplatesNamesOptions.find(template=>template['value']==rowData['RateTemplateNamesID']);
        if(tempOption){
          tempOption['value'] = rowData['RateTemplateNamesID'];
          tempOption['label'] = rowData['TemplateName'];
        } else {
          const newTemplateOption = {value: rowData['RateTemplateNamesID'], data: rowData, label: rowData['TemplateName']}
          this.rateTemplatesNamesOptions.push(newTemplateOption);
        }
      } else {
        this.rateTemplatesNamesOptions = this.rateTemplatesNamesOptions.filter(template=>rowData['RateTemplateNamesID'] != template['value'])
      }
      this.rateTemplatesNamesOptions.sort((a,b) => a['value'] - b['value']);
      this.onShowInactiveChange();
      if(dt) this.removeRowEditMode(dt);
      this.mangoAPISrvc.notify('success', this.translate.instant('Success'), this.translate.instant('notification_updated'));
    })
  }

  saveRateLevel(rowData){
    if(!this.validateRateLevels(rowData)){
      this.onRowEditInit(rowData['RateLevelID'], this.dtRateLevels)
      return;
    }
    if(rowData['IsNew']){
      this.mangoAPISrvc.showLoader(true);
      this.mangoAPISrvc.addRateLevel(rowData).subscribe((result: any)=>{
        this.mangoAPISrvc.showLoader(false);
        rowData['IsNew'] = false;
        rowData['IsChanged'] = false;
        rowData['RateLevelID'] = result.data['RateLevelID'];
        this.rateLevelsOptions.push({label: rowData['RateLevelDescription'], value: rowData['RateLevelID']});
        this.rateTemplateDetailsrateLevelsOptions.push({label: rowData['RateLevelDescription'], value: rowData['RateLevelID']});
        // this.exceptionsRateLevelsOptions.push({label: rowData['RateLevelDescription'], value: rowData['RateLevelID']});
        this.staffRateLevelsOptions.push({label: rowData['RateLevelDescription'], value: rowData['RateLevelID']});
        this.filterDetailRateLevelsOptions();
        this.correctRateLevelDescription();
        this.mangoAPISrvc.notify('success', this.translate.instant('Success'), this.translate.instant('notification_created'));
      })
    } else if(rowData['IsChanged'] && rowData['RateLevelID']){
      this.mangoAPISrvc.showLoader(true);
      this.mangoAPISrvc.putRateLevel(rowData, rowData['RateLevelID']).subscribe(result=>{
        this.mangoAPISrvc.showLoader(false);
        rowData['IsNew'] = false;
        rowData['IsChanged'] = false;
        const tempRateLevel = this.rateLevelsOptions.find(rateLevel=>rateLevel['value'] == rowData['RateLevelID']);
        if(tempRateLevel){
          tempRateLevel['label'] = rowData['RateLevelDescription'];
        }
        this.filterDetailRateLevelsOptions();
        this.correctRateLevelDescription();
        this.mangoAPISrvc.notify('success', this.translate.instant('Success'), this.translate.instant('notification_updated'));
      })
    }
  }

  validateActivityCodeAndRateLevel(rowData){
    let duplicate = null;
    if(rowData['RateTemplateDetailsID']){
      duplicate = this.rateTemplateDetails.find(item=> item['RateTemplateDetailsID'] != rowData['RateTemplateDetailsID'] && !item['IsNew'] && item['ServiceCodeID'] == rowData['ServiceCodeID'] && item['RateLevelID'] == rowData['RateLevelID']);
    } else {
      duplicate = this.rateTemplateDetails.find(item=> !item['IsNew'] && item['ServiceCodeID'] == rowData['ServiceCodeID'] && item['RateLevelID'] == rowData['RateLevelID']);
    }
    // true if has no duplicate
    return !duplicate;
  }

  filterDetailRateLevelsOptions(){
    if(this.selectedRateTemplateObj && this.selectedRateTemplateObj['TemplateType'] == 3) return;
    this.rateTemplateDetailsrateLevelsOptions = this.rateLevelsOptions.filter(item=>!this.rateTemplateDetails.map(detail=>detail['RateLevelID']).includes(item['value']));
  }

  filterExceptionsRateLevelsOptions(){
    this.exceptionsRateLevelsOptions = this.rateLevelsOptions.filter(item=>this.rateTemplateDetails.map(detail=>detail['RateLevelID']).includes(item['value']));
  }
  
  saveStaffRateLevel(rowData, dt){
    if(rowData['IsChanged'] && rowData['StaffID']){
      this.mangoAPISrvc.showLoader(true);
      this.mangoAPISrvc.putStaffRateLevel(rowData['StaffID'], rowData).subscribe(result=>{
        this.mangoAPISrvc.showLoader(false);
        dt.editingRowKeys[rowData['StaffID']] = false;
        rowData['IsChanged'] = false;
        rowData['AutoClicked'] = false;
        this.mangoAPISrvc.notify('success', this.translate.instant('Success'), this.translate.instant('notification_updated'));
      })
    }
  }

  saveActivityRateTemplate(rowData, dt){
    if(rowData['IsChanged'] && rowData['ServiceCodeID']){
      this.mangoAPISrvc.showLoader(true);
      this.mangoAPISrvc.putActivityRateTemplate(rowData['ServiceCodeID'], rowData).subscribe(result=>{
        this.mangoAPISrvc.showLoader(false);
        dt.editingRowKeys[rowData['ServiceCodeID']] = false;
        rowData['IsChanged'] = false;
        rowData['AutoClicked'] = false;
        this.mangoAPISrvc.notify('success', this.translate.instant('Success'), this.translate.instant('notification_updated'));
      })
    }
  }

  saveClientRateTemplate(rowData, dt){
    this.mangoAPISrvc.showLoader(true);
    rowData['GroupDescriptionIDArray'] = rowData['GroupDescriptionIDArray'] && rowData['GroupDescriptionIDArray'].length > 0? rowData['GroupDescriptionIDArray'] : null;
    this.mangoAPISrvc.putClientRateTemplates(rowData['ClientID'], rowData).subscribe(result=>{
      this.mangoAPISrvc.showLoader(false);
      dt.editingRowKeys[rowData['ClientID']] = false;
      rowData['AutoClicked'] = false;
      this.mangoAPISrvc.notify('success', this.translate.instant('Success'), this.translate.instant('notification_updated'));
    })
  }

  saveRateLevelExceptions(rowData){
    if(!this.validateRateLevelExceptions(rowData)){
      this.onRowEditInit(rowData['RateLevelExceptionsID'], this.dtRateLevelExceptions)
      return;
    }
    if(rowData['IsNew']){
      this.mangoAPISrvc.showLoader(true);
      this.mangoAPISrvc.postRateLevelExceptions(rowData).subscribe((result:any)=>{
        this.mangoAPISrvc.showLoader(false);
        rowData['IsNew'] = false;
        rowData['IsChanged'] = false;
        rowData['RateLevelExceptionsID'] = result.data['RateLevelExceptionsID'];
        this.filterStaffList();
        this.mangoAPISrvc.notify('success', this.translate.instant('Success'), this.translate.instant('notification_created'));
      })
    } else if(rowData['IsChanged'] && rowData['RateLevelExceptionsID']){
      this.mangoAPISrvc.showLoader(true);
      this.mangoAPISrvc.putRateLevelExceptions(rowData, rowData['RateLevelExceptionsID']).subscribe(result=>{
        this.mangoAPISrvc.showLoader(false);
        rowData['IsNew'] = false;
        rowData['IsChanged'] = false;
        this.filterStaffList();
        this.mangoAPISrvc.notify('success', this.translate.instant('Success'), this.translate.instant('notification_updated'));
      })
    }
  }

  // delete functions

  deleteSelectedRateTemplateDetails(){
    const warningMessage = "All the rate level exceptions with the same rate level may be deleted. Are you sure you want to delete?";
    Swal.fire({
      title: this.translate.instant('confirmation'),
      text: warningMessage,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('yes_delete'),
      cancelButtonText: this.translate.instant('no_delete')
    }).then((result) => {
      if(result.value){
        this.rateTemplateDetails = this.rateTemplateDetails.filter(template=>template['RateTemplateDetailsID'] != null);
        this.selectedRateTemplateDetails = this.selectedRateTemplateDetails.filter(template=>template['RateTemplateDetailsID'] != null);
        const obj = {
          RateTemplateNamesID: this.selectedRateTemplateObj['RateTemplateNamesID'],
          details:this.selectedRateTemplateDetails
        }
        this.mangoAPISrvc.showLoader(true);
        this.mangoAPISrvc.rateTemplateDetailBulkDelete(obj)
          .subscribe(responseForkJoin=>{
            this.mangoAPISrvc.showLoader(false);
            this.rateTemplateDetails = this.rateTemplateDetails.filter(template=>!this.selectedRateTemplateDetails.map(detail=>detail['RateTemplateDetailsID']).includes(template['RateTemplateDetailsID']));
            this.rateLevelExceptions = this.rateLevelExceptions.filter(item=>this.rateTemplateDetails.map(detail=>detail['RateLevelID']).includes(item['RateLevelID']));
            this.filterDetailRateLevelsOptions();
            this.filterExceptionsRateLevelsOptions();
            this.filterStaffList();
            this.selectedRateTemplateDetails = [];
            this.mangoAPISrvc.notify('success', this.translate.instant('Success'), this.translate.instant('delete-message'));
          })
      }
    })
  }

  deleteRateTemplateDetail(rowData){
    if(rowData['IsNew'] && !rowData['RateTemplateDetailsID']){
      this.rateTemplateDetails = this.rateTemplateDetails.filter(template=>template['RateTemplateDetailsID'] != null);
      this.mangoAPISrvc.notify('success', this.translate.instant('Success'), this.translate.instant('delete-message'));
      return;
    }
    const detailWithSameLevel = this.rateTemplateDetails.filter(item=>item['RateLevelID'] == rowData['RateLevelID'])
    const exceptionWithSameLevel = this.rateLevelExceptions.filter(item=>item['RateLevelID'] == rowData['RateLevelID']).map(item=>item['RateLevelExceptionsID']);
    let warningMessage = this.translate.instant('delete_alert');
    if(exceptionWithSameLevel.length > 0 && detailWithSameLevel.length == 1){
      warningMessage = "All the rate level exceptions with the same rate level will be deleted. Are you sure you want to delete?"
    }
    Swal.fire({
      title: this.translate.instant('confirmation'),
      text: warningMessage,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('yes_delete'),
      cancelButtonText: this.translate.instant('no_delete')
    }).then((result) => {
      if (result.value) {
        this.mangoAPISrvc.showLoader(true);
        this.mangoAPISrvc.deleteRateTemplateDetail(rowData['RateTemplateDetailsID']).subscribe((result: any)=>{
          this.mangoAPISrvc.showLoader(false);
          this.rateTemplateDetails = this.rateTemplateDetails.filter(template=>template['RateTemplateDetailsID'] != rowData['RateTemplateDetailsID']);
          if(result.destroyedException){
            this.rateLevelExceptions = this.rateLevelExceptions.filter(item=>!exceptionWithSameLevel.includes(item['RateLevelExceptionsID']));
          }
          this.filterDetailRateLevelsOptions();
          this.filterExceptionsRateLevelsOptions();
          this.filterStaffList();
          this.mangoAPISrvc.notify('success', this.translate.instant('Success'), this.translate.instant('delete-message'));
        })
      }
    })
  }

  deleteRateTemplateName(rowData){
    if(rowData['IsNew'] && !rowData['RateTemplateNamesID']){
      this.rateTemplatesNames = this.rateTemplatesNames.filter(template=>template['RateTemplateNamesID'] != null);
      this.onShowInactiveChange();
      this.mangoAPISrvc.notify('success', this.translate.instant('Success'), this.translate.instant('delete-message'));
      return;
    }
    Swal.fire({
      title: this.translate.instant('confirmation'),
      text: this.translate.instant('rate_template_delete_warning'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('yes_delete'),
      cancelButtonText: this.translate.instant('no_delete')
    }).then((result) => {
      if (result.value) {
        this.mangoAPISrvc.showLoader(true);
        this.mangoAPISrvc.deleteRateTemplateName(rowData['RateTemplateNamesID']).subscribe((result:any)=>{
          this.mangoAPISrvc.showLoader(false);
          if(result.error){
            this.mangoAPISrvc.notify('error', this.translate.instant('error'), result.message);
            return;
          }
          this.rateTemplatesNames = this.rateTemplatesNames.filter(template=>template['RateTemplateNamesID'] != rowData['RateTemplateNamesID']);
          this.rateTemplatesNamesOptions = this.rateTemplatesNamesOptions.filter(template=>rowData['RateTemplateNamesID'] != template['value'])
          if(this.selectedRateTemplate == rowData['RateTemplateNamesID']){
            this.selectedRateTemplate = null;
            this.selectedRateTemplateObj = {};
          }
          this.onShowInactiveChange();
          this.mangoAPISrvc.notify('success', this.translate.instant('Success'), this.translate.instant('delete-message'));
        })

      }
    })
  }

  deleteRateLevel(rowData){
    if(rowData['IsNew'] && !rowData['RateLevelID']){
      this.rateLevels = this.rateLevels.filter(template=>template['RateLevelID'] != null);
      this.mangoAPISrvc.notify('success', this.translate.instant('Success'), this.translate.instant('delete-message'));
      return;
    }
    Swal.fire({
      title: this.translate.instant('confirmation'),
      text: this.translate.instant('delete_alert'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('yes_delete'),
      cancelButtonText: this.translate.instant('no_delete')
    }).then((result) => {
      if (result.value) {
        this.mangoAPISrvc.showLoader(true);
        this.mangoAPISrvc.deleteRateLevel(rowData['RateLevelID']).subscribe((result:any)=>{
          this.mangoAPISrvc.showLoader(false);
          if(result.error){
            this.mangoAPISrvc.notify('error', this.translate.instant('error'), result.message);
            return;
          }
          this.rateLevels = this.rateLevels.filter(template=>template['RateLevelID'] != rowData['RateLevelID']);
          this.rateLevelsOptions = this.rateLevelsOptions.filter(template=>template['value'] != rowData['RateLevelID']);
          this.staffRateLevelsOptions = this.staffRateLevelsOptions.filter(template=>template['value'] != rowData['RateLevelID']);
          this.staffsRateLevels = this.staffsRateLevels.filter(template=>template['value'] != rowData['RateLevelID']);
          this.rateTemplateDetailsrateLevelsOptions = this.rateTemplateDetailsrateLevelsOptions.filter(template=>template['value'] != rowData['RateLevelID']);
          this.filterDetailRateLevelsOptions();
          this.mangoAPISrvc.notify('success', this.translate.instant('Success'), this.translate.instant('delete-message'));
        })

      }
    })
  }

  deleteRateLevelExeption(rowData){
    if(rowData['IsNew'] && !rowData['RateLevelExceptionsID']){
      this.rateLevelExceptions = this.rateLevelExceptions.filter(template=>template['RateLevelExceptionsID'] != null);
      this.mangoAPISrvc.notify('success', this.translate.instant('Success'), this.translate.instant('delete-message'));
      return;
    }
    Swal.fire({
      title: this.translate.instant('confirmation'),
      text: this.translate.instant('delete_alert'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('yes_delete'),
      cancelButtonText: this.translate.instant('no_delete')
    }).then((result) => {
      if (result.value) {
        this.mangoAPISrvc.showLoader(true);
        this.mangoAPISrvc.deleteRateLevelExceptions(rowData['RateLevelExceptionsID']).subscribe(result=>{
          this.mangoAPISrvc.showLoader(false);
          this.rateLevelExceptions = this.rateLevelExceptions.filter(template=>template['RateLevelExceptionsID'] != rowData['RateLevelExceptionsID']);
          this.filterStaffList();
          this.mangoAPISrvc.notify('success', this.translate.instant('Success'), this.translate.instant('delete-message'));
        })

      }
    })
  }

  // add row functions

  addRateTemplateDetail(dt){
    if(this.rateLevelsOptions.filter(rateLevel=>rateLevel['value']).length == 0){
      this.noRateLevelsWarning();
      return;
    }
    const checkNew = this.rateTemplateDetails.filter(item => item['IsNew']);
    if(checkNew.length > 0){
      return;
    }
    const obj = {
      RateTemplateDetailsID: null,
      RateTemplateNamesID: this.selectedRateTemplate,
      ServiceCodeID: null,
      RateLevelID: null,
      HourlyRate: null,
      CompanyID: this.companyId,
      IsNew: true
    };
    if(this.rateTemplateDetailsrateLevelsOptions.length > 0){
      obj['RateLevelID'] = null;
      this.onRateLevelChange(obj);
    }
    this.rateTemplateDetails.push(obj);
    this.onRowEditInit(null, dt);
  }

  addRateLevelExceptions(dt){
    if(this.rateTemplateDetails.length == 0){
      this.noRateTemplateDetailsWarning();
      return;
    }
    const checkNew = this.rateLevelExceptions.filter(item => item['IsNew']);
    if(checkNew.length > 0){
      return;
    }
    const obj = {
      RateLevelExceptionsID: null,
      RateTemplateNamesID: this.selectedRateTemplate,
      StaffID: null,
      RateLevelID: null,
      StaffNumber: null,
      CompanyID: this.companyId,
      IsNew: true
    };
    if(this.staffListOptions.length > 0){
      obj['StaffID'] = null;
      this.onStaffChange(obj);
    }
    this.rateLevelExceptions.push(obj);
    this.onRowEditInit(null, dt);
  }

  addRateTemplateName(dt){
    const checkNew = this.rateTemplatesNames.filter(item => item['IsNew']);
    if(checkNew.length > 0){
      return;
    }
    const obj = {
      RateTemplateNamesID: null,
      TemplateName: "",
      TemplateType: 1,
      Inactive: false,
      CompanyID: this.companyId,
      IsNew: true
    };
    this.onTemplateTypeChange(obj);
    this.rateTemplatesNames.push(obj);
    this.onShowInactiveChange();
    this.onRowEditInit(null, dt);
    setTimeout(() => {
      this.scrollDownToNewRow('new-row1')
    }, 1000);
  }

  addRateLevel(dt){
    const checkNew = this.rateLevels.filter(item => item['IsNew']);
    if(checkNew.length > 0){
      return;
    }
    const obj = {
      RateLevelID: null,
      RateLevelDescription: null,
      CompanyID: this.companyId,
      IsNew: true
    };
    this.rateLevels.push(obj);
    this.onRowEditInit(null, dt);
    setTimeout(() => {
      this.scrollDownToNewRow('new-row2')
    }, 1000);
  }

  // cancel

  cancelStaffRateLevel(rowData){
    rowData['AutoClicked'] = false;
  }
  
  cancelRateTemplateDetail(rowData){
    if(rowData['IsNew']){
      this.rateTemplateDetails = this.rateTemplateDetails.filter(item=>!item['IsNew'])
      this.filterDetailRateLevelsOptions();
    }
  }

  cancelRateLevelExceptions(rowData){
    if(rowData['IsNew']){
      this.rateLevelExceptions = this.rateLevelExceptions.filter(item=>!item['IsNew'])
      this.filterStaffList();
    }
  }

  cancelRateTemplateName(rowData){
    if(rowData['IsNew']){
      this.rateTemplatesNames = this.rateTemplatesNames.filter(item=>!item['IsNew'])
      this.onShowInactiveChange();
    }
  }

  cancelRateLevel(rowData){
    if(rowData['IsNew']){
      this.rateLevels = this.rateLevels.filter(item=>!item['IsNew'])
    }
  }

  // table edit 
  onRowEditInit(index, dt, rowData?,rowIndex?, column?) {
    if(!index) return;
    this.removeRowEditMode(dt);
    if(dt) dt.editingRowKeys[index] = true;
    if(!column || rowData['AutoClicked']) return;
    if(column == "rate-levels"){
      this.selectCell('rate-levels', rowIndex, '.p-dropdown > .p-dropdown-trigger');
    } else if (column == "client-rate-templates"){
      this.selectCell('client-rate-templates', rowIndex, '.p-dropdown > .p-dropdown-trigger');
    } else if (column == "activity-rate-templates"){
      this.selectCell('activity-rate-templates', rowIndex, '.p-dropdown > .p-dropdown-trigger');
    }
    rowData['AutoClicked'] = true;
  }

  removeRowEditMode(dt) {
    if(dt) dt.editingRowKeys = {};
  }

  //bulk

  bulkUpdateClientRateTemplates(clear?){
    Swal.fire({
      title: this.translate.instant('confirmation'),
      text: this.translate.instant(`Are you sure you want to ${clear? 'clear': 'update'} all the selected clients' rate template names?`),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('yes_continue'),
      cancelButtonText: this.translate.instant('no_cancel')
    }).then((result) => {
      if(result.value && this.selectedClientRateTemplates.length > 0){
        this.selectedClientRateTemplates = this.selectedClientRateTemplates.map(clientRateTemplate=>{
          clientRateTemplate['GroupDescriptionIDArray'] = clientRateTemplate['GroupDescriptionIDArray'] && clientRateTemplate['GroupDescriptionIDArray'].length > 0? 
            clientRateTemplate['GroupDescriptionIDArray'] : null;
          clientRateTemplate['RateTemplateNamesID'] = clear? null : this.clientSelectedRateTemplate;
          const event = {
            value: clientRateTemplate['RateTemplateNamesID']
          }
          this.onRateTemplateNamesChange(event, clientRateTemplate)
          return clientRateTemplate;
        })
        this.mangoAPISrvc.showLoader(true);
        this.mangoAPISrvc.bulkUpdateClientRateTemplates(this.selectedClientRateTemplates).subscribe(result=>{
          this.selectedClientRateTemplates = [];
          this.mangoAPISrvc.showLoader(false);
          this.mangoAPISrvc.notify('success', this.translate.instant('Success'), this.translate.instant('notification_updated'));
        })
      }
    })
  }

  bulkUpdateActivityRateTemplates(clear?){
    Swal.fire({
      title: this.translate.instant('confirmation'),
      text: this.translate.instant(`Are you sure you want to ${clear? 'clear': 'update'} all the selected activities' rate template names?`),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('yes_continue'),
      cancelButtonText: this.translate.instant('no_cancel')
    }).then((result) => {
      if(result.value && this.selectedActivityRateTemplates.length > 0){
        this.selectedActivityRateTemplates = this.selectedActivityRateTemplates.map(activity=>{
          activity['RateTemplateNamesID'] = clear? null : this.activitySelectedRateTemplate;
          const event = {
            value: activity['RateTemplateNamesID']
          }
          this.onRateTemplateNamesChange(event, activity)
          return activity;
        })
        this.mangoAPISrvc.showLoader(true);
        this.mangoAPISrvc.bulkUpdateActivityRateTemplates(this.selectedActivityRateTemplates).subscribe(result=>{
          this.selectedActivityRateTemplates = [];
          this.mangoAPISrvc.showLoader(false);
          this.mangoAPISrvc.notify('success', this.translate.instant('Success'), this.translate.instant('notification_updated'));
        })
      }
    })
  }

  // toggle checkboxes
  
  toggleShowSelectedTemplate(type){
    if(type=='client'){
      if(this.showSelectedTemplate){
        this.dtClients._value = this.clientRateTemplates.filter(template=>template['RateTemplateNamesID'] == this.clientSelectedRateTemplate);
      }else{
        this.dtClients._value = this.clientRateTemplates;
      }
    } else if(type=="activity"){
      if(this.showSelectedTemplate){
        this.dtActivity._value = this.activityRateTemplates.filter(template=>template['RateTemplateNamesID'] == this.activitySelectedRateTemplate);
      }else{
        this.dtActivity._value = this.activityRateTemplates;
      }
    }
  }

  onShowInactiveChange(toggle?){
    setTimeout(()=>{
      if(this.showInactive){
        this.dtRateTemplateNames._value = this.rateTemplatesNames;
      } else {
        this.dtRateTemplateNames._value = this.rateTemplatesNames.filter(template=>!template['Inactive']);
      }
    }, toggle? 0 : 500)
  }

  toggleClientCheckbox(){
  }

  toggleClientHeaderCheckbox(){
  }

  // on change

  onInactiveChange(rowData, dt){
    if(rowData['IsChanged'] && rowData['RateTemplateNamesID']){
      if(rowData['Inactive']){
        this.mangoAPISrvc.showLoader(true);
        this.mangoAPISrvc.checkRateTemplateNames(rowData['RateTemplateNamesID']).subscribe((result: any)=>{
          this.mangoAPISrvc.showLoader(false);
          if(result.linked){
            this.mangoAPISrvc.notify('error', 'Error', result.message);
            rowData['Inactive'] = false;
            rowData['IsChanged'] = false;
            this.removeRowEditMode(dt);
            return;
          } else {
            if(this.selectedRateTemplate == rowData['RateTemplateNamesID']){
              this.selectedRateTemplate = null;
              this.selectedRateTemplateObj = {};
            }
            this.updateRateTemplateName(rowData, dt);
          }
        })
      } else {
        this.updateRateTemplateName(rowData, dt);
      }
    }
  }

  onTemplateTypeChange(rowData){
    rowData['TemplateTypeValue'] = this.getRateTemplateType(rowData['TemplateType']);
  }

  onRateTemplateNamesChange(event, rowData){
    let selectedTemplate = this.rateTemplatesNamesOptions.find(template=>template.value==event.value);
    if(!selectedTemplate && !event.value){
      rowData['TemplateName'] = "None Assigned";
      rowData['RateTemplateNamesID']  = null;
      return;
    }
    selectedTemplate = selectedTemplate['data'];
    rowData['TemplateName'] = selectedTemplate['TemplateName'];
    rowData['RateTemplateNamesID'] = selectedTemplate['RateTemplateNamesID']
  }

  onRateTemplateNamesChangeClient(event, rowData, dt, autoSave?){
    this.onRateTemplateNamesChange(event, rowData)
    if(autoSave) this.saveClientRateTemplate(rowData, dt)
  }

  onRateTemplateNamesChangeActivity(event, rowData, dt, autoSave?){
    this.onRateTemplateNamesChange(event, rowData)
    // if(autoSave) this.saveClientRateTemplate(rowData, dt)
  }

  onRateTemplateChange(event){
    let selectedTempate = this.rateTemplatesNamesOptions.find(template=>template.value==event);
    if(!selectedTempate) return;
    selectedTempate = selectedTempate['data']
    this.selectedRateTemplateObj = selectedTempate;
    this.selectedRateTemplateObj['TemplateTypeValue'] = this.getRateTemplateType(selectedTempate['TemplateType']);
    this.selectedRateTemplateDetails = [];
    this.mangoAPISrvc.showLoader(true);
      this.mangoAPISrvc.getRateTemplateDetails(this.companyId, selectedTempate['RateTemplateNamesID']).subscribe((result: any)=>{
        this.rateTemplateDetails = result;
        this.mangoAPISrvc.getRateLevelExceptions(this.companyId, selectedTempate['RateTemplateNamesID']).subscribe((result: any)=>{
          this.rateLevelExceptions = result;
          this.filterStaffList();
          this.mangoAPISrvc.getRateLevels(this.companyId).subscribe((result: any)=>{
            this.mangoAPISrvc.showLoader(false);
            this.rateLevelsOptions = result.map(rateLevel=>{
              return {
                label: rateLevel['RateLevelDescription'],
                value: rateLevel['RateLevelID']
              }
            })
            this.rateTemplateDetailsrateLevelsOptions = this.rateLevelsOptions;
            this.filterDetailRateLevelsOptions();
            this.filterExceptionsRateLevelsOptions();
          })
        })
      })
  }

  handleRateLevelChange(event: any, rowData: any) {
    const selectedRateLevel = this.rateLevelsOptions.find(rateLevel => rateLevel.value === event);
    if (selectedRateLevel) {
      rowData.RateLevelDescription = selectedRateLevel.label;
      rowData.IsChanged = true;
    } else {
      rowData.RateLevelID = null;
      rowData.RateLevelDescription = "None Assigned";
      rowData.IsChanged = true;
    }
  }

  handleStaffChange(event: any, rowData: any) {
    const selectedStaff = this.staffListOptions.find(staff => staff.value === event);
    if (selectedStaff) {
      rowData.StaffName = selectedStaff.StaffName;
      rowData.StaffNumber = selectedStaff.StaffNumber;
      rowData.IsChanged = true;
    } else {
      rowData.StaffID = null;
      rowData.StaffName = "None Assigned";
      rowData.StaffNumber = null;
      rowData.IsChanged = true;
    }
  }

  onServiceCodeChange(rowData){
    const serviceCode = this.activityList.find(activity=>activity['value'] == rowData['ServiceCodeID']);
    rowData['ServiceCode'] = serviceCode? serviceCode['label'] : null;
  }
  
  onRateLevelChange(rowData){
    const rateLevel = this.rateLevelsOptions.find(rateLevel=>rateLevel['value'] == rowData['RateLevelID']);
    rowData['RateLevelDescription'] = rateLevel? rateLevel['label'] : "None Assigned";
    this.filterDetailRateLevelsOptions();
  }

  onStaffChange(rowData){
    const staff = this.staffListOptions.find(staff=>staff['value'] == rowData['StaffID']);
    if(staff){
      rowData['StaffName'] = staff['StaffName'];
      rowData['StaffNumber'] = staff['StaffNumber'];
      rowData['StaffID'] = staff['StaffID'];
      this.filterStaffList();
    } else{
      rowData['StaffName'] = "None Assigned";
      rowData['StaffID'] = null;
      rowData['StaffNumber'] = null;
    }
  }

  filterStaffList(){
    this.staffListOptions = [...this.staffList];
    this.staffListOptions = this.staffListOptions
      .filter(staff=>!this.rateLevelExceptions.map(item=>item['StaffID']).includes(staff['value']))
  }

  applySelectedRateLevel(clear?){
    if((!clear && !this.selectedRateLevel) || this.selectedStaffRateLevels.length == 0){
      Swal.fire({
        title: this.translate.instant('warning'),
        text: this.translate.instant(`You have to select a rate level and a list of staff to apply it to.`),
        icon: 'warning',
        confirmButtonText: this.translate.instant('ok'),
      });
      return;
    }
    this.selectedStaffRateLevels = this.selectedStaffRateLevels
      .map(staff=>{
        staff['RateLevelID'] = clear? null: this.selectedRateLevel['RateLevelID'];
        this.onRateLevelChange(staff);
        return staff;
      })
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.bulkUpdateStaffRateLevels(this.selectedStaffRateLevels)
      .subscribe(result=>{
        this.mangoAPISrvc.showLoader(false);
        this.selectedStaffRateLevels = [];
        this.selectedRateLevel = null;
        this.mangoAPISrvc.notify('success', this.translate.instant('Success'), this.translate.instant('notification_updated'));
      })
  }

  scrollDownToNewRow(id) {
    if (id) {
      const el = document.getElementById(id);
      el.scrollIntoView({ behavior: "smooth" });
      setTimeout(() => {
        const el1 = document.querySelectorAll(`.${id}`);
        el1[0].querySelectorAll("input")[0]?.focus();
      }, 500);
    }
  }

  selectCell(clss, index, xtraEl?) {
    setTimeout(() => {
      let colClass = `.${clss}-${index}`
      colClass += xtraEl ? ` > ${xtraEl}` : ''
      if(['rate-levels', 'client-rate-templates','activity-rate-templates'].includes(clss))
        $(colClass).click();
      else
        $(colClass).select();
    }, 50);
  }

  findRateLevelsOptions(item){
    const detail = this.rateLevelsOptions.find(rateLevel=>rateLevel['value'] == item['RateLevelID'] || rateLevel['value'] == item['value']);
    if(detail){
      item['RateLevelDescription'] = detail['label'];
      item['label'] = detail['label'];
    }
    return item;
  }

  correctRateLevelDescription(){
    this.rateTemplateDetails = this.rateTemplateDetails.map(item=>this.findRateLevelsOptions(item))
    this.rateLevelExceptions = this.rateLevelExceptions.map(item=>this.findRateLevelsOptions(item))
    this.staffRateLevelsOptions = this.staffRateLevelsOptions.map(item=>this.findRateLevelsOptions(item))
    this.staffsRateLevels = this.staffsRateLevels.map(item=>this.findRateLevelsOptions(item))
    this.rateTemplateDetailsrateLevelsOptions = this.rateTemplateDetailsrateLevelsOptions.map(item=>this.findRateLevelsOptions(item))
    this.exceptionsRateLevelsOptions = this.exceptionsRateLevelsOptions.map(item=>this.findRateLevelsOptions(item))
  }
}
