<div class="client-statements">
    <div class="layout-content p-pt-0">
        <span class="pull-right p-mr-3 clientform-btn-top">
            <p-button pRipple icon="fa fa-play-circle" label="{{'billing.Process-Late-Fees' | translate}}"
                styleClass="p-button-sm" class="p-button-help p-pr-2" [disabled]="(selectedClientsRecords.length == 0)" (click)="processStatements();">
            </p-button>
            <p-button pRipple label="{{'Reports_Center' | translate}}" styleClass="p-button-sm" class="p-button-help p-pr-2" routerLink="/reports">
            </p-button>
        </span>
        <div class="p-grid p-mx-2">
            <div class="p-col-12">
                <div class="card p-mb-1 p-pl-5 p-py-3">
                    <label class="label-text p-mr-4 required-field">{{'billing.calculate-late-fees-thru' |
                        translate}}</label>
                    <p-calendar [monthNavigator]="true" [yearNavigator]="true" [(ngModel)]="stmtDate"
                        (onSelect)="generateLateFees();calculateFooterTotals();" (onClearClick)="generateLateFees();calculateFooterTotals();"
                        yearRange="2000:2099" showButtonBar="true" styleClass="width-14p"></p-calendar>
                    <!-- <p-checkbox [(ngModel)]="isShowAll" [binary]="true" [ngModelOptions]="{standalone: true}" styleClass="p-ml-5 p-mb-2"
                        label="{{'statements.Show_All_Customers_with_Balances' | translate}}" class="label-text" (onChange)="onChangeCheckbox()">
                    </p-checkbox> -->
                </div>
                <div class="card p-pt-0">
                    <div class="p-col-12 p-pt-0">
                        <p-table [value]="filteredDataSource" [responsive]="true" sortField="ClientName"
                            (onFilter)="onFilter($event)" [rows]="15" [(selection)]="selectedClientsRecords"
                            styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                            [columns]="cols" dataKey="BillingHeaderID" [sortOrder]="0" sortMode="single" 
                            (onHeaderCheckboxToggle)="parentCheckboxSelection($event)"
                            [globalFilterFields]="['ClientName', 'DescriptionShort', 'LastLateFeeDate', 'InvoiceDate', 'InvoiceNumber', 'InvoiceBalance', 'calcultedLateFeeAmount']"
                            [rowHover]="true" #dt [scrollable]="true"
                            (onRowUnselect)="parentCheckboxUnSelection($event)" [scrollHeight]="'calc(90vh - 350px)'"
                            (onRowSelect)="childCheckboxSelection($event)">
                            <ng-template pTemplate="caption">
                                <div class="table-header">
                                    <span class="p-input-icon-left">
                                        <i class="fal fa-search"></i>
                                        <input #searchValue pInputText type="text"
                                            (input)="dt.filterGlobal($event.target.value, 'contains')"
                                            placeholder="{{'globalsearch' | translate}}" />
                                    </span>
                                    <span>
                                        <button pButton pRipple type="button" icon="pi pi-times"
                                            class="p-button-rounded p-button-danger p-button-text"
                                            (click)="dt.reset();clearSearchFilter()" tooltipPosition="top" pTooltip="{{'clear' | translate}}"></button>
                                    </span>
                                    <p-message *ngIf="filteredItemsSize > -1" severity="success"
                                        text="{{filteredItemsSize}} {{'records_found' | translate}}." styleClass="p-ml-2">
                                    </p-message>
                                    <span class="pull-right">
                                        <button type="button" pButton pRipple icon="pi pi-file-o" (click)="exportCSVFile()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom" *ngIf="filteredDataSource.length > 0"></button>
                                       <!--  <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2"  pTooltip="XLS" tooltipPosition="bottom" *ngIf="filteredDataSource.length > 0"></button> -->
                                        <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="exportPdf()" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" *ngIf="filteredDataSource.length > 0"></button>
                                    </span>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th class="width-3p p-text-center">
                                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                    </th>
                                    <th class="width-15p p-text-left" pSortableColumn="ClientName">
                                        {{'client.name' | translate}}
                                        <p-sortIcon field="ClientName"></p-sortIcon>
                                    </th>
                                    <th class="width-35p p-text-left">{{'invoice-memo' | translate}}</th>
                                    <th class="width-11p p-text-center"
                                        pTooltip="{{'billing.Last-Late-Fee-Date' | translate}}" tooltipPosition="top">
                                        {{'billing.Fee-Date' | translate}}<i class="fa fa-question-circle ic-blue p-pl-1"></i>
                                    </th>
                                    <th class="width-10p p-text-center">{{'Accounts.invoice-date' | translate}}</th>
                                    <th class="width-10p p-text-right">{{'Accounts.invoice-number' | translate}}</th>
                                    <th class="width-6p p-text-right">{{'client.grace_days' | translate}}</th>
                                    <th class="width-10p p-text-right" pSortableColumn="InvoiceBalance">
                                        {{'Accounts.invoice-balance' | translate}}
                                        <p-sortIcon field="InvoiceBalance"></p-sortIcon>
                                    </th>
                                    <th class="width-10p p-text-right"  pSortableColumn="calcultedLateFeeAmount">
                                        {{'client.fee_to_apply' | translate}}
                                        <p-sortIcon field="calcultedLateFeeAmount"></p-sortIcon>
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData let-columns="columns">
                                <tr>
                                    <td class="width-3p p-text-center">
                                        <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                                    </td>
                                    <td class="width-15p p-text-left p-text-nowrap p-text-truncate"
                                        pTooltip="{{rowData.ClientName}}" showDelay="800" tooltipPosition="top"
                                        tooltipStyleClass="tooltip-no-wrap">
                                        {{rowData.ClientName}}
                                    </td>
                                    <td class="width-35p p-text-left p-text-nowrap p-text-truncate">
                                        <span pTooltip="{{rowData.DescriptionShort}}" showDelay="800" tooltipPosition="top"
                                            tooltipStyleClass="tooltip-width-450"
                                            class="cursor-hand">{{rowData.DescriptionShort}}</span>
                                    </td>
                                    <td class="width-11p p-text-center p-text-nowrap p-text-truncate" showDelay="800" pTooltip="{{ mangoUtils.formatDateString(rowData.LastLateFeeDate) | date:'MM/dd/yyyy'}}" tooltipPosition="top"
                                    tooltipStyleClass="tooltip-no-wrap">
                                        {{mangoUtils.formatDateString(rowData.LastLateFeeDate) | date:'MM/dd/yyyy'}}
                                    </td>
                                    <td class="width-10p p-text-center p-text-nowrap p-text-truncate" showDelay="800" pTooltip="{{mangoUtils.formatDateString(rowData.InvoiceDate) | date:'MM/dd/yyyy'}}" tooltipPosition="top"
                                    tooltipStyleClass="tooltip-no-wrap">
                                        {{mangoUtils.formatDateString(rowData.InvoiceDate) | date:'MM/dd/yyyy'}}
                                    </td>
                                    <td class="width-10p p-text-right">
                                        {{rowData.InvoiceNumber}}
                                    </td>
                                    <td class="width-6p p-text-right">
                                        {{rowData.GraceDays}}
                                    </td>
                                    <td class="width-10p p-text-right">
                                        {{(rowData.InvoiceBalance) | currency:'USD':'symbol':'1.2-2'}}
                                    </td>
                                    <td class="width-10p p-text-right">
                                        {{rowData.calcultedLateFeeAmount | currency:'USD':'symbol':'1.2-2'}}
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="footer">
                                <tr>
                                  <td></td><td></td><td></td><td></td><td></td><td></td>
                                  <td class="width-6p p-text-right ic-orange p-text-bold">{{'Totals' | translate}}</td>                        
                                  <td class="width-10p p-text-right ic-orange p-text-bold">
                                    {{ totalInvoiceAmount | currency:'USD':'symbol':'1.2-2' }}
                                  </td>
                                  <td class="width-10p p-text-right ic-orange p-text-bold">
                                    {{ totalLateFeeAmount | currency:'USD':'symbol':'1.2-2'}}
                                  </td>
                                </tr>
                              </ng-template>
                              <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td class="width-98p">
                                        <p-message severity="info" text="{{'data_not_found' | translate}}." styleClass="p-px-2">
                                        </p-message>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>