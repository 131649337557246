import { AuthGuard } from './_services/auth-guard.service';
import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  HostListener,
  ViewEncapsulation,
  Output,
  EventEmitter,
} from "@angular/core";
import { SharedComponentsService } from "@app/shared/components";
import { Subscription, timer as ObservableTimer } from "rxjs";
import { AppMainComponent } from "./app.main.component";
import moment from "moment";
import { EncrDecrService, MangoApiService, mangoUtils } from "./_services";
import { AppConstants } from "./_helpers/api-constants";
import * as workerTimers from "worker-timers";
import { TranslateService } from "@ngx-translate/core";
import Swal from "sweetalert2";
import { Router } from "@angular/router";

declare let numeral: any;
@Component({
  selector: "app-timer-list",
  templateUrl: "./timer-list.component.html",
  encapsulation: ViewEncapsulation.Emulated,
})
export class TimerListComponent implements OnInit {
  @ViewChild("timerMenu") timerMenu;
  @ViewChild("timerIcon") timerIcon;
  public showMenuTime: boolean = false;
  public tasksList: any[] = [];
  public timerTaskSub: Subscription;
  public timersSub: Subscription;
  @Output() appendTasksList = new EventEmitter<any[]>();
  companyGlobalSetting: any = {};
  clientList = [];
  isLoggedInUser: boolean = false
  saveTimersInterval = null;
  companyId
  isSavingTimers: boolean = false;

  constructor(
    private encrDecSrvc: EncrDecrService,
    private mangoAPISrvc: MangoApiService,
    private sharedSrvc: SharedComponentsService,
    private translate: TranslateService,
    public app: AppMainComponent,
    private mangoUtils: mangoUtils,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private authGuard: AuthGuard
  ) {}

  @HostListener("window:beforeunload")
  saveTasksToSession(isFromIntevalSave: boolean = false) {
    if(this.isSavingTimers) return;
    let counter = 0;
    const staffID = this.encrDecSrvc.getObject(AppConstants.resourceID)
    if(!staffID) return;
    this.isSavingTimers = true;
    this.tasksList.map((task) => {
      if(!isFromIntevalSave) {
        if (task.isStarted) {
          if (task.timer) {
            // task.timer.unsubscribe();
            workerTimers.clearInterval(task.timer);
          }
          task.isStarted = false;
          task.doRestart = true;
          delete task.timer;
        } else {
          task.isStarted = false;
          task.doRestart = false;
        }
      }
      const obj = {
        StaffID: staffID,
        ClientID: task.ClientID,
        CompanyID: this.companyId,
        ProjectMasterID: task.ProjectMasterID,
        ServiceCodeID: task.ServiceCodeID,
        Description: task.Memo,
        StartTime: task.TimeStart,
        TimerID: task.TimerID,
        timerTask: task
      }

      this.mangoAPISrvc.createOrUpdateTimer(obj).subscribe((result: any) => {
        counter++
      }, err => {
        counter++
      })

    });

    const intervalid = setInterval(() => {
      if(counter === this.tasksList.length) {
        clearInterval(intervalid);
        this.isSavingTimers = false;
      }
    }, 500)
    this.encrDecSrvc.addObject(
      AppConstants.timerOwner,
      JSON.stringify(this.encrDecSrvc.getObject(AppConstants.staffID))
    );
    this.encrDecSrvc.addObject(
      AppConstants.timerTasks,
      JSON.stringify(this.tasksList)
    );
  }

  deleteTimer(id) {
    Swal.fire({
      title: "Confirmation",
      text: this.translate.instant("timer.delete-timer-dialog"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: this.translate.instant("Yes_do_it"),
      cancelButtonText: this.translate.instant("no_cancel"),
    }).then((result) => {
      if (result.value) {
        const srchTask = this.tasksList.filter((item) => {
          return id == item.TimerID;
        });

        if(srchTask.length === 0)
          return;

        srchTask[0].deleted = true;
        if (srchTask[0].timer) {
          workerTimers.clearInterval(srchTask[0].timer);
          delete srchTask[0].timer;
        }
        srchTask[0].isStarted = false;
        delete srchTask[0].timer;
        const tasks = this.encrDecSrvc.getObject(AppConstants.timerTasks);
        const Data = tasks ? JSON.parse(tasks) : "";
        if (Data) {
          this.encrDecSrvc.addObject(AppConstants.timerTasks, JSON.stringify(Data.filter(i => i.id !== srchTask[0].id)));
        }
        this.sharedSrvc.timerTask = srchTask[0];
        srchTask[0] = {};
      }
    })
  }

  // @HostListener('document:click', ['$event'])
  // onClick(event) {
  //   if (!this.timerMenu.nativeElement.contains(event.target) && !this.timerIcon.nativeElement.contains(event.target)) {
  //     this.showMenuTime = false;
  //   }
  // }

  loadTasksFromSession() {
    // let tasks = this.encrDecSrvc.getObject(AppConstants.timerTasks);
    const userJustLoggedIn = this.encrDecSrvc.getObject(AppConstants.userJustLoggedIn);
    this.mangoAPISrvc.getTimers(this.encrDecSrvc.getObject(AppConstants.resourceID)).subscribe((result: any) => {
      this.tasksList = result?.map((timer) => {
        const counter =
          timer?.timerTask?.counter || this.mangoUtils.convertDateTimeToMS(timer?.timerTask?.TotalTime);
        const [hours, minutes, seconds] = this.mangoUtils.msToTime(counter);
        return {
          ...timer?.timerTask,
          TimerID: timer?._id,
          id: timer?._id,
          counter,
          DisplayTime:
            (hours ? (hours > 9 ? hours : "0" + hours) : "00") +
            ":" +
            (minutes ? (minutes > 9 ? minutes : "0" + minutes) : "00") +
            ":" +
            (seconds > 9 ? seconds : "0" + seconds),
        };
      });
      this.appendTasksList.emit(this.tasksList);
      this.tasksList?.map((task) => {
        if (!task.isPaused) {
          this.startTimer(task.id);
        }
      });
    }, err => console.log(err))
  }

  ngOnInit() {
    this.companyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    setTimeout(() => {
      if(this.authGuard.isLoggedin) {
        this.subscribeSubmitTimerTask();
        this.loadTasksFromSession();
        this.timersSub = this.sharedSrvc.timersSub.subscribe((result) => {
          if(result) {
            this.saveTasksToSession()
          }
        })
  
        //@NOTE: This will save timers every minute.
        this.saveTimersInterval = workerTimers.setInterval(() => {
          if (this.tasksList?.length > 0 && this.authGuard.isLoggedin) this.saveTasksToSession(true);
        }, 60000);
      }
    }, 50);
  }

  ngOnDestroy() {
    if (this.timerTaskSub) {
      this.timerTaskSub.unsubscribe();
    }
    if (this.timersSub)
      this.timersSub.unsubscribe();
    this.saveTasksToSession();
    workerTimers.clearInterval(this.saveTimersInterval);
  }

  subscribeSubmitTimerTask() {
    this.timerTaskSub = this.sharedSrvc.timerTask.subscribe((timerTask) => {
      if (timerTask.isClearTimers) {
        this.tasksList = []
        return;
      }
      if (timerTask.deleted == true) {
        let index = undefined;
        this.tasksList.map((item, i) => {
          if (item.id == timerTask.id || item.id == timerTask.TimerID) {
            index = i;
          }
        });

        if(timerTask?.TimerID) {
          this.mangoAPISrvc.deleteTimer(timerTask?.TimerID).subscribe((result: any) => {
            console.log("Deleted")
          }, err => {
            console.log(err)
          })
        }

        this.tasksList.splice(index, 1);
        this.encrDecSrvc.addObject(
          AppConstants.timerTasks,
          JSON.stringify(this.tasksList)
        );
        this.appendTasksList.emit(this.tasksList);
        this.sharedSrvc.openTimerDialog({ display: false });
        return;
      }

      if(timerTask.isCanceled && timerTask.id) {
        this.startTimer(timerTask.id);
        return;
      }

      if (!timerTask.submitted) {
        return;
      }

      if (!timerTask.ClientID) {
        console.log("no client selected");
        return;
      }

      if (!timerTask.ServiceCodeID) {
        console.log("no activity selected");
        return;
      }

      const id = this.getRandomInt(99, 9999);
      let hours = moment(timerTask.TotalTime, "hh:mm:ss").hour().toString();
      if (hours.length == 1) {
        hours = "0" + hours;
      }
      let minutes = moment(timerTask.TotalTime, "hh:mm:ss")
        .minutes()
        .toString();
      if (minutes.length == 1) {
        minutes = "0" + minutes;
      }

      let seconds = moment(timerTask.TotalTime, "hh:mm:ss")
        .seconds()
        .toString();
      if (seconds.length == 1) {
        seconds = "0" + seconds;
      }

      if (!timerTask.id) {
        timerTask.id = id;
      }
      const obj = {
        StaffID: this.encrDecSrvc.getObject(AppConstants.resourceID),
        ClientID: timerTask.ClientID,
        CompanyID: this.companyId,
        ProjectMasterID: timerTask.ProjectMasterID,
        ServiceCodeID: timerTask.ServiceCodeID,
        Description: timerTask.Memo,
        StartTime: timerTask.TimeStart,
        TimerID: timerTask.TimerID,
        timerTask
      }

      if(obj.TimerID) {
        //this means it will go into update mode.
        delete obj.StaffID
        delete obj.StartTime
      }

      this.mangoAPISrvc.showLoader(true);
      this.mangoAPISrvc.createOrUpdateTimer(obj).subscribe((result: any) => {
        this.mangoAPISrvc.showLoader(false);

        timerTask.id =
          result.data.ops && result.data.ops?.length > 0
            ? result.data.ops[0]._id
            : timerTask.TimerID;
        timerTask.TimerID =
          result.data.ops && result.data.ops?.length > 0
            ? result.data.ops[0]._id
            : timerTask.TimerID;
        timerTask.DisplayTime = `${hours}:${minutes}:${seconds}`;
        timerTask.hours = Number(hours);
        timerTask.minutes = Number(minutes);
        timerTask.seconds = Number(seconds);
        timerTask.isStarted = 0;

        const srchTask = this.tasksList.filter((item, i) => {
          const returnVal = item.id == timerTask.id;
          if (returnVal) {
            this.tasksList[i] = timerTask;
          }
          return returnVal;
        });
        if (srchTask.length == 0) {
          this.tasksList.push(timerTask);
          this.appendTasksList.emit(this.tasksList);
        }

        this.encrDecSrvc.addObject(
          AppConstants.timerTasks,
          JSON.stringify(this.tasksList)
        );

        if (timerTask.id) {
          this.startTimer(timerTask.id);
          if(this.companyGlobalSetting && this.companyGlobalSetting['isAutoPause']){
            this.pauseTimersExcept(timerTask.id)
          }
        }
        // this.changeDetectorRef.detectChanges();
      }, err => {
        this.mangoAPISrvc.notify(
          "error",
          "Error!",
          "Error Creating/Updating Timer!"
        );
        this.mangoAPISrvc.showLoader(false);
      })

      this.sharedSrvc.openTimerDialog({ display: false });
    });
  }

  pauseTimersExcept(id, isPause?){
    const tasksToPause = this.tasksList.filter((item) => {
      return item.id != id;
    });

    if (tasksToPause.length == 0) {
      console.log("no task found");
      return;
    }
    tasksToPause.forEach(task=>{
      if (!task.isStarted) {
        console.log("task not running");
      }else if (task.timer) {
        // task.timer.unsubscribe();
        if(isPause) {
          const obj = {
            TimerID: task.TimerID,
            timerTask: { ...task, isPaused: true }
          }
          task.isPaused = true
  
          this.mangoAPISrvc.createOrUpdateTimer(obj).subscribe((result: any) => {
          }, err => console.log(err))
        }
        workerTimers.clearInterval(task.timer);
        task.isStarted = false;
        delete task.timer;
      }
    })
  }

  showMenuIconTimerChild() {
    this.showMenuTime = !this.showMenuTime;
  }

  startTimer(id, timerList?, isPause?) {
    const srchTask = this.tasksList.filter((item) => {
      return item.id == id;
    });

    if (srchTask.length == 0) {
      console.log("no task found");
      return;
    }

    const task = srchTask[0];
    if (task.isStarted) {
      console.log("task already running");
      return;
    }

    if(isPause) {
      const obj = {
        TimerID: task.TimerID,
        timerTask: { ...task, isPaused: false }
      }
      task.isPaused = false
      this.mangoAPISrvc.createOrUpdateTimer(obj).subscribe((result: any) => {
      }, err => console.log(err))
    }

    const timer = ObservableTimer(0, 997);
    if (task.timer) {
      // task.timer.unsubscribe();
      workerTimers.clearInterval(task.timer);
      delete task.timer;
    }
    task.isStarted = true;
    const startTime = Date.now() - (task.counter || 0)
    task.timer = workerTimers.setInterval(() => {
      task.counter = Date.now() -  startTime
      const [hours, minutes, seconds] = this.mangoUtils.msToTime(task.counter)

      task.hours = hours;
      task.minutes = minutes;
      task.seconds = seconds;
      task.DisplayTime =
        (hours ? (hours > 9 ? hours : "0" + hours) : "00") +
        ":" +
        (minutes ? (minutes > 9 ? minutes : "0" + minutes) : "00") +
        ":" +
        (seconds > 9 ? seconds : "0" + seconds);
    }, 1000);
    this.getCompanyGlobalSetting();
    if(timerList && this.companyGlobalSetting && this.companyGlobalSetting['isAutoPause']){
      this.pauseTimersExcept(id, isPause)
    }
  }

  stopTimer(id, isDelete: boolean = false, isPause: boolean = false) {
    const srchTask = this.tasksList.filter((item) => {
      return item.TimerID == id;
    });

    if (srchTask.length == 0) {
      console.log("no task found");
      return;
    }

    const task = srchTask[0];
    if(isDelete && task?.TimerID) {
      this.mangoAPISrvc.deleteTimer(task.TimerID).subscribe((result: any) => {
        console.log("Deleted")
      }, err => {
        console.log(err)
      })
      return;
    }

    if (!task.isStarted) {
      console.log("task not running");
      return;
    }


    if(isPause) {
      const obj = {
        TimerID: task.TimerID,
        timerTask: { ...task, isPaused: true }
      }
      
      task.isPaused = true;
      this.mangoAPISrvc.createOrUpdateTimer(obj).subscribe((result: any) => {
      }, err => console.log(err))
    }

    if (task.timer && task.timer) {
      // task.timer.unsubscribe();
      workerTimers.clearInterval(task.timer);
    }
    task.isStarted = false;
    delete task.timer;
  }

  editTask(id) {
    this.stopTimer(id);
    const srchTask = this.tasksList.filter((item) => {
      return item.id == id;
    });

    if (srchTask.length == 0) {
      console.log("no task found by id:", id);
      return;
    }

    this.showMenuTime = false;

    setTimeout(() => {
      this.sharedSrvc.openTimerDialog({
        display: true,
        timerTask: srchTask[0],
        convert: false,
      });
    }, 200);
  }

  openTimerModal() {
    this.getCompanyGlobalSetting();
    let tasksList = this.encrDecSrvc.getObject(AppConstants.timerTasks);
    tasksList = (tasksList === '' || !tasksList) ? [] : tasksList;
    let taskListLength = 0;
    try {
      taskListLength = JSON.parse(tasksList).length;
    } catch (e) {
    }
    if(this.companyGlobalSetting &&
        this.companyGlobalSetting['AllowedTimers'] != null && this.companyGlobalSetting['AllowedTimers'] != 0 &&
        this.companyGlobalSetting['AllowedTimers'] <= taskListLength){
          Swal.fire({
            title: this.translate.instant('confirmation'),
            text: this.translate.instant('max_allowed_timer_warning'),
            icon: 'warning',
            confirmButtonText: this.translate.instant('ok_button'),
            timer: 5000,
          })
          return;
        }
    this.app.onLayoutClick();
    this.sharedSrvc.openTimerDialog({ display: true });
  }

  convertHoursToNumericHours(time) {
    const arrTime = time.split(":");
    let minute: any = Math.round((arrTime[1] * 100) / 60);
    if (minute < 9) minute = "0" + minute;
    return arrTime[0] + "." + minute;
  }

  getCompanyGlobalSetting() {
    this.companyGlobalSetting = this.encrDecSrvc.getObject(
      AppConstants.timeAndExpenses
    );
  }

  fetchClients() {
    const list = this.encrDecSrvc.clientList;

    for (let i = 0; i < list.length; i++) {
      const item = list[i];
      if (item["ContactRecord"] != true && item["Inactive"] != true) {
        this.clientList.push(item);
      }
    }
  }

  formateNumber(name) {
    name = name.toString();
    if (name != undefined && name != "") {
      if (name.includes(".")) {
        const index = name.indexOf(".");
        if (name.substr(index + 1).length == 0) {
          name = name + "00";
        } else if (name.substr(index + 1).length == 1) {
          name = name + "0";
        }
      } else {
        name = name + ".00";
      }
      return name;
    }
  }

  calculateTimeAndAmount(data, billingRate) {
    const timeDiff = data.TimeStop.getTime() - data.TimeStart.getTime();
    const startTime = moment(data.TimeStart);
    const endTime = moment(data.TimeStop);
    let hours = endTime.diff(startTime, "hours");
    const minutes = endTime.diff(startTime, "minutes", true);
    let realmin = minutes - 60 * hours;

    if (isNaN(timeDiff))
      return {
        standardAmount: 0,
        finalTotalTime: this.formateNumber(data.TotalTime),
        finalElapsedTime: this.formateNumber(
          Math.round((minutes / 60) * 100) / 100
        ),
      };

    if (timeDiff < 0) {
      return {
        standardAmount: 0,
        finalTotalTime: this.formateNumber(data.TotalTime),
        finalElapsedTime: this.formateNumber(
          Math.round((minutes / 60) * 100) / 100
        ),
      };
    } else {
      data.ElaspedTime = this.formateNumber(
        Math.round((minutes / 60) * 100) / 100
      );

      /* get minutes from global setting */
      let globalMinute = 0;
      if (this.companyGlobalSetting.BillingIncrements) {
        globalMinute = parseInt(
          this.companyGlobalSetting.BillingIncrements.split(" ")[0]
        );
      }
      if (realmin <= globalMinute)
        if (realmin == 0) realmin = <any>"00";
        else realmin = globalMinute;
      else {
        let diffGlobalMin = 0;

        if (globalMinute != 0) {
          diffGlobalMin = Math.floor(realmin / globalMinute);
        }
        if (realmin % globalMinute === 0)
          realmin = diffGlobalMin * globalMinute;
        else realmin = (diffGlobalMin + 1) * globalMinute;
      }

      if (realmin >= 60) {
        hours = hours + Math.floor(realmin / 60);
        realmin = realmin % 60;
        if (realmin < 9) realmin = <any>"0" + realmin;
      }
      data.TotalTime = this.convertHoursToNumericHours(hours + ":" + realmin);

      if (!this.companyGlobalSetting.BillingIncrements) {
        data.TotalTime = data.ElaspedTime;
      }
    }

    if (billingRate) billingRate = billingRate.replace("$", "");

    if (!data.TotalTime) {
      return;
    }

    data.TotalTime = numeral(numeral(data.TotalTime).value()).format("0.00");
    if (
      !data.TotalTime ||
      data.TotalTime.toString().indexOf(".") < 0 ||
      data.TotalTime.toString().split(".").length > 2
    ) {
      data.TotalTime = 0.0;
    }

    data.TotalTime = this.formateNumber(data.TotalTime);

    if (data.TotalTime && billingRate) {
      if (billingRate.replace("$", "") == 0) {
        if (data.MarkSlipsBilled && this.companyGlobalSetting?.isAdminTimeZero)
          data.StandardAmount = "0.00";
        else data.StandardAmount = billingRate;
      } else {
        if (data.isFlatFee) {
        } else {
          if (
            data.MarkSlipsBilled &&
            this.companyGlobalSetting?.isAdminTimeZero
          )
            data.StandardAmount = "0.00";
          else
            data.StandardAmount = numeral(
              numeral(data.TotalTime).value() * numeral(billingRate).value()
            ).format("0,0.00");
        }
      }
    }

    return {
      standardAmount: data.StandardAmount,
      finalTotalTime: data.TotalTime,
      finalElapsedTime: data.ElaspedTime,
    };
  }

  saveAllOpenTimers(tasksList) {
    Swal.fire({
      title: "Confirmation",
      text: this.translate.instant("timer.convert_all_to_time_entry"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: this.translate.instant("Yes_do_it"),
      cancelButtonText: this.translate.instant("no_cancel"),
    }).then((result) => {
      if (result.value) {
        const ddate = (
          moment( )
          .format( "YYYY-MM-DD" )
        );

        const staffID = this.encrDecSrvc.getObject(AppConstants.resourceID);
        this.getCompanyGlobalSetting();
        this.fetchClients();

        tasksList?.forEach((task, index) => {
          // setTimeout(() => {
          this.mangoAPISrvc.showLoader(true);
          this.mangoAPISrvc
            .getBillingRateFromRateTemplate(task.ClientID, task.ServiceCodeID, staffID)
            .subscribe(
              (data: any) => {
                let enteredValue = numeral(data.BillingRate).value();
                enteredValue = enteredValue ? enteredValue : 0;
                const billingRate = numeral(enteredValue).format("0,0.00");

                let totalTime: any = "";
                const clientObj = this.clientList.filter(
                  (client) => client.ClientID === task.ClientID
                )[0];
                const markSlipsBilled = clientObj["MarkSlipsBilled"];

                task["MarkSlipsBilled"] = clientObj["MarkSlipsBilled"];

                if (new Date(task.DisplayTime).toString() == "Invalid Date") {
                  totalTime = moment(task.DisplayTime, "hh:mm:ss");
                } else {
                  const validDate = moment.utc(task.DisplayTime).toDate();
                  const str =
                    validDate.getHours() +
                    ":" +
                    validDate.getMinutes() +
                    ":" +
                    validDate.getSeconds();
                  totalTime = moment(str, "hh:mm:ss");
                }

                task["ElaspedTime"] = task["TotalTime"] =
                  this.convertHoursToNumericHours(
                    `${moment(totalTime).format("hh:mm:ss")}`
                  );
                task["TimeStart"] = moment.utc(task.TimeStart).toDate();
                task["TimeStop"] = moment
                  .utc(task.TimeStart)
                  .add(totalTime.hours(), "h")
                  .add(totalTime.minutes(), "m")
                  .add(totalTime.seconds(), "s")
                  .toDate();

                  let isBillable = false
                  if (clientObj['MarkSlipsBilled'] || !clientObj['Billable'] || !task.isBillable) {
                    isBillable = false;
                  } else {
                    isBillable = task?.ServiceBillable ? task?.ServiceBillable : false;
                  }

                const timerObj = {
                  ...task,
                  CompanyID: clientObj.CompanyID,
                  TimeStart: this.removeOffset(task.TimeStart),
                  ElaspedTime: this.convertHoursToNumericHours(
                    `${moment(totalTime).format("hh:mm:ss")}`
                  ),
                  TimeStop: this.removeOffset(task.TimeStop),
                  StaffID: staffID,

                  Ddate: (
                    ddate
                  ),

                  Approved: this.companyGlobalSetting?.AutoApproveSlips
                    ? true
                    : false,
                  Billable: isBillable,
                  Billed: markSlipsBilled ? true : false,
                  BillingRate: billingRate,
                  isFlatFee: false,
                  Description: task.Memo,
                  BillingPartnerID: clientObj?.BillingPartnerID,
                  OriginatingPartnerID: clientObj?.OriginatingPartnerID,
                  GroupDescriptionID: clientObj?.BillingGroupID,
                  ClientTypeID: clientObj?.ClientTypeID,
                  GroupDescriptionIDArray: clientObj?.GroupDescriptionIDArray?.length > 0 ? clientObj.GroupDescriptionIDArray : null,
                  IsTimeRecord: "T",
                };

                const { standardAmount, finalTotalTime, finalElapsedTime } =
                  this.calculateTimeAndAmount(task, billingRate);

                timerObj.TotalTime = finalTotalTime;
                timerObj.ElaspedTime = finalElapsedTime;
                timerObj.StandardAmount =
                  markSlipsBilled && this.companyGlobalSetting.isAdminTimeZero
                    ? "0.00"
                    : standardAmount;

                if(markSlipsBilled)
                  timerObj.InvoiceDate = (
                    ddate
                  );

                this.mangoAPISrvc.getUserInfo(staffID).subscribe(
                  (dataUserInfo) => {
                    timerObj.StaffDeptID = dataUserInfo.StaffDeptID;
                    timerObj.StaffPayRate = Number(
                      dataUserInfo.AverageCostPerHour
                    );
                    timerObj.StaffCost =
                      Number(timerObj.TotalTime) *
                      Number(timerObj.StaffPayRate);

                    if(clientObj.CompanyMangoLocationID) {
                      this.mangoAPISrvc
                        .getCompanyLocation(clientObj.CompanyMangoLocationID)
                        .subscribe(
                          (dataCompanyLocation: any) => {
                            const retTax = this.calculateSalesTax(
                              timerObj,
                              clientObj,
                              dataCompanyLocation
                            );

                            timerObj.Tax = retTax?.Tax;
                            timerObj.IsTaxable = retTax?.IsTaxable;

                            this.getBudgetYears(timerObj, tasksList);
                          },
                          (error) => {
                            this.getBudgetYears(timerObj, tasksList);
                          }
                        );
                    } else {
                      this.getBudgetYears(timerObj, tasksList);
                    }
                  },
                  (err) => {
                    this.mangoAPISrvc.showLoader(false);
                  }
                );
              },
              (error) => {
                this.mangoAPISrvc.notify(
                  "error",
                  "Error!",
                  AppConstants.fetchErrorMsg
                );
                this.mangoAPISrvc.showLoader(false);
              }
            );
          // }, i * 300)
        });
      }
    });
  }

  getBudgetYears(timeSheetObj, tasksList) {
    this.mangoAPISrvc
      .getBudgetEngagementAndYearOptions(
        timeSheetObj["ClientID"],
        timeSheetObj["ddmProjectHeaderID"] || null,
        this.companyId
      )
      .subscribe(
        (results: any) => {
          if (results?.length > 0 && timeSheetObj["ProjectMasterID"]) {
            results = results.filter(
              (result) => result.ProjectMasterID == timeSheetObj["ProjectMasterID"]
            );
          }
          timeSheetObj["BudgetsID"] = results.find(
            (result) => result.Year == new Date().getFullYear()
          )?.BudgetsID;
          const budgetList = results.map((result) => ({
            label: result.Year?.toString(),
            value: result.BudgetsID,
          }));
          if(!timeSheetObj["BudgetsID"])
            timeSheetObj["BudgetsID"] = budgetList?.length > 0 ? budgetList[0].value : null

          this.createTimeSheet(timeSheetObj, tasksList);
        },
        (err) => {
          this.createTimeSheet(timeSheetObj, tasksList);
        }
      );
  }

  createTimeSheet(timeSheetObj, tasksList) {
    this.stopTimer(timeSheetObj?.TimerID, true);
    this.mangoAPISrvc.createTimeSheet(timeSheetObj).subscribe(
      (successData) => {
        this.mangoAPISrvc.sendBudgetAlert({
          ClientID: timeSheetObj['ClientID'],
          ProjectMasterID: timeSheetObj['ProjectMasterID'],
          CompanyID: this.encrDecSrvc.getObject(AppConstants.companyID),

          Ddate: (
            timeSheetObj.Ddate
          )

        }).subscribe((data) => {}, err => { console.log(err) })

        const timerTask: any = {
          deleted: true,
          TimerID: timeSheetObj?.TimerID
        };
        this.sharedSrvc.timerTask = timerTask;
        if (tasksList.length == 0) {
          this.mangoAPISrvc.showLoader(false);
          const url = this.router.url;
          const urls = [
            "/billing-invoicing/manualInvoice",
            "/client/timeslips",
            "/engagements/UnbilledTime",
            "/time-expense/list",
            "/dashboard",
          ];
          if (urls.includes(url)) {
            this.sharedSrvc.setTimeEntryDialogData(timeSheetObj);
          }
        }
      },
      (err) => {
        this.mangoAPISrvc.showLoader(false);
        console.log(`Error with ${timeSheetObj.id}`);
      }
    );
  }

  calculateSalesTax(data, clientObj, companyObj) {
    // See if Company has Sales Tax Activated
    const lRates = this.companyGlobalSetting.ActivateLaborRates;
    const obj = {
      IsTaxable: false,
      Tax: 0,
    };

    let actualTaxRate = 0;
    if (lRates && lRates == true) {
      // 1st Check - Where to get labor rates by checking SalesTaxLevel Values, FirmRate, CompanyLocationRate, ClientRate
      if (clientObj && clientObj.SalesTaxLevel == "ClientRate") {
        actualTaxRate = clientObj.Tax1ID;
      } else if (
        clientObj &&
        clientObj.SalesTaxLevel == "CompanyLocationRate"
      ) {
        actualTaxRate = companyObj.TaxRateLaborL;
      } else {
        actualTaxRate = this.companyGlobalSetting.LaborRate1;
      }
      obj["Tax"] =
        numeral(data.StandardAmount).value() *
        (numeral(actualTaxRate).value() / 100);
      obj["IsTaxable"] = true;
    }
    return obj;
  }

  convertToTimeEntry(id) {
    this.stopTimer(id);
    const srchTask = this.tasksList.filter((item) => {
      return item.id == id;
    });

    if (srchTask.length == 0) {
      console.log("no task found by id:", id);
      return;
    }

    this.showMenuTime = false;

    setTimeout(() => {
      this.sharedSrvc.openTimerDialog({
        display: true,
        timerTask: srchTask[0],
        convert: true,
      });
    }, 200);
  }

  private getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  private removeOffset(date: Date) {
    const d = new Date();
    const offset = d.getTimezoneOffset();
    const time = date.getTime() - offset * 60 * 1000;
    return new Date(time);
  }

  private addOffset(date: Date) {
    const d = new Date();
    const offset = d.getTimezoneOffset();
    const time = date.getTime() + offset * 60 * 1000;
    return new Date(time);
  }

  get isTimerRunning() {
    let retVal = false;
    this.tasksList.map((task) => {
      if (task.isStarted) {
        retVal = true;
      }
    });
    return retVal;
  }
}