import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccountingRoutingModule } from './accounting-routing.module';
import { CashReceiptsComponent } from './cash-receipts/cash-receipts.component';
import { accountingComponent } from './accounting';
import { MakeDepositsComponent } from './make-deposits/make-deposits.component';
import { AppSharedModule, AppTranslationModule } from '@app/shared/modules';
import { MerchantSetupComponent } from './merchant-setup/merchant-setup.component';
import { STaxComponent } from './s-tax/s-tax.component';
import { StaxViewTransactionHistoryComponent } from './stax-view-transaction-history/stax-view-transaction-history.component';
import { EditDepositComponent } from './edit-deposit/edit-deposit.component';
import { TooltipModule } from 'primeng/tooltip';
import { KeydownCaptureDirective } from './keyboardCapture.directive';

@NgModule({
  declarations: [KeydownCaptureDirective, CashReceiptsComponent, accountingComponent, MerchantSetupComponent,MakeDepositsComponent, STaxComponent, StaxViewTransactionHistoryComponent, EditDepositComponent],
  imports: [
    CommonModule,
    TooltipModule,
    AppSharedModule,
    AccountingRoutingModule,
    AppTranslationModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AccountingModule { }
