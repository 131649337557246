<div class="billrate">
    <div class="layout-content p-pt-0">
        <div class="p-grid">
            <div class="p-col-12">
                <div class="card p-mt-2 p-pt-0 p-mx-3">
                   <div class="card">
                        <div class="p-formgroup-inline p-mt-3 p-mb-3">
                            <div class="p-ml-3 width-20p">
                                <label class="p-d-block label-text">{{'rate.select_rate_template' | translate}}
                                </label>
                                <p-dropdown [options]="rateTemplatesNamesOptions" placeholder="{{ 'rate.select_rate_template' | translate }}" (click)="checkRateTemplateOptions()"
                                    [(ngModel)]="selectedRateTemplate" scrollHeight="400px"  styleClass="width-100p" (ngModelChange)="onRateTemplateChange($event)">
                                </p-dropdown>
                            </div>
                            <div class="p-ml-3 width-20p">
                                <label class="p-d-block label-text">{{'rate.template_type' | translate}}
                                </label>
                                <input type="text" class="p-text-left width-90p" disabled="true" [(ngModel)]="selectedRateTemplateObj['TemplateTypeValue'] " pInputText autocomplete="off" styleClass="">
                            </div>
                            <div class="p-ml-3 p-mt-1 width-8p">
                                <button pButton pRipple label="{{ 'rate.manage' | translate }}" icon="fas fa-check" class="p-button-sm p-mt-3" 
                                    (click)="openRateTemplateNamesPanel()" pTooltip="{{ 'rate.manage_rate_templates' | translate }}" showDelay="800"
                                tooltipPosition="top" tooltipStyleClass="tooltip-width-450"></button>
                            </div>
                            <div class="p-ml-3 p-mt-3 p-pt-1 width-20p">
                                <demo-video videoCode="qu0q504r68"></demo-video>
                            </div>
                        </div>
                        <div *ngIf="selectedRateTemplate && selectedRateTemplateObj['RateTemplateNamesID'] && rateTemplatesNamesOptions.length > 0">
                            <div class="p-formgroup-inline">
                                <div class="p-col-6 manual">
                                    <p-panel header="{{ 'rate.rate_template_details' | translate }} ">
                                        <p-table [value]="rateTemplateDetails" [rowHover]="true" dataKey="RateTemplateDetailsID"
                                            selectionMode="single" [filterDelay]="0" styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                                            [scrollable]="true" [scrollHeight]="'calc(50vh - 100px)'" editMode="row" [globalFilterFields]="['ServiceCode','RateLevelDescription']"
                                            [responsive]="true" [resizableColumns]="true" metaKeySelection="true"  #dtRateTemplateDetail [(selection)]="selectedRateTemplateDetails">
                                            <ng-template pTemplate="caption">
                                                <div class="table-header">
                                                    <span class="p-input-icon-left">
                                                        <i class="fal fa-search"></i>
                                                        <input #rateTemplateDetailSearchValue pInputText type="text"
                                                            (input)="dtRateTemplateDetail.filterGlobal($event.target.value, 'contains')"
                                                            placeholder="{{'globalsearch' | translate}}" />
                                                    </span>
                                                    <span>
                                                        <button pButton pRipple type="button" icon="pi pi-times"
                                                            class="p-button-rounded p-button-danger p-button-text"
                                                            (click)="dtRateTemplateDetail.reset();clearSearchFilter('rate_template_details')" tooltipPosition="top"
                                                            pTooltip="{{'clear' | translate}}"></button>
                                                    </span>
                                                    <span class="pull-right">
                                                        <button *ngIf="rateTemplateDetailsrateLevelsOptions.length > 0" pRipple pButton icon="fal fa-plus" class="p-button-sm" label="{{'Add-Row' | translate}}" (click)="addRateTemplateDetail(dtRateTemplateDetail)">
                                                        </button>
                                                    </span>
                                                    <span class="pull-right p-mr-3">
                                                        <button *ngIf="selectedRateTemplateDetails.length > 0" pRipple pButton icon="fal fa-trash-alt" class="p-button-sm" label="{{'delete_selected' | translate}}" (click)="deleteSelectedRateTemplateDetails()">
                                                        </button>
                                                    </span>
                                                </div>
                                            </ng-template>
                                            <ng-template pTemplate="header" let-columns>
                                                <tr>
                                                    <th class="width-3p p-text-center">
                                                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                                    </th>
                                                    <th class="width-5p p-text-center">
                                                        {{ 'Actions' | translate }}
                                                    </th>
                                                    <th *ngIf="selectedRateTemplateObj['TemplateType'] == 3" class="width-10p p-text-left" pSortableColumn="ServiceCode">
                                                        {{ 'activity' | translate }}
                                                        <p-sortIcon field="ServiceCode"></p-sortIcon>
                                                    </th>
                                                    <th class="width-10p p-text-left required-field" pSortableColumn="RateLevelDescription">
                                                        {{ 'rate.staff_rate_level' | translate }}
                                                        <p-sortIcon field="RateLevelDescription"></p-sortIcon>
                                                    </th>
                                                    <th class="width-5p p-text-right required-field">
                                                        {{ 'rate.hourly_rate' | translate }}
                                                    </th>
                                                    <th class="width-6p p-text-center">
                                                    </th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex" let-editing="editing">
                                                <tr class="cursor-hand" [pEditableRow]="rowData">
                                                    <td class="width-3p p-text-center">
                                                        <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                                                    </td>
                                                    <td class="width-5p p-text-center">
                                                        <span>
                                                            <i class="fal fa-trash-alt ic-red p-mr-3 ic-size-15" aria-hidden="true" pTooltip="Delete" tooltipPosition="top" (click)="deleteRateTemplateDetail(rowData)" showDelay="800"></i>
                                                            <i class="fal fa-plus cursor-hand" (click)="addRateTemplateDetail(dtRateTemplateDetail)"
                                                                pTooltip="{{ 'add_new_row' | translate }}" showDelay="800" id="new-row"
                                                                tooltipPosition="top" *ngIf="((rateTemplateDetails.length-1 == rowIndex && rateTemplateDetailsrateLevelsOptions.length > 0) )"></i>
                                                        </span>
                                                    </td>
                                                    <td *ngIf="selectedRateTemplateObj['TemplateType'] == 3" pInitEditableRow class="width-10p p-text-left" (click)="onRowEditInit(rowData['RateTemplateDetailsID'], dtRateTemplateDetail)">
                                                        <p-cellEditor>
                                                            <ng-template pTemplate="input">
                                                                <p-dropdown styleClass="width-100p" [options]="activityList" placeholder="Select Activity" [(ngModel)]="rowData['ServiceCodeID']"
                                                                    (ngModelChange)="onServiceCodeChange(rowData);rowData['IsServiceCodeChanged'] = true"[ngModelOptions]="{standalone: true}" appendTo="body">
                                                                </p-dropdown>
                                                            </ng-template>
                                                            <ng-template pTemplate="output">
                                                                {{rowData['ServiceCode']}}
                                                            </ng-template>
                                                        </p-cellEditor>
                                                    </td>
                                                    <td pInitEditableRow  class="width-10p p-text-left" (click)="onRowEditInit(rowData['RateTemplateDetailsID'], dtRateTemplateDetail)">
                                                        <p-cellEditor>
                                                            <ng-template pTemplate="input">
                                                                <p-dropdown
                                                                    [disabled]="!rowData['IsNew']"
                                                                    styleClass="width-100p"
                                                                    [options]="rateTemplateDetailsrateLevelsOptions"
                                                                    [(ngModel)]="rowData['RateLevelID']"
                                                                    (ngModelChange)="handleRateLevelChange($event, rowData)"
                                                                    [ngModelOptions]="{standalone: true}"
                                                                    appendTo="body"
                                                                    placeholder="Select Rate Level">
                                                                </p-dropdown>
                                                            </ng-template>
                                                            <ng-template pTemplate="output">
                                                                {{rowData['RateLevelDescription']}}
                                                            </ng-template>
                                                        </p-cellEditor>
                                                    </td>
                                                    <td pInitEditableRow class="width-5p p-text-right" (click)="onRowEditInit(rowData['RateTemplateDetailsID'], dtRateTemplateDetail)">
                                                        <p-cellEditor>
                                                            <ng-template pTemplate="input">
                                                                <input pInputText type="text" pKeyFilter="num" [(ngModel)]="rowData['HourlyRate']" (ngModelChange)="rowData['IsHourlyRateChanged'] = true"
                                                                    [ngModelOptions]="{standalone: true}" class="width-100p  p-text-right">
                                                            </ng-template>
                                                            <ng-template pTemplate="output">
                                                                {{rowData.HourlyRate | currency:'USD':'symbol':'1.2-2'}}
                                                            </ng-template>
                                                        </p-cellEditor>
                                                    </td>
                                                    <td class="width-6p p-text-center">
                                                        <i *ngIf="!editing && (rowData['IsChanged'] || rowData['IsServiceCodeChanged'] || rowData['IsHourlyRateChanged'] || rowData['IsNew'])" class="fal fa-asterisk ic-red p-mr-3"></i>
                                                        <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="saveRateLevelDetail(rowData);" class="p-button-rounded p-button-text p-button-success p-mr-2"></button>
                                                        <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" class="p-button-rounded p-button-text p-button-danger" (click)="cancelRateTemplateDetail(rowData)"></button>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="emptymessage">
                                                <tr>
                                                    <td class="width-100p">
                                                        <p-message severity="info" text="{{'data_not_found' | translate}}."
                                                            styleClass="p-px-2"></p-message>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                    </p-panel>
                                </div>
                                <div class="p-col-6 manual">
                                    <p-panel header="{{ 'rate.staff_rate_level_exceptions' | translate }}">
                                        <p-table [value]="rateLevelExceptions" [rowHover]="true" dataKey="RateLevelExceptionsID"
                                            selectionMode="single" [filterDelay]="0" styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                                            [scrollable]="true" [scrollHeight]="'calc(50vh - 100px)'" #dtRateLevelExceptions editMode="row" [globalFilterFields]="['StaffName','StaffNumber','RateLevelDescription']"
                                            [responsive]="true" [resizableColumns]="true" metaKeySelection="true">
                                            <ng-template pTemplate="caption">
                                                <div class="table-header">
                                                    <span class="p-input-icon-left">
                                                        <i class="fal fa-search"></i>
                                                        <input #rateLevelExceptionsSearchValue pInputText type="text"
                                                            (input)="dtRateLevelExceptions.filterGlobal($event.target.value, 'contains')"
                                                            placeholder="{{'globalsearch' | translate}}" />
                                                    </span>
                                                    <span>
                                                        <button pButton pRipple type="button" icon="pi pi-times"
                                                            class="p-button-rounded p-button-danger p-button-text"
                                                            (click)="dtRateLevelExceptions.reset();clearSearchFilter('rate_level_exceptions')" tooltipPosition="top"
                                                            pTooltip="{{'clear' | translate}}"></button>
                                                    </span>
                                                    <span class="pull-right">
                                                        <button pRipple pButton icon="fal fa-plus" class="p-button-sm" label="{{'Add-Row' | translate}}" 
                                                             (click)="addRateLevelExceptions(dtRateLevelExceptions)" *ngIf="staffListOptions.length > 0">
                                                        </button>
                                                    </span>
                                                </div>
                                            </ng-template>
                                            <ng-template pTemplate="header" let-columns>
                                                <tr>
                                                    <th class="width-5p p-text-center">
                                                        {{ 'Actions' | translate }}
                                                    </th>
                                                    <th class="width-15p p-text-left required-field" pSortableColumn="StaffName">
                                                        {{ 'Staff_Name' | translate }}
                                                        <p-sortIcon field="StaffName"></p-sortIcon>
                                                    </th>
                                                    <th class="width-10p p-text-left" pSortableColumn="StaffNumber">
                                                        {{ 'user.user_initials' | translate }}
                                                        <p-sortIcon field="StaffNumber"></p-sortIcon>
                                                    </th>
                                                    <th class="width-15p p-text-left required-field" pSortableColumn="RateLevelDescription">
                                                        {{ 'rate.staff_rate_level' | translate }}
                                                        <p-sortIcon field="RateLevelDescription"></p-sortIcon>
                                                    </th>
                                                    <th class="width-10p p-text-center">
                                                    </th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex" let-editing="editing">
                                                <tr class="cursor-hand" [pEditableRow]="rowData">
                                                    <td class="width-5p p-text-center">
                                                        <span>
                                                            <i class="fal fa-trash-alt ic-red p-mr-3 ic-size-15" aria-hidden="true" pTooltip="Delete" tooltipPosition="top" (click)="deleteRateLevelExeption(rowData)" showDelay="800"></i>
                                                            <i class="fal fa-plus cursor-hand" (click)="addRateLevelExceptions(dtRateLevelExceptions)"
                                                                pTooltip="{{ 'add_new_row' | translate }}" showDelay="800" id="new-row"
                                                                tooltipPosition="top" *ngIf="((rateLevelExceptions.length-1) == rowIndex && staffListOptions.length > 0)"></i>
                                                        </span>
                                                    </td>
                                                    <td class="width-15p p-text-left" pInitEditableRow (click)="onRowEditInit(rowData['RateLevelExceptionsID'], dtRateLevelExceptions)">
                                                        <p-cellEditor>
                                                            <ng-template pTemplate="input">
                                                                <p-dropdown 
                                                                    styleClass="width-100p" 
                                                                    [options]="staffListOptions" 
                                                                    [(ngModel)]="rowData['StaffID']" 
                                                                    [ngModelOptions]="{standalone: true}" 
                                                                    appendTo="body" 
                                                                    (ngModelChange)="handleStaffChange($event, rowData)"
                                                                    placeholder="Select Staff Name">
                                                                </p-dropdown>
                                                            </ng-template>
                                                            <ng-template pTemplate="output">
                                                                {{rowData['StaffName']}}
                                                            </ng-template>
                                                        </p-cellEditor>
                                                    </td>
                                                    <td class="width-10p p-text-left">
                                                        {{rowData['StaffNumber']}}
                                                    </td>
                                                    <td class="width-15p p-text-left" pInitEditableRow (click)="onRowEditInit(rowData['RateLevelExceptionsID'], dtRateLevelExceptions)">
                                                        <p-cellEditor>
                                                            <ng-template pTemplate="input">
                                                                <p-dropdown styleClass="width-100p" [options]="exceptionsRateLevelsOptions" placeholder="Select Rate Level" [(ngModel)]="rowData['RateLevelID']" 
                                                                    [ngModelOptions]="{standalone: true}" appendTo="body" (ngModelChange)="onRateLevelChange(rowData);rowData['IsChanged'] = true">
                                                                </p-dropdown>
                                                            </ng-template>
                                                            <ng-template pTemplate="output">
                                                                {{rowData['RateLevelDescription']}}
                                                            </ng-template>
                                                        </p-cellEditor>
                                                    </td>
                                                    <td class="width-10p p-text-center">
                                                        <i *ngIf="!editing && (rowData['IsChanged'] || rowData['IsNew'])" class="fal fa-asterisk ic-red p-mr-3"></i>
                                                        <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="saveRateLevelExceptions(rowData);" class="p-button-rounded p-button-text p-button-success p-mr-2"></button>
                                                        <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" class="p-button-rounded p-button-text p-button-danger" (click)="cancelRateLevelExceptions(rowData)"></button>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="emptymessage">
                                                <tr>
                                                    <td class="width-100p">
                                                        <p-message severity="info" text="{{'data_not_found' | translate}}."
                                                            styleClass="p-px-2"></p-message>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                    </p-panel>
                                </div>
                           </div>
                        </div>
                   </div>
                </div>
            </div>
        </div>
    </div>
</div>
<p-sidebar [(visible)]="displayRateTemplateNamesPanel" position="right" (onHide)="cancelRateTemplateNamesPanel()"
        [style]="{'min-width':'90%','min-height':'100%','background':'#8e8f92','overflow': 'auto'}">
        <div class="card" style="min-height: calc(100vh - 34px)">
            <div class="p-col-12 p-d-flex p-jc-between">
                <label class="label-text ic-size-23 p-mb-3">{{'rate.manage' | translate}}</label>
            </div>
            <div class="p-mb-3 p-mt-2">
                <p-tabMenu [model]="items" [activeItem]="activeItem"></p-tabMenu>
            </div>
            <div *ngIf="viewType==0">
                <div class="p-formgroup-inline">
                    <div class="p-col-12 manual">
                        <p-panel header="{{ 'rate.rate_template_names' | translate }} ">
                            <p-table #dtRateTemplateNames [value]="rateTemplatesNames" [rowHover]="true" dataKey="RateTemplateNamesID"
                                selectionMode="single" [filterDelay]="0" styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                                [scrollable]="true" [scrollHeight]="'calc(80vh - 100px)'" editMode="row"
                                [responsive]="true" [resizableColumns]="true" [globalFilterFields]="['TemplateName','TemplateTypeValue']">
                                <ng-template pTemplate="caption">
                                    <div class="table-header">
                                        <span class="p-input-icon-left">
                                            <i class="fal fa-search"></i>
                                            <input #rateTemplateNamesSearchValue pInputText type="text"
                                                (input)="dtRateTemplateNames.filterGlobal($event.target.value, 'contains')"
                                                placeholder="{{'globalsearch' | translate}}" />
                                        </span>
                                        <span>
                                            <button pButton pRipple type="button" icon="pi pi-times"
                                                class="p-button-rounded p-button-danger p-button-text"
                                                (click)="dtRateTemplateNames.reset();clearSearchFilter('rate_template_names')" tooltipPosition="top"
                                                pTooltip="{{'clear' | translate}}"></button>
                                        </span>
                                        <span class="pull-right p-mr-3">
                                            <button pRipple pButton icon="fal fa-plus" class="p-button-sm" label="{{'Add-Row' | translate}}" (click)="addRateTemplateName(dtRateTemplateNames)">
                                            </button>
                                        </span>
                                        <span class="p-px-6 p-pt-2 p-d-inline-block pull-right">
                                            <p-checkbox [(ngModel)]="showInactive" (ngModelChange)="onShowInactiveChange(true)" label="{{'show_inactive' | translate}}" binary="true">
                                            </p-checkbox>
                                        </span>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header" let-columns>
                                    <tr>
                                        <th class="width-5p p-text-center">
                                            {{ 'actions' | translate }}
                                        </th>
                                        <th class="width-15p p-text-left required-field" pSortableColumn="TemplateName">
                                            {{ 'dm.Template_Name' | translate }}
                                            <p-sortIcon field="TemplateName"></p-sortIcon>
                                        </th>
                                        <th class="width-25p p-text-left required-field">
                                            {{ 'rate.template_type' | translate }}
                                        </th>
                                        <th class="width-5p p-text-center">
                                            {{ 'inactive' | translate }}
                                        </th>
                                        <th class="width-10p p-text-right"></th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex" let-editing="editing">
                                    <tr class="cursor-hand" [pEditableRow]="rowData">
                                        <td class="width-5p p-text-center">
                                            <span>
                                                <i class="fal fa-trash-alt ic-red p-mr-3 ic-size-15" aria-hidden="true" pTooltip="Delete" tooltipPosition="top" (click)="deleteRateTemplateName(rowData)" showDelay="800"></i>
                                                <i class="fal fa-plus cursor-hand" (click)="addRateTemplateName(dtRateTemplateNames)"
                                                    pTooltip="{{ 'add_new_row' | translate }}" showDelay="800" id="new-row1"
                                                    tooltipPosition="top" *ngIf="((dtRateTemplateNames._value.length-1) == rowIndex)"></i>
                                            </span>
                                        </td>
                                        <td pInitEditableRow class="width-15p p-text-left" (click)="onRowEditInit(rowData['RateTemplateNamesID'], dtRateTemplateNames)" [ngClass]="{'new-row1': (dtRateTemplateNames._value.length - 1 == rowIndex)}">
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input pInputText type="text" [(ngModel)]="rowData['TemplateName']" (ngModelChange)="rowData['IsChanged'] = true"
                                                        [ngModelOptions]="{standalone: true}">
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{rowData['TemplateName']}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td pInitEditableRow class="width-25p p-text-left" (click)="onRowEditInit(rowData['RateTemplateNamesID'], dtRateTemplateNames)">
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <p-dropdown [options]="templateTypeOptions" [disabled]="!rowData['IsNew']" placeholder="{{ 'rate.select_template_type' | translate }}" [(ngModel)]="rowData['TemplateType']" [ngModelOptions]="{standalone: true}" appendTo="body" (ngModelChange)="onTemplateTypeChange(rowData);rowData['IsChanged'] = true">
                                                    </p-dropdown>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{rowData['TemplateTypeValue']}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td pInitEditableRow class="width-5p p-text-center" (click)="onRowEditInit(rowData['RateTemplateNamesID'],dtRateTemplateNames)">
                                            <p-checkbox [(ngModel)]="rowData['Inactive']" binary="true" (ngModelChange)="rowData['IsChanged'] = true;onInactiveChange(rowData, dtRateTemplateNames);"></p-checkbox>
                                        </td>
                                        <td class="width-10p p-text-center">
                                            <i *ngIf="!editing && (rowData['IsChanged'] || rowData['IsNew'])" class="fal fa-asterisk ic-red p-mr-3"></i>
                                            <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check"
                                                (click)="saveRateTemplate(rowData);"
                                                class="p-button-rounded p-button-text p-button-success p-mr-2"></button>
                                            <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" 
                                                (click)="cancelRateTemplateName(rowData)" class="p-button-rounded p-button-text p-button-danger"></button>
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td class="width-100p">
                                            <p-message severity="info" text="{{'data_not_found' | translate}}."
                                                styleClass="p-px-2"></p-message>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </p-panel>
                    </div>
                </div>
            </div>
            <div *ngIf="viewType==1">
                <div class="p-formgroup-inline">
                    <div class="p-col-5 manual">
                        <p-panel header="{{ 'rate.rate_levels' | translate }}"> 
                            <p-table [value]="rateLevels" [rowHover]="true" dataKey="RateLevelID"
                                selectionMode="single" [filterDelay]="0" styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                                [scrollable]="true" [scrollHeight]="'calc(80vh - 100px)'" #dtRateLevels editMode="row" [globalFilterFields]="['RateLevelDescription']"
                                [responsive]="true" [resizableColumns]="true" metaKeySelection="true" [(selection)]="selectedRateLevel">
                                <ng-template pTemplate="caption">
                                    <div class="table-header">
                                        <span class="p-input-icon-left">
                                            <i class="fal fa-search"></i>
                                            <input #rateLevelsSearchValue pInputText type="text"
                                                (input)="dtRateLevels.filterGlobal($event.target.value, 'contains')"
                                                placeholder="{{'globalsearch' | translate}}" />
                                        </span>
                                        <span>
                                            <button pButton pRipple type="button" icon="pi pi-times"
                                                class="p-button-rounded p-button-danger p-button-text"
                                                (click)="dtRateLevels.reset();clearSearchFilter('rate_levels')" tooltipPosition="top"
                                                pTooltip="{{'clear' | translate}}"></button>
                                        </span>
                                        <span class="pull-right">
                                            <button pRipple pButton icon="fal fa-plus" class="p-button-sm" label="{{'Add-Row' | translate}}" (click)="addRateLevel(dtRateLevels)">
                                            </button>
                                        </span>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header" let-columns>
                                    <tr>
                                        <th class="width-3p p-text-left"></th>
                                        <th class="width-5p p-text-center">
                                            {{ 'actions' | translate}}
                                        </th>
                                        <th class="width-15p p-text-left required-field" pSortableColumn="RateLevelDescription">
                                            {{ 'description' | translate }}
                                            <p-sortIcon field="RateLevelDescription"></p-sortIcon>
                                        </th>
                                        <th class="width-6p p-text-center">
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex" let-editing="editing">
                                    <tr class="cursor-hand" [pEditableRow]="rowData">
                                        <td class="width-3p p-text-center">
                                            <p-tableRadioButton [value]="rowData"></p-tableRadioButton>
                                        </td>
                                        <td class="width-5p p-text-center">
                                            <span>
                                                <i class="fal fa-trash-alt ic-red p-mr-3 ic-size-15" aria-hidden="true" pTooltip="Delete" tooltipPosition="top" (click)="deleteRateLevel(rowData)" showDelay="800"></i>
                                                <i class="fal fa-plus cursor-hand" (click)="addRateLevel(dtRateLevels)" pTooltip="{{ 'add_new_row' | translate }}" showDelay="800" id="new-row2"
                                                    tooltipPosition="top" *ngIf="((rateLevels.length-1) == rowIndex)"></i>
                                            </span>
                                        </td>
                                        <td pInitEditableRow class="width-15p p-text-left" (click)="onRowEditInit(rowData['RateLevelID'], dtRateLevels)" [ngClass]="{'new-row2': (rateLevels.length - 1 == rowIndex)}">
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input pInputText type="text" [(ngModel)]="rowData['RateLevelDescription']" (ngModelChange)="rowData['IsChanged'] = true"
                                                        [ngModelOptions]="{standalone: true}" class="width-100p">
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{rowData['RateLevelDescription']}} 
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td class="width-6p p-text-center">
                                            <i *ngIf="!editing && (rowData['IsChanged'] || rowData['IsNew'])" class="fal fa-asterisk ic-red p-mr-3"></i>
                                            <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="saveRateLevel(rowData);" class="p-button-rounded p-button-text p-button-success p-mr-2"></button>
                                            <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" class="p-button-rounded p-button-text p-button-danger" (click)="cancelRateLevel(rowData)"></button>
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td class="width-100p">
                                            <p-message severity="info" text="{{'data_not_found' | translate}}."
                                                styleClass="p-px-2"></p-message>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </p-panel>
                    </div>
                    <div class="p-col-7 manual">
                        <p-panel header="{{ 'rate.assign_rate_levels_to_staffs' | translate }}">
                            <p-table [value]="staffsRateLevels" [rowHover]="true" dataKey="StaffID"
                                selectionMode="single" [filterDelay]="0" styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                                [scrollable]="true" [scrollHeight]="'calc(80vh - 100px)'" #dtStaffsRateLevels editMode="row" [globalFilterFields]="['StaffName', 'StaffNumber','RateLevelDescription','DeptDescription']"
                                [responsive]="true" [resizableColumns]="true" metaKeySelection="true" [(selection)]="selectedStaffRateLevels">
                                <ng-template pTemplate="caption">
                                    <div class="table-header">
                                        <span class="p-input-icon-left">
                                            <i class="fal fa-search"></i>
                                            <input #staffsRateLevelsSearchValue pInputText type="text"
                                                (input)="dtStaffsRateLevels.filterGlobal($event.target.value, 'contains')"
                                                placeholder="{{'globalsearch' | translate}}" />
                                        </span>
                                        <span>
                                            <button pButton pRipple type="button" icon="pi pi-times"
                                                class="p-button-rounded p-button-danger p-button-text"
                                                (click)="dtStaffsRateLevels.reset();clearSearchFilter('staffs_rate_levels')" tooltipPosition="top"
                                                pTooltip="{{'clear' | translate}}"></button>
                                        </span>
                                        <span class="pull-right">
                                            <span *ngIf="selectedStaffRateLevels.length > 0">
                                                <label class="label-text p-mr-2 p-mb-2" style="display: inline-block;">{{'pm.batch_options' | translate }}</label>
                                                <button type="button" class="p-mr-4" pButton pRipple icon="pi pi-chevron-down"
                                                    (click)="menu.toggle($event)"></button>
                                            </span>
                                            <p-menu appendTo="body" #menu [popup]="true" styleClass="button-list-250" [model]="batchOptionsButtons1"></p-menu>
                                        </span>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header" let-columns>
                                    <tr>
                                        <th class="width-5p p-text-center">
                                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                        </th>
                                        <th class="width-10p p-text-left" pSortableColumn="StaffName">
                                            {{ 'Staff_Name' | translate }}
                                            <p-sortIcon field="StaffName"></p-sortIcon>
                                        </th>
                                        <th class="width-7p p-text-left" pSortableColumn="StaffNumber">
                                            {{ 'user.user_initials' | translate}}
                                            <p-sortIcon field="StaffNumber"></p-sortIcon>
                                        </th>
                                        <th class="width-15p p-text-left" pSortableColumn="RateLevelDescription">
                                            {{ 'rate.staff_rate_level' | translate }}
                                            <p-sortIcon field="RateLevelDescription"></p-sortIcon>
                                        </th>
                                        <th class="width-10p p-text-left" pSortableColumn="DeptDescription">
                                            {{ 'department' | translate }}
                                            <p-sortIcon field="DeptDescription"></p-sortIcon>
                                        </th>
                                        <th class="width-4p p-text-center">
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex" let-editing="editing">
                                    <tr class="cursor-hand" [pEditableRow]="rowData">
                                        <td class="width-5p p-text-center">
                                            <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                                        </td>
                                        <td class="width-10p p-text-left">
                                            {{rowData['StaffName']}}
                                        </td>
                                        <td class="width-7p p-text-left">
                                            {{rowData['StaffNumber']}}
                                        </td>
                                        <td class="width-15p p-text-left" [ngClass]="(rowData['RateLevelID'] == null) ? 'ic-green' :'' " pInitEditableRow 
                                            (click)="onRowEditInit(rowData['StaffID'], dtStaffsRateLevels, rowData, rowIndex, 'rate-levels')">
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <p-dropdown [options]="staffRateLevelsOptions" placeholder="Select Rate Level" [(ngModel)]="rowData['RateLevelID']" class="rate-levels-{{rowIndex}}"
                                                        [ngModelOptions]="{standalone: true}" appendTo="body" 
                                                        (ngModelChange)="onRateLevelChange(rowData);rowData['IsChanged'] = true;saveStaffRateLevel(rowData, dtStaffsRateLevels)">
                                                    </p-dropdown>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{rowData['RateLevelDescription']}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td class="width-10p p-text-left">
                                            {{rowData['DeptDescription']}}
                                        </td>
                                        <td class="width-4p p-text-center">
                                            <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" class="p-button-rounded p-button-text p-button-danger"></button>
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td class="width-100p">
                                            <p-message severity="info" text="{{'data_not_found' | translate}}."
                                                styleClass="p-px-2"></p-message>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </p-panel>
                    </div>
               </div>
            </div>
            <div *ngIf="viewType==2">
                <div class="p-col-12 manual">
                    <p-panel header="{{ 'rate.client_rate_templates' | translate }}">
                        <p-table [columns]="selectedColumns1" [value]="clientRateTemplates" [rowHover]="true" dataKey="ClientID"
                            selectionMode="single" [filterDelay]="0" styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                            [scrollable]="true" [scrollHeight]="'calc(80vh - 100px)'" #dtClients editMode="row" [globalFilterFields]="clientRateTemplatesGlobalFilter"
                            [responsive]="true" [resizableColumns]="true" metaKeySelection="true" [(selection)]="selectedClientRateTemplates" (onHeaderCheckboxToggle)="toggleClientHeaderCheckbox()">
                            <ng-template pTemplate="caption">
                                <div class="table-header">
                                    <span class="p-input-icon-left">
                                        <i class="fal fa-search"></i>
                                        <input #searchValue pInputText type="text"
                                            (input)="dtClients.filterGlobal($event.target.value, 'contains')"
                                            placeholder="{{'globalsearch' | translate}}" />
                                    </span>
                                    <span>
                                        <button pButton pRipple type="button" icon="pi pi-times"
                                            class="p-button-rounded p-button-danger p-button-text"
                                            (click)="dtClients.reset();clearSearchFilter('clients')" tooltipPosition="top"
                                            pTooltip="{{'clear' | translate}}"></button>
                                    </span>

                                    <!-- dropdown -->
                                    <span class="pull-right">
                                        <p-multiSelect [options]="cols1" [(ngModel)]="selectedColumns1" optionLabel="header"
                                            selectedItemsLabel="{0} columns selected" [style]="{minWidth: '200px', width: '100px'}"
                                            placeholder="Choose Columns"></p-multiSelect>
                                    </span>
                                    <span class="pull-right p-mr-3">
                                        <button pRipple pButton icon="pi pi-check" [disabled]="selectedClientRateTemplates.length == 0" class="p-button-m" label="{{'Accounts.apply' | translate}}" (click)="bulkUpdateClientRateTemplates()">
                                        </button>
                                    </span>
                                    <span class="pull-right p-mr-3 width-20p">
                                        <p-dropdown [options]="clientRateTemplatesNamesOptions" placeholder="{{ 'rate.select_rate_template' | translate }}"
                                            scrollHeight="400px"  styleClass="width-100p"  [(ngModel)]="clientSelectedRateTemplate">
                                        </p-dropdown>
                                    </span>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header" let-columns>
                                <tr>
                                    <th class="width-5p p-text-center">
                                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                    </th>
                                    <th class="width-25p p-text-left" pSortableColumn="ClientName">
                                        {{ 'client.name' | translate }}
                                        <p-sortIcon field="ClientName"></p-sortIcon>
                                    </th>
                                    <th class="width-20p p-text-left" pSortableColumn="TemplateName">
                                        {{ 'rate.rate_template' | translate }}
                                        <p-sortIcon field="TemplateName"></p-sortIcon>
                                    </th>
                                    <!-- <th class="width-10p p-text-left" pSortableColumn="CustomerTypeDescription">
                                        {{ 'client.type' | translate }}
                                        <p-sortIcon field="ContactType"></p-sortIcon>
                                    </th>
                                    <th class="width-10p p-text-left" pSortableColumn="GroupDescriptionIDArrayDesc">
                                        {{ 'Client_Group' | translate }}
                                        <p-sortIcon field="GroupDescriptionIDArrayDesc"></p-sortIcon>
                                    </th>
                                    <th class="width-10p p-text-left" pSortableColumn="BillingPartner">
                                        {{ 'client.billing_partner' | translate }}
                                        <p-sortIcon field="BillingPartner"></p-sortIcon>
                                    </th>
                                    <th class="width-7p p-text-left" pSortableColumn="BusZip">
                                        {{ 'client.business_zip' | translate }}
                                        <p-sortIcon field="BusZip"></p-sortIcon>
                                    </th> -->
                                    <th *ngFor="let col of columns" class="{{col.rowClass}}" [pSortableColumn]="col.field">
                                        {{col.header}}
                                        <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
                                    </th>
                                    <th class="width-4p p-text-center">
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex" let-editing="editing">
                                <tr class="cursor-hand" [pEditableRow]="rowData">
                                    <td class="width-5p p-text-center">
                                        <p-tableCheckbox [value]="rowData" (click)="toggleClientCheckbox()"></p-tableCheckbox>
                                    </td>
                                    <td class="width-25p p-text-left">
                                        {{rowData['ClientName']}}
                                    </td>
                                    <td class="width-20p p-text-left" pInitEditableRow [ngClass]="(rowData['RateTemplateNamesID']) == null?'ic-green':''"
                                        (click)="onRowEditInit(rowData['ClientID'], dtClients, rowData, rowIndex, 'client-rate-templates')">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <p-dropdown [options]="clientRateTemplatesNamesOptions" placeholder="Select Template Type"
                                                    class="client-rate-templates-{{rowIndex}}" [(ngModel)]="rowData['RateTemplateNamesID']"
                                                    [ngModelOptions]="{standalone: true}" appendTo="body" (onChange)="onRateTemplateNamesChangeClient($event,rowData, dtClients, true);">
                                                </p-dropdown>
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                {{rowData['TemplateName']}}
                                            </ng-template>
                                        </p-cellEditor>
                                    </td>
                                    <td *ngFor="let col of columns" class="p-text-truncate" pTooltip="{{rowData[col.field]}}" showDelay="800" class="{{col.rowClass}}"
                                        tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top">
                                        {{ rowData[col.field] }}
                                    </td>
                                    <!-- <td class="width-10p p-text-left">
                                        {{rowData['CustomerTypeDescription']}}
                                    </td>
                                    <td class="width-10p p-text-left">
                                        {{rowData['GroupDescriptionIDArrayDesc']}}
                                    </td>
                                    <td class="width-10p p-text-left">
                                        {{rowData['BillingPartner']}}
                                    </td>
                                    <td class="width-7p p-text-left">
                                        {{rowData['BusZip']}}
                                    </td> -->
                                    <td class="width-4p p-text-center">
                                        <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" class="p-button-rounded p-button-text p-button-danger"></button>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td class="width-100p">
                                        <p-message severity="info" text="{{'data_not_found' | translate}}."
                                            styleClass="p-px-2"></p-message>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </p-panel>
                </div>
            </div>
            <div *ngIf="viewType==3">
                <div class="p-col-12 manual">
                    <p-panel header="{{ 'Activity Rate Templates' | translate }}">
                        <p-table [value]="activityRateTemplates" [rowHover]="true" dataKey="ServiceCodeID"
                            selectionMode="single" [filterDelay]="0" styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                            [scrollable]="true" [scrollHeight]="'calc(80vh - 100px)'" #dtActivity editMode="row" 
                            [globalFilterFields]="['ServiceCode', 'Description','TemplateName']"
                            [responsive]="true" [resizableColumns]="true" metaKeySelection="true" [(selection)]="selectedActivityRateTemplates">
                            <ng-template pTemplate="caption">
                                <div class="table-header">
                                    <span class="p-input-icon-left">
                                        <i class="fal fa-search"></i>
                                        <input #activitySearchValue pInputText type="text"
                                            (input)="dtActivity.filterGlobal($event.target.value, 'contains')"
                                            placeholder="{{'globalsearch' | translate}}" />
                                    </span>
                                    <span>
                                        <button pButton pRipple type="button" icon="pi pi-times"
                                            class="p-button-rounded p-button-danger p-button-text"
                                            (click)="dtActivity.reset();clearSearchFilter('activities')" tooltipPosition="top"
                                            pTooltip="{{'clear' | translate}}"></button>
                                    </span>
                                    <span class="pull-right">
                                        <button pRipple pButton icon="pi pi-check" class="p-button-m" [disabled]="selectedActivityRateTemplates.length == 0" label="{{'Accounts.apply' | translate}}" (click)="bulkUpdateActivityRateTemplates()">
                                        </button>
                                    </span>
                                    <span class="pull-right p-mr-3 width-20p">
                                        <p-dropdown [options]="activityRateTemplatesNamesOptions" placeholder="{{ 'rate.select_rate_template' | translate }}"
                                            scrollHeight="400px"  styleClass="width-100p"  [(ngModel)]="activitySelectedRateTemplate">
                                        </p-dropdown>
                                    </span>
                                    <!-- <span class="p-pt-2 p-d-inline-block pull-right p-mr-3">
                                        <p-checkbox label="{{'selected_only' | translate}}" binary="true" [(ngModel)]="showSelectedTemplate" 
                                            (ngModelChange)="toggleShowSelectedTemplate('activity')" tooltipPosition="top" pTooltip="{{'rate.show_selected_template' | translate}}">
                                        </p-checkbox>
                                    </span> -->
                                    <!-- <span *ngIf="selectedActivityRateTemplates.length > 0" class="pull-right p-mr-3">
                                        <label class="label-text p-mr-2 p-mb-2" style="display: inline-block;">{{'pm.batch_options' | translate }}</label>
                                        <button type="button" pButton pRipple icon="pi pi-chevron-down"
                                            (click)="menu.toggle($event)"></button>
                                    </span>
                                    <p-menu appendTo="body" #menu [popup]="true" styleClass="button-list-250" [model]="batchOptionsButtons3"></p-menu> -->
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header" let-columns>
                                <tr>
                                    <th class="width-5p p-text-center">
                                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                    </th>
                                    <th class="width-10p" pSortableColumn="ServiceCode">
                                        {{'activity-code' | translate}}
                                        <p-sortIcon field="ServiceCode"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="Description" class="width-25p">
                                        {{'description' | translate}}
                                        <p-sortIcon field="Description"></p-sortIcon>
                                    </th>
                                    <th class="width-20p p-text-left" pSortableColumn="TemplateName">
                                        {{ 'rate.rate_template' | translate }}
                                        <p-sortIcon field="TemplateName"></p-sortIcon>
                                    </th>
                                    <th class="width-4p p-text-center">
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex" let-editing="editing">
                                <tr class="cursor-hand" [pEditableRow]="rowData">
                                    <td class="width-5p p-text-center">
                                        <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                                    </td>
                                    <td class="width-10p p-text-left">
                                        {{rowData['ServiceCode']}}
                                    </td>
                                    <td class="width-25p p-text-left">
                                        {{rowData['Description']}}
                                    </td>
                                    <td class="width-20p p-text-left" pInitEditableRow [ngClass]="(rowData['RateTemplateNamesID']) == null?'ic-green':''"
                                        (click)="onRowEditInit(rowData['ServiceCodeID'], dtActivity, rowData, rowIndex, 'activity-rate-templates')">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <p-dropdown [options]="activityRateTemplatesNamesOptions" placeholder="Select Template Type"
                                                    class="activity-rate-templates-{{rowIndex}}" [(ngModel)]="rowData['RateTemplateNamesID']"
                                                    [ngModelOptions]="{standalone: true}" appendTo="body" 
                                                    (onChange)="onRateTemplateNamesChangeActivity($event,rowData, dtActivity, true);rowData['IsChanged'] = true;saveActivityRateTemplate(rowData, dtActivity)">
                                                </p-dropdown>
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                {{rowData['TemplateName']}}
                                            </ng-template>
                                        </p-cellEditor>
                                    </td>
                                    <td class="width-4p p-text-center">
                                        <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" class="p-button-rounded p-button-text p-button-danger"></button>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td class="width-100p">
                                        <p-message severity="info" text="{{'data_not_found' | translate}}."
                                            styleClass="p-px-2"></p-message>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </p-panel>
                </div>
            </div>
        </div>
    </p-sidebar>