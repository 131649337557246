<div>
    <div class="p-col-12 p-ml-2 p-mb-1 p-formgroup-inline" *ngIf="!(sharedSrvc.isTableView || clientID === -1)">
        <p-autoComplete [(ngModel)]="sharedSrvc.selClient" [ngModelOptions]="{standalone: true}" #clientAutoComplete
            inputStyleClass="client-autocomplete-width" panelStyleClass="client-dropdown-autocomplete" autocomplete="off"
            [suggestions]="filteredClients" (completeMethod)="filterClients($event)"
            field="ClientName" ngDefaultControl (onSelect)="redirectToClientView(sharedSrvc.selClient.ClientID, sharedSrvc.selClient);"
            placeholder="Search Clients" (onFocus)="$event.target.select()"
            [dropdown]="true" required (onKeyUp)="handleClientKeyPress($event)">
            <ng-template let-client pTemplate="item">
                <div>{{client.ClientName}}{{ client.ClientNumber ? ' - ' + client.ClientNumber : ''}}</div>
            </ng-template>
        </p-autoComplete>
        <div class="p-col-1">
            <p-checkbox binary="true" label="Include All" [(ngModel)]="isIncludeAll" (ngModelChange)="onIncludeAllChange()"></p-checkbox>
        </div>
        <div class="p-ml-2" style="display: flex;align-items: center;" *ngIf="isClientListDropdownLoading">
            <p-progressSpinner [style]="{width: '25px', height: '25px'}" styleClass="custom-spinner" strokeWidth="8" animationDuration=".5s"></p-progressSpinner>
            <label style="font: smaller;font-weight: 200; color: #87888a;">Loading clients...</label>
        </div>
    </div>
    <p-tabMenu *ngIf="!isClientList" [model]="items" [activeItem]="selectedMenu"></p-tabMenu>
    <router-outlet></router-outlet>
</div>