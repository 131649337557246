<p-sidebar
  [(visible)]="isPreviewVisible "
  [closeOnEscape]="false "
  [dismissible]="false "
  [showCloseIcon]="false"
  [fullScreen]="true "
  [style]="{ 'background': '#8e8f92'} ">
  <div class="card">
    <div class="pull-right p-pr-4">
      <button
        type="button"
        pButton
        pRipple
        icon="fal fa-times p-mr-2"
        label="{{ 'close' | translate }}"
        (click)="closeDialog()"></button>
    </div>
    <div>
      <p-tabView>
        <p-tabPanel
          *ngIf="dialogTab1Display"
          header="{{'Narrative-Invoices' | translate}}"
          [selected]="dialogTab1Display">
          <div>
            <ngx-extended-pdf-viewer
              *ngIf="dialogTab1Display"
              [(src)]="pdfUrl"
              id="Client Invoices 1"
              type="application/pdf"
              [height]="'85vh'"
              [useBrowserLocale]="true"
              [printResolution]="300"
              [showSecondaryToolbarButton]="false"
              [showBookmarkButton]="false"
              [showBorders]="true"
              [showPresentationModeButton]="false"
              [showRotateButton]="false"
              [showSidebarButton]="false"
              [showOpenFileButton]="false"
              [zoom]="125">
            </ngx-extended-pdf-viewer>
          </div>
        </p-tabPanel>
        <p-tabPanel
          *ngIf="dialogTab2Display"
          header="{{'Detail-Invoices' | translate}}"
          [selected]="dialogTab2Display">
          <div>
            <ngx-extended-pdf-viewer
              *ngIf="dialogTab2Display"
              [(src)]="pdfUrl"
              id="Client Invoices 2"
              type="application/pdf"
              [height]="'85vh'"
              [useBrowserLocale]="true"
              [printResolution]="300"
              [showSecondaryToolbarButton]="false"
              [showBookmarkButton]="false"
              [showBorders]="true"
              [showPresentationModeButton]="false"
              [showRotateButton]="false"
              [showSidebarButton]="false"
              [showOpenFileButton]="false"
              [zoom]="125">
            </ngx-extended-pdf-viewer>
          </div>
        </p-tabPanel>
        <p-tabPanel
          *ngIf="dialogTab3Display"
          header="{{'Simple-Narrative-Invoices' | translate}}"
          [selected]="dialogTab3Display">
          <div>
            <ngx-extended-pdf-viewer
              *ngIf="dialogTab3Display"
              [(src)]="pdfUrl"
              id="Client Invoices 3"
              type="application/pdf"
              [height]="'85vh'"
              [useBrowserLocale]="true"
              [printResolution]="300"
              [showSecondaryToolbarButton]="false"
              [showBookmarkButton]="false"
              [showBorders]="true"
              [showPresentationModeButton]="false"
              [showRotateButton]="false"
              [showSidebarButton]="false"
              [showOpenFileButton]="false"
              [zoom]="125">
            </ngx-extended-pdf-viewer>
          </div>
        </p-tabPanel>
        <p-tabPanel
          *ngIf="dialogTab4Display"
          header="{{'Narrative-Summary-Invoices' | translate}}"
          [selected]="dialogTab4Display">
          <div>
            <ngx-extended-pdf-viewer
              *ngIf="dialogTab4Display"
              [(src)]="pdfUrl"
              id="Client Invoices 4"
              type="application/pdf"
              [height]="'85vh'"
              [useBrowserLocale]="true"
              [printResolution]="300"
              [showSecondaryToolbarButton]="false"
              [showBookmarkButton]="false"
              [showBorders]="true"
              [showPresentationModeButton]="false"
              [showRotateButton]="false"
              [showSidebarButton]="false"
              [showOpenFileButton]="false"
              [zoom]="125">
            </ngx-extended-pdf-viewer>
          </div>
        </p-tabPanel>
        <p-tabPanel
          *ngIf="dialogTab5Display"
          header="{{'SimpleNoRemit' | translate}}"
          [selected]="dialogTab5Display">
          <div>
            <ngx-extended-pdf-viewer
              *ngIf="dialogTab5Display"
              [(src)]="pdfUrl"
              id="Client Invoices 5"
              type="application/pdf"
              [height]="'85vh'"
              [useBrowserLocale]="true"
              [printResolution]="300"
              [showSecondaryToolbarButton]="false"
              [showBookmarkButton]="false"
              [showBorders]="true"
              [showPresentationModeButton]="false"
              [showRotateButton]="false"
              [showSidebarButton]="false"
              [showOpenFileButton]="false"
              [zoom]="125">
            </ngx-extended-pdf-viewer>
          </div>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
</p-sidebar>

<div class="time-entry-list">
  <span class="pull-right p-mr-3 clientform-btn-top">
    <button
      (click)="addRecurringInvoiceHeader(dt)"
      type="button"
      pButton
      pRipple
      pTooltip="Update"
      label="{{ 'Add Recurring Invoice' | translate }}"
      icon="fal fa-plus"
      class="p-button-sm p-mr-2"></button>
    <button
      pButton
      pRipple
      icon="fal fa-file-text"
      [disabled]="(isProcessDisabled)"
      (click)="processInvoice()"
      class="p-button-sm p-mr-2"
      label="{{'process-invoices' | translate}}"></button>
    <button
      pButton
      pRipple
      icon="fal fa-paperclip"
      [disabled]="(isProcessDisabled)"
      class="p-button-sm p-mr-2"
      label="{{'attach-invoice-memo' | translate}}"
      (click)="attachInvoiceMemo();"></button>
    <button
      pButton
      pRipple
      label="{{'filters' | translate}}"
      icon="fal fa-filter"
      class="p-button-sm p-px-2 p-mr-2"
      (click)="showFilterSideBar = true;"></button>
  </span>

  <div class="layout-content p-pt-0">
    <div class="p-mx-2">
      <div class="p-col-12 p-py-0">
        <div class="card p-py-3">
          <div class="p-formgroup-inline">
            <div class="p-field p-mb-0 p-p-2">
              <label for="View" class="p-mr-3 label-text">
                {{ 'Accounts.invoice-date' | translate }}
              </label>
              <p-calendar
                name="Ddate"
                yearRange="2000:2030"
                showButtonBar="true"
                [monthNavigator]="true"
                [inputStyle]="{'width':'100px'}"
                [yearNavigator]="true"
                [(ngModel)]="invoiceDate"
                (onSelect)="
                              onDateSelect( $event, 'invoiceDate' );
                              verifySystemLocking( $event );
                              validateInvoiceDate( );
                            ">
              </p-calendar>
            </div>
            <div class="p-field p-ml-3 p-mb-0 p-p-2">
              <label for="For" class="p-mr-3 label-text">{{'last-invoice' | translate}}</label>
              <input
                type="text"
                class="p-text-right"
                disabled
                pInputText
                [(ngModel)]="lastInvoice"
                name="lastinvoice"
                id="lastinvoice"
                readonly />
            </div>
            <div class="p-field p-ml-3 p-mb-0 p-p-2">
              <label for="For" class="label-text p-mt-2">{{'Month' | translate}}</label>
              <i
                class="fa fa-question-circle ic-blue p-mr-3"
                style="margin-left: -2px"
                showDelay="800"
                tooltipStyleClass="tooltip-width-300"
                pTooltip="{{'selectedMonth_tooltip' | translate}}"
                tooltipPosition="top"></i>
              <p-dropdown
                class="p-mr-2 pull-right month-dropdown"
                [ngModelOptions]="{ standalone: true }"
                [style]="{'min-width':'120px'}"
                [autoWidth]="false"
                [options]="months"
                [(ngModel)]="currentMonth"
                [scrollHeight]="'340px !important'"></p-dropdown>
            </div>
            <div class="p-field p-ml-3 p-mb-0 p-p-2">
              <label for="For" class="label-text p-mt-2">{{'Quarter' | translate}}</label>
              <i
                class="fa fa-question-circle ic-blue p-mr-3"
                style="margin-left: -2px"
                showDelay="800"
                tooltipStyleClass="tooltip-width-300"
                pTooltip="{{'selectedQuarter_tooltip' | translate}}"
                tooltipPosition="top"></i>
              <p-dropdown
                class="p-mr-2 pull-right"
                [ngModelOptions]="{ standalone: true }"
                [style]="{'min-width':'120px'}"
                [autoWidth]="false"
                [options]="quarters"
                [(ngModel)]="currentQuarter"></p-dropdown>
            </div>
            <div class="p-field p-ml-3 p-mb-0 p-p-2">
              <label for="For" class="label-text p-mt-2">{{'Year' | translate}}</label>
              <i
                class="fa fa-question-circle ic-blue p-mr-3"
                style="margin-left: -2px"
                showDelay="800"
                tooltipStyleClass="tooltip-width-300"
                pTooltip="{{'selectedYear_tooltip' | translate}}"
                tooltipPosition="top"></i>
              <p-dropdown
                class="p-mr-2 pull-right"
                [ngModelOptions]="{ standalone: true }"
                [style]="{'min-width':'120px'}"
                [autoWidth]="false"
                [options]="years"
                [(ngModel)]="currentYear"></p-dropdown>
            </div>
          </div>
        </div>
      </div>
      <div class="p-col-12 p-pt-1">
        <div class="card p-pt-0">
          <p-table
            [columns]="selectedColumns1"
            [value]="headerListItems"
            [rowHover]="true"
            dataKey="RecurringInvoiceHeaderID"
            selectionMode="single"
            [filterDelay]="0"
            styleClass="recurring-invoice p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
            [globalFilterFields]="recurringHeaderFilterColumns"
            [(selection)]="selectedReviewItems"
            [style]="{overflow:'visible'}"
            (onFilter)="onFilter($event)"
            (onHeaderCheckboxToggle)="toggleHeaderCheckbox($event)"
            [scrollable]="true"
            [scrollHeight]="'calc(75vh - 100px)'"
            #dt
            editMode="row">
            <ng-template pTemplate="caption">
              <div class="table-header">
                <span class="p-input-icon-left">
                  <i class="fal fa-search"></i>
                  <input
                    #searchValue
                    pInputText
                    type="text"
                    (input)="dt.filterGlobal($event.target.value, 'contains')"
                    placeholder="{{'globalsearch' | translate}}" />
                </span>
                <span>
                  <button
                    pButton
                    pRipple
                    type="button"
                    icon="pi pi-times"
                    class="p-button-rounded p-button-danger p-button-text"
                    (click)="dt.reset();clearSearchFilter()"
                    tooltipPosition="top"
                    pTooltip="{{'clear' | translate}}"></button>
                </span>
                <p-message
                  *ngIf="filteredItemsSize > -1"
                  severity="success"
                  text="{{filteredItemsSize}} Record(s) found."
                  styleClass="p-ml-2"></p-message>
                <span>
                  <!-- <button
                    *ngIf="selectedReviewItems.length > 0 && selectedReviewItems.length < headerListItems.length"
                    pButton
                    pRipple
                    icon="fal fa-times"
                    class="p-button-sm p-mr-2 p-ml-2 p-mb-1"
                    label="{{'Unselect All' | translate}}"
                    (click)="unselectSelectedReviewItems();"></button> -->
                </span>
                <span class="pull-right">
                  <span *ngIf="selectedReviewItems.length > 0" class="p-mr-3">
                    <label class="label-text p-mr-2 p-mb-2" style="display: inline-block"
                      >{{ 'pm.batch_options' | translate }}</label
                    >
                    <button
                      type="button"
                      pButton
                      pRipple
                      icon="pi pi-chevron-down"
                      (click)="menu.toggle($event)"></button>
                  </span>
                  <p-menu
                    appendTo="body"
                    #menu
                    [popup]="true"
                    styleClass="button-list"
                    [model]="buttonsDeleteList">
                  </p-menu>
                  <p-multiSelect
                    [options]="cols1"
                    [(ngModel)]="selectedColumns1"
                    optionLabel="header"
                    selectedItemsLabel="{0} columns selected"
                    [style]="{minWidth: '200px'}"
                    placeholder="Choose Columns"
                    (onChange)="onChangeSelectedCols1($event)">
                  </p-multiSelect>
                </span>
              </div>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th class="width-4p p-text-center">
                  <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th class="width-15p p-text-center">{{'actions' | translate}}</th>
                <!-- <th class="width-7p p-text-center" showDelay="800"
                                    pTooltip="{{'invoice_recurring_header_rows_tooltip' | translate}}" tooltipPosition="top">
                                    {{'Rows' | translate}}
                                    <i class="fa fa-question-circle ic-blue"></i>
                                </th>
                                <th class="width-10p p-text-center" showDelay="800"
                                    pTooltip="{{'recurring_invoice_retainer' | translate}}" tooltipPosition="top">
                                    {{'Retainer' | translate}}
                                    <i class="fa fa-question-circle ic-blue"></i>
                                </th>
                                <th class="width-15p p-text-left" pSortableColumn="RecurringGroupDescription">
                                    {{'recurring_invoice_group' | translate}}
                                    <p-sortIcon field="RecurringGroupDescription"></p-sortIcon>
                                </th> -->
                <th
                  *ngFor="let col of columns"
                  class="{{col.rowClass}}"
                  [pSortableColumn]="col.field">
                  {{col.header}}
                  <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
                  <i
                    *ngIf="col.tooltip"
                    pTooltip="{{col.tooltipMessage}}"
                    tooltipStyleClass="tooltip-width-300"
                    tooltipPosition="top"
                    class="fa fa-question-circle ic-blue"></i>
                </th>
                <th class="width-20p p-text-left required-field" pSortableColumn="ClientName">
                  {{'client.name' | translate}}
                  <p-sortIcon field="ClientName"></p-sortIcon>
                </th>
                <th
                  class="width-15p p-text-left required-field"
                  pSortableColumn="RecurringGroupDescription">
                  {{'recurring_invoice_group' | translate}}
                  <p-sortIcon field="RecurringGroupDescription"></p-sortIcon>
                </th>
                <th class="width-30p p-text-left required-field">
                  {{'Accounts.payment-memo' | translate}}
                </th>
                <th class="width-13p p-text-left" pSortableColumn="Frequency">
                  {{'frequency' | translate}}
                  <p-sortIcon field="Frequency"></p-sortIcon>
                </th>
                <th class="width-13p p-text-left required-field" pSortableColumn="ScheduledDate">
                  {{'scheduled-date' | translate}}
                  <p-sortIcon field="ScheduledDate"></p-sortIcon>
                </th>
                <th class="width-10p p-text-right">{{'Accounts.invoice-amount' | translate}}</th>
                <th
                  class="width-10p p-text-right"
                  *ngIf="invoiceList.ActivateLaborRates || invoiceList.ActivateExpenseRates">
                  {{'sales-tax' | translate}}
                </th>
                <th class="width-10p p-text-right"></th>
                <!-- <th class="{{ col.text + ' ' + col.class }}" *ngFor="let col of columns">
                                    {{col.header}}
                                </th> -->
              </tr>
            </ng-template>
            <ng-template
              pTemplate="body"
              let-rowData
              let-rowIndex="rowIndex"
              let-editing="editing"
              let-columns="columns">
              <tr class="cursor-hand" [pEditableRow]="rowData">
                <!-- Checkbox -->
                <td
                  class="width-4p p-text-center"
                  (click)="toggleCheckbox($event,rowIndex,rowData)">
                  <p-tableCheckbox [value]="rowData" [disabled]="rowData.schedule_id != null">
                  </p-tableCheckbox>
                </td>
                <!-- Actions -->
                <td class="width-15p p-text-center">
                  <span [ngClass]="{'notAllowedCursor': editing, '': !editing}">
                    <i
                      class="fal fa-file-invoice-dollar ic-green p-mr-3 ic-size-15 p-ml-1"
                      aria-hidden="true"
                      tooltipPosition="top"
                      [ngClass]="{'notAllowed': editing, '': !editing}"
                      tooltipStyleClass="tooltip-width-300"
                      (click)="openViewPanel(rowData)"
                      showDelay="800"
                      pTooltip="{{'recurring_header_actions' | translate}}"></i>
                  </span>
                  <span [ngClass]="{'notAllowedCursor': editing, '': !editing}">
                    <i
                      class="fal fa-trash-alt ic-red p-mr-3 ic-size-15"
                      aria-hidden="true"
                      [ngClass]="{'notAllowed': editing, '': !editing}"
                      pTooltip="Delete"
                      tooltipPosition="top"
                      (click)="deleteRow($event,rowData)"
                      showDelay="800"></i>
                  </span>
                  <span [ngClass]="{'notAllowedCursor': editing, '': !editing}">
                    <i
                      class="fal fa-print ic-blue p-mr-3 ic-size-15"
                      aria-hidden="true"
                      pTooltip="{{'preview' | translate}}"
                      tooltipPosition="top"
                      [ngClass]="{'notAllowed': editing, '': !editing}"
                      (click)="previewInvoice(rowData)"
                      showDelay="800"></i>
                  </span>
                  <span [ngClass]="{'notAllowedCursor': editing, '': !editing}">
                    <i
                      *ngIf="companyDetails && companyDetails.StaxStatus =='ACTIVE' && rowData.ScheduledDate && rowData['schedule_id']"
                      class="fal p-mr-3 ic-size-15 fa-calendar-check ic-red"
                      [ngClass]="{'notAllowed': editing, '': !editing}"
                      tooltipStyleClass="tooltip-no-wrap"
                      aria-hidden="true"
                      pTooltip="{{'edit-schedule-payment' | translate}}"
                      tooltipPosition="top"
                      (click)="addRecurringPayments(rowData)"></i>

                    <i
                      *ngIf="companyDetails && companyDetails.StaxStatus =='ACTIVE' && rowData.ScheduledDate && !rowData['schedule_id']"
                      class="fal p-mr-3 ic-size-15 fa-calendar-alt ic-blue"
                      [ngClass]="{'notAllowed': editing, '': !editing}"
                      tooltipStyleClass="tooltip-no-wrap"
                      aria-hidden="true"
                      pTooltip="{{'create-schedule-payment' | translate}}"
                      tooltipPosition="top"
                      (click)="addRecurringPayments(rowData)"></i>
                  </span>
                  <span [ngClass]="{'notAllowedCursor': editing, '': !editing}">
                    <i
                      *ngIf="companyDetails && companyDetails.StaxStatus =='ACTIVE' && rowData.ScheduledDate && rowData['schedule_id']"
                      class="p-mr-3 ic-size-14 fal fa-history ic-blue"
                      [ngClass]="{'notAllowed': editing, '': !editing}"
                      tooltipStyleClass="tooltip-no-wrap"
                      aria-hidden="true"
                      pTooltip="{{'recurring-invoice-history' | translate}}"
                      tooltipPosition="top"
                      (click)="showRecurringInvoiceHistory(rowData)"></i>
                  </span>
                  <span>
                    <i
                      *ngIf="(dt.filteredValue && dt.filteredValue.length - 1 == rowIndex) ||  headerListItems.length - 1 == rowIndex"
                      class="fal fa-plus ic-blue p-mr-3 ic-size-14"
                      aria-hidden="true"
                      pTooltip="Add Row"
                      tooltipPosition="top"
                      (click)="addRecurringInvoiceHeader(dt)"
                      id="new-row"></i>
                  </span>
                </td>
                <!-- <td class="width-7p p-text-center" showDelay="800" pTooltip="{{ rowData.rowCount }} {{'invoice_recurring_header_rows1' | translate}}" tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap">
                                    {{ rowData['rowCount']}}
                                </td>
                                <td class="width-10p p-text-center" showDelay="800" pTooltip="{{ rowData['isRetainer']? 'Retainer' : 'Progress Invoice'}}" tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap">
                                    <p-checkbox binary="true" name="isRetainer" [(ngModel)]="rowData['isRetainer']"
                                        (onChange)="editCellComplete($event,rowData);saveRecurringInvoiceHeader(rowData, dt,rowData['RecurringInvoiceHeaderID'], false, true);"></p-checkbox>
                                </td>
                                <td pInitEditableRow (click)="onRowEditInit1(rowData['RecurringInvoiceHeaderID'], dt, rowData)" class="width-15p p-text-left"
                                    showDelay="800" pTooltip="{{ rowData.RecurringGroupDescription }}" tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-dropdown [options]="recurringInvoiceGroupList"
                                                [(ngModel)]="rowData['RecurringInvoiceGroupID']"
                                                name="itemRecurringInvoiceGroupID" [filter]="true"
                                                id="itemRecurringInvoiceGroupID"
                                                #itemRecurringInvoiceGroupID="ngModel"
                                                (onChange)="handleDropSelectClick($event,rowData,'RecurringGroupDescription');editCellComplete($event,rowData);"
                                                appendTo="body" [style]="{'width':'200px !important'}"
                                                class="text-ellipse recurring-dpdown"></p-dropdown>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{rowData['RecurringGroupDescription']}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td> -->
                <td
                  *ngFor="let col of columns"
                  class="p-text-truncate"
                  pTooltip="{{rowData[col.field]}}"
                  showDelay="800"
                  class="{{col.rowClass}}"
                  tooltipStyleClass="tooltip-no-wrap"
                  tooltipPosition="top">
                  <span *ngIf="col.field==='rowCount'">{{ rowData['rowCount']}}</span>
                  <span *ngIf="col.field==='isRetainer'">
                    <p-checkbox
                      binary="true"
                      name="isRetainer"
                      [(ngModel)]="rowData['isRetainer']"
                      (onChange)="editCellComplete($event,rowData);saveRecurringInvoiceHeader(rowData, dt,rowData['RecurringInvoiceHeaderID'], false, true);">
                    </p-checkbox>
                  </span>
                  <span *ngIf="col.field==='RecurringGroupDescription'">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <p-dropdown
                          [options]="recurringInvoiceGroupList"
                          [(ngModel)]="rowData['RecurringInvoiceGroupID']"
                          name="itemRecurringInvoiceGroupID"
                          [filter]="true"
                          id="itemRecurringInvoiceGroupID"
                          #itemRecurringInvoiceGroupID="ngModel"
                          (onChange)="handleDropSelectClick($event,rowData,'RecurringGroupDescription');editCellComplete($event,rowData);"
                          appendTo="body"
                          [style]="{'width':'200px !important'}"
                          class="text-ellipse recurring-dpdown"></p-dropdown>
                      </ng-template>
                      <ng-template pTemplate="output">
                        <span
                          (click)="onRowEditInit1(rowData['RecurringInvoiceHeaderID'], dt, rowData)">
                          {{rowData['RecurringGroupDescription']}}
                        </span>
                      </ng-template>
                    </p-cellEditor>
                  </span>
                  <span *ngIf="col.field==='CompanyLocation'">{{ rowData['CompanyLocation']}}</span>
                  <span *ngIf="col.field==='BillingPartnerName'"
                    >{{ rowData['BillingPartnerName']}}</span
                  >
                  <span *ngIf="col.field==='BillingGroupDescription'"
                    >{{ rowData['BillingGroupDescription']}}</span
                  >
                </td>
                <!-- Client Name -->
                <td
                  pInitEditableRow
                  (click)="onRowEditInit1(rowData['RecurringInvoiceHeaderID'], dt, rowData)"
                  class="width-20p p-text-left"
                  pTooltip="{{ rowData.ClientName }}"
                  tooltipPosition="top"
                  tooltipStyleClass="tooltip-no-wrap"
                  showDelay="800">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <p-autoComplete
                        placeholder="{{rowData['ClientName']}}"
                        [(ngModel)]="rowData['ClientName']"
                        autocomplete="off"
                        [suggestions]="filteredClients"
                        (completeMethod)="filterClients($event)"
                        field="ClientName"
                        [disabled]="rowData['schedule_id']"
                        (onSelect)="handleSelectClick($event,rowData);editCellComplete($event,rowData);"
                        [size]="30"
                        [minLength]="1"
                        appendTo="body"
                        (onFocus)="$event.target.select()"
                        [dropdown]="true"
                        class="text-nowrap p-mt-2">
                        <ng-template let-client pTemplate="item">
                          <div>
                            {{client.ClientName}}{{ client.ClientNumber ? ' - ' +
                            client.ClientNumber : '' }}
                          </div>
                        </ng-template>
                      </p-autoComplete>
                    </ng-template>
                    <ng-template pTemplate="output"> {{rowData['ClientName']}} </ng-template>
                  </p-cellEditor>
                </td>
                <td
                  class="width-15p p-text-left"
                  pTooltip="{{rowData['RecurringGroupDescription']}}"
                  showDelay="800"
                  tooltipStyleClass="tooltip-no-wrap"
                  tooltipPosition="top">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <p-dropdown
                        [options]="recurringInvoiceGroupList"
                        [(ngModel)]="rowData['RecurringInvoiceGroupID']"
                        name="itemRecurringInvoiceGroupID"
                        [filter]="true"
                        id="itemRecurringInvoiceGroupID"
                        #itemRecurringInvoiceGroupID="ngModel"
                        (onChange)="handleDropSelectClick($event,rowData,'RecurringGroupDescription');editCellComplete($event,rowData);"
                        appendTo="body"
                        [style]="{'width':'200px !important'}"
                        class="text-ellipse recurring-dpdown"></p-dropdown>
                    </ng-template>
                    <ng-template pTemplate="output">
                      <span
                        (click)="onRowEditInit1(rowData['RecurringInvoiceHeaderID'], dt, rowData)">
                        {{rowData['RecurringGroupDescription']}}
                      </span>
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td
                  pInitEditableRow
                  (click)="onRowEditInit1(rowData['RecurringInvoiceHeaderID'], dt, rowData)"
                  class="no-limit-xl p-text-left"
                  [style.overflow]="rowData['IsRowEditing'] ? 'visible' : 'hidden'"
                  [ngClass]="{'new-row': ((dt.filteredValue && dt.filteredValue.length - 1 == rowIndex) ||  headerListItems.length - 1 == rowIndex)}"
                  pTooltip="{{ rowData.Description }}"
                  tooltipPosition="top"
                  tooltipStyleClass="tooltip-no-wrap"
                  showDelay="800">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <span>
                        <mwl-text-input-autocomplete-container>
                          <textarea
                            pInputTextarea
                            [ngModelOptions]="{ standalone: true }"
                            [(ngModel)]="rowData['Description']"
                            class="width-100p text-left text-ellipse"
                            style="width: 100%; height: 40px; resize: vertical"
                            name="memo"
                            mwlTextInputAutocomplete
                            [findChoices]="mangoUtils.findChoices"
                            [getChoiceLabel]="mangoUtils.getChoiceLabel"
                            placeholder="{{ 'type_search_shortcuts' | translate }}"
                            (ngModelChange)="replaceShortcuts2($event, rowData, 'Description');editCellComplete($event,rowData);"
                            autofocus></textarea>
                        </mwl-text-input-autocomplete-container>
                      </span>
                    </ng-template>
                    <ng-template pTemplate="output"> {{rowData.Description}} </ng-template>
                  </p-cellEditor>
                </td>
                <td
                  pInitEditableRow
                  (click)="onRowEditInit1(rowData['RecurringInvoiceHeaderID'], dt, rowData)"
                  class="width-13p p-text-left">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <p-dropdown
                        [(ngModel)]="rowData['Frequency']"
                        [options]="freqList"
                        [autoWidth]="false"
                        [style]="{'width':'96%'}"
                        required="true"
                        appendTo="body"
                        (onChange)="handleDropSelectClick($event,rowData,'frequency');editCellComplete($event,rowData);">
                      </p-dropdown>
                    </ng-template>
                    <ng-template pTemplate="output"> {{rowData['Frequency']}} </ng-template>
                  </p-cellEditor>
                </td>

                <td
                  pInitEditableRow
                  class="width-13p p-text-left"
                  (click)="
                                    onRowEditInit1( rowData[ 'RecurringInvoiceHeaderID' ], dt, rowData )
                                  ">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <p-calendar
                        class="calender-component"
                        showButtonBar="true"
                        appendTo="body"
                        yearRange="1000:2099"
                        [(ngModel)]="rowData['ScheduledDate']"
                        [monthNavigator]="true"
                        [yearNavigator]="true"
                        [ngModelOptions]="{ standalone: true }"
                        (onSelect)="
                                          onSelectDate( $event,rowData, 'ScheduledDate' );
                                        ">
                      </p-calendar>
                    </ng-template>

                    <ng-template pTemplate="output">
                      {{ rowData[ 'ScheduledDate' ] | date:'MM/dd/yyyy' }}
                    </ng-template>
                  </p-cellEditor>
                </td>

                <td class="width-10p p-text-right">
                  {{rowData.InvoiceAmount | currency:'USD':'symbol':'1.2-2'}}
                </td>
                <td
                  class="width-10p p-text-right"
                  *ngIf="invoiceList.ActivateLaborRates || invoiceList.ActivateExpenseRates">
                  <p-progressSpinner
                    *ngIf="isLoadingSalesTax"
                    [style]="{width: '1.5rem', height: '1.5rem'}"></p-progressSpinner>
                  <span *ngIf="!isLoadingSalesTax"
                    >{{ rowData['SalesTax'] | currency:'USD':'symbol':'1.2-2' }}</span
                  >
                </td>
                <td class="width-10p p-text-center">
                  <button
                    *ngIf="editing"
                    pButton
                    pRipple
                    type="button"
                    pSaveEditableRow
                    icon="pi pi-check"
                    (click)="saveRecurringInvoiceHeader(rowData, dt,rowData['RecurringInvoiceHeaderID']);"
                    class="p-button-rounded p-button-text p-button-success p-mr-2"></button>
                  <button
                    *ngIf="editing"
                    pButton
                    pRipple
                    type="button"
                    pCancelEditableRow
                    icon="pi pi-times"
                    (click)="onRowEditCancel(rowData, null, 'header')"
                    class="p-button-rounded p-button-text p-button-danger"></button>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="footer" let-columns>
              <tr>
                <td class="width-4p"></td>
                <td class="width-15p"></td>
                <td *ngFor="let col of columns" class="{{col.rowClass}}"></td>
                <td class="width-20p"></td>
                <td class="width-30p"></td>
                <td class="width-13p"></td>
                <td class="width-13p p-text-right p-text-bold ic-red">Totals</td>
                <td class="width-10p p-text-right p-text-bold ic-red">
                  {{ headerInvoiceAmountTotal | currency:'USD':'symbol':'1.2-2' }}
                </td>
                <td
                  class="width-10p p-text-right p-text-bold ic-red"
                  *ngIf="invoiceList.ActivateLaborRates || invoiceList.ActivateExpenseRates">
                  {{ headerSalesTaxTotal | currency:'USD':'symbol':'1.2-2' }}
                </td>
                <td class="width-10p p-text-right p-text-bold ic-red"></td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td class="width-100p" [attr.colspan]="3 + selectedColumns.length">
                  <p-message
                    severity="info"
                    text="{{'data_not_found' | translate}}."
                    styleClass="p-px-2"></p-message>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
</div>
<p-dialog
  header="{{ 'batch_update_recurring_invoices' | translate }} - {{selectedReviewItems.length}} {{'selected' | translate}}"
  [(visible)]="showBatchUpdate"
  [modal]="true"
  [style]="{width: '35vw'}"
  [maximizable]="false"
  [contentStyle]="{overflow: 'visible'}"
  showEffect="fade"
  (onHide)="onCloseBatchUpdate();"
  [baseZIndex]="1"
  [draggable]="true"
  [resizable]="false">
  <form role="form" [formGroup]="batchUpdateForm" #formDirective="ngForm" novalidate>
    <div class="p-fluid p-formgrid p-grid p-m-1 common-dialog">
      <div class="p-col-12 p-formgroup-inline">
        <div class="p-col-6 p-mt-2">
          <p-checkbox
            formControlName="isScheduledDate"
            [binary]="true"
            label="{{ 'scheduled-date' | translate }}"
            class="label-text">
          </p-checkbox>
        </div>
        <div class="p-col-6">
          <p-calendar
            formControlName="ScheduledDate"
            [monthNavigator]="true"
            appendTo="body"
            [disabled]="!batchUpdateForm.controls['isScheduledDate'].value"
            (onSelect)="validateForm(true);"
            (onClearClick)="validateForm(true);"
            (onBlur)="validateForm(true);"
            [yearNavigator]="true"
            yearRange="2000:2099"
            showButtonBar="true">
          </p-calendar>
        </div>
      </div>
      <div class="p-col-12 p-formgroup-inline">
        <div class="p-col-6 p-mt-4">
          <p-checkbox
            formControlName="isReleaseDate"
            [binary]="true"
            label="{{ 'release-time-expenses' | translate }}"
            class="label-text">
          </p-checkbox>
        </div>
        <div class="p-col-6 p-mt-3">
          <p-calendar
            formControlName="ReleaseDate"
            [monthNavigator]="true"
            appendTo="body"
            [disabled]="!batchUpdateForm.controls['isReleaseDate'].value"
            (onSelect)="validateForm(true);"
            (onClearClick)="validateForm(true);"
            (onBlur)="validateForm(true);"
            [yearNavigator]="true"
            yearRange="2000:2099"
            showButtonBar="true">
          </p-calendar>
        </div>
      </div>
      <div class="p-col-12 p-formgroup-inline">
        <div class="p-col-6 p-mt-4">
          <p-checkbox
            formControlName="isMemo"
            [binary]="true"
            label="{{ 'Accounts.payment-memo' | translate }}"
            class="label-text">
          </p-checkbox>
        </div>
        <div class="p-col-6 p-mt-3">
          <mwl-text-input-autocomplete-container>
            <textarea
              pInputTextarea
              [(ngModel)]="batchUpdateMemo"
              [ngModelOptions]="{ standalone: true }"
              [disabled]="!batchUpdateForm.controls['isMemo'].value"
              class="width-100p text-left text-ellipse"
              style="width: 100%; height: 40px; resize: vertical"
              mwlTextInputAutocomplete
              [findChoices]="mangoUtils.findChoices"
              [getChoiceLabel]="mangoUtils.getChoiceLabel"
              placeholder="{{ 'type_search_shortcuts' | translate }}"
              (ngModelChange)="replaceShortcuts3($event);validateForm(true)"
              autofocus></textarea>
          </mwl-text-input-autocomplete-container>
        </div>
      </div>
      <div class="p-col-12 p-formgroup-inline">
        <div class="p-col-6 p-mt-4">
          <p-checkbox
            formControlName="isGroup"
            [binary]="true"
            label="{{ 'recurring_invoice_group' | translate }}"
            class="label-text">
          </p-checkbox>
        </div>
        <div class="p-col-6 p-mt-3">
          <p-dropdown
            [options]="recurringInvoiceGroupList"
            [disabled]="!batchUpdateForm.controls['isGroup'].value"
            formControlName="Group"
            [filter]="true"
            placeholder="Select Recurring Group"
            appendTo="body"
            [style]="{'width':'200px !important'}"
            class="text-ellipse recurring-dpdown"></p-dropdown>
        </div>
      </div>
      <div class="p-col-12 p-formgroup-inline">
        <div class="p-col-6 p-mt-4">
          <p-checkbox
            formControlName="isFrequency"
            [binary]="true"
            label="{{ 'frequency' | translate }}"
            class="label-text">
          </p-checkbox>
        </div>
        <div class="p-col-6 p-mt-3">
          <p-dropdown
            formControlName="Frequency"
            [options]="freqList"
            placeholder="Select Frequency"
            [disabled]="!batchUpdateForm.controls['isFrequency'].value"
            [autoWidth]="false"
            [style]="{'width':'100%'}"
            appendTo="body"></p-dropdown>
        </div>
      </div>
      <div class="p-col-12 p-formgroup-inline">
        <div class="p-col-6 p-mt-4">
          <p-checkbox
            formControlName="isInvoiceTemplate"
            [binary]="true"
            label="{{ 'billing-invoicing.invoice-template' | translate }}"
            class="label-text">
          </p-checkbox>
        </div>
        <div class="p-col-6 p-mt-3">
          <p-dropdown
            formControlName="InvoiceTemplate"
            [options]="invoiceTemplateTypes"
            placeholder="Select Invoice Templates"
            [disabled]="!batchUpdateForm.controls['isInvoiceTemplate'].value"
            [autoWidth]="false"
            [style]="{'width':'100%'}"
            appendTo="body"></p-dropdown>
        </div>
      </div>
      <div class="p-col-12 p-formgroup-inline">
        <div class="p-col-6 p-mt-4">
          <p-checkbox
            formControlName="isFinalizeAction"
            [binary]="true"
            label="{{ 'FinalizeAction' | translate }}"
            class="label-text">
          </p-checkbox>
        </div>
        <div class="p-col-6 p-mt-3">
          <p-dropdown
            formControlName="FinalizeAction"
            [options]="finalizeActions"
            placeholder="Select Finalize Action"
            [disabled]="!batchUpdateForm.controls['isFinalizeAction'].value"
            [autoWidth]="false"
            [style]="{'width':'100%'}"
            appendTo="body"></p-dropdown>
        </div>
      </div>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <button
      type="button"
      pButton
      pRipple
      icon="fal fa-times"
      (click)="onCloseBatchUpdate()"
      label="{{ 'cancel_button' | translate }}"></button>
    <button
      type="button"
      pButton
      pRipple
      icon="fal fa-check"
      label="{{ 'update-all' | translate }}"
      [disabled]="!isFormValid"
      (click)="saveBatchUpdate()"></button>
  </ng-template>
</p-dialog>

<p-sidebar
  [(visible)]="showFilterSideBar"
  (onHide)="onCloseFilter()"
  position="right"
  [style]="{'min-width':'25%','min-height':'100%','background':'#8e8f92','overflow': 'auto'}">
  <div class="card" style="min-height: calc(80vh - 200px)">
    <div class="card p-col-12 p-py-0 p-px-4 p-mx-2 p-mb-2">
      <div class="p-field p-col-12 p-fluid">
        <label class="label-text required-field"> {{'Bill-Through-Date' | translate}} </label>
        <p-calendar
          [monthNavigator]="true"
          [yearNavigator]="true"
          yearRange="2000:2090"
          (onSelect)="onDateSelect($event, 'InvoiceStartDate');"
          [(ngModel)]="InvoiceStartDate"
          showButtonBar="true"></p-calendar>
      </div>
      <div class="p-field p-col-12 p-mb-0 p-mt-2 p-pl-3 p-grid p-mb-3">
        <p-checkbox
          [(ngModel)]="isIncludedBillThrough"
          binary="true"
          [ngModelOptions]="{standalone: true}"
          label="{{'billing.include_bill_through_date_on_invoice' | translate}}"></p-checkbox>
      </div>
      <!-- <div class="p-field p-col-12 p-fluid">
            <label class="label-text">{{'user-title' | translate}}</label>
            <p-dropdown [options]="AllStaffsTypes" formControlName="staffId">
            </p-dropdown>
        </div> -->
      <!-- New Dropdown -->
      <div class="p-field p-col-12 p-fluid">
        <label class="label-text">{{'Billing_Partner' | translate}}</label>
        <p-dropdown [options]="AllStaffsTypes" [(ngModel)]="BillingPartnerID"></p-dropdown>
      </div>
      <!-- <div class="p-field p-col-12 p-fluid">
            <label class="label-text">{{'Engagements' | translate}}</label>
            <p-multiSelect [options]="engagementsTypes" class="multi-select"
                (onChange)="SelectEngagements($event)"
                [ngModelOptions]="{standalone: true}" [(ngModel)]="selEngagementType"
                defaultLabel="All Engagements">
                <ng-template let-engagement pTemplate="item">
                    <div style="width:100%;display:inline-block;vertical-align:middle">{{engagement.label}}
                    </div>
                </ng-template>
            </p-multiSelect>
        </div> -->
      <div class="p-field p-col-12 p-fluid">
        <label class="label-text">{{'Client_Group' | translate}}</label>
        <p-dropdown
          [options]="groupList"
          [(ngModel)]="SelectedGroup"
          placeholder="All"></p-dropdown>
      </div>
      <div class="p-field p-col-12 p-fluid">
        <label class="label-text">{{'recurring_invoice_group' | translate}}</label>
        <p-dropdown
          [options]="recurringInvoiceGroupList"
          [(ngModel)]="InvoiceGroup"
          placeholder="All">
        </p-dropdown>
      </div>
      <!-- <div class="p-field p-col-12 p-fluid">
            <label class="label-text">{{'engagement.billing_method' | translate}}</label>
            <p-dropdown [options]="billingMethodList" formControlName="selectedBillingMethod" placeholder="All"></p-dropdown>
        </div> -->
      <div
        class="p-d-flex p-jc-end p-mr-2 p-mt-6 filter-btns"
        style="bottom: 0px; position: absolute; margin-bottom: 10px">
        <button
          type="button"
          pButton
          pRipple
          icon="fal fa-sync"
          class="p-mr-2"
          (click)="onFilterFetch()"
          label="{{ 'fetch' | translate }}"></button>
        <button
          type="button"
          pButton
          pRipple
          icon="fal fa-undo"
          class="p-mr-2"
          label="{{ 'Reset' | translate }}"
          (click)="onResetFilters()"></button>
        <button
          type="button"
          pButton
          pRipple
          icon="fal fa-times"
          label="{{ 'cancel_button' | translate }}"
          (click)="onCloseFilter()"></button>
      </div>
    </div>
  </div>
</p-sidebar>

<p-sidebar
  *ngIf="selectedEngagment"
  [(visible)]="timeRecordsDisplay"
  position="right"
  (onHide)="cancelTimeRecordsSideBar()"
  [style]="{'min-width':'99%','min-height':'100%','background':'#8e8f92','overflow': 'auto'}">
  <div class="card" style="min-height: calc(100vh - 34px)">
    <div class="p-col-12 p-d-flex p-jc-between">
      <label class="label-text ic-size-18 p-mb-3"
        >{{'customer_records' | translate}} - {{ selectedEngagment['EngagementName'] }}</label
      >
      <span *ngIf="selectedTimeRecords?.length > 0">
        <app-batch-options-time
          [selOpenTimeRecords]="selectedTimeRecords"
          [defaultClient]="{ ClientID: selectedData['ClientID'], ClientName: selectedData['ClientName'] }"
          [selOpenExpRecords]="[]"
          [deleteWuwdAndBilledAmt]="true"></app-batch-options-time>
      </span>
    </div>
    <div class="p-col-12 p-mt-3 p-pr-0 manual">
      <p-panel header="{{ 'Open-Time-Records' | translate }}" [toggleable]="true">
        <p-table
          [value]="recurringInvoiceOpenTimeRecords"
          [rows]="5"
          [pageLinks]="3"
          sortField="Ddate"
          [sortOrder]="-1"
          [rowHover]="true"
          dataKey="SlipMasterID"
          [scrollable]="true"
          [scrollHeight]="'calc(85vh - 100px)'"
          [responsive]="true"
          [resizableColumns]="true"
          metaKeySelection="true"
          (onHeaderCheckboxToggle)="toggleTimeRecordCheckBox(selectedEngagment)"
          [(selection)]="selectedTimeRecords"
          (onFilter)="onFilterTime($event)"
          [globalFilterFields]="openTimeGlobalColumns"
          styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
          editMode="row"
          #timeRecordsDt
          [columns]="selectedColumns">
          <ng-template pTemplate="caption">
            <div class="table-header" style="margin-top: -10px">
              <div class="p-d-flex p-jc-between">
                <div>
                  <span class="p-input-icon-left">
                    <i class="fal fa-search"></i>
                    <input
                      #searchValueTime
                      pInputText
                      type="text"
                      (input)="timeRecordsDt.filterGlobal($event.target.value, 'contains')"
                      placeholder="{{ 'Search-by' | translate }}" />
                  </span>
                  <span>
                    <button
                      pButton
                      pRipple
                      type="button"
                      icon="pi pi-times"
                      class="p-button-rounded p-button-danger p-button-text"
                      (click)="timeRecordsDt.reset();clearSearchFilterTime()"
                      tooltipPosition="top"
                      pTooltip="{{'clear' | translate}}"></button>
                  </span>
                  <p-message
                    *ngIf="filteredTimeItemsSize > -1"
                    severity="success"
                    text="{{filteredTimeItemsSize}} {{ 'records_found' | translate }}."
                    styleClass="p-ml-2"></p-message>
                </div>
                <p-multiSelect
                  [options]="cols"
                  [(ngModel)]="selectedColumns"
                  optionLabel="header"
                  selectedItemsLabel="{0} columns selected"
                  [style]="{minWidth: '200px'}"
                  (onChange)="onChangeSelectedCols($event)"
                  placeholder="Choose Columns">
                </p-multiSelect>
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th class="width-4p p-text-center">
                <p-tableHeaderCheckbox
                  [disabled]="searchValueTime && searchValueTime.nativeElement.value">
                </p-tableHeaderCheckbox>
              </th>
              <th class="width-4p p-text-center">{{'action' | translate}}</th>
              <th
                class="width-3p p-text-center"
                pTooltip="{{'Private-Memo' | translate}}"
                tooltipPosition="top">
                {{'PM' | translate}}
                <i class="fa fa-question-circle ic-blue"></i>
              </th>
              <!-- <th class="width-7p p-text-left" pSortableColumn="Ddate">
                                    {{'date' | translate}}
                                    <p-sortIcon field="Ddate"></p-sortIcon>
                                </th>
                                <th class="width-10p p-text-left" pSortableColumn="StaffName">
                                    {{'user-title' | translate}}
                                    <p-sortIcon field="StaffName"></p-sortIcon>
                                </th>
                                <th class="width-10p p-text-left" pSortableColumn="scdescr">
                                    {{'activity' | translate}}
                                    <p-sortIcon field="scdescr"></p-sortIcon>
                                </th>
                                <th class="width-15p p-text-left">
                                    {{'description' | translate}}
                                </th> -->
              <th
                *ngFor="let col of columns"
                class="{{col.rowClass}}"
                [pSortableColumn]="col.field"
                class="p-text-left">
                {{col.header}}
                <i
                  *ngIf="col.field == 'ServiceCode'"
                  pTooltip="{{'activity-code' | translate}}"
                  tooltipPosition="top"
                  class="fa fa-question-circle ic-blue"></i>
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
              <th
                class="width-5p p-text-right"
                pTooltip="{{'Billing-Hours' | translate}}"
                tooltipPosition="top">
                {{'timer.hours' | translate}}
                <i class="fa fa-question-circle ic-blue"></i>
              </th>
              <th class="width-5p p-text-right">{{'billing-rate' | translate}}</th>
              <!-- <th class="width-8p p-text-right" pTooltip="{{'Non-Billable-Amount' | translate}}"
                                    tooltipPosition="top">
                                    {{'Non-Billable' | translate}}
                                    <i class="fa fa-question-circle ic-blue"></i>
                                </th> -->
              <th
                class="width-3p p-text-center"
                pTooltip="{{'Non-Billable-tooltip' | translate}}"
                tooltipPosition="top"
                tooltipStyleClass="tooltip-no-wrap">
                NB
                <i class="fa fa-question-circle ic-blue"></i>
              </th>
              <th
                class="width-8p p-text-right"
                pTooltip="{{'billable-amount' | translate}}/{{'Non-Billable-Amount' | translate}}"
                tooltipPosition="top"
                tooltipStyleClass="tooltip-no-wrap">
                {{'Standard_Amount' | translate}}
                <i class="fa fa-question-circle ic-blue"></i>
              </th>
              <th class="width-5p p-text-right"></th>
            </tr>
          </ng-template>
          <ng-template
            pTemplate="body"
            let-childrowData
            let-columns="columns"
            let-ri="rowIndex"
            let-editing="editing">
            <tr class="cursor-hand" [pEditableRow]="childrowData">
              <td class="width-4p p-text-center">
                <p-tableCheckbox
                  [value]="childrowData"
                  (click)="toggleTimeRecordCheckBox(childrowData)"></p-tableCheckbox>
              </td>
              <td class="width-4p p-text-center">
                <span
                  ><i
                    class="fal fa-trash-alt ic-red ic-size-15"
                    (click)="deleteClientAlert(childrowData,ri,'Opentime');"
                    tooltipPosition="top"
                    pTooltip="{{'mgmt-delete' | translate}}"></i
                ></span>
              </td>
              <td class="width-3p p-text-center">
                <i
                  class="fa fa-sticky-note p-pr-2 cursor-hand"
                  [ngClass]="(childrowData.PrivateMemo === null || childrowData.PrivateMemo === '') ? 'ic-grey' : 'ic-folder-yellow'"
                  (click)="onShowScratchpad(childrowData)"
                  pTooltip="{{ 'private_memo' | translate }}"
                  tooltipPosition="top"
                  showDelay="800"></i>
              </td>
              <!-- <td class="width-7p p-text-left">
                                    {{ childrowData.displayDate | date: "MM/dd/yyyy" }}
                                </td>
                                <td class="width-10p p-text-left p-text-nowrap p-text-truncate" (click)="onRowEditInit1(childrowData['SlipMasterID'], timeRecordsDt, childrowData)"
                                     showDelay="800" pTooltip="{{ childrowData.StaffName }}" tooltipPosition="top"
                                    tooltipStyleClass="tooltip-no-wrap" pInitEditableRow>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-autoComplete placeholder="{{ childrowData.StaffName }}"
                                                [(ngModel)]="childrowData['StaffName']"
                                                [suggestions]="filteredStaffSingle"
                                                (onSelect)="handleDropStaffSelectClick($event,childrowData, 'Staff')"
                                                (completeMethod)="filterStaffItems($event,childrowData)" field="label"
                                                [size]="30" [minLength]="1" [dropdown]="true" appendTo="body">
                                            </p-autoComplete>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <div>{{ childrowData.StaffName }}</div>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td class="width-10p p-text-left p-text-nowrap p-text-truncate" showDelay="800" pTooltip="{{ childrowData.scdescr }}"
                                    (click)="onRowEditInit1(childrowData['SlipMasterID'], timeRecordsDt, childrowData)"
                                    tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap" pInitEditableRow>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-dropdown [options]="activityList1"
                                                placeholder="{{ childrowData.scdescr }}"
                                                [(ngModel)]="childrowData.ServiceCodeID" id="TimeDescription"
                                                name="TimeDescription" [filter]="true" #TimeDescription="ngModel"
                                                (onChange)="handleDropStaffSelectClick($event,childrowData,'Activity')"
                                                appendTo="body" class="p-text-left" [style]="{'width':'100%'}">
                                            </p-dropdown>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <div>{{ childrowData['scdescr'] }}
                                            </div>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td style="overflow: visible;" [style.overflow]="childrowData['IsRowEditing'] ? 'visible' : 'hidden'"
                                    (click)="onRowEditInit1(childrowData['SlipMasterID'], timeRecordsDt, childrowData)" pInitEditableRow
                                    class="width-15p p-text-left text-nowrap p-text-truncate"  showDelay="800" pTooltip="{{ childrowData.Memo }}"
                                    tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                                <mwl-text-input-autocomplete-container>
                                                    <textarea pInputTextarea
                                                        [ngModelOptions]="{ standalone: true }"
                                                        [(ngModel)]="childrowData['Memo']"
                                                        class="width-100p text-left text-ellipse"
                                                        style="width: 100%;height: 40px; resize: vertical;" name="memo"
                                                        mwlTextInputAutocomplete [findChoices]="mangoUtils.findChoices"
                                                        [getChoiceLabel]="mangoUtils.getChoiceLabel"
                                                        placeholder="{{ 'type_search_shortcuts' | translate }}"
                                                        (ngModelChange)="replaceShortcuts2($event, childrowData, 'Memo');recurringInvoiceChange(childrowData);" autofocus></textarea>
                                                </mwl-text-input-autocomplete-container>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{childrowData.Memo}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td> -->
              <td
                *ngFor="let col of columns"
                class="{{col.rowClass}} p-text-truncate"
                pTooltip="{{childrowData[col.field]}}"
                showDelay="800"
                tooltipStyleClass="tooltip-no-wrap"
                tooltipPosition="top">
                <span *ngIf="col.field==='Ddate'">
                  {{ mangoUtils.formatDateString(childrowData['Ddate']) | date:'MM/dd/yyyy' }}
                </span>
                <span
                  *ngIf="col.field==='StaffName'"
                  (click)="onRowEditInit1(childrowData['SlipMasterID'], timeRecordsDt, childrowData)"
                  pInitEditableRow>
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <p-autoComplete
                        placeholder="{{ childrowData.StaffName }}"
                        [(ngModel)]="childrowData['StaffName']"
                        [disabled]="childrowData['Billed'] || isLocked(childrowData['Ddate'])"
                        [suggestions]="filteredStaffSingle"
                        (onSelect)="handleDropStaffSelectClick($event,childrowData, 'Staff')"
                        (completeMethod)="filterStaffItems($event,childrowData)"
                        field="label"
                        [size]="30"
                        [minLength]="1"
                        [dropdown]="true"
                        appendTo="body">
                      </p-autoComplete>
                    </ng-template>
                    <ng-template pTemplate="output">
                      <div>{{ childrowData.StaffName }}</div>
                    </ng-template>
                  </p-cellEditor>
                </span>
                <span *ngIf="col.field==='StaffNumber'"> {{ childrowData.StaffNumber }} </span>
                <span
                  *ngIf="col.field==='scdescr'"
                  (click)="onRowEditInit1(childrowData['SlipMasterID'], timeRecordsDt, childrowData)"
                  pInitEditableRow>
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <p-dropdown
                        [options]="activityList1"
                        placeholder="{{ childrowData.scdescr }}"
                        [(ngModel)]="childrowData.ServiceCodeID"
                        id="TimeDescription"
                        [disabled]="childrowData['Billed'] || isLocked(childrowData['Ddate'])"
                        name="TimeDescription"
                        [filter]="true"
                        #TimeDescription="ngModel"
                        (onChange)="handleDropStaffSelectClick($event,childrowData,'Activity')"
                        appendTo="body"
                        class="p-text-left"
                        [style]="{'width':'100%'}">
                      </p-dropdown>
                    </ng-template>
                    <ng-template pTemplate="output">
                      <div>{{ childrowData['scdescr'] }}</div>
                    </ng-template>
                  </p-cellEditor>
                </span>
                <span *ngIf="col.field==='ServiceCode'"> {{ childrowData.ServiceCode }} </span>
                <span
                  *ngIf="col.field==='Memo'"
                  (click)="onRowEditInit1(childrowData['SlipMasterID'], timeRecordsDt, childrowData)"
                  pInitEditableRow>
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <mwl-text-input-autocomplete-container>
                        <textarea
                          pInputTextarea
                          [ngModelOptions]="{ standalone: true }"
                          [(ngModel)]="childrowData['Memo']"
                          class="width-100p text-left text-ellipse"
                          [disabled]="childrowData['Billed'] || isLocked(childrowData['Ddate'])"
                          style="width: 100%; height: 40px; resize: vertical"
                          name="memo"
                          mwlTextInputAutocomplete
                          [findChoices]="mangoUtils.findChoices"
                          [getChoiceLabel]="mangoUtils.getChoiceLabel"
                          placeholder="{{ 'type_search_shortcuts' | translate }}"
                          (ngModelChange)="replaceShortcuts2($event, childrowData, 'Memo');recurringInvoiceChange(childrowData);"
                          autofocus></textarea>
                      </mwl-text-input-autocomplete-container>
                    </ng-template>
                    <ng-template pTemplate="output"> {{childrowData.Memo}} </ng-template>
                  </p-cellEditor>
                </span>
              </td>
              <td
                class="width-5p p-text-right"
                showDelay="800"
                pTooltip="{{ childrowData.TotalTime | number: '1.2-2' }}"
                tooltipPosition="top"
                tooltipStyleClass="tooltip-no-wrap"
                (click)="onRowEditInit1(childrowData['SlipMasterID'], timeRecordsDt, childrowData)"
                pInitEditableRow>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input
                      pInputText
                      type="text"
                      pKeyFilter="num"
                      [(ngModel)]="childrowData.TotalTime"
                      mode="decimal"
                      [ngModelOptions]="{standalone: true}"
                      [disabled]="childrowData['Billed'] || isLocked(childrowData['Ddate'])"
                      (ngModelChange)="onTotalTimeChange($event, childrowData);childrowData['BillRateChanged'] = true;"
                      (focus)="$event.target.select()"
                      class="width-100p p-text-right"
                      (mouseup)="$event.preventDefault()" />
                    <!-- <p-inputNumber maxlength="8" minFractionDigits="2"
                                                inputStyleClass="width-100p p-text-right"
                                                (onFocus)="$event.target.select()" [step]="0"
                                                [value]="childrowData.TotalTime" [ngModelOptions]="{standalone: true}"
                                                (onBlur)="onTotalTimeChange($event, childrowData);childrowData['BillRateChanged'] = true;"
                                                [(ngModel)]="childrowData.TotalTime" mode="decimal"></p-inputNumber> -->
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ childrowData.TotalTime | number: "1.2-2" }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td
                class="width-5p p-text-right"
                showDelay="800"
                pTooltip="{{ childrowData.BillingRate | currency: 'USD':true:'1.2-2' }}"
                tooltipPosition="top"
                tooltipStyleClass="tooltip-no-wrap"
                (click)="onRowEditInit1(childrowData['SlipMasterID'], timeRecordsDt, childrowData)"
                pInitEditableRow>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-inputNumber
                      maxlength="20"
                      minFractionDigits="2"
                      inputStyleClass="width-100p p-text-right"
                      (onFocus)="$event.target.select()"
                      [step]="0"
                      [value]="childrowData.BillingRate"
                      [disabled]="childrowData['Billed'] || isLocked(childrowData['Ddate'])"
                      [ngModelOptions]="{standalone: true}"
                      (onBlur)="onBillRateChange($event, childrowData);childrowData['BillRateChanged'] = true;"
                      [(ngModel)]="childrowData.BillingRate"
                      mode="decimal">
                    </p-inputNumber>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ childrowData.BillingRate | currency: "USD":true:"1.2-2" }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <!-- <td class="width-8p p-text-right">
                                    {{ childrowData.nonbillableamount | currency: "USD":true:"1.2-2" }}
                                </td> -->
              <td
                class="width-3p p-text-center"
                id="border-right"
                (click)="onRowEditInit1(childrowData['SlipMasterID'], timeRecordsDt, childrowData)">
                <i *ngIf="childrowData.nonbillableamount" class="pi pi-check ic-green"></i>
              </td>
              <td
                class="width-8p p-text-right"
                id="border-right"
                (click)="onRowEditInit1(childrowData['SlipMasterID'], timeRecordsDt, childrowData)"
                pInitEditableRow>
                {{ childrowData.billableamount || childrowData.nonbillableamount | currency:
                "USD":true:"1.2-2" }}
              </td>
              <td class="width-5p p-text-center">
                <!-- <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="fal fa-pencil"
                                                (click)="onRowEditInit(childrowData)" class="ic-green p-button-rounded p-button-text"></button> -->
                <button
                  *ngIf="editing"
                  pButton
                  pRipple
                  type="button"
                  pSaveEditableRow
                  (click)="updateTimeRecord(childrowData)"
                  icon="pi pi-check"
                  class="p-button-rounded p-button-text p-button-success p-mr-2"></button>
                <button
                  *ngIf="editing"
                  pButton
                  pRipple
                  type="button"
                  pCancelEditableRow
                  (click)="onRowEditCancel(childrowData, null)"
                  icon="pi pi-times"
                  class="p-button-rounded p-button-text p-button-danger"></button>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="footer" let-columns>
            <tr>
              <td class="width-4p p-text-center"></td>
              <td class="width-4p p-text-center"></td>
              <td class="width-3p p-text-center"></td>
              <td *ngFor="let col of columns" class="{{col.rowClass}}"></td>
              <td class="width-5p p-text-right p-text-bold ic-red">Totals</td>
              <td class="width-5p p-text-right p-text-bold ic-red">
                {{ timeRecordsFooter.totalTime | number: "1.2-2" }}
              </td>
              <td class="width-3p p-text-right p-text-bold ic-red">&nbsp;</td>
              <td class="width-8p p-text-right p-text-bold ic-red">
                {{ timeRecordsFooter.laboramount + timeRecordsFooter.nonbillableamount | currency:
                "USD":true:"1.2-2" }}
              </td>
              <td class="width-5p p-text-right p-text-bold ic-red"></td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="8">
                <p-message
                  severity="info"
                  text="{{ 'data_not_found' | translate }}."
                  styleClass="p-px-2"></p-message>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </p-panel>
    </div>
  </div>
</p-sidebar>

<!-- ScratchPad sidebar -->
<p-sidebar
  *ngIf="editableData != null"
  [(visible)]="scratchPadEnabled"
  position="bottom"
  [style]="{'min-height':'522px','background':'#8e8f92'}"
  (onHide)="onScratchpadClose()">
  <div class="card">
    <div class="control-label header-color text-nowrap p-col-12">
      <h4 class="label-text">{{ 'Private-Memo' | translate }}</h4>
    </div>
    <div class="p-col-12 col-md-12">
      <ejs-richtexteditor
        id="clientEditor"
        #clientEditor
        [(ngModel)]="editableData.PrivateMemo"
        [toolbarSettings]="tools"
        [height]="400"
        (ngModelChange)="onScratchpadChange()">
      </ejs-richtexteditor>
    </div>
  </div>
</p-sidebar>

<p-sidebar
  [(visible)]="sidePanelDisplay"
  position="right"
  (onHide)="cancelRecurringInvoiceSideBar()"
  [style]="{'min-width':'98%','min-height':'100%','background':'#8e8f92','overflow': 'auto'}">
  <div class="card" style="min-height: calc(100vh - 34px)">
    <div class="p-col-12 p-formgroup-inline">
      <label class="p-col-5 label-text ic-size-18 p-pb-4">
        Edit Recurring Invoice - {{ selectedData['ClientName'] }}
      </label>
      <div class="p-col-7">
        <button
          type="button"
          pButton
          pRipple
          pTooltip="Update"
          label="{{ 'Add Expense' | translate }}"
          icon="fal fa-plus"
          class="p-mr-1 p-button-sm pull-right"
          (click)="addExpenseRow(dtExpenses)"></button>
        <button
          type="button"
          pButton
          pRipple
          pTooltip="Update"
          label="{{ 'Add Service' | translate }}"
          icon="fal fa-plus"
          class="p-mr-1 p-button-sm pull-right"
          (click)="addDetailRow(dtDetails)"></button>
        <p-dropdown
          class="p-mr-3 pull-right"
          [ngModelOptions]="{ standalone: true }"
          [style]="{'min-width':'120px'}"
          [autoWidth]="false"
          [(ngModel)]="selectedData['FinalizeAction']"
          [options]="finalizeActions"
          (onChange)="onFinalizeActionChange($event)"></p-dropdown>
        <p-dropdown
          class="p-mr-1 pull-right"
          [ngModelOptions]="{ standalone: true }"
          [style]="{'min-width':'250px'}"
          [autoWidth]="false"
          [(ngModel)]="selectedData['InvoiceTemplate']"
          scrollHeight="400px"
          [options]="invoiceTemplateTypes"
          (onChange)="onInvoiceTemplateChange($event)">
        </p-dropdown>
      </div>
    </div>
    <div class="card p-py-1">
      <div class="p-grid p-fluid">
        <div class="p-col-12 p-mt-3 p-pr-0 manual">
          <p-panel header="Services" [toggleable]="true">
            <p-table
              [value]="recurringInvoiceDetails"
              [rowHover]="true"
              dataKey="RecurringInvoiceDetailID"
              selectionMode="single"
              [filterDelay]="0"
              styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
              [globalFilterFields]="globalFilterColumns"
              [scrollable]="true"
              [scrollHeight]="'calc(50vh - 100px)'"
              #dtDetails
              editMode="row"
              [responsive]="true"
              [resizableColumns]="true"
              metaKeySelection="true"
              [(selection)]="selectedDetails"
              (onHeaderCheckboxToggle)="toggleDetailCheckbox($event)"
              [reorderableColumns]="true"
              (onRowReorder)="onRowChange($event)">
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th class="width-5p p-text-center">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                  </th>
                  <th class="width-20p p-text-center">{{'actions' | translate}}</th>
                  <th class="width-6p p-text-center"></th>
                  <th class="width-15p p-text-left required-field" pSortableColumn="StaffNumber">
                    {{'user-title' | translate}}
                    <p-sortIcon field="StaffNumber"></p-sortIcon>
                  </th>
                  <th class="width-20p p-text-left required-field">
                    {{'Engagements' | translate}}
                  </th>
                  <th
                    class="width-15p p-text-center"
                    pTooltip="{{'NDE' | translate}}"
                    tooltipPosition="top"
                    showDelay="800">
                    {{'Exp.' | translate}}
                    <i class="fa fa-question-circle ic-blue"></i>
                  </th>
                  <th class="width-30p p-text-left required-field">
                    {{'billing-invoicing.invoice-description' | translate}}
                  </th>
                  <th class="width-15p p-text-center">{{'release-time-expenses' | translate}}</th>
                  <th class="width-20p p-text-center">{{'Release Thru' | translate}}</th>
                  <th
                    class="width-10p p-text-right"
                    pSortableColumn="totaltime"
                    showDelay="800"
                    pTooltip="{{'billable-time' | translate}}"
                    tooltipPosition="top">
                    {{'Time' | translate}} <i class="fa fa-question-circle ic-blue"></i>
                  </th>
                  <!-- <th class="width-10p p-text-right" pTooltip="{{'Non-Billable-Amount' | translate}}"
                                            tooltipPosition="top">{{'Non-Billable' | translate}}
                                            <i class="fa fa-question-circle ic-blue"></i>
                                        </th> -->
                  <th
                    class="width-20p p-text-right"
                    pTooltip="{{'billable-amount' | translate}}/{{'Non-Billable-Amount' | translate}}"
                    tooltipPosition="top"
                    showDelay="800"
                    tooltipStyleClass="tooltip-no-wrap">
                    {{'Standard_Amount' | translate}}
                    <i class="fa fa-question-circle ic-blue"></i>
                  </th>
                  <th
                    class="width-15p p-text-right"
                    pTooltip="{{'writes-up-down' | translate}}"
                    tooltipPosition="top"
                    showDelay="800">
                    WUWD
                    <i class="fa fa-question-circle ic-blue"></i>
                  </th>
                  <th
                    class="width-15p p-text-right"
                    showDelay="800"
                    pTooltip="{{'Accounts.invoice-amount' | translate}}"
                    tooltipPosition="top">
                    {{'amount' | translate}}
                    <i class="fa fa-question-circle ic-blue"></i>
                  </th>
                  <th class="width-15p p-text-right"></th>
                </tr>
              </ng-template>
              <ng-template
                pTemplate="body"
                let-rowData
                let-columns="columns"
                let-rowIndex="rowIndex"
                let-editing="editing">
                <tr class="cursor-hand" [pEditableRow]="rowData" [pReorderableRow]="rowIndex">
                  <td class="width-5p p-text-center">
                    <p-tableCheckbox [value]="rowData" (click)="toggleDetailCheckbox($event)">
                    </p-tableCheckbox>
                  </td>
                  <td class="width-20p p-text-center">
                    <span>
                      <i
                        class="fal fa-trash-alt ic-red p-mr-3"
                        aria-hidden="true"
                        pTooltip="Delete"
                        tooltipPosition="top"
                        (click)="deleteRecurringInvoiceDetail(rowData)"></i>
                    </span>
                    <span>
                      <i
                        *ngIf="!selectedData['isRetainer'] && rowData['isReleaseTime'] && !rowData['IsNew'] && !rowData['ProjectMasterDuplicate'] && checkIfDetailIsSelected(rowData)"
                        class="fal fa-clock ic-black p-mr-3"
                        [disabled]="rowData['laboramount'] == null"
                        aria-hidden="true"
                        pTooltip="View Time Records"
                        tooltipPosition="top"
                        (click)="openTimeRecords(rowData)"></i>
                    </span>
                    <span>
                      <i
                        *ngIf="recurringInvoiceDetails.length - 1 == rowIndex && !rowData['isNew']"
                        class="fal fa-plus ic-blue p-mr-3"
                        (click)="addDetailRow(dtDetails)"
                        aria-hidden="true"
                        pTooltip="Add Row"
                        tooltipPosition="top"
                        id="new-row2"></i>
                    </span>
                  </td>
                  <td class="width-6p p-text-center">
                    <i
                      class="fal fa-bars ic-green ic-size-17"
                      pTooltip="{{'Reorder-Row' | translate}}"
                      pReorderableRowHandle></i>
                  </td>
                  <td
                    class="width-15p p-text-left p-text-nowrap p-text-truncate"
                    (click)="onRowEditInit1(rowData['RecurringInvoiceDetailID'], dtDetails, rowData)"
                    showDelay="800"
                    pTooltip="{{ rowData.StaffName }}"
                    tooltipPosition="top"
                    tooltipStyleClass="tooltip-no-wrap"
                    pInitEditableRow>
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <p-autoComplete
                          placeholder="{{ rowData.StaffNumber }}"
                          [(ngModel)]="rowData['StaffName']"
                          [suggestions]="filteredStaffSingle"
                          (onSelect)="handleDropStaffSelectClick($event,rowData, 'Staff')"
                          (completeMethod)="filterStaffItems($event,rowData)"
                          field="label"
                          [size]="30"
                          [minLength]="1"
                          [dropdown]="true"
                          appendTo="body">
                        </p-autoComplete>
                      </ng-template>
                      <ng-template pTemplate="output">
                        <div>{{ rowData.StaffNumber }}</div>
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td
                    pInitEditableRow
                    class="width-20p p-text-left p-text-truncate"
                    (click)="onRowEditInit1(rowData['RecurringInvoiceDetailID'], dtDetails, rowData)"
                    showDelay="800"
                    pTooltip="{{ rowData.EngagementName }}"
                    tooltipPosition="top"
                    tooltipStyleClass="tooltip-no-wrap">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <p-autoComplete
                          placeholder="{{rowData['EngagementName']}}"
                          [(ngModel)]="rowData['EngagementName']"
                          [suggestions]="filteredGroupsSingle"
                          (completeMethod)="filterGroupsItems($event,rowData)"
                          field="label"
                          (onSelect)="handleDropSelectClick($event,rowData,'EngagementName', true); recurringInvoiceChange(rowData);"
                          [size]="30"
                          [minLength]="1"
                          (onFocus)="$event.target.select()"
                          [dropdown]="true"
                          appendTo="body">
                        </p-autoComplete>
                      </ng-template>
                      <ng-template pTemplate="output">
                        <div style="margin: 8px 0 0 0" class="p-text-truncate">
                          {{rowData['EngagementName']}}
                        </div>
                      </ng-template>
                    </p-cellEditor>
                  </td>

                  <td class="width-15p p-text-center">
                    <p-checkbox
                      binary="true"
                      [(ngModel)]="rowData['IsExpenseRollUp']"
                      (onChange)="saveRecurringInvoiceDetail(rowData, rowData['RecurringInvoiceDetailID'], dtDetails, true)">
                    </p-checkbox>
                  </td>
                  <!-- Client Name -->
                  <td
                    pInitEditableRow
                    class="width-30p p-text-left p-text-truncate shortcut-desc"
                    (click)="onRowEditInit1(rowData['RecurringInvoiceDetailID'], dtDetails, rowData)"
                    [style.overflow]="rowData['IsRowEditing'] ? 'visible' : 'hidden'"
                    [ngClass]="{'new-row2': (recurringInvoiceDetails.length - 1 == rowIndex && !rowData['isNew'])}"
                    showDelay="800"
                    pTooltip="{{ rowData.InvoiceDescription }}"
                    tooltipPosition="top"
                    tooltipStyleClass="tooltip-no-wrap">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <mwl-text-input-autocomplete-container>
                          <textarea
                            pInputTextarea
                            [ngModelOptions]="{ standalone: true }"
                            [(ngModel)]="rowData['InvoiceDescription']"
                            class="width-100p text-left text-ellipse"
                            style="width: 100%; height: 40px; resize: vertical"
                            name="memo"
                            mwlTextInputAutocomplete
                            [findChoices]="mangoUtils.findChoices"
                            [getChoiceLabel]="mangoUtils.getChoiceLabel"
                            placeholder="{{ 'type_search_shortcuts' | translate }}"
                            (ngModelChange)="replaceShortcuts2($event, rowData, 'InvoiceDescription');recurringInvoiceChange(rowData);"
                            autofocus></textarea>
                        </mwl-text-input-autocomplete-container>
                      </ng-template>
                      <ng-template pTemplate="output"> {{rowData.InvoiceDescription}} </ng-template>
                    </p-cellEditor>
                  </td>

                  <td class="width-15p p-text-center">
                    <p-checkbox
                      *ngIf="
                                                !rowData[ 'ProjectMasterDuplicate' ]
                                            &&
                                                !selectedData[ 'isRetainer' ]
                                          "
                      binary="true"
                      name="isReleaseTime"
                      [(ngModel)]="rowData[ 'isReleaseTime' ]"
                      [disabled]="selectedData[ 'isRetainer' ]"
                      (onChange)="toggleReleaseTime( rowData );">
                    </p-checkbox>
                  </td>
                  <td
                    pInitEditableRow
                    class="width-20p p-text-center"
                    (click)="
                                        onRowEditInit1(
                                          rowData[ 'RecurringInvoiceDetailID' ],
                                          dtDetails,
                                          rowData
                                        )
                                      ">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <p-calendar
                          *ngIf="
                                                  !rowData[ 'ProjectMasterDuplicate' ]
                                              &&
                                                  !selectedData[ 'isRetainer' ]
                                            "
                          clearButtonStyleClass="recurring-calendar"
                          appendTo="body"
                          yearRange="1000:2099"
                          dateFormat="mm/dd/yy"
                          class="calender-component"
                          showButtonBar="true"
                          [(ngModel)]="rowData[ 'ReleaseDate' ]"
                          [ngModelOptions]="{ standalone: true }"
                          [monthNavigator]="true"
                          [yearNavigator]="true"
                          [disabled]="!rowData[ 'isReleaseTime' ]"
                          (ngModelChange)="
                                              recurringInvoiceChange( rowData );
                                              releaseDateChange( rowData );
                                            "
                          (onSelect)="onSelectDate( $event,rowData,'ReleaseDate' );">
                        </p-calendar>
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{ ( !rowData[ 'ProjectMasterDuplicate' ] && !selectedData[ 'isRetainer' ] )
                        ? ( rowData[ 'ReleaseDate' ] | date:'MM/dd/yyyy' ) : ( null ) }}
                      </ng-template>
                    </p-cellEditor>
                  </td>

                  <td class="width-10p p-text-right">
                    {{ rowData['ProjectMasterDuplicate']? null : (rowData['isReleaseTime'] &&
                    !selectedData['isRetainer']? rowData['totaltime'] : 0 | number: "1.2-2") }}
                  </td>
                  <!-- <td class="width-10p p-text-right">
                                            {{ rowData['ProjectMasterDuplicate']? null : (rowData['nonbillableamount'] | currency: "USD":true:"1.2-2") }}
                                        </td> -->
                  <td class="width-20p p-text-right">
                    {{ rowData['ProjectMasterDuplicate']? null : ((rowData['isReleaseTime'] &&
                    (rowData['laboramount'] || rowData['nonbillableamount']) &&
                    !selectedData['isRetainer']? (rowData['laboramount'] +
                    rowData['nonbillableamount']) : 0) | currency: "USD":true:"1.2-2") }}
                  </td>
                  <td class="width-15p p-text-right">
                    <span [style.color]="setWriteUpDownColor(rowData['writeupValue'])">
                      <span *ngIf="rowData['writeupValue'] < 0">
                        ({{ rowData['writeupValue'] | currency: "USD":"symbol":"1.2-2" }})
                      </span>
                      <span *ngIf="rowData['writeupValue'] >= 0"
                        >{{ rowData['writeupValue'] | currency: "USD":"symbol":"1.2-2" }}
                      </span>
                    </span>
                  </td>
                  <td
                    class="width-15p p-text-right ic-red"
                    pInitEditableRow
                    (click)="onRowEditInit1(rowData['RecurringInvoiceDetailID'], dtDetails, rowData)">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input
                          type="text"
                          pInputText
                          class="p-text-right"
                          id="amt_{{ rowData['uuid'] }}"
                          [(ngModel)]="rowData['Amount']"
                          (ngModelChange)="rowData['isAmountChanged']=true"
                          [ngModelOptions]="{ standalone: true }"
                          maxlength="14"
                          (focus)="$event.target.select();" />
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{ rowData['Amount'] | currency: "USD":true:"1.2-2" }}
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td class="width-15p p-text-center">
                    <button
                      *ngIf="editing"
                      pButton
                      pRipple
                      type="button"
                      pSaveEditableRow
                      icon="pi pi-check"
                      (click)="saveRecurringInvoiceDetail(rowData, rowData['RecurringInvoiceDetailID'], dtDetails, false);"
                      class="p-button-rounded p-button-text p-button-success p-mr-2"></button>
                    <button
                      *ngIf="editing"
                      pButton
                      pRipple
                      type="button"
                      pCancelEditableRow
                      icon="pi pi-times"
                      (click)="onRowEditCancel(rowData, null, 'detail')"
                      class="p-button-rounded p-button-text p-button-danger"></button>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="footer">
                <tr>
                  <td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                  <td class="width-35p p-text-right p-text-bold ic-red"></td>
                  <td class="width-95p p-text-right p-text-bold ic-red">Totals</td>
                  <td class="width-20p p-text-right p-text-bold ic-red">
                    {{ parentDetailFooter.totalTime | number: "1.2-2" }}
                  </td>
                  <!-- <td class="width-10p p-text-right p-text-bold ic-red">
                                            {{ parentDetailFooter.nonbillableamount | currency: "USD":true:"1.2-2" }}
                                        </td> -->
                  <td class="width-20p p-text-right p-text-bold ic-red">
                    {{ parentDetailFooter.laboramount + parentDetailFooter.nonbillableamount |
                    currency: "USD":true:"1.2-2" }}
                  </td>
                  <td class="width-15p p-text-right p-text-bold ic-red">
                    <span [style.color]="setWriteUpDownColor(parentDetailFooter.wuwd)">
                      <span *ngIf="parentDetailFooter.wuwd < 0">
                        ({{ parentDetailFooter.wuwd | currency: "USD":true:"1.2-2" }})
                      </span>
                      <span *ngIf="parentDetailFooter.wuwd >= 0">
                        {{ parentDetailFooter.wuwd | currency: "USD":true:"1.2-2" }}
                      </span>
                    </span>
                  </td>
                  <td class="width-15p p-text-right p-text-bold ic-red">
                    {{ parentDetailFooter.amount | currency: "USD":true:"1.2-2" }}
                  </td>
                  <td class="width-15p p-text-right p-text-bold ic-red"></td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td class="width-100p">
                    <p-message
                      severity="info"
                      text="{{'data_not_found' | translate}}."
                      styleClass="p-px-2"></p-message>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </p-panel>
        </div>
        <div *ngIf="recurringInvoiceExpenses.length > 0" class="p-col-12 p-mt-3 p-pr-0 manual">
          <p-panel header="Expenses" [toggleable]="true">
            <p-table
              [value]="recurringInvoiceExpenses"
              [rowHover]="true"
              dataKey="RecurringInvoiceExpenseID"
              selectionMode="single"
              [filterDelay]="0"
              styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
              [globalFilterFields]="globalFilterColumns"
              [scrollable]="true"
              [scrollHeight]="'calc(50vh - 100px)'"
              #dtExpenses
              editMode="row"
              [responsive]="true"
              [resizableColumns]="true"
              metaKeySelection="true"
              [(selection)]="selectedExpenses"
              (onHeaderCheckboxToggle)="toggleExpensesCheckBox($event)">
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th class="width-4p p-text-left">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                  </th>
                  <th class="width-10p p-text-center">{{'actions' | translate}}</th>
                  <th class="width-15p p-text-left" pSortableColumn="StaffNumber">
                    {{'user-title' | translate}}
                    <p-sortIcon field="StaffNumber"></p-sortIcon>
                  </th>
                  <th class="width-25p p-text-left">{{'Engagements' | translate}}</th>
                  <th class="width-25p p-text-left required-field">{{'Expense' | translate}}</th>
                  <th class="width-20p p-text-left">{{'memo' | translate}}</th>
                  <th class="width-10p p-text-right">{{'units' | translate}}</th>
                  <th class="width-10p p-text-right">{{'Cost' | translate}}</th>
                  <th class="width-10p p-text-right">{{'billable' | translate}}</th>
                  <th class="width-11p p-text-right"></th>
                </tr>
              </ng-template>
              <ng-template
                pTemplate="body"
                let-rowData
                let-columns="columns"
                let-rowIndex="rowIndex"
                let-editing="editing">
                <tr class="cursor-hand" [pEditableRow]="rowData">
                  <td class="width-4p p-text-left">
                    <p-tableCheckbox [value]="rowData" (click)="toggleExpensesCheckBox($event)">
                    </p-tableCheckbox>
                  </td>
                  <td class="width-10p p-text-left">
                    <span>
                      <i
                        class="fal fa-trash-alt ic-red p-mr-3"
                        aria-hidden="true"
                        pTooltip="Delete"
                        tooltipPosition="top"
                        (click)="deleteRecurringInvoiceExpense(rowData)"></i>
                    </span>
                    <span>
                      <i
                        *ngIf="recurringInvoiceExpenses.length - 1 == rowIndex  && !rowData['isNew']"
                        class="fal fa-plus ic-blue p-mr-3"
                        (click)="addExpenseRow(dtExpenses)"
                        aria-hidden="true"
                        pTooltip="Add Row"
                        tooltipPosition="top"
                        id="new-row3"></i>
                    </span>
                  </td>
                  <td
                    class="width-15p p-text-left p-text-nowrap p-text-truncate"
                    (click)="onRowEditInit1(rowData['RecurringInvoiceExpenseID'], dtExpenses, rowData)"
                    showDelay="800"
                    pTooltip="{{ rowData.StaffName }}"
                    tooltipPosition="top"
                    tooltipStyleClass="tooltip-no-wrap"
                    pInitEditableRow>
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <p-autoComplete
                          placeholder="{{ rowData.StaffNumber }}"
                          [(ngModel)]="rowData['StaffName']"
                          [suggestions]="filteredStaffSingle"
                          (onSelect)="handleDropStaffSelectClick($event,rowData, 'Staff')"
                          (completeMethod)="filterStaffItems($event,rowData)"
                          field="label"
                          [size]="30"
                          [minLength]="1"
                          [dropdown]="true"
                          appendTo="body">
                        </p-autoComplete>
                      </ng-template>
                      <ng-template pTemplate="output">
                        <div>{{ rowData.StaffNumber }}</div>
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td
                    class="width-25p p-text-left p-text-truncate"
                    pInitEditableRow
                    (click)="onRowEditInit1(rowData['RecurringInvoiceExpenseID'], dtExpenses, rowData)"
                    showDelay="800"
                    pTooltip="{{ rowData.EngagementName }}"
                    tooltipPosition="top"
                    tooltipStyleClass="tooltip-no-wrap">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <p-autoComplete
                          placeholder="{{rowData['EngagementName']}}"
                          [(ngModel)]="rowData['EngagementName']"
                          [suggestions]="filteredGroupsSingle"
                          (completeMethod)="filterGroupsItems($event,rowData)"
                          field="label"
                          (onSelect)="handleDropSelectClick($event,rowData,'EngagementName');recurringInvoiceChange(rowData);"
                          [size]="30"
                          [minLength]="1"
                          (onFocus)="$event.target.select()"
                          [dropdown]="true"
                          appendTo="body">
                        </p-autoComplete>
                      </ng-template>
                      <ng-template pTemplate="output">
                        <div style="margin: 8px 0 0 0">{{rowData['EngagementName']}}</div>
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <!-- Client Name -->
                  <td
                    pInitEditableRow
                    class="width-25p p-text-left p-text-truncate"
                    (click)="onRowEditInit1(rowData['RecurringInvoiceExpenseID'], dtExpenses, rowData)"
                    showDelay="800"
                    pTooltip="{{ rowData.ExpenseCode }}"
                    tooltipPosition="top"
                    tooltipStyleClass="tooltip-no-wrap">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <p-dropdown
                          panelStyleClass="dialog-dropdown"
                          name="ExpenseCodeID"
                          [options]="expenseList"
                          [(ngModel)]="rowData['ExpenseCodeID']"
                          (onChange)="handleDropSelectClick($event,rowData,'ExpenseCode');recurringInvoiceChange(rowData);"
                          placeholder="{{ 'select' | translate }} {{ 'Expense' | translate }}"
                          [filter]="true"
                          #ExpenseCodeID="ngModel"
                          appendTo="body">
                        </p-dropdown>
                      </ng-template>
                      <ng-template pTemplate="output">
                        <div style="margin: 8px 0 0 0">{{rowData['ExpenseCode']}}</div>
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td
                    class="width-20p p-text-left p-text-truncate shortcut-desc"
                    pInitEditableRow
                    (click)="onRowEditInit1(rowData['RecurringInvoiceExpenseID'], dtExpenses, rowData)"
                    [style.overflow]="rowData['IsRowEditing'] ? 'visible' : 'hidden'"
                    [ngClass]="{'new-row3': (recurringInvoiceExpenses.length - 1 == rowIndex  && !rowData['isNew'])}"
                    showDelay="800"
                    pTooltip="{{ rowData.ExpenseMemo }}"
                    tooltipPosition="top"
                    tooltipStyleClass="tooltip-no-wrap">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <mwl-text-input-autocomplete-container>
                          <textarea
                            pInputTextarea
                            [ngModelOptions]="{ standalone: true }"
                            [(ngModel)]="rowData['ExpenseMemo']"
                            class="width-100p text-left text-ellipse"
                            style="width: 100%; height: 40px; resize: vertical"
                            name="memo"
                            mwlTextInputAutocomplete
                            [findChoices]="mangoUtils.findChoices"
                            [getChoiceLabel]="mangoUtils.getChoiceLabel"
                            placeholder="{{ 'type_search_shortcuts' | translate }}"
                            (ngModelChange)="replaceShortcuts2($event, rowData, 'ExpenseMemo');recurringInvoiceChange(rowData);"
                            autofocus></textarea>
                        </mwl-text-input-autocomplete-container>
                      </ng-template>
                      <ng-template pTemplate="output"> {{rowData.ExpenseMemo}} </ng-template>
                    </p-cellEditor>
                  </td>
                  <td
                    class="width-10p p-text-right"
                    pInitEditableRow
                    (click)="onRowEditInit1(rowData['RecurringInvoiceExpenseID'], dtExpenses, rowData)">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input
                          type="text"
                          pInputText
                          class="p-text-right"
                          [(ngModel)]="rowData['Units']"
                          (ngModelChange)="recurringInvoiceChange(rowData);computeExpenseAmount(rowData)"
                          [ngModelOptions]="{ standalone: true }"
                          (focus)="$event.target.select();" />
                      </ng-template>
                      <ng-template pTemplate="output"> {{rowData['Units']}} </ng-template>
                    </p-cellEditor>
                  </td>
                  <td
                    class="width-10p p-text-right"
                    pInitEditableRow
                    (click)="onRowEditInit1(rowData['RecurringInvoiceExpenseID'], dtExpenses, rowData)">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input
                          type="text"
                          pInputText
                          class="p-text-right"
                          [(ngModel)]="rowData['Cost']"
                          (ngModelChange)="recurringInvoiceChange(rowData);computeExpenseAmount(rowData)"
                          [ngModelOptions]="{ standalone: true }"
                          (focus)="$event.target.select();" />
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{rowData['Cost'] | currency: "USD":true:"1.2-2"}}
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td class="width-10p p-text-right">
                    {{rowData['Amount'] | currency: "USD":true:"1.2-2"}}
                  </td>
                  <td class="width-11p p-text-center">
                    <button
                      *ngIf="editing"
                      pButton
                      pRipple
                      type="button"
                      pSaveEditableRow
                      icon="pi pi-check"
                      (click)="saveRecurringInvoiceExpense(rowData, rowData['RecurringInvoiceExpenseID'], dtExpenses);"
                      class="p-button-rounded p-button-text p-button-success p-mr-2"></button>
                    <button
                      *ngIf="editing"
                      pButton
                      pRipple
                      type="button"
                      pCancelEditableRow
                      icon="pi pi-times"
                      (click)="onRowEditCancel(rowData, null, 'expense')"
                      class="p-button-rounded p-button-text p-button-danger"></button>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="footer">
                <tr>
                  <td></td><td></td><td></td><td></td><td></td><td></td>
                  <td class="width-95p p-text-right p-text-bold ic-red"></td>
                  <td class="width-25p p-text-right p-text-bold ic-red">Totals</td>
                  <td class="width-10p p-text-right p-text-bold ic-red">
                    {{ parentExpenseFooter.amount | currency: "USD":true:"1.2-2" }}
                  </td>
                  <td class="width-11p p-text-right p-text-bold ic-red"></td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td class="width-100p">
                    <p-message
                      severity="info"
                      text="{{'data_not_found' | translate}}."
                      styleClass="p-px-2"></p-message>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </p-panel>
        </div>
      </div>
    </div>
  </div>
</p-sidebar>

<!-- Attach Invoice Memo -->
<p-dialog
  header="{{'invoice-attach-memo' | translate}}"
  [(visible)]="dialogDisplay"
  positionTop="1"
  [modal]="true"
  [style]="{width: '55vw'}">
  <div class="p-col-12 richtext">
    <div class="p-field p-col-12 p-py-0 p-my-0">
      <div class="p-text-right">
        <span
          ><a href="JavaScript:void(0);" (disable)="IsEditorChanges" (click)="enableEditor()"
            >{{btnText}}</a
          ></span
        >
      </div>
      <ejs-richtexteditor
        [height]="'calc(30vh - 100px)'"
        [enableResize]="false"
        [readonly]="IsDataReadOnly"
        [(value)]="invoiceList.InvoiceMessage"
        (change)="onEditorChange($event)">
      </ejs-richtexteditor>
    </div>
  </div>
  <div class="p-col-12 richtext">
    <div class="p-field p-col-12 p-py-0 p-my-0">
      <span class="label-text">{{'top-memo' | translate}}</span>
      <div>
        <ejs-richtexteditor
          [height]="'calc(30vh - 100px)'"
          [enableResize]="false"
          [(value)]="topMemoValue"
          (change)="onTopBottomEditorChange($event)">
        </ejs-richtexteditor>
      </div>
    </div>
  </div>
  <div class="p-col-12 richtext">
    <div class="p-field p-col-12 p-py-0 p-my-0">
      <span class="label-text">{{'bottom-memo' | translate}}</span>
      <div>
        <ejs-richtexteditor
          [height]="'calc(30vh - 100px)'"
          [enableResize]="false"
          [(value)]="bottomMemoValue"
          (change)="onTopBottomEditorChange($event)">
        </ejs-richtexteditor>
      </div>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <button
      type="button"
      pButton
      pRipple
      icon="fal fa-check"
      [disabled]="!IsTopBottomModified"
      (click)="saveTopBottomMemo()"
      label="{{'attach-memo' | translate}}"></button>
    <button
      type="button"
      pButton
      pRipple
      icon="fal fa-times"
      (click)="dialogDisplay=false"
      label="{{'cancel_button' | translate}}"></button>
  </ng-template>
</p-dialog>

<!-- Credit Card Dialog -->
<p-dialog
  [(visible)]="cardDialogDisplay"
  modal="modal"
  [responsive]="true"
  [resizable]="true"
  [positionTop]="0"
  (onHide)="closeCreditCardForm();"
  [showHeader]="false"
  [style]="{width: '50vw'}"
  [draggable]="true">
  <form class="education-form" role="form" novalidate [formGroup]="myPaymentForm" novalidate>
    <div class="p-grid">
      <div class="p-col-12">
        <!-- Card Header -->
        <div class="p-grid">
          <h3 class="p-col-6">{{'Credit-Card' | translate}}</h3>
          <div class="p-col-6 p-text-right">
            <span><i class="fab fa-cc-visa p-ml-2 ic-blue ic-size-22"></i></span>
            <span><i class="fab fa-cc-mastercard p-ml-2 ic-blue ic-size-22"></i></span>
            <span><i class="fab fa-cc-discover p-ml-2 ic-blue ic-size-22"></i></span>
            <span><i class="fab fa-cc-amex p-ml-2 ic-blue ic-size-22"></i></span>
          </div>
        </div>
        <div class="p-fluid">
          <div class="p-field p-grid p-mt-2">
            <div class="p-col-12">
              <p-checkbox
                binary="true"
                class="font-size-checkbox_mailconfig"
                label="{{'billing.Activate-Automatic-billing' | translate}}"
                formControlName="InvoiceReminderActivate">
              </p-checkbox>
            </div>
          </div>
          <!-- Card type -->
          <div class="p-grid p-mt-2">
            <div class="p-fluid p-col-12">
              <p-radioButton
                name="groupname"
                value="true"
                label="Visa/MasterCard/Discover"
                [(ngModel)]="isVisaCard"
                [ngModelOptions]="{standalone: true}">
              </p-radioButton>
              <p-radioButton
                name="groupname"
                value="false"
                label="Amex"
                class="p-ml-2"
                [(ngModel)]="isVisaCard"
                [ngModelOptions]="{standalone: true}">
              </p-radioButton>
            </div>
          </div>
          <!-- Amount -->
          <div class="p-field p-grid p-mt-2">
            <div class="p-col-12">
              <div>
                <label for="cardNumber" class="required-field label-text"
                  >{{'billing.Amount-to-Charge' | translate}}:</label
                >
                <div>
                  <input
                    type="text"
                    pInputText
                    class="form-control width-100"
                    formControlName="amount"
                    readonly="true" />
                </div>
              </div>
            </div>
          </div>
          <!-- Company Name -->
          <div class="p-field p-grid p-mt-2">
            <div class="p-col-12">
              <div>
                <label class="label-text" for="cupncode">{{'company' | translate}}</label>
                <div>
                  <input
                    type="text"
                    pInputText
                    class="form-control width-100"
                    formControlName="company"
                    readonly="true" />
                </div>
              </div>
            </div>
          </div>
          <!-- Card Holder Name -->
          <div class="p-grid">
            <div class="p-col-12">
              <div>
                <label for="cardNumber" class="label-text required-field"
                  >{{'billing-invoicing.name-on-card' | translate}}</label
                >
                <div>
                  <input
                    type="text"
                    pInputText
                    class="form-control width-100"
                    formControlName="nameOnCard"
                    autofocus />
                </div>
              </div>
            </div>
          </div>
          <!-- Card Number -->
          <div class="p-grid">
            <div class="p-col-12">
              <div>
                <label for="cardNumber" class="label-text required-field"
                  >{{'billing-invoicing.card-number' | translate}}</label
                >
                <div class="input-group" *ngIf="isVisaCard =='true'">
                  <p-inputMask
                    mask="9999-9999-9999-9999"
                    formControlName="cardno"
                    autofocus
                    styleClass="form-control width-100"></p-inputMask>
                  <span class="input-group-addon"><i class="fa fa-credit-card"></i></span>
                </div>
                <div class="input-group" *ngIf="isVisaCard =='false'">
                  <p-inputMask
                    mask="9999-999999-99999"
                    formControlName="cardno"
                    autofocus
                    styleClass="form-control width-100"></p-inputMask>
                  <span class="input-group-addon"><i class="fa fa-credit-card"></i></span>
                </div>
              </div>
            </div>
          </div>
          <!-- EXP Date CVV -->
          <div class="p-grid">
            <div class="p-col-7">
              <label for="cardExpiry" class="label-text required-field"
                ><span>{{'billing-invoicing.expiration-date' | translate}}</span></label
              >
              <p-inputMask
                mask="99/99"
                formControlName="expDate"
                styleClass="form-control width-100"
                autofocus>
              </p-inputMask>
            </div>
            <div class="p-col-5">
              <label class="label-text" for="cardCVC">CV {{'Code' | translate}}</label>
              <p-inputMask
                mask="999?9"
                formControlName="securityCode"
                styleClass="form-control width-100"
                autofocus></p-inputMask>
            </div>
          </div>
          <!-- Effective Start Date/ Interval -->
          <div class="p-grid">
            <div class="p-col-7">
              <label for="cardExpiry" class="label-text required-field"
                ><span>{{'billing.Effective-Start-Date' | translate}}</span></label
              >
              <p-calendar
                formControlName="InvoiceReminderStartDate"
                [monthNavigator]="true"
                [yearNavigator]="true"
                yearRange="2000:2099"
                class="form-control width-100"
                showButtonBar="true"></p-calendar>
            </div>
            <div class="p-col-5">
              <label class="label-text" for="cardCVC">{{'Interval' | translate}}</label>
              <p-inputMask
                mask="9?999"
                formControlName="securityCode"
                styleClass="form-control width-100"
                autofocus></p-inputMask>
            </div>
          </div>
        </div>
        <!-- Buttons -->
        <div class="p-grid p-mt-5 p-text-right">
          <div class="p-col-12">
            <button
              type="button"
              pButton
              [disabled]="(!isDialogFormValid)"
              (click)="singularProcessPayment();"
              label="{{'save_button' | translate}}"
              class="p-mr-2"></button>
            <button
              type="button"
              pButton
              class="p-mr-2"
              (click)="cardDialogDisplay=false;"
              label="{{'cancel_button' | translate}}"></button>
          </div>
        </div>
      </div>
    </div>
  </form>
</p-dialog>

<!-- Recurring Rules -->
<p-dialog
  [(visible)]="dialogRecurringPayments"
  positionTop="1"
  [modal]="true"
  [resizable]="false"
  showEffect="fade"
  [baseZIndex]="1"
  [maximizable]="false"
  [closable]="false"
  [draggable]="false"
  [responsive]="true"
  [style]="{width: '55vw'}">
  <p-header>
    <div>
      <span
        ><span><i class="fal fa-calendar-alt ic-blue p-pr-3"></i></span>{{ 'scheduled-payments' |
        translate }} | {{recurringRowData['ClientName']}}</span
      >
    </div>
  </p-header>

  <div class="p-pt-0">
    <div class="p-grid">
      <div class="p-col">
        <div class="p-formgroup-inline">
          <div class="p-field p-col-6 p-pl-0">
            <label for="Count" class="p-d-block label-text">{{'frequency' | translate}}</label>
            <div>{{recurringRowData['Frequency']}}</div>
          </div>
          <div class="p-field p-col-6 p-pl-0">
            <label for="Count" class="p-d-block label-text">{{'scheduled-date' | translate}}</label>
            <div>{{recurringRowData['ScheduledDate'] | date: "MM/dd/yyyy"}}</div>
          </div>
        </div>
        <div class="p-formgroup-inline">
          <div class="p-field p-col-6 p-pl-0">
            <label for="Count" class="p-d-block label-text"
              >Count
              <i
                class="fa fa-question-circle ic-blue"
                pTooltip="How many occurrences will be generated."
                tooltipPosition="top"
                tooltipStyleClass="tooltip-width-300"></i>
            </label>
            <p-inputNumber
              [(ngModel)]="recurringRowData['count']"
              mode="decimal"
              [showButtons]="true"
              inputId="minmax-buttons"
              [min]="1"
              [max]="100"
              [inputStyle]="{width: '80%'}"
              [disabled]="isNeverEnds"
              (onBlur)="validatePaymentForm()">
            </p-inputNumber>
          </div>
          <div class="p-field p-col-6 p-pl-0">
            <label for="Count" class="p-d-block label-text"> &nbsp; </label>
            <p-checkbox
              [(ngModel)]="isNeverEnds"
              class="p-mt-2 ic-size-11"
              (onChange)="validatePaymentForm()"
              binary="true"
              inputId="binary"
              label="{{'never-ends' | translate}}"></p-checkbox>
          </div>
        </div>
        <div class="p-formgroup-inline">
          <div class="p-field p-col-4 p-pl-0">
            <label for="Count" class="p-d-block label-text"
              >{{'Accounts.invoice-amount' | translate}}
              <i
                class="fa fa-question-circle ic-blue"
                pTooltip="Sales Tax : {{this.salesTaxObj['serviceTax'] | currency:'USD':'symbol':'1.2-2'}}"
                tooltipPosition="top"></i
            ></label>
            <div>{{defaultStaxObj['total'] | currency:'USD':'symbol':'1.2-2'}}</div>
          </div>
          <div
            class="p-field p-col-4 p-pl-0"
            *ngIf="sTaxProfile && companyDetails && companyDetails.isSurchargeEnabled && defaultStaxObj['SurChargeAmount'] > 0">
            <label for="Count" class="p-d-block label-text">{{'surcharge' | translate}}</label>
            <div>{{defaultStaxObj['SurChargeAmount'] | currency:'USD':'symbol':'1.2-2'}}</div>
          </div>
          <div
            class="p-field p-col-4 p-pl-0"
            *ngIf="sTaxProfile && companyDetails && companyDetails.isSurchargeEnabled && defaultStaxObj['SurChargeAmount'] > 0">
            <label for="Count" class="p-d-block label-text">{{'total-amount' | translate}}</label>
            <div class="ic-red">
              {{defaultStaxObj['withSurcharge'] | currency:'USD':'symbol':'1.2-2'}}
            </div>
          </div>
          <div
            class="p-field p-col-12 p-pl-0"
            *ngIf="sTaxProfile && companyDetails && companyDetails.isSurchargeEnabled && defaultStaxObj['SurChargeAmount'] == 0">
            <div class="surcharge-msg p-pt-2">
              <p-message
                severity="info"
                text="{{'surcharge-msg' |
                    translate}}"></p-message>
            </div>
          </div>
        </div>
      </div>
      <div class="p-col">
        <div class="card p-py-0">
          <label for="paymenttype" class="label-text p-d-block p-pt-2"
            >{{ 'Accounts.payment-type' | translate }}</label
          >
          <p-dropdown
            [options]="cardOptionsList"
            [(ngModel)]="selectedModeOfPayment"
            [style]="{'width':'75%'}"
            [ngModelOptions]="{ standalone: true }"
            placeholder="Select Payment Option"
            (onChange)="changePaymentType(recurringRowData)">
          </p-dropdown>
        </div>
        <div
          class="card p-py-0"
          *ngIf="sTaxProfile == null && selectedModeOfPayment && addNewCrCardDialog">
          <form role="form" [formGroup]="myPaymentForm" autocomplete="off" novalidate>
            <div class="p-fluid">
              <div class="p-formgroup-inline">
                <div class="p-field p-col-6 p-pl-0">
                  <label for="cardNumber" class="required-field label-text"
                    >{{ 'fname' | translate }}</label
                  >
                  <input type="text" pInputText formControlName="fname" autofocus />
                </div>
                <div class="p-field p-col-6 p-pr-0">
                  <label for="cardNumber" class="required-field label-text"
                    >{{ 'lname' | translate }}</label
                  >
                  <input type="text" pInputText formControlName="lname" autofocus />
                </div>
              </div>
              <div *ngIf="isCrCard">
                <!--address -->
                <div class="p-field">
                  <label for="cardNumber" class="required-field label-text"
                    >{{ 'user.user_address' | translate }}</label
                  >
                  <span class="p-text-italic p-ml-2 ic-red" style="font-size: 11px"
                    >(Address must match billing address on Credit Card)</span
                  >
                  <input type="text" pInputText formControlName="address" autofocus />
                </div>
                <div class="p-formgroup-inline">
                  <div class="p-field p-col-4 p-pl-0">
                    <label for="cardExpiry" class="required-field label-text"
                      ><span>{{ 'client.city' | translate }}</span></label
                    >
                    <input
                      type="text"
                      formControlName="city"
                      pInputText
                      autocomplete="nope"
                      maxlength="50" />
                  </div>
                  <div class="p-field p-col-4 p-pr-0">
                    <label for="cardExpiry" class="required-field label-text"
                      ><span>{{ 'client.state' | translate }}</span></label
                    >
                    <input
                      type="text"
                      formControlName="state"
                      pInputText
                      autocomplete="nope"
                      maxlength="2" />
                  </div>
                  <div class="p-field p-col-4 p-pr-0">
                    <label class="label-text required-field">{{ 'client.zip' | translate }}</label>
                    <input
                      type="text"
                      pInputText
                      class="p-text-right"
                      formControlName="zip"
                      maxlength="10"
                      (blur)="focusCard(0,'card_number')"
                      autocomplete="nope" />
                  </div>
                </div>
                <!-- Card Number -->
                <div class="p-field">
                  <label for="cardNumber" class="required-field label-text"
                    >{{ 'billing-invoicing.card-number' | translate }}</label
                  >
                  <div id="fattjs-number" style="height: 35px"></div>
                </div>
                <!-- EXP Date CVV -->
                <div class="p-formgroup-inline">
                  <div class="p-field p-col-6 p-pl-0">
                    <label for="cardExpiry" class="required-field label-text"
                      ><span>{{ 'billing-invoicing.expiration-date' | translate }}</span></label
                    >
                    <p-inputMask
                      mask="99/9999"
                      formControlName="expDate"
                      autofocus
                      (onBlur)="focusCard(1,'cvv')">
                    </p-inputMask>
                  </div>
                  <div class="p-field p-col-6 p-pr-0">
                    <label for="cardCVC" class="label-text">{{ 'cv-code' | translate }}</label>
                    <div id="fattjs-cvv" style="height: 35px"></div>
                  </div>
                </div>
              </div>
              <div *ngIf="!isCrCard">
                <div class="p-field">
                  <label for="amt" class="required-field label-text p-d-block"
                    >Account to Debit</label
                  >
                  <p-dropdown [options]="transCodeTypes" formControlName="TransCode"> </p-dropdown>
                </div>
                <div class="p-field">
                  <label for="amt" class="required-field label-text p-d-block"
                    >Routing Number</label
                  >
                  <input
                    type="text"
                    pInputText
                    formControlName="RoutingNumber"
                    autofocus
                    (blur)="validatePaymentForm()" />
                </div>
                <div class="p-field">
                  <label for="amt" class="required-field label-text p-d-block"
                    >Account Number</label
                  >
                  <input
                    type="text"
                    pInputText
                    formControlName="AccountNumber"
                    autofocus
                    (blur)="validatePaymentForm()" />
                </div>
                <div class="p-field">
                  <p-radioButton
                    name="groupname"
                    value="false"
                    [(ngModel)]="isBusiness"
                    [ngModelOptions]="{standalone: true}"
                    label="Personal"
                    labelStyleClass="p-pr-2">
                  </p-radioButton>
                  <p-radioButton
                    name="groupname"
                    value="true"
                    [(ngModel)]="isBusiness"
                    [ngModelOptions]="{standalone: true}"
                    label="Business">
                  </p-radioButton>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="card p-py-0" *ngIf="!addNewCrCardDialog && sTaxProfile">
          <div class="p-field p-col-12 p-pl-0">
            <label *ngIf="isCrCard" for="Name" class="p-d-block label-text"
              >{{'billing-invoicing.name-on-card' | translate}}</label
            >
            <label *ngIf="!isCrCard" for="Name" class="p-d-block label-text"
              >{{'user.Name' | translate}}</label
            >
            <div>{{sTaxProfile.NameOnCard}}</div>
          </div>
          <div class="p-field p-col-12 p-pl-0">
            <label *ngIf="isCrCard" for="digits" class="p-d-block label-text">
              <span *ngIf="!isDebitCard">
                <label for="uname" class="label-text"
                  >{{ 'last-4digits-crcard' | translate }}</label
                >
              </span>
              <span *ngIf="isDebitCard">
                <label for="uname" class="label-text"
                  >{{ 'last-4digits-dbcard' | translate }}</label
                >
              </span>
            </label>
            <label *ngIf="!isCrCard" for="digits" class="p-d-block label-text"
              >{{'last-4digits-bank' | translate}}</label
            >
            <div>
              <span class="p-text-bold p-mr-3"> ****{{sTaxProfile.CardNo}}</span>
              <span *ngIf="!isCrCard && sTaxProfile['CardType']">
                <i
                  class="far fa-money-check ic-green ic-size-18 crcardimg"
                  tooltipPosition="top"
                  pTooltip="{{sTaxProfile['CardType']}}"
                  showDelay="800"
                  tooltipStyleClass="tooltip-no-wrap"></i>
              </span>
              <span
                *ngIf="isCrCard && sTaxProfile['CardType'] == 'visa'"
                tooltipPosition="top"
                title="Visa"
                showDelay="800"
                tooltipStyleClass="tooltip-no-wrap"
                class="crcardimg">
                <img src="assets/mango/svg/visa.svg" />
              </span>
              <span
                *ngIf="isCrCard && sTaxProfile['CardType'] == 'mastercard'"
                tooltipPosition="top"
                title="Mastercard"
                showDelay="800"
                tooltipStyleClass="tooltip-no-wrap"
                class="crcardimg">
                <img src="assets/mango/svg/mastercard.svg" />
              </span>
              <span
                *ngIf="isCrCard && sTaxProfile['CardType'] == 'discover'"
                tooltipPosition="top"
                title="Discover"
                showDelay="800"
                tooltipStyleClass="tooltip-no-wrap"
                class="crcardimg">
                <img src="assets/mango/svg/discover.svg" />
              </span>
              <span
                *ngIf="isCrCard && sTaxProfile['CardType'] == 'amex'"
                tooltipPosition="top"
                class="crcardimg"
                title="Amex"
                showDelay="800"
                tooltipStyleClass="tooltip-no-wrap">
                <img src="assets/mango/svg/amex.svg" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <!-- <button pButton pRipple
                label="{{ 'authorize-card' | translate }} & {{'create' | translate}} {{'Schedule' | translate}}"
                class="p-button-sm p-pr-3 p-mr-2" [disabled]="isProcessing"
                *ngIf="selectedModeOfPayment && addNewCrCardDialog && !recurringRowData['schedule_id'] && companyDetails && !companyDetails.isSurchargeEnabled"
                (click)="isCrCardisCrCard(recurringRowData,false)" icon="fa fa-check">
            </button> -->
    <button
      pButton
      pRipple
      label="{{ 'authorize-card' | translate }}"
      class="p-button-sm p-pr-3 p-mr-2"
      [disabled]="isProcessing"
      *ngIf="selectedModeOfPayment && isCrCard && addNewCrCardDialog"
      (click)="addSTaxNewCard(recurringRowData,false)"
      icon="fa fa-check"></button>
    <button
      pButton
      pRipple
      label="{{ 'authorize-bank' | translate }}"
      class="p-button-sm p-pr-3 p-mr-2"
      [disabled]="isProcessing"
      *ngIf="selectedModeOfPayment && !isCrCard && addNewCrCardDialog"
      (click)="addSTaxNewCard(recurringRowData,false)"
      icon="fa fa-check"></button>
    <!-- <button pButton pRipple
                label="{{ 'authorize-card' | translate }} & {{'update_button' | translate}} {{'Schedule' | translate}}"
                class="p-button-sm p-pr-3 p-mr-2" [disabled]="isProcessing"
                *ngIf="selectedModeOfPayment && addNewCrCardDialog && recurringRowData['schedule_id'] && companyDetails && !companyDetails.isSurchargeEnabled"
                (click)="addSTaxNewCard(recurringRowData,true)" icon="fa fa-check">
            </button> -->
    <button
      pButton
      pRipple
      (click)="addNewCreditCard()"
      label="{{ 'Add-New-Card' | translate }}"
      class="p-button-sm p-pr-3 p-mr-2"
      icon="fal fa-plus"
      *ngIf="!addNewCrCardDialog && sTaxProfile && isCrCard"></button>
    <button
      pButton
      pRipple
      (click)="addNewBankCard()"
      label="{{ 'add-new-bank' | translate }}"
      class="p-button-sm p-pr-3 p-mr-2"
      icon="fal fa-plus"
      *ngIf="!addNewCrCardDialog && sTaxProfile && !isCrCard"></button>
    <button
      *ngIf="!addNewCrCardDialog && !recurringRowData['schedule_id']"
      type="button"
      pButton
      pRipple
      icon="fal fa-plus"
      (click)="createScheduledInvoice(recurringRowData)"
      [disabled]="isProcessing"
      label="{{'create' | translate}} {{'Schedule' | translate}}"></button>
    <button
      *ngIf="!addNewCrCardDialog && recurringRowData['schedule_id']"
      type="button"
      pButton
      pRipple
      icon="fa fa-check"
      (click)="updateScheduledInvoice(recurringRowData,true)"
      [disabled]="isProcessing"
      label="{{'update_button' | translate}} {{'Schedule' | translate}}"></button>
    <button
      *ngIf="!addNewCrCardDialog &&  recurringRowData['schedule_id']"
      type="button"
      pButton
      pRipple
      icon="fal fa-trash-alt"
      (click)="deleteScheduledInvoice(recurringRowData['RecurringInvoiceHeaderID'],recurringRowData['schedule_id'],true)"
      label="{{'stoprecurringpayment' | translate}}"></button>
    <button
      type="button"
      pButton
      pRipple
      icon="fal fa-times"
      (click)="closeStaxForm(recurringRowData)"
      label="{{'close' | translate}}"></button>
  </ng-template>
</p-dialog>
<!-- show Recurring Invoice History -->
<p-sidebar
  [(visible)]="showRecurringInvoiceHistoryDialog"
  position="right"
  (onHide)="hideRecurringHistorySideBar()"
  [style]="{'min-width':'60%','min-height':'100%','background':'#8e8f92','overflow': 'auto'}">
  <div class="card" style="min-height: calc(100vh - 34px)">
    <div class="control-label header-color text-nowrap p-col-12">
      <h4 class="label-text">{{ 'billing-invoicing.invoice-history' | translate }}</h4>
    </div>
    <div class="p-col-12 p-py-0 p-px-4 p-mx-2 p-mb-2">
      <p-table
        [value]="RecurringInvoiceHistory"
        [rows]="5"
        [pageLinks]="3"
        sortField="updated_at"
        [sortOrder]="-1"
        [rowHover]="true"
        dataKey="id"
        [responsive]="true"
        metaKeySelection="true"
        styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
        editMode="row"
        #timeRecordsDt
        [columns]="selectedColumns">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th class="width-10p p-text-center" pSortableColumn="created_at">
              {{ 'Date' | translate }}
              <p-sortIcon field="created_at"></p-sortIcon>
            </th>
            <th class="width-20p p-text-left">{{ 'Transaction Reference' | translate }}</th>
            <th class="width-10p p-text-center">
              {{ 'Payment Method' | translate }}
              <p-sortIcon field="bankType"></p-sortIcon>
            </th>
            <th class="width-8p p-text-center">{{ 'status' | translate }}</th>
            <th class="width-8p p-text-right">
              {{ 'Amount' | translate }}
              <p-sortIcon field="total"></p-sortIcon>
            </th>
            <th class="width-8p p-text-right" *ngIf="superAdminVisible.includes(loginCompanyId)">
              {{ 'Create' | translate }}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr class="cursor-hand">
            <td class="width-10p p-text-center">{{rowData.created_at | date:'MM/dd/yyyy'}}</td>
            <td class="width-20p p-text-left">{{rowData.id}}</td>
            <td class="width-10p p-text-center p-text-capitalize">
              <span *ngIf="!rowData.payment_method.card_type">
                <i
                  class="far fa-money-check ic-green ic-size-22"
                  tooltipPosition="top"
                  pTooltip="{{rowData.payment_method.bank_type}}"
                  showDelay="800"
                  tooltipStyleClass="tooltip-no-wrap"></i>
              </span>
              <span
                *ngIf="rowData.payment_method.card_type == 'visa'"
                tooltipPosition="top"
                class="crcardimg"
                pTooltip="Visa"
                showDelay="800"
                tooltipStyleClass="tooltip-no-wrap">
                <img src="assets/mango/svg/visa.svg" />
              </span>
              <span
                *ngIf="rowData.payment_method.card_type == 'mastercard'"
                tooltipPosition="top"
                class="crcardimg"
                pTooltip="Mastercard"
                showDelay="800"
                tooltipStyleClass="tooltip-no-wrap">
                <img src="assets/mango/svg/mastercard.svg" />
              </span>
              <span
                *ngIf="rowData.payment_method.card_type == 'discover'"
                tooltipPosition="top"
                class="crcardimg"
                pTooltip="Discover"
                showDelay="800"
                tooltipStyleClass="tooltip-no-wrap">
                <img src="assets/mango/svg/discover.svg" />
              </span>
              <span
                *ngIf="rowData.payment_method.card_type == 'amex'"
                tooltipPosition="top"
                class="crcardimg"
                pTooltip="Amex"
                showDelay="800"
                tooltipStyleClass="tooltip-no-wrap">
                <img src="assets/mango/svg/amex.svg" />
              </span>
            </td>
            <td class="width-4p p-text-center">
              <span
                *ngIf="rowData.success == false"
                tooltipPosition="top"
                pTooltip="{{rowData.message}}"
                tooltipStyleClass="tooltip-width-800">
                <i class="fas fa-exclamation-circle ic-red ic-size-22"></i>
              </span>
              <span *ngIf="rowData.success == true" tooltipPosition="top">
                <i class="fas fa-check-circle ic-green ic-size-22"></i>
              </span>
            </td>
            <td class="width-8p p-text-right">
              {{rowData.total | currency : 'USD' : true : '1.2-2'}}
            </td>
            <td
              class="width-4p p-text-right"
              *ngIf="superAdminVisible.includes(loginCompanyId) && rowData.success == true">
              <i
                class="fal fa-plus p-mr-3 ic-size-15 ic-red"
                tooltipStyleClass="tooltip-no-wrap"
                pTooltip="Recreate Billing Header/Detail & Payment Header/Detail"
                aria-hidden="true"
                (click)="reGenarateMangoData(rowData,loginCompanyId)"></i>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="5" *ngIf="!superAdminVisible.includes(loginCompanyId)">
              <p-message
                severity="info"
                text="{{ 'data_not_found' | translate }}."
                styleClass="p-px-2">
              </p-message>
            </td>
            <td colspan="6" *ngIf="superAdminVisible.includes(loginCompanyId)">
              <p-message
                severity="info"
                text="{{ 'data_not_found' | translate }}."
                styleClass="p-px-2">
              </p-message>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="footer">
          <tr *ngIf="RecurringInvoiceHistory.length > 1">
            <td class="width-10p"></td>
            <td class="width-20p"></td>
            <td class="width-10p"></td>
            <td class="width-8p p-text-right p-text-bold ic-red">Totals</td>
            <td class="width-8p p-text-right p-text-bold ic-red">
              {{ recurringInvoiceHistoryTotal | currency:'USD':'symbol':'1.2-2' }}
            </td>
            <td class="width-10p" *ngIf="superAdminVisible.includes(loginCompanyId)"></td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</p-sidebar>
