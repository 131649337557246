<div class="create-template common-dialog" (click)="checkIfRemoveEdit();">
    <div class="layout-content">
        <span class="pull-right p-mr-3 clientform-btn-top">
            <button pButton pRipple label="{{'save_button' | translate}}" icon="fal fa-check"
                class="p-mr-2 p-button-sm" (click)="saveAllTasks()" [disabled]="(!isFormValid || !isLineItemsValid || !isFormChanged)">
            </button>
            <p-button pRipple label="{{'Clone' | translate}}" icon="fal fa-clone" styleClass="p-button-sm"
                pTooltip="{{ 'clone_template_tooltip' | translate }}" showDelay="800" class="p-button-help p-pr-2"
                (click)="onClone()" *ngIf="isEditTS"></p-button>
            <p-button pRipple label="{{'dashboard' | translate}}" icon="fal fa-tachometer-alt-fastest"
                styleClass="p-button-sm" class="p-button-help p-pr-2" routerLink="/project-management/dashboard">
            </p-button>
            <p-button pRipple label="{{'Clients' | translate}}" icon="fal fa-users" styleClass="p-button-sm "
                class="p-button-help p-pr-2" routerLink="/project-management/clientHistory"></p-button>
            <p-button pRipple label="{{ 'Projects' | translate }}/{{ 'Tasks' | translate }} List" icon="fal fa-building" styleClass="p-button-sm "
                class="p-button-help p-pr-2" routerLink="/project-management/projectList"></p-button>
            <p-button pRipple label="{{'PM-Settings' | translate}}" icon="fal fa-cog" styleClass="p-button-sm"
                class="p-button-help p-pr-2" routerLink="/project-management/settings"></p-button>
            <!-- <p-button pRipple label="{{'Add-Tasks' | translate}}" icon="fal fa-plus" styleClass="p-button-sm"
                class="p-button-help p-pr-2" (click)="addRow()" *ngIf="tasksDataSource.length == 0"></p-button> -->
        </span>
        <div class="p-grid">
            <div class="p-col-4">
                <div class="card p-ml-3">

                    <div class="p-d-flex p-mx-2 p-mb-1">
                        <div [ngClass]="{'editing-record': isEditTS, 'adding-record': !isEditTS }">
                            {{ (isEditTS ? 'Editing' : 'Adding') | translate }}
                        </div>
                    </div>
                    <form role="form" [formGroup]="myCreateForm" novalidate>
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-col-12">
                                <div class="p-formgroup-inline">
                                    <div class="p-field p-col-6">
                                        <div class="p-d-flex p-jc-between p-mb-2">
                                            <label class="required-field label-text">{{'pm.Project-Template-Name' |
                                                translate}}</label>
                                            <i class="fal fa-table ic-green cursor-hand ic-size-18 p-pr-2"
                                                tooltipPosition="top"
                                                tooltipStyleClass="tooltip-no-wrap"
                                                pTooltip="{{ 'pm.show_wildcards_table' | translate }}" pRipple
                                                (click)="onOpenWildcardsDialog()"></i>
                                        </div>
                                        <input type="text" pInputText formControlName="TemplateName" maxlength="100"
                                            autocomplete="off" [disabled]="true" (input)="onChangeForm()">
                                    </div>
                                    <div class="p-field p-col-6">
                                        <label class="label-text">{{'due-date' | translate}}</label>
                                        <p-calendar name="dateCreated" (onSelect)="onChangeForm()" showButtonBar="true" [monthNavigator]="true"
                                            [yearNavigator]="true" yearRange="2000:2099" formControlName="DueDate">
                                        </p-calendar>
                                    </div>
                                </div>
                                <div class="p-formgroup-inline">
                                    <div class="p-field p-col-6">
                                        <label class="label-text" style="width: 100%;">
                                            {{'Billing_Partner' | translate}}
                                            <i class="fa fa-question-circle ic-blue"
                                                pTooltip="{{ 'optional_override_client' | translate }}"
                                                showDelay="800"></i>
                                        </label>
                                        <p-dropdown [options]="AllManagerTypes" placeholder="Select Partner"
                                            formControlName="ManagerID" (onChange)="onChangeForm()">
                                        </p-dropdown>
                                    </div>
                                    <div class="p-field p-col-6">
                                        <label class="label-text">{{'Turnaround-Days' | translate}}</label>
                                        <p-inputNumber formControlName="TurnAround" placeholder="0"
                                            (focus)="$event.target.select()" maxlength="3" (onInput)="onChangeForm()"></p-inputNumber>
                                    </div>
                                </div>
                                <div class="p-formgroup-inline">
                                    <div class="p-field p-col-6">
                                        <label class="label-text" style="width: 100%;">
                                            {{'manager' | translate}}
                                            <i class="fa fa-question-circle ic-blue"></i>
                                        </label>
                                        <p-dropdown [options]="AllManagerTypes" placeholder="Select Manager"
                                            formControlName="UserAssignedID" (onChange)="onChangeForm()">
                                        </p-dropdown>
                                    </div>
                                    <div class="p-field p-col-6">
                                        <div class="p-d-flex p-jc-between">
                                            <label class="label-text">{{'repeat' | translate}}</label>
                                            <span class="pull-right">
                                                <i *ngIf="myCreateForm.value['Repeat'] === 'custom'"
                                                    class="fal fa-repeat ic-red cursor-hand ic-size-13"
                                                    tooltipPosition="top" pTooltip="{{ 'view_custom' | translate }}"
                                                    pRipple
                                                    (click)="rruleComponent.isDisplayRuleDialog = true;rruleComponent.ngOnInit();"></i>
                                            </span>
                                        </div>
                                        <div class="p-mt-2">
                                            <p-dropdown [options]="dateRangeList"
                                                [disabled]="!!!myCreateForm.value['DueDate']" (onChange)="onChangeRepeat()"
                                                formControlName="Repeat">
                                            </p-dropdown>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-formgroup-inline"
                                    *ngIf="ddmSettingsObj?.isTrackingTimebyProjects && ddmSettingsObj?.isBudgetingProjectLevel">
                                    <div class="p-field p-col-6">
                                        <label
                                            class="label-text {{ ddmSettingsObj?.isBudgetingProjectLevel ? 'required-field' : ''}}"
                                            style="width: 100%;">
                                            {{'engagement.type' | translate}}
                                        </label>
                                        <p-dropdown *ngIf="engagementList && engagementList.length" optionLabel="label" optionValue="value"  [options]="engagementList" formControlName="EngagementTypeID"
                                           name="EngagementTypeID" (onChange)="onChangeEngagement()">
                                        </p-dropdown>
                                    </div>
                                    <div class="p-field p-col-6 p-mt-5" >
                                        <!-- <p-checkbox formControlName="isTrackTimeTaskLevel" [binary]="true"
                                            label="Enable Task Budget" class="label-text">
                                        </p-checkbox> -->
                                        <button type="button" pButton pRipple label="Show Budget"
                                            icon="fal fa-ballot"
                                            [disabled]="!(!isEngagementChanged && !!myCreateForm.controls['EngagementTypeID'].value)"
                                            class="p-button-sm" (click)="onShowTasksBudget()">
                                        </button>
                                        <label *ngIf="isEngagementChanged && !!myCreateForm.controls['EngagementTypeID'].value" class="ic-size-10 ic-red">{{ 'budgets.should-save-first' | translate }}</label>
                                        <label *ngIf="!!!myCreateForm.controls['EngagementTypeID'].value" class="ic-size-10 ic-red">{{ 'budgets.should-choose-engagement-first' | translate }}</label>
                                    </div>
                                </div>
                                <div class="p-formgroup-inline p-mt-3">
                                    <div class="p-field p-col-6">
                                        <p-checkbox formControlName="isReviewRequired" [binary]="true"
                                            label="{{'pm.Requires-Final-Review' | translate}}" class="label-text" pTooltip="{{'pm.Requires-Final-Review-tooltip' | translate}}"
                                            tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top" (onChange)="onChangeForm()">
                                        </p-checkbox>
                                    </div>
                                    <div class="p-field p-col-6">
                                        <p-checkbox formControlName="IsCompleteInOrder" [binary]="true"
                                            label="{{'pm.complete-tasks-in-order' | translate}}" class="label-text"
                                            pTooltip="{{'pm.Complete-Tasks-tooltip' | translate}}"
                                            tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top"
                                            (onChange)="onChangeForm()"
                                            >
                                        </p-checkbox>
                                    </div>
                                    <div class="p-field p-col-6 p-mt-2">
                                        <p-checkbox formControlName="automaticComputeDuedate" [binary]="true"
                                            label="{{ 'auto_compute_due_date' | translate }}"
                                            class="label-text" pTooltip="{{'auto_compute_due_date' | translate}}"
                                            tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top" (onChange)="onChangeForm()">
                                        </p-checkbox>
                                    </div>
                                    <div class="p-field p-col-6 p-mt-2">
                                        <p-checkbox formControlName="isPreviousPeriodYearPolicy" [binary]="true"
                                            label="{{ 'pm.previous_period_year_policy' | translate }}"
                                            [disabled]="myCreateForm.value['Repeat'] === 'custom' ||
                                                myCreateForm.value['Repeat'] === '' ||
                                                myCreateForm.value['Repeat'] === 'none' ||
                                                myCreateForm.value['Repeat'] == null"
                                            [style.cursor]="(myCreateForm.value['Repeat'] === 'custom' ||
                                                myCreateForm.value['Repeat'] === '' ||
                                                myCreateForm.value['Repeat'] === 'none' ||
                                                myCreateForm.value['Repeat'] == null) ? 'not-allowed' : 'pointer'"
                                            class="label-text" pTooltip="{{'pm.previous_period_year_policy' | translate}}"
                                            tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top" (onChange)="onChangeForm()">
                                        </p-checkbox>
                                    </div>
                                </div>
                                <div class="p-field p-mt-2 p-ml-2">
                                    <label class="label-text">{{'pm.Project-Memo' | translate}}</label>
                                    <app-mentions [rows]="5" [(inputText)]="myCreateForm.controls['ProjectMemo'].value" (inputTextChange)="onProjectMemoChange($event)"
                                        style="width:100% !important; resize: vertical;"></app-mentions>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="p-col-8">
                <div class="card p-mr-3">
                    <p-table [value]="tasksDataSource" [responsive]="true" dataKey="TaskRow" [reorderableColumns]="true"
                        styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                        [scrollable]="true" [scrollHeight]="'calc(70vh - 100px)'" editMode="row" [rowHover]="true"
                        (onRowReorder)="onRowChange($event)" #dt>
                        <ng-template pTemplate="caption">
                            <div class="table-header">
                                <span class="p-d-flex p-jc-end">
                                    <button pRipple pButton icon="fal fa-plus" class="p-button-sm"
                                        (click)="addRow('new-row');" label="{{'Add-Row' | translate}}"
                                        pTooltip="{{'mgmt-add-new' | translate}}" showDelay="800"
                                        tooltipPosition="top"></button>
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="width-3p p-text-center">
                                    {{'actions' | translate}}
                                </th>
                                <th class="width-3p p-text-center">

                                </th>
                                <th class="width-3p p-text-center">
                                    {{'task' | translate}} #
                                </th>
                                <th class="width-8p p-text-left required-field" pReorderableColumn>
                                    {{'Task-Description' | translate}}
                                </th>
                                <th class="width-6p p-text-left">
                                    {{'memo' | translate}}
                                </th>
                                <th class="width-7p p-text-center" pTooltip="{{ 'due-date' | translate }} {{ 'offset_days' | translate }}"
                                    tooltipPosition="top" showDelay="800" *ngIf="myCreateForm.get('automaticComputeDuedate').value == true">
                                    {{ 'offset_days' | translate }}
                                    <i class="fa fa-question-circle ic-blue"></i>
                                </th>
                                <th class="width-6p p-text-center">
                                    {{'due-date' | translate}}
                                </th>
                                <th class="width-7p p-text-left">
                                    {{ 'Assigned-User' | translate }}
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-editing="editing">
                            <tr [pReorderableRow]="rowIndex" [pEditableRow]="rowData">
                                <td class="width-3p p-text-center">
                                    <i class="fal fa-trash-alt ic-red p-pr-3 cursor-hand"
                                        (click)="deleteRow($event,rowData);isFormChanged = false;" pTooltip="{{'Delete-Row' | translate}}"
                                        tooltipPosition="top"></i>
                                    <i class="fal fa-plus cursor-hand" (click)="addRow('new-row')" id="new-row"
                                        pTooltip="{{'Add-Row' | translate}}" tooltipPosition="top"
                                        *ngIf="((tasksDataSource.length-1) == rowIndex)"></i>
                                </td>
                                <td class="width-3p p-text-center">
                                    <i class="fal fa-bars cursor-hand" pReorderableRowHandle
                                        pTooltip="{{'Reorder-Row' | translate}}" tooltipPosition="top"></i>
                                </td>
                                <td class="width-3p p-text-center p-px-3">
                                    <div class="badge-number bg-blue">{{rowIndex+1}}</div>
                                </td>
                                <td pInitEditableRow class="width-8p p-text-left p-text-nowrap p-text-truncate p-fluid"
                                    [ngClass]="{'new-row': ((tasksDataSource.length-1) == rowIndex)}"
                                    (click)="onRowEditInit1(rowData, 'TaskDescription', rowIndex)"
                                    pTooltip="{{rowData['TaskDescription']}}" showDelay="800"
                                    tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input pInputText type="text" [(ngModel)]="rowData['TaskDescription']"
                                                [ngModelOptions]="{standalone: true}" (focus)="$event.target.select()"
                                                class="tasks-row-edit task-desc-{{rowIndex}}"
                                                (ngModelChange)="onTaskChanged(rowData)">
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <span>{{rowData['TaskDescription']}}</span>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pInitEditableRow class="width-6p p-text-left p-text-nowrap p-text-truncate p-fluid"
                                    pTooltip="{{rowData['TaskMemo']}}" showDelay="800"
                                    tooltipStyleClass="tooltip-no-wrap"
                                    (click)="onRowEditInit1(rowData, 'TaskMemo', rowIndex)" tooltipPosition="top">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input pInputText type="text" [(ngModel)]="rowData['TaskMemo']"
                                                [ngModelOptions]="{standalone: true}" (focus)="$event.target.select()"
                                                class="tasks-row-edit task-memo-{{rowIndex}}"
                                                (ngModelChange)="onTaskChanged(rowData)">
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <span>{{rowData['TaskMemo']}}</span>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pInitEditableRow (click)="onRowEditInit1(rowData, 'OffsetDays', rowIndex)"
                                    [ngClass]="{'new-row': ((tasksDataSource.length-1) == rowIndex)}"
                                    class="width-5p p-text-left p-text-nowrap p-text-truncate p-fluid"
                                    pTooltip="{{ 'offset_days' | translate}}" tooltipPosition="top" showDelay="800"
                                    *ngIf="myCreateForm.get('automaticComputeDuedate').value == true"
                                    tooltipStyleClass="tooltip-no-wrap">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input pInputText type="number" [(ngModel)]="rowData['dueDateOffsetDaysIndicator']"
                                                [ngModelOptions]="{standalone: true}"
                                                (focus)="$event.target.select()"
                                                min="0"
                                                class="tasks-row-edit offset-days-{{rowIndex}}"
                                                (ngModelChange)="validateForm();myCreateForm.markAsDirty();onOffsetDaysChange($event, rowIndex);" required>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <span>{{rowData['dueDateOffsetDaysIndicator']}}</span>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pInitEditableRow
                                    class="width-6p p-text-center p-text-nowrap p-text-truncate p-fluid"
                                    pTooltip="{{rowData['DueDate'] | date:'MM/dd/yyyy'}}"
                                    (click)="onRowEditInit1(rowData, 'DueDate', rowIndex)"
                                    tooltipStyleClass="tooltip-no-wrap" showDelay="800" tooltipPosition="top">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-calendar [monthNavigator]="true" [yearNavigator]="true"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="rowData['DueDate']"
                                                appendTo="body" yearRange="2000:2099" dateFormat="mm/dd/yy"
                                                class="calender-component" showButtonBar="true"
                                                panelStyleClass="tasks-row-edit"
                                                inputStyleClass="tasks-row-edit due-date-{{rowIndex}}"
                                                (onSelect)="onTaskChanged(rowData)">
                                            </p-calendar>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <span>{{rowData['DueDate'] | date:'MM/dd/yyyy'}}</span>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pInitEditableRow class="width-7p p-text-left p-text-nowrap p-text-truncate p-fluid"
                                    (click)="onRowEditInit1(rowData, 'StaffName', rowIndex)"
                                    pTooltip="{{rowData['StaffNames']}}" showDelay="800"
                                    tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <!-- <p-dropdown [(ngModel)]="rowData['UserAssigned']"
                                                [options]="AllManagerTypes" name="itemUserAssigned" placeholder="{{ 'Assigned-User' | translate }}"
                                                id="itemUserAssigned" #itemUserAssigned="ngModel" appendTo="body"
                                                [ngModelOptions]="{standalone: true}"
                                                (onChange)="handleDropSelectClick($event,rowData)">
                                            </p-dropdown> -->
                                            <p-multiSelect #multiStaff [(ngModel)]="rowData['UserAssignedIDArray']"
                                                [options]="StaffOptionsForTasks" placeholder="Unassigned"
                                                (onChange)="handleDropSelectClick($event,rowData);" appendTo="body"
                                                id="itemUserAssigned" name="itemUserAssigned"
                                                styleClass="tasks-row-edit staff-name-{{rowIndex}}"
                                                panelStyleClass="tasks-row-edit"></p-multiSelect>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{rowData['StaffNames']}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="7">
                                    <p-message severity="info" text="{{'data_not_found' | translate}}."
                                        styleClass="p-px-2">
                                    </p-message>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>

<p-sidebar [(visible)]="isShowBudgetsDialog" position="right" (onHide)="onCloseBudgetDialog()" [baseZIndex]="2"
    [style]="{'min-width':'68%','min-height':'100%','background':'#8e8f92','overflow': 'auto'}">
    <div class="card" *ngIf="isShowBudgetsDialog">
        <h4>Project Budget</h4>
        <form role="form" [formGroup]="myCreateForm" novalidate>
            <div class="p-col-12 p-pb-0">
                <div class="p-col-12 p-pb-0 p-fluid p-formgrid p-grid">
                    <div class="p-field width-20p p-mr-4">
                        <label class="label-text p-mr-4">Project Budgeted Time</label>
                        <input type="text" (focus)="$event.target.select();" pKeyFilter="num" pInputText
                            (blur)="myCreateForm.controls['TimeAmount'].setValue(mangoUtils.doMoneyFormat(myCreateForm.controls['TimeAmount'].value, 'time'))"
                            [ngClass]="{'notAllowed': myCreateForm.controls['isTrackTimeTaskLevel'].value, 'p-text-right': true}"
                            formControlName="TimeAmount" maxlength="100" autocomplete="off">
                    </div>
                    <div class="p-field width-20p p-mr-4">
                        <label class="label-text p-mr-4">{{ 'budget.year' | translate }}</label>
                        <!-- todo -->
                        <p-dropdown [options]="yearsList" formControlName="BudgetYear">
                        </p-dropdown>
                    </div>
                    <div class="p-field p-mr-4 p-mt-5">
                        <p-checkbox formControlName="isTrackTimeTaskLevel" [binary]="true"
                            (onChange)="onTrackTimeChanged()" label="Enable Task Budget" class="p-mt-2 label-text">
                        </p-checkbox>
                    </div>
                    <div class="p-field p-mr-4 p-mt-5">
                        <button type="button" pButton pRipple label="{{'save_button' | translate}}" icon="fal fa-check"
                            [disabled]="shouldDisableBudget()" class="p-button-sm" (click)="onSaveBudget()">
                        </button>
                    </div>
                    <div class="p-field p-mr-4 p-mt-5">
                        <button type="button" pButton pRipple label="Rollover Options" icon="fal fa-cogs"
                            class="p-button-sm" (click)="onShowRolloverDialog()">
                        </button>
                    </div>
                </div>
            </div>
        </form>
        <div class="p-col-12">
            <app-tasks-budget *ngIf="myCreateForm.controls['isTrackTimeTaskLevel'].value"
                (onTasksBudgetSaved)="onTasksBudgetSaved($event)" (onTasksBudgetUpdated)="onTasksBudgetUpdated($event)"
                [isFromTemplate]="true" [CompanyTemplateHeaderID]="CompanyTemplateHeaderID"></app-tasks-budget>
        </div>
    </div>
</p-sidebar>

<app-budget-rollover-options (onSaveDialog)="onCloseRolloverDialog($event)" *ngIf="showRollOverDialog"
    [templateObj]="templateObj"></app-budget-rollover-options>

<p-dialog [(visible)]="showCloneDialog" [modal]="true" [style]="{width: '35vw'}" [responsive]="true"
    (onHide)="showCloneDialog = false" [draggable]="true" [resizable]="false" showEffect="fade" [baseZIndex]="1"
    [maximizable]="false">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-col-12 p-field">
            <label class="label-text required-field">{{'pm.Project-Template-Name' | translate}}</label>
            <input type="text" pInputText [(ngModel)]="cloneTemplateName" maxlength="100" autocomplete="off">
        </div>
    </div>
    <p-footer>
        <button pButton label="{{'create' | translate}}" (click)="createClone(CompanyTemplateHeaderID)"
            [disabled]="!cloneTemplateName"></button>
        <button pButton label="{{'cancel_button' | translate}}" (click)="showCloneDialog = false"></button>
    </p-footer>
</p-dialog>

<div *ngIf="isDisplayWildcardsTable">
    <app-pm-wildcards-dialog
        (onClose)="isDisplayWildcardsTable=false">
    </app-pm-wildcards-dialog>
</div>

<app-rrule-generator [selectedDueDate]="myCreateForm.value['DueDate']"
    [selectedRuleString]="myCreateForm.value['RuleString']" (onCompleteRrule)="onCompleteRruleDialog($event)">
</app-rrule-generator>
