<div class="mentions">
  <textarea class="width-100p" [style]="style" [disabled]="disabled" [required]="required" [id]="id" #inputBox
    (input)="onInput(inputBox.value)" pInputTextarea [(ngModel)]="inputText" (ngModelChange)="onInputTextChange()"
    (focus)="$event.target.select()" (keydown)="onKeyDown($event)" [class]="class" [rows]="rows" [maxlength]="maxlength"
    placeholder="Type @ or / to search Shortcuts"
    pTooltip="Type @ or / to search Shortcuts"
    tooltipPosition="top"
    >
  </textarea>
  <ul *ngIf="showSuggestions && suggestions.length > 0" class="suggestions-list">
    <li *ngFor="let suggestion of suggestions; let i = index" [class.selected]="i === selectedIndex" #suggestionItem
      (click)="selectSuggestion(suggestion)">
      {{ suggestion }}
    </li>
  </ul>
</div>