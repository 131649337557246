import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppConstants } from '@app/_helpers/api-constants';
import { EncrDecrService, MangoApiService, BreadcrumbService, mangoUtils } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { Dialog } from '@syncfusion/ej2-angular-popups';
import { NodeSelection, ResizeService, RichTextEditorComponent, LinkService, ImageService, HtmlEditorService, ToolbarService, } from '@syncfusion/ej2-angular-richtexteditor';
import { NavigationPaneService, DetailsViewService, } from '@syncfusion/ej2-angular-filemanager';
import { EditService, FilterService, FreezeService, PageService } from '@syncfusion/ej2-angular-treegrid';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

import {
  environment
} from "@environments/environment";

declare let numeral: any;

@Component({
  selector: 'app-settings-other',
  templateUrl: './other.component.html',
  providers: [NavigationPaneService, ToolbarService, DetailsViewService, ToolbarService, EditService, PageService, FilterService, FreezeService, ResizeService, LinkService, ImageService, HtmlEditorService],
})
export class OtherComponent implements OnInit {
  companyData: any = {};
  companyId;
  dashboardSettingsForm: UntypedFormGroup;
  formDisabled: boolean = true;
  public emailTemplateList: any = [];
  public emailTemplateOptions: any = [];
  public selectedEmailTemplate = null;
  public selectedTemplate = null;
  public showTemplateEditor = false;
  public usingDefaultTemplate = false;
  public isChanged = false;
  public target: HTMLElement = document.getElementById('rteSection');
  public selection: NodeSelection = new NodeSelection();
  public ranges: Range;
  public warnNumOfDaysOptions: any = [
    {label: "1 Day", value: 1},
    {label: "2 Days", value: 2},
    {label: "3 Days", value: 3},
    {label: "4 Days", value: 4},
    {label: "5 Days", value: 5},
    {label: "6 Days", value: 6},
    {label: "7 Days", value: 7},
    {label: "8 Days", value: 8},
    {label: "9 Days", value: 9},
    {label: "10 Days", value: 10},
  ];
  public effectiveLockDayOptions: any = [
    {label: `Every 1st`, value: 1},
    {label: `Every 2nd`, value: 2},
    {label: `Every 3rd`, value: 3},
    {label: `Every 4th`, value: 4},
    {label: `Every 5th`, value: 5},
    {label: `Every 6th`, value: 6},
    {label: `Every 7th`, value: 7},
    {label: `Every 8th`, value: 8},
    {label: `Every 9th`, value: 9},
    {label: `Every 10th`, value: 10},
    {label: `Every 11th`, value: 11},
    {label: `Every 12th`, value: 12},
    {label: `Every 13th`, value: 13},
    {label: `Every 14th`, value: 14},
    {label: `Every 15th`, value: 15},
    {label: `Every 16th`, value: 16},
    {label: `Every 17th`, value: 17},
    {label: `Every 18th`, value: 18},
    {label: `Every 19th`, value: 19},
    {label: `Every 20th`, value: 20},
    {label: `Every 21st`, value: 21},
    {label: `Every 22nd`, value: 22},
    {label: `Every 23rd`, value: 23},
    {label: `Every 24th`, value: 24},
    {label: `Every 25th`, value: 25},
    {label: `Every 26th`, value: 26},
    {label: `Every 27th`, value: 27},
    {label: `Every 28th`, value: 28},
  ];

  public previousMonthsOptions: any = [
    {label: `1 month`, value: 1},
    {label: `2 months`, value: 2},
    {label: `3 months`, value: 3},
    {label: `4 months`, value: 4},
    {label: `5 months`, value: 5},
    {label: `6 months`, value: 6},
    {label: `7 months`, value: 7},
    {label: `8 months`, value: 8},
    {label: `9 months`, value: 9},
    {label: `10 months`, value: 10},
    {label: `11 months`, value: 11},
    {label: `12 months`, value: 12},
  ];

  public nylasIntegrationFeatureStatus: boolean = false;

  subscriptionLevel

  @ViewChild('Dialog')
  public dialogObj: Dialog

  @ViewChild('customRTE')
  public rteObj: RichTextEditorComponent;

  public imageSettings = {
    saveFormat: "Base64"
  };

  public tools: object = {
    items: ['Undo', 'Redo', '|',
    'Bold', 'Italic', 'Underline', '|',
    'FontName', 'FontSize', 'FontColor', 'BackgroundColor', '|',
    {
      tooltipText: 'Insert Field',
      undo: true,
      click: this.onClick.bind(this),
      template: '<button class="e-tbar-btn e-btn" tabindex="-1" id="custom_tbar"  style="width:100%">'
      + '<div class="e-tbar-btn-text" style="font-weight: 500;"><i class="fa fa-file-text-o" aria-hidden="true"></i></div></button>'
    },
    'Alignments', '|', 'OrderedList', 'UnorderedList', '|',
    'CreateLink', 'Image', '|', 'ClearFormat', 'Print', 'SourceCode']
  };

  public emailTools: object = {
    items: ['Undo', 'Redo', '|',
    'Bold', 'Italic', 'Underline', '|',
    'FontName', 'FontSize', 'FontColor',
    'Alignments', '|', 'OrderedList', 'UnorderedList',
    'CreateLink', 'SourceCode']
  };

  public dlgButtons: any = [{ buttonModel: { content: "Insert", isPrimary: true }, click: this.onInsert.bind(this) },
    { buttonModel: { content: 'Cancel' }, click: this.dialogOverlay.bind(this) }];

  displayPaymentOptions: boolean = false;
  clientPortalForm: UntypedFormGroup;
  timeexp: any = {};

  constructor
  (private fb: UntypedFormBuilder,
    private breadcrumbService: BreadcrumbService,
    private encrDecSrvc: EncrDecrService,
    private mangoAPISrvc: MangoApiService,
    private mangoUtils: mangoUtils,
    private translate: TranslateService,
    private router: Router) {
    this.companyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.subscriptionLevel = this.encrDecSrvc.getObject(AppConstants.subscriptionLevel);
    const interval = setInterval(() => {
    if(!this.translate.translations[this.translate.currentLang])
      return;
    clearInterval(interval);
    this.initTranslations();
    }, 300);

    this.initializeForm();

    this.nylasIntegrationFeatureStatus = (
      (
            environment.NYLAS_INTEGRATION_FEATURE_STATUS
        === true
      )
    );
  }

  public onClick() {
    this.rteObj.focusIn();
    this.ranges = this.selection.getRange(document);
    this.dialogObj.width = this.rteObj.element.offsetWidth * 0.4;
    this.dialogObj.dataBind();
    this.dialogObj.show();
    this.dialogObj.element.style.maxHeight = 'none';
  }

  public onInsert(): void {
    const activeEle: Element = this.dialogObj.element.querySelector('.char_block.e-active');
    if (activeEle) {
    const newNode = document.createElement('strong');
    const newTxtNode = document.createTextNode(activeEle.textContent);
    newNode.appendChild(newTxtNode);
    this.ranges.insertNode(newNode);
    }
    this.dialogOverlay();
  }

  selectTemplate(event){
    let templateType=null;
    templateType = event.value;
    this.showTemplateEditor = true;
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getEmailTemplateByTypeAndCompany(templateType, this.companyId)
      .subscribe(result=>{
        this.mangoAPISrvc.showLoader(false);
        if(!result['EmailTemplateID']){
          this.usingDefaultTemplate = true;
          this.selectedEmailTemplate = this.mangoUtils.getDefaultTemplate(templateType, this.companyId);
        }else{
          this.selectedEmailTemplate = result;
        }
      })
  }

  resetTemplate(){
    this.selectedEmailTemplate = {...this.selectedEmailTemplate, ...this.mangoUtils.getDefaultTemplate(this.selectedTemplate, this.companyId)}
    this.isChanged = true;
    this.usingDefaultTemplate = true;
    console.log(this.selectedEmailTemplate)
  }

  onTemplateEditorClose(){
    if(this.isChanged){
      Swal.fire({
        title: this.translate.instant('warning'),
        text: this.translate.instant('unsaved_changes_warning_slideout'),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.translate.instant('yes_leave'),
        cancelButtonText: this.translate.instant('no_stay')
      }).then(result=>{
        if(result.value){
          this.resetTemplateEditor();
        }else{
          this.showTemplateEditor = true;
        }
      })
      } else {
        this.resetTemplateEditor();
      }
  }

  resetTemplateEditor(){
    this.showTemplateEditor = false;
    this.usingDefaultTemplate = false
    this.isChanged = false;
    this.selectedTemplate = null;
    this.selectedEmailTemplate = null;
  }

  onRecordChange(){
    this.isChanged = true;
  }

  ngOnInit() {
    this.subscriptionLevel = this.encrDecSrvc.getObject(AppConstants.subscriptionLevel);
    this.emailTemplateList = this.encrDecSrvc.getObject(AppConstants.dmsEmailTemplate);
    this.emailTemplateOptions = [
      {label: 'Invoice Email Template', value: 'invoice'},
      {label: 'Statement Email Template', value: 'statement'},
      {label: 'Cash Receipt Email Template', value: 'cashReceipt'},
    ]
    this.getCompanyInfo();
    this.updateClientPortalForm();
  }

  public dialogOverlay(): void {
    const activeEle: Element = this.dialogObj.element.querySelector('.char_block.e-active');
    if (activeEle) {
    activeEle.classList.remove('e-active');
    }
    this.dialogObj.hide();
  }

  public dialogCreate(): void {
    const dialogCtn: HTMLElement = document.getElementById('rteSpecial_char');
    dialogCtn.onclick = (e: Event) => {
    const target: HTMLElement = e.target as HTMLElement;
    const activeEle: Element = this.dialogObj.element.querySelector('.char_block.e-active');
    if (target.classList.contains('char_block')) {
      target.classList.add('e-active');
      if (activeEle) {
      activeEle.classList.remove('e-active');
      }
    }
    };
  }

  saveTemplate(){
    if(this.selectedEmailTemplate['EmailTemplateID']){
      this.mangoAPISrvc.showLoader(true);
      this.mangoAPISrvc.updateEmailTemplate(this.selectedEmailTemplate)
        .subscribe(result=>{
          this.mangoAPISrvc.showLoader(false);
          this.mangoAPISrvc.notify('success', this.translate.instant('Success'), AppConstants.updateMsg);
          this.isChanged = false;
          this.showTemplateEditor = false
        })
    }else{
      this.mangoAPISrvc.showLoader(true);
      this.mangoAPISrvc.createEmailTemplate(this.selectedEmailTemplate)
        .subscribe(result=>{
          this.mangoAPISrvc.showLoader(false);
          this.mangoAPISrvc.notify('success', this.translate.instant('Success'), AppConstants.createMsg);
          this.isChanged = false;
          this.showTemplateEditor = false
        })
    }
  }

  initTranslations() {
    this.breadcrumbService.setItems([
      { label: this.translate.instant('Settings') },
      { label: this.translate.instant('client.other'), icon: 'ic-red' },
      { label: this.translate.instant('settings.Client-Portal'), icon: 'ic-red' }
    ]);
  }

  initializeForm( ){
    this.dashboardSettingsForm = (
      this.fb.group(
        {
          IsHideAgenda: [false],
          IsHideLeaderBoard: [false],
          IsHideToDo: [false],
          IsEnableTFA: [false],
          IsMicrosoftLogin: [false],
          isMSRequired: [false],
          isTFARequired: [false],
          isShowDashboardAdmin: [false],
          percentage: [0],
          timePercentage: [0],
          isEnableRollOver: [false],
          RollOverOption: [null],
          isUpdateRevenue: [false],
          isUpdateTime: [false],
          isEnableSystemLocking: [false],
          warnNumberOfDays: [0],
          effectiveLockDay: [0],
          monthsPreviousToLock: [0],
          nylasIntegrationSupportStatus: [false],
          nylasCalendarIntegrationSupportStatus: [false],
          nylasMailIntegrationSupportStatus: [false],
        }
      )
    );

    this.dashboardSettingsForm.valueChanges.subscribe(
      (
        ( event ) => {
          this.encrDecSrvc.addObject(
            AppConstants.isFormChanged,
            !this.dashboardSettingsForm.pristine
          );

          this.valueChanges( event, this.companyData );
        }
      )
    );

    this.clientPortalForm = this.fb.group({
      ActivateCustomerPortal: [false],
      AllowCustomerPortalEdit: [false],
      isShowInvHistory: [false],
      isShowPmtHistory: [false],
      isDMSVisiblePortal: [],
      modeOfPayment: [-1],
      isAllowPartialPayments: [false]
    })

    this.clientPortalForm.get('modeOfPayment').valueChanges.subscribe(val => {
      this.clientPortalForm.controls['modeOfPayment'].setValue(val, { emitEvent: false });
    });

    this.clientPortalForm.valueChanges.subscribe(val => {
      this.encrDecSrvc.addObject(AppConstants.isFormChanged, !this.clientPortalForm.pristine);
    });
  }

  valueChanges( event, companyData ){
    (
        this.formDisabled
      = (
              (
                    event.IsHideAgenda
                === companyData.IsHideAgenda
              )
          &&
              (
                    !event.IsHideLeaderBoard
                === companyData.IsLeaderboardVisible
              )
          &&
              (
                    event.IsHideToDo
                === companyData.IsHideToDo
              )
          &&
              (
                    event.IsEnableTFA
                === companyData.isEnableTFA
              )
          &&
              (
                    event.IsMicrosoftLogin
                === companyData.isMicrosoftLogin
              )
          &&
              (
                    event.isShowDashboardAdmin
                === companyData.isShowDashboardAdmin
              )
          &&
              (
                    event.RollOverOption
                === companyData.RollOverOption
              )
          &&
              (
                    numeral( event.percentage ).value( )
                === numeral( companyData.PctOptionRev ).value( )
              )
          &&
              (
                    numeral( event.timePercentage ).value( )
                === numeral( companyData.PctOptionTime ).value( )
              )
          &&
              (
                    event.isEnableRollOver
                === companyData.isEnableRollOver
              )
          &&
              (
                    event.isMSRequired
                === companyData.isMSRequired
              )
          &&
              (
                    event.isTFARequired
                === companyData.isTFARequired
              )
          &&
              (
                    event.isEnableSystemLocking
                === companyData.isEnableSystemLocking
              )
          &&
              (
                    event.monthsPreviousToLock
                === companyData.monthsPreviousToLock
              )
          &&
              (
                    event.warnNumberOfDays
                === companyData.warnNumberOfDays
              )
          &&
              (
                    event.effectiveLockDay
                === companyData.effectiveLockDay
              )
          &&
              (
                    event.nylasIntegrationSupportStatus
                === companyData.nylasIntegrationSupportStatus
              )
          &&
              (
                    event.nylasCalendarIntegrationSupportStatus
                === companyData.nylasCalendarIntegrationSupportStatus
              )
          &&
              (
                    event.nylasMailIntegrationSupportStatus
                === companyData.nylasMailIntegrationSupportStatus
              )
        )
    );

    this.encrDecSrvc.addObject(
      AppConstants.isFormChanged,
      !this.formDisabled
    );
  }

  getCompanyInfo() {
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getCompanyMangoDetails(this.companyId).subscribe(response => {
      this.companyData = {
        ...response[0],
        IsHideAgenda: (response[0].IsHideAgenda === null || response[0].IsHideAgenda === undefined) ? false : response[0].IsHideAgenda,
        IsLeaderboardVisible: response[0].IsLeaderboardVisible === null ? false : response[0].IsLeaderboardVisible,
        IsHideToDo: response[0].IsHideToDo === null ? false : response[0].IsHideToDo,
        isEnableTFA: response[0].isEnableTFA === null ? false : response[0].isEnableTFA,
        isMicrosoftLogin: response[0].isMicrosoftLogin === null ? false : response[0].isMicrosoftLogin,
        isShowDashboardAdmin: response[0].isShowDashboardAdmin === null ? false : response[0].isShowDashboardAdmin,
        PctOptionRev: response[0].PctOptionRev === null ? 0 : response[0].PctOptionRev,
        PctOptionTime: response[0].PctOptionTime === null ? 0 : response[0].PctOptionTime,
        isEnableRollOver: response[0].isEnableRollOver === null ? true : response[0].isEnableRollOver,
        RollOverOption: response[0].RollOverOption === null ? 'existing' : response[0].RollOverOption,
        isMSRequired: response[0].isMSRequired === null ? false : response[0].isMSRequired,
        isTFARequired: response[0].isTFARequired === null ? false : response[0].isTFARequired,
        isEnableSystemLocking: response[0].isEnableSystemLocking === null ? false : response[0].isEnableSystemLocking,
        warnNumberOfDays: response[0].warnNumberOfDays === null ? 0 : response[0].warnNumberOfDays,
        effectiveLockDay: response[0].effectiveLockDay === null ? 0 : response[0].effectiveLockDay,
        monthsPreviousToLock: response[0].monthsPreviousToLock === null ? 0 : response[0].monthsPreviousToLock,
        IsELEnabled: response[0].ELClientLimit > 0 ? true : false,
        isELConfigured: response[0].isELConfigured ?? false,
      };

      if (
        this.companyData.PctOptionRev &&
        numeral(this.companyData.PctOptionRev).value() > 0
      )
        this.dashboardSettingsForm.controls["isUpdateRevenue"].setValue(
        true
        );
      if (
        this.companyData.PctOptionTime &&
        numeral(this.companyData.PctOptionTime).value() > 0
      )
        this.dashboardSettingsForm.controls["isUpdateTime"].setValue(
        true
        );

      this.dashboardSettingsForm.controls['IsHideAgenda'].setValue(this.companyData.IsHideAgenda);
      this.dashboardSettingsForm.controls['IsHideLeaderBoard'].setValue(!this.companyData.IsLeaderboardVisible);
      this.dashboardSettingsForm.controls['IsHideToDo'].setValue(this.companyData.IsHideToDo);
      this.dashboardSettingsForm.controls['IsEnableTFA'].setValue(this.companyData.isEnableTFA);
      this.dashboardSettingsForm.controls['IsMicrosoftLogin'].setValue(this.companyData.isMicrosoftLogin);
      this.dashboardSettingsForm.controls['isShowDashboardAdmin'].setValue(this.companyData.isShowDashboardAdmin);
      this.dashboardSettingsForm.controls['percentage'].setValue(this.companyData.PctOptionRev || 0);
      this.dashboardSettingsForm.controls['timePercentage'].setValue(this.companyData.PctOptionTime || 0);
      this.dashboardSettingsForm.controls['isEnableRollOver'].setValue(this.companyData.isEnableRollOver);
      this.dashboardSettingsForm.controls['RollOverOption'].setValue(this.companyData.RollOverOption);
      this.dashboardSettingsForm.controls['isTFARequired'].setValue(this.companyData.isTFARequired);
      this.dashboardSettingsForm.controls['isMSRequired'].setValue(this.companyData.isMSRequired);
      this.dashboardSettingsForm.controls['isEnableSystemLocking'].setValue(this.companyData.isEnableSystemLocking);
      this.dashboardSettingsForm.controls['warnNumberOfDays'].setValue(this.companyData.warnNumberOfDays);
      this.dashboardSettingsForm.controls['effectiveLockDay'].setValue(this.companyData.effectiveLockDay);
      this.dashboardSettingsForm.controls['monthsPreviousToLock'].setValue(this.companyData.monthsPreviousToLock);

      this.nylasIntegrationFeatureStatus &&
      this.dashboardSettingsForm.controls['nylasIntegrationSupportStatus']
      .setValue( this.companyData.nylasIntegrationSupportStatus );

      this.nylasIntegrationFeatureStatus &&
      this.dashboardSettingsForm.controls['nylasCalendarIntegrationSupportStatus']
      .setValue( this.companyData.nylasCalendarIntegrationSupportStatus );

      this.nylasIntegrationFeatureStatus &&
      this.dashboardSettingsForm.controls['nylasMailIntegrationSupportStatus']
      .setValue( this.companyData.nylasMailIntegrationSupportStatus );

      this.mangoAPISrvc.showLoader(false);
    }, () => {
      this.mangoAPISrvc.showLoader(false);
    });
  }

  saveClientSettings( ){
    const formData = this.dashboardSettingsForm.value;

    if (formData.isEnableSystemLocking && (!formData.effectiveLockDay || !formData.monthsPreviousToLock)) {
      this.mangoAPISrvc.notify('error', "Please select Effective Lock Day of The Month & Month(s) Previous to Lock", "");
      this.dashboardSettingsForm.controls['isEnableSystemLocking'].setValue(this.companyData.isEnableSystemLocking);
      this.dashboardSettingsForm.controls['warnNumberOfDays'].setValue(this.companyData.warnNumberOfDays);
      this.dashboardSettingsForm.controls['effectiveLockDay'].setValue(this.companyData.effectiveLockDay);
      this.dashboardSettingsForm.controls['monthsPreviousToLock'].setValue(this.companyData.monthsPreviousToLock);
      return;
    }

    this.companyData.IsHideAgenda = formData.IsHideAgenda;
    this.companyData.IsLeaderboardVisible = !formData.IsHideLeaderBoard;
    this.companyData.IsHideToDo = formData.IsHideToDo;
    this.companyData.isEnableTFA = formData.IsEnableTFA;
    this.companyData.isMicrosoftLogin = formData.IsMicrosoftLogin;
    this.companyData.isShowDashboardAdmin = formData.isShowDashboardAdmin;
    this.companyData.PctOptionRev = formData.isUpdateRevenue ? formData.percentage : 0;
    this.companyData.PctOptionTime = formData.isUpdateTime ? formData.timePercentage : 0;
    this.companyData.isEnableRollOver = formData.isEnableRollOver;
    this.companyData.RollOverOption = formData.RollOverOption;
    this.companyData.isEnableSystemLocking = formData.isEnableSystemLocking;
    this.companyData.warnNumberOfDays = formData.warnNumberOfDays;
    this.companyData.effectiveLockDay = formData.effectiveLockDay;
    this.companyData.monthsPreviousToLock = formData.monthsPreviousToLock;
    if(this.companyData.isEnableTFA)
      this.companyData.isTFARequired = formData.isTFARequired;
    else
      this.companyData.isTFARequired = false;

    if(this.companyData.isMicrosoftLogin)
      this.companyData.isMSRequired = formData.isMSRequired;
    else
      this.companyData.isMSRequired = false;

    this.nylasIntegrationFeatureStatus &&
    (
          this.companyData.nylasIntegrationSupportStatus
      =   (
            formData.nylasIntegrationSupportStatus
          )
    );

    if(
        (
              this.companyData.nylasIntegrationSupportStatus
          === true
        )
    ){
      this.nylasIntegrationFeatureStatus &&
      (
            this.companyData.nylasCalendarIntegrationSupportStatus
        =   (
              formData.nylasCalendarIntegrationSupportStatus
            )
      );

      this.nylasIntegrationFeatureStatus &&
      (
            this.companyData.nylasMailIntegrationSupportStatus
        =   (
              formData.nylasMailIntegrationSupportStatus
            )
      );
    }
    else{
      this.nylasIntegrationFeatureStatus &&
      (
            this.companyData.nylasCalendarIntegrationSupportStatus
        =   (
              false
            )
      );

      this.nylasIntegrationFeatureStatus &&
      (
            this.companyData.nylasMailIntegrationSupportStatus
        =   (
              false
            )
      );
    }

    /**
     * Necessary fields for the this.mangoAPISrvc.updateCompanyView.
     * If not specified, API will return an error "Invalid Parameters"
     */
    this.companyData['phone'] = this.companyData['Telephone'];
    this.companyData['email'] = this.companyData['EmailCompany'];
    this.companyData['state'] = this.companyData['State'];
    this.companyData['country'] = this.companyData['Country'];
    this.companyData['companyName'] = this.companyData['CompanyName'];
    this.companyData['InvoiceReminderDaysPastDue'] = this.companyData['InvoiceReminderDaysPastDue'] ? this.companyData['InvoiceReminderDaysPastDue'] : 0;
    this.companyData['InvoiceReminderInterval'] = this.companyData['InvoiceReminderInterval'] ? this.companyData['InvoiceReminderInterval'] : 0;
    this.companyData['InvoiceReminderStartDate'] = this.companyData['InvoiceReminderStartDate'] ? new Date(this.companyData['InvoiceReminderStartDate']) : null;

    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.updateCompanyView(this.companyData).subscribe(() => {
      if(!this.companyData.isELConfigured) {
        this.mangoAPISrvc.copyTemplatesToCompany(this.companyData.CompanyID).subscribe(
          () => {
            this.mangoAPISrvc.notify(
              "success",
              this.translate.instant("Success"),
              "Successfully enabled engagement letters."
            );
            this.companyData.isELConfigured = true;
            this.encrDecSrvc.addObject(AppConstants.companySettingsData, this.companyData);
          },
          () => {
            this.mangoAPISrvc.notify(
              "error",
              this.translate.instant("Error"),
              "Failed to enable engagement letters."
            );
          }
        );
      }
      this.encrDecSrvc.addObject(AppConstants.companySettingsData, this.companyData)
      const data = {}
      data['Action'] = "Settings - Others";
      data['Description'] = "Changes to Settings";
      data['Table'] = "CompanyMango";
      const isManaging = this.encrDecSrvc.getObject(AppConstants.isManagingAccount);
      if (!isManaging) {
        this.mangoAPISrvc.addUserLogs(data).subscribe(res=>{}, err=>{});
      }
      this.formDisabled = true;
      this.mangoAPISrvc.showLoader(false);
      this.mangoAPISrvc.notify('success', this.translate.instant('Success'), AppConstants.updateMsg);
      this.encrDecSrvc.addObject(AppConstants.isFormChanged, false);

      const companySetting = this.encrDecSrvc.getObject(AppConstants.systemLocking);
      const locking = {
        "isEnableSystemLocking":  this.companyData.isEnableSystemLocking ?? false,
        "monthsPreviousToLock": this.companyData.monthsPreviousToLock ?? 0,
        "effectiveLockDay": this.companyData.effectiveLockDay ?? 0,
        "warnNumberOfDays": this.companyData.warnNumberOfDays ?? 0,
        "CompanyID": companySetting.CompanyID,
        "StaffID": companySetting.StaffID
        };

        this.encrDecSrvc.addObject(AppConstants.systemLocking, locking);
    }, () => {
      this.mangoAPISrvc.showLoader(false);
      this.mangoAPISrvc.notify('error', this.translate.instant('error'), AppConstants.updateErrorMsg);
    });
  }

  updateClientPortalForm() {
      const parent = this;
      parent.mangoAPISrvc.showLoader(true);
      parent.mangoAPISrvc.getTimeAndExpenses().subscribe(function (data) {
        parent.encrDecSrvc.addObject(AppConstants.timeAndExpenses, data);
        parent.timeexp = parent.encrDecSrvc.getObject(AppConstants.timeAndExpenses);
        parent.displayPaymentOptions = parent.timeexp.isStMIdPresents;
        if (data && Object.keys(data).length > 0) {
          // parent.clientPortalForm.controls['ActivateCustomerPortal'].setValue(data['ActivateCustomerPortal']);
          parent.clientPortalForm.controls['AllowCustomerPortalEdit'].setValue(data['AllowCustomerPortalEdit']);
          parent.clientPortalForm.controls['isShowInvHistory'].setValue(data['isShowInvHistory']);
          parent.clientPortalForm.controls['isShowPmtHistory'].setValue(data['isShowPmtHistory']);
          parent.clientPortalForm.controls['isDMSVisiblePortal'].setValue(data['isDMSVisiblePortal']);
          parent.clientPortalForm.controls['isAllowPartialPayments'].setValue(data['isAllowPartialPayments']);
  
          data['modeOfPayment'] = data['modeOfPayment'] != null ? numeral(data['modeOfPayment']).value() : -1;
          parent.clientPortalForm.controls['modeOfPayment'].setValue(data['modeOfPayment']);
        }
        parent.mangoAPISrvc.showLoader(false);
      });
    }

    saveClientPortalForm() {
      const parent = this;
      const formObj = this.clientPortalForm.value;
      formObj.modeOfPayment = formObj.modeOfPayment == -1 ? null : formObj.modeOfPayment;
      parent.mangoAPISrvc.showLoader(true);
      parent.mangoAPISrvc.updateCustomerPortalSettings(parent.companyId, formObj).subscribe(function (data) {
        parent.mangoAPISrvc.showLoader(false);
        parent.mangoAPISrvc.notify('success', parent.translate.instant('Success'), AppConstants.updateMsg);
        parent.clientPortalForm.markAsPristine();
        parent.encrDecSrvc.addObject(AppConstants.isFormChanged, !parent.clientPortalForm.pristine);
        parent.updateStorage();
  
      }, (error) => {
        parent.mangoAPISrvc.showLoader(false);
        parent.mangoAPISrvc.notify('error', parent.translate.instant('error'), AppConstants.updateErrorMsg);
      });
    }

    updateStorage() {
      const formObj = this.clientPortalForm.value;
      this.timeexp['ActivateCustomerPortal'] = formObj.ActivateCustomerPortal;
      this.timeexp['AllowCustomerPortalEdit'] = formObj.AllowCustomerPortalEdit;
      this.timeexp['isShowInvHistory'] = formObj.isShowInvHistory;
      this.timeexp['isShowPmtHistory'] = formObj.isShowPmtHistory;
      this.timeexp['isAllowPartialPayments'] = formObj.isAllowPartialPayments;
      this.timeexp['modeOfPayment'] = formObj.modeOfPayment;
      this.encrDecSrvc.addObject(AppConstants.timeAndExpenses, this.timeexp);
    }

    redirectStaxToLogin() {
        if (this.subscriptionLevel == 'FREE') {
          Swal.fire({
            icon: 'warning',
            title: 'Information!',
            text: "Your are on a trial account. You must be on a paid plan to enroll and electronic payments!",
            showConfirmButton: false,
            timer: 3000
          })
        } else {
          this.router.navigate([`/accounting/cards-view`]);
        }
      }
}
