<div class="batch-options-time">
  <label class="label-text p-mr-2 p-mb-2" style="display: inline-block">{{
    "pm.batch_options" | translate
  }}</label>
  <button
    type="button"
    class="p-mr-4"
    pButton
    pRipple
    icon="pi pi-chevron-down"
    (click)="menu.toggle($event)"
  ></button>
  <p-menu appendTo="body" #menu [popup]="true" styleClass="button-list" [model]="batchButtons"> </p-menu>

  <p-dialog
    [(visible)]="showReassignDialog"
    [modal]="true"
    [style]="{ width: '30vw' }"
    [maximizable]="false"
    showEffect="fade"
    [draggable]="true"
    [resizable]="false"
    (onHide)="onCloseReassignForm()"
  >
    <p-header>
      {{ "Reassign_Time_records" | translate }} -
      {{ selOpenTimeRecords?.length + selOpenExpRecords?.length }}
      {{ "selected" | translate }}
    </p-header>
    <div class="p-fluid p-formgrid p-grid p-m-1 common-dialog">
      <form #reassignForm="ngForm" class="p-col-12">
        <div class="p-formgroup-inline">
          <div class="p-col-4 p-mt-4">
            <label class="label-text">{{ "client.name" | translate }}</label>
          </div>
          <div class="p-col-8 p-mt-4">
            <p-autoComplete
              [(ngModel)]="formObj.SelClient"
              autocomplete="off"
              [suggestions]="filteredClients"
              (completeMethod)="filterClients($event)"
              field="ClientName"
              (onSelect)="handleSelectClick($event)"
              [dropdown]="true"
              ngDefaultControl
              placeholder="{{ 'type_client_name' | translate }}"
              name="selclientID"
              #selclientID="ngModel"
              appendTo="body"
              required
            >
              <ng-template let-client pTemplate="item">
                <div>{{client.ClientName}}{{ client.ClientNumber ? ' - ' + client.ClientNumber : '' }}</div>
              </ng-template>
            </p-autoComplete>
          </div>
        </div>
        <div class="p-formgroup-inline">
          <div class="p-col-4 p-mt-4">
            <label class="required-field label-text">{{
              "engagement" | translate
            }}</label>
          </div>
          <div class="p-col-8 p-mt-4">
            <p-dropdown
              [options]="projectsList"
              panelStyleClass="dialog-dropdown"
              [(ngModel)]="formObj.ProjectMasterID"
              name="itemProjectSelected"
              [filter]="true"
              (onChange)="handleSelectClick($event, true)"
              id="itemProjectSelected"
              appendTo="body"
              #itemProjectSelected="ngModel"
              [disabled]="!formObj.SelClient"
              placeholder="{{ 'select-engagement' | translate }}"
              required
            ></p-dropdown>
          </div>
        </div>
      </form>
    </div>
    <ng-template pTemplate="footer">
      <div class="p-d-flex p-jc-end">
        <button
          type="button"
          pButton
          pRipple
          label="{{ 'Reassign' | translate }}"
          (click)="onReassign()"
          [disabled]="!(formObj.SelClient && formObj.ProjectMasterID)"
        ></button>
        <button
          type="button"
          pButton
          pRipple
          label="{{ 'cancel_button' | translate }}"
          (click)="onCloseReassignForm()"
        ></button>
      </div>
    </ng-template>
  </p-dialog>

  <p-dialog
    [(visible)]="isShowUpdateBilledAmtDialog"
    [modal]="true"
    [style]="{ width: '30vw' }"
    [maximizable]="false"
    showEffect="fade"
    [draggable]="true"
    [resizable]="false"
    (onHide)="onCloseUpdateBilledAmtDialog()"
  >
    <p-header>
      Distribute Amount to Time Records -
      {{ selOpenTimeRecords?.length }}
      {{ "selected" | translate }}
    </p-header>
    <div class="p-fluid p-formgrid p-grid p-m-1 common-dialog">
      <form #batchUpdateForm="ngForm" class="p-col-12">
        <div class="p-formgroup-inline">
          <div class="p-col-4 p-mt-4">
            <label class="required-field label-text">
              {{'billed-amount' | translate}}
            </label>
          </div>
          <div class="p-col-8 p-mt-4">
            <input pInputText type="text"
              autocomplete="off" class="p-text-right"
              name="billedAmount" #billedAmount="ngModel"
              [(ngModel)]="batchUpdateFormObj.billedAmount"
              (focus)="$event.target.select();"
              (blur)="formatBilledAmount();"
              required
            >
          </div>
        </div>
      </form>
    </div>
    <ng-template pTemplate="footer">
      <div class="p-d-flex p-jc-end">
        <button
          type="button"
          pButton
          pRipple
          [disabled]="!!!batchUpdateFormObj.billedAmount || batchUpdateFormObj.billedAmount == '0'"
          label="{{ 'Update' | translate }}"
          (click)="onBatchUpdateBilledAmount()"
        ></button>
        <button
          type="button"
          pButton
          pRipple
          label="{{ 'cancel_button' | translate }}"
          (click)="onCloseUpdateBilledAmtDialog()"
        ></button>
      </div>
    </ng-template>
  </p-dialog>

  <p-dialog
    [(visible)]="showUpdateDialog"
    [modal]="true"
    [style]="{ width: '30vw' }"
    [maximizable]="false"
    showEffect="fade"
    [draggable]="true"
    [resizable]="false"
    (onHide)="onCloseBatchUpdateForm()"
  >
    <p-header>
      {{ "Batch Update Billing Rate" | translate }} -
      {{ ((selOpenExpRecords?.length>0)?selOpenExpRecords.length:0) + ((selOpenTimeRecords?.length>0)?selOpenTimeRecords.length:0) }}
      {{ "selected" | translate }}
    </p-header>
    <div class="p-fluid p-formgrid p-grid p-m-1 common-dialog">
      <form #batchUpdateForm="ngForm" class="p-col-12">
        <div class="p-formgroup-inline">
          <div class="p-col-4 p-mt-4">
            <label class="required-field label-text">
              {{ 'billable' | translate }} {{ 'rate' | translate }}
            </label>
          </div>
          <div class="p-col-8 p-mt-4">
            <input pInputText type="text"
              autocomplete="off" class="p-text-right"
              name="billingRate" #billingRate="ngModel"
              [(ngModel)]="batchUpdateFormObj.billingRate"
              (focus)="$event.target.select();"
              (blur)="formatBillingRate();"
              required
            >
          </div>
        </div>
      </form>
    </div>
    <ng-template pTemplate="footer">
      <div class="p-d-flex p-jc-end">
        <button
          type="button"
          pButton
          pRipple
          [disabled]="!!!batchUpdateFormObj.billingRate || batchUpdateFormObj.billingRate == '0'"
          label="{{ 'Update' | translate }}"
          (click)="onBatchUpdate()"
        ></button>
        <button
          type="button"
          pButton
          pRipple
          label="{{ 'cancel_button' | translate }}"
          (click)="onCloseBatchUpdateForm()"
        ></button>
      </div>
    </ng-template>
  </p-dialog>
</div>
